import React, { useState } from "react";
import classNames from "classnames";
import { translate, icon } from "lib";
import _ from "lodash";



const Tabs = (props: any) => {
  const hashSubstring = window.location.hash.split("?")[0].substr(1);
  const allTabReferences = _.map(_.compact(_.get(props, "tabs", [])), tab => _.get(tab, "props.name"));
  const firstTabReference = _.first(allTabReferences);
  const [state, setState] = useState({ selectedTab: hashSubstring && _.includes(allTabReferences, hashSubstring) ? hashSubstring : firstTabReference })
  const { tabs, highlightedTabs } = props;
  const { selectedTab } = state;

  return (
    <div className={classNames("tabs")}>
      <ul key="tabs" className="nav">
        {_.map(
          tabs,
          tab =>
            !!tab && (
              <li
                data-ref={_.get(tab, "props.name", "").toLowerCase()}
                className={classNames("nav-item", {
                  "highlighted-tab": _.includes(highlightedTabs, _.get(tab, "props.name"))
                })}
                key={`item-${_.get(tab, "props.name")}`}
              >
                <a
                  className={classNames("nav-link", `nav-${_.get(tab, "props.name")}`, {
                    active: selectedTab === _.get(tab, "props.name")
                  })}
                  href={`#${_.get(tab, "props.name")}`}
                  onClick={() => {
                    setState({ selectedTab: _.get(tab, "props.name") })
                    window.history.pushState({}, "", `#${_.get(tab, "props.name")}`)
                  }
                  }
                >
                  {icon(_.get(tab, "props.icon"))}
                  {translate(_.get(tab, "props.name"))}
                </a>
                {_.includes(highlightedTabs, _.get(tab, "props.name")) && icon("protected")}
              </li>
            )
        )}
      </ul>
      {_.find(tabs, tab => _.get(tab, "props.name") === selectedTab)}
    </div>
  );

}

export default Tabs;


// export default class Tabs extends React.Component {
//   constructor(props) {
//     super(props);
//     const hashSubstring = window.location.hash.substr(1);
//     const tabs = _.compact(_.get(props, "tabs", []));
//     const allTabReferences = _.map(tabs, tab => _.get(tab, "props.name"));
//     const firstTabReference = _.first(allTabReferences);
//     const selectedTab =
//       hashSubstring && _.includes(allTabReferences, hashSubstring) ? hashSubstring : firstTabReference;
//     this.state = { selectedTab };
//   }

//   render() {
//     const { tabs, highlightedTabs } = this.props;
//     const { selectedTab } = this.state;
//     return (
//       <div className={classNames("tabs")}>
//         <ul key="tabs" className="nav">
//           {_.map(
//             tabs,
//             tab =>
//               !!tab && (
//                 <li
//                   data-ref={_.get(tab, "props.name", "").toLowerCase()}
//                   className={classNames("nav-item", {
//                     "highlighted-tab": _.includes(highlightedTabs, _.get(tab, "props.name"))
//                   })}
//                   key={`item-${_.get(tab, "props.name")}`}
//                 >
//                   <a
//                     className={classNames("nav-link", `nav-${_.get(tab, "props.name")}`, {
//                       active: selectedTab === _.get(tab, "props.name")
//                     })}
//                     href={`#${_.get(tab, "props.name")}`}
//                     onClick={() =>
//                       this.setState({ selectedTab: _.get(tab, "props.name") }, () =>
//                         window.history.pushState({}, "", `#${_.get(tab, "props.name")}`)
//                       )
//                     }
//                   >
//                     {icon(_.get(tab, "props.icon"))}
//                     {translate(_.get(tab, "props.name"))}
//                   </a>
//                   {_.includes(highlightedTabs, _.get(tab, "props.name")) && icon("protected")}
//                 </li>
//               )
//           )}
//         </ul>
//         {_.find(tabs, tab => _.get(tab, "props.name") === selectedTab)}
//       </div>
//     );
//   }
// }
