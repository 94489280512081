import { getProperty } from "actions";
import { memoAC } from "./memoize";
import axios from "v2/utils/axios";

export const getTenancy = memoAC((id: string) => (dispatch: any) =>
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}tenancies/${id}`)
		.then((res) => {
			dispatch({ type: "GET_TENANCY_SUCCESS", resource: res.data.tenancy });
			dispatch(getProperty(res.data.tenancy.property_id));
		})
		.catch((e) => { dispatch({ type: "GET_TENANCY_FAILED", response: e }); }));

export const getTenancies = memoAC(() => (dispatch: any) => 
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}tenancies`)
		.then((res) => dispatch({ type: "GET_TENANCIES_SUCCESS", resource: res.data.tenancies }))
		.catch((e) => { dispatch({ type: "GET_TENANCIES_FAILED", response: e }); }));

export const putTenancy = memoAC((tenancy: tcpinpoint.Tenancy) => (dispatch: any) =>
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}tenancies/${tenancy.id}`, { tenancy })
		.then((res) => {
			dispatch({ type: "PUT_TENANCY_SUCCESS", resource: res.data.tenancy });
			dispatch(getTenancy(tenancy.id));
		})
		.catch((e) => { dispatch({ type: "PUT_TENANCY_FAILED", response: e }); }));
