import React, { useState, memo } from "react";
import classNames from "classnames";
import { ListGroupControl, ListGroup } from "components/Shared";
import { Item as Document } from "components/Documents";

interface Props {
	documents: tcpinpoint.Document[];
	onAdd?: (arg0: any) => void;
}

const Collection = ({ documents, onAdd }: Props) => {
	const [viewBy, setViewBy] = useState("grid");
	const [sortBy, setSortBy] = useState("created_at");
	const [filterBy, setFilterBy] = useState("all");

	// @ts-ignore
	const collection = documents.sort(function compare(a, b) {
		if (sortBy === "created_at") {
			var dateA = new Date(a.created_at);
			var dateB = new Date(b.created_at);
			//@ts-ignore
			return dateB - dateA;
		}
		return a.name;
	});

	return (
		<React.Fragment>
			<ListGroupControl
				onFilterChange={setFilterBy}
				selectedFilter={filterBy}
				filterOptions={["all", "mine"]}
				onSortChange={setSortBy}
				selectedSort={sortBy}
				sortOptions={["created_at", "name"]}
				onViewChange={setViewBy}
				selectedView={viewBy}
				viewOptions={["list", "grid"]}
			/>
			<ListGroup
				resource={"documents"}
				collection={collection}
				onAdd={onAdd}
				renderRow={document => <Document key={document.id} document={document} />}
				className={classNames("documents", "document-list-group", `documents-${viewBy}-view`)}
			/>
		</React.Fragment>
	);
};

export default memo(Collection);
