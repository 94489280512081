import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Date } from "components/Shared";
import Avatar from "components/Avatar/Show";
// Twemoji
import { thumbsUp, clap, heart, shocked, think, fire } from "utils/twemoji/twemoji";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityReactionItem = ({ activity }: Props) => {
	const reactionIcons = [
		{
			id: 1,
			icon: thumbsUp,
			alt: "Thumbs Up",
		},
		{
			id: 2,
			icon: clap,
			alt: "Clamp",
		},
		{
			id: 3,
			icon: fire,
			alt: "Fire",
		},
		{
			id: 4,
			icon: heart,
			alt: "Heart",
		},
		{
			id: 5,
			icon: shocked,
			alt: "Shocked",
		},
		{
			id: 6,
			icon: think,
			alt: "Thinking",
		},
	];

	const linkType = `${activity.attributes.item.object.commentable_type === "Project" ? "projects" : "tasks"}`;
	const link = `/${linkType}/${activity.attributes.item.object.id}?_comment_id=${activity.attributes.item.object.comment_id}#comments`;
	const output = (
		<>
			<strong className="activity-item-actor">{activity.attributes.item.actor.name}</strong> reacted{" "}
			{activity.attributes.item.object.reaction_type && (
				<img
					style={{
						display: "inline",
						height: "24px",
					}}
					src={reactionIcons[activity.attributes.item.object.reaction_type - 1].icon}
					alt={reactionIcons[activity.attributes.item.object.reaction_type - 1].alt}
				/>
			)}{" "}
			to your comment on <strong className="activity-item-subject">{activity.attributes.item.object.name}</strong>
			{activity.attributes.item.object.project_name && (
				<>
					{" "}
					on <strong className="activity-item-subject">{activity.attributes.item.object.project_name}</strong>
				</>
			)}
		</>
	);

	return (
		<Link to={link} className="activity-item-link">
			<div className="activity-item-avatar">
				<Avatar
					size={32}
					url={activity.attributes.item.actor.image}
					name={
						activity.attributes.item.actor && !!activity.attributes.item.actor.name
							? activity.attributes.item.actor.name
							: "tcbot"
					}
				/>
			</div>
			<div className="activity-item-container">
				<div className="activity-item-body">{output}</div>
				<span className="activity-item-date">
					<Date showRelative date={activity.attributes.item.published} />
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.object.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{link}</code>}
				</span>
			</div>
		</Link>
	);
};

export default memo(ActivityReactionItem);
