import React, { memo } from "react";
import moment from "moment";
import { RelativeDate } from "components/Shared";
import { translate } from "lib";
import classNames from "classnames";

const Date = ({
	date,
	showRelative = false,
	showEmpty = true,
	showTime = true,
	showDay = false,
	showShort = false,
	className
}: {
	date?: any;
	showRelative?: boolean;
	showEmpty?: boolean;
	showTime?: boolean;
	showDay?: boolean;
	showShort?: boolean;
	className?: string;
}) => {
	let output = null;
	if (!!showEmpty) {
		output = <span className={classNames("muted", className)}>{translate("unknown")}</span>;
	}
	if (date) {
		const momentDate = moment(date);
		const dayString = momentDate.format("dddd");
		const dateString = momentDate.format("D MMMM YYYY");
		const timeString = momentDate.format("h:mma");
		if (showRelative && momentDate.isAfter(moment().subtract("1", "hour"))) {
			output = (
				<span className={classNames("formatted-date", className)}>
					<RelativeDate date={date} />
				</span>
			);
		} else if (moment(date).isAfter(moment().startOf("day")) && momentDate.isBefore(moment().endOf("day"))) {
			output = (
				<span
					key={moment(date).toJSON()}
					className={classNames("formatted-date", className)}
					data-date={moment(date).toJSON()}
				>
					Today {showTime && `at ${timeString}`}
				</span>
			);
		} else {
			output = (
				<span
					key={moment(date).toJSON()}
					className={classNames("formatted-date", className)}
					data-date={moment(date).toJSON()}
				>
					{showDay && <span className={classNames("formatted-date-day", className)}>{dayString}, </span>}
					<span className={classNames("formatted-date-date", className)}>{dateString}</span>
					{showTime && <span className={classNames("formatted-date-time", className)}>{timeString}</span>}
				</span>
			);
		}
	}
	return output;
};

export default memo(Date);
