import _ from "lodash";
import classNames from "classnames";
import Datasource, { DatasourceActionType, DatasourceResponse } from "lib/datasource";
import Modal from "react-modal";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import validate from "validate.js";
import { Button, LoadingState, ModalHeader } from "components/Shared";
import { TextInput } from "components/Inputs";
import { translate } from "lib";

const constraints = {
	comment: { presence: true },
};

const OverrideModal = ({ task, lastMoment, isOpen, onRequestClose, onUpdate }: any) => {
	const [canPost, setCanPost] = useState(false);
	const [comment, setComment] = useState<string>();
	const [errors, setErrors] = useState();

	const { id: taskId } = task;
	const taggableUsers = useMemo(() => _.get(task, "all_users", []), [task]);
	const [incompleteConditions, setIncompleteConditions] = useState([] as any[]);
	console.log("🚀 ~ file: Override.tsx:24 ~ OverrideModal ~ incompleteConditions:", incompleteConditions);
	const [openParentTasks, setOpenParentTasks] = useState([] as any[]);
	const [, renderTrigger] = useState(moment());
	const [conditionDatasource] = useState(
		new Datasource({
			mainModelName: "condition",
			sortBy: "created_at",
			perPage: -1,
			currentPage: 1,
			renderTrigger: renderTrigger,
		})
	);
	const [taskDatasource] = useState(
		new Datasource({
			mainModelName: "task",
			sortBy: "created_at",
			perPage: -1,
			currentPage: 1,
			renderTrigger: renderTrigger,
		})
	);
	const isLoading = useMemo(
		() => taskDatasource.isLoading || conditionDatasource.isLoading,
		[taskDatasource.isLoading, conditionDatasource.isLoading]
	);

	const closeTask = useCallback(() => {
		if (!_.isNil(taskId))
			taskDatasource.put("v2", `tasks/${taskId}/close`, { body: { message: comment } }, { updateIsLoding: true });
	}, [taskDatasource, taskId, comment]);

	const onSubmit = useCallback(() => {
		const errors = validate({ comment }, constraints);
		if (!!errors) {
			setErrors(errors);
		} else {
			setErrors({} as any);
			closeTask();
		}
	}, [setErrors, closeTask, comment]);

	useEffect(() => {
		let sub = conditionDatasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setIncompleteConditions(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [conditionDatasource]);

	useEffect(() => {
		let sub = taskDatasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && response.actionType === DatasourceActionType.GET)
				setOpenParentTasks(response.normalizedMainModelResponse);
			if (response.success && response.actionType === DatasourceActionType.PUT) {
				onUpdate(response.normalizedMainModelResponse);
				onRequestClose();
			}
		});
		return () => sub.unsubscribe();
	}, [taskDatasource, onRequestClose, onUpdate]);

	// API call
	useEffect(() => {
		if (!taskId) return;

		conditionDatasource.get("v2", `tasks/${taskId}/conditions`, {
			queryStringParameters: {
				status: "incompleted",
				sort: conditionDatasource.sortBy,
				per_page: conditionDatasource.perPage,
				page: conditionDatasource.currentPage,
				fields: JSON.stringify({
					condition: ["id", "name"],
				}),
			},
		});
	}, [
		taskId,
		lastMoment,
		conditionDatasource,
		conditionDatasource.sortBy,
		conditionDatasource.currentPage,
		conditionDatasource.perPage,
	]);

	useEffect(() => {
		if (!taskId) return;

		taskDatasource.get("v2", `tasks/${taskId}/parent-tasks`, {
			queryStringParameters: {
				status: "open",
				sort: taskDatasource.sortBy,
				per_page: taskDatasource.perPage,
				page: taskDatasource.currentPage,
				fields: JSON.stringify({
					task: ["id", "name"],
					responsible_role: ["name"],
				}),
			},
		});
	}, [taskId, taskDatasource, taskDatasource.sortBy, taskDatasource.currentPage, taskDatasource.perPage]);

	return (
		<Modal
			ariaHideApp={false}
			className={classNames("task-override-modal", "warning")}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="reject"
		>
			<ModalHeader title="task_override_notification_header" onRequestClose={onRequestClose} />
			{isLoading && <LoadingState />}
			{!isLoading && (
				<>
					<section>
						{!!incompleteConditions?.length && (
							<React.Fragment>
								<div className="alert alert-warning">
									<h4>You are about to close a task with {incompleteConditions.length} incomplete conditions</h4>
									<ul className="fa-ul">
										{_.map(incompleteConditions, (condition) => (
											<li key={condition.id}>
												{condition.kind === "document" ? (
													<span>
														A document to complete <strong>{condition.name}</strong>
													</span>
												) : (
													<span>
														An answer to <strong>{condition.name}</strong>
													</span>
												)}
											</li>
										))}
									</ul>

									<p>Leave a comment to say why you are closing this task with incomplete conditions.</p>
								</div>
							</React.Fragment>
						)}

						{!!openParentTasks.length && (
							<React.Fragment>
								<div className="alert alert-warning">
									<h4>You are about to close a task with {openParentTasks.length} open parent task</h4>
									<ul className="fa-ul">
										{_.map(openParentTasks, (task) => (
											<li key={task.name}>
												<strong>{task.name}</strong> is still open (it has not been closed by{" "}
												{task.responsible_role.name})
											</li>
										))}
									</ul>

									<p>Leave a comment to say why you are closing this task with open parent tasks.</p>
								</div>
							</React.Fragment>
						)}

						<TextInput
							name="comment"
							users={taggableUsers}
							label={false}
							defaultState={{ errors, comment }}
							onChange={(comment: string) => {
								setComment(comment);
								setCanPost(comment.replace(/(<([^>]+)>)/gi, "") !== "");
							}}
							required={constraints}
						/>
					</section>
					<footer>
						<Button comment onClick={onSubmit} disabled={!canPost}>
							{translate("close_comment")}
						</Button>
					</footer>
				</>
			)}
		</Modal>
	);
};

export default memo(OverrideModal);
