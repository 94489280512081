import React, { useState, useRef, memo } from "react";
import classNames from "classnames";
import { Upload } from "components/Documents/";

const DefaultActions = memo(({
	resource,
	onClose,
	comment
}: {
	resource: tcpinpoint.Resource;
	comment: tcpinpoint.Comment;
	onClose: (arg: { action: string; file: File }) => void;
}) => (
	<li>
		<Upload
			comment={comment}
			confirmUpload={true}
			resource={resource}
			onUpdate={file => onClose({ action: "document_upload", file })}
		>
			<i className="fas fa-fw fa-file-upload" /> Add a file from your computer
		</Upload>
	</li>
));

const Action = (props: {
	isOpen: boolean;
	onClose: (arg: any) => void;
	resource: tcpinpoint.Resource;
	comment: any;
}) => {
	const { onClose, resource, comment } = props;
	const ref = useRef(null);
	const [action, setAction] = useState(null);
	const [isOpen, setOpen] = useState(props.isOpen);
	return (
		<React.Fragment>
			<button
				className={classNames("button", "btn", "btn-add", "btn-primary", {
					"action-menu-open": isOpen
				})}
				id="action-menu-button"
				type="button"
				onClick={() => setOpen(!isOpen)}
			>
				<i className="fas fa-plus" />
			</button>
			<ul ref={ref} className={classNames({ "action-menu-open": !!isOpen })} id="action-menu">
				{!action && (
					<DefaultActions
						comment={comment}
						resource={resource}
						onClose={action => {
							setAction(null);
							setOpen(false);
							onClose(action);
						}}
					/>
				)}
				{!!action && (
					<React.Fragment>
						<li>
							<button type="button" onClick={() => setAction(null)}>
								<i className="fas fa-fw fa-arrow-left" /> Go Back
							</button>
						</li>
					</React.Fragment>
				)}
			</ul>
		</React.Fragment>
	);
};

export default memo(Action);
