import React, { useState, memo } from "react";
//@ts-ignore
import Modal from "react-modal";
import { ListGroup, ListGroupItem, Button, ModalHeader } from "components/Shared";
import _ from "lodash";
import { Show as Avatar } from "components/Avatar";
import { useDispatch } from "react-redux";
import { postPermission } from "actions";

interface Props extends ReactModal.Props {
	resource: any;
	scope: tcpinpoint.Project | tcpinpoint.Project[];
}

const ShareModal = ({ resource, onRequestClose, scope, isOpen }: Props) => {
	const [users, setUsers] = useState([]);
	const dispatch = useDispatch();

	const onSend = () => {
		_.each(users, user => dispatch(postPermission(resource, user)));
		setUsers([]);
		//@ts-ignore
		onRequestClose();
	};

	const collection = _.compact(_.map(_.flatMap([scope], "permissions"), "user")) as tcpinpoint.User[];

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} className="share-modal" contentLabel="share">
			<ModalHeader title="share" onRequestClose={onRequestClose} />
			<section>
				<ListGroup
					resource="user"
					pagination={false}
					collection={collection}
					renderRow={(user: tcpinpoint.User) => (
						<ListGroupItem
							key={user.id}
							selected={_.includes(_.map(users, "id"), user.id)}
							icon={<Avatar url={user.avatar} name={user.name} />}
							heading={`${user.firstname} ${user.surname}`}
							subheading={_.get(user, "company.name")}
							//@ts-ignore
							onSelect={() => setUsers(_.unionBy([user], users, "id"))}
							onUnselect={() => setUsers(_.filter(users, _user => _user !== user))}
						/>
					)}
				/>
			</section>
			<footer>
				<Button disabled={!users.length} onClick={onSend} />
			</footer>
		</Modal>
	);
};

export default memo(ShareModal);
