import Actor from "components/Activities/Actor";
import Context from "components/Activities/Context";
import ItemObject from "components/Activities/ItemObject";
import Subject from "components/Activities/Subject";
import { ErrorBoundary } from "components/Shared";
import { translate } from "lib";
import React from "react";

const UndoCondition = ({ activity }: { activity: tcpinpoint.ActivityItem }) => (
	<ErrorBoundary>
		<Actor actor={activity.attributes.item.actor} /> {translate("reopened")}{" "}
		<ItemObject object={activity.attributes.item.object} /> {translate("condition")} {translate("for")}{" "}
		<Subject subject={activity.attributes.item.subject} /> {translate("task")}{" "}
		<Context context={activity.attributes.item.context} />
	</ErrorBoundary>
);

export default UndoCondition;
