import { Button, Date, LoadingState } from "components/Shared";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const BooleanCondition = ({ condition, onUpdate, onUpdateMoment }: any) => {
	const rejected_by = useMemo<any>(() => _.get(condition, "rejected_by", {}), [condition]);
	const accepted_by = useMemo<any>(() => _.get(condition, "accepted_by", {}), [condition]);
	const conditionId = useMemo(() => _.get(condition, "id"), [condition]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "condition", renderTrigger: renderTrigger }));

	const accept = useCallback(() => {
		if (!_.isNil(conditionId)){
			datasource.put("v2", `conditions/${conditionId}/accept`, {}, { updateIsLoding: true });
		}
	}, [datasource, conditionId]);

	const reject = useCallback(() => {
		if (!_.isNil(conditionId)){
			datasource.put("v2", `conditions/${conditionId}/reject`, {}, { updateIsLoding: true });
		}
	}, [datasource, conditionId]);

	const undo = useCallback(() => {
		if (!_.isNil(conditionId)){
			datasource.put("v2", `conditions/${conditionId}/reopen`, {}, { updateIsLoding: true });
		}
	}, [datasource, conditionId]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && !_.isNil(onUpdate)){
				onUpdate(response.normalizedMainModelResponse);
				onUpdateMoment();
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, onUpdate, onUpdateMoment]);

	if (datasource.isLoading) return <LoadingState />;

	if (_.isEmpty(condition)) return null;

	return (
		<div className="condition-actions">
			{!condition.closed_at && !condition.completed_at ? (
				<div className="btn-group" role="group">
					<Button kind="yes" className="condition-done" small onClick={accept} />
					<Button kind="no" className="condition-done" small onClick={reject} />
				</div>
			) : (
				<React.Fragment>
					{!!condition.rejected_at && !_.isEmpty(rejected_by) && (
						<React.Fragment>
							<p>{translate("no")}</p>
							<p className="condition-meta muted">
								{rejected_by.firstname} said no <Date date={condition.rejected_at} />
							</p>
						</React.Fragment>
					)}
					{!!condition.accepted_at && !_.isEmpty(accepted_by) && (
						<React.Fragment>
							<p>{translate("yes")}</p>
							<p className="condition-meta muted">
								{accepted_by.firstname} said yes <Date date={condition.accepted_at} />
							</p>
						</React.Fragment>
					)}
					<div className="condition-toolbar">
						<Button onClick={undo} kind="undo" />
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default BooleanCondition;
