import React from "react";
import uuidv4 from "uuid/v4";
import { View, Button } from "components/Shared";
import { Tasks } from "components/Templates";
import { Modal as TaskModal } from "components/Tasks";
import { Modal as PhaseModal } from "components/Phases";
import _ from "lodash";

export default class Phase extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = props.phase;
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { phaseModalOpen, taskModalOpen } = this.state;
    this.setState(_.assign({}, props.phase, { phaseModalOpen, taskModalOpen }));
  }

  addTask(task) {
    const { onUpdate } = this.props;
    const { tasks } = this.state;
    tasks.push({
      id: uuidv4(),
      created_at: new Date().toJSON(),
      description: task.description,
      index: tasks.length + 1,
      name: task.name,
      role: task.role,
      length: task.length || 1,
      spectators: task.spectators || [],
      conditions: task.conditions || [],
      dependencies: task.dependencies || []
    });
    this.setState({ tasks, taskModalOpen: false }, () => onUpdate(this.state));
  }

  updateTasks(tasks) {
    const { onUpdate } = this.props;
    this.setState({ tasks, taskModalOpen: false }, () => onUpdate(this.state));
  }

  render() {
    const { template, phase, onUpdate, onDelete, sortedTasks } = this.props;
    const { phaseModalOpen, taskModalOpen, id, tasks, color, name, description, milestone } = this.state;
    return (
      <View
        key={`${id}-view`}
        id={id}
        title={name}
        data={{ name }}
        icon={
          phase.color && (
            <span
              key="phase-color"
              style={{
                backgroundColor: phase.color,
                width: "16px",
                height: "16px",
                display: "block",
                borderRadius: "16px"
              }}
              className="phase-color"
            />
          )
        }
        resource="phase"
        actions={[
          <Button kind="edit" key="edit" onClick={() => this.setState({ phaseModalOpen: true })}>
            edit_phase
          </Button>
        ]}
      >
        {description && <p className="description">{description}</p>}
        <Tasks
          sortedTasks={sortedTasks}
          phase={phase}
          template={template}
          tasks={_.map(tasks, task => _.assign({}, task, { index: sortedTasks.indexOf(task.id) }))}
          onUpdate={tasks => this.updateTasks(tasks)}
          onAdd={() => this.setState({ taskModalOpen: true })}
        />
        <TaskModal
          template={template}
          phase={{ id, name, description, milestone }}
          isOpen={taskModalOpen}
          doCloseModal={() => this.setState({ taskModalOpen: false })}
          onUpdate={task => this.addTask(task)}
        />
        <PhaseModal
          tasks={tasks}
          phase={{ id, name, description, milestone, color }}
          isOpen={phaseModalOpen}
          doCloseModal={() => this.setState({ phaseModalOpen: false })}
          onDelete={() => onDelete(phase)}
          onUpdate={phase => this.setState(phase, () => onUpdate(this.state))}
        />
      </View>
    );
  }
}
