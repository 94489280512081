import { memoAC } from "./memoize";
import axios from "v2/utils/axios";

export const getWorkflow = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_WORKFLOW_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}workflows/${id}`)
		.then((res) => dispatch({ type: "GET_WORKFLOW_SUCCESS", response: { status: 200 }, resource: res.data.workflow }))
		.catch((e) => { dispatch({ type: "GET_WORKFLOW_FAILED", response: e }); });
});

export const getPortfolioWorkflows = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_WORKFLOWS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios/${id}/workflows`)
		.then((res) => dispatch({ type: "GET_WORKFLOWS_SUCCESS", response: { status: 200 }, resource: res.data.workflows }))
		.catch((e) => { dispatch({ type: "GET_WORKFLOWS_FAILED", response: e }); });
});

export const getProjectWorkflows = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_WORKFLOWS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/${id}/workflows`)
		.then((res) => dispatch({ type: "GET_WORKFLOWS_SUCCESS", response: { status: 200 }, resource: res.data.workflows }))
		.catch((e) => { dispatch({ type: "GET_WORKFLOWS_FAILED", response: e }); });
});
