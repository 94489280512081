import React, { memo } from "react";
import { Title, Header } from "components/Shared";

const THeader = ({ hideCover, name }: any) => {
  return <Header resource="template" hideCover={hideCover}>
    <Title>{name}</Title>
  </Header>
}

export default memo(THeader);
