import React, { memo } from "react";
import _ from "lodash";
import { View, DefinitionItem, DefinitionList, PhoneNumber, Address, Col, Row } from "components/Shared";

import { translate } from "lib";
import { Map } from "components/Widgets";

const Metadata = memo(({ k, value }) => {
  if (k === "opening_hours") {
    return <OpeningHours hours={value} />;
  } else if (k === "phone_number") {
    return <PhoneNumber phone_number={value} />;
  } else if (k === "links") {
    return <Links links={value} />;
  }
  return value;
});

const Links = memo(({ links }) => (
  <ul className="links" key="metadata-links">
    {_.map(
      _.keys(links, link => (
        <li key={link}>
          <a href={links[link]} target="_blank" rel="noopener noreferrer">
            {translate(link)}
          </a>
        </li>
      ))
    )}
  </ul>
));

const OpeningHours = memo(({ hours }) => {
  if (hours) {
    return (
      <table className="opening_hours">
        <tbody>
          {Object.keys(hours).map(key => (
            <tr key={key}>
              <td>{translate(key)}</td>
              <td>{hours[key][0]}</td>
              <td>{hours[key][1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  return null;
});

export default class PropertyDashboard extends React.Component {
  render() {
    const { property } = this.props;
    const {
      managed_by,
      operated_by,
      owned_by,
      metadata,
      gross_lettable_area,
      gross_floor_area,
      addresses,
      status,
      kind,
      net_lettable_area,
      net_occupied_area,
      common_area,
      circulation_area
    } = property;

    return (
      <View resource="details" hideTitle={true}>
        <Row>
          <Col>
            <DefinitionList>
              {!!kind && <DefinitionItem title="kind">{translate(kind)}</DefinitionItem>}
              {!!status && <DefinitionItem title="status">{translate(status)}</DefinitionItem>}
              {!!parseInt(gross_floor_area, 10) && (
                <DefinitionItem title="gross_floor_area">
                  {parseInt(gross_floor_area, 10).toLocaleString()}m²
								</DefinitionItem>
              )}
              {!!parseInt(gross_floor_area, 10) && (
                <DefinitionItem title="gross_floor_area">
                  {parseInt(gross_floor_area, 10).toLocaleString()}m²
								</DefinitionItem>
              )}
              {!!parseInt(gross_lettable_area, 10) && (
                <DefinitionItem title="gross_lettable_area">
                  {parseInt(gross_lettable_area, 10).toLocaleString()}m²
								</DefinitionItem>
              )}
              {(!!parseInt(gross_floor_area, 10) || !!parseInt(gross_lettable_area, 10)) && <hr />}
              {!!parseInt(common_area, 10) && (
                <DefinitionItem title="common_area">{parseInt(common_area, 10).toLocaleString()}m²</DefinitionItem>
              )}
              {!!parseInt(circulation_area, 10) && (
                <DefinitionItem title="circulation_area">
                  {parseInt(circulation_area, 10).toLocaleString()}m²
								</DefinitionItem>
              )}
              {!!parseInt(net_occupied_area, 10) && (
                <DefinitionItem title="net_occupied_area">
                  {parseInt(net_occupied_area, 10).toLocaleString()}m²
								</DefinitionItem>
              )}
              {!!parseInt(net_lettable_area, 10) && !(net_lettable_area === gross_lettable_area) && (
                <DefinitionItem title="net_lettable_area">
                  {parseInt(net_lettable_area, 10).toLocaleString()}m²
								</DefinitionItem>
              )}
              {(!!parseInt(common_area, 10) ||
                !!parseInt(circulation_area, 10) ||
                !!parseInt(net_occupied_area, 10) ||
                (!!parseInt(net_lettable_area, 10) && !(net_lettable_area === gross_lettable_area))) && <hr />}
              {!!owned_by && (
                <DefinitionItem title="owned_by">
                  {owned_by.name}
                </DefinitionItem>
              )}
              {!!managed_by && (
                <DefinitionItem title="managed_by">
                  {managed_by.name}
                </DefinitionItem>
              )}
              {!!operated_by && (
                <DefinitionItem title="developed_by">
                  {operated_by.name}
                </DefinitionItem>
              )}
              {metadata &&
                Object.keys(metadata).map(key => (
                  <DefinitionItem key={key} title={key}>
                    <Metadata k={key} value={metadata[key]} />
                  </DefinitionItem>
                ))}
            </DefinitionList>
          </Col>
          {!!addresses && (
            <Col>
              <Map
                addresses={property.addresses}
                buildings={_.map(property.buildings, building => ({
                  name: building.name,
                  coordinates: building.shape
                }))}
                height="394px"
              />
              <hr />
              <DefinitionList>
                {_.map(addresses, a => (
                  <DefinitionItem title={_.get(a, "locality.name")} key={a.id}>
                    <Address key={a.id} address={a} />
                  </DefinitionItem>
                ))}
              </DefinitionList>
            </Col>
          )}
        </Row>
      </View>
    );
  }
}
