import { faReply, faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Show as Avatar } from "components/Avatar";
import { Date, Date as DateComponent, Flyout, LoadingState, OpenGraph, Text } from "components/Shared";
import User from "components/Users/Inline";
import { IsLoadingContext, UserContext } from "context";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CommentContext } from "./CardDynamic";
import ReactTooltip from "react-tooltip";

// Twemoji
import { thumbsUp, clap, heart, shocked, think, fire } from "utils/twemoji/twemoji";

const CommentDeleteButton = ({ comment, onDelete }: any) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const { setIsLoading } = useContext(IsLoadingContext);
	const { id, created_by_id } = comment;
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "comment",
			renderTrigger: renderTrigger,
		})
	);

	const isCreator = useCallback(() => created_by_id === _.get(currentUser, "id"), [created_by_id, currentUser]);
	const deleteComment = () => {
		setIsLoading(true);
		datasource
			.del("v2", `comments/${id}`, {}, { updateIsLoding: true })
			.then(() => (comment.deleted_at = moment()))
			.catch()
			.finally(() => setIsLoading(false));
	};

	if (!isCreator()) return null;

	return (
		<Flyout
			menu={[
				<button onClick={deleteComment}>
					<i className="fas fa-fw fa-delete" />
					{translate("delete")}
				</button>,
			]}
		/>
	);
};

const CommentReplyButton = ({ comment }: any) => {
	const { setContext } = useContext(CommentContext);
	const onReply = () => {
		setContext({ replyToComment: comment });
		setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 200);
	};

	return (
		<button onClick={onReply} className="comment-reply-link">
			<FontAwesomeIcon icon={faReply} /> {translate("reply")}
		</button>
	);
};

const Comment = ({ comment }: any) => {
	const { currentUser } = useContext(UserContext) as any;
	const { context, setContext } = useContext(CommentContext);
	const [isLoading, setIsLoading] = useState(false);
	const isLoadingContextValue = { isLoading, setIsLoading };
	const currentCommentRef = useRef(null);
	const [isHighlight, setIsHighlight] = useState(false);

	const [likeReact, setLikeReact] = useState(0);
	const [clapReact, setClapReact] = useState(0);
	const [fireReact, setFireReact] = useState(0);
	const [heartReact, setHeartReact] = useState(0);
	const [shockReact, setShockReact] = useState(0);
	const [thinkReact, setThinkReact] = useState(0);

	const [likeReactTracker, setLikeReactTracker] = useState(false);
	const [clapReactTracker, setClapReactTracker] = useState(false);
	const [fireReactTracker, setFireReactTracker] = useState(false);
	const [heartReactTracker, setHeartReactTracker] = useState(false);
	const [shockReactTracker, setShockReactTracker] = useState(false);
	const [thinkReactTracker, setThinkReactTracker] = useState(false);

	const [reactorListsData, setReactorListsData] = useState([]) as any;

	const [datasource] = useState(
		new Datasource({
			mainModelName: "comment",
		})
	);

	const [datasource2] = useState(
		new Datasource({
			mainModelName: "reaction",
		})
	);

	// subscribe datasource2 subject
	useEffect(() => {
		datasource2.get("v2", `comments/${comment.id}/reaction`, {});
		let sub = datasource2.responseSubject$.subscribe((response: DatasourceResponse) =>
			setReactorListsData(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [comment.id, setReactorListsData, datasource2]);

	const reactionIcons = [
		{
			id: 1,
			icon: thumbsUp,
			alt: "Thumbs Up",
		},
		{
			id: 2,
			icon: clap,
			alt: "Clamp",
		},
		{
			id: 3,
			icon: fire,
			alt: "Fire",
		},
		{
			id: 4,
			icon: heart,
			alt: "Heart",
		},
		{
			id: 5,
			icon: shocked,
			alt: "Shocked",
		},
		{
			id: 6,
			icon: think,
			alt: "Thinking",
		},
	];

	const reactorLists = (reactor: number): any => {
		const filteredReacts = reactorListsData.filter((item: any) => {
			return item.reaction_type === reactor;
		});

		return filteredReacts.map((react: any, i: number) => {
			return (
				<span key={i}>
					{react.user.name} <br />
				</span>
			);
		});
	};

	const reaction = (iconId: number): any => {
		let reactTracker = true;
		if (iconId === 1) {
			reactTracker = likeReactTracker;
			likeReactTracker ? setLikeReact(likeReact - 1) : setLikeReact(likeReact + 1);
			setLikeReactTracker(!likeReactTracker);
		} else if (iconId === 2) {
			reactTracker = clapReactTracker;
			clapReactTracker ? setClapReact(clapReact - 1) : setClapReact(clapReact + 1);
			setClapReactTracker(!clapReactTracker);
		} else if (iconId === 3) {
			reactTracker = fireReactTracker;
			fireReactTracker ? setFireReact(fireReact - 1) : setFireReact(fireReact + 1);
			setFireReactTracker(!fireReactTracker);
		} else if (iconId === 4) {
			reactTracker = heartReactTracker;
			heartReactTracker ? setHeartReact(heartReact - 1) : setHeartReact(heartReact + 1);
			setHeartReactTracker(!heartReactTracker);
		} else if (iconId === 5) {
			reactTracker = shockReactTracker;
			shockReactTracker ? setShockReact(shockReact - 1) : setShockReact(shockReact + 1);
			setShockReactTracker(!shockReactTracker);
		} else if (iconId === 6) {
			reactTracker = thinkReactTracker;
			thinkReactTracker ? setThinkReact(thinkReact - 1) : setThinkReact(thinkReact + 1);
			setThinkReactTracker(!thinkReactTracker);
		}

		toogleReactorListsData(iconId, reactTracker);

		datasource.post(
			"v2",
			`comments/${comment.id}/reaction`,
			{
				body: {
					comment_id: comment.id,
					user_id: currentUser.id,
					reaction_type: iconId,
				},
			},
			{ updateIsLoding: true }
		);
	};

	const toogleReactorListsData = (reactType: number, remove: boolean) => {
		if (!remove) {
			setReactorListsData([
				...reactorListsData,
				{
					from_list_data: reactType,
					reaction_type: reactType,
					user: { name: `${currentUser.firstname} ${currentUser.surname}` },
				},
			]);
		} else {
			const filterByReactType = reactorListsData.filter((item: any) => {
				return item.reaction_type === reactType;
			});

			const reactListFiltered = filterByReactType.filter(
				(item: any) => item.user.name !== `${currentUser.firstname} ${currentUser.surname}`
			);

			setReactorListsData([
				...reactorListsData.filter((item: any) => item.reaction_type !== reactType),
				...reactListFiltered,
			]);
		}
	};

	const Reactionlist = () => {
		return (
			<ul>
				{reactionIcons.map((icon, idx): any => (
					<li className="liEmoji" key={idx} onClick={() => reaction(icon.id)}>
						{/* <Twemoji options={{ className: "twemoji2" }}>{icon.icon}</Twemoji> */}
						<img src={icon.icon} alt={icon.alt} />
					</li>
				))}
			</ul>
		);
	};

	useEffect(() => {
		const reactCounter: Record<number, number> = {};

		if (comment.reactions && comment.reactions.length > 0) {
			comment.reactions.forEach((r: any) => {
				const reactionType = r.reaction_type;
				reactCounter[reactionType] = (reactCounter[reactionType] || 0) + 1;
				if (r.user_id === currentUser.id) {
					switch (reactionType) {
						case 1:
							setLikeReactTracker(true);
							break;
						case 2:
							setClapReactTracker(true);
							break;
						case 3:
							setFireReactTracker(true);
							break;
						case 4:
							setHeartReactTracker(true);
							break;
						case 5:
							setShockReactTracker(true);
							break;
						case 6:
							setThinkReactTracker(true);
							break;
						default:
							break;
					}
				}
			});

			if (reactCounter[1] > 0) {
				setLikeReact(reactCounter[1]);
			}
			if (reactCounter[2] > 0) {
				setClapReact(reactCounter[2]);
			}
			if (reactCounter[3] > 0) {
				setFireReact(reactCounter[3]);
			}
			if (reactCounter[4] > 0) {
				setHeartReact(reactCounter[4]);
			}
			if (reactCounter[5] > 0) {
				setShockReact(reactCounter[5]);
			}
			if (reactCounter[6] > 0) {
				setThinkReact(reactCounter[6]);
			}
		}
	}, [comment.reactions, currentUser.id]);

	useEffect(() => {
		let _comment_id = new URLSearchParams(window.location.search).get("_comment_id");
		if ((_.get(context, "highlightCommentId") === comment.id && currentCommentRef) || _comment_id === comment.id) {
			window.scrollTo(0, _.get(currentCommentRef, "current.offsetTop"));
			setIsHighlight(true);
			setTimeout(() => setIsHighlight(false), 3000);
		}
	}, [context, setContext, currentCommentRef, comment.id, comment.reactions, currentUser.id]);

	if (!comment) return null;

	if (_.isNil(comment.global_id)) {
		return (
			<div key={comment.created_at} className={`list-group-item event event-${comment.name}`}>
				{translate(comment.name)} by <User user={comment.user} /> <DateComponent date={comment.created_at} />
			</div>
		);
	}

	if (isLoading) return <LoadingState />;

	const reactStates = new Map();

	reactStates.set(1, {
		state: likeReact,
		tooltipId: `like-${comment.id}`,
		emoji: "👍",
	});
	reactStates.set(2, {
		state: clapReact,
		tooltipId: `clap-${comment.id}`,
		emoji: "👏",
	});
	reactStates.set(3, {
		state: fireReact,
		tooltipId: `fire-${comment.id}`,
		emoji: "🔥",
	});
	reactStates.set(4, {
		state: heartReact,
		tooltipId: `heart-${comment.id}`,
		emoji: "💖",
	});
	reactStates.set(5, {
		state: shockReact,
		tooltipId: `shock-${comment.id}`,
		emoji: "😲",
	});
	reactStates.set(6, {
		state: thinkReact,
		tooltipId: `think-${comment.id}`,
		emoji: "🤔",
	});

	return (
		<React.Fragment>
			<div
				ref={currentCommentRef}
				id={`comment-${comment.id}`}
				className={classNames("list-group-item", "comment", {
					deleted: !!comment.deleted_at,
					"is-reply": !!comment.reply_to_id,
					"comment-anonymous": !comment.created_by,
					hightlight: isHighlight,
				})}
			>
				{!!comment.deleted_at ? (
					<div className="list-group-item-deleted">
						Comment by <User user={comment.created_by} /> deleted <Date date={comment.deleted_at} />
					</div>
				) : (
					<React.Fragment>
						<div className="list-group-item-avatar">
							<Avatar url={_.get(comment, "created_by.avatar")} name={_.get(comment, "created_by.name")} />
						</div>
						<div className="list-group-item-body">
              {
                // This is used to allow multiple links (instead of 1) displayed in comment when uploads from TCP Outlook addin
                // As there is no restriction on how many documents can be uploaded via TCP Outlook addin
                Array.isArray(comment.metadata) ? 
                comment.metadata.map((each_metadata: any) => {
                  return <OpenGraph metadata={each_metadata} />
                }) : <OpenGraph metadata={comment.metadata} />
              }

							<div className="relative comment">
								<span className="comment-timestamp">
									{translate("posted_by")} <User user={comment.created_by} />{" "}
									<Date date={comment.created_at} showTime showRelative />
								</span>

								<div className="flex items-center justify-start space-x-2 comment-text">
									<Text>{`${comment.body}`.replace(/&amp;#x(\d+);/g, (match, dec) => String.fromCharCode(dec))}</Text>
								</div>
							</div>

							<span className="flex items-center space-x-1">
								{Array.from(reactStates.entries()).map(([id, { state, tooltipId, emoji }]) =>
									state ? (
										<React.Fragment key={id}>
											<ReactTooltip
												id={tooltipId}
												place="top"
												backgroundColor="grey"
												effect="solid"
												getContent={() => reactorLists(id)}
											/>
											<button data-tip data-for={tooltipId} className="comment-react-icon" onClick={() => reaction(id)}>
												{/* <Twemoji tag="span" options={{ className: "twemoji" }}>
													{emoji} <span className="text-xs font-medium text-gray-200">{state}</span>
												</Twemoji> */}
												<span>
													{emoji} {state}
												</span>
											</button>
										</React.Fragment>
									) : (
										<React.Fragment key={id}></React.Fragment>
									)
								)}
							</span>

							<div className="flex items-center justify-start space-x-2 mt-3">
								<CommentReplyButton comment={comment} />
								<div className="react">
									<div className="react-me">
										<FontAwesomeIcon icon={faSmile} className="comment-add-react" />
										<div className="inner">
											<div className="react-box">
												<Reactionlist />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<IsLoadingContext.Provider value={isLoadingContextValue}>
							<CommentDeleteButton comment={comment} onDelete={setIsLoading} />
						</IsLoadingContext.Provider>
					</React.Fragment>
				)}
			</div>
			{!comment.reply_to_id &&
				_.map(comment.recrusived_replies, (reply: any) => <Comment key={reply.id} comment={reply} />)}
		</React.Fragment>
	);
};

export default memo(Comment);
