
import React from "react";
import { translate } from "lib";
import { ErrorBoundary } from "components/Shared";
import Actor from "components/Activities/Actor"
import Subject from "components/Activities/Subject"
import Context from "components/Activities/Context"
import ItemObject from "components/Activities/ItemObject"

const Update = ({ activity }: { activity: tcpinpoint.ActivityItem }) => <ErrorBoundary>
    <Actor actor={activity.attributes.item.actor} />{' '}
    {translate('updated')}{' '}
    <ItemObject object={activity.attributes.item.object} />{" "}
    <Subject subject={activity.attributes.item.subject} />{" "}
    {activity.attributes.item.object.link !== activity.attributes.item.context.link && <Context context={activity.attributes.item.context} />}
</ErrorBoundary>

export default Update;