import React, { memo, useState } from "react";
import Datasource, { DatasourceResponse } from "lib/datasource";
import { components } from "react-select";
import { Label } from "components/Inputs";
import classNames from "classnames";
import AsyncSelect from "react-select/async";
import _ from "lodash";

const Option = (props: any) => {
	const option = _.find(props.options, { value: props.value });
	return (
		<components.Option {...props}>
			<dt>{_.get(option, "item.name")}</dt>
			<dd>
				{_.compact([_.get(option, "item.locality_name", false), _.get(option, "item.region_name", false)]).join(", ")}
			</dd>
		</components.Option>
	);
};

const Lookup = ({ name, className, children, required, defaultState, label, onChange }: any) => {
	const [datasource] = useState(
		new Datasource({
			mainModelName: "property",
			perPage: -1,
			currentPage: 1,
			sortBy: "name"
		})
	);

	const loadOptions = (inputValue: any, callback: any) => {
		if (inputValue.length < 3) return;

		datasource
			.get("v2", "properties", {
				queryStringParameters: {
					q: inputValue,
					sort: "name",
					per_page: datasource.perPage,
					page: datasource.currentPage
				}
			})
			.then((response: DatasourceResponse) => {
				callback(
					_.map(_.uniqBy(response.normalizedMainModelResponse, "id"), (item: any) => ({
						value: item.id,
						label: `${item.name} ${_.defaultTo(_.get(item, "address_name"), "")}`,
						item
					}))
				);
			});
	};

	const [debouncedLoadOptions] = useState(() => _.debounce(loadOptions, 200));

	return (
		<div
			className={classNames("form-group", `form-control-${name}`, className, {
				className,
				"is-required": _.get(required, "property", false) || _.get(required, "property_id", false),
				"has-danger": _.get(defaultState, "errors.property") || _.get(defaultState, "errors.property_id")
			})}
		>
			<Label name={label || name || "property"} />

			<AsyncSelect
				cacheOptions
				name={name}
				placeholder="Start typing to search…"
				autoFocus={false}
				classNamePrefix="react-select"
				components={{ Option }}
				loadOptions={debouncedLoadOptions}
				onInputChange={(newValue: string) => {
					return newValue;
				}}
				onChange={(option: any) => {
					onChange(option.item);
				}}
				defaultInputValue={defaultState.name}
			/>

			{children && children}
		</div>
	);
};

export default memo(Lookup);
