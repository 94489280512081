import classNames from "classnames";
import { Item as Document } from "components/Documents";
import { ListGroup, ListGroupControl, LoadingState } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";

interface Props {
	queryStringParameters?: {};
	onAdd?: (arg0: any) => void;
	onLoad?: (documents: any) => void; // callback for on documents load from backend enpoint
	docDispatch?: any;
	lastMoment?: any;
}

const Documents = ({ onAdd, queryStringParameters, onLoad, docDispatch, lastMoment }: Props) => {
	const [viewBy, setViewBy] = useState("grid");
	const [filteredBy, setFilteredBy] = useState("all");
	const [documents, setDocuments] = useState([] as tcpinpoint.Document[]);
	const getSortAttribute = (sortBy: string) => (sortBy === "created_at" ? "-created_at" : sortBy);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "document",
			perPage: 20,
			currentPage: 1,
			sortBy: "created_at",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setDocuments(response.normalizedMainModelResponse);
			if (!!onLoad) onLoad(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource, onLoad, docDispatch, lastMoment]);

	// API call
	useEffect(() => {
		datasource.get("v2", "documents", {
			queryStringParameters: _.extend({}, queryStringParameters, {
				owner: filteredBy,
				sort: getSortAttribute(datasource.sortBy),
				per_page: datasource.perPage,
				page: datasource.currentPage
			})
		});
	}, [
		datasource,
		datasource.currentPage,
		datasource.perPage,
		datasource.sortBy,
		filteredBy,
		queryStringParameters,
		docDispatch,
		lastMoment
	]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<React.Fragment>
			<ListGroupControl
				selectedFilter={filteredBy}
				filterOptions={["all", "mine"]}
				sortOptions={["created_at", "name"]}
				onViewChange={setViewBy}
				selectedView={viewBy}
				viewOptions={["list", "grid"]}
				onSortChange={newSortBy => {
					datasource.setCurrentPage(1);
					datasource.setSortBy(newSortBy);
				}}
				selectedSort={datasource.sortBy}
				onFilterChange={newFilterBy => {
					datasource.setCurrentPage(1);
					setFilteredBy(newFilterBy);
				}}
			/>
			<ListGroup
				resource={"documents"}
				collection={documents}
				onAdd={onAdd}
				pagination={false}
				renderRow={document => <Document key={document.id} document={document}/>}
				className={classNames("documents", "document-list-group", `documents-${viewBy}-view`)}
			/>
			<div className={classNames("margin-atuo", "flex justify-center")}>
				<Pagination datasource={datasource} />
			</div>
		</React.Fragment>
	);
};

export default memo(Documents);
