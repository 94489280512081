import React, { memo } from "react";
import moment from "moment";
import classNames from "classnames";

const RelativeDate = (props: any) => {
	const { date } = props;
	return (
		<span key={moment(date).toJSON()} className={classNames("relative-date")} data-date={moment(date).toJSON()}>
			{moment(date).fromNow()}
		</span>
	);
};

export default memo(RelativeDate);
