import React, { memo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Date as DateComponent, ListGroupItem } from "components/Shared";
import { Icon } from "components/Documents";
//@ts-ignore
import filesize from "filesize";
import { translate } from "lib";
import _ from "lodash";

const Document = ({ document }: { document: tcpinpoint.Document }) => {
	const upload = useSelector((store: tcpinpoint.Store) => store.uploads[document.id], shallowEqual);
	const [type, subtype] = `$document.mimetype}`.split("/");
	// const documentExpires = !!_.get(document, "expires_at");
	// const documentExpired =
	// 	new Date(_.get(document, "expires_at")).setUTCHours(0, 0, 0, 0) < new Date().setUTCHours(0, 0, 0, 0) &&
	// 	!!_.get(document, "expires_at");
	if (!!upload && upload.state === "PROGRESS") {
		const uploadStatus = upload as tcpinpoint.DocumentUpload;
		const loadedFilesize = filesize(uploadStatus.loaded || 9999, { round: 1 });
		const totalFilesize = filesize(uploadStatus.total || 9999, { round: 1 });
		let remaining;
		if (uploadStatus.remainingSeconds > 60) {
			const minutes = Math.round(uploadStatus.remainingSeconds / 60);
			remaining = `${minutes} ${translate("minute")} ${translate("left")}`;
		} else if (uploadStatus.remainingSeconds) {
			remaining = `${Math.ceil(uploadStatus.remainingSeconds / 10) * 10} ${translate("seconds")} ${translate("left")}`;
		} else {
			remaining = translate("finishing");
		}

		return (
			<ListGroupItem
				heading={document.display_name || document.name}
				secondHeading={document.created_by.full_name}
				subheading={<DateComponent date={document.created_at} />}
				icon={<Icon {...{ document }} />}
				className={[
					"document",
					"document-uploading",
					type,
					subtype
					// { "document-expires": documentExpires, "document-expired": !!documentExpired }
				]}
			>
				<div className="upload-status">
					<div className="progress">
						<div
							className="progress-bar"
							role="progressbar"
							style={{ width: `${uploadStatus.percentComplete}%` }}
							aria-valuenow={uploadStatus.percentComplete}
							aria-valuemin={0}
							aria-valuemax={100}
						/>
					</div>
					<div className="document-uploading-size">
						{uploadStatus.total !== uploadStatus.loaded && `${loadedFilesize} of `}
						{totalFilesize}
					</div>
					<div className="document-uploading-estimated-seconds">{remaining}</div>
				</div>
			</ListGroupItem>
		);
	}
	let thumbnail: string | null = null;
	if (_.includes(document.mimetype, "image") && _.get(document.metadata, "thumbnail")) {
		thumbnail = `${_.get(document.metadata, "thumbnail")}`;
	}

	return (
		<ListGroupItem
			thumbnail={thumbnail}
			heading={document.display_name || document.name}
			subheading={<DateComponent date={document.created_at} />}
			secondHeading={document.created_by.full_name}
			icon={<Icon {...{ document }} />}
			link={`/documents/${document.id}`}
			data={{ thumbnail, mimetype: document.mimetype, type, subtype }}
			className={[
				"document",
				type,
				subtype,
				{
					"has-thumbnail": !!thumbnail
					// "document-expires": documentExpires,
					// "document-expired": !!documentExpired
				}
			]}
			datapoints={[
				document.deleted_at && {
					key: "deleted",
					value: <DateComponent date={document.deleted_at} showTime={false} />
				},
				// documentExpires && {
				// 	key: documentExpired ? "expired" : "expires",
				// 	value: <DateComponent date={document.expires_at} showTime={false} />
				// },
				document.filesize_bytes !== 0 && filesize(document.filesize_bytes || 0, { round: 1 })
			]}
		/>
	);
};

export default memo(Document);
