import { Row } from "components/Conditions";
import { ListGroup, LoadingState, View } from "components/Shared";
import Datasource, { DatasourceResponse } from "lib/datasource";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";

const List = ({ taskId, onUpdate, lastMoment }: any) => {
	const [conditions, setConditions] = useState([] as any[]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "condition",
			sortBy: "created_at",
			perPage: -1,
			currentPage: 1,
			renderTrigger: renderTrigger
		})
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setConditions(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		if (!taskId) return;

		datasource.get("v2", `tasks/${taskId}/conditions`, {
			queryStringParameters: {
				sort: datasource.sortBy,
				per_page: datasource.perPage,
				page: datasource.currentPage,
				fields: JSON.stringify({
					task: ["id", "global_id"],
					accepted_by: ["firstname", "surname", "name"],
					rejected_by: ["firstname", "surname", "name"],
					dismissed_by: ["firstname", "surname", "name"],
					closed_by: ["firstname", "surname", "name"]
				})
			}
		});
	}, [taskId, datasource, datasource.sortBy, datasource.currentPage, datasource.perPage, lastMoment]);

	if (datasource.isFirstTimeLoading) return <LoadingState />;

	return (
		<View>
			<ListGroup
				resource="conditions"
				collection={conditions}
				renderRow={condition => (
					<Row key={condition.id} condition={condition} task={condition.task as tcpinpoint.Task} onUpdateMoment={onUpdate} lastMoment={lastMoment} />
				)}
			/>
		</View>
	);
};

export default memo(List);
