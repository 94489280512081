import React from "react";
//@ts-ignore
import AsyncCreatableSelect from 'react-select/async-creatable';
import classNames from "classnames";
import { Label } from "components/Inputs";
import _ from "lodash";
import axios from "v2/utils/axios";

// interface Props {
//   name: string;
//   collection: "business_codes";
//   className?: string | string[];
//   required?: any;
//   showIf?: boolean;
//   defaultState: any;
//   onChange: any;
// }
interface Item {
  id: string;
  name: string;
}

/**
 * Lookup a value from a `collection`
 *
 * @param name Input Name
 * @param collection Collection to lookup against
 * @param className additional classnames
 * @param required constraints object
 * @param showIf only show input when true
 * @param defaultState default values object
 * @param onChange on change callback
 */

const Lookup = (props: any) => {
  const { collection, name, showIf, required, className, defaultState, onChange } = props;
  if (showIf) {
    const loadOptions = (inputValue: string, callback: any) => {
      setTimeout(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}${collection}`, { params: { q: inputValue } })
          .then((res) => 
            callback(
            _.map(_.uniq(res.data.json), (item: Item) => {
              return {
                value: item.id,
                label: item.name,
                item
              };
            }))
          );
      }, 1000);


    };

    return (
      <div
        className={classNames("form-group", "form-control-" + name, {
          className,
          "is-required": required && required[name],
          "has-danger": defaultState && defaultState.errors && defaultState.errors[name]
        })}
      >
        <Label name={name} />
        <AsyncCreatableSelect
          loadOptions={loadOptions}
          name={name}
          value={defaultState[name]}
          cacheOptions
          onChange={onChange}
        />
      </div>
    );
  }
  return null;
};

export default Lookup;
