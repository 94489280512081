const awsconfig = {
	Auth: {
		region: "ap-southeast-2",
		userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
		mandatorySignIn: true,
		oauth: {
			domain: "login.tcpinpoint.com",
			scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
			redirectSignIn: "https://app.tcpinpoint.com/auth",
			redirectSignOut: "https://app.tcpinpoint.com/signout",
			responseType: "code"
		}
	},
	Storage: {
		bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
		region: "ap-southeast-2"
	}
}

export default awsconfig;