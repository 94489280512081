import Progress from "components/Visualisations/Progress/Progress2";
import WorkflowVisual from "components/Workflows/WorkflowVisual2";
import { translate } from "lib";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";

interface Props {
	workflow: any;
	displayLeaseStatus?: boolean
	displayHandoverAt?: boolean
}

const PhaseReportWorkflowRow = ({ workflow, displayLeaseStatus = false, displayHandoverAt = true }: Props) => {
	if (_.isNil(workflow)) return <></>;

	return (
		<>
			<div className="report-item" key={workflow.project_id}>
				<header className="item-header">
					<h4>
						<Link to={`/projects/${workflow.project_id}`}>{workflow.project_name}</Link>
					</h4>
					<span> - </span>
					{!!workflow.property_id && (
						<h4>
							<Link to={`/properties/${workflow.property_id}`}>{workflow.property_name}</Link>
						</h4>
					)}
					{displayLeaseStatus && !_.isEmpty(workflow.lease_status) && (
						<>
							<span> - </span>
							<h4>{translate(workflow.lease_status)}</h4>
						</>
					)}
					{displayHandoverAt && !_.isEmpty(workflow.lease_handover_at) && (
						<>
							<span> - </span>
							<h4>Hand over: {translate(workflow.lease_handover_at)}</h4>
						</>
					)}
				</header>
				<div className="workflow-visual" style={{ marginBottom: "10px" }}>
					<WorkflowVisual
						key={`${workflow.project_id}-workflow`}
						phases={workflow.phases}
						onReport={true}
						projectId={workflow.project_id}
					/>
				</div>
				<Progress width="100%" type="project" value={workflow.progress} className="ahead" />
			</div>
		</>
	);
};

export default memo(PhaseReportWorkflowRow);
