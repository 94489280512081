import React, { useState, memo } from "react";
import classNames from "classnames";
import { validateFormItem } from "v2/utils";
import Input from "./Input";
import { useFormContext } from "./hooks";
import Agree from "./Agree";

interface Rule {
	message: string;
	validator: (value: any) => any;
}

interface FormItemProps {
	label?: string;
	type: string;
	name: string;
	info?: string;
	hint?: string;
	placeholder?: string;
	value?: any;
	predefined?: boolean;
	wordBreakHint?: boolean;
	confirm?: boolean;
	onChange?: any;
	rules?: Rule[];
	error?: any;
	setError?: (props: any) => any;
	loading?: boolean;
}

const Item = ({
	label = "",
	type,
	name,
	info,
	hint,
	placeholder,
	confirm = false,
	predefined = false,
	wordBreakHint = true,
	value = "",
	rules,
	loading
}: FormItemProps) => {
	const [focused, setFocused] = useState(false);
	const [eyeHover, setEyeHover] = useState(false);
	const { data, setData, error, setError } = useFormContext();
	const onChange = (value: any) => {
		setData!({ ...data, [name]: value });
	};

	const validate = (value: any) => {
		const message = validateFormItem(value, rules);
		setError && setError({ ...error, [name]: message });
	};
	const hintStyle = wordBreakHint ? "text-black-60 text-12 leading-4 mt-1 break-all text-left" : "text-black-60 text-12 leading-4 mt-1 text-left";
	const errorStyle =  wordBreakHint ? "text-error text-12 leading-4 mt-1 break-all text-left font-medium" : "text-error text-12 leading-4 mt-1 text-left font-medium";
	return (
		<div className="my-3">
			{/* TODO: validation */}
			{/* type: checkbox */}
			{type === "checkbox" && <Agree value={value} onChange={onChange} />}

			{/* type: not checkbox */}
			{type !== "checkbox" && (
				<label htmlFor={type} className="flex flex-col">
					{/* label area */}
					<div
						className={classNames("text-12 leading-4 mb-1", {
							"text-black-60": !confirm,
							"text-blue-100": !!focused,
							"text-black-100": !!confirm,
							"font-medium": !!confirm,
							"text-16": !!confirm,
							"text-error": !!error[name] && !confirm && !focused && !eyeHover
						})}
					>
						{label}
					</div>

					{/* input area */}

					{/* confirm */}
					{!!confirm && <div className="text-black-100 text-16 leading-6 h-8 font-normal">{value}</div>}

					{/* predefined */}
					{!!predefined && <div className="text-black-100 text-16 leading-6 h-8 font-normal">{value}</div>}

					{/* not confirm and not predefined: need validation */}
					{!confirm && !predefined && (
						<div
							className={classNames(
								"input-wrapper",
								"border border-black-60 rounded-2",
								"px-3 h-9",
								"flex justify-center items-center",
								"caret-primary text-16 leading-6 tracking-normal text-black-100",
								"focus-within:border-blue-100",
								{
									"border-error": !!error[name] && !eyeHover
								}
							)}
						>
							<Input
								type={type}
								name={name}
								defaultType={type}
								value={value}
								onChange={onChange}
								error={error}
								setError={setError}
								validate={validate}
								placeholder={placeholder}
								focused={focused}
								setFocused={setFocused}
								setEyeHover={setEyeHover}
								disabled={loading}
							/>
						</div>
					)}

					{/* info/error/hints */}
					{!!error[name] && !focused && !eyeHover ? null : (
						<div className="text-black-60 text-12 leading-4 mt-1 break-all text-right">{info}</div>
					)}
					{!!error[name] && !focused && !eyeHover ? null : (
						<div className={hintStyle}>{hint}</div>
					)}
					{!!error[name] && !confirm && !focused && !eyeHover && (
						<div className={errorStyle}>{error[name]}</div>
					)}
				</label>
			)}
		</div>
	);
};

export default memo(Item);
