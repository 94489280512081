import _ from "lodash";
export default (state = [], action: any) => {
	switch (action.type) {
		case "GET_USER_FEED_SUCCESS":
			return _.assign({}, state, { user: action.activities.data as {}[] });
		case "GET_RESOURCE_FEED_SUCCESS":
			return _.assign({}, state, { [action.resource.link]: action.activities.data as {}[] });
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
