import React from "react";
import {View} from "../Shared";
import  ButtonDropDown  from '../Shared/ButtonDropDown';

export const TodoFilterBlock = ({
    status, 
    assigneeName, 
    projectName, 
    allProjectList, 
    allPropertyList, 
    allAssigneeList,
    propertyName, 
    StatusCallBack,
    projectCallBack,
    propertyCallBack,
    setAssigneeName,
    todoTypeCallBack,
    globalTodoType,
    statusOptions,
    todoTypeOptions,
    }: any) => {

    return (
        <View>
            <div className="flex m-auto justify-between"
                style={{
                    width: '100%',
                    maxWidth: '1024px',
                }}
            >

                <div className="flex flex-col">
                    <span className="text-reportProjectSubtitle font-semibold pb-2 text-16 ">
                        Status
                    </span>
                    <ButtonDropDown 
                        buttonOption={status}
                        dropdownOptions={statusOptions}
                        callBack={(arg) => {
                            StatusCallBack(arg);
                        }}
                        textClassNames="w-150 h-50 text-14 transition rounded-l-md text-left py-4 pl-2 truncate"
                        dropDownArrowClassNames="bg-white border-borderColor border-l"
                        dropDownClassNames="w-200 text-black"
                    />
                </div>
                
                <div className="flex flex-col">
                    <span className="text-reportProjectSubtitle font-semibold pb-2 text-16">
                        Assignee
                    </span>
                    <ButtonDropDown 
                        buttonOption={assigneeName}
                        dropdownOptions={allAssigneeList}
                        callBack={(arg) => {
                            setAssigneeName({ 
                                id: arg,
                                name: arg,
                            });
                        }}
                        textClassNames="w-210 h-50 text-14 transition rounded-l-md text-left py-4 pl-2 truncate"
                        dropDownArrowClassNames="bg-white border-borderColor border-l"
                        dropDownClassNames="w-265"
                    />
                </div>

                <div className="flex flex-col">
                    <span className="text-reportProjectSubtitle font-semibold pb-2 text-16">
                        Type
                    </span>
                    <ButtonDropDown 
                        buttonOption={globalTodoType}
                        dropdownOptions={todoTypeOptions}
                        callBack={(arg) => {
                            todoTypeCallBack(arg); 
                        }}
                        textClassNames="w-140 h-50 text-14 transition rounded-l-md text-left py-4 pl-2 truncate"
                        dropDownArrowClassNames="border-borderColor border-l"
                        dropDownClassNames="w-190 text-black"
                    />
                </div>
                
                {globalTodoType.name === 'Project' ?
                    <div className="flex flex-col">
                        <span className="text-reportProjectSubtitle font-semibold pb-2 text-16">
                            For Project
                        </span>
                        <ButtonDropDown 
                            buttonOption={projectName}
                            dropdownOptions={allProjectList}
                            callBack={(arg) => {
                                projectCallBack(arg); 
                            }}
                            textClassNames="w-210 h-50 text-14 transition rounded-l-md text-left py-4 pl-2 truncate"
                            dropDownArrowClassNames="border-borderColor border-l"
                            dropDownClassNames="w-265 text-black"
                        />
                    </div> 
                    : 
                    <div className="flex flex-col">
                        <span className="text-reportProjectSubtitle font-semibold pb-2 text-16">
                            For Property
                        </span>
                        <ButtonDropDown 
                            buttonOption={propertyName}
                            dropdownOptions={allPropertyList}
                            callBack={(arg) => {
                                propertyCallBack(arg)
                            }}
                            textClassNames="w-210 h-50 text-14 transition rounded-l-md text-left py-4 pl-2 truncate"
                            dropDownArrowClassNames="bg-white border-borderColor border-l"
                            dropDownClassNames="w-265 text-black"
                        />
                    </div>
                }

            </div>
        </View>
    )
}