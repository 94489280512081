import { Breadcrumbs, Header, LoadingState, View, Workspace } from "components/Shared";
import { Form } from "components/Tenants";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {
	match: any;
}

const TenantEdit = ({ match, history }: Props) => {
	const { id } = match.params;
	const [, renderTrigger] = useState(moment());
	const [tenant, setTenant] = useState({} as any);
	const [datasource] = useState(
		new Datasource({ mainModelName: "tenant", renderTrigger: renderTrigger })
	);
	const putTenant = (tenant: tcpinpoint.Tenant) => {
		datasource.put("v2", `tenants/${tenant.id}`, { body: { tenant } })
		.then(tenant => {
			history.goBack()
		})
	}

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setTenant(response.success ? response.normalizedMainModelResponse : response.normalizedMainModelResponseBackup)
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		if (!_.isEmpty(id)) datasource.get("v2", `tenants/${id}`, {});
	}, [datasource, id]);

	if (datasource.isLoading) return <LoadingState />;
	if (!!tenant) {
		return (
			<React.Fragment>
				<Breadcrumbs items={[{ title: tenant.name }]} />,
				<Header stamp="tenants" title={tenant.name} />
				<Workspace id="project" className="show">
					<View>
						<Form
							tenant={tenant}
							onSubmit={(tenant: any) => putTenant(_.assign({}, tenant, { id }))}
							extended={true}
						/>
					</View>
				</Workspace>
			</React.Fragment>
		);
	}
	return null;
};

export default TenantEdit;
