import { API } from "aws-amplify";
import { memoAC } from "./memoize";

/**
 * Get all Searches
 * @param term Search Term
 */
export const getSearchedTerm = memoAC((term: string) => (dispatch: any) => {
  console.log("able to go in actions")
    dispatch({ type: "GET_SEARCHED_TERM_REQUEST" });
    API.get("v2", `search?q=${term}`, {})
      .then((jsonApiResults) => {
        // dispatch({ type: "GET_PROJECTS_SUCCESS", resource: _.values(new Normalizer(jsonApiProjects).normalize()["entities"]["project"]) as tcpinpoint.Project[] });
        console.log(jsonApiResults);
      })
      .catch(reason => console.log(reason));
  });