import React from "react";
import { lazy } from "react";
import CardDynamic from "./CardDynamic"
import Collection from "./Collection";
import CollectionDynamic from "./CollectionDynamic"
import Editor from "./Editor";
import Details from "./Details";
import Download from "./Download";
import EditModal from "./EditModal";
import Icon from "./Icon";
import Item from "./Item";
import List from "./List";
import Panel from "./Panel";
import Revisions from "./Revisions";
import Status from "./Status";
import Tags from "./Tags";
import Trash from "./Trash";
import Thumbnail from "./Thumbnail";
import Upload from "./Upload";
import { RouteComponentProps } from "react-router-dom";

const LazyPreview = lazy(() => import("./Preview"));
const Preview = (props: any) => <LazyPreview {...props}></LazyPreview>;
const LazyShow = lazy(() => import("./Show"));
const Show = (props: RouteComponentProps) => <LazyShow {...props} />;

export {
	Trash,
	CardDynamic,
	Collection,
	CollectionDynamic,
	Editor,
	Details,
	Download,
	EditModal,
	Icon,
	Item,
	List,
	Preview,
	Revisions,
	Show,
	Status,
	Tags,
	Thumbnail,
	Upload,
	Panel
};
