import React from "react";
import { ListGroup, ListGroupItem, View } from "components/Shared";
import _ from "lodash";
import { translate } from "lib";
import { postLease } from "actions";
import { useSelector, useDispatch } from "react-redux";

interface Props {
	name?: string;
	tenancy: tcpinpoint.Tenancy;
}

const Collection = (props: Props) => {
	const { tenancy } = props;
	const dispatch = useDispatch();
	return (
		<View>
			<ListGroup
				resource="leases"
				collection={useSelector((store: tcpinpoint.Store) => _.filter(store.leases, { tenancy_id: tenancy.id }))}
				onAdd={() => {
					dispatch(
						postLease({
							kind: "fixed-term",
							status: "opportunity",
							property_id: tenancy.property_id,
							tenancy_id: tenancy.id
						})
					);
				}}
				renderRow={lease => (
					<ListGroupItem
						icon="lease"
						key={lease.id}
						title={!!lease.status && translate(lease.status)}
						heading={`${lease.tenancy_name} at ${lease.property_name}`}
						link={`/leases/${lease.id}`}
					/>
				)}
			/>
		</View>
	);
};

export default Collection;
