import React, { useState, memo } from "react";
import classNames from "classnames";
import { translate } from 'lib';
import { icon as icon_lookup } from "lib";
import _ from "lodash";

const Button = (props: any) => {
	const [state, setState] = useState({ confirmed: false });
	let { data = {} } = props;
	let { onClick } = props;
	const { disabled, small, className, errors, loading, warning, danger, children, confirm = false } = props;
	const { confirmed } = state;

	const attributes = {};

	_.set(attributes, "type", "button");
	if (disabled) _.set(attributes, "disabled", "disabled");

	let kind = props.kind || translate("ok");
	let label = translate(!!children ? children : kind);
	if (!!confirm && confirmed) {
		kind = "confirm";
		label = translate("confirm");
	}

	data.kind = kind;
	data.label = label;
	data.ref = kind;

	if (!!confirm && !confirmed) {
		onClick = () => setState({ confirmed: true });
	}

	_.each(_.keys(data), key => {
		if (!_.includes(key, "on")) _.set(attributes, `data-${key}`, data[key]);
	});

	return (
		<button
			{...attributes}
			onClick={onClick}
			className={classNames("button", "btn", className, `btn-${kind}`, {
				"btn-sm": small,
				"btn-primary": !warning && !danger && kind !== "destroy" && kind !== "confirm",
				"btn-warning": warning,
				"btn-danger": !!danger || kind === "destroy" || kind === "confirm",
				error: !!_.keys(errors).length,
				loading
			})}
		>
			{icon_lookup(kind)}
			{label}
		</button>
	);
};

export default memo(Button);
