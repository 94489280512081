import React, { memo } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Status } from "components/Shared/Status";
import CircleStatus from "components/Visualisations/Progress/CircleStatus";

interface Props {
	phases: tcpinpoint.Phase[];
	project: tcpinpoint.Project;
	isLinked?: boolean;
	onReport?: boolean;
}

const WorkflowVisual = (props: Props) => {
	const { phases, project, onReport } = props;
	return (
		<div className="workflow-status">
			{_.map(phases, phase => {
				// the portfolio report workflow visual and project workflow visual are using a different ways of communicating phase completeness
				// portfolio uses a phase.completed_at attribute from the API, which doesn't respect tasks with no conditions to complete
				// project uses front end code to determine whether child conditions have been met
				// TODO - this should be corrected in API v2 - it should come from the API, being a single source of truth

				// TODO - this isLinked code has been commented out to temporarily turn off the links on phase icons in the project workflow. Review in future to correct errors
				// if (isLinked) {
				// 	return (
				// 		<Link
				// 			key={phase.id}
				// 			to={{ pathname: `/projects/${project.id}#${phase.id}` }}
				// 			className={classNames("phase", {
				// 				"phase-open": !phase.closed_at,
				// 				"phase-closed": !!phase.closed_at,
				// 				"phase-complete": !!phaseComplete,
				// 				"phase-incomplete": !phaseComplete,
				// 				"phase-ahead": !phase.behind && !phase.closed_at,
				// 				"phase-behind": !!phase.behind && !phase.closed_at
				// 			})}
				// 		>
				// 			<Status incomplete={!phaseComplete} closed={!!phase.closed_at} />
				// 			<figcaption>{phase.name}</figcaption>
				// 		</Link>
				// 	);
				// }

				if (!!onReport) {
					return (
						<Link
							key={phase.id}
							to={{ pathname: `/projects/${project.id}/` }}
							className={classNames("phase", {
								"phase-open": !phase.closed_at,
								"phase-closed": !!phase.closed_at,
								"phase-complete": !!phase.complete,
								"phase-incomplete": !phase.complete,
								"phase-ahead": !phase.behind && !phase.closed_at,
								"phase-behind": !!phase.behind && !phase.closed_at
							})}
						>
							<CircleStatus
								id={phase.id}
								incomplete={!phase.complete}
								closed={!!phase.closed_at}
								progress={phase.progress}
							/>
							<figcaption>{phase.name}</figcaption>
						</Link>
					);
				}

				return (
					<div
						key={phase.id}
						className={classNames("phase", {
							"phase-open": !phase.closed_at,
							"phase-closed": !!phase.closed_at,
							"phase-complete": !!phase.complete,
							"phase-incomplete": !phase.complete,
							"phase-ahead": !phase.behind && !phase.closed_at,
							"phase-behind": !!phase.behind && !phase.closed_at
						})}
					>
						<Status incomplete={!phase.complete} closed={!!phase.closed_at} />
						<figcaption>{phase.name}</figcaption>
					</div>
				);
			})}
		</div>
	);
};

export default memo(WorkflowVisual);
