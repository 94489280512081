import React, { memo } from "react";
import { translate } from "../../lib";

interface Props {
	resource?: string;
	children?: JSX.Element;
}

const EmptyState =  (props: Props) => {
	const { resource, children } = props;
	if (!!resource || !!children) {
		return (
			<div className="alert alert-light empty-state">
				<div>{!!resource && `No ${translate(resource)} found`}</div>
				{children}
			</div>
		);
	}
	return null;
};

export default memo(EmptyState);
