import React, { memo } from "react";

const Logo = ({ className }: { className?: string }) => (
	<svg
		width="124"
		height="56"
		viewBox="0 0 124 56"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<g clipPath="url(#clip0)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.9529 40.0126C28.254 40.0126 27.5479 39.5475 27.5479 38.6587V22.1903C27.5479 21.3733 28.1171 20.8237 28.9648 20.8237C29.7768 20.8237 30.287 21.3289 30.304 22.1474C31.414 21.0731 32.9571 20.6648 34.2102 20.6648C35.119 20.6648 36.0683 20.8495 36.8867 21.1863L36.893 21.1744C39.8209 22.3849 41.5164 25.1174 41.4291 28.4869C41.3133 32.6265 38.3449 35.5167 34.2102 35.5167C32.681 35.5167 31.3017 35.0246 30.3572 34.181V38.6587C30.3572 39.5475 29.6507 40.0126 28.9529 40.0126ZM34.3514 23.3715C32.4243 23.379 30.3572 24.5367 30.3572 27.0694V29.1653C30.3572 31.2134 31.9162 32.7119 34.1499 32.81C35.9818 32.81 37.6007 31.8016 38.2548 30.2416C38.4611 29.7006 38.596 29.0366 38.6198 28.4321C38.6456 26.6981 38.0394 25.2965 36.8168 24.2774C36.1718 23.7658 35.3178 23.4505 34.3514 23.3715Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M60.7931 35.3841C59.9585 35.3841 59.375 34.8225 59.375 34.0182V26.9904C59.375 24.6758 57.5106 23.2917 55.7076 23.2917C53.5139 23.2917 51.1656 25.1293 51.1656 27.865V34.0182C51.1656 34.8487 50.6197 35.3841 49.7744 35.3841C48.9104 35.3841 48.3304 34.8356 48.3304 34.0182V22.1375C48.3304 21.2534 49.0477 20.7772 49.7216 20.7772C50.4127 20.7772 51.1124 21.2534 51.1124 22.1633V22.4052C52.1866 21.251 53.8031 20.612 55.7346 20.612C58.9394 20.612 62.1843 22.8024 62.1843 26.9904V34.0182C62.1843 34.8487 61.6389 35.3841 60.7931 35.3841Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M65.2775 40.0126C64.5789 40.0126 63.8724 39.5475 63.8724 38.6587V22.1903C63.8724 21.3733 64.4417 20.8237 65.2894 20.8237C66.1014 20.8237 66.6116 21.3289 66.629 22.1474C67.7385 21.0731 69.2817 20.6648 70.5348 20.6648C71.4436 20.6648 72.3929 20.8495 73.2112 21.1863L73.218 21.1744C76.1455 22.3849 77.841 25.1174 77.7537 28.4869C77.6379 32.6265 74.6695 35.5167 70.5348 35.5167C69.0056 35.5167 67.6263 35.0246 66.6818 34.181V38.6587C66.6818 39.5475 65.9753 40.0126 65.2775 40.0126ZM70.676 23.3715C68.7489 23.379 66.6818 24.5367 66.6818 27.0694V29.1653C66.6818 31.2134 68.2411 32.7119 70.4745 32.81C72.3068 32.81 73.9253 31.8016 74.5798 30.2416C74.7857 29.7006 74.9206 29.0366 74.9444 28.4321C74.9701 26.6981 74.364 25.2965 73.1414 24.2774C72.4964 23.7658 71.6423 23.4505 70.676 23.3715Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M86.3609 35.464C84.0875 35.464 82.0874 34.5605 80.7264 32.9182C79.4863 31.4582 78.9242 29.694 79.0095 27.5291C79.3082 24.204 81.2591 21.7305 84.2291 20.8925C84.8154 20.7209 85.5144 20.6375 86.3609 20.6375C87.1452 20.6375 87.7842 20.7154 88.436 20.8917C91.4155 21.74 93.4243 24.2763 93.6842 27.5108C93.7976 29.6166 93.2292 31.4312 91.9967 32.919C90.6348 34.5605 88.6331 35.464 86.3609 35.464ZM86.3351 23.3176C85.7687 23.3176 85.2835 23.4256 85.0443 23.4891C83.1009 23.9991 81.8458 25.8106 81.8458 28.1038C81.8458 29.2762 82.233 30.3994 82.907 31.1874C83.7404 32.1882 85.0304 32.7843 86.3609 32.7843C87.6462 32.7843 88.9826 32.1711 89.845 31.183C90.4944 30.395 90.903 29.2309 90.903 28.1566C90.903 25.8288 89.6383 23.9979 87.6811 23.4891C87.2451 23.3815 86.7532 23.3176 86.3351 23.3176Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M112.365 35.3841C111.53 35.3841 110.947 34.8225 110.947 34.0182V26.9904C110.947 24.6758 109.083 23.2917 107.279 23.2917C105.086 23.2917 102.737 25.1293 102.737 27.865V34.0182C102.737 34.8487 102.192 35.3841 101.346 35.3841C100.482 35.3841 99.902 34.8356 99.902 34.0182V22.1375C99.902 21.2534 100.619 20.7772 101.293 20.7772C101.985 20.7772 102.684 21.2534 102.684 22.1633V22.4052C103.759 21.251 105.375 20.612 107.306 20.612C110.511 20.612 113.756 22.8024 113.756 26.9904V34.0182C113.756 34.8487 113.21 35.3841 112.365 35.3841Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M120.803 35.4111C117.893 35.4111 116.419 33.6306 116.419 30.1197V23.3973H115.108C114.598 23.3973 114.286 23.1888 114.113 23.0132C113.889 22.7841 113.766 22.4767 113.766 22.145C113.766 21.5167 114.236 20.8503 115.108 20.8503H116.419V18.2119C116.419 17.3036 117.115 16.8286 117.803 16.8286C118.481 16.8286 119.201 17.3136 119.201 18.2119V20.8503H121.651C122.525 20.8503 122.983 21.4909 122.983 22.1244C122.983 22.7571 122.525 23.3973 121.651 23.3973H119.201V30.1197C119.201 32.0372 119.651 32.7842 120.803 32.7842C121.155 32.7842 121.649 32.6579 121.824 32.5543C122.071 32.4129 122.319 32.3426 122.567 32.3426C123.149 32.3426 123.661 32.7465 123.81 33.324C123.97 33.9408 123.674 34.5615 123.056 34.9047C122.261 35.3515 121.47 35.4111 120.803 35.4111Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.74026 35.5383C6.0651 35.5383 5.34869 35.043 5.34869 34.1252V23.6397H1.45322C0.559885 23.6397 0.0775146 22.9383 0.0775146 22.2786C0.0775146 21.9303 0.202074 21.6002 0.428186 21.3512C0.682462 21.0708 1.04702 20.9171 1.45322 20.9171H12.067C12.9805 20.9171 13.4736 21.6185 13.4736 22.2786C13.4736 22.9383 12.9805 23.6397 12.067 23.6397H8.15247L8.17151 34.1244C8.17151 35.0097 7.44438 35.5383 6.74026 35.5383Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.1255 35.5174C16.0249 35.5174 12.8134 32.1852 12.8134 27.93C12.8134 24.3277 15.3799 21.3121 18.9176 20.7592C19.3797 20.6965 19.8188 20.6659 20.2465 20.6659C22.3735 20.6659 24.1737 21.4257 25.5978 22.925C25.9714 23.3475 26.0599 23.8551 25.8473 24.3261C25.6243 24.8206 25.088 25.1665 24.5418 25.1665C24.1756 25.1665 23.8341 25.0168 23.5814 24.7448C22.7099 23.8218 21.6678 23.3908 20.3024 23.3908C19.9815 23.3908 19.6534 23.4143 19.3182 23.452C16.9917 23.8118 15.5945 25.4946 15.5945 27.9507C15.5945 30.6208 17.6458 32.7941 20.1663 32.7941C20.834 32.7941 21.5738 32.607 22.2501 32.2678C22.8142 31.9771 23.3462 31.5418 23.7068 31.0775C23.9277 30.7479 24.3089 30.5489 24.7417 30.5489C25.2792 30.5489 25.8052 30.8662 26.0516 31.3393C26.2733 31.7658 26.2341 32.2654 25.9473 32.6753C25.3522 33.5121 24.5017 34.2163 23.4854 34.7132C22.3521 35.2334 21.1616 35.5174 20.1255 35.5174Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M48.8619 12.9186C49.9243 13.5327 50.288 14.8925 49.6747 15.9558C49.0615 17.0194 47.7032 17.3836 46.6413 16.7695C45.579 16.1555 45.2152 14.796 45.8285 13.7324C46.4418 12.6692 47.7996 12.3046 48.8619 12.9186Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M94.6171 14.0177C94.6067 13.9995 94.5853 13.9653 94.5853 13.9653C89.764 5.72258 80.8314 0.182556 70.6013 0.182556C62.4117 0.182556 55.0515 3.732 49.9672 9.37609C49.9672 9.37609 49.8462 9.52066 49.795 9.60287C49.3746 10.2761 49.5792 11.1633 50.252 11.5839C50.8332 11.9477 51.5726 11.8421 52.0339 11.373C52.0339 11.373 52.0534 11.3552 52.0558 11.3524C56.6169 6.26233 63.234 3.05683 70.6013 3.05683C79.7461 3.05683 87.7349 7.99317 92.0676 15.3466L92.1778 15.5198C92.5912 16.1501 93.4321 16.3582 94.093 15.9765C94.7777 15.5806 95.0126 14.7036 94.6171 14.0177Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M92.3302 43.2412C91.2679 42.6272 90.9042 41.2673 91.5174 40.2041C92.1307 39.1405 93.489 38.7763 94.5509 39.3903C95.6132 40.0043 95.977 41.3638 95.3637 42.4274C94.7504 43.4906 93.3926 43.8552 92.3302 43.2412Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M94.3054 35.7989C94.0599 36.5555 94.4736 37.3681 95.2293 37.6139C95.981 37.8586 96.7875 37.4491 97.0374 36.6993L97.0413 36.7005C97.9077 34.0093 98.3805 31.1414 98.3805 28.1618C98.3805 25.1474 97.8918 22.2493 97.0056 19.5307C97.0017 19.5184 97.0013 19.5057 96.9973 19.493C96.7518 18.7368 95.9401 18.3225 95.1849 18.5684C94.4292 18.8142 94.0154 19.6268 94.261 20.3834C94.2645 20.3941 94.2709 20.4033 94.2745 20.414C95.071 22.8538 95.5097 25.4556 95.5097 28.1618C95.5097 30.8121 95.0885 33.3619 94.3233 35.758C94.3181 35.7723 94.3102 35.7846 94.3054 35.7989Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.8867 35.7989C47.1322 36.5555 46.7185 37.3681 45.9628 37.6139C45.2111 37.8586 44.4046 37.4491 44.1547 36.6993L44.1507 36.7005C43.2844 34.0093 42.8115 31.1414 42.8115 28.1618C42.8115 25.1474 43.3002 22.2493 44.1864 19.5307C44.1904 19.5184 44.1908 19.5057 44.1948 19.493C44.4403 18.7368 45.2519 18.3225 46.0072 18.5684C46.7629 18.8142 47.1767 19.6268 46.9311 20.3834C46.9275 20.3941 46.9212 20.4033 46.9176 20.414C46.1211 22.8538 45.6823 25.4556 45.6823 28.1618C45.6823 30.8121 46.1036 33.3619 46.8688 35.758C46.874 35.7723 46.8819 35.7846 46.8867 35.7989Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.575 42.1419C46.5853 42.1601 46.6068 42.1943 46.6068 42.1943C51.4281 50.437 60.3607 55.977 70.5908 55.977C78.7804 55.977 86.1405 52.4276 91.2249 46.7835C91.2249 46.7835 91.3459 46.6389 91.397 46.5567C91.8175 45.8835 91.6128 44.9963 90.9401 44.5757C90.3589 44.2119 89.6199 44.3175 89.1581 44.7866C89.1581 44.7866 89.1387 44.8044 89.1363 44.8072C84.5752 49.8973 77.9581 53.1028 70.5908 53.1028C61.446 53.1028 53.4571 48.1664 49.1245 40.813L49.0143 40.6398C48.6009 40.0095 47.7599 39.8014 47.0991 40.1831C46.4144 40.579 46.1795 41.456 46.575 42.1419Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="123.91" height="56" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default memo(Logo);
