import { lazy } from 'react';
import PlainTextInput from "./PlainText";
import Feedback from "./Feedback";
import Required from "./Required";
import Checkbox from "./Checkbox";
import Label from "./Label";
import Attachment from "./Attachment";
import Address from "./Address";
import Tags from "./Tags";
import Plans from "./Plans";
import Payment from "./Payment";
import Input from './Input';

const TextInput = lazy(() => import("./DraftText"))

export {
  Plans,
  Payment,
  Tags,
  Label,
  Address,
  Input,
  TextInput,
  PlainTextInput,
  Feedback,
  Required,
  Checkbox,
  Attachment
};