import { memoAC } from "./memoize";
import axios from "v2/utils/axios";

export const getTenant = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_TENANT_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}tenants/${id}`)
		.then((res) => dispatch({ type: "GET_TENANT_SUCCESS", resource: res.data.tenant }))
		.catch((e) => { dispatch({ type: "GET_TENANT_FAILED", response: e }); });
});

export const getTenants = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_TENANTS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}tenants`)
		.then((res) => dispatch({ type: "GET_TENANTS_SUCCESS", resource: res.data.tenants }))
		.catch((e) => { dispatch({ type: "GET_TENANTS_FAILED", response: e }); });
});

export const putTenant = memoAC((tenant: tcpinpoint.Tenant) => (dispatch: any) => {
	dispatch({ type: "PUT_TENANT_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}tenants/${tenant.id}`, { tenant })
		.then((res) => {
			dispatch({ type: "PUT_TENANT_SUCCESS" });
			dispatch(getTenant(res.data.tenant.id));
		})
		.catch((e) => { dispatch({ type: "PUT_TENANTS_FAILED", response: e }); });
});
