import React from "react";
import validate from "validate.js";
import s from "underscore.string";
import { Input } from "components/Inputs";
import { Lookup } from "components/Localities";
import { Button } from "components/Shared";

const constraints = {
  name: {
    presence: true
  },
  street_1: {
    presence: true
  },
  locality: {
    presence: true
  }
};

export default class PropertyCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.property;
  }

  onSubmit() {
    const { onUpdate } = this.props;
    const { name, street_1, street_2, locality } = this.state;
    const errors = validate({ name, street_1, street_2, locality }, constraints);
    if (!!errors) {
      this.setState({ errors });
    } else {
      onUpdate({
        created: true,
        name: s(name)
          .titleize()
          .value(),
        addresses: [
          {
            street_1: s(street_1)
              .titleize()
              .value(),
            street_2: s(street_2)
              .titleize()
              .value(),
            locality
          }
        ]
      });
    }
  }
  render() {
    return (
      <section>
        <Input
          required={constraints}
          defaultState={this.state}
          name="name"
          onChange={name => this.setState({ name })}
        />
        <Input
          required={constraints}
          defaultState={this.state}
          name="street_1"
          onChange={street_1 => this.setState({ street_1 })}
        />
        <Input
          required={constraints}
          defaultState={this.state}
          name="street_2"
          onChange={street_2 => this.setState({ street_2 })}
        />
        <Lookup required={constraints} defaultState={this.state} onChange={locality => this.setState({ locality })} />
        <Button onClick={() => this.onSubmit()} />
      </section>
    );
  }
}
