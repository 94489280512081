import React, { memo } from "react";
import { translate } from "lib";
import { Breadcrumbs, Workspace, Header, View } from "components/Shared";

const User = () => (
	<React.Fragment>
		<Breadcrumbs items={[{ title: "user" }]} />
		<Header title={translate("user")} />
		<Workspace id="user">
			<View />
		</Workspace>
	</React.Fragment>
);
export default memo(User);
