import React, { memo } from "react";
import ReactAvatar from "react-avatar";

const Avatar = ({ size = 48, url, name, color = "#09b4c2" }: any) => {
	if (url) {
		return (
			<img
				alt="Avatar"
				style={{
					clipPath: `circle(${size / 2}px at ${size / 2}px ${size / 2}px)`,
					width: `${size}px`,
					height: `${size}px`
				}}
				className="avatar"
				src={url}
			/>
		);
	}
	return <ReactAvatar size={size} color={color} className="avatar" round name={name} />;
};

export default memo(Avatar);
