import axios from "axios";
//@ts-ignore
import { saveAs } from "file-saver";
import { whatIs } from "lib";
import Datasource from "lib/datasource";
import _ from "lodash";
import { postEvent } from "./history";
import { memoAC } from "./memoize";
import moment from "moment";
import { getAuthTokenFromCookie } from 'lib/hooks';

/** Dispatches a Document request and makes an API call
 * @param {Document} document
 * @yields `GET_DOCUMENTS_REQUEST`
 * @yields recieveDocument
 * @yields getsCommentsFor
 */
export const getDocument = memoAC((id: string, viewEvent: boolean = true) => (dispatch: any) => {
	dispatch({ type: "GET_DOCUMENT_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		let document = Datasource.getNormalizedData(res.data, "document") as tcpinpoint.Document;
		// dispatch(getCommentsFor(document));
		if (viewEvent) {
			dispatch(postEvent("View", document));
		}
		dispatch({ type: "GET_DOCUMENT_SUCCESS", response: { status: 200 }, resource: document });
	})
	.catch((e) => { dispatch({ type: "GET_DOCUMENT_FAILED", resource: document, response: e }); });
});

/** Deletes a Document */
export const deleteDocument = memoAC((document: tcpinpoint.Document) => (dispatch: any) => {
	dispatch({ type: "DELETE_DOCUMENT_REQUEST" });
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/${document.id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "DELETE_DOCUMENT_SUCCESS", response: { status: 200 }, resource: document });
		dispatch(getDocument(document.id, false));
	})
	.catch((e) => { dispatch({ type: "DELETE_DOCUMENT_FAILED", resource: document, response: e }); });
});

/** Updates a Document
 * @param {Document} document
 */
export const putDocument = memoAC((document: tcpinpoint.Document) => (dispatch: any) => {
	dispatch({ type: "PUT_DOCUMENTS_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/${document.id}`, { document }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		let document = Datasource.getNormalizedData(res.data, "document") as tcpinpoint.Document;
		dispatch(getDocument(document.id, false));
		dispatch({ type: "PUT_DOCUMENTS_SUCCESS" });
	})
	.catch((e) => { dispatch({ type: "PUT_DOCUMENTS_FAILED", resource: document, response: e }); });
});

/** Upload a new Revision to the Document
 * @param {document} document
 * @param {string} uri
 */
export const postDocumentRevision = memoAC(
	(document: tcpinpoint.Document, uri: string, callback: any) => (dispatch: any) => {
		dispatch({ type: "POST_DOCUMENT_REVISION_REQUEST" });
		axios.put(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/${document.id}/revision`, { uri, source: "web" }, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getAuthTokenFromCookie()}`,
			}
		})
		.then((res) => {
			let document = Datasource.getNormalizedData(res.data, "document") as tcpinpoint.Document;
			dispatch({ type: "POST_DOCUMENT_REVISION_SUCCESS", response: { status: 200 }, resource: document });
			dispatch(createThumbnail(document));
			dispatch(getExif(document));
			setTimeout(() => {
				dispatch({ type: "FINALISE_DOCUMENT", document });
				callback(moment());
			}, 5000);
		})
		.catch((e) => { dispatch({ type: "POST_DOCUMENT_REVISION_FAILED", resource: document, response: e }); });
	}
);

export const exportToDocument = memoAC((document: tcpinpoint.Document) => (dispatch: any) => {
	dispatch({ type: "EXPORT_DOCUMENT_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}documents`, { document }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "EXPORT_DOCUMENT_SUCCESS" });
		dispatch(getDocument(document.id));
	})
	.catch((e) => { dispatch({ type: "EXPORT_DOCUMENT_FAILED", resource: document, response: e }); });
});

/** Creates a Document */
export const postDocument = memoAC(
	(resource: tcpinpoint.Resource, file: File, comment: string = "", callback: any) =>
		(dispatch: any) => {
			const { link, id } = resource;
			dispatch({ type: "POST_DOCUMENT_REQUEST" });
			axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}${whatIs(link)}/${id}/documents`, {
				document: { mimetype: file.type, name: file.name, filesize_bytes: file.size }
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthTokenFromCookie()}`,
				}
			})
			.then((res) => {
				let document = Datasource.getNormalizedData(res.data, "document") as tcpinpoint.Document;
				dispatch({ type: "POST_DOCUMENT_SUCCESS" });
				dispatch(upload(document, file, callback));
				// dispatch(labelDocument(document, file));
				dispatch(getDocument(document.id, false));
				if (comment !== "") {
					axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}comments`, {
						global_id: link,
						body: comment,
						metadata: {
							ogSiteName: "tcpinpoint",
							ogTitle: document.name,
							ogUrl: `${process.env.REACT_APP_HOST}documents/${document.id}`,
							ogDescription: "",
						}
					}, 
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${getAuthTokenFromCookie()}`,
						}
					})
					.then((res) => {})
					.catch((e) => {});
				}
				!!callback && callback(moment());
			})
			.catch((e) => { dispatch({ type: "POST_DOCUMENT_FAILED", resource: document, response: e }); });
		}
);

/** Creates a Thumbnail */
const createThumbnail = memoAC((document: tcpinpoint.Document) => (dispatch: any) => {
	dispatch({ type: "POST_THUMBNAIL_REQUEST", document });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/${document.id}/thumbnail`, {}, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		const metadata = _.assign({}, document.metadata, res.data);
		const d = _.assign({}, document, { metadata });
		dispatch({ type: "POST_THUMBNAIL_SUCCESS", response: { status: 200 }, resource: res.data, document: d });
		dispatch(putDocument(d));
		dispatch(getExif(d));
	})
	.catch((e) => { dispatch({ type: "POST_THUMBNAIL_FAILED", response: e }); });
});

/** Retrieves the EXIF's metadata */
const getExif = memoAC((document: tcpinpoint.Document) => (dispatch: any) => {
	dispatch({ type: "GET_EXIF_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/${document.id}/exif`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "GET_EXIF_SUCCESS", response: { status: 200 }, resource: res.data });
		const metadata = _.assign({}, document.metadata, res.data);
		dispatch(putDocument(_.assign({}, document, { metadata })));
	})
	.catch((e) => { dispatch({ type: "GET_EXIF_FAILED", resource: document, response: e }); });
});

/** Updates the progress of Document upload
 * @param {ProgressEvent} progressEvent https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent
 * @param {Document} document
 * @param {number} timestamp
 * @param {cancelToken} cancelToken
 */
const progressDocumentUpload = memoAC(
	(progressEvent: ProgressEvent, document: tcpinpoint.Document, timestamp: number, cancelToken: any) =>
		(dispatch: any) => {
			if (progressEvent.lengthComputable) {
				/** Percent of Upload Completed */
				const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
				/** Number of seconds since upload began */
				const deltaSeconds = (+new Date() - timestamp) / 1000;
				/** Estimated time to completing upload */
				const estimatedSeconds = Math.abs(Math.round(deltaSeconds / (percentComplete / 100)));
				/** Estimated time remaining */
				const remainingSeconds = Math.abs(Math.round(estimatedSeconds - deltaSeconds));
				dispatch({
					type: "UPLOAD_DOCUMENT_PROGRESS",
					state: "PROGRESS",
					document: document,
					finished: percentComplete === 100 || !percentComplete,
					total: progressEvent.total,
					loaded: progressEvent.loaded,
					percentComplete: percentComplete,
					estimatedSeconds: estimatedSeconds,
					remainingSeconds: remainingSeconds,
					cancelToken,
				} as tcpinpoint.DocumentUpload);
			}
		}
);

export const downloadDocument = memoAC((document: tcpinpoint.Document) => async (dispatch: any) => {
	dispatch(postEvent("Read", document));
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/${document.id}`, {
		params: {
			fields: JSON.stringify({ document: ["name"] })
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		saveAs(document.revisions[0].signed_uri, _.get(res, "data.attributes.name", ""));
	})
	.catch((e) => {});
});

export const upload = memoAC((document: tcpinpoint.Document, file: File, callback: any) => (dispatch: any) => {
	const now = +new Date();
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();
	dispatch({ type: "UPLOAD_DOCUMENT_REQUEST", cancelToken: source });
	if (document.presigned_post_url) {
		const data = new FormData();
		data.append("key", document.presigned_post_url.fields["key"]);
		data.append("Expires", document.presigned_post_url.fields["Expires"]);
		data.append("success_action_status", document.presigned_post_url.fields["success_action_status"]);
		data.append("policy", document.presigned_post_url.fields["policy"]);
		data.append("x-amz-credential", document.presigned_post_url.fields["x-amz-credential"]);
		data.append("x-amz-algorithm", document.presigned_post_url.fields["x-amz-algorithm"]);
		data.append("x-amz-date", document.presigned_post_url.fields["x-amz-date"]);
		data.append("x-amz-signature", document.presigned_post_url.fields["x-amz-signature"]);
		data.append("acl", document.presigned_post_url.fields["acl"]);
		data.append("file", file);
		axios
			.post(document.presigned_post_url.url, data, {
				cancelToken: source.token,
				onUploadProgress: (event) => dispatch(progressDocumentUpload(event, document, now, source)),
			})
			.then((response) => _.get(response, "data", ""))
			//@ts-ignore
			.then((body) => new window.DOMParser().parseFromString(body, "text/xml"))
			.then((xml) => xml.getElementsByTagName("Key")[0].innerHTML)
			.then((uri) => {
				dispatch({ type: "UPLOAD_DOCUMENT_SUCCESS", response: { status: 200 }, resource: document, uri });

				dispatch(postDocumentRevision(document, uri, callback));
			})
			.catch((response) => {
				if (axios.isCancel(response)) {
					dispatch({ type: "UPLOAD_DOCUMENT_CANCELLED", document, response });
					dispatch(postEvent("Undo", document));
					dispatch(deleteDocument(document));
				} else {
					dispatch({ type: "UPLOAD_DOCUMENT_FAILED", document, response });
				}
			});
	} else {
		dispatch({ type: "UPLOAD_DOCUMENT_FAILED", document });
	}
});
