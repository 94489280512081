import React, { memo } from "react";
import { DefinitionList, DefinitionItem, View, Address, PhoneNumber, Button } from "components/Shared";
import { translate } from "lib";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {
	tenant: tcpinpoint.Tenant;
	name?: string;
	className?: string;
	editable?: boolean;
	onUpdate?: () => void;
	icon?: string;
	onClear?: (props: any) => any;
}

const Tenant = ({ tenant, className, history, editable, onClear }: Props) => {
	if (!!tenant) {
		return (
			<View className={className} title={tenant.name}>
				{!!onClear && (
					<button type="button" className="close" aria-label="Close" onClick={onClear}>
						<span aria-hidden="true">×</span>
					</button>
				)}
				<DefinitionList>
					<DefinitionItem title="trading_name" value={tenant.trading_name} />
					<DefinitionItem title="contact_details" value={tenant.contact_details} />
					<DefinitionItem title="phone_number" value={<PhoneNumber phone_number={tenant.phone_number} />} />
					{!!_.get(tenant, "addresses", []).length && (
						<DefinitionItem title="address">
							{_.map(tenant.addresses, address => (
								<Address key={address.id} address={address} />
							))}
						</DefinitionItem>
					)}
					<DefinitionItem title="sector" value={translate(tenant.sector)} />
					<DefinitionItem title="purpose" value={tenant.purpose} />
					<DefinitionItem title="business_code" value={_.get(tenant, "business_code.name", false)} />
					<DefinitionItem title="source" value={translate(tenant.source)} />
				</DefinitionList>
				{!!editable && <Button onClick={() => history.push(`/tenants/${tenant.id}/edit`)}>Edit</Button>}
			</View>
		);
	}
	return null;
};

export default memo(withRouter(Tenant));
