import React from "react";
import validate from "validate.js";
import { Input } from "components/Inputs";
import { Button, Title, ErrorBlock } from "components/Shared";
import { translate } from "lib";
import { Auth } from "aws-amplify";

class VerifyPhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: !!props.phone_number ? "code_sent" : false,
      phone_number: props.phone_number
    };
  }

  requestCode() {
    const { phone_number } = this.state;
    const errors = validate(
      { phone_number },
      {
        phone_number: {
          presence: true
        }
      }
    );

    if (!errors) {
      Auth.resendSignUp(phone_number)
        .then(() => this.setState({ errors: {}, state: "code_sent" }))
        .catch(errors => this.setState({ errors: { phone_number: [errors.message] } }));
    } else {
      this.setState({ errors });
    }
  }

  verifyPhoneNumber() {
    const { onUpdate } = this.props;
    const { phone_number, verification } = this.state;

    const errors = validate(
      { phone_number, verification },
      {
        phone_number: {
          presence: true
        },
        verification: {
          presence: true
        }
      }
    );
    if (!errors) {
      Auth.confirmSignUp(phone_number, verification)
        .then(() => (!!onUpdate ? onUpdate() : this.setState({ errors: {}, state: "phone_number_verified" })))
        .catch(errors => {
          this.setState({ errors: { verification: errors } });
        });
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { onUpdate } = this.props;
    const { state, errors } = this.state;
    if (!onUpdate && state === "email_verified") {
      return (
        <React.Fragment>
          <Title>{translate("verify_email")}</Title>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ErrorBlock {...{ errors }} />
        <Title>{translate("verify_phone_number")}</Title>
        <Input
          name="phone_number"
          disabled={!!onUpdate}
          defaultState={this.state}
          onChange={phone_number => this.setState({ phone_number })}
        />
        {state === "code_sent" ? (
          <React.Fragment>
            <Input
              name="verification"
              onChange={verification => this.setState({ verification })}
              defaultState={this.state}
            />
            <Button onClick={() => this.verifyPhoneNumber()}>{translate("verify")}</Button>
          </React.Fragment>
        ) : (
          <Button onClick={() => this.requestCode()}>{translate("request_code")}</Button>
        )}
      </React.Fragment>
    );
  }
}

export default VerifyPhoneNumber;
