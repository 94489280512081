import _ from "lodash";
export default (state: tcpinpoint.Ledger[] = [], action: any) => {
	switch (action.type) {
		case "GET_LEDGER_SUCCESS":
			return _.unionBy([action.resource], state, "id");
		case "GET_LEDGERS_SUCCESS":
			return _.unionBy(action.resource as tcpinpoint.Ledger[], state, "id");
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
