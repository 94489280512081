import React, { memo } from "react";
import classNames from "classnames";

const Board = ({ children, className }: { children?: React.ReactNode; className?: string }) => {
	return (
		<div
			className={classNames(
				"flex flex-col justify-between",
				"bg-white",
				"lg:mx-auto",
				"lg:rounded-2 lg:shadow-card ",
				"z-30",
				className
			)}
		>
			{/* blue bar 1 */}
			<div className="h-8 bg-blue-10 w-full flex-shrink-0 lg:hidden" />
			{children}
			{/* blue bar 2 */}
			<div className="h-8 bg-blue-10 w-full flex-shrink-0 lg:hidden" />
		</div>
	);
};

export default memo(Board);
