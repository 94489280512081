import "whatwg-fetch";
import React from "react";
import { Money, Button, Text } from "components/Shared";

import _ from "lodash";
import axios from "v2/utils/axios";

class PlansInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { plans: [] };
  }

  componentDidMount() {
    const { currency } = this.props;
    axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}plans`, { params: { currency: currency || "AUD" } })
      .then((res) => this.setState({ plans: res.data.plans }));
  }

  render() {
    const { onSubmit } = this.props;
    const { plans } = this.state;
    return (
      <div className="plans">
        {_.map(plans, plan => (
          <div className="plan" key={plan.id}>
            <h2>{plan.name}</h2>
            <Text>{plan.description}</Text>
            <Money showCurrency={true} currency={plan.currency} amount={plan.amount_cents} decimals={0} />
            <Button kind="select" onClick={() => onSubmit(plan)} />
          </div>
        ))}
      </div>
    );
  }
}

export default PlansInput;
