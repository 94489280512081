import BuildingLookup from "components/Buildings/SelectDynamic";
import { Input } from "components/Inputs";
// import PropertyLookup from "components/Properties/Lookup";
import { Button, Col, Row } from "components/Shared";
import FloorLookup from "components/Tenancies/FloorSelectDynamic";
import TenancyLookup from "components/Tenancies/TenancySelectDynamic";
import { NewProjectContext } from "context";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import validate from "validate.js";

const constraints = {
	name: {
		presence: {
			message: "^ is required",
		},
	},
};
const Form = ({ tenancy = {}, onUpdate, oldBuildingId }) => {
	const [data, setData] = useState(tenancy);
	const newProject = useContext(NewProjectContext);
	const template = _.get(newProject, "template", false);
	const buildings = _.get(data, "property.buildings", []);
	const building = _.find(buildings, { id: _.get(data, "building_id") });
	const tenancies = _.filter(_.get(building, "tenancies", []), { floor_id: _.get(data, "floor_id") });

	const getTenancyKindOptions = useCallback(
		() => [
			!!data.tenancy_exist && "existing_tenancy",
			"new_tenancy",
			!_.includes(_.get(template, "tags"), "flinders") ? "unallocated_space" : false,
		],
		[data.tenancy_exist, template]
	);
	const [tenancyKindOptions, setTenancyKindOptions] = useState(() => getTenancyKindOptions());

	useEffect(() => {
		setTenancyKindOptions(() => getTenancyKindOptions());
	}, [getTenancyKindOptions]);

	// const onChangeProperty = (property) =>
	// 	setData((prevData) =>
	// 		_.assign({}, prevData, {
	// 			property: property,
	// 			building_id: null,
	// 			floor_id: null,
	// 			tenancy_exist: false,
	// 			// id: null,
	// 			tenancy_kind: null,
	// 		})
	// 	);

	const onChangeBuilding = (building_id) =>
		setData((prevData) =>
			_.assign({}, prevData, {
				building_id: building_id,
				floor_id: null,
				tenancy_exist: false,
				// id: null,
				tenancy_kind: null,
			})
		);

	const onChangeFloor = (floor_id, tenancy_exist) =>
		setData((prevData) =>
			_.assign({}, prevData, {
				floor_id: floor_id,
				tenancy_exist: tenancy_exist,
				// id: null,
				tenancy_kind: null,
			})
		);

	const onChangeTenancy = (tenancy_id) => setData((prevData) => _.assign({}, prevData, { id: tenancy_id }));

	const onSubmit = () => {
		if (_.get(data, "tenancy_kind") === "unallocated_space") data["name"] = "Unallocated Space";

		if (_.get(data, "tenancy_kind") === "existing_tenancy" && !!_.get(data, "id")) {
			onUpdate(
				_.assign(
					{},
					_.find(tenancies, { id: data.id }),
					_.assign({}, data, {
						building: _.find(buildings, { id: data.building_id }),
					})
				)
			);
		} else if (_.get(data, "tenancy_kind") === "existing_tenancy" && !_.get(data, "id")) {
			setData(_.assign({}, data, {}));
		} else if (_.get(data, "tenancy_kind") !== "existing_tenancy") {
			const errors = validate(data, constraints);
			!!errors ? setData(_.assign({}, data, { errors })) : onUpdate(data);
		}
	};

	const propertyId = _.get(data, "property.id") ? _.get(data, "property.id") : data.property_id;

	// console.log("EWWEWE", _.get(data, "building_id"), { oldBuildingId });

	return (
		<Row>
			<Col>
				{/* <PropertyLookup onChange={onChangeProperty} name="property" defaultState={{ data }} /> */}
				<BuildingLookup propertyId={propertyId} onChange={onChangeBuilding} preselectedBuildingId={oldBuildingId} />
				<FloorLookup buildingId={_.get(data, "building_id")} onChange={onChangeFloor} />
				<Input
					onChange={(tenancy_kind) => setData((prevData) => _.assign({}, prevData, { tenancy_kind }))}
					name="tenancy_kind"
					defaultState={data}
					required={constraints}
					options={tenancyKindOptions}
					showIf={!!_.get(data, "property")}
				/>
				{_.get(data, "tenancy_kind") === "existing_tenancy" && (
					<TenancyLookup floorId={_.get(data, "floor_id")} onChange={onChangeTenancy} />
				)}
				<Input
					onChange={(name) => setData(_.assign({}, data, { name }))}
					name="name"
					defaultState={data}
					required={constraints}
					label={_.includes(_.get(template, "tags"), "flinders") ? "room_number" : "tenancy_name"}
					// showIf={_.get(data, "tenancy_kind") === "new_tenancy"}
				/>
				<Input
					onChange={(area) => setData(_.assign({}, data, { area }))}
					name="area"
					defaultState={data}
					required={constraints}
					suffix="m²"
					type="number"
					// showIf={_.get(data, "tenancy_kind") === "new_tenancy" || _.get(data, "tenancy_kind") === "unallocated_space"}
				/>
				<Input
					onChange={(kind) => setData(_.assign({}, data, { kind }))}
					name="kind"
					defaultState={data}
					required={constraints}
					options={["speciality", "food", "kiosk", "food-kiosk"]}
					showIf={
						(_.get(data, "tenancy_kind") === "new_tenancy" || _.get(data, "tenancy_kind") === "unallocated_space") &&
						_.includes(_.get(template, "tags"), "retail")
					}
				/>
				<Button onClick={onSubmit} />
			</Col>
		</Row>
	);
};

export default Form;
