import React from "react";
import classNames from "classnames";
import { View } from "components/Shared";
import _ from "lodash";

interface Datapoints {
  viewBy?: string
  children?: React.ReactNode
  title?: string
  collection: Array<any>
  limit?: 30
  className?: string
  resource?: string;
}
const Datapoints = ({ viewBy, children, title, collection, limit = 30, className, resource }: Datapoints) => {
  let viewAs = viewBy || "list";
  if (!viewBy && collection.length <= 6) {
    viewAs = "grid";
  }
  return (
    <View title={title} resource={resource} className={classNames("datapoints", `datapoints-${viewAs}`, className)}>
      {children}
      <div className="datapoints-collection">{collection.slice(0, limit)}</div>
      {_.get(collection, "length", 0) > limit && (
        <div className="muted">and {_.get(collection, "length", 0) - limit} more</div>
      )}
    </View>
  );
};

export default Datapoints;
