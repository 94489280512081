import { getTask } from "actions";
import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const putCondition = memoAC((condition: any) => (dispatch: any) => {
  dispatch({ type: "PUT_CONDITION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}conditions/${condition.id}`, { condition }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "PUT_CONDITION_SUCCESS" });
    dispatch(getTask(res.data.condition.task.id));
  })
  .catch((e) => { dispatch({ type: "PUT_CONDITION_FAILED", response: e }); });
});

export const reopenCondition = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "REOPEN_CONDITION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}conditions/${id}/reopen`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "REOPEN_CONDITION_SUCCESS" });
    dispatch(getTask(res.data.condition.task.id));
  })
  .catch((e) => { dispatch({ type: "REOPEN_CONDITION_FAILED", response: e }); });
});

export const dismissCondition = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "DISMISS_CONDITION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}conditions/${id}/dismiss`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "DISMISS_CONDITION_SUCCESS" });
    dispatch(getTask(res.data.condition.task.id));
  })
  .catch((e) => { dispatch({ type: "DISMISS_CONDITION_FAILED", response: e }); });
});

//TODO - deprecate accept and reject condition functions. Everything should be passed as an arbitrary response.
export const acceptCondition = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "ACCEPT_CONDITION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}conditions/${id}/accept`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "ACCEPT_CONDITION_SUCCESS" });
    dispatch(getTask(res.data.condition.task.id));
  })
  .catch((e) => { dispatch({ type: "ACCEPT_CONDITION_FAILED", response: e }); });
});
export const rejectCondition = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "REJECT_CONDITION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}conditions/${id}/reject`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "REJECT_CONDITION_SUCCESS" });
    dispatch(getTask(res.data.condition.task.id));
  })
  .catch((e) => { dispatch({ type: "REJECT_CONDITION_FAILED", response: e }); });
});



export const closeCondition = memoAC((id: string, response?: string) => (dispatch: any) => {
  dispatch({ type: "CLOSE_CONDITION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}conditions/${id}/close`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch(putCondition({ response, id }));
    dispatch({ type: "CLOSE_CONDITION_SUCCESS" });
    dispatch(getTask(res.data.condition.task.id));
  })
  .catch((e) => { dispatch({ type: "CLOSE_CONDITION_FAILED", response: e }); });
});

export const getConditions = memoAC(() => (dispatch: any) => {
  dispatch({ type: "GET_CONDITION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}conditions`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "GET_CONDITIONS_SUCCESS", response: { status: 200 }, resource: res.data.conditions })
  })
  .catch((e) => { dispatch({ type: "GET_CONDITIONS_FAILED", response: e }); });
});
