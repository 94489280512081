import { Button, LoadingState, ModalHeader } from "components/Shared";
import Datasource, { DatasourceResponse } from "lib/datasource";
import moment from "moment";
import { default as React, memo, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory, withRouter } from "react-router-dom";

const TrashProjectModal = ({ isOpen, onRequestClose, projectId }: any) => {
	const history = useHistory();
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "project", renderTrigger: renderTrigger }));
	const deleteProject = useCallback(() => {
		datasource.del("v2", `projects/${projectId}`, {}, { updateIsLoding: true });
	}, [datasource, projectId]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) {
				!!onRequestClose && onRequestClose();
				setTimeout(() => history.push("/projects"), 1000);
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, history, onRequestClose]);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			className="archive"
			onRequestClose={onRequestClose}
			contentLabel="close-project"
		>
			<ModalHeader title="trash_project" onRequestClose={onRequestClose} />
			<section>
				<p>
					This action will send your project to the trash. You will no longer be able to access this project in any way.
				</p>
				<p>If you do not want to trash your project, click the arrow in the top-right corner to close this modal.</p>
				<hr />
				<h3>Are you sure you want to trash this project?</h3>
			</section>
			<footer>{datasource.isLoading ? <LoadingState /> : <Button onClick={deleteProject} />}</footer>
		</Modal>
	);
};
export default memo(withRouter(TrashProjectModal));
