import { Input } from "components/Inputs";
import { Button, Date as DateComponent, LoadingState, Money as MoneyComponent } from "components/Shared";
import { Inline as User } from "components/Users";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import { default as React, useCallback, useEffect, useMemo, useState } from "react";

const QuoteCondition = ({ condition, onUpdate }: any) => {
	const closed_at = useMemo(() => _.get(condition, "closed_at"), [condition]);
	const closed_by = useMemo<any>(() => _.get(condition, "closed_by", {}), [condition]);
	const conditionId = useMemo(() => _.get(condition, "id"), [condition]);
	const [response, setResponse] = useState();
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "condition", renderTrigger: renderTrigger }));

	const close = useCallback(() => {
		if (!_.isNil(conditionId))
			datasource.put(
				"v2",
				`conditions/${conditionId}/close`,
				{ body: { response: response } },
				{ updateIsLoding: true }
			);
	}, [datasource, conditionId, response]);

	const undo = useCallback(() => {
		if (!_.isNil(conditionId)) datasource.put("v2", `conditions/${conditionId}/reopen`, {}, { updateIsLoding: true });
	}, [datasource, conditionId]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && !_.isNil(onUpdate)) onUpdate(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource, onUpdate]);

	if (datasource.isLoading) return <LoadingState />;
	if (_.isEmpty(condition)) return null;

	return (
		<div className="condition-actions">
			{!closed_at ? (
				<React.Fragment>
					<p>
						<MoneyComponent amount={_.get(condition, "response")} />
					</p>
					<p className="condition-meta muted">
						<User user={closed_by} /> {translate("responded")} <DateComponent date={closed_at} />
					</p>
					<div className="condition-toolbar">
						<Button onClick={undo} kind="undo" />
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Input name="response" type="money" defaultState={{ response }} onChange={setResponse} />
					<Button small onClick={close} />
				</React.Fragment>
			)}
		</div>
	);
};

export default QuoteCondition;
