import Icon from "components/Activities/Icon";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { AcceptTask, Announce, MoveTask, RemindTask, UndoTask } from "../Items";
import ActivityItemDom from "./ActivityItemDom";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityTaskItem = ({ activity }: Props) => {
	const props = { key: activity.attributes.item.id, activity };
	let link = `/tasks/${_.get(activity.attributes.item.object, "id")}`;
	let output;;
	let icon;

	switch (activity.attributes.item.type) {
		case "Announce":
			icon = Icon("announce");
			link = `/news/${activity.id}`;
			output = <Announce {...props} />;
			break;
		case "tcpinpoint/Remind":
			icon = Icon("remind-task");
			output = <RemindTask {...props} />;
			break;
		case "Undo":
			icon = Icon("undo-task");
			output = <UndoTask {...props} />;
			break;
		case "Accept":
			icon = Icon("accept-task");
			output = <AcceptTask {...props} />;
			break;
		case "Move":
			icon = Icon("move-task");
			output = <MoveTask {...props} />;
			break;
	}

	return (
		<Link to={link} className="activity-item-link">
			{" "}
			<ActivityItemDom activity={activity} icon={icon} link={link} output={output} />
		</Link>
	);
};

export default memo(ActivityTaskItem);
