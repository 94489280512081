import React from "react";
import { View } from "components/Shared";
import _ from "lodash";
import { Map } from "components/Widgets";
import { toSentenceSerial } from "underscore.string";

export default ({ property, onUpdate, className }) => {
	const { name, addresses } = property;
	if (property) {
		return (
			<View resource={name} className={className}>
				{onUpdate && (
					<button onClick={onUpdate} className="close">
						<span aria-hidden="true">&times;</span>
					</button>
				)}
				<h4>
					{toSentenceSerial(
						_.map(
							addresses,
							address =>
								`${_.get(address, "locality.name")},
                  ${_.get(address, "region.name")}`
						)
					)}
				</h4>
				<Map
					height={"400px"}
					defaultZoom={11}
					addresses={_.get(property, "addresses", [])}
					buildings={_.map(property.buildings, building => ({
						name: building.name,
						coordinates: building.shape
					}))}
				/>
			</View>
		);
	}
	return false;
};
