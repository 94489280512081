import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Date } from "components/Shared";
import Avatar from "components/Avatar/Show";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityConditionUploadItem = ({ activity }: Props) => {
	const link = `/tasks/${activity.attributes.item.object.id}#conditions`;
	const output = (
		<>
			<strong className="activity-item-actor">{activity.attributes.item.actor.name}</strong> has uploaded a document for{" "}
			<strong className="activity-item-subject">{activity.attributes.item.object.task_name}</strong> on{" "}
			<strong className="activity-item-subject">{activity.attributes.item.object.project_name}</strong>
		</>
	);

	return (
		<Link to={link} className="activity-item-link">
			<div className="activity-item-avatar">
				<Avatar
					size={32}
					url={activity.attributes.item.actor.image}
					name={
						activity.attributes.item.actor && !!activity.attributes.item.actor.name
							? activity.attributes.item.actor.name
							: "tcbot"
					}
				/>
			</div>
			<div className="activity-item-container">
				<div className="activity-item-body">{output}</div>
				<span className="activity-item-date">
					<Date showRelative date={activity.attributes.item.published} />
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.object.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{link}</code>}
				</span>
			</div>
		</Link>
	);
};

export default memo(ActivityConditionUploadItem);
