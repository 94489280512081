import React, { memo } from "react";
import StepItem from "./StepItem";
import { toArray } from "v2/utils";
import classNames from "classnames";

const Steps = ({
	NavClassName,
	className,
	children,
	current,
	setCurrent
}: {
	className?: string;
	NavClassName?: string;
	children?: React.ReactNode;
	current?: number;
	setCurrent: (curr: number) => any;
}) => {
	const childArray = toArray(children);
	return (
		<div className={className}>
			{/* navigation */}
			<div className="bg-white h-20 flex items-center rounded-1">
				<div className={classNames("flex w-2/3 mx-auto")}>
					{childArray.map((child, index) => {
						const isLast = index === childArray.length - 1;
						const clickable = child.props.clickable;
						return (
							<StepItem
								current={current!}
								index={index}
								key={index}
								isLast={isLast}
								title={child.props.title}
								onClick={() => !!clickable && setCurrent(index)}
								clickable={clickable}
								vertical
							/>
						);
					})}
				</div>
			</div>

			{/* step content */}
			<div className="content">
				{toArray(children).map((child, index) => {
					if (current === index) return child;
					return null;
				})}
			</div>
		</div>
	);
};

export default memo(Steps);
