import classNames from "classnames";
import { ListGroup, ListGroupControl, ListGroupItem, LoadingState, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import { LastMomentTriggerAPIContext } from "context";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useContext, useEffect, useState } from "react";

const Portfolios = () => {
	const { lastMoment } = useContext(LastMomentTriggerAPIContext);
	const [portfolios, setPortfolios] = useState([] as any[]);
	const [, renderTrigger] = useState(moment());
	const getSortAttribute = (sortBy: string) => (sortBy === "projects_length" ? "-projects_length" : sortBy);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "portfolio",
			perPage: 20,
			currentPage: 1,
			sortBy: "name",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setPortfolios(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		datasource.get("v2", "portfolios", {
			queryStringParameters: {
				sort: getSortAttribute(datasource.sortBy),
				per_page: datasource.perPage,
				page: datasource.currentPage,
				associations: JSON.stringify(["projects"])
			}
		});
	}, [datasource, datasource.currentPage, datasource.perPage, datasource.sortBy, lastMoment]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<View>
			<ListGroupControl
				onSortChange={newSortBy => {
					datasource.setCurrentPage(1);
					datasource.setSortBy(newSortBy);
				}}
				selectedSort={datasource.sortBy}
				sortOptions={["name", "projects_length"]}
			/>
			<ListGroup
				resource="portfolios"
				collection={portfolios}
				pagination={false}
				renderRow={(portfolio: tcpinpoint.Portfolio) => (
					<ListGroupItem
						key={_.get(portfolio, "id")}
						heading={_.get(portfolio, "name")}
						link={`/portfolios/${_.get(portfolio, "id")}`}
						datapoints={[{ key: "projects", value: `${_.get(portfolio, "projects", []).length}` }]}
						icon="portfolios"
					/>
				)}
			/>
			<div className={classNames("margin-auto", "flex justify-center")}>
				<Pagination datasource={datasource} />
			</div>
		</View>
	);
};

export default memo(Portfolios);
