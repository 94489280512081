import React from "react";
import { translate } from "lib";
import { ErrorBoundary } from "components/Shared";
import Actor from "components/Activities/Actor"
import Subject from "components/Activities/Subject"
import Context from "components/Activities/Context"
import ItemObject from "components/Activities/ItemObject"

const RejectCondition = ({ activity }: { activity: tcpinpoint.ActivityItem }) => <ErrorBoundary>
    <Actor actor={activity.attributes.item.actor} />{' '}
    {translate('rejected')}{' '}
    <ItemObject object={activity.attributes.item.object} />{" "}
    {translate('condition')}{' '}
    {translate("for")}{" "}
    <Subject subject={activity.attributes.item.subject} />{" "}
    {translate("task")}{" "}
    <Context context={activity.attributes.item.context} />
</ErrorBoundary>

export default RejectCondition;