import _ from "lodash";
export default (state: tcpinpoint.Workflow[] = [], action: any) => {
	switch (action.type) {
		case "GET_WORKFLOW_SUCCESS":
			return _.unionBy([action.resource as tcpinpoint.Workflow], state, "id");
		case "GET_WORKFLOWS_SUCCESS":
			return _.unionBy(action.resource as tcpinpoint.Workflow[], state, "id");
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
