import React, { memo } from "react";
import Checkbox from "./Checkbox";
import { useFormContext } from "./hooks";

const Agree = ({ value, onChange }: { value?: any; onChange?: any }) => {
	const { submitRef } = useFormContext();
	return (
		<div className="text-14 leading-5 text-black-100">
			<Checkbox agree={value} onClick={() => onChange(!value)} submitRef={submitRef} />I agree to{" "}
			<a href="http://tcpinpoint.com/terms" target="_new" className="text-blue-100 focus:outline-none">
				{" "}
				Terms & Conditions
			</a>
		</div>
	);
};

export default memo(Agree);
