import { getTemplate, getProperty, getLease, getTenant, getTenancy } from "actions";
import { memoAC } from "./memoize";
import { batch } from "react-redux";
import Normalizer from "lib/jsonapi-normalizer";
import _ from "lodash";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

/**
 * Update a Project
 * @param project Project resource
 */
export const putProject = memoAC((project: tcpinpoint.Project) => (dispatch: any) => {
  dispatch({ type: "PUT_PROJECT_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/${project.id}`, { project }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch(getProject(res.data.project.id));
    dispatch({ type: "PUT_PROJECT_SUCCESS" });
  })
  .catch((e) => { dispatch({ type: "PUT_PROJECT_FAILED", response: e }); });
});
/**
 * Delete a Project
 * @param {uuid} id Project Id
 */
export const trashProject = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "DELETE_PROJECT_REQUEST" });
  axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch(getProjects());
    dispatch({ type: "DELETE_PROJECT_SUCCESS", resource: { id } });
  })
  .catch((e) => { dispatch({ type: "DELETE_PROJECT_FAILED", response: e }); });
});
/**
 * Change the Project to Archived status
 * @param {uuid} id Project Id
 */
export const archiveProject = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "ARCHIVE_PROJECT_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/${id}/close`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch(getProject(res.data.project.id));
    dispatch({ type: "ARCHIVE_PROJECT_SUCCESS" });
  })
  .catch((e) => { dispatch({ type: "ARCHIVE_PROJECT_FAILED", response: e }); });
});

/**
 * Get a Project resource
 * @param {uuid} id Project Id
 */
export const getProject = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "GET_PROJECT_REQUEST" });
  axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    batch(() => {
      dispatch({ type: "GET_PROJECT_SUCCESS", resource: res.data.project });
      !!res.data.project.template_id && dispatch(getTemplate(res.data.project.template_id));
      dispatch(getProperty(res.data.project.property_id));
      dispatch(getLease(res.data.project.lease_id));
      dispatch(getTenant(res.data.project.tenant_id));
      dispatch(getTenancy(res.data.project.tenancy_id));
    })
  })
  .catch((e) => { dispatch({ type: "GET_PROJECT_FAILED", response: e }); });
});
/**
 * Get all Projects for Current User
 */
export const getProjects = memoAC((args: Object) => (dispatch: any) => {
  dispatch({ type: "GET_PROJECTS_REQUEST" });
  axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}projects`, {
    params: args,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "GET_PROJECTS_SUCCESS", resource: _.values(new Normalizer(res.data.jsonApiProjects).normalize()["entities"]["project"]) as tcpinpoint.Project[] });
  })
  .catch((e) => { dispatch({ type: "GET_PROJECTS_FAILED", response: e }); });
});
