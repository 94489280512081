import { memoAC } from './memoize';
import axios from "v2/utils/axios";

export const getApprovals = memoAC((resource: tcpinpoint.Resource) => (dispatch: any) => {
	dispatch({ type: "GET_APPROVALS_REQUEST" });	
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}approvals`)
		.then((res) => { dispatch({ type: "GET_APPROVALS_SUCCESS", resource: res.data.approvals }) })
		.catch((e) => { dispatch({ type: "GET_APPROVALS_FAILED", response: e }); });
});

export const postApproval = memoAC((resource: tcpinpoint.Resource) => (dispatch: any) => {
	dispatch({ type: "POST_APPROVAL_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}approvals`, { resource })
	.then((res) => { dispatch({ type: "POST_APPROVAL_SUCCESS", resource: res.data.approval }) })
	.catch((e) => { dispatch({ type: "POST_APPROVAL_FAILED", response: e }); });
});
