import classNames from "classnames";
import { Input } from "components/Inputs";
import { Col, ListGroup, ListGroupItem, LoadingState, Row, Title, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, memo, useEffect, useState } from "react";
import { Button, Center } from "v2/components/shared";

interface Props {
	project?: any;
	onUpdate: (state: any) => any;
	onSubmit: (props: any) => any;
	errors?: any;
	clearErrors?: () => void;
}

const Confirmation = ({ project, onUpdate, onSubmit }: Props) => {
	const [errors, setErrors] = useState(null);
	const setName = (name: string) => onUpdate({ ...project, name });
	const setKind = (kind: string) => onUpdate({ ...project, kind });
	const setReference = (reference: string) => onUpdate({ ...project, reference });
	const setPortfolioIds = (portfolio_ids: string[]) => onUpdate({ ...project, portfolio_ids });
	const { name, kind, reference, portfolio_ids } = project;

	const [portfolios, setPortfolios] = useState([]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "portfolio",
			perPage: 10,
			currentPage: 1,
			sortBy: "name",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setPortfolios(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		datasource.get("v2", "portfolios", {
			queryStringParameters: {
				sort: datasource.sortBy,
				per_page: datasource.perPage,
				page: datasource.currentPage
			}
		});
	}, [datasource, datasource.currentPage, datasource.perPage, datasource.sortBy]);

	if (!!_.get(errors, "response")) {
		return (
			<View resource="error" hideTitle>
				<Title>Something Went Wrong</Title>
				<Button onClick={() => setErrors(null)}>{translate("retry")}</Button>
			</View>
		);
	}

	return (
		<Center>
			<Row>
				<Col>
					<Input defaultState={{ name, errors }} name="name" onChange={setName} large />
					<Input defaultState={{ reference, errors }} name="reference" onChange={setReference} />
					<Input
						defaultState={{ kind, errors }}
						name="kind"
						onChange={setKind}
						options={["speciality", "food", "kiosk", "food-kiosk"]}
					/>
					{!datasource.isLoading ? (
						<Fragment>
							<label>Portfolios</label>
							<ListGroup
								resource="portfolios"
								collection={_.sortBy(portfolios, "name")}
								pagination={false}
								renderRow={(portfolio: tcpinpoint.Portfolio) => (
									<ListGroupItem
										icon="portfolios"
										key={_.get(portfolio, "id")}
										heading={_.get(portfolio, "name")}
										subheading={`with ${_.get(portfolio, "projects_length", 0)} projects`}
										selected={_.includes(portfolio_ids, portfolio.id)}
										onSelect={() => setPortfolioIds([...portfolio_ids, portfolio.id])}
										onUnselect={() => setPortfolioIds(_.filter(portfolio_ids, id => id !== portfolio.id))}
									/>
								)}
							/>
							<div className={classNames("margin-atuo", "flex justify-center")}>
								<Pagination datasource={datasource} />
							</div>
						</Fragment>
					) : (
						<LoadingState />
					)}

					<Button
						label="Create my Project!"
						className={classNames("text-white my-10 w-full", {
							" bg-success-darker shadow-button hover:shadow-hover": true
						})}
						onClick={onSubmit}
					/>
				</Col>
			</Row>
		</Center>
	);
};

export default memo(Confirmation);
