import React from "react";
import { useAppClass } from "lib/hooks";

const Aside = ({ children }: { children: JSX.Element | (JSX.Element | false)[] }) => {
	useAppClass("with-aside");
	if (!!children) {
		return <aside>{children}</aside>;
	}
	return null;
};
export default Aside;
