import React, { memo } from "react";
import classNames from "classnames";
import CheckOutlined from "./CheckOutlined";

const StepItem = ({
	current,
	title,
	index,
	isLast,
	onClick,
	vertical = false,
	className,
	clickable
}: {
	title?: string;
	index: number;
	current: number;
	isLast?: boolean;
	vertical?: boolean;
	onClick?: (index?: number) => any;
	className?: string;
	clickable?: boolean;
}) => {
	const isActive = current === index;
	const isFinished = current > index;
	const isWait = current < index;
	return (
		<div
			className={classNames("step-nav-item flex justify-center flex-1 items-center", className, {
				"lg:flex-none": isLast
			})}
		>
			<div
				className={classNames("step-nav-item-icon flex", {
					"flex-col items-center": vertical
				})}
			>
				<div
					className={classNames(
						"step-nav-item-icon-circle",
						"border-black-10 rounded-3",
						"h-6 w-6 leading-6",
						"text-center text-14",
						"mr-1/2",
						"relative",
						{
							border: !isActive,
							"text-black-40": !isActive,
							"text-white": isActive,
							"bg-blue-100": isActive,
							"border-blue-100": isFinished,
							"hover:cursor-pointer": !!clickable
						}
					)}
					onClick={() => !!onClick && onClick(index)}
				>
					{(isActive || isWait) && index + 1}
					{isFinished && (
						<CheckOutlined
							className={classNames("absolute top-4px left-4px", {
								"text-blue-100": isFinished
							})}
						/>
					)}
				</div>
				<div
					className={classNames(
						"leading-6",
						"whitespace-no-wrap",
						"hidden lg:block",
						"lg:align-middle lg:pl-1",
						"lg:text-16",
						{
							"lg:text-black-100": isActive,
							"lg:text-black-60": !isActive
						}
					)}
				>
					{!!title && title}
				</div>
			</div>
			{!isLast && (
				<div
					className={classNames("step-nav-item-line", "hidden lg:block", "lg:h-px", "lg:border-b", "lg:mx-2", {
						"lg:border-black-20": isActive || isWait,
						"lg:border-blue-100": isFinished,
						"lg:flex-grow": !isLast
					})}
				/>
			)}
		</div>
	);
};

export default memo(StepItem);
