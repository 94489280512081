import React from "react";
import { Route } from "react-router-dom";
import { UnprotectedLayout } from "layouts";

const UnprotectedRoute = (props: any) => (
	<UnprotectedLayout>
		<Route {...props} />
	</UnprotectedLayout>
);

export default UnprotectedRoute;
