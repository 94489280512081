import React, { useState, useEffect } from "react";
//@ts-ignore
import validate from "validate.js";
import { PlainTextInput, Input } from "../Inputs";
import { Button, Row, Col, ErrorBlock } from "../Shared";
import _ from "lodash";
import moment from "moment";
import "moment-business-days";

const constraints = { kind: { presence: true }, status: { presence: true } };

interface Props {
	onSubmit: (lease: tcpinpoint.Lease) => any;
	lease?: tcpinpoint.Lease;
	tenancy?: any;
	template?: tcpinpoint.Template;
	extended?: true | undefined;
}

export default (props: Props) => {
	const { lease, tenancy, onSubmit, template, extended = false } = props;
	const fitout_period = moment(_.get(lease, "fitout_ends_at")).diff(moment(_.get(lease, "handover_at")), "days");
	let initActualOpen = _.get(lease, "actual_rent_starts_at") || _.get(lease, "rent_starts_at");
	const [data, setData] = useState({
		...lease,
		fitout_period,
		net_lettable_area: tenancy?.area,
		actual_rent_starts_at: initActualOpen,
	});

	useEffect(() => {
		if (_.get(data, "actual_rent_starts_at") === null) {
			setData(_.set(data, "actual_rent_starts_at", _.get(data, "rent_starts_at")));
		}
	}, [data]);
	return (
		<Row>
			<Col>
				<ErrorBlock resource={lease} />
				<Input
					name="kind"
					defaultState={data}
					required={constraints}
					onChange={(kind: string) => setData(_.assign({}, data, { kind }))}
					options={
						_.includes(_.get(template, "tags"), "flinders")
							? ["lump-sum", "design-and-construct"]
							: ["fixed-term", "periodic", "at-will"]
					}
				/>
				<Input
					name="status"
					defaultState={data}
					required={constraints}
					showIf={!_.includes(_.get(template, "tags", []), "flinders")}
					onChange={(status: string) => setData(_.assign({}, data, { status }))}
					options={["opportunity", "draft_lease", "final_lease", "executed_lease", "renewal"]}
				/>
				<Input
					name="permitted_use"
					defaultState={data}
					required={constraints}
					showIf={extended}
					onChange={(permitted_use: string) => setData(_.assign({}, data, { permitted_use }))}
				/>
				<Input
					name="net_lettable_area"
					defaultState={data}
					required={constraints}
					showIf={extended}
					disabled
					onChange={(net_lettable_area: any) => setData(_.assign({}, data, { net_lettable_area }))}
				/>
				<Input
					name="seating_area"
					defaultState={data}
					required={constraints}
					showIf={extended}
					onChange={(seating_area: any) => setData(_.assign({}, data, { seating_area }))}
				/>
				<Input
					name="storage_area"
					defaultState={data}
					required={constraints}
					showIf={extended}
					onChange={(storage_area: any) => setData(_.assign({}, data, { storage_area }))}
				/>
				<hr />
				<Input
					name="base_rent_frequency"
					defaultState={data}
					required={constraints}
					showIf={extended}
					onChange={(base_rent_frequency: any) => setData(_.assign({}, data, { base_rent_frequency }))}
					options={["weekly", "fortnightly", "monthly"]}
				/>
				<Input
					name="rent_per_sqm_cents"
					defaultState={data}
					required={constraints}
					showIf={extended}
					hint="(per month, excluding taxes)"
					onChange={(rent_per_sqm_cents: string) => {
						const base_rent_cents = Math.round(
							parseInt(rent_per_sqm_cents, 10) * parseFloat(_.get(data, "net_lettable_area"))
						);
						setData(
							_.assign({}, data, {
								annual_rent_cents: base_rent_cents * 12,
								annual_rent_cents_per_sq_m: parseInt(rent_per_sqm_cents, 10) * 12,
								rent_per_sqm_cents,
								base_rent_cents,
							})
						);
					}}
				/>
				<Input
					name="base_rent_cents"
					defaultState={data}
					required={constraints}
					showIf={!_.includes(_.get(template, "tags", []), "flinders")}
					hint="(per month, excluding taxes)"
					onChange={(base_rent_cents: any) => setData(_.assign({}, data, { base_rent_cents }))}
				/>
				<Input
					name="rent_review_percent"
					defaultState={data}
					suffix="%"
					type="number"
					required={constraints}
					showIf={extended}
					onChange={(rent_review_percent: any) => setData(_.assign({}, data, { rent_review_percent }))}
				/>
				<Input
					name="statutory_outgoings_cents"
					defaultState={data}
					required={constraints}
					showIf={extended}
					hint="(excluding taxes)"
					onChange={(statutory_outgoings_cents: any) => setData(_.assign({}, data, { statutory_outgoings_cents }))}
				/>
				<Input
					name="operational_outgoings_cents"
					defaultState={data}
					required={constraints}
					showIf={extended}
					hint="(excluding taxes)"
					onChange={(operational_outgoings_cents: any) => setData(_.assign({}, data, { operational_outgoings_cents }))}
				/>
				<hr />
				<Input
					name="fitout_contribution_cents"
					defaultState={data}
					required={constraints}
					showIf={extended}
					onChange={(fitout_contribution_cents: any) => setData(_.assign({}, data, { fitout_contribution_cents }))}
				/>
				<PlainTextInput
					name="incentives"
					defaultState={data}
					required={constraints}
					// @ts-ignore
					showIf={extended}
					onChange={(incentives: any) => setData(_.assign({}, data, { incentives }))}
				/>

				<Input
					name="vacant_possession_at"
					defaultState={data}
					required={constraints}
					showIf={extended}
					max={_.get(data, "starts_at")}
					onChange={(vacant_possession_at: any) => setData(_.assign({}, data, { vacant_possession_at }))}
				/>
				<Input
					name="handover_at"
					min={_.get(data, "vacant_possession_at")}
					max={_.get(data, "ends_at")}
					defaultState={data}
					required={constraints}
					showIf={!_.includes(_.get(template, "tags", []), "flinders")}
					onChange={(handover_at: string) => {
						const date = _.get(data, "fitout_period") && moment(handover_at).add(_.get(data, "fitout_period"), "days");
						setData(
							_.assign({}, data, {
								handover_at,
								fitout_ends_at: date,
								starts_at: date,
								opening_at: date,
								rent_starts_at: date,
							})
						);
					}}
				/>

				<Input
					name="fitout_period"
					defaultState={data}
					required={constraints}
					showIf={!_.includes(_.get(template, "tags", []), "flinders")}
					type="number"
					onChange={(fitout_period: string) => {
						const date = _.get(data, "handover_at") && moment(_.get(data, "handover_at")).add(fitout_period, "days");
						setData(
							_.assign({}, data, {
								fitout_period,
								fitout_ends_at: date,
								starts_at: date,
								opening_at: date,
								rent_starts_at: date,
							})
						);
					}}
					suffix="Calendar Days"
				/>
				<Input
					name="fitout_ends_at"
					min={moment(_.get(data, "handover_at")).add(_.get(data, "fitout_period"), "days").format("YYYY-MM-DD")}
					defaultState={data}
					required={constraints}
					showIf={!_.includes(_.get(template, "tags", []), "flinders")}
					onChange={(fitout_ends_at: string) => {
						const date = moment(fitout_ends_at).format("YYYY-MM-DD");
						setData(
							_.assign({}, data, {
								fitout_ends_at: date,
								starts_at: date,
								opening_at: date,
								rent_starts_at: date,
							})
						);
					}}
				/>

				<Input
					name="opening_at"
					min={_.get(data, "fitout_ends_at")}
					label={_.includes(_.get(template, "tags", []), "flinders") ? "required_at" : "opening_at"}
					defaultState={data}
					required={constraints}
					onChange={(opening_at: any) => setData(_.assign({}, data, { opening_at }))}
				/>
				<Input
					name="actual_opening_at"
					type="date"
					label={_.includes(_.get(template, "tags", []), "flinders") ? "required_at" : "actual_opening_at"}
					defaultState={data}
					required={constraints}
					onChange={(actual_opening_at: any) => {
						setData(_.assign({}, data, { actual_opening_at }));
					}}
				/>
				<Input
					name="starts_at"
					defaultState={data}
					required={constraints}
					showIf={extended}
					label="lease_starts_at"
					max={_.get(data, "ends_at")}
					onChange={(starts_at: string) => {
						let ends_at = _.get(data, "ends_at");
						if (_.get(data, "duration") && _.get(data, "rent_starts_at")) {
							ends_at = moment(starts_at).add(_.get(data, "duration"), "months").format("YYYY-MM-DD");
						}
						setData(_.assign({}, data, { starts_at, ends_at, rent_starts_at: starts_at }));
					}}
				/>
				{!_.includes(["at-will"], _.get(data, "kind")) && (
					<Input
						defaultState={data}
						required={constraints}
						showIf={!_.includes(_.get(template, "tags", []), "flinders")}
						name="duration"
						label="lease_duration"
						suffix="months"
						type="number"
						onChange={(duration: string) => {
							let ends_at = _.get(data, "ends_at");
							if (_.get(data, "starts_at") && duration) {
								ends_at = moment(_.get(data, "starts_at")).add(duration, "months").format("YYYY-MM-DD");
							}
							setData(_.assign({}, data, { duration, ends_at }));
						}}
					/>
				)}

				{!_.includes(["at-will"], _.get(data, "kind")) && (
					<Input
						name="ends_at"
						defaultState={data}
						required={constraints}
						showIf={!_.includes(_.get(template, "tags", []), "flinders")}
						onChange={(ends_at: any) => setData(_.assign({}, data, { ends_at }))}
					/>
				)}
				{_.includes(["periodic"], _.get(data, "kind")) && (
					<Input
						name="lease_renewals"
						type="number"
						defaultState={data}
						required={constraints}
						showIf={!_.includes(_.get(template, "tags", []), "flinders")}
						onChange={(lease_renewals: any) => setData(_.assign({}, data, { lease_renewals }))}
					/>
				)}
				<Input
					defaultState={data}
					required={constraints}
					showIf={extended}
					name="rent_starts_at"
					max={_.get(data, "ends_at")}
					onChange={(rent_starts_at: any) => setData(_.assign({}, data, { rent_starts_at }))}
				/>
				<Input
					name="actual_rent_starts_at"
					type="date"
					label={_.includes(_.get(template, "tags", []), "flinders") ? "required_at" : "actual_rent_starts_at"}
					defaultState={data}
					required={constraints}
					onChange={(actual_rent_starts_at: any) => {
						setData(_.assign({}, data, { actual_rent_starts_at }));
					}}
				/>
				<Button
					onClick={() => {
						const errors = validate(data, constraints);
						if (!!errors) {
							setData(_.assign({}, data, { errors }));
						} else {
							onSubmit(data as tcpinpoint.Lease);
						}
					}}
				/>
			</Col>
		</Row>
	);
};
