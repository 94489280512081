import _ from "lodash";
import { getCommentsFor, deleteComment } from "actions";
import { Inline as User } from "components/Users";
import { ListGroup, Date as DateComponent } from "components/Shared";
import { Row } from "components/Comments";
import { translate } from "lib";
import { UserContext } from "context";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useContext, memo } from "react";

interface Props {
	events?: any[];
	resource: tcpinpoint.Resource;
	onReply: (arg: any) => void;
}

const Comments = ({ events, onReply, resource }: Props) => {
	const { cable } = useContext(UserContext) as { cable: any };
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getCommentsFor(resource));
		cable.subscriptions.create(
			{ channel: "CommentsChannel", resource_id: resource.id },
			{
				connected: () => {
					console.log("CommentsChannel: connected");
				},
				received: (payload: any) => {
					// console.log("CommentsChannel: payload: %o", payload);
					dispatch(getCommentsFor(resource));
				}
			}
		);
	}, [cable, dispatch, resource]);

	const collection = useSelector((store: tcpinpoint.Store) =>
		_.sortBy(_.filter(store.comments, { commentable_id: resource.id }), "created_at")
	) as tcpinpoint.Comment[];
	return (
		<ListGroup
			hidePageCount
			pagination={false}
			collection={_.filter(collection, { reply_to_id: null })}
			resource="comments"
			renderRow={comment =>
				!!comment.link ? (
					<Row
						collection={collection}
						key={comment.id}
						comment={comment}
						onReply={onReply}
						onDelete={() => dispatch(deleteComment(comment, resource))}
					/>
				) : (
					<div key={comment.created_at} className={`list-group-item event event-${comment.name}`}>
						{translate(comment.name)} by <User user={comment.user} /> <DateComponent date={comment.created_at} />
					</div>
				)
			}
		/>
	);
};

export default memo(Comments);
