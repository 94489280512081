import React, { memo } from "react";
import classNames from "classnames";
import _ from "lodash";

const Icon = ({ document, className }: { document: tcpinpoint.Document; className?: string | false }) => {
	if (document) {
		const { extension, mimetype } = document;
		let iconClass = "-alt";
		if (extension === "pdf") {
			iconClass = "-pdf";
		} else if (_.get(document, "mimetype") === "application/x-tcpinpoint-document") {
		} else if (extension === "mp3" || extension === "m4a") {
			iconClass = "-audio";
		} else if (extension === "ppt" || extension === "pptx") {
			iconClass = "-powerpoint";
		} else if (extension === "docx" || extension === "doc") {
			iconClass = "-word";
		} else if (extension === "xlsx" || extension === "xls") {
			iconClass = "-excel";
		} else if (mimetype && mimetype.split("/")[0] === "video") {
			iconClass = "-video";
		} else if ((mimetype && mimetype.split("/")[0] === "image") || extension === "dwg") {
			iconClass = "-image";
		}
		return <i className={classNames("document-icon", "far", `fa-file${iconClass}`, className)} />;
	}
	return null;
};

export default memo(Icon);
