import React, { memo } from "react";
import { Long as Logo } from "v2/components/shared/icons";
import classNames from "classnames";

const Header = ({ className }: { className?: string }) => {
	return (
		<div className={classNames("flex flex-col justify-center items-center", className)}>
			<div className="flex-1" />
			<Logo className="flex-3" />
			<div className="flex-1" />
		</div>
	);
};

export default memo(Header);
