import classNames from "classnames";
import { LoadingState, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ActivityItemDynamic } from "./Item";

interface Props {
	queryStringParameters: {};
	name?: string;
}

const ActivityDynamic = ({ queryStringParameters }: Props) => {
	const [jsonApiActivities, setJsonApiActivities] = useState({} as any);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "activity",
			perPage: 10,
			currentPage: 1,
			sortBy: "-created_at",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setJsonApiActivities(response.jsonApiResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		// activity#index
		datasource.get("v2", "feed", {
			queryStringParameters: _.extend({}, queryStringParameters, {
				sort: "-created_at",
				per_page: datasource.perPage,
				page: datasource.currentPage
			})
		});
	}, [datasource, datasource.currentPage, datasource.perPage, queryStringParameters]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<View resource="RESOURCE_FEEDS">
			<div id="activities">
				{_.map(jsonApiActivities?.data, (activity: tcpinpoint.ActivityItem) => {
					return (
						<div
							key={activity.attributes.item.id}
							id={activity.attributes.item.id}
							className={classNames(
								"activity-item",
								_.get(activity.attributes.item, "object.type"),
								activity.attributes.item.type
							)}
						>
							<ActivityItemDynamic activity={activity} />
						</div>
					);
				})}
			</div>
			<div className={classNames("margin-auto", "flex justify-center")}>
				<Pagination datasource={datasource} />
			</div>
		</View>
	);
};

export default ActivityDynamic;
