import React, { memo } from "react";

const Step = ({
	children,
	className
}: {
	title?: string;
	children?: React.ReactNode;
	className?: string;
	clickable?: boolean;
}) => {
	return <div className={className}>{children}</div>;
};

export default memo(Step);
