import React, { memo, useContext } from "react";
import { View, ListGroup } from "components/Shared";
import { Row } from "components/Permissions";
import _ from "lodash";
import { UserContext } from "context";

const Permissions = ({ resource }: { name?: string; resource: any }) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	if (!!_.get(resource, "permissions")) {
		return (
			<View resource="permissions">
				<ListGroup
					resource={"permissions"}
					pagination={false}
					collection={_.uniqBy(
						[{ user: resource.created_by, can: ["creator"] }, ..._.sortBy(resource.permissions, "user.surname")],
						"user.id"
					)}
					renderRow={row => {
						// console.log(row);
						return <Row hideDelete {...row} resource={resource} key={row.id || currentUser.id} />;
					}}
				/>
			</View>
		);
	}
	return null;
};

export default memo(Permissions);
