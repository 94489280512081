import { Input } from "components/Inputs";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import React, { memo, useEffect, useState, useCallback } from "react";

interface Props {
	floorId: string;
	preselectedTenancyId?: string;
	onChange?: (tenancyId: string) => void;
}

const constraints = {
	name: {
		presence: {
			message: "^ is required"
		}
	}
};

const SelectDynamic = ({ floorId, onChange, preselectedTenancyId }: Props) => {
	const [options, setOptions] = useState([] as any[]);
	const [defaultState, setDefaultState] = useState({ tenancy_id: preselectedTenancyId } as any);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "tenancy",
			perPage: 1000,
			currentPage: 1,
			sortBy: "name"
		})
    );
    
    const onSelectOption = useCallback((tenancyId: string ) => {
			if (!_.isNil(onChange)) onChange(tenancyId);
			setDefaultState({ tenancy_id: tenancyId });
		}, [onChange]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			const options = _.map(response.normalizedMainModelResponse, (tenancy: any) => {
				return {
					item: tenancy,
					value: tenancy.id,
		            label: tenancy.name
				};
			});
            setOptions(options);
            onSelectOption(_.get(options[0], "value")) // default select the first one
		});
		return () => sub.unsubscribe();
	}, [datasource, onSelectOption]);

	// API call
	useEffect(() => {
		if (_.isEmpty(floorId)) return;
		datasource.get("v2", "tenancies/index_with_joins", {
			queryStringParameters: {
				floor_id: floorId,
				sort: datasource.sortBy,
				per_page: datasource.perPage,
				page: datasource.currentPage
			}
		});
	}, [datasource, floorId, datasource.currentPage, datasource.perPage, datasource.sortBy]);

	if (_.isEmpty(floorId) || options.length === 0) return null;

	return (
		<Input
			onChange={onSelectOption}
			name="tenancy_id"
			defaultState={defaultState}
			required={constraints}
			options={options}
			showIf={true}
		/>
	);
};

export default memo(SelectDynamic);
