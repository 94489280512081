import { Date } from "components/Shared";
import FeatureFlag from "components/Shared/FeatureFlag/FeatureFlag";
import { translate } from "lib";
import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import axios from "v2/utils/axios";

interface Props {
	resource: any;
	attributeFilter?: string[];
}

const HistoryCard = ({ resource, attributeFilter }: Props) => {
	const [history, setHistory] = useState();

	useEffect(() => {
		if (!resource.link) return;
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}history`, { params: { id: resource.link } })
		.then((res) => setHistory(_.uniqBy(res.data, "created_at") as any));
	}, [resource]);

	return (
		<div className="history-card">
			<header className="component-header">
				<h2>Edit History</h2>
				<FeatureFlag title={"beta"} tooltip={"this feature is in development"} />
			</header>
			<table className="table">
				<tbody>
					<tr>
						<th>attribute name</th>
						<th>existing scheduled date</th>
						<th>updated scheduled date</th>
						<th>date of change</th>
						<th>changed by</th>
					</tr>
					{_.map(history, (row: any) =>
						_.map(_.keys(row.changes), (attribute: string) => {
							if (!attributeFilter || _.includes(attributeFilter, attribute)) {
								return (
									<tr key={row.id}>
										<td>{translate(attribute)}</td>
										<td>
											<Date date={row.changes[attribute][0]} showTime={false} />
										</td>
										<td>
											<Date date={row.changes[attribute][1]} showTime={false} />
										</td>
										<td>
											<Date date={row.created_at} />
										</td>
										<td>{_.get(row, "user.name", "tcbot")}</td>
									</tr>
								);
							}
							return null;
						})
					)}
				</tbody>
			</table>
		</div>
	);
};

export default memo(HistoryCard);
