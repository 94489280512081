/* eslint arrow-body-style: ["error", "as-needed", { requireReturnForObjectLiteral: true }]*/
import "whatwg-fetch";
import React, { useState } from "react";
// @ts-ignore
import AsyncSelect from "react-select/async";

import classNames from "classnames";
import { Feedback, Label } from "components/Inputs";
import _ from "lodash";
import axios from "v2/utils/axios";

const Lookup = (props: any) => {
	const [state] = useState({});
	const { name, className, children, required, defaultState } = props;

	const loadOptions = (inputValue: any, callback: any) => {
		if (inputValue.length > 3) {
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}localities/search`, { params: { q: inputValue } })
				.then((res) => 
					callback(
						_.map(_.uniqBy(res.data.json, "id"), (item: any) => {
							return { value: item.id, label: `${item.name}, ${item.region.name}`, item };
						})
					)
				);
		}
	};

	return (
		<div
			className={classNames("form-group", `form-control-${name}`, className, {
				className,
				"is-required": required && (required.locality || required.locality_id),
				"has-danger":
					defaultState && defaultState.errors && (defaultState.errors.locality || defaultState.errors.locality_id)
			})}
		>
			<Label {...props} name="locality" />
			<AsyncSelect
				name={name}
				classNamePrefix="react-select"
				autoFocus={false}
				// value={state.value}
				onChange={(option: any, _: any) => props.onChange(option.item)}
				loadOptions={(input: any, callback: any) => loadOptions(input, callback)}
			/>
			<Feedback {...state} {...props} name="locality" />
			{children && children}
		</div>
	);
};

export default Lookup;

// export default class Lookup extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {};
// 	}

// 	loadOptions(inputValue, callback) {
// 		if (inputValue.length > 3) {
// 			API.get("v1", "localities/search", { queryStringParameters: { q: inputValue } }).then(json =>
// 				callback(
// 					_.map(_.uniqBy(json, "id"), item => {
// 						return { value: item.id, label: `${item.name}, ${item.region.name}`, item };
// 					})
// 				)
// 			);
// 		}
// 	}

// 	render() {
// 		const { name, className, children, required, defaultState } = this.props;

// 		return (
// 			<div
// 				className={classNames("form-group", `form-control-${name}`, className, {
// 					className,
// 					"is-required": required && (required.locality || required.locality_id),
// 					"has-danger":
// 						defaultState && defaultState.errors && (defaultState.errors.locality || defaultState.errors.locality_id)
// 				})}
// 			>
// 				<Label {...this.props} name="locality" />
// 				<AsyncSelect
// 					name={name}
// 					classNamePrefix="react-select"
// 					autoFocus={false}
// 					value={this.state.value}
// 					onChange={(option, action) => this.props.onChange(option.item)}
// 					loadOptions={(input, callback) => this.loadOptions(input, callback)}
// 				/>
// 				<Feedback {...this.state} {...this.props} name="locality" />
// 				{children && children}
// 			</div>
// 		);
// 	}
// }
