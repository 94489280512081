import classNames from "classnames";
import React from "react";

const ArrowIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path d="M5 12H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12 5L19 12L12 19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const HomeIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path
				d="M2 5.99998L8 1.33331L14 5.99998V13.3333C14 13.6869 13.8595 14.0261 13.6095 14.2761C13.3594 14.5262 13.0203 14.6666 12.6667 14.6666H3.33333C2.97971 14.6666 2.64057 14.5262 2.39052 14.2761C2.14048 14.0261 2 13.6869 2 13.3333V5.99998Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M6 14.6667V8H10V14.6667" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const ProjectIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path
				d="M14.6663 11.6667C14.6663 12.0203 14.5259 12.3594 14.2758 12.6095C14.0258 12.8595 13.6866 13 13.333 13H2.66634C2.31272 13 1.97358 12.8595 1.72353 12.6095C1.47348 12.3594 1.33301 12.0203 1.33301 11.6667V2.33333C1.33301 1.97971 1.47348 1.64057 1.72353 1.39052C1.97358 1.14048 2.31272 1 2.66634 1H5.99967L7.33301 3H13.333C13.6866 3 14.0258 3.14048 14.2758 3.39052C14.5259 3.64057 14.6663 3.97971 14.6663 4.33333V11.6667Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const TemplateIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path
				d="M9.33366 1.33331H4.00033C3.6467 1.33331 3.30756 1.47379 3.05752 1.72384C2.80747 1.97389 2.66699 2.31302 2.66699 2.66665V13.3333C2.66699 13.6869 2.80747 14.0261 3.05752 14.2761C3.30756 14.5262 3.6467 14.6666 4.00033 14.6666H12.0003C12.3539 14.6666 12.6931 14.5262 12.9431 14.2761C13.1932 14.0261 13.3337 13.6869 13.3337 13.3333V5.33331L9.33366 1.33331Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M9.33301 1.33331V5.33331H13.333" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.6663 8.66669H5.33301" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.6663 11.3333H5.33301" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M6.66634 6H5.99967H5.33301" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const PropertyIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path
				d="M14 6.66663C14 11.3333 8 15.3333 8 15.3333C8 15.3333 2 11.3333 2 6.66663C2 5.07533 2.63214 3.5492 3.75736 2.42399C4.88258 1.29877 6.4087 0.666626 8 0.666626C9.5913 0.666626 11.1174 1.29877 12.2426 2.42399C13.3679 3.5492 14 5.07533 14 6.66663Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8 8.66663C9.10457 8.66663 10 7.7712 10 6.66663C10 5.56206 9.10457 4.66663 8 4.66663C6.89543 4.66663 6 5.56206 6 6.66663C6 7.7712 6.89543 8.66663 8 8.66663Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const TaskIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path d="M3 8H15" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1 4H13" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1 12H13" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const ReportIcon = ({ className }: { className?: string }) => {
	return (
		<svg 
			width="18" 
			height="18" 
			viewBox="0 0 24 24"
			fill="none" 
			xmlns="http://www.w3.org/2000/svg" 
			className={classNames("stroke-current", className)}
		>
			<path d="M12 2L2 7L12 12L22 7L12 2Z" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M2 17L12 22L22 17"  strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M2 12L12 17L22 12"  strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

	);
};

const NotificationIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path
				d="M12 5.33334C12 4.27248 11.5786 3.25506 10.8284 2.50492C10.0783 1.75477 9.06087 1.33334 8 1.33334C6.93913 1.33334 5.92172 1.75477 5.17157 2.50492C4.42143 3.25506 4 4.27248 4 5.33334C4 10 2 11.3333 2 11.3333H14C14 11.3333 12 10 12 5.33334Z"
				strokeWidth="1.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.15335 14C9.03614 14.2021 8.86791 14.3698 8.6655 14.4864C8.46309 14.6029 8.2336 14.6643 8.00001 14.6643C7.76643 14.6643 7.53694 14.6029 7.33453 14.4864C7.13212 14.3698 6.96389 14.2021 6.84668 14"
				strokeWidth="1.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const SignOutIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path
				d="M12.2404 4.42667C13.0793 5.26586 13.6506 6.33496 13.8819 7.49879C14.1133 8.66263 13.9943 9.86893 13.5402 10.9652C13.086 12.0614 12.3169 12.9984 11.3303 13.6576C10.3436 14.3168 9.18368 14.6686 7.99707 14.6686C6.81047 14.6686 5.65051 14.3168 4.66386 13.6576C3.6772 12.9984 2.90817 12.0614 2.45398 10.9652C1.9998 9.86893 1.88087 8.66263 2.11222 7.49879C2.34357 6.33496 2.91482 5.26586 3.75374 4.42667"
				strokeWidth="1.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M8 1.33334V8.00001" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const PortfolioIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("stroke-current", className)}
		>
			<path d="M21 8V21H3V8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M23 3H1V8H23V3Z" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10 12H14" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const DateIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("inline-block stroke-current", className)}
		>
			<path
				d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M16 2V6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8 2V6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3 10H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const DownloadIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("inline-block stroke-current", className)}
		>
			<path
				d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
				className={classNames("inline-block stroke-current align-middle", className)}
			/>
			<path
				d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M16 2V6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8 2V6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3 10H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M7 10L12 15L17 10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12 15V3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

const TodoIcon = ({ className }: { className?: string }) => {
	return (
		<svg 
			width="18" 
			height="18" 
			viewBox="0 0 24 24" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg" 
			className={classNames("stroke-current", className)}>
				<path d="M8 6H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M8 12H21"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M8 18H21"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M3 6H3.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M3 12H3.01"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M3 18H3.01"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

	);
};

const icons = {
	home: HomeIcon,
	projects: ProjectIcon,
	templates: TemplateIcon,
	properties: PropertyIcon,
	portfolios: PortfolioIcon,
	tasks: TaskIcon,
	reports: ReportIcon,
	todos: TodoIcon,
	notifications: NotificationIcon,
	signout: SignOutIcon,
	arrow: ArrowIcon,
	date: DateIcon,
	download: DownloadIcon
} as { [key: string]: any };

export default (name: string, className?: string) => {
	const Component = icons[name];
	return <Component className={className} />;
};
