import classNames from "classnames";
import { ListGroup, ListGroupControl, ListGroupItem, LoadingState, ModalHeader, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import { LastMomentTriggerAPIContext } from "context";
import { useDefaults } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";

const OneProjectLine = ({ portfolioId, project }: any) => {
	const { setLastMoment } = useContext(LastMomentTriggerAPIContext);
	const [, renderTrigger] = useState(moment());
	const { id: projectId } = project;
	const [datasource] = useState(
		new Datasource({
			mainModelName: "portfolio",
			renderTrigger: renderTrigger,
		})
	);
	const isProjectConnected = () => _.some(project.portfolios, (portfolio) => portfolio.id === portfolioId);
	const getTenancyAndPropertyName = useCallback(() => {
		let property_name = _.get(project.property_summary, "name");
		let tenancy_name = _.compact([
			_.get(project, "tenancy_name") !== project.name && _.get(project, "tenancy_name"),
			_.get(project, "building_name") ? ` at ${_.get(project, "building_name")}` : null,
		]).join("");
		return `${tenancy_name} - ${property_name}`;
	}, [project]);

	const addProjectToPortfolio = () => {
		datasource
			.post(
				"v2",
				`portfolios/${portfolioId}/add-project`,
				{ queryStringParameters: { project_id: projectId } },
				{ updateIsLoding: true }
			)
			.then(() => setLastMoment(moment()))
			.catch();
	};

	const removeProjectFromPortfolio = () => {
		datasource
			.post(
				"v2",
				`portfolios/${portfolioId}/remove-project`,
				{ queryStringParameters: { project_id: projectId } },
				{ updateIsLoding: true }
			)
			.then(() => setLastMoment(moment()))
			.catch();
	};

	if (datasource.isLoading) return <LoadingState />;

	return (
		<ListGroupItem
			key={projectId}
			heading={project.name}
			subheading={getTenancyAndPropertyName()}
			selected={isProjectConnected()}
			onSelect={addProjectToPortfolio}
			onUnselect={removeProjectFromPortfolio}
		/>
	);
};

interface Props extends ReactModal.Props {
	portfolioId: string;
	isOpen: boolean;
	onRequestClose: () => void;
}

const AddProjectToPortfolio = ({ portfolioId, isOpen, onRequestClose }: Props) => {
	const { setLastMoment: setParentLastMoment } = useContext(LastMomentTriggerAPIContext);
	const [lastMoment, setLastMoment] = useState(moment());
	const lastMomentProviderValue = { lastMoment, setLastMoment };
	const defaults = useDefaults() as { sortBy: string; filteredBy: string };
	const [projects, setProjects] = useState([] as tcpinpoint.Project[]);
	const [filteredBy, setFilteredBy] = useState(defaults.filteredBy || "open");
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "project",
			perPage: 20,
			currentPage: 1,
			sortBy: "name",
			renderTrigger: renderTrigger,
		})
	);

	const onModalClose = useCallback(() => {
		setParentLastMoment(moment());
		if (onRequestClose) onRequestClose();
	}, [setParentLastMoment, onRequestClose]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setProjects(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		if (!isOpen) return;
		datasource.get("v2", "projects", {
			queryStringParameters: {
				status: filteredBy,
				sort: datasource.sortBy,
				per_page: datasource.perPage,
				page: datasource.currentPage,
				fields: JSON.stringify({ project: ["name", "property_summary", "tenancy_name"] }),
				associations: JSON.stringify(["portfolios"]),
			},
		});
	}, [
		portfolioId,
		isOpen,
		lastMoment,
		datasource,
		datasource.currentPage,
		datasource.perPage,
		datasource.sortBy,
		filteredBy,
	]);

	return (
		<Modal
			isOpen={isOpen}
			className="archive"
			onRequestClose={onModalClose}
			contentLabel="close-project"
			ariaHideApp={false}
		>
			<ModalHeader title="add_project" onRequestClose={onModalClose} />
			<section>
				<View resource="projects" hideTitle={true}>
					{datasource.isFirstTimeLoading ? (
						<LoadingState />
					) : (
						<>
							<ListGroupControl
								sortOptions={["name", "tenancy_name", "property_name"]}
								onSortChange={(newSortBy) => {
									datasource.setCurrentPage(1);
									datasource.setSortBy(newSortBy);
								}}
								selectedSort={datasource.sortBy}
								selectedView={"list"}
								filterOptions={["open", "ahead", "behind", "archived", "defects"]}
								onFilterChange={(newFilterBy) => {
									datasource.setCurrentPage(1);
									setFilteredBy(newFilterBy);
								}}
								selectedFilter={filteredBy}
							/>
							<LastMomentTriggerAPIContext.Provider value={lastMomentProviderValue}>
								<ListGroup
									resource="projects"
									collection={projects}
									pagination={false}
									renderRow={(project: tcpinpoint.Project) => (
										<OneProjectLine key={project.id} portfolioId={portfolioId} project={project} />
									)}
								/>
							</LastMomentTriggerAPIContext.Provider>
							<div className={classNames("margin-atuo", "flex justify-center")}>
								<Pagination datasource={datasource} />
							</div>
						</>
					)}
				</View>
			</section>
		</Modal>
	);
};

export default memo(AddProjectToPortfolio);
