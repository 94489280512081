import React from "react";
import { View, Title } from "components/Shared";
import { translate } from "lib";
export default class Missing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("app").classList.add("is-missing");
  }

  UNSAFE_componentWillReceiveProps() {
    document.getElementById("app").classList.add("is-missing");
  }

  componentWillUnmount() {
    document.getElementById("app").classList.remove("is-missing");
  }

  render() {
    const { children } = this.props;
    return (
      <section>
        <View resource="missing" hideTitle>
          <h1 className="display-1">404</h1>
          <Title>File Not Found</Title>
          {translate("missing")} {children}
        </View>
      </section>
    );
  }
}
