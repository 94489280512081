import _ from "lodash";

export interface JsonApiError {
	title?: string;
	status: number;
	detail?: string[];
	source?: {
		parameter?: any;
	};
}

export default class ErrorFormatter {
	private jsonApiError: JsonApiError;
	private normalError: any;
	private _messages: string = "";

	constructor(httpResponse: any) {
		this.jsonApiError = _.get(httpResponse, "data.errors");
		this.normalError = _.get(httpResponse, "data", {});
		this.format();
	}

	public get messages(): string {
		return this._messages;
	}

	private format(): void {
		let messages = _.get(this.jsonApiError, "detail", "");
		if (_.isEmpty(messages)) {
			if (_.isArray(this.normalError)) {
				// normal jsonapi exception
				messages = _.map(this.normalError, (message, type) => `${message}`);
			} else if (!_.isEmpty(_.get(this.normalError, "exception"))) {
				// rails runtime exception
				messages = this.normalError.exception.match(/#<RuntimeError:(.*)>/)[1];
			}
		}

		this._messages = _.isArray(messages) ? _.join(messages, ", ") : messages;
	}
}
