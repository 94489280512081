import React, { memo, useState } from "react";
import { Row, Col } from "components/Shared";
import { Button } from "v2/components/shared";
import { Input } from "components/Inputs";
import { Center } from "v2/components/shared";
import Lookup from "components/Inputs/Lookup";

//@ts-ignore
import validate from "validate.js";
import _ from "lodash";
import classNames from "classnames";

const constraints = { name: { presence: true }, contact_email: { email: true } };

interface Props {
	tenant: tcpinpoint.Tenant;
	onSubmit: (object: any) => any;
	extended?: boolean;
}

const Form = ({ tenant, onSubmit, extended = false }: Props) => {
	const [data, setData] = useState(tenant);
	return (
		<Center>
			<Row>
				<Col>
					<Input
						defaultState={data}
						required={constraints}
						label="Lessee"
						name="name"
						onChange={(name: string) => setData(_.assign({}, data, { name }))}
					/>
					<Input
						defaultState={data}
						required={constraints}
						name="trading_name"
						onChange={(trading_name: string) => setData(_.assign({}, data, { trading_name }))}
					/>

					<Input
						defaultState={data}
						required={constraints}
						name="contact_name"
						onChange={(contact_name: string) => setData(_.assign({}, data, { contact_name }))}
					/>

					<Input
						defaultState={data}
						required={constraints}
						name="contact_email"
						type="email"
						onChange={(contact_email: string) => setData(_.assign({}, data, { contact_email }))}
					/>

					<Input
						defaultState={data}
						required={constraints}
						name="phone_number"
						onChange={(phone_number: string) => setData(_.assign({}, data, { phone_number }))}
					/>

					{/* <Address
					defaultState={data}
					required={constraints}
					name="addresses"
					onChange={(addresses: string) => setData(_.assign({}, data, { addresses }))}
				/> */}
					<Input
						defaultState={data}
						required={constraints}
						showIf={extended}
						name="source"
						onChange={(source: string) => setData(_.assign({}, data, { source }))}
					/>
					<Input
						defaultState={data}
						required={constraints}
						showIf={extended}
						name="sector"
						onChange={(sector: string) => setData(_.assign({}, data, { sector }))}
					/>
					<Input
						defaultState={data}
						required={constraints}
						showIf={extended}
						name="purpose"
						onChange={(purpose: string) => setData(_.assign({}, data, { purpose }))}
					/>
					<Lookup
						collection="business_codes"
						defaultState={data}
						required={constraints}
						showIf={extended}
						name="business_code"
						onChange={(option: any) =>
							setData(_.assign({}, data, { business_code: option, business_code_id: option.value }))
						}
					/>
					<Input
						defaultState={data}
						required={constraints}
						showIf={extended}
						name="source"
						onChange={(source: string) => setData(_.assign({}, data, { source }))}
					/>

					<Button
						label="Next"
						className={classNames("float-right text-white mt-5", {
							"bg-blue-100 hover:shadow-hover shadow-button": true
						})}
						onClick={() => {
							const errors = validate(data, constraints);
							if (!!errors) {
								setData(_.assign({}, data, { errors }));
							} else {
								onSubmit(data);
							}
						}}
					/>
				</Col>
			</Row>
		</Center>
	);
};
export default memo(Form);
