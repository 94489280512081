import React, { memo } from "react";
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const PhoneNumber = ({ phone_number }: { phone_number: string }) => {
	if (!!phone_number) {
		const strippedNumber = `+${phone_number.replace(/^04/, "614").replace(/[^0-9]/g, "")}`;
		const parsedNumber = parsePhoneNumberFromString(strippedNumber)
		if (parsedNumber) {
			const formattedNumber = parsedNumber.formatNational();
			return (
				<a className="contact-phone-number" href={`tel:${formattedNumber}`}>
					{formattedNumber}
				</a>
			);
		}
		return <a className="contact-phone-number" href={`tel:${strippedNumber}`}>{strippedNumber}</a>

	}
	return <span className="muted phone-number">Unknown</span>;
};

export default memo(PhoneNumber);