import React from "react";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import classNames from "classnames";
import axios from "v2/utils/axios";

export default class ProjectLookup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	loadOptions(inputValue, callback) {
		setTimeout(() => {
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/search`, { params: { q: inputValue } })
				.then((res) => 
					callback(
						_.map(_.uniqBy(res.data.json, "id"), item => {
							return {
								value: item.id,
								label: `${item.name} (${item.email})`,
								item
							};
						})
					)
				);
		});
	}
	render() {
		const { name, className, children, required, defaultState, onChange } = this.props;
		const { value } = this.state;
		return (
			<div
				className={classNames("form-group", `form-control-${name}`, className, {
					className,
					"is-required": required && (required.property || required.property_id),
					"has-danger":
						defaultState && defaultState.errors && (defaultState.errors.property || defaultState.errors.property_id)
				})}
			>
				<AsyncSelect
					name={name}
					autoFocus={false}
					className="react-select"
					value={value}
					onChange={(option, _) => onChange && onChange(option.item)}
					loadOptions={(input, callback) => this.loadOptions(input, callback)}
				/>
				{children && children}
			</div>
		);
	}
}
