import React, { memo } from "react";
import { Report } from "components/Templates";
import { View } from "components/Shared";

const Reports = (props: any) => {
  const { reports } = props;
  return (
    <View resource="reports" hideTitle>
      {(reports || []).map((report: any) => <Report key={report.id} report={report} />)}
    </View>
  );

}
// class Reports extends React.Component {
//   constructor(props, context) {
//     super(props, context);
//     this.state = {};
//   }

//   render() {
//     return [
//       <View resource="reports" hideTitle>
//         {(reports || []).map(report => <Report key={report.id} report={report} />)}
//       </View>
//     ];
//   }
// }

export default memo(Reports);
