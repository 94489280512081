import { Show as Avatar } from "components/Avatar";
import Projects from "components/Projects/CollectionDynamic";
import { Breadcrumbs, Header, LoadingState, Workspace } from "components/Shared";
// import { CardDynamic as Documents } from "components/Documents";
// import { Card as Permissions } from "components/Permissions";
// import { Collection as Tenancies } from "components/Tenancies";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const Show = ({ match }: RouteComponentProps) => {
	const id: string = _.get(match.params, "id");
	const [building, setBuilding] = useState({} as any);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "building", renderTrigger: renderTrigger }));
	const property = useMemo(() => _.get(building, "property", {}), [building]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setBuilding(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource, setBuilding]);

	// API call
	useEffect(() => {
		if (!_.isNil(id))
			datasource.get("v2", `buildings/${id}`, {
				queryStringParameters: {
					fields: JSON.stringify({
						property: ["name", "avatar"],
						addresses: ["street_1", "street_2", "locality_name", "region_iso_alpha"]
					})
				}
			});
	}, [id, datasource]);

	if (!_.isEmpty(building)) {
		return (
			<React.Fragment>
				<Breadcrumbs
					key="building-breadcrumbs"
					items={[
						{ link: "/properties", title: translate("properties") },
						{ link: `/properties/${_.get(property, "id")}`, title: _.get(property, "name") },
						!!building.precinct && { title: building.precinct },
						{ title: translate("buildings") },
						{ title: _.get(building, "name") }
					]}
				/>
				<Header
					stamp={<Avatar url={property.avatar} name={_.get(building, "name")} />}
					title={_.compact([building.precinct, _.get(building, "name")]).join(" / ")}
					subtitle={
						_.get(property, "name") !== _.get(building, "name")
							? _.get(property, "name")
							: _.compact([
									_.get(building, "addresses[0].locality_name"),
									_.get(building, "addresses[0].region_iso_alpha")
							  ]).join(", ")
					}
				/>
				<Workspace
					id={building.id}
					className={["show", "building"]}
					tabs={[
						<Projects name="projects" key="projects" queryStringParameters={{ building_id: id }} />
						// <Documents name="documents" key="documents" resource={building} queryStringParameters={{building_id : id}} />,
						// <Tenancies name="tenancies" property={property} tenancies={_.get(building, "tenancies")} key="tenancies" />,
						// <Details name="dashboard" building={building} property={property} key={"dashboard"} />
						// <Permissions resource={property} name="permissions" key="permissions" />
					]}
				/>
			</React.Fragment>
		);
	}
	return <LoadingState />;
};

export default withRouter(Show);
