import React, { useContext, memo } from "react";
import { Date as DateComponent, Status } from "components/Shared";
import { Link } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";
import { UserContext } from "context";
import moment from "moment";

const ProjectDeadlines = ({ project }: { project: tcpinpoint.Project }) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	if (!project.closed_at) {
		const deadlines = _.map(_.sortBy(project.deadlines, "date"), deadline => {
			const ahead = moment(deadline.date).isAfter(moment().endOf("date")) && !deadline.behind && !deadline.closed_at;
			const behind =
				(moment(deadline.date).isBefore(moment().endOf("date")) || !!deadline.behind) && !deadline.closed_at;
			// console.log("ahead", ahead);
			// console.log("behind", behind);
			const attrs = {
				className: classNames("project-deadline", {
					"deadline-open": !deadline.closed_at,
					"deadline-closed": !!deadline.closed_at,
					"deadline-complete": !!deadline.completed_at,
					"deadline-incomplete": !deadline.completed_at,
					"deadline-ahead": ahead,
					"deadline-behind": behind
				}),
				key: deadline.id || deadline.name,
				id: deadline.id
			};
			let body = (
				<React.Fragment>
					<span>
						<Status closed={!!deadline.closed_at} incomplete={!deadline.completed_at} />
						<span className="project-deadline-name">{deadline.name}</span>
					</span>
					<span className="project-deadline-date">
						<DateComponent date={deadline.date} showTime={false} />
					</span>
				</React.Fragment>
			);
			if (currentUser.id === project.created_by_id) {
				return (
					<Link to={`/tasks/${deadline.task_id}`} {...attrs}>
						{body}
					</Link>
				);
			}
			return <div {...attrs}>{body}</div>;
		});
		return <div id="project-deadlines">{_.compact(deadlines)}</div>;
	}
	return null;
};

export default memo(ProjectDeadlines);
