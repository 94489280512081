import { CardDynamic } from "components/Documents";
import { LoadingState } from "components/Shared";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const DocumentCondition = ({ condition, onUpdate, onUpdateMoment, lastMoment }: any) => {
	const queryStringParameters = useMemo(() => ({ condition_id: condition.id }), [condition.id]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "condition", renderTrigger: renderTrigger }));
	const conditionId = useMemo(() => _.get(condition, "id"), [condition]);
	const uploadedDocumentSize = useSelector(
		(store: tcpinpoint.Store) => _.filter(store.documents, { documentable_id: conditionId }).length
	) as number; // document upload part havent been migrated to API-v2, so use redux document size change to indicate whether or not document upload is successful

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && !_.isNil(onUpdate)){
				onUpdate(response.normalizedMainModelResponse);
				onUpdateMoment();
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, onUpdate, onUpdateMoment]);

	useEffect(() => {
		if (!_.isEmpty(conditionId)) datasource.get("v2", `conditions/${conditionId}`, {});
	}, [datasource, uploadedDocumentSize, conditionId]);

	if (datasource.isLoading) return <LoadingState />;
	if (_.isEmpty(condition)) return null;

	return (
		<React.Fragment>
			<div className="inline-block">
				<CardDynamic
					hideTitle
					hideFilter
					download={true}
					resource={condition}
					queryStringParameters={queryStringParameters}
					momentUpdate={onUpdateMoment}
					lastMoment={lastMoment}
					// because document upload will touch condition to auto close, so dont manual close condition,
					// else it will cause concurrency problem
					// onUpdate={close}
				/>
			</div>
		</React.Fragment>
	);
};

export default DocumentCondition;
