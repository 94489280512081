import React from "react";
import { translate } from "lib";

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidMount() {
		const { hasError } = this.state;
		const root = document.getElementById("app");

		if (hasError) {
			root && root.classList.add("has-error");
		}
	}

	UNSAFE_componentWillReceiveProps() {
		const { hasError } = this.state;
		const root = document.getElementById("app");

		if (hasError) {
			root && root.classList.add("has-error");
		}
	}

	componentWillUnmount() {
		const root = document.getElementById("app");
		root && root.classList.remove("has-error");
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true, error, info });
	}

	render() {
		const { hasError, error } = this.state;
		const { children } = this.props;
		if (hasError) {
			return (
				<div className="error-boundary">
					<h1>{translate("error_boundary_title")}</h1>
					<p>{translate("error_boundary_body")}</p>
					<blockquote>{error.message}</blockquote>
				</div>
			);
		}
		return children;
	}
}
