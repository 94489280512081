import React from "react";

export default class Logo extends React.Component {
  render() {
    const output = (
      <svg
        id="logo"
        width="300px"
        height="300px"
        viewBox="0 0 300 300"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="letters" transform="translate(35.106383, 108.510638)">
          <path
            className="letter"
            id="letter-p"
            d="M184.571036,14.6893617 C174.193188,14.7297872 163.061247,20.9297872 163.061247,34.5 L163.061247,45.7255319 C163.061247,56.7 171.458802,64.7255319 183.485827,65.2531915 C193.350978,65.2531915 202.068969,59.8510638 205.593763,51.493617 C206.704607,48.5957447 207.428792,45.0382979 207.556966,41.7978723 C207.695822,32.5085106 204.433785,25.0021277 197.847761,19.5404255 C194.374236,16.8 189.774914,15.1106383 184.571036,14.6893617 L184.571036,14.6893617 Z M155.498962,103.838298 C151.737045,103.838298 147.932404,101.344681 147.932404,96.5851064 L147.932404,8.36170213 C147.932404,3.98297872 151.000043,1.03829787 155.565185,1.03829787 C159.938066,1.03829787 162.683133,3.74468085 162.777128,8.12978723 C168.752188,2.37446809 177.062157,0.187234043 183.810535,0.187234043 C188.706795,0.187234043 193.816678,1.17659574 198.223739,2.98297872 L198.260055,2.91914894 C214.025498,9.40212766 223.155782,24.0425532 222.68581,42.093617 C222.064164,64.2702128 206.076553,79.7531915 183.810535,79.7531915 C175.575335,79.7531915 168.149768,77.1148936 163.061247,72.5978723 L163.061247,96.5851064 C163.061247,101.344681 159.258742,103.838298 155.498962,103.838298 L155.498962,103.838298 Z"
          />
          <path
            className="letter"
            id="letter-c"
            d="M107.736934,79.5617021 C85.6546328,79.5617021 68.3596431,61.7106383 68.3596431,38.9148936 C68.3596431,19.6191489 82.1811084,3.46382979 101.232086,0.5 C103.720805,0.165957447 106.085621,0 108.390623,0 C119.845136,0 129.539387,4.07234043 137.206349,12.1021277 C139.218686,14.3659574 139.695067,17.087234 138.550043,19.6106383 C137.349477,22.2595745 134.461282,24.1106383 131.519681,24.1106383 C129.547932,24.1106383 127.708631,23.3106383 126.347847,21.8510638 C121.65453,16.9085106 116.044767,14.5978723 108.689696,14.5978723 C106.961479,14.5978723 105.19481,14.7234043 103.389688,14.9255319 C90.8606469,16.8531915 83.3368137,25.8680851 83.3368137,39.0255319 C83.3368137,53.3319149 94.3833047,64.9723404 107.959103,64.9723404 C111.552256,64.9723404 115.536342,63.9702128 119.178629,62.1531915 C122.216361,60.5957447 125.083193,58.2659574 127.022898,55.7787234 C128.212783,54.0106383 130.265709,52.9468085 132.598481,52.9468085 C135.490949,52.9468085 138.325738,54.6468085 139.650206,57.1787234 C140.844363,59.4659574 140.632876,62.1404255 139.088375,64.3361702 C135.886153,68.8212766 131.303921,72.5914894 125.830877,75.2553191 C119.727642,78.0425532 113.31679,79.5617021 107.736934,79.5617021"
          />
          <path
            className="letter"
            id="letter-t"
            d="M35.8802677,80.4553191 C32.2443892,80.4553191 28.3863418,77.8021277 28.3863418,72.8851064 L28.3863418,16.712766 L7.40847632,16.712766 C2.59766644,16.712766 0,12.9553191 0,9.41914894 C0,7.55531915 0.670778998,5.78723404 1.8862989,4.45319149 C3.25776424,2.95106383 5.22096774,2.12765957 7.40847632,2.12765957 L64.5656829,2.12765957 C69.485441,2.12765957 72.1407859,5.88510638 72.1407859,9.41914894 C72.1407859,12.9553191 69.485441,16.712766 64.5656829,16.712766 L43.4831417,16.712766 L43.5878174,72.8808511 C43.5878174,77.6234043 39.6699554,80.4553191 35.8802677,80.4553191"
          />
        </g>
        <g id="border">
          <path
            className="ball"
            id="top-left-ball"
            d="M32.9252831,69.3297872 C38.6461307,72.6191489 40.607198,79.9042553 37.3045728,85.6 C34.0019475,91.2978723 26.6874657,93.2489362 20.9666181,89.9595745 C15.2479067,86.6702128 13.2868394,79.387234 16.5894647,73.6893617 C19.8920899,67.9914894 27.2065717,66.0404255 32.9252831,69.3297872"
          />
          <path
            className="ball"
            id="bottom-right-ball"
            d="M267.0122,231.77234 C261.291352,228.482979 259.332421,221.197872 262.635046,215.5 C265.937672,209.804255 273.250017,207.851064 278.970865,211.142553 C284.691712,214.429787 286.650643,221.714894 283.348018,227.412766 C280.045393,233.108511 272.730911,235.061702 267.0122,231.77234"
          />
          <path
            className="horizontal-arc"
            id="top-arc"
            d="M279.327617,75.2191489 C279.269938,75.1212766 279.156718,74.9382979 279.156718,74.9382979 C253.190734,30.7808511 205.086908,1.10212766 149.997581,1.10212766 C105.89493,1.10212766 66.2591541,20.1170213 38.8768445,50.3531915 C38.8768445,50.3531915 38.2274279,51.1276596 37.9497169,51.5680851 C35.6874399,55.1744681 36.7897392,59.9276596 40.410664,62.1808511 C43.5402539,64.1297872 47.5222032,63.5638298 50.0087852,61.0510638 C50.0087852,61.0510638 50.1134609,60.9553191 50.1262783,60.9404255 C74.6866163,33.6723404 110.321217,16.5 149.997581,16.5 C199.242159,16.5 242.263873,42.9446809 265.59801,82.3382979 L266.191884,83.2659574 C268.417845,86.6425532 272.946671,87.7574468 276.503509,85.712766 C280.192793,83.5914894 281.457447,78.893617 279.327617,75.2191489"
          />
          <path
            className="horizontal-arc"
            id="bottom-arc"
            d="M20.6120024,225.885106 C20.6675446,225.980851 20.7829015,226.16383 20.7829015,226.16383 C46.7467485,270.321277 94.8505748,300 149.942038,300 C194.044689,300 233.678329,280.987234 261.060638,250.748936 C261.060638,250.748936 261.712191,249.976596 261.987766,249.53617 C264.250043,245.92766 263.14988,241.176596 259.526819,238.921277 C256.397229,236.97234 252.41528,237.538298 249.928698,240.053191 C249.928698,240.053191 249.826158,240.148936 249.811205,240.161702 C225.250867,267.431915 189.616266,284.602128 149.942038,284.602128 C100.695324,284.602128 57.6736102,258.157447 34.3416095,218.765957 L33.7477351,217.83617 C31.5217742,214.459574 26.9929478,213.346809 23.4339739,215.391489 C19.7446894,217.512766 18.4821723,222.210638 20.6120024,225.885106"
          />
          <path
            className="vertical-arc"
            id="right-arc"
            d="M277.648533,191.902128 C276.328337,195.955319 278.554298,200.308511 282.623833,201.625532 C286.672006,202.93617 291.017116,200.742553 292.36081,196.725532 L292.382172,196.731915 C297.047718,182.314894 299.594115,166.951064 299.594115,150.989362 C299.594115,134.840426 296.962268,119.314894 292.189911,104.751064 C292.170685,104.685106 292.168548,104.617021 292.14505,104.548936 C290.822718,100.497872 286.454109,98.2787234 282.384574,99.5957447 C278.315039,100.912766 276.086942,105.265957 277.409274,109.319149 C277.4285,109.376596 277.46268,109.425532 277.481906,109.482979 C281.771474,122.553191 284.134154,136.491489 284.134154,150.989362 C284.134154,165.187234 281.865468,178.846809 277.7468,191.682979 C277.719029,191.759574 277.674168,191.825532 277.648533,191.902128"
          />
          <path
            className="vertical-arc"
            id="left-arc"
            d="M22.2889499,191.902128 C23.6112817,195.955319 21.3853209,200.308511 17.3157859,201.625532 C13.265477,202.93617 8.92250343,200.742553 7.57667296,196.725532 L7.55531057,196.731915 C2.88976493,182.314894 0.343368222,166.951064 0.343368222,150.989362 C0.343368222,134.840426 2.97521448,119.314894 7.74757207,104.751064 C7.76893445,104.685106 7.77107069,104.617021 7.79243308,104.548936 C9.11476493,100.497872 13.4855096,98.2787234 17.5550446,99.5957447 C21.6224434,100.912766 23.8505405,105.265957 22.5282086,109.319149 C22.5089825,109.376596 22.4748027,109.425532 22.4555765,109.482979 C18.1660089,122.553191 15.8033288,136.491489 15.8033288,150.989362 C15.8033288,165.187234 18.0720144,178.846809 22.1928191,191.682979 C22.2205903,191.759574 22.263315,191.825532 22.2889499,191.902128"
          />
        </g>
      </svg>
    );
    return output;
  }
}
