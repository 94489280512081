import _ from "lodash";
import { Map } from "components/Widgets";
import { Show as Avatar } from "components/Avatar";
import { View, ListGroup, ListGroupItem, Row, Col, ListGroupControl } from "components/Shared";
import React, { useState } from "react";

const Buildings = ({
	buildings,
	property
}: {
	name?: string;
	buildings: tcpinpoint.Building[];
	property: tcpinpoint.Property;
}) => {
	const [sortBy, setSortBy] = useState("name");
	const [filteredBy, setFilteredBy] = useState("all");
	const collection = _.sortBy(
		_.filter(buildings, building => filteredBy === "all" || building.precinct === filteredBy),
		sortBy
	);
	return (
		<View>
			<Row>
				<Col>
					<ListGroupControl
						onSortChange={setSortBy}
						selectedSort={sortBy}
						sortOptions={["name", "locality", !!_.map(buildings, "precinct").length && "precinct"]}
						onFilterChange={setFilteredBy}
						selectedFilter={filteredBy}
						filterOptions={["all", ..._.compact(_.uniq(_.map(buildings, "precinct")))]}
					/>
					<ListGroup
						hidePageCount
						resource="buildings"
						collection={collection}
						renderRow={building => (
							<ListGroupItem
								key={building.id}
								icon={<Avatar url={building.avatar} name={building.name} />}
								heading={building.name}
								subheading={_.get(building, "addresses[0].street_1")}
								datapoints={[
									!!building.precinct && {
										key: "precinct",
										value: building.precinct
									}
								]}
								link={`/properties/${property.id}/buildings/${building.id}`}
							/>
						)}
					/>
				</Col>
				<Col>
					<Map
						buildings={_.map(collection, building => ({
							name: building.name,
							coordinates: building.shape
						}))}
						addresses={_.get(property, "addresses", [])}
						height="600px"
					/>
				</Col>
			</Row>
		</View>
	);
};

export default Buildings;
