import { translate } from "lib";
import React, { memo } from "react";

import { Breadcrumbs, Header, Workspace } from "../Shared";
import CollectionDynamic from "./CollectionDynamic";

const Tasks = (props: any) => {
	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: translate("tasks") }]} />
			<Header stamp="tasks" title="list_tasks" />
			<Workspace id="tasks" className="list">
				<CollectionDynamic />
			</Workspace>
		</React.Fragment>
	);
};

const MemoizedTasks = memo(Tasks);
export default ({ history }: any) => <MemoizedTasks history={history} />;
