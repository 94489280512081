import classNames from "classnames";
import CommentRow from "components/Comments/RowDynamic";
import { ListGroup, ListGroupControl, LoadingState, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useContext, useEffect, useState } from "react";
import { CommentContext } from "./CardDynamic";

interface Props {
	globalId: string;
}

const Comments = ({ globalId }: Props) => {
	const { context, setContext } = useContext(CommentContext);
	const [, renderTrigger] = useState(moment());
	const [apiCallCounter, setApiCallCounter] = useState(0);
	const [rootComments, setRootComments] = useState([] as any[]);
	const getSortAttribute = (sortBy: string) => {
		switch (sortBy) {
			case "created_at_asc":
				return "created_at";
			case "created_at_desc":
				return "-created_at";
			default:
				return sortBy;
		}
	};
	const [datasource] = useState(
		new Datasource({
			mainModelName: "comment",
			perPage: 10,
			currentPage: 1,
			sortBy: "created_at_desc",
			renderTrigger: renderTrigger,
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setRootComments(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	let _comment_id = new URLSearchParams(window.location.search).get("_comment_id");
	var comment_id = _comment_id != null && rootComments.length === 0 ? _comment_id : 1;

	// API call
	useEffect(() => {
		// activity#index
		datasource.get("v2", "comments", {
			queryStringParameters: {
				global_id: globalId,
				sort: getSortAttribute(datasource.sortBy), 
				per_page: datasource.perPage,
				page: datasource.currentPage,
				comment_id: comment_id
			},
		});
		setApiCallCounter(apiCallCounter + 1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datasource, globalId, datasource.currentPage, datasource.perPage, datasource.sortBy, comment_id, datasource.reloadTrigger]);

	useEffect(() => {
		if (!context.listReload) return;
		if (_.isEmpty(context.replyToComment) && context.highlightCommentId) {
			datasource.setSortBy("created_at_asc");
			datasource.setCurrentPage(1);
		}
		datasource.reloadTrigger = moment();
		setContext((prevState: any) => ({ ...prevState, ...{ listReload: false, replyToComment: {} } }));
	}, [context.listReload, context.replyToComment, context.highlightCommentId, datasource, setContext]);

	if (datasource.isFirstTimeLoading) return <LoadingState />;

	return (
		<>
			<View resource="comments" hideTitle={true}>
				<ListGroupControl
					sortOptions={["created_at_desc", "created_at_asc"]}
					onSortChange={(newSortBy) => {
						datasource.setCurrentPage(1);
						datasource.setSortBy(newSortBy);
					}}
					selectedSort={datasource.sortBy}
					selectedView={"list"}
				/>
				<ListGroup
					hidePageCount
					resource="comments"
					collection={rootComments}
					pagination={false}
					renderRow={(comment) => {
						return <CommentRow key={comment.id} comment={comment} />;
					}}
				/>
				<div className={classNames("margin-atuo", "flex justify-center")}>
					<Pagination datasource={datasource} />
				</div>
			</View>
		</>
	);
};

export default memo(Comments);
