import { Date, DefinitionItem, DefinitionList, LoadingState, RelativeDate, View } from "components/Shared";
import EditHistory from "components/Tasks/EditHistory";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import "moment-business-days";
import React, { memo, useEffect, useMemo, useState } from "react";

interface Props {
	taskId: string;
	name?: string;
}

const InsightsTab = ({ taskId }: Props) => {
	const [, renderTrigger] = useState(moment());
	const [task, setTask] = useState({} as any);
	const [datasource] = useState(new Datasource({ mainModelName: "task", renderTrigger: renderTrigger }));

	const closedAtDiffReschedule = useMemo(() => {
		if (_.isEmpty(task) || _.isNil(task.closed_at_diff_reschedule)) return {};
		return {
			days: Math.abs(task.closed_at_diff_reschedule),
			status: task.closed_at_diff_reschedule > 0 ? "Behind" : "Ahead",
			classNames:
				task.closed_at_diff_reschedule > 0
					? "bg-red-50 rounded-full px-2 text-white"
					: "bg-green-50 rounded-full px-2 text-white"
		};
	}, [task]);

	const closedAtDiffTcpSchedule = useMemo(() => {
		if (_.isEmpty(task) || _.isNil(task.closed_at_diff_tcp_schedule)) return {};
		return {
			days: Math.abs(task.closed_at_diff_tcp_schedule),
			status: task.closed_at_diff_tcp_schedule > 0 ? "Behind" : "Ahead",
			classNames:
				task.closed_at_diff_tcp_schedule > 0
					? "bg-red-50 rounded-full px-2  text-white"
					: "bg-green-50 rounded-full px-2 text-white"
		};
	}, [task]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setTask(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		if (!_.isEmpty(taskId))
			datasource.get("v2", `tasks/${taskId}`, {
				queryStringParameters: {
					fields: JSON.stringify({
						task: [
							"description",
							"length",
							"closed_at",
							"due_at",
							"link",
							"closed_at_diff_tcp_schedule",
							"closed_at_diff_reschedule"
						]
					})
				}
			});
	}, [datasource, taskId]);

	if (datasource.isLoading) return <LoadingState />;

	if (!!task) {
		return (
			<View resource="details">
				<DefinitionList>
					<DefinitionItem title="description" value={task.description} />
					{!task.closed_at && !!task.length && (
						<React.Fragment>
							<DefinitionItem title="projected_length" value={task.length} suffix={` ${translate("calendar_days")}`} />
							<DefinitionItem
								title="scheduled_close_date"
								value={<Date date={task.due_at} showTime={false} showDay={true} />}
							/>
						</React.Fragment>
					)}
					{!!task.closed_at && (
						<DefinitionItem
							title="due_at"
							value={<Date date={task.due_at} showTime={false} showDay={true} />}
							footnote={!moment(task.due_at).isSame(moment(), "day") && <RelativeDate date={task.due_at} />}
						/>
					)}
				</DefinitionList>
				{(!_.isEmpty(closedAtDiffReschedule) || !_.isEmpty(closedAtDiffTcpSchedule)) && (
					<div className="mt-5">
						<header className="component-header">
							<h2>Task Metrics</h2>
							{!_.isEmpty(closedAtDiffReschedule) && (
								<div className="flex">
									<div className="w-2/6">
										Closed Date <span className="font-bold">vs</span> Due Date
									</div>
									<div className="w-1/6">
										<span className={closedAtDiffReschedule.classNames}>{closedAtDiffReschedule.days}</span>
										<span> days {closedAtDiffReschedule.status} </span>
									</div>
								</div>
							)}
						</header>
					</div>
				)}
				<EditHistory taskId={taskId} />
			</View>
		);
	}
	return null;
};
export default memo(InsightsTab);
