import React, { memo } from "react";
import { Title } from "components/Shared";
import { translate } from "lib";

const Header = (props: any) => {
	const {title, onRequestClose} = props; 

	return (
	<header>
		{title && (
			<div className="modal-header-body">
				<Title>{translate(title)}</Title>
			</div>
		)}
		<button type="button" className="close" aria-label="Close" onClick={onRequestClose}>
			<span aria-hidden="true">×</span>
		</button>
	</header>
	)

}

export default memo(Header);

