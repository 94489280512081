import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const addProjectToPortfolio = memoAC((project_id: string, portfolio_id: string) => (dispatch: any) => {
	dispatch({ type: "ADD_PROJECT_PORTFOLIO_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios/${portfolio_id}/projects/${project_id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getPortfolio(portfolio_id));
		dispatch({ type: "ADD_PROJECT_PORTFOLIO_SUCCESS" });
	})
	.catch((e) => { dispatch({ type: "ADD_PORTFOLIO_FAILED", response: e }); });
});

export const removeProjectFromPortfolio = memoAC((project_id: string, portfolio_id: string) => (dispatch: any) => {
	dispatch({ type: "REMOVE_PROJECT_PORTFOLIO_REQUEST" });
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios/${portfolio_id}/projects/${project_id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getPortfolio(portfolio_id));
		dispatch({ type: "REMOVE_PROJECT_PORTFOLIO_SUCCESS" });
	})
	.catch((e) => { dispatch({ type: "REMOVE_PORTFOLIO_FAILED", response: e }); });
});

export const deletePortfolio = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "DELETE_PORTFOLIO_REQUEST" });
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getPortfolios());
		dispatch({ type: "DELETE_PORTFOLIO_SUCCESS", resource: { id } });
	})
	.catch((e) => { dispatch({ type: "DELETE_PORTFOLIO_FAILED", response: e }); });
});

export const postPortfolio = memoAC((portfolio: { name: string }) => (dispatch: any) => {
	dispatch({ type: "POST_PORTFOLIO_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios`, { portfolio }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getPortfolio(res.data.portfolio.id));
		dispatch({ type: "POST_PORTFOLIO_SUCCESS" });
	})
	.catch((e) => { dispatch({ type: "POST_PORTFOLIOS_FAILED", response: e }); });
});

export const putPortfolio = memoAC((portfolio: tcpinpoint.Portfolio) => (dispatch: any) => {
	dispatch({ type: "PUT_PORTFOLIO_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios/${portfolio.id}`, { portfolio }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getPortfolio(res.data.portfolio.id));
		dispatch({ type: "PUT_PORTFOLIO_SUCCESS" });
	})
	.catch((e) => { dispatch({ type: "PUT_PORTFOLIOS_FAILED", response: e }); });
});

/**
 * Returns a portfolio from the API
 * @param id Portfolio Id
 */
export const getPortfolio = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_PORTFOLIO_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_PORTFOLIO_SUCCESS", resource: res.data.portfolio }))
	.catch((e) => { dispatch({ type: "GET_PORTFOLIO_FAILED", response: e }); });
});

export const getPortfolios = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_PORTFOLIOS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_PORTFOLIOS_SUCCESS", resource: res.data.portfolios }))
	.catch((e) => dispatch({ type: "GET_PORTFOLIOS_FAILED", response: e }));
});
