import React, { useRef, useState, memo } from "react";
import { useDispatch } from "react-redux";
import { translate } from "lib";
import { postDocument } from "actions";
import _ from "lodash";
import Modal from "components/Documents/Modal.jsx";

interface Props {
	resource: any;
	onUpdate?: (arg: any) => void;
	postCallback?: (arg: any) => void;
	children?: any;
	comment?: tcpinpoint.Comment;
	confirmUpload?: boolean;
}

const Upload = ({ resource, children, onUpdate, postCallback, confirmUpload }: Props) => {
	const dispatch = useDispatch();
	const uploadRef = useRef<HTMLInputElement>(null);
	const divRef = useRef(null);

	const [modalOpen, setModalOpen] = useState(false);

	const onUpload = (comment = "") => {
		const { files } = uploadRef.current as HTMLInputElement;
		setModalOpen(false);
		_.forEach(files, file => {
			!!onUpdate && onUpdate(file);
			dispatch(postDocument(resource, file, comment, postCallback));
		});
	};

	const onDrop = (event: any) => {
		event.preventDefault();
		const { files } = event.dataTransfer;
		_.forEach(files, (file: any) => {
			!!onUpdate && onUpdate(file);
			dispatch(postDocument(resource, file, "", postCallback));
		});
	};

	const onDragOver = (event: any) => {
		if (divRef && divRef.current) {
			//@ts-ignore
			divRef.current.style.borderColor = "red";
			console.log("Draggging");
			event.preventDefault();
		}
	};

	return (
		<div ref={divRef} onDrop={onDrop} onDragOver={onDragOver} className="upload">
			<input
				className="file-input"
				multiple
				ref={uploadRef}
				onChange={() => (!!confirmUpload ? setModalOpen(true) : onUpload())}
				id={`${resource.id}-file`}
				name={`${resource.id}-file`}
				type="file"
			/>
			<label htmlFor={`${resource.id}-file`} className="btn btn-primary btn-sm button">
				{children || translate("upload_document")}
			</label>
			<Modal
				resource={resource}
				modalOpen={!!modalOpen}
				onUpdate={onUpload}
				files={_.get(uploadRef, "current.files", [])}
				doCloseModal={() => setModalOpen(false)}
			/>
		</div>
	);
};

export default memo(Upload);
