import React from "react";
import { parse, format } from "libphonenumber-js";
import validate from "validate.js";
import axios from "axios";
import { VerifyEmail } from "components/Users";
import { Success } from "components/Session";
import { Payment, Input, Plans } from "components/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import {
	Row,
	Col,
	DefinitionItem,
	DefinitionList,
	Step,
	Steps,
	Button,
	Title,
	Text,
	ListGroup,
	ListGroupItem,
	LoadingState
} from "components/Shared";
import { translate } from "lib";
import { Auth } from "aws-amplify";
import _ from "lodash";
import { withRouter } from "react-router-dom";

const constraints = {
	email: {
		presence: true
	},
	firstname: {
		presence: true
	},
	surname: {
		presence: true
	},
	phone_number: {
		presence: true
	},
	password: {
		presence: true,
		length: {
			minimum: 6
		}
	}
};

let loadOptions = false;

const SESSION_STORAGE_KEY = "cached_new_user";

class SignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invites: [],
			invite: "",
			currency: "AUD",
			loading: false,
			terms_conditions: true,
			// signup_mailing_list: true,
			cognito_response: {},
			state: "user_new",
			errors: {},
			firstname: "",
			surname: "",
			email: "",
			phone_number: "",
			password: ""
		};
		if (!localStorage.getItem(SESSION_STORAGE_KEY)) {
			localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(this.state));
		}
	}

	componentWillUnmount() {
		document.getElementById("app").classList.remove("wizard");
	}

	componentDidMount() {
		document.getElementById("app").classList.add("wizard");
		this.restore(this.props);
	}

	UNSAFE_componentWillReceiveProps(props) {
		document.getElementById("app").classList.add("wizard");
		this.restore(props);
	}

	restore() {
		let jsonifiedState = {};
		try {
			jsonifiedState = JSON.parse(localStorage.getItem(SESSION_STORAGE_KEY));
		} catch (error) {
			console.error(error);
		}

		this.setState(jsonifiedState);
	}

	reset() {
		localStorage.setItem(SESSION_STORAGE_KEY, {});
	}

	cache() {
		const {
			firstname,
			surname,
			email,
			project,
			phone_number,
			password,
			company,
			title,
			avatar,
			user,
			role,
			invites,
			state
		} = this.state;

		try {
			const jsonifiedState = JSON.stringify({
				firstname,
				surname,
				project,
				email,
				phone_number,
				user,
				role,
				avatar,
				password,
				company,
				invites,
				title,
				state
			});
			localStorage.setItem(SESSION_STORAGE_KEY, jsonifiedState);
		} catch (error) {
			console.error(error);
		}
	}

	processInvite() {
		const { invites } = this.state;
		const invite = invites[0];

		this.setState(
			{
				firstname: _.get(invite, "firstname"),
				surname: _.get(invite, "surname"),
				email: _.get(invite, "email"),
				phone_number: _.get(invite, "phone_number"),
				user: _.get(invite, "user"),
				project: _.get(invite, "project")
			},
			() => this.cache()
		);
	}

	lookupEmail() {
		const { email, checkedEmail } = this.state;

		if (email.includes("@") && email !== checkedEmail) {
			if (!!loadOptions) {
				loadOptions.cancel();
			}
			loadOptions = _.debounce(() => {
				this.setState({ checkedEmail: email });
				const domain_matches = email.match(/@(.+$)/);
				if (!!domain_matches) {
					axios
						.get(`${process.env.REACT_APP_API_ENDPOINT_V1}companies/domain`, { domain: domain_matches[1] })
						.then(companies => this.setState({ company: companies[0] }));
				}
				axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}invites/search`, { email }).then(invites => {
					if (!!invites.length) {
						const invite = invites[0];
						this.processInvite(invite);
					}
					this.setState({ invites });
				});
				axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}users/search`, { query: email }).then(users => {
					if (!!users && users.length) {
						this.setState({ state: "user_exists" });
					}
				});
			}, 250);
			loadOptions();
		}
	}

	onSubmit() {
		const { avatar, payment_token, password, company, title, firstname, surname, plan, email } = this.state;
		let phone_number;
		this.setState({ loading: true }, () => this.reset());
		if (!!this.state.phone_number) {
			const strippedNumber = this.state.phone_number.replace(/[^0-9]/g, "").replace(/^61/, 0);
			phone_number = format(parse(strippedNumber, "AU"), "International").replace(/ /g, "");
		}
		const user = {
			plan_id: _.get(plan, "id"),
			company_id: _.get(company, "id"),
			email,
			avatar,
			firstname,
			password,
			payment_token,
			phone_number,
			surname
		};

		const errors = validate(user, constraints);
		if (!errors) {
			Auth.signUp({
				username: email,
				password: password,
				attributes: {
					email,
					given_name: firstname,
					family_name: surname,
					phone_number
				}
			})
				.then(cognito_response => {
					this.setState({ cognito_response });
					axios
						.post(`${process.env.REACT_APP_API_ENDPOINT_V1}users`, {
							user: {
								company_id: _.get(company, "id"),
								email,
								firstname,
								title,
								phone_number,
								avatar,
								password,
								surname
							}
						})
						.then(() => this.setState({ state: "user_created" }))
						.catch(errors => {
							window.Sentry.captureException(errors);
							this.setState({ loading: false, errors }, () => this.cache());
						});
				})
				.catch(aws_error => {
					window.Sentry.captureException(aws_error);
					this.setState({ state: "user_exists", loading: false, errors: { email: aws_error.message } }, () =>
						this.cache()
					);
				});
		} else {
			this.setState({ errors, loading: false }, () => this.cache());
		}
	}

	render() {
		const { history } = this.props;
		const {
			state,
			email,
			firstname,
			invites,
			title,
			surname,
			phone_number,
			user,
			project,
			cognito_response,
			password,
			plan,
			errors,
			paidUser = false,
			company,
			loading
		} = this.state;

		if (state === "user_created" && !cognito_response.userConfirmed) {
			return <VerifyEmail {...this.state} onUpdate={() => this.setState({ state: "user_confirmed" })} />;
		} else if (state === "user_confirmed") {
			return <Success {...this.state} />;
		} else if (state === "user_exists") {
			return (
				<React.Fragment>
					<Title>Haven't we met before?</Title>
					<p>We recognise that email address. Have you signed up with us before?</p>
					<Button onClick={() => history.push("/signin")} />
				</React.Fragment>
			);
		} else {
			return (
				<Steps>
					<Step ref="welcome" enabled={true}>
						<Title>{translate("welcome_step_title")}</Title>
						<Row>
							<Col>
								<Input
									name="email"
									required={constraints}
									hint="signup_email_hint"
									onChange={email =>
										this.setState({ email }, () => {
											this.cache();
											this.lookupEmail();
										})
									}
									defaultState={this.state}
								/>
								<Input
									name="password"
									required={constraints}
									autoComplete="new-password"
									type="password"
									hint="password_hint"
									onChange={password => this.setState({ password })}
									defaultState={this.state}
								/>
							</Col>
						</Row>
					</Step>
					{!!user && !!project && (
						<Step ref="invite" enabled={true}>
							<Title>{translate("invite_step_title")}</Title>
							<Text>{translate("invite_step_body")}</Text>
							<ListGroup
								resource="invites"
								collection={invites}
								renderRow={row => (
									<ListGroupItem
										icon={<FontAwesomeIcon icon={faEnvelope} />}
										heading={`${_.get(row, "role.name")} at ${_.get(row, "project.name")}`}
										subheading={`${_.get(row, "user.name")} (${_.get(row, "user.email")})`}
									/>
								)}
							/>
						</Step>
					)}
					<Step ref="about" enabled={!!email && !!password}>
						<Title>{translate("about_step_title")}</Title>
						<Text>{translate("about_step_body")}</Text>
						<Row>
							<Col>
								<Input
									name="email"
									required={constraints}
									onChange={email => this.setState({ email }, () => this.cache())}
									defaultState={this.state}
								/>
								<Input
									name="firstname"
									required={constraints}
									onChange={firstname => this.setState({ firstname }, () => this.cache())}
									defaultState={this.state}
								/>
								<Input
									name="surname"
									required={constraints}
									onChange={surname => this.setState({ surname }, () => this.cache())}
									defaultState={this.state}
								/>
								<Input
									name="phone_number"
									required={constraints}
									onChange={phone_number => this.setState({ phone_number }, () => this.cache())}
									defaultState={this.state}
								/>
							</Col>
						</Row>
					</Step>
					{!!paidUser && (
						<React.Fragment>
							<Step ref="plans" enabled={!!email}>
								<Title>{translate("plans_step_title")}</Title>
								<Text>{translate("plans_step_body")}</Text>
								<Plans onSubmit={plan => this.setState({ plan })} />
							</Step>
							<Step ref="payment" enabled={!!email && !!plan}>
								<Payment onSubmit={payment_token => this.setState({ payment_token })} />
							</Step>
						</React.Fragment>
					)}
					<Step ref="confirmation" enabled={!!firstname && !!surname && !!email && !!phone_number}>
						{loading ? (
							<LoadingState />
						) : (
							<React.Fragment>
								{!!_.keys(errors).length && (
									<div className="alert alert-warning" style={{ marginBottom: "10px" }}>
										<DefinitionList>
											{_.map(_.keys(errors), key => (
												<DefinitionItem title={key} value={errors[key].join(", ")} />
											))}
										</DefinitionList>
									</div>
								)}
								<Title>{translate("confirmation_step_title")}</Title>
								<DefinitionList>
									<DefinitionItem title="name" value={`${firstname} ${surname}`} />
									<DefinitionItem title="title" value={title} />
									<DefinitionItem title="email" value={email} />
									<DefinitionItem title="phone_number" value={phone_number} />
									<DefinitionItem title="company" value={_.get(company, "name")} />
								</DefinitionList>
								<hr />
								{/* <Input
									type="checkbox"
									defaultState={this.state}
									name="signup_mailing_list"
									onChange={signup_mailing_list => this.setState({ signup_mailing_list })}
								/> */}
								<Input
									type="checkbox"
									defaultState={this.state}
									name="terms_conditions"
									onChange={terms_conditions => this.setState({ terms_conditions })}
								/>
								<Button onClick={() => this.onSubmit()} />
								<hr />
							</React.Fragment>
						)}
					</Step>
				</Steps>
			);
		}
	}
}

export default withRouter(SignUp);
