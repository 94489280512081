import React, { memo } from "react";
import { RouteComponentProps } from "react-router";
import { Breadcrumbs, Header, LoadingState, View, Workspace } from "components/Shared";
import { Form } from "components/Leases";
import { useGetLease, useGetTenancy } from "v2/hooks";
import _ from "lodash";

interface Props extends RouteComponentProps {
	match: any;
}

const Edit = ({ match, history }: Props) => {
	const { id } = match.params;
	const { lease, datasource: leaseDatasource } = useGetLease(id, {
		fields: JSON.stringify({
			lease: [
				"tenancy",
				"kind",
				"status",
				"duration",
				"permitted_use",
				"incentives",
				"handover_at",
				"fitout_ends_at",
				"rent_starts_at",
				"ends_at",
				"base_rent_cents",
				"rent_per_sqm_cents",
				"base_rent_frequency",
				"annual_review",
				"opening_at",
				"actual_rent_starts_at",
				"actual_opening_at",
				"net_lettable_area",
				"area",
				"seating_area",
				"storage_area",
				"rent_review_percent",
				"statutory_outgoings_cents",
				"operational_outgoings_cents",
				"fitout_contribution_cents",
				"vacant_possession_at",
				"fitout_period",
				"starts_at",
				"ends_at",
				"lease_renewals",
				"tenancy_name",
				"property_name",
			],
		}),
	});

	const { tenancy, datasource: tenancyDatasource } = useGetTenancy(lease?.tenancy?.id, {
		fields: JSON.stringify({ tenancy: ["area"] }),
	});

	const putLease = (lease: tcpinpoint.Lease) => {
		leaseDatasource
			.put("v2", `leases/${lease.id}`, { body: { lease } })
			.then(({ normalizedMainModelResponse }: any) => {
				// setLease(normalizedMainModelResponse);
				history.goBack();
			});
	};

	if (leaseDatasource?.isLoading || tenancyDatasource?.isLoading) return <LoadingState />;

	if (!!lease) {
		return (
			<>
				<Breadcrumbs items={[{ title: "leases" }]} />
				<Header
					subtitle={_.get(lease, "status")}
					title={`${_.get(lease, "tenancy_name")} at ${_.get(lease, "property_name")}`}
					stamp="lease"
				/>
				<Workspace id="lease-edit">
					<View>
						<Form extended lease={lease} onSubmit={putLease} tenancy={tenancy!} />
					</View>
				</Workspace>
			</>
		);
	}
	return null;
};

export default memo(Edit);
