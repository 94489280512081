import Actor from "components/Activities/Actor";
import ItemObject from "components/Activities/ItemObject";
import { ErrorBoundary } from "components/Shared";
import { translate } from "lib";
import React from "react";

const DeleteDocument = ({ activity }: { activity: tcpinpoint.ActivityItem }) => (
	<ErrorBoundary>
		<Actor actor={activity.attributes.item.actor} /> {translate("deleted")}{" "}
		<ItemObject object={activity.attributes.item.object} />{" "}
	</ErrorBoundary>
);

export default DeleteDocument;
