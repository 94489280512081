/* eslint arrow-body-style: ["error", "as-needed", { requireReturnForObjectLiteral: true }]*/
import classNames from "classnames";
import { Label, Required } from "components/Inputs";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import React, { memo, useState } from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

const Option = memo((props: any) => {
	const option = _.find(props.options, option => option.value === props.value);
	if (!!option.item) {
		return <components.Option {...props}>{_.get(option, "item.name")}</components.Option>;
	}
	return (
		<components.Option {...props}>
			We don't have a listing for <strong>{props.value}</strong>. Add now?
		</components.Option>
	);
});

const Lookup = (props: any) => {
	const { name, className, children, required, defaultState, label, onChange, defaultOption } = props;
	const default_role_option = !defaultOption
		? {
				value: _.get(defaultState, ["role", "id"]) || _.get(defaultState, [label, "id"], ""),
				label: _.get(defaultState, ["role", "name"]) || _.get(defaultState, [label, "name"], ""),
				item: _.get(defaultState, ["role"]) || _.get(defaultState, [label], {})
		  }
		: defaultOption;
	const [selected, setSelected] = useState(default_role_option);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "role",
			perPage: -1,
			currentPage: 1,
			sortBy: "name"
		})
	);

	const loadOptions = (inputValue: any, callback: any) => {
		if (inputValue.length < 3) {
			datasource.get("v2", `roles`, {}).then((response: DatasourceResponse) =>
			callback(
				_.map(response.normalizedMainModelResponse, item => {
					return {
						value: item.id,
						label: item.name,
						item
					};
				})
			)
			);
		}
		datasource.get("v2", `roles/search?q=${inputValue}`, {}).then((response: DatasourceResponse) =>
			callback(
				_.map(response.normalizedMainModelResponse, item => {
					return {
						value: item.id,
						label: item.name,
						item
					};
				})
			)
		);
		return;
	};

	const [debouncedLoadOptions] = useState(() => _.debounce(loadOptions, 200));

	return (
		<div
			className={classNames("form-group", `form-control-${name}`, className, {
				className,
				"is-required": required && (required.Role || required.Role_id),
				"has-danger": defaultState && defaultState.errors && (defaultState.errors.Role || defaultState.errors.Role_id)
			})}
		>
			<Label name={label || name || "Role"} />
			<AsyncSelect
				name={name}
				placeholder="Start typing to search…"
				autoFocus={false}
				classNamePrefix="react-select"
				components={{ Option }}
				value={selected}
				onChange={(option: any) => {
					onChange(option.item);
					setSelected(option);
				}}
				// @ts-ignore
				loadOptions={debouncedLoadOptions}
				defaultOptions={true}
			/>
			<Required {...props} />
			{!!children && children}
		</div>
	);
};

export default memo(Lookup);
