import React, { memo } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { translate } from "lib";
import icons from "v2/utils/icon";

const Link = ({
	href,
	label,
	className,
	hideLabel,
	beta,
	children
}: {
	href: string;
	label?: string;
	className?: string;
	hideLabel?: boolean;
	beta?: boolean;
	children?: React.ReactNode;
}) => {
	return (
		<NavLink
			to={href}
			className={classNames(
				"hover:bg-transparent text-black-60 hover:text-blue-100",
				"relative",
				"px-3 h-20 flex flex-col justify-center items-center border-transparent hover:border-blue-100 border-b-3",
				className
			)}
			activeClassName="bg-transparent border-blue-100 text-blue-100"
			exact
		>
			{label && icons(label)}
			{!hideLabel && label && <div className="text-12 font-mont mt-1">{translate(label)}</div>}
			{children}
			{beta && (
				<div className=" text-11 absolute w-11 h-5 leading-5 rounded-3 bg-error-darker text-center text-white top-2px right-0">
					Beta
				</div>
			)}
		</NavLink>
	);
};

export default memo(Link);
