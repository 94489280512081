import React, { useState, memo } from "react";
import Modal from "react-modal";
import { Button, ModalHeader } from "components/Shared";
import { TextInput } from "components/Inputs";

const Reject = (props: any) => {
	const [message, setMessage] = useState("");
	const { modalOpen, closeModal, onClose, completeConditions } = props;

	return (
		<Modal className="warning" isOpen={modalOpen} onRequestClose={closeModal} contentLabel="reject">
			<button type="button" className="close" aria-label="Close" onClick={event => closeModal()}>
				<span aria-hidden="true">×</span>
			</button>
			
      <ModalHeader 
      title="request_submission" 
      onClose={closeModal} 
      />
			<section>
				<p>You are requesting a resubmission of…</p>
				<ul>
					{completeConditions &&
						completeConditions.map((c: any) => {
							if (c.kind === "document") {
								return (
									<li key={c.id}>
										<i className="fa-li fas fa-exclamation-circle " />{" "}
										<strong>{c.documents.map((document: any) => document.name).join(" ")}</strong> added for{" "}
										<strong>{c.name}</strong>
									</li>
								);
							} else {
								return (
									<li key={c.id}>
										The answer for <strong>{c.name}</strong>
									</li>
								);
							}
						})}
				</ul>
				<p>Please provide a message for requesting a resubmission</p>
				<TextInput
					name="message"
					defaultState={{ message }}
					required
					onChange={(message: any) => setMessage(message)}
				/>
			</section>
			<footer>
				<Button
					onClick={() => {
						onClose({ message });
						closeModal();
					}}
				/>
			</footer>
		</Modal>
	);
};

export default memo(Reject);
