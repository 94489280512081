import React from "react";
// @ts-ignore
import { isFragment } from "react-is";

export default function toArray(children: React.ReactNode): React.ReactElement[] {
	let ret: React.ReactElement[] = [];

	React.Children.forEach(children, (child: any) => {
		if (child === undefined || child === null) {
			return;
		}

		if (Array.isArray(child)) {
			ret = ret.concat(toArray(child));
		} else if (isFragment(child) && child.props) {
			ret = ret.concat(toArray(child.props.children));
		} else {
			ret.push(child);
		}
	});

	return ret;
}
