import { Input } from "components/Inputs";
import { Button, ListGroup, ListGroupItem, Date } from "components/Shared";
import _ from "lodash";
import React, { useState, memo } from "react";
import { translate } from "lib";
//@ts-ignore
import validate from "validate.js";
import ValidationErrors from "components/Shared/ValidationErrors";
import { Status } from "components/Shared/Status";
//@ts-ignore
import { v4 } from "uuid";

const constraints = {
	name: { presence: true },
	date: { presence: true }
};

interface Props {
	project: tcpinpoint.Project;
	tasks: tcpinpoint.Task[] | [];
	onSubmit: (arg1: any) => void;
}

const Deadlines = (props: Props) => {
	const { project, tasks, onSubmit } = props;
	const [deadline, setDeadline] = useState<any>();
	const [deadlines, setDeadlines] = useState(project.deadlines || []);
	const [errors, setErrors] = useState();
	if (!!project) {
		return (
			<ListGroup
				renderRow={(deadline: tcpinpoint.Deadline) => (
					<ListGroupItem
						key={deadline.id}
						icon={
							<Status closed={!!_.get(deadline, "task.closed_at")} incomplete={!_.get(deadline, "task.closed_at")} />
						}
						className="deadline"
						heading={deadline.name}
						subheading={<Date date={deadline.date} showTime={false} />}
						parent={
							!!deadline.task && (
								<React.Fragment>
									<h3>{deadline.task.name}</h3>
									<h6>{<Date date={deadline.task.due_at} showTime={false} />}</h6>
								</React.Fragment>
							)
						}
						menu={[
							<button onClick={() => setDeadline(deadline)}>{translate("edit")}</button>,
							<button
								onClick={() => {
									const _deadlines = _.filter(deadlines, _deadline => _deadline.id !== deadline.id);
									setDeadlines(_deadlines);
									onSubmit({ id: project.id, deadlines: _deadlines });
								}}
							>
								{translate("delete")}
							</button>
						]}
					/>
				)}
				key="deadlines"
				pagination={false}
				resource="deadlines"
				collection={_.map(_.sortBy(_.compact(deadlines), "date"), deadline =>
					_.assign({}, deadline, { task: _.find(tasks, { id: deadline.task_id }) })
				)}
			>
				<hr />
				<ValidationErrors errors={errors} />
				<Input
					defaultState={deadline}
					name="name"
					required={constraints}
					onChange={(name: string) => setDeadline(_.assign({}, deadline, { name }))}
				/>
				<Input
					defaultState={deadline}
					name="date"
					required={constraints}
					onChange={(date: string) => setDeadline(_.assign({}, deadline, { date }))}
				/>
				<Input
					defaultState={deadline}
					name="task_id"
					required={constraints}
					options={_.map(_.compact(tasks), task => ({ label: task.name, value: task.id }))}
					onChange={(task_id: string) => setDeadline(_.assign({}, deadline, { task_id }))}
				/>
				<Button
					onClick={() => {
						const valid = validate(deadline, constraints);
						setErrors(valid);
						if (!valid) {
							let newDeadlines = [] as Array<any>;
							if (!!deadline.id) {
								newDeadlines = _.unionBy([deadline], deadlines, "id");
							} else {
								newDeadlines = _.unionBy([_.assign({}, deadline, { id: v4() })], deadlines, "id");
							}
							setDeadlines(newDeadlines);
							setDeadline(false);
							onSubmit({ id: project.id, deadlines: newDeadlines });
						}
					}}
				/>
			</ListGroup>
		);
	}
	return null;
};

export default memo(Deadlines);
