import _ from "lodash";
import classNames from "classnames";
import React, { memo, useState } from "react";
import Subtitle from "./Subtitle";
import Title from "./Title";
import { Button } from "./";
import { Helmet } from "react-helmet";
import { icon, translate } from "lib";

interface Props {
	title: string | Element | false;
	subtitle?: string | Element | false;
	stamp?: any;
	banner?: any;
	className?: string;
	resource?: any;
	children?: any;
	metadata?: any;
	datapoints?: object[];
	toolbar?: (boolean | { label: string; onClick: () => any; icon?: string; confirm?: boolean })[] | false;
}

const Header = (props: any) => {
	const { title, subtitle, banner, className = "", resource = "", children, metadata, datapoints, toolbar } = props;
	// this was used for "Likes", currently not in use
	// const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const [showBanner, setShowBanner] = useState(!!banner);

	const closeButtonStyle = (stamp: any) => {
		if (stamp.props) {
			if (stamp.props.closed === false && stamp.props.incomplete === false) {
				return true;
			} else {
				return false;
			}
		} else {
			return;
		}
	};

	// const circleStyle = {
	// 	width: "50px",
	// 	height: "50px",
	// 	borderRadius: "50%",
	// 	display: "flex",
	// 	alignItems: "center",
	// 	justifyContent: "center",
	// 	fontSize: "34px",
	// 	border: "2px solid red",
	// };

	// const textStyle = {
	// 	display: "flex",
	// 	alignItems: "center",
	// 	justifyContent: "center",
	// };

	if (!!title) {
		const stamp = props.stamp || props.title;
		return (
			<header
				id="main-header"
				className={classNames(
					{
						[`main-header-${resource}`]: resource,
						"main-header-has-subtitle": !!subtitle,
						"main-header-has-title": !!title,
						"main-header-has-stamp": !!stamp,
						"main-header-has-toolbar": !!toolbar,
						"main-header-has-metadata": !!metadata,
						"main-header-has-banner": !!banner,
					},
					className
				)}
			>
				<Helmet>
					<title>{translate(title as string)} / TCPinpoint</title>
				</Helmet>
				{!!showBanner && (
					<div className={`alert ${banner.kind}`} role="alert">
						<i className="fas fa-exclamation-triangle" /> {banner.message}
						<button type="button" className="close" aria-label="Close" onClick={() => setShowBanner(!showBanner)}>
							<span aria-hidden="true">×</span>
						</button>
					</div>
				)}
				{/* todo - review use case for the 'like' feature */}
				{/* {!!isFeatureToggled("likes", currentUser) && <Like {...{ resource }} />} */}
				{!!stamp && (
					<>
						{/* Commenting this now to replace with another D icon */}
						{/* {resource?.is_defects ? (
							<div className="main-header-stamp" title="Defects">
								<div style={circleStyle} title="Defects">
									<span style={textStyle}>D</span>
								</div>
							</div>
						) : (
							<div className="main-header-stamp">
								{typeof stamp === "string" ? icon(stamp, "fal", ["fa-2x", "fa-fw"]) : stamp}
							</div>
						)} */}
						<div className="main-header-stamp">
							{typeof stamp === "string" ? icon(stamp, "fal", ["fa-2x", "fa-fw"]) : stamp}
						</div>
					</>
				)}
				<div className="main-header-titles">
					{!!subtitle && <Subtitle>{translate(subtitle as string)}</Subtitle>}
					{!!title && <Title>{translate(title as string)}</Title>}
				</div>
				{resource?.is_defects && (
					<div
						className="badge badge-warning"
						title="Defect"
						style={{ marginRight: "10px", backgroundColor: "rgba(0, 198, 215, 0.75)" }}
					>
						Defect
					</div>
				)}

				{!!toolbar && !!_.get(_.compact(toolbar), "length") && (
					<div className="main-header-toolbar">
						{_.map(_.compact(toolbar), (button: any) => (
							<Button
								confirm={!!button.confirm}
								data={_.assign({}, button, { ref: _.get(button, "label", "").toLowerCase() })}
								key={`button-${button.label}`}
								onClick={button.onClick}
								kind={!!button.icon ? button.icon : button.label}
								className={`${
									closeButtonStyle(stamp) &&
									button.label === "close_task" &&
									"bg-green-10 text-success-darker border-success-darker"
								}`}
							>
								{button.label}
							</Button>
						))}
					</div>
				)}
				{_.map(
					datapoints,
					(datapoint: any) =>
						datapoint.data && (
							<div className="main-header-datapoint" key={datapoint.title}>
								<h6>{datapoint.title}</h6>
								<span>{datapoint.data}</span>
							</div>
						)
				)}
				{!!children && <div className="main-header-children">{children}</div>}
				{!!metadata && !metadata.title && <div className="main-header-metadata">{metadata}</div>}
			</header>
		);
	}
	return null;
};
export default memo(Header);
