import classNames from "classnames";
import { Accept, Boolean, Date, Document, Inspection, Quote } from "components/Conditions";
import { Button, Date as DateComponent, LoadingState, Status } from "components/Shared";
import { LastMomentTriggerAPIContext } from "context";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";

interface Props {
	condition: tcpinpoint.Condition;
	task: tcpinpoint.Task;
}

const Row = ({ condition: originalCondition, task, onUpdateMoment, lastMoment}: any) => {
	const { setLastMoment: setTaskLastMoment } = useContext(LastMomentTriggerAPIContext);
	const [condition, setCondition] = useState({} as any);
	const conditionId = useMemo(() => _.get(condition, "id"), [condition]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "condition", renderTrigger: renderTrigger }));

	const dismiss = useCallback(() => {
		if (!_.isNil(conditionId)){
			datasource.put("v2", `conditions/${conditionId}/dismiss`, {}, { updateIsLoding: true });
		}
	}, [datasource, conditionId]);

	const undo = useCallback(() => {
		if (!_.isNil(conditionId)){
			datasource.put("v2", `conditions/${conditionId}/reopen`, {}, { updateIsLoding: true });
		}
	}, [datasource, conditionId]);

	const onUpdate = useCallback(
		(condition: any) => {
			setCondition(condition);
			setTaskLastMoment(moment());
			onUpdateMoment(moment());
		},
		[setCondition, setTaskLastMoment, onUpdateMoment]
	);

	useEffect(() => {
		setCondition(originalCondition);
	}, [originalCondition]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) onUpdate(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource, onUpdate]);

	if (datasource.isLoading) return <LoadingState />;
	if (_.isEmpty(condition)) return null;

	let element;
	const attrs = { condition, task, onUpdate, onUpdateMoment, lastMoment };

	switch (condition.kind) {
		case "boolean":
			element = <Boolean {...attrs} />; // API-v2 applied
			break;
		case "accept":
			element = <Accept {...attrs} />; // API-v2 applied
			break;
		case "document":
			element = <Document {...attrs} />;
			break;
		case "date":
			element = <Date {...attrs} />; // API-v2 applied
			break;
		case "inspection":
			element = <Inspection {...attrs} />; // TBD, currently no inspections used
			break;
		case "quote":
			element = <Quote {...attrs} />; // API-v2 applied
			break;
		default:
			element = null;
	}

	return (
		<div
			id={condition.id}
			key={condition.id}
			className={classNames("list-group-item", "condition", `condition-${condition.kind}`, {
				"condition-open": !condition.closed_at,
				"condition-closed": !!condition.closed_at,
				"condition-complete": !!condition.completed_at,
				"condition-incomplete": !condition.completed_at,
				"condition-deleted": !!condition.deleted_at,
				"condition-dismissed": !!condition.dismissed_at
			})}
		>
			<Status closed={!!task.closed_at} incomplete={!condition.completed_at} dismissed={!!condition.dismissed_at} />

			<div className="condition-block">
				<div className="descriptors">
					<h4>{condition.name}</h4>
					<p>{condition.description}</p>
				</div>
				{condition.dismissed_at ? (
					<div className="dismissed-meta">
						<p>{translate("dismissed")}</p>
						<p className="condition-meta muted">
							{_.get(condition, "dismissed_by.firstname")} {translate("dismissed_this")}{" "}
							<DateComponent date={condition.dismissed_at} />
						</p>
					</div>
				) : (
					element
				)}
			</div>
			{!task.closed_at && (
				<div className="condition-toolbar">
					{!condition.dismissed_at && !condition.completed_at && <Button onClick={dismiss} kind="dismiss" />}
					{!!condition.dismissed_at && <Button onClick={undo} kind="undo" />}
				</div>
			)}
		</div>
	);
};

export default memo(Row);
