import classNames from "classnames";
import { Show as Avatar } from "components/Avatar";
import { Address, ListGroup, ListGroupControl, ListGroupItem, LoadingState, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import { translate, useDefaults } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";

const OnePropertyRow = (property: any) => {
	return (
		<ListGroupItem
			icon={<Avatar url={property.avatar} name={property.name} />}
			link={`/properties/${property.id}`}
			key={property.id}
			heading={property.name}
			subheading={<Address address={_.first(property.addresses)} />}
			datapoints={[
				!!Math.round(property.gross_lettable_area) && {
					key: "gross_lettable_area",
					value: <React.Fragment>{Math.round(property.gross_lettable_area).toLocaleString()}m²</React.Fragment>
				},
				!!property.kind && { key: "kind", value: translate(property.kind) }
			]}
		/>
	);
};

const CollectionDynamic = () => {
	const defaults = useDefaults() as { sortBy: string; filteredBy: string };
	const [properties, setProperties] = useState([] as tcpinpoint.Project[]);
	const [filteredBy, setFilteredBy] = useState(defaults.filteredBy || "all");
	const [, renderTrigger] = useState(moment());
	const getSortAttribute = (sortBy: string) => (sortBy === "gross_lettable_area" ? "-gross_lettable_area" : sortBy);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "property",
			perPage: 20,
			currentPage: 1,
			sortBy: "name",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setProperties(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		datasource.get("v2", "properties", {
			queryStringParameters: {
				kind: filteredBy,
				sort: getSortAttribute(datasource.sortBy),
				per_page: datasource.perPage,
				page: datasource.currentPage,
				fields: JSON.stringify({ property: ["name", "avatar", "kind", "gross_lettable_area"] })
			}
		});
	}, [datasource, datasource.currentPage, datasource.perPage, datasource.sortBy, filteredBy]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<View hideTitle name="properties" key="company-properties" resource="properties">
			<ListGroupControl
				sortOptions={["name", "locality", "gross_lettable_area"]}
				onSortChange={newSortBy => {
					datasource.setCurrentPage(1);
					datasource.setSortBy(newSortBy);
				}}
				selectedSort={datasource.sortBy}
				filterOptions={["all", "subregional", "neighbourhood", "cbdretail"]}
				onFilterChange={newFilterBy => {
					datasource.setCurrentPage(1);
					setFilteredBy(newFilterBy);
				}}
				selectedFilter={filteredBy}
			/>
			<ListGroup pagination={false} resource="properties" collection={properties} renderRow={OnePropertyRow} />
			<div className={classNames("margin-atuo", "flex justify-center")}>
				<Pagination datasource={datasource} />
			</div>
		</View>
	);
};

export default memo(CollectionDynamic);
