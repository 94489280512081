import React, { memo, useContext } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Date as DateComponent, Status, ListGroupItem } from "../Shared";
import { translate, icons } from "../../lib";
import { UserContext } from "context";

interface Props {
	task: tcpinpoint.Task;
	project?: tcpinpoint.Project;
	showDue?: boolean;
	showRole?: boolean;
	showProject?: boolean;
}

const TaskRow = memo(({ task, showDue = true, showRole = true, showProject = false, project }: Props) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const isAssigned: boolean = _.includes(_.get(task, "user_ids", []), _.get(currentUser, "id"));
	const isSpectator: boolean = _.includes(_.get(task, "spectator_ids", []), _.get(currentUser, "id"));

	let badge = <i className={`far fa-${icons.no_permission}`} />;
	if (!!isAssigned) {
		badge = <span>{translate("mine")}</span>;
	} else if (!!isSpectator) {
		badge = <i className={`far fa-${icons.permission}`} />;
	}

	return (
		<ListGroupItem
			className={classNames("list-group-item", "task", {
				"list-group-item-yours": isAssigned,
				"list-group-item-watching": isSpectator,
				"list-group-item-prohibited": !isSpectator && !isAssigned,
				"task-open": !task.closed,
				"task-closed": !!task.closed,
				"task-ahead": !task.behind,
				"task-behind": !!task.behind,
				"task-complete": !!task.complete,
				"task-incomplete": !task.complete,
				"task-yours": !!isAssigned
			})}
			id={_.get(task, "id")}
			link={(!!isSpectator || !!isAssigned) && `/tasks/${_.get(task, "id")}`}
			parent={
				!!showProject && (
					<React.Fragment>
						<h6>{_.get(task, "property_name")}</h6>
						<h3>{_.get(task, "project_name")}</h3>
					</React.Fragment>
				)
			}
			heading={_.get(task, "name")}
			subheading={showRole && _.get(task, "role.name")}
			icon={<Status closed={!!task.closed} incomplete={!task.complete} />}
			datapoints={[
				!!showDue &&
					!_.get(task, "closed_at") && {
						key: _.get(task, "due_at_fixed") ? "fixed" : "tcp_due_at",
						value: <DateComponent date={_.get(task, "due_at")} showTime={false} />
					},
				!!_.get(task, "closed_at") && {
					key: "closed_at",
					value: <DateComponent date={_.get(task, "closed_at")} showTime={false} />
				}
			]}
			badge={badge}
			statistics={[
				(!!isSpectator || !!isAssigned) && {
					key: "conditions",
					value: (
						<React.Fragment>
							{_.get(task, "complete_conditions_count", 0)}
							<span className="muted">/</span>
							{_.get(task, "condition_ids", []).length}
						</React.Fragment>
					)
				},
				(!!isSpectator || !!isAssigned) && { key: "documents", value: _.get(task, "document_ids", []).length },
				(!!isSpectator || !!isAssigned) && { key: "comments", value: _.get(task, "comment_ids", []).length }
			]}
		/>
	);
});

export default TaskRow;
