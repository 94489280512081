import remark from "remark";
import strip from "strip-markdown";
import html from "remark-html";

// import emojione from 'emojione';
const hashtagify = text => {
  const regex = /([\s>])(#(\w+))/g;
  return `${text}`.replace(regex, "$1<a class='hashtag' href='/search?q=$3' class='hashtag'>$2</a>");
  // if (!!text) {
  //   let output = `${text}`;
  //   emojione.ascii = true;
  //   output = emojione.shortnameToImage(output);
  //   output = emojione.toImage(output);
  //   return output;
  // }
};

const emojify = text => {
  return text;
  // if (!!text) {
  //   let output = `${text}`;
  //   emojione.ascii = true;
  //   output = emojione.shortnameToImage(output);
  //   output = emojione.toImage(output);
  //   return output;
  // }
};

const stripTags = html => {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

const markdown = text => {
  if (!!text) {
    return remark()
      .use(strip)
      .process(text, (err, file) => String(file));
  }
};

const markup = text => {
  if (!!text) {
    return remark()
      .use(html)
      .process(hashtagify(emojify(text)), (err, file) => String(file));
  }
};

export {
  markdown,
  markup,
  emojify,
  stripTags
};