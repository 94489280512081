import React, { memo } from "react";
import classNames from "classnames";
import { Tabs } from "components/Shared";

interface Props {
	id: string;
	children?: any;
	hideTitle?: boolean;
	resource?: string;
	tabs?: (JSX.Element | boolean)[];
	className?: string | string[];
	highlightedTabs?: any;
	ready?: boolean;
}
const Workspace = (props: Props) => {
	const { id, children, resource, tabs, className, highlightedTabs, ready = true } = props;
	if (ready) {
		return (
			(children || tabs) && (
				<section className={classNames(resource, className, "workspace")} id={id}>
					{tabs && <Tabs {...{ tabs, highlightedTabs }} />}
					{children}
				</section>
			)
		);
	}
	return null;
};

export default memo(Workspace);
