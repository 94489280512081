import Icon from "components/Activities/Icon";
import { whatIs } from "lib";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { CreateComment, CreateCommentMention, DeleteComment } from "../Items";
import ActivityItemDom from "./ActivityItemDom";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityCommentItem = ({ activity }: Props) => {
	const props = { key: activity.attributes.item.id, activity };
	let link = `/${whatIs(_.get(activity.attributes.item.object, "link"))}/${_.get(
		activity.attributes.item.object,
		"id"
	)}`;
	let output;
	let icon;

	switch (activity.attributes.item.type) {
		case "Delete":
			icon = Icon("delete-comment");
			output = <DeleteComment {...props} />;
			break;
		case "Create":
			link = `/${whatIs(_.get(activity.attributes.item.subject, "link"))}/${_.get(
				activity.attributes.item.subject,
				"id"
			)}?_comment_id=${activity.attributes.item.object.id}#comments`;
			icon = Icon("create-comment");
			output = <CreateComment {...props} />;
			if (!!activity.attributes.item.to.length) {
				icon = Icon("create-comment-mention");
				output = <CreateCommentMention {...props} />;
			} else if ("inReplyTo" in activity.attributes.item) {
				icon = Icon("create-comment-reply");
			}
			break;
	}

	return (
		<Link to={link} className="activity-item-link">
			{" "}
			<ActivityItemDom activity={activity} icon={icon} link={link} output={output} />
		</Link>
	);
};

export default memo(ActivityCommentItem);
