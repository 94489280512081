import React, { memo } from "react";
import classNames from "classnames";
import { translate } from "lib";
import { Hint, Help, ErrorBoundary, Protected } from ".";
import _ from "lodash";

interface Props {
	resource?: string;
	children?: any;
	restricted?: boolean;
	title?: string | JSX.Element | false;
	subtitle?: string | JSX.Element | false;
	actions?: any;
	help?: any;
	name?: string;
	hint?: any;
	ghost?: any;
	hideTitle?: boolean;
	className?: string | string[];
	badges?: any;
	data?: any;
	isActive?: boolean;
	id?: string;
	icon?: any;
	onDrop?: any;
	active?: boolean;
}

const View = (props: Props) => {
	const {
		children,
		actions,
		resource,
		help,
		restricted = false,
		hint,
		title,
		ghost,
		hideTitle,
		subtitle,
		className,
		badges,
		data,
		id = false,
		icon,
		active = false
	} = props;

	// const currentActivity: string[] = useSelector((store: tcpinpoint.Store) => store.activity) as any;
	// const active: boolean = !!resource && !!_.get(currentActivity, resource);

	const ghostHeight: string = ghost && `ghost-${ghost.height}`;
	const attributes: any = { id: !!id ? id : `${resource}-card` };

	_.each(_.keys(data), key => {
		attributes["data-" + key] = data[key];
	});

	return (
		<ErrorBoundary>
			<article
				{...attributes}
				className={classNames([
					className,
					resource,
					"card",
					"view",
					ghostHeight,
					{
						"is-active": active,
						"has-help": !!help
					}
				])}
			>
				<div className={classNames("card-block")}>
					{!hideTitle && (!!title || !!resource) && (
						<div className="card-header">
							<i className="fal fa-circle-notch fa-spin" />
							{!!icon && <span className="icon">{icon}</span>}
							<h2>
								{translate((title as string) || (resource as string))} {badges && badges}
							</h2>
							{actions && (
								<div className="card-links">
									<div className="card-actions">{actions}</div>
								</div>
							)}
						</div>
					)}
					{!!restricted && <Protected />}
					{!!children && children}
					{!!subtitle && <p className="muted">{subtitle}</p>}
					{!!hint && <Hint>{hint}</Hint>}
					{!!help && <Help>{help}</Help>}
				</div>
			</article>
		</ErrorBoundary>
	);
};
export default memo(View);
