import React, { Fragment } from "react";
import { Tags, DefinitionList, DefinitionItem, Date, Row, Col } from "components/Shared";
import { Inline as User } from "components/Users";
import { Center } from "v2/components/shared";
import { translate } from "lib";
import _ from "lodash";

export default ({
	template,
	onUpdate,
	id
}: {
	template: tcpinpoint.Template;
	onUpdate: (props: any) => any;
	id?: string;
}) => {
	const tasks = _.flatten(_.map(_.get(template, "body.phases", []), phase => phase.tasks));
	return (
		<Fragment>
			{onUpdate && (
				<button onClick={onUpdate} className="close">
					<span aria-hidden="true">&times;</span>
				</button>
			)}
			<Center>
				<Row>
					<Col>
						<h3 className="text-center mb-5">{template?.name}</h3>
						{!!template?.description && <p>{template.description}</p>}
						<Row>
							<Col>
								<DefinitionList className="mt-5">
									{!!_.get(template, "property.name") && (
										<React.Fragment>
											<DefinitionItem title="projects_at">
												{_.get(template, "property.name")} at {_.get(template, "property.addresses[0].locality.name")}
											</DefinitionItem>
											<hr />
										</React.Fragment>
									)}
									{!!template?.company && (
										<DefinitionItem title="company">{_.get(template, "company.name")}</DefinitionItem>
									)}
									<DefinitionItem title="kind">{translate(template?.kind)}</DefinitionItem>
									<DefinitionItem title="tags">
										<Tags tags={template?.tags} readOnly={true} />
									</DefinitionItem>
								</DefinitionList>
							</Col>
							<Col>
								<DefinitionList>
									<DefinitionItem title="phases">
										{_.get(template, "body.phases", []).length} {translate("phases")}
									</DefinitionItem>
									<DefinitionItem title="tasks">
										{tasks.length} {translate("tasks")}
									</DefinitionItem>

									{!!tasks.length && (
										<DefinitionItem title="total_duration">
											{_.sum(_.map(tasks, task => parseInt(task.length, 10)))} {translate("days")}
										</DefinitionItem>
									)}
									{!!_.get(template, "body.users", []).length && (
										<React.Fragment>
											<hr />
											<DefinitionItem title="users">
												{_.get(template, "body.users", []).length} {translate("users")}
											</DefinitionItem>
										</React.Fragment>
									)}
								</DefinitionList>
							</Col>
							<Col>
								<DefinitionList>
									<DefinitionItem title="created_by">
										<User user={template?.created_by} />
									</DefinitionItem>
									<DefinitionItem title="created_at">
										<Date showTime date={template?.created_at} />
									</DefinitionItem>
								</DefinitionList>
							</Col>
						</Row>
					</Col>
				</Row>
			</Center>
		</Fragment>
	);
};
