import classNames from "classnames";
import _ from "lodash";
import React, { memo } from "react";
import { icons, translate } from "../../lib";
import { Date as DateComponent, ListGroupItem, Status } from "../Shared";

interface Props {
	task: tcpinpoint.Task;
	project?: tcpinpoint.Project;
	showDue?: boolean;
	showRole?: boolean;
	showProject?: boolean;
}

const TaskRow = ({ task, showDue = true, showRole = true, showProject = false, project }: Props) => {
	const isAssigned: boolean = _.get(task, "is_responsible", false);
	const isSpectator: boolean = _.get(task, "is_spectator", false);
	const hasNoAccess: boolean = !isAssigned && !isSpectator;
	const badge = isAssigned ? (
		<span>{translate("mine")}</span>
	) : isSpectator ? (
		<i className={`far fa-${icons.watching}`} />
	) : (
		<i className={`far fa-${icons.no_permission}`} />
	);

	return (
		<ListGroupItem
			className={classNames("list-group-item", "task", {
				"list-group-item-yours": isAssigned,
				"list-group-item-watching": isSpectator,
				"list-group-item-prohibited": hasNoAccess,
				"task-open": !!task.open,
				"task-closed": !!task.closed,
				"task-ahead": !!task.ahead,
				"task-behind": !!task.behind,
				"task-complete": !!task.complete,
				"task-incomplete": !!task.incomplete,
				"task-yours": !!isAssigned
			})}
			id={_.get(task, "id")}
			link={!hasNoAccess && `/tasks/${_.get(task, "id")}`}
			parent={
				!!showProject && (
					<React.Fragment>
						<h6>{_.get(task, "property_name")}</h6>
						<h3>{_.get(task, "project_name")}</h3>
					</React.Fragment>
				)
			}
			heading={_.get(task, "name")}
			subheading={showRole && _.get(task, "responsible_role_name")}
			icon={<Status closed={!!task.closed} incomplete={!task.complete} />}
			datapoints={[
				!!showDue &&
					!_.get(task, "closed_at") && {
						key: _.get(task, "due_at_fixed") ? "due_on" : "rescheduled_due_at",
						value: <DateComponent date={_.get(task, "due_at")} showTime={false} />
					},
				!!_.get(task, "closed_at") && {
					key: "closed_at",
					value: <DateComponent date={_.get(task, "closed_at")} showTime={false} />
				}
			]}
			badge={badge}
			statistics={[
				!hasNoAccess && {
					key: "conditions",
					value: (
						<React.Fragment>
							{_.get(task, "conditions_complete_count", 0)}
							<span className="muted">/</span>
							{_.get(task, "conditions_count", 0)}
						</React.Fragment>
					)
				},
				!hasNoAccess && { key: "documents", value: _.get(task, "all_documents_count", 0) },
				!hasNoAccess && { key: "comments", value: _.get(task, "comments_count", 0) }
			]}
		/>
	);
};

export default memo(TaskRow);
