import React from "react";
import { Row, Col, Title, Text } from "components/Shared";
import { Link } from "react-router-dom";
import { translate } from "lib";
import { useAppClass } from "lib/hooks";

const Success = () => {
	useAppClass("signup-success");
	return (
		<React.Fragment>
			<Row>
				<Col>
					<Title>{translate("success_title")}</Title>
					<Text>{translate("success_title_body")}</Text>
				</Col>
			</Row>
			<Row>
				<Col>
					<Title>{translate("success_how_things_work_title")}</Title>
					<Text>{translate("success_how_things_work_body")}</Text>
				</Col>
				<Col>
					<Title>{translate("success_support_title")}</Title>
					<Text>{translate("success_support_body")}</Text>
				</Col>
			</Row>
			<Row>
				<Col>
					<Link className="btn btn-primary btn-block btn-lg" to={`/signin`}>
						{translate("success_go")}
					</Link>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default Success;
