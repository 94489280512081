import _ from "lodash";
import React, { useContext, memo } from "react";
import { View, ListGroup } from "components/Shared";
import { Row } from "components/Tasks";
import { markup } from "lib/formatting";
import classNames from "classnames";
import { UserContext } from "context";
import { shallowEqual } from "react-redux";

interface Props {
	sortBy?: string;
	filteredBy?: string;
	viewBy?: string;
	phase: tcpinpoint.Phase;
	project: tcpinpoint.Project;
}
const Phase = ({ phase, sortBy = "", filteredBy, viewBy, project }: Props) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	if (phase) {
		const filterFunction = (task: tcpinpoint.Task) => {
			return (
				filteredBy === "" ||
				filteredBy === "all" ||
				!filteredBy ||
				(filteredBy === "overdue" && !!task.overdue) ||
				(filteredBy === "userIsAssigned" && !!task.userIsAssigned) ||
				(filteredBy === "userIsWatching" && !!task.userIsWatching) ||
				(filteredBy === "userHasPermission" && (!!task.userIsAssigned || !!task.userIsWatching))
			);
		};

		const collection = _.sortBy(
			_.filter(
				_.map(phase.tasks, (task: any) => {
					const closedAt = new Date(task.closed_at);
					const dueAt = new Date(task.due_at);
					//@ts-ignore
					const sortByDate = !!task.closed_at ? Math.floor(closedAt / 1000) : Math.floor(dueAt / 1000);
					const userIsAssigned = _.includes(task.user_ids, currentUser.id);
					const userIsWatching =
						userIsAssigned ||
						project.created_by_id === currentUser.id ||
						_.includes(task.spectator_ids, currentUser.id) ||
						_.includes(project.authority_ids, currentUser.id);
					// TODO: remove creating task object
					// const result = _.assign({}, new Task(task), { userIsAssigned, userIsWatching, sortByDate });
					return { ...task, userIsAssigned, userIsWatching, sortByDate };
				}),
				filterFunction
			),
			sortBy
		);

		if (!!collection.length) {
			return (
				<View
					id={phase.id}
					resource={"phase"}
					title={phase.name}
					className={classNames(phase.status, `viewby-${viewBy}`)}
				>
					{!!phase.description && <span dangerouslySetInnerHTML={{ __html: markup(phase.description) }} />}
					<ListGroup
						hidePageCount
						resource="tasks"
						collection={collection}
						renderRow={task => <Row project={project} key={`task-row-${task.id}`} task={task} showDue showRole />}
					/>
				</View>
			);
		}
	}
	return null;
};

export default memo(Phase, shallowEqual);
