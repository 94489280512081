import React, { memo } from "react";
import { View } from "components/Shared";

const Unauthorised = () => (
	<View resource="unauthorised" hideTitle>
		<div className="alert alert-warning">
			<h3>You can't view this. Sorry.</h3>
			<p>
				If you think this is a mistake, email <a href="mailto:help@tcpinpoint.com">help@tcpinpoint.com</a>
			</p>
		</div>
	</View>
);

export default memo(Unauthorised);
