import React, { memo } from "react";
import Anchor from "./Anchor";
import classNames from "classnames";

const Footer = ({ className }: { className?: string }) => {
	return (
		<div className={classNames("flex flex-col justify-center lg:justify-end z-10 text-white", className)}>
			<ul className="z-10 flex flex-col lg:flex-row lg:justify-center items-center text-12 lg-text-14 leading-4 tracking-wider ">
				<li>&copy;TCPinpoint {new Date().getFullYear()}</li>
				<ul className="flex mt-3 lg:mt-0 lg:ml-4">
					<li className="px-2">
						<Anchor href="mailto:help@tcpinpoint.com" label="Support" />
					</li>
					<li className="px-2">
						<Anchor href="http://tcpinpoint.com/about" label="About" />
					</li>
					<li className="px-2">
						<Anchor href="http://tcpinpoint.com/terms" label="Terms" />
					</li>
					<li className="px-2">
						<Anchor href="http://tcpinpoint.com/privacy" label="Privacy" />
					</li>
				</ul>
			</ul>
		</div>
	);
};
export default memo(Footer);
