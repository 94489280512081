import React, { memo } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Status } from "components/Shared/Status";
import CircleStatus from "components/Visualisations/Progress/CircleStatus";

interface Props {
	phases: tcpinpoint.Phase[];
	projectId: string;
	isLinked?: boolean;
	onReport?: boolean;
	clickableLink? : boolean;
}

const WorkflowVisual = (props: Props) => {
	const { phases, projectId, onReport, clickableLink } = props;

	const handleScrollToSection = (phaseId: string) => {
		const section = document.getElementById(phaseId);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className="workflow-status">
			{_.map(phases, (phase) => {
				if (!!onReport) {
					return (
						<Link
							onClick={ clickableLink? 
								() => handleScrollToSection(phase.id) :
								(e) => e.preventDefault()
							}
							key={phase.id}
							to={{ pathname: `/projects/${projectId}/` }}
							className={classNames("phase", {
								"phase-open": !phase.closed,
								"phase-closed": !!phase.closed,
								"phase-complete": !!phase.complete,
								"phase-incomplete": !phase.complete,
								"phase-ahead": !phase.behind && !phase.closed,
								"phase-behind": !!phase.behind && !phase.closed,
								"disabled-cursor": !clickableLink
							})}
						>
							<CircleStatus
								id={phase.id}
								incomplete={!phase.complete}
								closed={!!phase.closed}
								progress={phase.progress}
							/>
							<figcaption>{phase.name}</figcaption>
						</Link>
					);
				}

				return (
					<div
						onClick={() => handleScrollToSection(phase.id)}
						key={phase.id}
						className={classNames("phase", "cursor-pointer", {
							"phase-open": !phase.closed,
							"phase-closed": !!phase.closed,
							"phase-complete": !!phase.complete,
							"phase-incomplete": !phase.complete,
							"phase-ahead": !phase.behind && !phase.closed,
							"phase-behind": !!phase.behind && !phase.closed,
						})}
					>
						<Status incomplete={!phase.complete} closed={!!phase.closed} />
						<figcaption>{phase.name}</figcaption>
					</div>
				);
			})}
		</div>
	);
};

export default memo(WorkflowVisual);
