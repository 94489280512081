import { MomentInput, Moment } from "moment";
import React, { Dispatch, SetStateAction } from "react";
import moment from "moment";

export const UserContext = React.createContext({});
export const ProjectContext = React.createContext({});
export const NewProjectContext = React.createContext({});
export const ResourceContext = React.createContext({});

interface ILastMomentTriggerAPI {
    lastMoment: moment.MomentInput;
    setLastMoment: Dispatch<SetStateAction<Moment>>
}
export const LastMomentTriggerAPIContext = React.createContext({setLastMoment: (lastMoment: any) => {} } as ILastMomentTriggerAPI);

interface IIsLoadingContext {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>
}
export const IsLoadingContext = React.createContext({isLoading: false, setIsLoading: (isLoading: boolean) => {} } as IIsLoadingContext);
