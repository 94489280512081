import validator from "validator";
import { parsePhoneNumberFromString } from "libphonenumber-js";

// pass => true;
export const isLongerThan = (value: any, min: any) => value.length >= min;
export const isShorterThan = (value: any, max: any) => value.length <= max;
export const isEmail = (email: any) => validator.isEmail(email);
export const isPresent = (value: any) => !!value && !!value.length;

export const isValidPassword = (value: any) => {
	if (!value) {
		return false;
	}
	const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
	const numerals = /[0-9]+/;
	const upperCaseLetters = /[A-Z]+/;
	const formatValid = specialCharacters.test(value) && numerals.test(value) && upperCaseLetters.test(value);
	return value.length >= 8 && formatValid;
};

export const getValidator = (type: string): ((value: any, rule: any) => boolean) => {
	switch (type) {
		case "email":
			return isEmail;
		case "password":
			return isPresent;
		case "required":
			return isPresent;
		default:
			return (_: any, __: any) => true;
	}
};

export const generatePhoneNumberValidator = (region: any) => (phone: string) => {
	try {
		const phoneNumber = parsePhoneNumberFromString(phone, region);
		return phoneNumber && phoneNumber.isValid();
	} catch (err) {
		return false;
	}
};

export const generateAUOrIntlPhoneNumberValidator = () => (phone: string) => {
	try {
		return phone.replace(/ /g, "").length >= 6;
	} catch (err) {
		return false;
	}
};
