import "whatwg-fetch";
import LocalNotification from "lib/Notification";
import React from "react";
import validate from "validate.js";
import { withRouter } from "react-router-dom";
import { LoadingState, Steps, Step } from "components/Shared";
import { Select as Template } from "components/Templates";
import { Select as Tenancy } from "components/Tenancies";
import { Select as Lease } from "components/Leases";
import { Confirmation } from "components/Projects";
import { Select as Tenant } from "components/Tenants";
import { connect } from "react-redux";
import { translate, getParams, isFeatureToggled } from "lib";
import { UserContext, NewProjectContext } from "context";

import { getTemplates, getProperties, getTenants, getTenancies } from "actions";
import { Ledgers } from "components/Ledgers/Create";
import _ from "lodash";
import moment from "moment";
import { toSentenceSerial } from "underscore.string";
import uuidv4 from "uuid";
import axios from "v2/utils/axios";

const constraints = {
	property: {
		presence: { message: "^Must select a Property", allowEmpty: false }
	},
	template: {
		presence: { message: "^Must select a Template", allowEmpty: false }
	}
};

const SESSION_STORAGE_KEY = "cached_new_project";

class ProjectCreate extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			errors: false,
			schedule: false,
			deadlines: false,
			details: false,
			property: false,
			tenant: false,
			tenancy: false,
			template: false,
			lease: false,
			loading: false,
			ledgers: false,
			enabled: ["property", "template", "tenant", "tenancy", "schedule"]
		};
		if (!sessionStorage.getItem(SESSION_STORAGE_KEY)) {
			sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(this.state));
		}
	}

	componentDidMount() {
		const { getTemplates, getProperties } = this.props;
		getTemplates && getTemplates();
		getProperties && getProperties();
		this.restore(this.props);
		document.getElementById("app").classList.add("project-create");
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.restore(props);
	}

	restore() {
		const { property, template } = this.props;
		let jsonifiedState = {};
		try {
			jsonifiedState = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
		} catch (error) {
			console.error(error);
		}

		if (property) {
			jsonifiedState.property = property;
		}

		if (template) {
			jsonifiedState.template = template;
		}
		this.setState(jsonifiedState);
	}

	reset() {
		this.setState({
			errors: {},
			details: false,
			lease: false,
			loading: false,
			property: false,
			schedule: false,
			template: false,
			tenancy: false,
			tenant: false
		});
		sessionStorage.setItem(SESSION_STORAGE_KEY, {});
	}

	cache() {
		const { tenant, property, lease, template, tenancy } = this.state;
		const complete =
			!!tenant &&
			!!tenancy &&
			!!property &&
			// (!!_.get(ledgers, "length", 0) || !_.includes(_.get(template, "ledgers", []))) &&
			(!!lease || !_.includes(_.get(template, "tags"), "lease")) &&
			!!template;
		this.setState({ complete });
		try {
			const jsonifiedState = JSON.stringify(this.state);
			sessionStorage.setItem(SESSION_STORAGE_KEY, jsonifiedState);
		} catch (error) {
			console.error(error);
		}
	}

	payload() {
		let { tenant, tenancy, template, property, lease, reference, deadlines, name, portfolio_ids } = this.state;
		return {
			template: {
				id: template.id
			},
			tenancy: {
				id: _.get(tenancy, "id"),
				floor_id: _.get(tenancy, "floor.id", null) || _.get(tenancy, "floor_id", ""),
				building_id: _.get(tenancy, "building.id", null) || _.get(tenancy, "building_id", ""),
				name: _.get(tenancy, "name", ""),
				area: _.get(tenancy, "area", ""),
				precinct: _.get(tenancy, "precinct", ""),
				division: _.get(tenancy, "division", ""),
				status: _.get(tenancy, "status", ""),
				kind: _.get(tenancy, "status", "")
			},
			tenant: {
				name: _.get(tenant, "name"),
				trading_name: _.get(tenant, "trading_name"),
				contact_details: _.get(tenant, "contact_details"),
				sector: _.get(tenant, "sector"),
				source: _.get(tenant, "source"),
				phone_number: _.get(tenant, "phone_number", "").replace(/[^0-9]/g, ""),
				full_time_equivalent: parseInt(_.get(tenant, "full_time_equivalent", 0), 10),
				business_code_id: _.get(tenant, "kind.id"),
				addresses: _.get(tenant, "addresses", [])
			},
			// ledgers: _.map(ledgers, ledger => ({
			// 	name: _.get(ledger, "name"),
			// 	quotes: _.map(ledger.quotes, quote => ({
			// 		name: quote.name
			// 	}))
			// })),
			company: {
				name: _.get(tenant, "name")
			},
			property: {
				id: _.get(property, "id"),
				name: _.get(property, "name")
			},
			lease: {
				annual_review: _.get(lease, "annual_review", ""),
				base_rent_cents: !!_.get(lease, "base_rent_cents", 0) ? parseInt(_.get(lease, "base_rent_cents", 0), 10) : 0,
				base_rent_frequency: _.get(lease, "base_rent_frequency", ""),
				duration: _.get(lease, "duration", ""),
				ends_at: !!_.get(lease, "ends_at", false) && moment(_.get(lease, "ends_at", false)).format("YYYY-MM-DD"),
				fitout_contribution_cents: _.get(lease, "fitout_contribution_cents", 0),
				fitout_ends_at:
					!!_.get(lease, "fitout_ends_at", false) && moment(_.get(lease, "fitout_ends_at", false)).format("YYYY-MM-DD"),
				handover_at:
					!!_.get(lease, "handover_at", false) && moment(_.get(lease, "handover_at", false)).format("YYYY-MM-DD"),
				incentives: _.get(lease, "incentives", ""),
				kind: _.get(lease, "kind", ""),
				lease_renewals: parseInt(_.get(lease, "lease_renewals", 0), 10),
				net_lettable_area: _.get(lease, "net_lettable_area", ""),
				opening_at:
					!!_.get(lease, "opening_at", false) && moment(_.get(lease, "opening_at", false)).format("YYYY-MM-DD"),
				operational_outgoings_cents: parseInt(_.get(lease, "operational_outgoings_cents", 0), 10),
				permitted_use: _.get(lease, "permitted_use", ""),
				rent_per_sqm_cents: parseInt(_.get(lease, "rent_per_sqm_cents", 0), 10),
				rent_review_percent: parseInt(_.get(lease, "rent_review_percent", 0), 10),
				rent_starts_at:
					!!_.get(lease, "rent_starts_at", false) && moment(_.get(lease, "rent_starts_at", false)).format("YYYY-MM-DD"),
				seating_area: _.get(lease, "seating_area", ""),
				starts_at: !!_.get(lease, "starts_at", false) && moment(_.get(lease, "starts_at", false)).format("YYYY-MM-DD"),
				status: _.get(lease, "status", ""),
				statutory_outgoings_cents: parseInt(_.get(lease, "statutory_outgoings_cents", 0), 10),
				storage_area: parseInt(_.get(lease, "storage_area", 0), 10),
				vacant_possession_at:
					!!_.get(lease, "vacant_possession_at", false) &&
					moment(_.get(lease, "vacant_possession_at", false)).format("YYYY-MM-DD")
			},
			project: {
				name,
				reference,
				portfolio_ids,
				deadlines: _.map(deadlines, deadline => ({
					name: _.get(deadline, "name"),
					date: _.get(deadline, "date"),
					task_template_id: _.get(deadline, "task_id")
				})),
				template_id: template.id
			}
		};
	}

	export() {
		const json = JSON.stringify(this.payload());
		const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(json);
		const dlAnchorElem = document.createElement("a");
		dlAnchorElem.setAttribute("href", dataStr);
		dlAnchorElem.setAttribute("download", "create-project.json");
		dlAnchorElem.click();
	}

	updateTemplate(template) {
		const { properties } = this.props;
		if (template) {
			this.setState(
				{
					template,
					company: _.get(template, "company", false),
					property: _.find(properties, { id: _.get(template, "property.id") })
				},
				() => this.cache()
			);
			if (!!template.company) {
				axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}companies/${_.get(template, "company.id")}/tenants`)
					.then((res) => this.setState({ tenants: res.data.tenants }));
			}
		} else {
			this.reset();
		}
	}

	updateTenancy(tenancy) {
		this.setState({ tenancy, property: tenancy.property, name: _.get(tenancy, "name") }, () => this.cache());
	}

	updateTenant(tenant) {
		this.setState({ tenant, name: _.get(tenant, "trading_name") }, () => this.cache());
	}

	updateLedgers(ledgers) {
		this.setState({ ledgers }, () => this.cache());
	}

	updateLease(lease) {
		const deadlines = [];
		_.map(["ends_at", "fitout_ends_at", "handover_at", "opening_at", "rent_starts_at", "starts_at"], d => {
			if (!!lease[d]) {
				deadlines.push({
					id: uuidv4(),
					date: moment(lease[d]).format("YYYY-MM-DD"),
					name: translate(d)
				});
			}
		});

		this.setState({ lease, deadlines: _.sortBy(deadlines, "date") }, () => this.cache());
	}

	updateSchedule(schedule) {
		this.setState({ schedule }, () => this.cache());
	}

	onSubmit() {
		const { history } = this.props;
		let { tenant, tenancy, template, property, lease, schedule } = this.state;
		if (template.kind === "retail" && !!property.name) {
			constraints.tenant = {
				presence: { allowEmpty: false }
			};
			constraints.tenancy = {
				presence: { allowEmpty: false }
			};
		}
		tenant = !!tenant.name ? tenant : undefined;
		tenancy = !!tenancy.name ? tenancy : undefined;
		property = !!property.name ? property : undefined;
		template = !!template.id ? template : undefined;
		this.setState({ loading: true });
		const errors = validate(
			{
				template,
				tenant,
				tenancy,
				lease,
				property,
				schedule
			},
			constraints
		);
		if (errors !== undefined) {
			this.setState({ errors, loading: false }, () => {
				let errorMessage = toSentenceSerial(_.map(_.keys(errors, key => errors[key])));
				new LocalNotification(`but ${errorMessage}`, translate("error_boundary_body"), {
					type: "error"
				});
			});
		} else {
			axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}projects`, { body: this.payload() })
				.then((res) => {
					new LocalNotification("Project Created");
					sessionStorage.setItem(SESSION_STORAGE_KEY, {});
					history.push(`/projects/${_.get(res.data.project, "id")}`);
				})
				.catch((e) => {
					const { response } = errors;
					this.setState({ loading: false, errors: { response } }, () => this.cache());
					new LocalNotification("Something Went Wrong", "Something Went Wrong", {
						type: "error"
					});
				});
		}
	}

	render() {
		const { errors, ledgers, property, template, tenant, tenancy, lease, loading } = this.state;
		const { currentUser } = this.context;

		if (!!loading) {
			return <LoadingState />;
		}

		return (
			<NewProjectContext.Provider value={this.state}>
				<Steps>
					{!!isFeatureToggled("templates", currentUser) && (
						<Step ref="template" complete={!!template} enabled={true}>
							<Template {...this.state} onUpdate={template => this.updateTemplate(template)} />
						</Step>
					)}
					{!!isFeatureToggled("tenancies", currentUser) && (
						<Step
							ref="tenancy"
							label={_.includes(_.get(template, "tags"), "flinders") ? "space" : "tenancy"}
							complete={!!tenancy}
							enabled={!!template}
						>
							<Tenancy {...this.state} onUpdate={tenancy => this.updateTenancy(tenancy)} />
						</Step>
					)}
					{!!isFeatureToggled("tenants", currentUser) && (
						<Step
							ref="tenant"
							label={_.includes(_.get(template, "tags"), "flinders") ? "client" : "tenant"}
							complete={!!tenant}
							enabled={!!template && !!property && !!tenancy}
						>
							<Tenant {...this.state} onUpdate={tenant => this.updateTenant(tenant)} />
						</Step>
					)}
					{!!isFeatureToggled("ledgers", currentUser) && false && (
						<Step ref="ledgers" complete={!!ledgers} enabled={!!tenant && !!tenancy && !!property && !!template}>
							<Ledgers {...this.state} onUpdate={ledgers => this.updateLedgers(ledgers)} />
						</Step>
					)}
					{!!isFeatureToggled("leases", currentUser) && (
						<Step
							ref="lease"
							label={_.includes(_.get(template, "tags"), "flinders") ? "parameters" : "lease"}
							complete={!!lease}
							enabled={!!template && !!property && !!tenancy && !!tenant}
						>
							<Lease {...this.state} onUpdate={lease => this.updateLease(lease)} />
						</Step>
					)}
					{/* {!!isFeatureToggled("deadlines", currentUser) && (
						<Step
							ref="key-dates"
							complete={!!_.get(deadlines, "length", 0)}
							enabled={!!tenant && !!tenancy && !!property && !!template}
						>
							<View>
								<Row>
									<Col>
										<Title>{translate("deadlines")}</Title>
										<Deadlines
											project={this.state}
											tasks={_.flatMap(_.get(template, "body.phases", []), "tasks")}
											onSubmit={project => {
												this.setState({ deadlines: project.deadlines }, () => this.cache());
											}}
										/>
									</Col>
								</Row>
							</View>
						</Step>
					)} */}
					<Step
						ref="project"
						complete={
							!!tenant &&
							!!tenancy &&
							!!property &&
							(!!lease || !_.includes(_.get(template, "tags"), "lease")) &&
							!!template &&
							!errors
						}
						enabled={
							!!tenant &&
							!!tenancy &&
							!!property &&
							(!!lease || !_.includes(_.get(template, "tags"), "lease")) &&
							!!template
						}
					>
						<Confirmation
							{...this.state}
							clearErrors={() => this.setState({ errors: false })}
							goto={currentStepIndex => this.setState({ currentStepIndex })}
							onUpdate={state => this.setState(_.assign({}, this.state, state), () => this.cache())}
							onSubmit={() => this.onSubmit()}
						/>
					</Step>
				</Steps>
			</NewProjectContext.Provider>
		);
	}
}
export const Create = withRouter(ProjectCreate);

const mapStateToProps = ({ properties, tenants, tenancies, templates }, props) => {
	const query = _.get(props, "location.search", false);
	if (query) {
		const { property_id, tenancy_id, tenant_id, template_id } = getParams();
		return {
			properties,
			property: _.find(properties, { id: property_id }),
			tenant: _.find(tenants, { id: tenant_id }),
			tenancy: _.find(tenancies, { id: tenancy_id }),
			template: _.find(templates, { id: template_id })
		};
	}
	return { property: false, tenant: false, tenancy: false, template: false };
};

const mapDispatchToProps = dispatch => ({
	getTemplates: () => dispatch(getTemplates()),
	getProperties: () => dispatch(getProperties()),
	getTenants: () => dispatch(getTenants()),
	getTenancies: property => dispatch(getTenancies(property))
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
