import React from "react";
import { ListGroup } from "components/Shared";
import { Task } from "components/Templates";
import _ from "lodash";

export default class Tasks extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tasks: props.tasks
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { tasks } = props;
    this.setState({ tasks });
  }

  onUpdate(task) {
    const { onUpdate } = this.props;
    const { tasks } = this.state;
    tasks.splice(_.findIndex(tasks, {id: task.id}), 1, task)
    this.setState({ tasks: tasks }, onUpdate(tasks));
  }

  onDelete(task) {
    const { onUpdate } = this.props;
    const { tasks } = this.state;
    const newTasks = tasks.filter(_task => _task.id !== task.id);
    this.setState({ tasks: newTasks }, onUpdate(newTasks));
  }

  render() {
    const { template, phase, onAdd } = this.props;
    const { tasks } = this.state;

    return (
      <ListGroup
        resource="tasks"
        onAdd={onAdd}
        collection={tasks}
        renderRow={task => (
          <Task
            task={task}
            key={task.id}
            phase={phase}
            template={template}
            onUpdate={task => this.onUpdate(task)}
            onDelete={task => this.onDelete(task)}
          />
        )}
      />
    );
  }
}
