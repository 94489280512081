import React, {useState} from "react";
import { ListGroupControl, View, LoadingState } from "../../Shared";
import { WorkflowLayout, WorkflowTable } from './ReportTable';
import { ButtonDropDown } from '../ButtonDropDown';
import _ from 'lodash';
import { saveAs } from "file-saver";
import axios from "v2/utils/axios";

const TenancyCollection = ({ properties }: { properties: any}) => {

	const [propertyNameSelected, setSelectedProperty] = useState({
		id: "",
		name: "Select a Property",
	});
	const [TenancyReports, setTenancyReports] = useState([]);
	const [isReportLoading, setIsReportLoading] = useState(false);

	const getTenancyTrackerReport = (id: string) => {
		const path = `advanced_reports/tenancy_tracking_report?property_id=${id}`;
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}${path}`)
			.then((res) => {
				setTenancyReports(res.data)
			})
			.catch((e) => {})
			.finally(() => setIsReportLoading(false));
	}

	let tempReports = Object.values(TenancyReports);
	let reports: any = tempReports[0];

	const callBack = (id: string) => {
		setIsReportLoading(true);
		let property = _.find(properties, {'id': id});
		setSelectedProperty({
			id: property.id,
			name: property.name,
		});
		getTenancyTrackerReport(id);
		return;
	}

	const downloadCSV = (templateId: string, propertyName: string) => {
		const path = `advanced_reports/tenancy_tracking_report_download_csv?property_id=${propertyNameSelected.id}&template_id=${templateId}`
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}${path}`, { params: {'responseType': 'text'} })
			.then((res) => {
				let blob = new Blob([res.data], {type: "text/csv;charset=utf-8"});
				saveAs(blob, _.kebabCase(propertyName) + ".csv");
			})
			.catch((e) => {});
	}

	const openProject = (projectId: string) => {
		const urlPath = `/projects/${projectId}`;
		const win = window.open(urlPath, '_blank');
		if(win){
			win.focus();
		}
	}

	return (
		<View className="flex flex-row">
			<ListGroupControl />
			<div className="flex items-center">
				<ButtonDropDown
					buttonOption={propertyNameSelected}
					dropdownOptions={properties}
					callBack={(arg) => {
						callBack(arg);
					}}
					textClassNames="w-215 text-14 transition rounded-l-md text-left p-4"
					dropDownClassNames="bg-white border-borderColor border-l"
				/>
				<p className="text-reportProjectSubtitle text-14 ml-6">Choose the property you want to report on</p>
			</div>
			<div className="">
				{isReportLoading === false && propertyNameSelected.id && reports ? (<div className="-mx-4 flex items-center bg-blue-10 text-blue-darker text-20 h-80 mt-4 font-bold py-4 px-8">
					Property: {propertyNameSelected.name}
				</div>) : ''}
			</div>
			<div>
			{isReportLoading === false ? reports && (reports.map((template: any) => {
				return(
					<>
						<div className="flex flex-col py-4">
							<div className="flex">
								<div className="mt-10">
									<WorkflowTable reports={template} clickHandler={(projectId: string) => openProject(projectId)}/>
								</div>
								<div
									className="overflow-x-auto flex"
									style={{
										minWidth: 'calc(100vw - 482px - 64px)',
										maxWidth: 'calc(100vw - 482px - 64px)',
									}}
								>
									<WorkflowLayout reports={template} />
								</div>
							</div>
							<button className="mt-4 py-3 rounded-md border-none bg-blue-90 w-140" onClick={() => downloadCSV(template.template_id, propertyNameSelected.name)}>
								<span className="text-white text-12 font-medium">
									Download For Excel
								</span>
							</button>
							<div className="text-workflowGray text-14 font-bold mt-5">
								Workflow: {template.template_name}
							</div>
						</div>
					</>
				)
			})) : <LoadingState />}

			</div>
		</View>
	);
};
export default TenancyCollection;
