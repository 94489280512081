import React, { memo, useState, Fragment } from "react";
import { format } from "v2/utils";
import classNames from "classnames";
import Eye from "./Eye";
import { useFormContext } from "./hooks";

const Input = ({
	defaultType,
	name,
	focused,
	setFocused,
	value,
	validate,
	onChange,
	placeholder,
	setEyeHover,
	disabled
}: any) => {
	const [type, setType] = useState(defaultType || "text");
	const [visible, setVisible] = useState(true);
	const { error, setError } = useFormContext();

	return (
		<Fragment>
			<input
				type={type}
				name={name}
				id={name}
				className={classNames("w-full focus:outline-none bg-transparent tracking-wider", {
					"text-center": name === "code",
					"tracking-widest": name === "code",
					"mr-2": defaultType === "password"
				})}
				onBlur={() => {
					validate(value);
					setFocused(false);
				}}
				onFocus={() => {
					setError && setError({ ...error, [name]: "" });
					setFocused(true);
				}}
				value={value}
				onChange={event => {
					validate(event.target.value);
					onChange(format(event.target.value, name));
				}}
				placeholder={placeholder}
				disabled={disabled}
			/>
			<Eye
				enable={defaultType === "password"}
				visible={visible}
				className={classNames("h-4", "hover:cursor-pointer ", "transition-all duration-200 ease-in", {
					"text-blue-100": focused,
					"text-black-40": !focused,
					"hover:text-black-100": !focused
				})}
				onClick={() => {
					document.getElementById(name!)?.focus();
					setType(visible ? "text" : "password");
					setVisible(!visible);
				}}
				onHover={() => setEyeHover(true)}
				onLeave={() => setEyeHover(false)}
			/>
		</Fragment>
	);
};

export default memo(Input);
