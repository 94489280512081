import React from 'react';
import List from "./List";
import AddProject from "./AddProject";
import Reports from "./Reports";
import Modal from "./Modal";
import Report from "./Report";

import { lazy } from "react";
const LazyShow = lazy(() => import("./Show"));
const Show = (props: any) => <LazyShow {...props}/> 

export { Reports, AddProject, Show, List, Modal, Report };
