import React from "react";
import { translate } from "lib";
import { ErrorBoundary } from "components/Shared";
import Actor from "components/Activities/Actor";
import Subject from "components/Activities/Subject";
import Context from "components/Activities/Context";

const CreateComment = ({ activity }: { activity: tcpinpoint.ActivityItem }) => (
	<ErrorBoundary>
		{<Actor actor={activity.attributes.item.actor} />}{" "}
		{translate("inReplyTo" in activity.attributes.item ? "replied_to" : "posted_to")}{" "}
		{"inReplyTo" in activity.attributes.item && (
			<React.Fragment>
				<Actor actor={activity.attributes.item.inReplyTo} />
				{" on "}
			</React.Fragment>
		)}
		<Subject subject={activity.attributes.item.subject} />{" "}
		{activity.attributes.item.context.link !== activity.attributes.item.subject.link && (
			<Context context={activity.attributes.item.context} />
		)}
	</ErrorBoundary>
);

export default CreateComment;
