import classNames from "classnames";
import { UserContext } from "context";
import { isFeatureToggled } from "lib";
import _ from "lodash";
import React, { memo, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Circle as Logo } from "v2/components/shared/icons";
import Link from "./Link";
import SearchComponent from "v2/components/Search";
import tcpRobot from "../../../../images/tcp_robot.png";

const betas = [""];

const PermissionBasedMast = (): string[] => {
	const { currentUser } = useContext(UserContext) as { cable: any; currentUser: tcpinpoint.User };
	let features = ["properties", "projects", "tasks", "todos", "portfolios"];
	let reportFeatures = ["tenancy-tracking-report", "team-analysis-report", "lease-to-open-report"];
	reportFeatures = reportFeatures.filter((f) => !!isFeatureToggled(f, currentUser));
	if(reportFeatures.length > 0){
		features.push("reports");
	}
	features.push("templates");
	return features;
}

const Masthead = ({ className }: { className?: string }) => {
	const { currentUser } = useContext(UserContext) as { cable: any; currentUser: tcpinpoint.User };
	const features = PermissionBasedMast();

	return (
		<header className={classNames("nav h-20 bg-white flex", className)}>
			<div className="left flex flex-1 border-t-3 border-transparent">
				<NavLink to="/" className="hover:bg-transparent">
					<Logo className="p-1 ml-2 w-15 h-20" />
				</NavLink>
				<div className="features flex ml-1 h-20 items-center">
					{_.map(features, feature => {
						return (
							(feature === "reports" || !!isFeatureToggled(feature, currentUser)) && (
								<Link
									href={"/" + feature}
									label={feature}
									className={feature === "tasks" ? "px-5" : "px-4"}
									beta={_.includes(betas, feature)}
									key={feature}
								/>
							)
						);
					})}
					<SearchComponent />
					{process.env.NODE_ENV === "development" && (
						<div className="flex justify-center items-center ml-10">
							<div className=" bg-warning-light w-full px-3 text-center h-12 leading-12 text-black-80 font-bold text-20 uppercase whitespace-no-wrap">
								develop branch
							</div>
						</div>
					)}
				</div>
			</div>
      <div className="flex items-center ml-10 mr-5">
        <a href="https://tcpinpoint-learning.thinkific.com/communities/Q29tbXVuaXR5LTQ5NTM4/"
          target="_blank" 
          rel="noopener noreferrer"
          className="hover:bg-transparent">
            <div className="flex justify-end">
              <div className="w-full px-3 text-right h-12 leading-12 text-black-80 font-bold text-15 whitespace-no-wrap">
                Join our Learning Community!
              </div>
              <img src={tcpRobot} alt="TCP Thinkific" className="w-12 h-12"/>
            </div>
        </a>
      </div>
			<div className="right">
				<div className="flex border-t-3 border-transparent">
					<Link href="/signout" className="w-19" label="signout" hideLabel />
				</div>
			</div>
		</header>
	);
};

export default memo(Masthead);
