import React from "react";
import Modal from "react-modal";
import { Button, ListGroup, ListGroupItem, ModalHeader } from "components/Shared";
import { Lookup } from "components/Users";
import _ from "lodash";
import { Show as Avatar } from "components/Avatar";

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: _.get(props, "users", [])
    };
  }

  pushItem(user) {
    const { users } = this.state;
    users.push(user);
    this.setState({ users: _.uniqBy(users, "id") });
  }

  popItem(user) {
    const { users } = this.state;
    this.setState({ users: _.filter(users, _user => user.id !== _user.id) });
  }

  render() {
    const { users } = this.state;
    const { onUpdate, onClose, isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHeader title="select_user" onClose={onClose} />
        <section>
          <Lookup onChange={user => this.pushItem(user)}>
            <ListGroup
              resource="users"
              collection={users}
              renderRow={user => (
                <ListGroupItem
                  key={user.id}
                  icon={<Avatar url={_.get(user, "avatar")} name={_.get(user, "name")} />}
                  heading={user.name}
                  subheading={_.get(user, "company.name")}
                  onClose={() => this.popItem(user)}
                />
              )}
            />
          </Lookup>
        </section>
        <footer>
          <Button onClick={() => onUpdate(users)} />
        </footer>
      </Modal>
    );
  }
}

export default Select;
