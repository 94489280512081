import React, { useContext } from "react";
import _ from "lodash";
import { UserContext } from "context";

const Greeting = () => {
	const { currentUser } = useContext(UserContext) as { currentUser: any };
	const greetings = [
		"Aloha%s",
		"Hello%s",
		"Hi%s",
		"’Sup%s",
		"Ahoy-hoy%s",
		"Guten Tag%s",
		"Good Day%s",
		"¡Hola%s!",
		"Bonjour%s",
		"Namaste%s",
		"Sawubona%s",
		"Chào%s",
		"Salam%s",
		"Salut%s",
		"You look nice today %s",
		"ellohay%s"
	];

	let greeting = greetings[Math.floor(Math.random() * greetings.length)];

	return (
		<div className="greeting text-black-100">
			{greeting.replace(/%s/i, " " + _.get(currentUser, "firstname", ""))}
			{process.env.NODE_ENV === "development" && `[${_.get(currentUser, "id")}]`}
		</div>
	);
};
export default React.memo(Greeting);
