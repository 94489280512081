import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import { Button, ButtonDropDown, Date as DateInput } from "components/Shared";
import moment from "moment";
import _ from "lodash";
import { UserContext } from "context";
import axios from "v2/utils/axios";

const AttachmentIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.3335 10.8333C8.69137 11.3118 9.14796 11.7077 9.67229 11.9941C10.1966 12.2806 10.7764
			 12.4509 11.3724 12.4936C11.9683 12.5363 12.5665 12.4503 13.1263 12.2415C13.6861 12.0327 14.1944 
			 11.7059 14.6168 11.2833L17.1168 8.78334C17.8758 7.9975 18.2958 6.94499 18.2863 5.85251C18.2768 4.76002
			  17.8386 3.71497 17.0661 2.94243C16.2935 2.1699 15.2485 1.7317 14.156 1.7222C13.0635 1.71271 12.011
			   2.13269 11.2252 2.89168L9.79183 4.31668"
				stroke="#A0A4A8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.6668 9.16668C11.309 8.68824 10.8524 8.29236 10.328 8.0059C9.80371 7.71943 9.22391 7.54908
			 8.62796 7.5064C8.032 7.46372 7.43384 7.54971 6.87404 7.75853C6.31425 7.96735 5.8059 8.29412 5.3835
			  8.71668L2.8835 11.2167C2.12451 12.0025 1.70453 13.055 1.71402 14.1475C1.72352 15.24 2.16172 16.2851
			   2.93426 17.0576C3.70679 17.8301 4.75184 18.2683 5.84433 18.2778C6.93681 18.2873 7.98932 17.8673
			    8.77517 17.1083L10.2002 15.6833"
				stroke="#A0A4A8"
				stroke-Width="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

interface ModalProps {
	name?: string;
	isOpen?: boolean;
	reloadPage: () => void;
	toggleModal?: (arg: boolean) => void;
	id: string;
	updateTodoId?: string;
	updateTodo: boolean;
	updateTodoInfo?: any;
	todoKind: string;
	userList?: any;
}

const TodoModal = (props: ModalProps) => {
	const { currentUser } = useContext(UserContext) as { currentUser: any };
	const { reloadPage, isOpen, toggleModal, updateTodo, updateTodoInfo, updateTodoId, todoKind, userList } = props;
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [assignee, setAssignee] = useState({
		id: "",
		name: "Pick an assignee",
	});
	const [reporter, setReporter] = useState({
		id: "",
		name: "Pick a reporter",
	});

	const [status, setTodoStatus] = useState({
		id: "1",
		name: "Open",
	});
	const [createdDate, setCreatedDate] = useState("");
	const [date, setDate] = useState("");
	const [, creatingNewTodo] = useState(false);
	const [titleError, setTitleError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);
	const [assigneeError, setAssigneeError] = useState(false);
	const [dueDateError, setDueDateError] = useState(false);
	const [teamMembers, setTeamMembers] = useState([]);
	const [isDateUpdated, setUpdatedDate] = useState(false);

	useEffect(() => {
		if (updateTodo) {
			setDate(updateTodoInfo.attributes.due_at);
			setTitle(updateTodoInfo.attributes.title);
			setDescription(updateTodoInfo.attributes.description);
			setAssignee({
				id: updateTodoInfo.attributes.assigned_to_id,
				name: updateTodoInfo.attributes.assigned_to_name,
			});
			setReporter({
				id: updateTodoInfo.attributes.created_by_id,
				name: updateTodoInfo.attributes.created_by_name,
			});
			setTodoStatus(
				updateTodoInfo.attributes.status.toLowerCase() === "open"
					? {
							id: "1",
							name: "Open",
					  }
					: {
							id: "2",
							name: "Closed",
					  }
			);
			setCreatedDate(updateTodoInfo.attributes.created_at);
			setAssigneeError(false);
			setTitleError(false);
			setDescriptionError(false);
			setDueDateError(false);
			setAssigneeError(false);
			setDescriptionError(false);
			setTitleError(false);
			setUpdatedDate(false);
		} else {
			initialState();
		}
	}, [updateTodo, updateTodoInfo]);

	const initialState = () => {
		setDate("");
		setTitle("");
		setDescription("");
		setAssignee({ id: "", name: "Pick an assignee" });
		setReporter({ id: "", name: "Pick an reporter" });
		setTodoStatus({ id: "1", name: "Open" });
		setCreatedDate("");
		setAssigneeError(false);
		setTitleError(false);
		setDescriptionError(false);
		setDueDateError(false);
		setAssigneeError(false);
		setDescriptionError(false);
		setTitleError(false);
		setUpdatedDate(false);
	};

	const getTeamMembersList = () => {
		if (todoKind === "global") {
			if (updateTodoInfo.attributes.todoable_type === "Project") {
				const urlPath = `parties?project_id=${updateTodoInfo.attributes.todoable_id}&sort=name&per_page=-1&page=1`;

				axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}${urlPath}`)
					.then((res) => setTeamMembers(res.data.users.included))
					.catch((e) => {});
			} else {
				const queryString = {
					// queryStringParameters: {
						fields: JSON.stringify({ users: ["firstname", "surname", "name", "email", "phone_number"] }),
					// },
				};

				axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}properties/${updateTodoInfo.attributes.todoable_id}`, 
						{ 
							params: queryString 
						})
					.then((res) => setTeamMembers(res.data.users.included))
					.catch((e) => {});
			}
		}
	};

	const openDocument = (documentId: string) => {
		const urlPath = `/documents/${documentId}`;
		const win = window.open(urlPath, "_blank");
		if (win) {
			win.focus();
		}
	};

	const [todoLoading, setTodoLoading] = useState(false);
	const createTodoApi = (urlPath: string) => {
		const apiBody = {
			title: title,
			description: description,
			due_at: moment(date).toISOString(),
			assigned_to_id: assignee.id,
			status: status.name.toLowerCase(),
			todoable_type: "Property",
		};

		axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}${urlPath}/${props.id}/todos`, { todo: apiBody })
			.catch((e) => console.log("Error found while creating todo ticket"))
			.finally(() => {
				creatingNewTodo(false);
				toggleModal && toggleModal(false);
				setTodoLoading(false);
				reloadPage();
			});
	};

	const updateTodoApi = (urlPath: string) => {
		let id;
		if (todoKind === "global") {
			id = updateTodoInfo.attributes.todoable_id;
		} else {
			id = props.id;
		}
		let todoableType;
		if (updateTodoInfo.attributes.todoable_type === "Project") {
			todoableType = "Project";
		} else {
			todoableType = "Property";
		}
		const apiBody = {
			title: title,
			description: description,
			due_at: moment(date).toISOString(),
			assigned_to_id: assignee.id,
			status: status.name.toLowerCase(),
			todoable_type: todoableType,
		};

		axios.put(`${process.env.REACT_APP_API_ENDPOINT_V2}${urlPath}/${id}/todos/${updateTodoId}`, { todo: apiBody })
			.catch((e) => console.log("Error found while updating todo ticket"))
			.finally(() => {
				toggleModal && toggleModal(false);
				setTodoLoading(false);
				reloadPage();
			});
	};

	const validateFields = (): boolean => {
		if (title === "" && description === "" && assignee.id === "" && date === "") {
			setTitleError(true);
			setDescriptionError(true);
			setAssigneeError(true);
			setDueDateError(true);
			return false;
		} else if (title === "") {
			setTitleError(true);
			return false;
		} else if (description === "") {
			setDescriptionError(true);
			return false;
		} else if (assignee.id === "") {
			setAssigneeError(true);
			return false;
		} else if (date === "") {
			setDueDateError(true);
			return false;
		} else if (updateTodo && isDateUpdated && !checkDate(date)) {
			setDueDateError(true);
			return false;
		} else if (!updateTodo && !checkDate(date)) {
			setDueDateError(true);
			return false;
		}
		return true;
	};

	const createTodo = () => {
		const allFieldsValidated = validateFields();
		if (allFieldsValidated) {
			const urlPath = "properties";
			setTodoLoading(true);
			createTodoApi(urlPath);
		}
	};

	const todoUpdate = () => {
		const allFieldsValidated = validateFields();
		if (allFieldsValidated) {
			let urlPath;
			if (updateTodoInfo.attributes.todoable_type === "Project") {
				urlPath = "projects";
			} else {
				urlPath = "properties";
			}
			setTodoLoading(true);
			updateTodoApi(urlPath);
		}
	};

	const statusList = [
		{
			id: "1",
			name: "Open",
		},
		{
			id: "2",
			name: "Closed",
		},
	];

	const getMembersList = (partyList: any) => {
		if (todoKind !== "global") {
			return _.map(partyList, (user) => {
				return {
					id: user.id,
					name: user.name,
				};
			});
		} else {
			const filteredMembersList = _.filter(partyList, {
				type: "user",
			});
			return _.map(filteredMembersList, (user) => {
				return {
					id: user.id,
					name: user.attributes.name,
				};
			});
		}
	};

	const callBackAssignee = (assigneeId: string) => {
		let member;
		if (todoKind !== "global") {
			member = _.find(getMembersList(userList), { id: assigneeId });
		} else {
			member = _.find(getMembersList(teamMembers), { id: assigneeId });
		}
		if (member) {
			setAssignee({
				id: member.id,
				name: member.name,
			});
		}
	};

	const callBackStatus = (statusId: string) => {
		statusId === "1"
			? setTodoStatus({
					id: statusId,
					name: "Open",
			  })
			: setTodoStatus({
					id: statusId,
					name: "Closed",
			  });
	};

	const checkDate = (date: string): boolean => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const dueDate = new Date(date);
		const twoYearTime = moment().add(2, "year");
		return dueDate.getTime() >= today.getTime() && twoYearTime.isAfter(dueDate);
	};

	let pickedDate;
	if (date === "") {
		pickedDate = "Pick a Date";
	} else {
		pickedDate = moment(date).format("YYYY-MM-DD");
	}

	const loggedUser = _.compact([_.get(currentUser, "firstname", ""), _.get(currentUser, "surname", "")]).join(" ");
	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen!}
			className="todo-modal"
			contentLabel="ToDo"
			onAfterOpen={getTeamMembersList}
		>
			<div className="flex justify-center py-4">
				<div className="w-700">
					<div className="w-full">
						<div className="font-bold text-center text-24 text-workflowGray">
							{updateTodo ? "Update ToDo" : "Create ToDo"}
						</div>

						<button
							type="button"
							className="close"
							aria-label="Close"
							onClick={() => {
								toggleModal && toggleModal(false);
								!updateTodo && initialState();
							}}
						>
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<section>
						<label className="text-reportProjectSubtitle font-semibold text-16">Title</label>
						<input
							className="w-full border border-black-20 rounded p-2"
							type="text"
							id="todoTitle"
							onChange={(evt) => {
								setTitle(evt.target.value);
								if (evt.target.value !== "") {
									setTitleError(false);
								}
							}}
							value={title}
						/>

						{titleError && <div className="text-error-darker text-14 font-semibold">Fill in the title</div>}
						<label className="mt-4 text-reportProjectSubtitle font-semibold text-16">Description</label>
						<textarea
							className="w-full p-2 border border-black-20 rounded "
							id="todoDescription"
							onChange={(evt) => {
								setDescription(evt.target.value);
								if (evt.target.value !== "") {
									setDescriptionError(false);
								}
							}}
							value={description}
						/>
						{descriptionError && <div className="text-error-darker text-14 font-semibold">Fill in the description</div>}
					</section>
					<div className="flex px-8 justify-between">
						<div className="flex flex-col">
							<span className="text-reportProjectSubtitle pb-2 font-semibold text-16">Reporter</span>
							<div className="w-315 text-14 transition rounded-l-md text-left py-4 pl-2 bg-black-5 border-1 border-black-20 rounded">
								{updateTodo ? (reporter.name ? reporter.name : loggedUser) : loggedUser}
							</div>
						</div>
						<div className="flex flex-col relative">
							<span className="text-reportProjectSubtitle font-semibold pb-2 text-16">Assignee</span>
							<ButtonDropDown
								buttonOption={assignee}
								dropdownOptions={userList?.length ? userList : getMembersList(teamMembers)}
								callBack={(arg) => {
									callBackAssignee(arg);
									if (arg !== "") {
										setAssigneeError(false);
									}
								}}
								textClassNames="w-265 text-14 transition rounded-l-md text-left py-4 pl-2"
								dropDownArrowClassNames="bg-white border-borderColor border-l"
								dropDownClassNames="w-315"
							/>
							{assigneeError && <div className="text-error-darker text-14 font-semibold">Pick an assignee</div>}
						</div>
					</div>
					<div className="flex mt-5 px-8">
						<div className="flex flex-col">
							<span className="text-reportProjectSubtitle font-semibold text-16 pb-2">Reported</span>
							<div className="border-1 border-black-20 rounded w-315 py-4 bg-black-5">
								<DateInput date={createdDate ? createdDate : new Date()} className="text-left pl-1" />
							</div>
						</div>
						<div className="flex flex-col pl-2">
							<span className="text-reportProjectSubtitle font-semibold text-16 pb-2">Due Date</span>
							<input
								className="border-1 border-black-20 rounded w-315 px-2 h-53 flex justify-start cursor-pointer"
								type="date"
								value={pickedDate}
								onChange={(evt: any) => {
									if (updateTodo) {
										if (date !== evt.target.value) {
											setDate(evt.target.value);
											setUpdatedDate(true);
										}
									} else {
										setDate(evt.target.value);
									}
								}}
							/>
							{dueDateError && <div className="text-error-darker text-14 font-semibold">Pick a valid date</div>}
						</div>
					</div>
					<div className="flex mt-4 px-8">
						<div className="flex flex-col relative">
							<span className="text-reportProjectSubtitle font-semibold text-16 pb-2">Status</span>
							{updateTodo ? (
								<div>
									<ButtonDropDown
										buttonOption={status}
										dropdownOptions={statusList}
										callBack={callBackStatus}
										textClassNames="w-265 text-14 transition rounded-l-md text-left py-4 pl-2"
										dropDownArrowClassNames="bg-white border-borderColor border-l"
										dropDownClassNames="w-315"
									/>
								</div>
							) : (
								<div className="w-315 text-14 transition rounded-l-md text-left py-4 pl-2 bg-black-5 border-1 border-black-20 rounded">
									Open
								</div>
							)}
						</div>
						{todoKind === "global" ? (
							<div className="flex flex-col relative px-2">
								<span className="text-reportProjectSubtitle font-semibold text-16 pb-2">
									{updateTodoInfo.attributes.todoable_type}
								</span>
								<div className="w-315 text-14 transition rounded-l-md text-left py-4 pl-2 bg-black-5 border-1 border-black-20 rounded">
									{updateTodoInfo.attributes.todoable_name}
								</div>
							</div>
						) : (
							""
						)}
					</div>
					<div className="flex flex-col mt-4 px-8">
						<div className="text-reportProjectSubtitle font-semibold text-16 pb-2">Attachment</div>
						{updateTodoInfo?.attributes?.document.id ? (
							<div
								className="text-14 text-left cursor-pointer flex"
								onClick={() => {
									openDocument(updateTodoInfo.attributes.document.id);
								}}
							>
								<span className="pr-2">
									<AttachmentIcon />{" "}
								</span>
								{updateTodoInfo.attributes.document.name}
							</div>
						) : (
							<div className="text-14 text-left">"There is no document attached"</div>
						)}
					</div>
					<footer className="mt-4 flex px-4 justify-start items-center">
						<Button
							disabled={todoLoading}
							kind={updateTodo ? "Update" : "Save"}
							onClick={() => {
								updateTodo ? todoUpdate() : createTodo();
							}}
						/>
						<span
							onClick={() => {
								toggleModal && toggleModal(false);
								!updateTodo && initialState();
							}}
							className="text-blue-darker font-normal pr-2 cursor-pointer"
						>
							{" "}
							Cancel
						</span>
					</footer>
				</div>
			</div>
		</Modal>
	);
};

export default TodoModal;
