import React, { useState, memo } from "react";
import _ from "lodash";
import Modal from "react-modal";
import { ListGroup, ListGroupItem, Button, ModalHeader } from "components/Shared";
import { Icon } from "components/Documents";
import { TextInput } from "components/Inputs";
import { toSentenceSerial } from "underscore.string";

const UploadModal = ({ files, resource, modalOpen, doCloseModal, onUpdate }) => {
	const [comment, setComment] = useState("");
	const users = _.map(_.get(resource, "permissions", []), "user");
	return (
		<Modal className="document-upload-modal" isOpen={modalOpen} onRequestClose={doCloseModal} contentLabel="zip">
			<ModalHeader title="upload" onRequestClose={doCloseModal} />
			<section>
				{!!users.length && (
					<div className="alert alert-info">
						<i className="fas fa-exclamation-circle" /> Shared with {toSentenceSerial(_.map(users, "name"))}
					</div>
				)}
				<TextInput
					users={users}
					defaultState={{ comment }}
					name="body"
					onChange={setComment}
					placeholder="Add a comment about the file"
				/>
				<br />
				<ListGroup
					collection={files}
					pagination={false}
					resource="documents"
					renderRow={document => <ListGroupItem heading={document.name} icon={<Icon document={{}} />} />}
				/>
			</section>
			<footer>
				<Button onClick={() => onUpdate(comment)} />
			</footer>
		</Modal>
	);
};

export default memo(UploadModal);
