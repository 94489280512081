import React, { useContext } from "react";
import { translate } from "lib";
import { UserContext } from "context";
import { ErrorBoundary } from "components/Shared";
import Actor from "components/Activities/Actor";
import Subject from "components/Activities/Subject";
import Context from "components/Activities/Context";

const CreateCommentMention = ({ activity }: { activity: tcpinpoint.ActivityItem }) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const to = activity.attributes.item.to[0];

	return (
		<ErrorBoundary>
			{<Actor actor={activity.attributes.item.actor} />} {translate("mentioned")}{" "}
			{to.email === currentUser.email ? translate("you") : <Actor actor={to} />}
			{activity.attributes.item.to.length > 1 && [" ", translate("and_others")]} {translate("on")}{" "}
			<Subject subject={activity.attributes.item.subject} />{" "}
			{activity.attributes.item.context.link !== activity.attributes.item.subject.link && (
				<Context context={activity.attributes.item.context} />
			)}
		</ErrorBoundary>
	);
};
export default CreateCommentMention;
