import React from "react";
import _ from "lodash";
import { CardElement, injectStripe, Elements, StripeProvider } from "react-stripe-elements";
import { Button } from "components/Shared";
import config from "config.js";

class CardForm extends React.Component {
  onSubmit() {
    const { onSubmit, stripe } = this.props;
    stripe.createToken().then(response => onSubmit(_.get(response, "token.id")));
  }

  render() {
    return (
      <React.Fragment>
        <CardElement />
        <Button onClick={() => this.onSubmit()} kind="send" />
      </React.Fragment>
    );
  }
}

const Checkout = injectStripe(CardForm);

const Payment = ({ onSubmit }) => (
  <StripeProvider apiKey={config.Stripe.key}>
    <Elements>
      <Checkout onSubmit={onSubmit} />
    </Elements>
  </StripeProvider>
);

export default Payment;
