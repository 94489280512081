import React, { useState } from "react";
import _ from "lodash";

const graphScale = (deliveryValues: number[]) => {
	let absDeliveries = deliveryValues.map((d: number) => Math.abs(d));
	let absoluteMaxDelivery = Math.max.apply(null, absDeliveries);

	return Math.ceil(absoluteMaxDelivery / 2) * 2;
};

const clampBarWidth = (width: number) => {
	return Math.max(Math.min(width, 15), 40);
};

const assignBarColor = (delivery: number) => {
	if (delivery === 0) {
		return "#394A58";
	} else if (delivery < -14) {
		return "#f7605a";
	} else if (delivery > 0) {
		return "#97d1ac";
	} else {
		return "#f2994a";
	}
};

const pickTextClass = (delivery: number) => {
	if (delivery === 0) {
		return "early-open";
	} else if (delivery < -14) {
		return "overdue-open";
	} else if (delivery > 0) {
		return "earlier-open";
	} else {
		return "borderline-open";
	}
};

const pickRevenueTextClass = (revenue: number) => {
	if (revenue < 0) {
		return "overdue-open";
	}	else {
		return "earlier-open";
	}
};

const shortenText = (text: string) => {
	if (text.length > 20) {
		return text.slice(0, 20) + "...";
	} else {
		return text;
	}
};

const drawSVGUpArrow = (x: number, y: number, i: any) => {
	return (
		<path
			id={"bar-overflow-arrow-" + i}
			d={`M${x},${y}
        h${-15}
        L${x} ${y - 10}
        L${x + 15} ${y}
        h${-15}
        z
    `}
			fill="grey"
		>
			<title>The lateness of this delivery exceeds the maximum amount for charting, 60 days</title>
		</path>
	);
};

const drawSVGDownArrow = (x: number, y: number, i: any) => {
	return (
		<path
			id={"bar-overflow-arrow-" + i}
			d={`M${x},${y}
        h${-15}
        L${x} ${y + 10}
        L${x + 15} ${y}
        h${-15}
        z
    `}
			fill="grey"
		>
			<title>The earliness of this delivery exceeds the maximum amount for charting, 60 days</title>
		</path>
	);
};

export const OpenAnalyticChart = ({ reports }: { reports: any }) => {
	var SVG_REF: SVGSVGElement;
	const [entityHighlighted, setHighlightedEntity] = useState({
		name: "",
		delivery: "",
		revenue: "",
		className: "early",
	});
	if (JSON.stringify(reports) === JSON.stringify({})) {
		return <span></span>;
	}
	let propertySpecified = _.get(reports, "project_details") !== null && _.get(reports, "project_details") !== undefined;
	let tableContents = propertySpecified ? _.get(reports, "project_details") : _.get(reports, "property_details");
	let averages = _.get(reports, "averages");
	let averageDelivery = _.get(averages, "actual_delivery_in_days_average");
	const MAX_GRAPH_SCALE = 60;
	const barRects: any[] = [];
	const yTicks: any[] = [];
	const deliveryVals: number[] = tableContents.map((c: any) =>
		propertySpecified ? _.get(c, "actual_delivery") : _.get(c, "average_actual_delivery")
	);
	const revenueVals: number[] = tableContents.map((c: any) =>
		propertySpecified ? _.get(c, "actual_revenue") : _.get(c, "average_actual_revenue")
	);
	const names = tableContents.map((c: any) => _.get(c, "name"));
	const scale = Math.min(Math.max(graphScale(deliveryVals), 2), MAX_GRAPH_SCALE);
	const graphBounds = {
		min: -scale,
		max: scale,
		dataHeight: 2 * scale,
	};
	const doubleGraphWidth = tableContents.length > 15;
	const width = doubleGraphWidth ? 900 * Math.ceil(tableContents.length / 15) : 900;
	const height = 700;
	const backgroundBox = {
		x: 90,
		y: 50,
		width: doubleGraphWidth ? width - 180 : width - 90,
		height: height - 170,
		rx: 5,
		ry: 5,
	};
	const barSpaces = tableContents.length + 2;
	const barWidth = backgroundBox.width / barSpaces;

	var i = 0;
	for (i = 0; i < deliveryVals.length; i++) {
		barRects.push({
			x: (i + 0.5) * barWidth - clampBarWidth(barWidth) / 2 + backgroundBox.x,
			width: clampBarWidth(barWidth),
			height: backgroundBox.height * Math.min(1.0, (graphBounds.max - deliveryVals[i]) / graphBounds.dataHeight),
			color: assignBarColor(deliveryVals[i]),
			drawUpArrow: deliveryVals[i] < -graphBounds.max,
			drawDownArrow: deliveryVals[i] > graphBounds.max,
		});
	}

	const averageBarRect = {
		x: (barRects.length + 1.5) * barWidth - clampBarWidth(barWidth) / 2 + backgroundBox.x,
		width: clampBarWidth(barWidth),
		y: ((averageDelivery - graphBounds.min) / graphBounds.dataHeight) * backgroundBox.height + backgroundBox.y,
		height: backgroundBox.height * Math.min(1.0, (graphBounds.max - averageDelivery) / graphBounds.dataHeight),
		color: assignBarColor(averageDelivery),
		drawUpArrow: averageDelivery < -graphBounds.max,
		drawDownArrow: averageDelivery > graphBounds.max,
	};

	let yTickInterval;
	if (scale > 24) {
		yTickInterval = 5;
	} else {
		yTickInterval = 2;
	}
	for (i = yTickInterval; i <= scale; i = i + yTickInterval) {
		yTicks.push({
			text: i.toString(),
			y: backgroundBox.y + backgroundBox.height / 2 - ((backgroundBox.height / 2) * i) / scale,
		});
		yTicks.push({
			text: i.toString(),
			y: backgroundBox.y + backgroundBox.height / 2 + ((backgroundBox.height / 2) * i) / scale,
		});
	}

	const showTooltip = (e: any) => {
		let id = e.target.id;
		let idTokens = id.split("-");
		let lastToken = idTokens[idTokens.length - 1];

		if (lastToken === "average") {
			let className = pickTextClass(_.get(averages, "actual_delivery_in_days_average"));
			setHighlightedEntity({
				name: "Average",
				delivery: formatDays(_.get(averages, "actual_delivery_in_days_average")),
				revenue: formatRevenue(_.get(averages, "actual_revenue_average")),
				className: className,
			});
		} else {
			let recordIdx = parseInt(lastToken);
			let className = pickTextClass(deliveryVals[recordIdx]);
			setHighlightedEntity({
				name: _.get(tableContents[recordIdx], "name"),
				delivery: formatDays(deliveryVals[recordIdx]),
				revenue: formatRevenue(revenueVals[recordIdx]),
				className: className,
			});
		}
		let tooltip = document.getElementById("tooltip");
		let scrollingDiv = document.getElementById("lto-chart-container");
		if (tooltip && SVG_REF) {
			var CTM = SVG_REF.getScreenCTM();
			if (!CTM) {
				return;
			}
			var mouseX = (e.clientX - CTM.e) / CTM.a;
			var mouseY = (e.clientY - CTM.f) / CTM.d;
			if (scrollingDiv) {
				mouseX = mouseX - scrollingDiv.scrollLeft;
			}
			tooltip.style.position = "absolute";
			tooltip.style.display = "block";
			tooltip.style.left = mouseX + "px";
			tooltip.style.top = mouseY + "px";
		}
	};

	const hideTooltip = (e: any) => {
		let tooltip = document.getElementById("tooltip");
		if (tooltip) {
			tooltip.style.position = "absolute";
			tooltip.style.display = "none";
		}
	};
	return (
		<div id="lto-chart-container">
			<svg
				id="LeaseToOpenChart"
				width={width.toString()}
				height={height.toString()}
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				ref={(svg) => {
					if (svg != null) {
						SVG_REF = svg;
					}
				}}
			>
				<defs>
					<linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
						<stop offset="0%" stopColor="#f8d1d0" />
						<stop offset="50%" stopColor="white" stopOpacity="0" />
						<stop offset="100%" stopColor="#c2e7d2" />
					</linearGradient>
				</defs>
				<rect
					fill="url(#Gradient2)"
					x={backgroundBox.x}
					y={backgroundBox.y}
					width={backgroundBox.width}
					height={backgroundBox.height}
					rx={backgroundBox.rx}
					ry={backgroundBox.ry}
				/>
				<g fill="none" stroke="gray" strokeWidth="1">
					<path
						strokeDasharray="5,5"
						d={`M ${backgroundBox.x} ${backgroundBox.y + backgroundBox.height / 2} l${backgroundBox.width} 0`}
					/>
				</g>
				<line
					x1={backgroundBox.x}
					x2={backgroundBox.x + backgroundBox.width}
					y1={backgroundBox.y + backgroundBox.height / 2}
					y2={backgroundBox.y + backgroundBox.height / 2}
					stroke="dotted"
				/>
				<text
					dominantBaseline="central"
					textAnchor="middle"
					transform={`translate(${backgroundBox.x - 40},${backgroundBox.y + backgroundBox.height * 0.25}) rotate(-90)`}
				>
					Late
				</text>
				<text
					dominantBaseline="central"
					textAnchor="middle"
					transform={`translate(${backgroundBox.x - 40},${backgroundBox.y + backgroundBox.height * 0.75}) rotate(-90)`}
				>
					Early
				</text>
				<text
					dominantBaseline="central"
					textAnchor="end"
					transform={`translate(${backgroundBox.x - 5},${backgroundBox.y + backgroundBox.height / 2})`}
				>
					On Time
				</text>
				{doubleGraphWidth && (
					<text
						dominantBaseline="central"
						textAnchor="middle"
						transform={`translate(${backgroundBox.x + backgroundBox.width + 40},${
							backgroundBox.y + backgroundBox.height * 0.25
						}) rotate(90)`}
					>
						Late
					</text>
				)}
				{doubleGraphWidth && (
					<text
						dominantBaseline="central"
						textAnchor="middle"
						transform={`translate(${backgroundBox.x + backgroundBox.width + 40},${
							backgroundBox.y + backgroundBox.height * 0.75
						}) rotate(90)`}
					>
						Early
					</text>
				)}
				{doubleGraphWidth && (
					<text
						dominantBaseline="central"
						textAnchor="start"
						transform={`translate(${backgroundBox.x + backgroundBox.width + 5},${
							backgroundBox.y + backgroundBox.height / 2
						})`}
					>
						On Time
					</text>
				)}
				{doubleGraphWidth && (
					<text
						textAnchor="end"
						transform={`translate(${backgroundBox.x + backgroundBox.width + 10},${backgroundBox.y - 15})`}
					>
						Days
					</text>
				)}
				<text textAnchor="end" transform={`translate(${backgroundBox.x - 10},${backgroundBox.y - 15})`}>
					Days
				</text>
				{yTicks.map((el, i) => (
					<text
						key={"lto-chart-left-axis-tick-" + i}
						dominantBaseline="central"
						textAnchor="end"
						fontSize="smaller"
						transform={`translate(${backgroundBox.x - 5},${el.y})`}
					>
						{el.text}
					</text>
				))}
				{doubleGraphWidth &&
					yTicks.map((el) => (
						<text
							dominantBaseline="central"
							textAnchor="start"
							fontSize="smaller"
							transform={`translate(${backgroundBox.x + backgroundBox.width + 5},${el.y})`}
						>
							{el.text}
						</text>
					))}
				{barRects.map((el, i) => {
					return (
						<path
							key={"lto-chart-bar-" + i}
							onMouseMove={showTooltip}
							onMouseOut={hideTooltip}
							className="report-graph-rect"
							id={`report-graph-rect-${i}`}
							d={`M${el.x},${backgroundBox.y + backgroundBox.height}
                                v-${el.height - 5}
                                q0,-2 5,-5
                                h${el.width - 10}
                                q2,0 5,5
                                v${el.height - 5}
                                z
                            `}
							fill={el.color}
						/>
					);
				})}
				{barRects.map((el, i) => {
					if (el.drawUpArrow) {
						return drawSVGUpArrow(el.x + el.width / 2, backgroundBox.y - 5, i);
					} else if (el.drawDownArrow) {
						return drawSVGDownArrow(el.x + el.width / 2, backgroundBox.y + backgroundBox.height + 5, i);
					} else {
						return <></>;
					}
				})}
				{barRects.map((el, i) => {
					if (el.drawDownArrow) {
						return (
							<rect
								x={el.x}
								y={el.y}
								width={el.width}
								height={backgroundBox.height}
								fill="transparent"
								stroke="transparent"
								id={`report-graph-invisi-rect-${i}`}
								onMouseMove={showTooltip}
								onMouseOut={hideTooltip}
							/>
						);
					} else {
						return <></>;
					}
				})}
				{names.map((s: any, i: number) => (
					<text
						key={"lto-chart-x-label-" + i}
						dominantBaseline="central"
						fontSize="x-small"
						textAnchor="end"
						transform={`translate(${barRects[i].x + clampBarWidth(barWidth) / 2},${
							backgroundBox.y + backgroundBox.height + 20
						}) rotate(-45)`}
					>
						<title>{s}</title>
						{shortenText(s)}
					</text>
				))}
				<path
					className="report-graph-rect"
					id="report-graph-rect-average"
					onMouseMove={showTooltip}
					onMouseOut={hideTooltip}
					d={`M${averageBarRect.x},${backgroundBox.y + backgroundBox.height}
                    v-${averageBarRect.height - 5}
                    q0,-2 5,-5
                    h${averageBarRect.width - 10}
                    q2,0 5,5
                    v${averageBarRect.height - 5}
                    z
                `}
					fill={averageBarRect.color}
				/>
				<text
					fontSize="x-small"
					textAnchor="end"
					transform={`translate(${averageBarRect.x + clampBarWidth(barWidth) / 2},${
						backgroundBox.y + backgroundBox.height + 20
					}) rotate(-45)`}
				>
					Average
				</text>
				{averageBarRect.drawUpArrow ? (
					drawSVGUpArrow(averageBarRect.x + averageBarRect.width / 2, backgroundBox.y - 5, "average")
				) : (
					<></>
				)}
				{averageBarRect.drawDownArrow ? (
					drawSVGDownArrow(
						averageBarRect.x + averageBarRect.width / 2,
						backgroundBox.y + backgroundBox.height + 5,
						"average"
					)
				) : (
					<></>
				)}
				{averageBarRect.drawDownArrow ? (
					<rect
						x={averageBarRect.x}
						y={averageBarRect.y}
						width={averageBarRect.width}
						height={backgroundBox.height}
						fill="transparent"
						stroke="transparent"
						id={"report-graph-invisi-rect-average"}
						onMouseMove={showTooltip}
						onMouseOut={hideTooltip}
					/>
				) : (
					<></>
				)}
			</svg>
			<div id="tooltip" style={{ zIndex: 999 }}>
				<ul>
					<li className="tooltip-heading">{entityHighlighted.name}</li>
					<li className={entityHighlighted.className}>{entityHighlighted.delivery}</li>
					<li
						className={
							pickRevenueTextClass(parseFloat(entityHighlighted.revenue.replace(/[^0-9.-]+/g, "")))
						}
					>
						{entityHighlighted.revenue}
					</li>
				</ul>
			</div>
		</div>
	);
};

const formatDays = (days: number) => {
	let dayPlural = Math.round(Math.abs(days)) === 1 ? "Day" : "Days";
	if (days > 0) {
		return `${Math.round(Math.abs(days))} ${dayPlural} Early`;
	} else if (days < 0) {
		return `${Math.round(Math.abs(days))} ${dayPlural} Late`;
	} else {
		return "On Time";
	}
};

const formatRevenue = (revenue: number) => {
	if (revenue > 0) {
		return `+$${Math.abs(revenue / 100).toFixed(2)}`;
	} else if (revenue < 0) {
		return `-$${Math.abs(revenue / 100).toFixed(2)}`;
	} else {
		return "$0";
	}
};