import React, { memo } from "react";
import { Breadcrumbs, Workspace} from "../Shared";
import TodoTab from "../Shared/TodoTab";

const List = () => {

	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: "ToDo" }]} />
			<Workspace id="ToDo">
				<TodoTab name="ToDos" key="todo-tab" globalTodo={true} todoKind="global" />
			</Workspace>
		</React.Fragment>
	);
};

const MemoizedList = memo(List);
export default () => <MemoizedList />;
