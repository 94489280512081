import React, { memo } from "react";
import { View } from "components/Shared";
import { Lookup, Card } from "components/Templates";

const Select = ({ onUpdate, template }: { onUpdate?: any; template?: any }) => {
	if (template) {
		return <Card template={template} onUpdate={() => onUpdate(false)} />;
	}
	return (
		<View resource="template" className={"form-group"}>
			<Lookup onChange={onUpdate} />
		</View>
	);
};

export default memo(Select);
