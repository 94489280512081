import { Button, Date, LoadingState } from "components/Shared";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const AcceptCondition = ({ condition, onUpdate, onUpdateMoment }: any) => {
	const accepted_by = useMemo<any>(() => _.get(condition, "accepted_by", {}), [condition]);
	const conditionId = useMemo(() => _.get(condition, "id"), [condition]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "condition", renderTrigger: renderTrigger }));

	const accept = useCallback(() => {
		if (!_.isNil(conditionId)){
			datasource.put("v2", `conditions/${conditionId}/accept`, {}, { updateIsLoding: true });
		}
	}, [datasource, conditionId]);

	const undo = useCallback(() => {
		if (!_.isNil(conditionId)){
			datasource.put("v2", `conditions/${conditionId}/reopen`, {}, { updateIsLoding: true });
		}
	}, [datasource, conditionId]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && !_.isNil(onUpdate)){
				onUpdate(response.normalizedMainModelResponse);
				onUpdateMoment();
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, onUpdate, onUpdateMoment]);

	if (datasource.isLoading) return <LoadingState />;

	if (_.isEmpty(condition)) return null;

	return (
		<div className="condition-actions">
			{_.isEmpty(accepted_by) ? (
				<div className="btn-group" role="group">
					<Button kind="accept" className="condition-done" small onClick={accept} />
				</div>
			) : (
				<React.Fragment>
					<p>{translate("accepted")}</p>
					<p className="condition-meta muted">
						{accepted_by.firstname} {translate("accepted")} <Date date={condition.accepted_at} />
					</p>
					<div className="condition-toolbar">
						<Button onClick={undo} kind="undo" />
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default AcceptCondition;
