import { whatIs } from "lib";
import _ from "lodash";
import { postEvent } from "./history";
import { memoAC } from './memoize';
import axios from "v2/utils/axios";

/** Get Comments for a scoped Resource
 * @param {Resource} resource Resource to scope Comments against
 */
export const getCommentsFor = memoAC((resource: tcpinpoint.Resource) => (dispatch: any) => {
	dispatch({ type: "GET_COMMENTS_REQUEST", resource });
	const klass = whatIs(resource.link);
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}${klass}/${resource.id}/comments`)
	.then((res) => { dispatch({ type: "GET_COMMENTS_SUCCESS", response: { status: 200 }, resource: res.data.comments }); })
	.catch((e) => { dispatch({ type: "GET_COMMENTS_FAILED", resource, response: e }); });
});

/** Delete a Comment
 * @param {Resource} resource
 * @param {Comment} comment to delete
 * @emits `DELETE_COMMENTS_REQUEST`
 * @emits `DELETE_COMMENT_SUCCESS`
 * @emits `DELETE_COMMENT_FAILED`
 */
export const deleteComment = memoAC((comment: tcpinpoint.Comment, resource: tcpinpoint.Resource) => (dispatch: any) => {
	dispatch({ type: "DELETE_COMMENTS_REQUEST", resource, comment });
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}comments/${comment.id}`)
	.then((res) => { 
		dispatch(getCommentsFor(resource));
		dispatch(postEvent("delete", "comments", comment));
		dispatch({ type: "DELETE_COMMENT_SUCCESS", response: { status: 204 } });
	 })
	.catch((e) => { dispatch({ type: "DELETE_COMMENT_FAILED", resource, comment, response: e }); });
});

/** Post a new Comment against a Resource
 *
 * @param {Resource} resource
 * @param {Comment} comment Body of new Comment
 * @emits `POST_COMMENT_REQUEST`
 * @emits `POST_COMMENT_SUCCESS` on success
 * @emits `POST_COMMENT_FAILED` on failed
 */
export const postComment = memoAC((
	resource: tcpinpoint.Resource,
	comment: { body: string; metadata?: {}; reply_to_id?: string }
) => (dispatch: any) => {
	dispatch({ type: "POST_COMMENT_REQUEST", resource, comment });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}comments`, 
	{
		comment: _.assign({}, comment, {
			commentable_type: resource.link.split("/")[3],
			commentable_id: resource.id
		})
	})
	.then((res) => {
		dispatch(postEvent("post", "comments", resource));
		dispatch(getCommentsFor(resource));
		dispatch({ type: "POST_COMMENT_SUCCESS", response: { status: 200 }, resource: comment });
	 })
	.catch((e) => { dispatch({ type: "POST_COMMENT_FAILED", resource: comment, response: e }) });
});

