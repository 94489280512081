import React, { memo } from "react";
import { Link } from "react-router-dom";

const Dependency = (props: any) => {
	const { name, id, closed_at } = props.dependency;
	return (
		<Link
			to={`/tasks/${id}`}
			id={id}
			key={id}
			className={`list-group-item condition-input condition-input-dependency ${closed_at ? "completed" : "incomplete"}`}
		>
			<div className="condition-status">
				<i className="fas fa-check-circle" />
			</div>
			<div className="condition-body" id={id}>
				<h6>Depends on…</h6>
				<h3>{name}</h3>
			</div>
			<div className="disclosure">
				<i className="fas fa-angle-right" />
			</div>
		</Link>
	);
};

export default memo(Dependency);
