import React, { useContext, memo } from "react";
import { NewProjectContext } from "context";
import { View } from "components/Shared";

const Protected = () =>
	!!useContext(NewProjectContext) ? null : (
		<View resource="protected" hideTitle className="alert alert-warning">
			This is a protected resource. Only authorised users can view this resource.
		</View>
	);

export default memo(Protected);
