export default (state: tcpinpoint.Party[] = [], action: any) => {
	switch (action.type) {
		case "GET_PARTY_SUCCESS":
			return action.resource as tcpinpoint.Party;
		case "GET_PARTIES_SUCCESS":
			return action.resource as tcpinpoint.Party[];
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
