import { LoadingState, View, ListGroupControl } from "components/Shared";
import { generateCSVDownload } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// @ts-ignore
import sanitizeHtml from "sanitize-html";

const commentsTableMeta = [
	{ label: "Project Name", key: "project_name" },
	{ label: "Tenancy Name", key: "tenancy_name" },
	{ label: "Comment", key: "comment_body" },
	{ label: "Commenter", key: "username" },
	{ label: "Comment Date", key: "comment_at" }
];

const LatestCommentReport = ({ portfolioId }: any) => {
	const [data, setData] = useState([] as any[]);
	const commentsTableRef = useRef<HTMLDivElement>(null);
	const [filteredBy, setFilteredBy] = useState("open");
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({ mainModelName: "latest_comment_report", renderTrigger: renderTrigger })
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			let data = _.each(
				response.normalizedMainModelResponse,
				oneRecord => (oneRecord.comment_body = sanitizeHtml(oneRecord.comment_body, { allowedTags: [] }))
			);
			data = _.sortBy(data, oneRecord => oneRecord.comment_at);
			setData(data);
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		if (!_.isEmpty(portfolioId))
			datasource.get("v2", `portfolios/${portfolioId}/latest-comment-report`, {
				queryStringParameters: { status: filteredBy }
			});
	}, [datasource, portfolioId, filteredBy]);

	if (datasource.isLoading) return <LoadingState />;
	if (!!data && !!data.length) {
		return (
			<View resource="lease-table">
				<React.Fragment>
					<div className="report-header">
						<h3>Latest Comments Report</h3>
						<div>{generateCSVDownload(data, commentsTableMeta, "comments-report.csv")}</div>
						{/* <Button onClick={() => exportPDFbyId(commentsTableRef!.current!.innerHTML)}>Download PDF</Button> */}
					</div>
					<ListGroupControl
						filterOptions={["All", "open"]}
						onFilterChange={newFilterBy => {
							datasource.setCurrentPage(1);
							setFilteredBy(newFilterBy);
						}}
						selectedFilter={filteredBy}
					/>
					<div ref={commentsTableRef} className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th scope="col">Project Name</th>
									<th scope="col">Tenancy Name</th>
									<th scope="col">Comment</th>
									<th scope="col">Commenter</th>
									<th scope="col">Comment Date</th>
								</tr>
							</thead>
							<tbody>
								{_.map(data, comment => (
									<tr key={comment.project_id}>
										<td>
											<Link to={`/projects/${_.get(comment, "project_id")}`}> {_.get(comment, "project_name")} </Link>
										</td>
										<td>{_.get(comment, "tenancy_name")}</td>
										<td>{_.get(comment, "comment_body")}</td>
										<td>{_.get(comment, "username")}</td>
										<td>{_.get(comment, "comment_at")}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</React.Fragment>
			</View>
		);
	}
	return null;
};

export default memo(LatestCommentReport);
