import React, { memo } from "react";
import { Button, Money, Date, Unauthorised, DefinitionList, DefinitionItem, View } from "../Shared";
import { translate } from "lib";
import _ from "lodash";
import moment from "moment";

//@ts-ignore
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {
	name?: string;
	editable?: boolean;
	lease: tcpinpoint.Lease | false;
	tenant?: tcpinpoint.Tenant | false;
	onClear?: () => void;
	icon?: string;
}

const LeaseCard = ({ lease, history, onClear }: Props) => {
	if (!!lease) {
		return (
			<View restricted>
				{!!onClear && (
					<button type="button" className="close" aria-label="Close" onClick={onClear}>
						<span aria-hidden="true">×</span>
					</button>
				)}
				<DefinitionList>
					<DefinitionItem title="kind" value={translate(_.get(lease, "kind"))} />
					<DefinitionItem title="duration" value={_.get(lease, "duration")} suffix="months" />
					<DefinitionItem title="permitted_use" value={_.get(lease, "permitted_use")} />
					<DefinitionItem title="incentives" value={_.get(lease, "incentives")} />
					<DefinitionItem title="handover_at" value={<Date date={_.get(lease, "handover_at")} showTime={false} />} />
					{_.get(lease, "fitout_ends_at") && _.get(lease, "handover_at") && (
						<DefinitionItem
							title="fitout_period"
							value={moment(_.get(lease, "fitout_ends_at")).diff(moment(_.get(lease, "handover_at")), "days")}
							suffix="days"
						/>
					)}
					<DefinitionItem title={"opening_at"} value={<Date date={_.get(lease, "opening_at")} showTime={false} />} />
					<DefinitionItem
						title="fitout_ends_at"
						value={<Date date={_.get(lease, "fitout_ends_at")} showTime={false} />}
					/>
					<DefinitionItem
						title="rent_starts_at"
						value={<Date date={_.get(lease, "rent_starts_at")} showTime={false} />}
					/>
					<DefinitionItem title={"actual_rent_starts_at"} value={<Date date={_.get(lease, "actual_rent_starts_at")} showTime={false} />} />
					<DefinitionItem title="ends_at" value={<Date date={_.get(lease, "ends_at")} showTime={false} />} />
					<DefinitionItem
						title="annual_rent_cents"
						value={<Money amount={_.get(lease, "base_rent_cents", 0) * 12} />}
						footnote={translate("(ex tax)")}
					/>
					<DefinitionItem
						title="annual_rent_cents_per_sq_m"
						footnote={translate("(ex tax)")}
						value={<Money amount={_.get(lease, "rent_per_sqm_cents", 0) * 12} />}
					/>
					<DefinitionItem
						title="daily_rent_cents"
						footnote={translate("(ex tax)")}
						value={<Money amount={_.get(lease, "base_rent_cents", 0) * 12 / 365} />}
					/>					
					{/* {_.map(
						[
							"base_rent_cents",
							"rent_per_sqm_cents",
							"fitout_contribution_cents",
							"operational_outgoings_cents",
							"statutory_outgoings_cents"
						],
						value =>
							!!_.get(lease, "value") &&
							!isUndefined(_.get(lease, "value")) && (
								<DefinitionItem title={value} footnote={!!_.get(lease, "value") && translate("(excluding tax)")}>
									{!!_.get(lease, "value") ? (
										<Money amount={_.get(lease, "value")} />
									) : (
										<span className="muted">{translate("unknown")}</span>
									)}
								</DefinitionItem>
							)
					)} */}

					<DefinitionItem title="base_rent_frequency" value={translate(_.get(lease, "base_rent_frequency"))} />
					<DefinitionItem title="annual_review" value={_.get(lease, "annual_review")} suffix="%" />
				</DefinitionList>
				{!!lease.id && <Button onClick={() => history.push(`/leases/${lease.id}/edit`)}>Edit</Button>}
			</View>
		);
	}
	return <Unauthorised />;
};

export default memo(withRouter(LeaseCard));
