import React from "react";
import { View, Button } from "components/Shared";
import { Quotes } from "components/Ledgers/Create";
import _ from "lodash";

export default class Ledger extends React.Component {
  render() {
    const { ledger, onDelete, onUpdate } = this.props;
    return (
      <View
        data={ledger}
        resource={ledger}
        title={_.get(ledger, "name")}
        actions={[
          <Button kind="trash" key="trash" onClick={() => onDelete({ id: _.get(ledger, "id") })}>
            trash
          </Button>
        ]}
      >
        <Quotes ledger={ledger} onUpdate={quotes => onUpdate(_.assign({}, ledger, { quotes }))} />
      </View>
    );
  }
}
