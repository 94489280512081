import React, { memo } from "react";
import { RelativeDate, Date, DefinitionList, DefinitionItem, View } from "components/Shared";
import { Inline as User } from "components/Users";
import { translate } from "lib";

const Details = ({ project, company }: { project: tcpinpoint.Project; company: tcpinpoint.Company }) => (
	<View id="project-timeline-card" resource="details">
		<DefinitionList>
			<DefinitionItem title="status" value={translate(project.status)} />
			<DefinitionItem title="reference" value={project.reference} />
			<DefinitionItem title="kind" value={translate(project.kind)} />
			<DefinitionItem title="started_by" value={<User user={project.started_by} isAuthority={true} />} />
			<DefinitionItem title="delivered_by" value={company.name} />
			<DefinitionItem title="created_at" value={<Date date={project.created_at} showTime={false} />} />
			<DefinitionItem
				title="handover_at"
				value={<Date date={project.handover_at} showTime={false} />}
				footnote={<RelativeDate date={project.projected_handover_at} />}
				showIcon={true}
			/>
			<DefinitionItem
				title={project.kind === "retail" ? "projected_fitout_completion_at" : "projected_build_completion_at"}
				footnote={<RelativeDate date={project.projected_fitout_completion_at} />}
				value={<Date date={project.fitout_completion_at} showTime={false} />}
			/>
			{!!project.closed_at && <DefinitionItem title="project_archived" value={<Date date={project.closed_at} />} footnote={<RelativeDate date={project.closed_at} />} />}
			{!!project.closed_by && <DefinitionItem title="archived_by" value={<User user={project.closed_by} />} />}
		</DefinitionList>
	</View>
);
export default memo(Details);
