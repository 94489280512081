import React from "react";
import { View } from "components/Shared";
import { Form, Card } from "components/Tenancies";

export default ({ onUpdate, tenancy, name, property, onSelect }) => (
	<View resource="tenancy" className="form-group">
		{!!tenancy ? (
			<Card property={tenancy.property} tenancy={tenancy} name={name} onClear={() => onUpdate(false)} />
		) : (
			<Form onUpdate={onUpdate} />
		)}
	</View>
);
