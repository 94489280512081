import React, { useContext, memo } from "react";
import { translate } from "lib";
import { UserContext } from "context";
import classNames from "classnames";

interface Props {
	user?: any;
	className?: string | string[];
	onlyFirstName?: boolean;
	isAuthority?: boolean;
}

const User = (props: Props) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const { user, onlyFirstName, className, isAuthority } = props;
	if (user?.id === "tcbot") {
		return (
			<span className={classNames("user", "user-bot", "inline", className)}>
				<span className="user-name">tcbot</span>
			</span>
		);
	} else if (user?.id === currentUser.id) {
		return (
			<span className={classNames("user", "user-self", "inline", className, { authority: !!isAuthority })}>
				<span className="user-name">
					{translate("me")}
					{!!isAuthority && <i className="fas fa-badge-check" title="An Authority on this resource" />}
				</span>
			</span>
		);
	} else if (user?.id) {
		let displayName = !!onlyFirstName ? user!.firstname : user!.name;
		return (
			<span className={classNames("user", "inline", className, { authority: !!isAuthority })}>
				<span className="user-name">
					{displayName}
					{!!isAuthority && <i className="fas fa-badge-check" title="You're an Authority on this resource" />}
				</span>
			</span>
		);
	}
	return null;
};

export default memo(User);
