import React, { useState, memo } from "react";
import { View, ListGroup } from "components/Shared";
import _ from "lodash";
import { InviteModal } from "components/Tasks";
import { Row } from "components/Users";
import { translate } from "lib";

interface Props {
	currentUserIsProjectAuthority: boolean;
	users: tcpinpoint.User[];
	spectators: tcpinpoint.User[];
	task: tcpinpoint.Task;
	name: string;
}

const Parties = ({ currentUserIsProjectAuthority, users, spectators, task }: Props) => {
	const [inviteModal, setInviteModal] = useState(false);
	const action = currentUserIsProjectAuthority ? () => setInviteModal(true) : false;
	
	return (
		<View hideTitle={true} resource="parties">
			<h4>{translate("responsible_users")}</h4>
			<ListGroup
				resource="responsible_users"
				collection={users}
				renderRow={user => <Row key={user.id} {...{ user }} subheading={_.get(task, "role.name")} />}
			/>
			<hr />
			<h4>{translate("spectators")}</h4>
			<ListGroup
				resource="spectators"
				collection={spectators}
				renderRow={user => <Row key={user.id} {...{ user }} subheading={_.get(user, "role.name")} />}
				onAdd={action}
			/>
			<InviteModal task={task} isOpen={inviteModal} onRequestClose={() => setInviteModal(false)} />
		</View>
	);
};

export default memo(Parties);
