import { assign } from "lodash";
import { memoAC } from "./memoize";
import Datasource from "lib/datasource";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

/**
 * Create a Template
 * @param {template} template Template
 */
export const postTemplate = memoAC((template: tcpinpoint.Template) => (dispatch: any) => {
	dispatch({ type: "POST_TEMPLATE_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}templates`, { template }, { headers })
		.then((res) => {
			dispatch({ type: "POST_TEMPLATE_SUCCESS" });
			dispatch(getTemplates());
		})
		.catch((e) => { dispatch({ type: "POST_TEMPLATE_FAILED", response: e }); });
});

/**
 * Delete a Template
 * @param {uuid} id Template ID
 */
export const deleteTemplate = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "DELETE_TEMPLATE_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}templates/${id}`, { headers })
		.then((res) => {
			dispatch(getTemplates());
			dispatch({ type: "DELETE_TEMPLATE_SUCCESS", resource: { id } });
		})
		.catch((e) => { dispatch({ type: "DELETE_TEMPLATE_FAILED", response: e }); });
});

/**
 * Duplicate a Template
 * @param {template} template Template
 */
export const duplicateTemplate = memoAC((template: tcpinpoint.Template) => (dispatch: any) => {
	dispatch({ type: "DUPLICATE_TEMPLATE_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}templates`, assign({}, template, { name: `Copy of ${template.name}` }), { headers })
		.then((res) => {
			dispatch({ type: "DUPLICATE_TEMPLATE_SUCCESS" });
			dispatch(getTemplates());
		})
		.catch((e) => { dispatch({ type: "DUPLICATE_TEMPLATE_FAILED", response: e }); });
});

/**
 * Update a Template
 * @param {template} template Template
 */
export const putTemplate = memoAC((template: tcpinpoint.Template) => (dispatch: any) => {
	dispatch({ type: "PUT_TEMPLATE_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}templates/${template.id}`, { template }, { headers })
		.then((res) => {
			dispatch({ type: "PUT_TEMPLATE_SUCCESS" });
			dispatch(getTemplate(res.data.template.id));
		})
		.catch((e) => { dispatch({ type: "PUT_TEMPLATE_FAILED", response: e }); });
});
/**
 * Get a Template
 * @param {uuid} id Template ID
 */
export const getTemplate = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_TEMPLATE_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}templates/${id}`, { headers })
		.then((res) => dispatch({ type: "GET_TEMPLATE_SUCCESS", resource: res.data.template }))
		.catch((e) => { dispatch({ type: "GET_TEMPLATE_FAILED", response: e }); });
});

/**
 * Get Templates
 */
export const getTemplates = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_TEMPLATES_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}templates`, { headers })
		.then((res) => {
			let templates = Datasource.getNormalizedData(res.data, "template") as tcpinpoint.Template;
			dispatch({ type: "GET_TEMPLATES_SUCCESS", resource: templates })
		})
		.catch((e) => { dispatch({ type: "GET_TEMPLATES_FAILED", response: e }); });
});
