import React from "react";
import RCDatePicker, { ReactDatePickerProps } from "react-datepicker";
import classNames from "classnames";

interface DatePickerProps extends ReactDatePickerProps {
	className?: string;
	innerCls?: string;
}

const DatePicker = (props: DatePickerProps) => {
	const { className: wrapperCls, innerCls, children, disabled } = props;
	return (
		<label
			className={classNames("whitespace-no-wrap", "transition-colors duration-300", wrapperCls, {
				"hover:cursor-pointer": !disabled,
				"hover:cursor-not-allowed": disabled
			})}
		>
			<RCDatePicker
				{...props}
				className={classNames("w-full text-center h-9 bg-transparent z-50", innerCls, {
					"hover:cursor-not-allowed": disabled,
					"hover:cursor-pointer": !disabled
				})}
				children={null}
			/>
			{!!children && children}
		</label>
	);
};

export default DatePicker;
