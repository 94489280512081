import React, { memo, useEffect, useState, useCallback } from "react";
import { Input } from "components/Inputs";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";

interface Props {
	propertyId: string;
	defaultState?: any;
	onChange?: (buildingId: string) => void;
}

const constraints = {
	name: {
		presence: {
			message: "^ is required"
		}
	}
};

const AsyncSelect = ({ propertyId, onChange, defaultState: defaultProps }: Props) => {
	const [options, setOptions] = useState([] as any[]);
	const [defaultState, setDefaultState] = useState(defaultProps);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "building",
			perPage: 1000,
			currentPage: 1,
			sortBy: "name"
		})
	);

	const onSelectOption = useCallback(
		(buildingId: string) => {
			if (!_.isNil(onChange)) onChange(buildingId);
			setDefaultState({ ...defaultState, building_id: buildingId });
		},
		[onChange, defaultState]
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			const options = _.map(response.normalizedMainModelResponse, (building: any) => {
				return {
					item: building,
					value: building.id,
					label: _.compact([building.name, building.precinct]).join(" ")
				};
			});
			onSelectOption(_.get(options[0], "value"));
			setOptions(options);
		});
		return () => sub.unsubscribe();
	}, [datasource, onSelectOption]);

	// API call
	useEffect(() => {
		if (_.isEmpty(propertyId)) return;
		datasource.get("v2", "buildings", {
			queryStringParameters: {
				property_id: propertyId,
				sort: datasource.sortBy,
				per_page: datasource.perPage,
				page: datasource.currentPage
			}
		});
	}, [datasource, propertyId, datasource.currentPage, datasource.perPage, datasource.sortBy]);

	if (_.isEmpty(propertyId) || options.length === 0) return null;

	return (
		<Input
			onChange={onSelectOption}
			name="building_id"
			defaultState={defaultState}
			required={constraints}
			options={options}
			showIf={true}
		/>
	);
};

export default memo(AsyncSelect);
