import React, { memo } from "react";
import { translate } from "lib";

const Label = ({
	name,
	label,
	children
}: {
	name?: string;
	label?: string;
	children?: JSX.Element | JSX.Element[] | any;
}) => (
	<label htmlFor={`form-control-input-${name}`}>
		<span>{translate(`${label || name}`)}</span>
		{children}
	</label>
);

export default memo(Label);
