import React from "react";
import { View } from "../Shared";
import { Form, Card } from ".";

interface Props {
	onUpdate: (lease: tcpinpoint.Lease | boolean) => void;
	lease?: tcpinpoint.Lease;
}

export default ({ onUpdate, lease }: Props) => (
	<View resource="lease" className={"form-group"}>
		{!!lease ? <Card lease={lease} onClear={() => onUpdate(false)} /> : <Form onSubmit={onUpdate} lease={lease} />}
	</View>
);
