import React, { useState, memo } from "react";
import { ListGroup, ListGroupItem } from "components/Shared";
import { Inline as User } from "components/Users";
import { translate } from "lib";
import classNames from "classnames";
import _ from "lodash";

export default memo(({ can, user, id, resource }) => {
	const [permissionOpen, setPermissionOpen] = useState(false);
	if (!!user) {
		const authority =
			_.get(resource, "created_by_id") === _.get(user, "id") ||
			_.get(resource, "created_by.id") === _.get(user, "id") ||
			_.includes(
				_.map(_.get(resource, "authorities", []), admin => admin.user_id),
				_.get(user, "id")
			);
		return (
			<div
				key={id}
				id={id}
				data-email={_.get(user, "email")}
				className={classNames("list-group-item", "list-group-item-nested", "permission")}
			>
				<div className="list-group-item-heading flex p-1" onClick={() => setPermissionOpen(!permissionOpen)}>
					<div>
						<User user={user} context={resource} authority={authority} />
						{user.email && <div className="muted">{user.email}</div>}
					</div>
					<div className="ml-auto flex-end">
						<i className={`fas ${!!permissionOpen ? "fa-angle-down" : "fa-angle-up"}`} />{" "}
					</div>
				</div>
				{!!permissionOpen && (
					<ListGroup
						resource="permissions"
						collection={can}
						renderRow={permission => (
							<ListGroupItem
								key={permission}
								heading={translate(`permission_${permission}`)}
								icon={<i className="fa fa-check-circle" style={{ color: "green" }} />}
							/>
						)}
					/>
				)}
			</div>
		);
	}
	return false;
});
