import React, { useState, memo } from "react";
import _ from "lodash";
import classNames from "classnames";
import { EmptyState, Button, Pagination } from "./";
import { translate } from "../../lib";
import { useSelector } from "react-redux";

interface Props {
	renderRow: (arg0: any) => string | boolean | JSX.Element;
	className?: string | string[];
	resource?: string;
	children?: JSX.Element | JSX.Element[];
	groupBy?: any;
	maximumPagesInWidget?: any;
	hidePageCount?: boolean;
	collection: any[];
	pagination?: boolean;
	onAdd?: any;
}

/**
 * Renders a collection of resources into a list
 *
 * @param {any[]} collection Array of resources to be iterated
 * @param {(resource)=>string | boolean | JSX.Element} renderRow Interator function to render a single row
 * @param {string|string[]} className Additional classnames (adds to 'list-group')
 * @param {string} resource Collective name of the resource being rendered into a list
 * @param {JSX.Element[]} children Child elements to nest inside ListGroup
 * @param {boolean} pagination Paginate the list
 * @param {boolean} hidePageCount Show Page count
 * @param {()=>any} onAdd Callback function. Adds a "Add" button below the list
 * @param {number} maximumPagesInWidget Number of pages to show in pagination widget (if pagination is true)
 */
const ListGroup = ({
	renderRow,
	className,
	resource,
	children,
	hidePageCount = false,
	maximumPagesInWidget,
	collection,
	pagination = true,
	onAdd,
}: Props) => {
	const [itemsPerPage, setItemsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(0);
	const active = useSelector((store: tcpinpoint.Store) => _.get(store.activity, [`${resource}`.toUpperCase()]));
	let rows = _.compact(collection);
	let slicedRows = rows;
	if (rows && pagination) {
		slicedRows = rows.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage);
	}

	let output: any = false;
	if (!!_.get(rows, "length", 0)) {
		// TODO: performance issue here
		output = _.map(slicedRows, renderRow);
	} else if (!!active) {
		output = (
			<div className="list-group-item list-group-status">
				<i className="fal fa-circle-notch fa-spin" />
			</div>
		);
	} else {
		output = <EmptyState resource={resource || translate("items")} />;
	}

	return (
		<div
			id={`list-group-component-${resource}`}
			className={classNames(className, "list-group", {
				"is-active": !!active,
				"is-empty": !_.get(rows, "length", 0),
			})}
		>
			<div className="list-group-section">
				<div
					aria-label="List Component"
					className={classNames(`list-group-component-${resource}`, "list-group-component")}
				>
					{output}
				</div>
			</div>
			{onAdd && (
				<div className={classNames("list-group-add")}>
					{typeof onAdd === "function" ? (
						<Button kind="add" onClick={onAdd} />
					) : (
						_.map(_.compact(onAdd), (x: any) => (
							<Button key={`add-${x.label}`} kind="add" onClick={x.func}>
								{translate(x.label)}
							</Button>
						))
					)}
				</div>
			)}
			{children && children}
			{pagination && !hidePageCount && (
				<Pagination
					currentPage={currentPage}
					totalItems={_.get(rows, "length", 0)}
					jumpToPage={setCurrentPage}
					itemsPerPage={itemsPerPage}
					maximumPagesInWidget={maximumPagesInWidget || 10}
					updateItemsPerPage={setItemsPerPage}
				/>
			)}
		</div>
	);
};

export default memo(ListGroup);
