import React, { memo } from "react";
import classNames from "classnames";
import { translate } from "lib";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faAsterisk } from "@fortawesome/pro-regular-svg-icons";



const Required = (props: any) => {
  const { required, defaultState, name } = props;
  const isRequired = !!_.get(_.get(required, name), 'presence')
  
  if (isRequired) {
    const isFulfilled = !!_.get(defaultState, name, false);
    return (
      <span className={classNames("required", { "required-fulfilled": isFulfilled })}>
        <FontAwesomeIcon icon={isFulfilled ? faCheck : faAsterisk} />
        <span>{translate("required")}</span>
      </span>
    );
  }
  return null
  // return false;
}

export default memo(Required);
