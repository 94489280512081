import { LatestCommentReport } from "components/Comments";
import { FitoutPeriodTimeline, HandoverDateTimeline, LeaseTableReport } from "components/Leases";
import { Tabs, View } from "components/Shared";
import React, { memo } from "react";
import { shallowEqual } from "react-redux";
import PortfolioPhaseReport from "./PhaseReport";
// import SunburstReport from "./SunburstReport";

interface Props {
	portfolioId: string;
	name: string;
}

const Reports = ({ portfolioId }: Props) => {
	return (
		<View resource="insights">
			<h2>Available Reports</h2>
			<Tabs
				tabs={[
					<PortfolioPhaseReport name="Portfolio Phases Report" portfolioId={portfolioId} />,
					<HandoverDateTimeline name="Handover Date Timeline" portfolioId={portfolioId} />,
					<FitoutPeriodTimeline name="Fitout Period Timeline" portfolioId={portfolioId} />,
					<LeaseTableReport name="Lease Table Report" portfolioId={portfolioId} />,
					<LatestCommentReport name="Latest Comment Report" portfolioId={portfolioId} />,
					// <SunburstReport name="Sunburst Graph Report" portfolioId={portfolioId} />
				]}
			/>
		</View>
	);
};

export default memo(Reports, shallowEqual);
