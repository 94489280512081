import _ from "lodash";
import React, { memo } from "react";
import {
	ActivityCommentItem,
	ActivityConditionItem,
	ActivityDocumentItem,
	ActivityInviteItem,
	ActivityPartyItem,
	ActivityProjectItem,
	ActivityTaskItem,
	ActivityTemplateItem,
	ActivityReactionItem,
	ActivityConditionUploadItem,
	ActivityDefectActItem,
} from ".";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityItemDynamic = ({ activity }: Props) => {
	const components = {
		Project: ActivityProjectItem,
		Party: ActivityPartyItem,
		Invite: ActivityInviteItem,
		Document: ActivityDocumentItem,
		Template: ActivityTemplateItem,
		Task: ActivityTaskItem,
		Comment: ActivityCommentItem,
		Condition: ActivityConditionItem,
		Reaction: ActivityReactionItem,
		ConditionUpload: ActivityConditionUploadItem,
		Defectact: ActivityDefectActItem,
	};
	const objectType = _.split(activity.attributes.item.object.type, "/")[1];
	const ActivityItem = _.get(components, objectType);

	if (!ActivityItem) return null;
	return <ActivityItem activity={activity} />;
};

export default memo(ActivityItemDynamic);
