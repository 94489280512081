import React from "react";
import { View, Title } from "components/Shared";
import { useAppClass } from "lib/hooks";

const Missing = () => {
	useAppClass("is-deleted");
	return (
		<section>
			<View resource="deleted" hideTitle>
				<h1 className="display-1">404</h1>
				<Title>File Deleted</Title>
			</View>
		</section>
	);
};

export default Missing;
