import thumbsUp from '../../images/twemoji/1f44d.png'
import clap from '../../images/twemoji/1f44f.png'
import heart from '../../images/twemoji/1f496.png'
import shocked from '../../images/twemoji/1f632.png'
import think from '../../images/twemoji/1f914.png'
import fire from '../../images/twemoji/1f525.png'

export {
    thumbsUp,
    clap,
    heart,
    shocked,
    think,
    fire
}