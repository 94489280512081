import React, { useEffect } from "react";
import { Missing, Unauthorised } from "components/Shared";
import { useSelector, useDispatch } from "react-redux";
import { useAppClass } from "lib/hooks";
import { boot } from "actions";
import { getResource } from "v2/utils/route";
import className from "classnames";
import _ from "lodash";

export const useBoot = (children: React.ReactNode) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(boot());
	}, [children, dispatch]);
	const status = useSelector(store => _.get(store, ["errors", getResource(), "GET", "status"]));

	let output = children;
	if (status === 404) {
		output = <Missing />;
	} else if (status === 401) {
		output = <Unauthorised />;
	}

	const bodyClass = className("application-layout", {
		"is-missing": status === 404,
		"is-unauthorised": status === 401
	});
	useAppClass(bodyClass);

	return output;
};
