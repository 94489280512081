import React, { useState } from "react";
import { Button, Date as DateComponent } from "components/Shared";
import { Inline as User } from "components/Users";
import { Input } from "components/Inputs";
import { translate } from "lib";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { closeCondition } from "actions";

const InspectionCondition = ({ condition, onUpdate }: any) => {
	const dispatch = useDispatch();
	const [response, setResponse] = useState();
	const { closed_at, id } = condition;
	const task = useSelector((store: tcpinpoint.Store) => _.find(store.tasks, { id: condition.task_id }));
	if (task) {
		return (
			<div className="condition-actions">
				{!closed_at ? (
					<React.Fragment>
						<p>{condition.response}</p>
						<p className="condition-meta muted">
							<User user={condition.closed_by} /> {translate("responded")} <DateComponent date={closed_at} />
						</p>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Input
							name="response"
							defaultState={{ response }}
							onChange={setResponse}
							endpoint={`projects/${task.project_id}/inspections`}
						/>
						<Button small onClick={() => dispatch(closeCondition(id, response))} />
					</React.Fragment>
				)}
			</div>
		);
	}
	return null;
};
export default InspectionCondition;
