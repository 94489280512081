import _ from "lodash";
export default (state: tcpinpoint.Template[] = [], action: any) => {
	switch (action.type) {
		case "GET_TEMPLATE_SUCCESS":
			return _.unionBy([action.resource as tcpinpoint.Template], state, "id");
		case "GET_TEMPLATES_SUCCESS":
			return _.unionBy(action.resource as tcpinpoint.Template[], state, "id");
		case "DELETE_TEMPLATE_SUCCESS":
			return _.filter(state, template => template.id !== action.resource.id);
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
