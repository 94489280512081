import React from "react";
import Archive from "./Archive";
import Defect from "./Defect";
import Collection from "./Collection";
import Confirmation from "./Confirmation";
import Create from "./Create";
import Dashboard from "./Dashboard";
import Deadline from "./Deadline";
import Deadlines from "./Deadlines";
import Details from "./Details";
import Documents from "./Documents";
import Edit from "./Edit";
import List from "./List";
import Row from "./Row";
import Lookup from "./Lookup";
import Select from "./Select";
import AddToPortfolio from "./AddToPortfolio";
import Trash from "./Trash";

import { lazy } from "react";
const LazyShow = lazy(() => import("./Show"));
const Show = (props: any) => <LazyShow {...props} />;

export {
	Archive,
	Defect,
	Collection,
	Lookup,
	Confirmation,
	Create,
	Dashboard,
	Trash,
	Deadline,
	AddToPortfolio,
	Deadlines,
	Details,
	Documents,
	Select,
	Edit,
	List,
	Row,
	Show,
};
