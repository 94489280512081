import React, { useState, useRef, useEffect, ReactElement, memo } from 'react';
import _ from "lodash";
const DropDownArrow = ({ color }: { color: string }): ReactElement => {
    return (
        <svg width="15" height="15" viewBox="0 0 13 12" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 12L0.0048084 0.750001L12.9952 0.75L6.5 12Z" fill={color}/>
        </svg>
        
    );
};

function useOutsideAlerter(ref: any, toggleDropDownState: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, toggleDropDownState]);
}

const ButtonDropDown = ({
    disabled,
    buttonOption,
    dropdownOptions,
    groupingOptions,
    textClassNames,
    dropDownArrowClassNames,
    dropDownClassNames,
    dropDownArrowColor,
    callBack,
}: {
    disabled?: boolean,
    buttonOption: {
        id: string;
        name: string;
    };
    dropdownOptions: {
        id: string;
        name: string;
        groupingName?: string
        disabled?: boolean
    }[];
    groupingOptions?: {}[]
    callBack: (arg0: any) => void;
    textClassNames?: string;
    dropDownArrowClassNames?: string;
    dropDownClassNames?: string;
    dropDownArrowColor?: string;
}): ReactElement => {
    const handleClick = (e: any) => {
        toggleDropDownState(false);
        callBack(e.target.dataset.value);
    };

    const [isOpen, toggleDropDownState] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleDropDownState);

    return (
        <span ref={wrapperRef} className="relative inline-flex shadow-sm rounded-md text-center border border-borderColor">
            <button
                disabled={disabled}
                onClick={handleClick}
                data-value={buttonOption.id}
                type="button"
                className={` ${
                    textClassNames
                        ? textClassNames
                        : 'relative inline-flex items-center rounded-l-md border-border-1 box-border text-12 focus:outline-none select-none transition ease-in-out duration-500 text-blue-100'
                } select-none transition ease-in-out duration-500 border-r border-borderColor truncate`}
            >
                {buttonOption.name}
            </button>
            <span className="-ml-px relative block">
                <button
                    disabled={disabled}
                    type="button"
                    onClick={() => toggleDropDownState(!isOpen)}
                    className={` ${
                        dropDownArrowClassNames
                            ? dropDownArrowClassNames
                            : 'rounded-r-md border text-blue-100'
                    } relative flex justify-center rounded-r-md select-none`}
                    aria-label="Expand"
                >
                    <p className={`transition ease-linear duration-200 py-4 px-4 ${isOpen ? 'transform rotate-180' : ''}`}>
                        <DropDownArrow color={`${dropDownArrowColor ? dropDownArrowColor : '#D8D8D8'}`} />
                    </p>
                </button>
                <div
                    className={`origin-top-right absolute right-0 mt-2 -mr-1 w-168 rounded-md shadow-lg z-50 ${
                        isOpen ? 'animate-grow-down-dropdown-animation opacity-1 ' : 'opacity-0 pointer-events-none'
                    }`}
                >
                    <div className="rounded-md bg-white shadow-xs overflow-y-auto" style={{
						minHeight: '50px',
                        maxHeight: '155px',
					}}>
                        <div className="w-full">
                            {groupingOptions && groupingOptions.map((groupType) => (
                                <div className="flex flex-col">
                                    {<strong> {groupType}</strong>}
                                    
                                {_.filter(dropdownOptions, list => list.groupingName === groupType).map((item) => (
                                    <button
                                        data-value={item.id}
                                        onClick={handleClick}
                                        key={item.id}
                                        disabled={item.disabled}
                                        className={`${dropDownClassNames ? dropDownClassNames : ''} py-3 px-2 block font-normal text-left text-14 text-black 
                                        focus:outline-none focus:text-black select-none ${(item.disabled) ? 'text-black-20' : 'hover:bg-blue-100 hover:text-white'}`}
                                        >
                                        {item.name}
                                    </button>
                                ))}
                                </div>
                            ))}

                            {!groupingOptions && dropdownOptions.map((item) => (
                                <div className="flex flex-col">
                                    <button
                                        data-value={item.id}
                                        onClick={handleClick}
                                        key={item.id}
                                        disabled={item.disabled}
                                        className={`${dropDownClassNames ? dropDownClassNames : ''} py-3 px-2 block font-normal text-left text-14 text-black 
                                        focus:outline-none focus:text-black select-none ${(item.disabled) ? 'text-black-20' : 'hover:bg-blue-100 hover:text-white'}`}
                                        >
                                        {item.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </span>
        </span>
    );
};

export default memo(ButtonDropDown);