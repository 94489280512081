import React from "react";
import { ListGroup, ListGroupControl, ListGroupItem, View } from "components/Shared";
import _ from "lodash";
import { translate } from "lib";
import { isFeatureToggled } from "lib";
import { UserContext } from "context";
import { Link } from "react-router-dom";

export default class List extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			sortBy: "name",
			filteredBy: false,
		};
	}

	render() {
		const { property, projectIds } = this.props;
		const { sortBy, filteredBy } = this.state;
		const { currentUser } = this.context;

		const tenancies = _.compact(_.flatten(_.map(_.get(property, "tenancies", []))));
		const filterFunction = (tenancy) => {
			if (filteredBy) {
				if (filteredBy === "occupied") {
					return !!(tenancy.current_tenant_trading_name || tenancy.newest_unexpired_tenant_trading_name);
				} else if (filteredBy === "vacant") {
					return !!!(
						tenancy.current_tenant_trading_name ||
						tenancy.newest_unexpired_tenant_trading_name ||
						tenancy.current_project_id
					);
				} else if (filteredBy === _.get(tenancy, "building_name")) {
					return tenancy;
				} else if (filteredBy === _.get(tenancy, "floor_name")) {
					return tenancy;
				}
			} else {
				return true;
			}
		};
		let collection;
		if (sortBy.includes("area")) {
			collection = _.filter(
				_.sortBy(tenancies, [
					(obj) => {
						return parseFloat(obj.area);
					},
				]),
				filterFunction
			);
		} else {
			collection = _.filter(_.sortBy(tenancies, sortBy), filterFunction);
		}

		const sortOptions = _.merge(["name", "floor", "building_name", "area", "occupancy_status"]);
		const filterOptions = _.union(
			["vacant", "occupied"],
			_.uniq(_.map(tenancies, (t) => t.floor_name)),
			_.uniq(_.map(property.buildings, (b) => b.name))
		);

		const tenancyToName = (tenancy) => {
			let status = _.get(tenancy, "occupancy_status");
			let tenancyID = _.get(tenancy, "name");
			let currentTradingName = _.get(tenancy, "current_tenant_trading_name");
			let newestTradingName = _.get(tenancy, "newest_unexpired_tenant_trading_name");
			if (status !== "vacant" && (currentTradingName || newestTradingName)) {
				return tenancyID + " — " + (currentTradingName || newestTradingName);
			} else {
				return tenancyID;
			}
		};

		const projectStatusDisplay = (tenancy) => {
			let projectStatus = _.get(tenancy, "current_project_status");
			if (projectStatus === "vacant") {
				return "—";
			} else if (projectStatus === "complete") {
				return "open_complete";
			} else if (projectStatus === "behind") {
				return "open_behind";
			} else if (projectStatus === "ahead") {
				return "open_ahead";
			} else if (projectStatus.includes("closed")) {
				return "archived";
			}
		};

		console.log({ tenancies });
		return (
			<View title="Tenancies">
				<ListGroupControl
					collection={collection}
					onSortChange={(sortBy) => this.setState({ sortBy })}
					selectedSort={sortBy}
					sortOptions={sortOptions}
					onFilterChange={(filteredBy) => this.setState({ filteredBy })}
					selectedFilter={filteredBy}
					filterOptions={filterOptions}
				/>
				<ListGroup
					resource="tenancies"
					pagination={true}
					collection={collection}
					renderRow={(tenancy) => (
						<ListGroupItem
							id={tenancy.id}
							key={tenancy.id}
							link={
								!!isFeatureToggled("tenancies", currentUser) &&
								(tenancy.current_project_id && projectIds.includes(tenancy.current_project_id)
									? `/projects/${tenancy.current_project_id}`
									: null)
							}
							heading={tenancyToName(tenancy)}
							datapoints={[
								!!_.get(tenancy, "area") && {
									key: "size",
									value: <React.Fragment>{Math.round(_.get(tenancy, "area")).toLocaleString()}m²</React.Fragment>,
								},
								!!_.get(tenancy, "occupancy_status") && {
									key: "occupancy_status",
									value: <React.Fragment>{_.capitalize(_.get(tenancy, "occupancy_status"))}</React.Fragment>,
								},
								!!_.get(tenancy, "current_project_status") && {
									key: "project_status",
									value: <React.Fragment>{translate(projectStatusDisplay(tenancy))}</React.Fragment>,
								},
								{
									key: "",
									value: (
										<Link
											className="btn btn-primary"
											to={{
												pathname: `/tenancies/${tenancy.id}/edit`,
											}}
										>
											<i className="fa fa-pencil" /> Edit
										</Link>
									),
								},
							]}
						/>
					)}
				/>
			</View>
		);
	}
}
