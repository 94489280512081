import ActivityTab from "components/Activities/ActivityDynamic";
import { UserContext } from "context";
import React, { memo, useCallback, useContext } from "react";

const HomeFeed = () => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const queryStringParameters = useCallback(() => ({ user_id: currentUser.id, include_actor: false }), [
		currentUser.id
	]);

	return <ActivityTab name="activity" key="activity-tab" queryStringParameters={queryStringParameters()} />;
};

export default memo(HomeFeed);
