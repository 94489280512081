import React, { memo } from "react";
import { ListGroupItem, PhoneNumber } from "components/Shared";
import { Show as Avatar } from "components/Avatar";
import _ from "lodash";

interface Props {
	user: tcpinpoint.User;
	subheading?: string;
}

const Row = (props: Props) => {
	const { subheading, user } = props;
	return (
		<ListGroupItem
			key={user.id}
			icon={<Avatar url={_.get(user, "url")} name={`${_.get(user, "firstname")} ${_.get(user, "surname")}`} />}
			heading={`${user.firstname} ${user.surname}`}
			subheading={subheading}
			datapoints={[
				!!_.get(user, "company.name") && {
					key: "company",
					value: <a href={`/companies/${_.get(user, "company.id")}`}>{_.get(user, "company.name")}</a>
				},
				{
					key: "email",
					value: <a href={`mailto:${user.email}`}>{user.email}</a>
				},
				{
					key: "phone_number",
					value: <PhoneNumber phone_number={_.get(user, "phone_number")} />
				}
			]}
		/>
	);
};

export default memo(Row);
