import React from "react";
import { translate } from "lib";

export class AvatarUpload extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      image: props.image || null
    };
  }

  componentDidMount() {
    this.updateImage();
  }

  onUpdate() {
    const { onChange } = this.props;
    onChange && onChange(this.canvas.toDataURL("image/png"));
  }

  updateImage() {
    const { image } = this.state;
    const { size } = this.props;
    if (this.canvas) {
      const context = this.canvas.getContext("2d");
      context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      const img = new Image();
      img.onload = () => {
        const imgWidth = parseInt(img.width, 10);
        const imgHeight = parseInt(img.height, 10);
        const widthRatio = size / imgWidth;
        const heightRatio = size / imgHeight;
        var ratio;
        if (widthRatio > heightRatio) {
          ratio = widthRatio;
        } else {
          ratio = heightRatio;
        }
        const imgMiddleWidth = (imgWidth * ratio) / 2;
        const imgMiddleHeight = (imgHeight * ratio) / 2;
        const canvasMiddleWidth = size / 2;
        const canvasMiddleHeight = size / 2;
        const xOffset = imgMiddleWidth - canvasMiddleWidth;
        const yOffset = imgMiddleHeight - canvasMiddleHeight;
        context.save();
        context.beginPath();
        context.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, false);
        context.clip();
        context.drawImage(
          img,
          xOffset > 0 ? -1 * xOffset : 0,
          yOffset > 0 ? -1 * yOffset : 0,
          img.width * ratio,
          img.height * ratio
        );
        context.restore();
        this.onUpdate();
      };
      img.src = image;
    }
  }

  onChange(event) {
    event.preventDefault();
    var reader = new FileReader();
    var file = event.target.files[0];
    if (!file) return;
    reader.onload = img => {
      this.setState({ image: img.target.result }, () => this.updateImage());
    };
    reader.readAsDataURL(file);
  }

  startDrag(event) {
    this.setState({ dragging: true });
  }

  stopDrag(event) {
    this.setState({ dragging: false });
  }

  onMove(event) {
    const { dragging } = this.state;
    if (dragging) {
      const x = event.clientX;
      const y = event.clientY;
      const deltaX = this.state.x - event.clientX;
      const deltaY = this.state.y - event.clientY;
      this.setState({
        x,
        y,
        destX: this.state.destX + deltaX,
        destY: this.state.destY + deltaY
      });
    }
  }

  render() {
    const { size } = this.props;
    return (
      <div className="avatar-upload">
        <canvas
          height={size}
          width={size}
          onMouseMove={event => this.onMove(event)}
          onMouseDown={event => this.startDrag(event)}
          onMouseUp={event => this.stopDrag(event)}
          ref={canvas => (this.canvas = canvas)}
          className="avatar-upload-canvas"
        />
        <div className="avatar-upload-buttons">
          <label className="btn btn-outline-primary btn-sm" htmlFor="avatar-upload" style={{ cursor: "pointer" }}>
            {translate("upload")}
          </label>
        </div>
        <input
          style={{ display: "none" }}
          name="avatar-upload"
          id="avatar-upload"
          type="file"
          accept="image/*"
          onChange={event => this.onChange(event)}
        />
      </div>
    );
  }
}

AvatarUpload.defaultProps = {
  size: 120
};

export default AvatarUpload;
