import React, {memo} from "react";
import moment from "moment";

const dateDiffInDays = (a: Date, b: Date): number => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

const formatDate = (date: string) => {
    let temp = date.split('-');
    let formattedDate = temp[2] + '-' + temp[1] + '-' + temp[0];
    return formattedDate;
}

const DueDate = ({
    taskDueDate,
    taskStatus,
    successClassNames,
    warningClassName,
    errorClassNames,
    fullDateRequired,
    extraClassNames,
    showClosedDate,
}: {
    taskDueDate: string;
    taskStatus: string;
    successClassNames?: string;
    warningClassName?: string;
    errorClassNames?: string;
    fullDateRequired?: boolean;
    extraClassNames?: string;
    showClosedDate?: boolean;
}) => {
    let label = '',
        className = `${extraClassNames ? extraClassNames : 'text-12 font-normal'}`;
        taskDueDate = taskDueDate.toLowerCase();
    if (taskDueDate !== 'Invalid date') {
        const cDate = new Date(),
            //dDate = new Date(formatDate(taskDueDate));
            dDate = new Date(taskDueDate);
        let diff = dateDiffInDays(cDate, dDate);


        if((taskStatus) === 'open'){
            if(diff === 0){
                label = `Due Today`;
                className += ` ${warningClassName ? warningClassName : ' text-warning-darker'}`
            }
            else if (diff <= 2 && diff > 0){
                label = `${Math.abs(diff)}d to go`;
                className += ` ${warningClassName ? warningClassName : ' text-warning-darker'}`
            }
            else if (diff > 2) { 
                if(diff > 2 && diff < 7){
                    label = `${Math.abs(diff)}d to go`;
                    className += ' text-black-90';
                }
                if(diff >= 7 && diff < 31){
                    diff = Math.floor(diff/7);
                    label = `${Math.abs(diff)}w to go`;
                    className += ' text-black-90';
                }
                if(diff >= 31 && diff < 365){
                    diff = Math.floor(diff/31);
                    label = `${Math.abs(diff)}m to go`;
                    className += ' text-black-90';
                }
                else if (diff >= 365){
                    diff = Math.floor(diff/365);
                    label = `${Math.abs(diff)}y to go`;
                    className += ' text-black-90';
                }
            }
            else if (diff < 0){
                if (diff > -7 && diff <= -1){
                    label = `Due ${Math.abs(diff)}d ago`;
                    className += ` ${errorClassNames ? errorClassNames : ' text-error-darker'}`
                }
                else if(diff <= -7 && diff > -31){
                    diff = Math.floor(diff/7);
                    label = `Due ${Math.abs(diff)}w ago`;
                    className += ` ${errorClassNames ? errorClassNames : ' text-error-darker'}`
                }
                else if(diff <= -31 && diff > -365){
                    diff = Math.floor(diff/31);
                    label = `Due ${Math.abs(diff)}m ago`;
                    className += ` ${errorClassNames ? errorClassNames : ' text-error-darker'}`
                }
                else if (diff <= -365){
                    diff = Math.floor(diff/365);
                    label = `Due ${Math.abs(diff)}y ago`;
                    className += ` ${errorClassNames ? errorClassNames : ' text-error-darker'}`
                }
            }
        }
        else if (taskStatus === 'closed'){
            showClosedDate ? label = moment(taskDueDate).format("DD MMM YYYY") : label = "Closed";
            className += ` ${successClassNames}`
        }
    } else{
        label = "No Date";
    }

    return (
        <div className='flex select-none flex-col'>
            <p className={className}>{label}</p>
            {fullDateRequired && 
                <p className="">{taskDueDate === '' ? '' : moment(formatDate(taskDueDate)).format('DD MMM YYYY')}</p>}
        </div>
    );
};

export default memo(DueDate);