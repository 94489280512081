import React, { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import WorkflowVisual from "components/Workflows/WorkflowVisual";
import { View } from "components/Shared";
import { getPortfolioWorkflows } from "actions/workflows";
import { getProperties } from "actions";
import { Link } from "react-router-dom";
import Progress from "components/Visualisations/Progress/Progress";
import { Status } from "components/Shared/Status";
import { Legend } from "./Legend";
import { propertiesSelector } from "../../selectors/index";

const PortfolioReport = ({ portfolio }: any) => {
	const { id } = portfolio;
	const dispatch = useDispatch();
	const projects = _.filter(
		_.get(portfolio, "projects", []),
		project => project.closed_at === null && project.deleted_at === null
	);

	const project_ids = _.map(projects, "id");
	const properties = useSelector(propertiesSelector);
	const workflows = useSelector((store: tcpinpoint.Store) =>
		_.filter(store.workflows, workflow => _.includes(project_ids, workflow.project_id))
	) as tcpinpoint.Workflow[];
	// const numberOfProjects = workflows.length;

	useEffect(() => {
		dispatch(getProperties());
		dispatch(getPortfolioWorkflows(id));
	}, [dispatch, id]);

	if (!!portfolio) {
		return (
			<View resource="portfolio-phase-status-report">
				{/* <header className="report-header">
					<h4 className="page-title">TCPinpoint Portfolio Status Report</h4>
					<FeatureFlag title={"beta"} tooltip={"this feature is still in development"} />
					<h1>{portfolio.name}</h1>
					<h5>Delivered: {moment().format("LLL")}</h5>
				</header> */}
				<div className="report-header">
					<h3>Project Phase Status Report</h3>
					{/* <Button onClick={() => exportPDFbyId("", 'phase-report', 'phase_report.png', id, numberOfProjects)}>
            Download PDF
						</Button> */}

					{/* Legends Area */}
					<div className="status-legend">
						<div>
							<Status incomplete={true} />
							<figcaption>Incomplete</figcaption>
						</div>

						<div>
							<Status incomplete={false} />
							<figcaption>Complete</figcaption>
						</div>

						<div>
							<Status incomplete={false} closed={true} />
							<figcaption>Closed Complete</figcaption>
						</div>

						<div>
							<Status closed={true} />
							<figcaption>Closed Incomplete</figcaption>
						</div>
						<div className="ahead">
							<Legend type="spinner" color="#ddd" />
							<figcaption>Phase Progress</figcaption>
						</div>
					</div>
				</div>

				<div className="report-body">
					{_.map(_.sortBy(_.uniqBy(portfolio.projects, "id") as Array<any>, "tenancy.name"), project => {
						const property = _.find(properties, { id: _.get(project, "property_id") });
						const projectWorkflow = _.find(workflows, {
							project_id: _.get(project, "id")
						});

						if (projectWorkflow) {
							return (
								<div className="report-item" key={project.id}>
									<header className="item-header">
										<h4>
											<Link to={`/projects/${project.id}`}>{project.name}</Link>
										</h4>
										<span> - </span>
										{!!property && (
											<h4>
												<Link to={`/properties/${_.get(property, "id")}`}>{property.name}</Link>
											</h4>
										)}
									</header>
									<div className="workflow-visual" style={{ marginBottom: "10px" }}>
										<WorkflowVisual
											key={`${project.id}-workflow`}
											phases={projectWorkflow.phases}
											onReport={true}
											project={project}
										/>
									</div>
									<Progress width="100%" type="project" id={_.get(project, "id")} className="ahead" />
								</div>
							);
						}
						return null;
					})}
				</div>
			</View>
		);
	}

	return null;
};

export default memo(PortfolioReport);
