import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie, getUserIdFromStorage } from 'lib/hooks';

export const postEvent = memoAC((
	verb:
		| "Accept"
		| "Add"
		| "Announce"
		| "Arrive"
		| "Block"
		| "Create"
		| "Delete"
		| "Dislike"
		| "Flag"
		| "Follow"
		| "Ignore"
		| "Invite"
		| "Join"
		| "Leave"
		| "Like"
		| "Listen"
		| "Move"
		| "Offer"
		| "Question"
		| "Reject"
		| "Read"
		| "Remove"
		| "TentativeReject"
		| "TentativeAccept"
		| "Travel"
		| "Undo"
		| "Update"
		| "View",
	resource: tcpinpoint.Resource,
	label?: string,
	value?: string
) => (dispatch: any) => {
	dispatch({ type: "POST_EVENT_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}users/${getUserIdFromStorage()}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	}).then(session => {
		const user_id = session.data["user"]["id"];
		const matches = /gid:\/\/tcpinpoint\/(.*)\/(.*)/.exec(resource.link);
		if (!!matches) {
			const [, resource_type, resource_id] = matches;
			const message: tcpinpoint.TrackingEvent = {
				environment: process.env.NODE_ENV,
				version: "2019-08-30",
				created_at: new Date().toJSON(),
				user_id,
				resource_id,
				resource_type,
				verb
			};

			//@ts-ignore
			window.Intercom("update");
			// window.analytics.track(event.label, event);
			//@ts-ignore
			window.ga("set", "userId", user_id);
			//@ts-ignore
			window.ga("send", {
				hitType: "event",
				eventCategory: resource_type,
				eventAction: verb,
				eventLabel: label,
				eventValue: value
			});

			axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}activities`, { data: { attributes: message } }, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthTokenFromCookie()}`,
				}
			})
			.then((res) => { dispatch({ type: "GET_EVENTS_SUCCESS", resource: res.data.events, response: { status: 200 } }) })
			.catch((e) => { dispatch({ type: "GET_EVENTS_FAILED", e, response: e }); });
		}
	});
});
