import React, { useState, useEffect, useMemo, memo } from "react";
import Color from "color";
import Container from "./Container";
import Completion from "./Completion";
import { project_phase_task_by_project_id, project_phase_task_by_phase_id } from "./models";
import { getFormattedPercentage } from 'lib'
import axios from "v2/utils/axios";

const Progress = ({ type, id, color = "#49da9a", width = "100%", display }: any) => {
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    const getTasks = async () => {
      

      const tasks = await axios.post(`statistics/any`, 
        { sql: type === "project" ? project_phase_task_by_project_id(id) : project_phase_task_by_phase_id(id) }
      );
      setTasks(tasks.data);
    }
    getTasks()
  }, [type, id]);

  const memorizedPercentage = useMemo(() => getFormattedPercentage(type, tasks), [type, tasks])

  return (
    <React.Fragment>
      <Container
        backGround={Color(color).fade(0.7).rgb().string()}
        width={width}
        display={display}
      >
        <Completion type={type} data={memorizedPercentage} color={color} />

        <div
          style={{ margin: "0 auto", color: "#fff" }}>
          {memorizedPercentage === '' ? '0%' : null}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default memo(Progress);
