import config from "config.js";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { getAuthTokenFromCookie, getUserIdFromStorage } from 'lib/hooks'

export const boot = () => () => {
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}users/${getUserIdFromStorage()}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getAuthTokenFromCookie()}`,
			}
		}).then(session => {
		const email = session.data.user.email;
		const firstname = session.data.user.given_name;
		const surname = session.data.user.family_name;
		const name = `${firstname} ${surname}`;
		const id = session.data.user.id;

		/**
		 * Sentry
		 */
		Sentry.init({ dsn: config.Sentry.dsn, release: config.version });
		Sentry.configureScope(scope => scope.setUser({ name, username: email, email, id }));

		/**
		 * Google Analytics
		 */
		//@ts-ignore
		if (window.ga) {
			//@ts-ignore
			window.ga("create", config.GoogleAnalytics.accessToken, "auto");
		}

		/**
		 * Intercom
		 */
		//@ts-ignore
		if (window.Intercom) {
			//@ts-ignore
			window.Intercom("boot", { app_id: config.Intercom.app_id });
			//@ts-ignore
			window.Intercom("update", { name, email, id, user_id: id, update_last_request_at: true });
		}

		/**
		 * Zendesk
		 */
		//@ts-ignore
		if (window.zE && window.zE.identify) {
			//@ts-ignore
			window.zE(() => window.zE.identify({ name, email }));
		}
	});
};
