import React, { memo } from "react";
import { Form, Card } from ".";

interface Props {
	onUpdate: (lease: tcpinpoint.Lease | null) => void;
	lease?: tcpinpoint.Lease;
	viewState?: number;
	setViewState?: any;
	tenancyArea?: any;
}

const Select = ({ onUpdate, lease, viewState, setViewState, tenancyArea }: Props) => {
	return viewState === 1 ? (
		<Card lease={lease!} tenancyArea={tenancyArea} onClear={() => setViewState(0)} />
	) : (
		<Form
			onSubmit={lease => {
				onUpdate(lease);
				setViewState(1);
			}}
			lease={lease}
		/>
	);
};

export default memo(Select);
