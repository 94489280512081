import React, { memo } from "react";
import { icon as stdIcon, icons, translate } from "lib";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Flyout, Date } from ".";
import classNames from "classnames";

interface Props {
	badge?: string | JSX.Element | false | null;
	children?: JSX.Element;
	className?: string | any | false;
	data?: any;
	datapoints?: ({ key: string; value: any } | false | null)[];
	deleted_at?: boolean;
	disabled?: boolean;
	heading?: string | JSX.Element | false;
	icon?: any;
	id?: string;
	isFolder?: boolean;
	link?: any;
	menu?: (JSX.Element | false | null)[];
	onAdd?: () => void | false;
	onEdit?: () => void | false;
	onClose?: () => void | false;
	onRemove?: () => void | false;
	onSelect?: () => any;
	onUnselect?: () => any;
	parent?: string | JSX.Element | false;
	selected?: boolean;
	statistics?: false | ({ key: string; value: any } | false | null)[];
	subheading?: string | JSX.Element | false;
	thumbnail?: string | null;
	title?: string | JSX.Element | false;
	secondHeading?: string | JSX.Element | false;
}

const ListGroupItem = (props: Props) => {
	const {
		badge = false,
		children,
		className = [],
		datapoints = [],
		disabled = false,
		heading = null,
		icon,
		id,
		isFolder = false,
		link = false,
		menu = [],
		onClose = false,
		onRemove = false,
		onSelect = false,
		onUnselect = false,
		onAdd = false,
		parent = false,
		selected = false,
		statistics = [],
		deleted_at = false,
		subheading = null,
		thumbnail = false,
		title = false,
		secondHeading = false,
	} = props;
	let { data } = props;
	const _className = classNames(className, "list-group-item", {
		selected: !!selected,
		disabled: !!disabled,
	});

	data = _.assign({}, data, {
		"data-title": title,
		"data-heading": heading,
		"data-subheading": subheading,
	});

	_.each(_.keys(data), (key) => (data[`data-${key}`] = data[key]));

	const element = (
		<React.Fragment>
			{!isFolder && !!icon && <div className="stamp">{typeof icon === "string" ? stdIcon(icon, "fal") : icon}</div>}
			{!!isFolder && !icon && (
				<div className="stamp">
					<i
						className={classNames("fas", {
							"fa-folder-open": !!selected,
							"fa-folder": !selected,
						})}
					/>
				</div>
			)}
			<span className="list-group-item-content">
				{!!deleted_at && (
					<div className="list-group-item-deleted">
						<Date date={deleted_at} />
					</div>
				)}
				{!!parent && <div className="list-group-item-parent">{parent}</div>}
				{(!!heading || !!subheading) && (
					<div className="list-group-item-heading">
						{!!title && <span>{title}</span>}
						{!!heading && <h3> {heading}</h3>}
						{!!secondHeading && <br></br>}
						{!!secondHeading && (
							<h6>
								{" "}
								<strong>
									{" "}
									<i> {secondHeading} </i>{" "}
								</strong>{" "}
							</h6>
						)}
						{!!subheading && <h6> {subheading}</h6>}
					</div>
				)}
			</span>
			{!!children && <div className="list-group-item-body">{children}</div>}
			{!!statistics && (
				<div className="list-group-item-statistics">
					{_.map(statistics, (statistic: { key: string; value: string }) => (
						<div
							key={statistic.key}
							data-key={statistic.key}
							className="list-group-item-datapoint statistics statistics-item"
							title={statistic.key}
						>
							<i className={`fal fa-${_.get(icons, statistic.key)}`} />
							<p>{statistic.value}</p>
						</div>
					))}
				</div>
			)}
			<div className="list-group-item-datapoints">
				{_.map(_.compact(datapoints), (datapoint: { key?: string; value?: string }, index) => (
					<div
						data-key={_.get(datapoint, "key", datapoint)}
						className="list-group-item-datapoint"
						key={`datapoint-${index}`}
					>
						{!!_.get(datapoint, "key") || !!_.get(datapoint, "value") ? (
							<React.Fragment>
								{!!datapoint.key && <h6>{translate(datapoint.key)}</h6>}
								{!!datapoint.value && datapoint.value}
							</React.Fragment>
						) : (
							(datapoint as string)
						)}
					</div>
				))}
			</div>
			{!!badge && <div className="badge">{badge}</div>}
		</React.Fragment>
	);
	if (!disabled && !!thumbnail) {
		return (
			<Link to={link} {...data} className={_className}>
				<div className="thumbnail" style={{ backgroundImage: `url(${thumbnail})` }} title={`${heading}`} />
			</Link>
		);
	} else if (!disabled && !!link && typeof link === "string" && !onClose && !menu.length) {
		if (link.slice(0, 4) === "http") {
			return (
				<a href={link} {...data} className={_className} target="_blank" rel="noopener noreferrer">
					{element}
					<div className="disclosure">
						<i className="fas fa-angle-right" />
					</div>
				</a>
			);
		} else {
			return (
				<Link to={link} {...data} className={_className}>
					{element}
					<div className="disclosure">
						<i className="fas fa-angle-right" />
					</div>
				</Link>
			);
		}
	} else if (!disabled && !!onAdd) {
		return (
			<a
				href="#link"
				onClick={(event) => {
					event.preventDefault();
					onAdd();
				}}
				{...data}
				className={_className}
			>
				{element}
				<div className="disclosure">
					<i className="fal fa-plus" />
				</div>
			</a>
		);
	} else if (!disabled && !!onRemove) {
		return (
			<div {...data} className={_className} id={id}>
				{element}
				{!!onRemove && (
					<button onClick={onRemove} className="close">
						<span aria-hidden="true">&times;</span>
					</button>
				)}
			</div>
		);
	} else if (!disabled && !!onSelect && !onClose && !!onUnselect) {
		return (
			<a
				href="#link"
				onClick={(event) => {
					event.preventDefault();
					!!selected ? onUnselect() : onSelect();
				}}
				{...data}
				className={_className}
			>
				{element}
				<div className="disclosure">{!!selected ? <i className="fal fa-minus" /> : <i className="fal fa-plus" />}</div>
			</a>
		);
	} else if (!disabled && !!link && typeof link === "function" && !onClose && !menu.length) {
		return (
			<a href="#link" onClick={link} {...data} className={_className}>
				{element}
				<div className="disclosure">
					<i className="fas fa-angle-right" />
				</div>
			</a>
		);
	} else {
		return (
			<div {...data} className={_className} id={id}>
				{element}
				{!!onClose && (
					<button onClick={onClose} className="close">
						<span aria-hidden="true">&times;</span>
					</button>
				)}
				{!!menu.length && <Flyout menu={menu} />}
			</div>
		);
	}
};

export default memo(ListGroupItem);
