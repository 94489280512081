import { Show as Avatar } from "components/Avatar";
import { ListGroupItem, LoadingState, PhoneNumber } from "components/Shared";
import { LastMomentTriggerAPIContext } from "context";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";

const TaskUser = ({ taskUser, canUpdate }: any) => {
	const { setLastMoment } = useContext(LastMomentTriggerAPIContext);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "task_user", renderTrigger: renderTrigger }));
	const isAdministrator = useMemo(() => _.get(taskUser, "is_administrator", false), [taskUser]);
	const roleName = useMemo(() => _.get(taskUser, "role_name"), [taskUser]);
	const remove = useCallback(() => {
		if (!_.isNil(taskUser)) datasource.del("v2", `task_users/${taskUser.id}`, {}, { updateIsLoding: true });
	}, [datasource, taskUser]);

	const subheading = useMemo(
		() => (
			<>
				{isAdministrator && (
					<span>
						<i className="fas fa-user-shield"></i> Administrator
					</span>
				)}
				{isAdministrator && roleName && <span> | </span>}
				<span>{roleName}</span>
			</>
		),
		[isAdministrator, roleName]
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) setLastMoment(moment());
		});
		return () => sub.unsubscribe();
	}, [datasource, setLastMoment]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<>
			<ListGroupItem
				id={taskUser.id}
				icon={<Avatar name={_.get(taskUser, "user.name")} url={_.get(taskUser, "user.avatar")} />}
				heading={_.get(taskUser, "user.name")}
				subheading={subheading}
				datapoints={[
					{
						key: "email",
						value: <a href={`mailto:${_.get(taskUser, "user.email")}`}>{_.get(taskUser, "user.email")}</a>
					},
					{
						key: "phone_number",
						value: <PhoneNumber phone_number={_.get(taskUser, "user.phone_number")} />
					}
				]}
				menu={[
					!!canUpdate && !!taskUser.deletable && (
						<button key={`remove-${_.get(taskUser, "id")}`} onClick={remove}>
							{translate("remove")}
						</button>
					)
				]}
			/>
		</>
	);
};

export default memo(TaskUser);
