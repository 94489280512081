import ActivityTab from "components/Activities/ActivityDynamic";
import {
	Aside,
	Breadcrumbs,
	Col,
	DefinitionItem,
	DefinitionList,
	Header,
	LoadingState,
	Row,
	View,
	Workspace
} from "components/Shared";
import { Shape } from "components/Tenancies";
import { UserContext } from "context";
import { translate } from "lib";
import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import axios from "v2/utils/axios";

class Facility extends React.Component {
	static contextType = UserContext;
	state = {};

	componentDidMount() {
		const { match } = this.props;
		const id = match.params.id;
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}facilities/${id}`)
			.then((res) => this.setState({ facility: res.data.facility }));
	}

	deleteFacility() {
		const { history } = this.props;
		const { facility } = this.state;
		const inspection_id = facility.inspection.id;
		axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}facilities/${facility.id}`)
			.then((res) => history.push(`/inspections/${inspection_id}`));
	}

	render() {
		const { facility } = this.state;
		if (!!facility) {
			const { inspection } = facility;
			const queryStringParameters = { facility_id: facility.id };
			return (
				<React.Fragment>
					<Breadcrumbs
						items={[
							{ link: "/inspections", title: translate("inspections") },
							{
								link: `/inspections/${_.get(inspection, "id")}`,
								title: `#${_.get(inspection, "reference")}`
							},
							{ title: translate("facilities") },
							{ link: `/facilities/${_.get(facility, "id")}`, title: _.get(facility, "name") }
						]}
					/>
					<Header
						// banner={!_.get(facility, "current") && { kind: "warning", message: translate("facility-not-current") }}
						stamp="inspections"
						title={translate(_.get(facility, "name"))}
						subtitle={`#${_.get(inspection, "reference")} at ${_.get(facility, "inspection.inspectable_name")}`}
						toolbar={[
							!_.get(inspection, "closed_at") && {
								label: "trash",
								onClick: () => this.deleteFacility(),
								confirm: true
							}
						]}
					/>
					<Workspace
						resource={facility}
						tabs={[
							<View ref="details">
								<Row>
									<Col>
										<DefinitionList>
											{_.map(_.get(facility, "metadata"), y => (
												<DefinitionItem title={y.key}>{translate(y.value)}</DefinitionItem>
											))}
										</DefinitionList>
									</Col>
									{!!_.get(facility, "inspectable.shape") && (
										<Col>
											<Shape {..._.get(facility, "inspectable")} />
										</Col>
									)}
								</Row>
							</View>
						]}
					/>
					<Aside>
						<ActivityTab queryStringParameters={queryStringParameters} />
					</Aside>
				</React.Fragment>
			);
		}
		return <LoadingState />;
	}
}

export default withRouter(Facility);
