import React from 'react';
import Datasource, { DatasourceResponse } from "lib/datasource";
import { LoadingState } from "components/Shared";
import moment from "moment";
import { Link } from 'react-router-dom';
import { useEffect, useState, ChangeEvent, FormEvent, useRef, useCallback } from 'react';

interface SearchModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const SearchModal: React.FC<SearchModalProps> = ({ isOpen, closeModal }) => {
  const initialRender = useRef(true);
  const [, renderTrigger] = useState(moment());
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchList, setSearchList] = useState([]);
  const [filter, setFilter] = useState('All Categories');
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [datasource] = useState(new Datasource({ mainModelName: "document", renderTrigger: renderTrigger }));
  const modalRef = useRef<HTMLDivElement>(null);

  const filters = [
    {
      'filter_name': 'All Categories',
      'filter_icon': 'fal fa-th'
    },
    {
      'filter_name': 'Project',
      'filter_icon': 'fal fa-cogs'
    }, 
    {
      'filter_name': 'Task',
      'filter_icon': 'fal fa-list'
    },
    {
      'filter_name': 'Property',
      'filter_icon': 'fal fa-home'
    },
    {
      'filter_name': 'Document',
      'filter_icon': 'fal fa-file'
    },
    {
      'filter_name': 'Portfolio',
      'filter_icon': 'fal fa-archive'
    },
    {
      'filter_name': 'Comment',
      'filter_icon': 'fal fa-comments'
    },
    {
      'filter_name': 'Inspection',
      'filter_icon': 'fal fa-search'
    },
    {
      'filter_name': 'Issue',
      'filter_icon': 'fal fa-paperclip'
    }
  ];

  useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
      setSearchList(response.jsonApiResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    datasource.get("v2", `search?query=${searchTerm}&filter=${filter}`, {})
              .finally(() => {
                setIsLoading(false);
              })
  };

  const handleFilterClick = (event: React.MouseEvent, index: any) => {
    event.preventDefault()
    setFilter(filters[index].filter_name);
    setActiveButtonIndex(index);
  }

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  }, [closeModal]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return
    } else {
      if (!isLoading) setIsLoading(true);

      const delayDebounceFn = setTimeout(() => {
        handleSubmit({ preventDefault: () => {} } as FormEvent<HTMLFormElement>);
      }, 500); // Adjust the debounce delay as needed (in milliseconds)

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
    // eslint-disable-next-line
  }, [searchTerm, filter]);

  const truncate_comment_body = (body: string, limit: number = 50) => {
    // HTML tag removal
    const sanitized_body = body.replace(/<[^>]*>?/gm, '');

    // Truncate
    const index = sanitized_body.indexOf(searchTerm);
    const start_index = Math.max(0, index - limit);
    const end_index = Math.min(sanitized_body.length, index + limit);

    let truncate_result = start_index === 0 ? "" : "...";
    truncate_result += sanitized_body.substring(start_index, end_index);
    truncate_result += end_index !== sanitized_body.length ? "..." : "";
    
    return truncate_result
  }

  return (
    <form className='relative'>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="fixed left-0 top-0 w-100/100 h-screen bg-black-100 bg-opacity-80"></div>
        <div className="absolute bg-white rounded-lg shadow-lg top-1 w-1/2 z-10" ref={modalRef}>
          <div className="relative">
            <div className="flex items-center gap-4 pl-5">
              <i className="fal fa-search fa-2x text-black-100"></i>
              <input type="text" placeholder="Search..." value={searchTerm} onChange={handleInputChange} autoFocus={isOpen} className='w-100/100 rounded h-15 text-black-100' />
            </div>
              <div className='absolute bg-white border-1 rounded-2 w-100/100 z-10 mt-1'>
                <div className="flex rounded-2 overflow-hidden">
                  <div className="search-filter px-3 py-5 w-3/6 bg-black-5">
                    <ul className='flex flex-column gap-2'>
                      {filters.map((button, index) => (
                        <li key={index} className={activeButtonIndex === index ? 'px-2 py-2 rounded active': 'px-2 py-2 rounded'}>
                          <button className='font-bold text-black-100' onClick={(event) => handleFilterClick(event, index)}>
                            <i className={`${button.filter_icon} mr-2`}></i>
                            {button.filter_name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className='py-5 w-100/100 search-results'>
                    {isLoading ? (
                      <div className="flex items-center justify-center">
                        <LoadingState />
                      </div>
                    ) : (
                      <>
                        <span className='px-3 font-medium text-13 text-black-60'>
                        {
                          filter === "All Categories" ?
                            "Top 5 results, click on the section to left to see more" : "All search results"
                        }
                        </span>
                        
                        <ul>
                          {searchList.length === 0 ? (
                            <li className='px-3 py-1 '>No results found!</li>
                          ): searchList.map((category) => {
                            return (
                              <>
                                {
                                  (category["model_data"] as any).length > 0 &&
                                    filter === "All Categories" &&
                                  <div className="line-with-category-text">
                                    <span className="line"></span>
                                    <span className="text">{category["model_type"]}</span>
                                    <span className="line"></span>
                                  </div>
                                }

                                { category["model_type"] === 'Project' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const id = item['id'];
                                    const url = `/projects/${id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-cogs mr-2'></i>
                                        {item['name']}
                                      </Link>
                                    </li>;
                                  })
                                } 

                                { category["model_type"] === 'Task' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const id = item['id'];
                                    const url = `/tasks/${id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-list mr-2'></i>
                                        { item['project_name'] !== null &&
                                          <span style={{ color: 'grey' }}>
                                            {item['project_name']} {'> '}
                                          </span> 
                                        }
                                        {item['name']}
                                      </Link>
                                    </li>;
                                  })
                                }

                                { category["model_type"] === 'Property' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const id = item['id'];
                                    const url = `/properties/${id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-home mr-2'></i>
                                        {item['name']}
                                      </Link>
                                    </li>;
                                  })
                                }

                                { category["model_type"] === 'Document' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const id = item['id'];
                                    const url = `/documents/${id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-file mr-2'></i>
                                        {item['name']}
                                      </Link>
                                    </li>;
                                  })
                                }

                                { category["model_type"] === 'Portfolio' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const id = item['id'];
                                    const url = `/portfolios/${id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-archive mr-2'></i>
                                        {item['name']}
                                      </Link>
                                    </li>;
                                  })
                                }

                                { category["model_type"] === 'Comment' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const task_id = item['commentable_id'];
                                    const url = `/tasks/${task_id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-comments mr-2'></i>
                                        {truncate_comment_body(item["body"])}
                                      </Link>
                                    </li>;
                                  })
                                }

                                { category["model_type"] === 'Inspection' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const id = item['id'];
                                    const url = `/inspections/${id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-search mr-2'></i>
                                        {item['name']}
                                      </Link>
                                    </li>;
                                  })
                                }

                                { category["model_type"] === 'Issue' &&
                                  (category["model_data"] as any[]).map((item: any, index: number) => {
                                    const id = item['id'];
                                    const url = `/issues/${id}`;
                                    return <li key={index} className='px-3 py-1 hover:bg-black-5' onClick={closeModal}>
                                      <Link to={url} className='block font-bold'>
                                      <i className='fal fa-paperclip mr-2'></i>
                                        {item['name']}
                                      </Link>
                                    </li>;
                                  })
                                }

                              </>
                            )
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
          </div>
        </div>
        
      </div>
    </form>
  );
};

export default SearchModal;