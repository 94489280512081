import React, { useEffect, useState} from "react";
import { ListGroupControl, View, LoadingState } from "../../Shared";
import { ButtonDropDown } from '../ButtonDropDown';
import _ from 'lodash';
import PhaseReport from './PhaseReport';
import Datasource, { DatasourceResponse } from "lib/datasource";

const ProjectPhaseAnalyticsReport = ({properties}: { properties: any}) => {
	
	const [propertyNameSelected, setSelectedProperty] = useState({
		id: "",
		name: "Select a Property",
	});
	const [isProjectPhaseAnalysisLoading, setIsProjectPhaseAnalysisLoading] = useState(false);
	const [workflows, setWorkflows] = useState([] as any[]);
	const [userProjects, setUserProjects] = useState([] as any[])
    const [datasource] = useState(
		new Datasource({
			mainModelName: "workflow",
			sortBy: "lease_handover_at",
			includeRelationship: true
		})
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setWorkflows(response.normalizedMainModelResponse)
			setUserProjects(response.jsonApiResponse.current_user_projects)
		}
			
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	const getProjectPhaseAnalysisReport = (id: string) => {
		setIsProjectPhaseAnalysisLoading(true);
		datasource.get("v2", "advanced_reports/project_phase_analysis_report", {
			queryStringParameters: {
				property_id: id
			}
		})
		.finally(() => setIsProjectPhaseAnalysisLoading(false));
	}
	
	const callBack = (id: string) => {
		let property = _.find(properties, {'id': id});
		setSelectedProperty({
			id: property.id,
			name: property.name,
		});
		getProjectPhaseAnalysisReport(id);
		return;
	}

	return (
		<View className="flex flex-row">
			<ListGroupControl />
			<div className="flex items-center justify-between">
				<div className='flex items-center z-10'>
					<ButtonDropDown 
						buttonOption={propertyNameSelected}
						dropdownOptions={properties}
						callBack={(arg) => {
							callBack(arg);
						}}
						textClassNames="w-215 text-14 transition rounded-l-md text-left p-4"
						dropDownClassNames="bg-white border-borderColor border-l"
					/>
					<p className="text-reportProjectSubtitle text-14 ml-6">Choose the property you want to report on</p>
				</div>
			</div>
			
			{isProjectPhaseAnalysisLoading === false && propertyNameSelected.id && workflows ? (
				<>
					<div className="-mx-4 bg-blue-10 text-blue-darker text-20 h-215 mt-4 font-bold py-4 px-8">
						Property: {propertyNameSelected.name}
					</div>
					<PhaseReport workflows={workflows} userProjects={userProjects}/>
				</>
			) : ''}

			<div>
				{isProjectPhaseAnalysisLoading === true && <LoadingState />}
			</div>
		</View>
	);
};
export default ProjectPhaseAnalyticsReport;
