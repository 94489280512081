import React from "react";
import validate from "validate.js";
import { Show as Avatar } from "components/Avatar";
import { Breadcrumbs, Header, Title, View, LoadingState, Button } from "components/Shared";
import { Input } from "components/Inputs";
import { connect } from "react-redux";
import { getProperties, putProperty } from "actions";
import _ from "lodash";
import LocalNotification from "lib/Notification";
import { withRouter } from "react-router-dom";

const constraints = {
	name: {
		presence: {
			message: "^ is required"
		}
	}
};

class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { getProperties, routeParams, property } = this.props;
		getProperties(routeParams);
		if (property) {
			this.setState(property);
		}
	}

	onSubmit() {
		const { putProperty, history } = this.props;
		const { name, id, avatar, cover, status, valuation_cents, kind, company, owned_by, managed_by } = this.state;

		const data = {
			id,
			name,
			avatar,
			cover,
			status,
			valuation_cents,
			kind,
			company_id: _.get(company, "id", ""),
			owned_by_id: _.get(owned_by, "id", ""),
			managed_by_id: _.get(managed_by, "id", "")
		};

		const errors = validate(data, constraints);
		if (errors) {
			this.setState({ errors });
		} else {
			putProperty(data).then(() => {
				new LocalNotification("property_updated", "property_updated", {
					type: "success"
				});
				history.push(`/properties/${id}`);
			});
		}
	}

	render() {
		const { name, avatar } = this.state;
		if (name) {
			return (
				<React.Fragment>
					<Breadcrumbs items={[{ title: `${name}` }]} />
					<Header avatar={<Avatar url={avatar} name={name} />}>
						<Title>{name}</Title>
					</Header>
					<View resource="property" hideTitle>
						<Input defaultState={this.state} name="name" type="text" onChange={name => this.setState({ name })} />
						<Input defaultState={this.state} name="avatar" type="text" onChange={avatar => this.setState({ avatar })} />
						<Input defaultState={this.state} name="cover" type="text" onChange={cover => this.setState({ cover })} />
						<hr />
						<Input
							defaultState={this.state}
							name="status"
							options={["construction", "development", "operational"]}
							onChange={status => this.setState({ status })}
						/>
						<Input
							defaultState={this.state}
							name="valuation_cents"
							type="money"
							onChange={valuation_cents => this.setState({ valuation_cents })}
						/>
						<Input
							defaultState={this.state}
							name="kind"
							options={_.sortBy([
								"commercial",
								"major_regional",
								"subregional",
								"university",
								"airport",
								"cbdretail",
								"neighbourhood"
							])}
							onChange={kind => this.setState({ kind })}
						/>
						<Input
							defaultState={this.state}
							name="phone_number"
							type="tel"
							onChange={phone_number => this.setState({ phone_number })}
						/>
						<hr />
						<Input
							defaultState={this.state}
							name="company"
							endpoint={"companies"}
							onChange={company => this.setState({ company })}
						/>
						<Input
							defaultState={this.state}
							name="managed_by"
							endpoint={"companies"}
							onChange={managed_by => this.setState({ managed_by })}
						/>
						<Input
							defaultState={this.state}
							name="owned_by"
							endpoint={"companies"}
							onChange={owned_by => this.setState({ owned_by })}
						/>
						<Input
							defaultState={this.state}
							name="operated_by"
							endpoint={"companies"}
							onChange={operated_by => this.setState({ operated_by })}
						/>
						{/* <Address resource={property} /> */}
						<Button onClick={() => this.onSubmit()} />
					</View>
				</React.Fragment>
			);
		}
		return <LoadingState />;
	}
}

const PropertyEdit = withRouter(Edit);

const mapStateToProps = (state, props) => {
	const { properties } = state;
	const { id } = props.match.params;
	const property = _.find(properties, { id });
	return { property };
};

const mapDispatchToProps = dispatch => ({
	getProperties: () => dispatch(getProperties()),
	putProperty: property => dispatch(putProperty(property))
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyEdit);
