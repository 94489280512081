import React, { memo } from "react";
import { Circle as Logo } from "./icons";

const Spinner = ({
	tcp = true,
	width,
	height,
	className
}: {
	tcp?: boolean;
	width?: string;
	height?: string;
	className?: string;
}) => {
	return <Logo spin width={width} height={height} className={className} hideTCP={!tcp} />;
};

export default memo(Spinner);
