import _ from "lodash";

export const PhaseHelper = (reports: any) => {
    const phases = [];
    const arrayOfPhases = reports.projects[0].phases;
    for(let i=0; i<arrayOfPhases.length; i++){
        phases.push(arrayOfPhases[i].phase_name);
    }
    return phases;
}

export const TaskHelper = (reports: any) => {
    const arrayOfTasks = [];
    let tasks = [];
    const arrayOfPhases = reports.projects[0].phases;
    for(let i=0; i<arrayOfPhases.length; i++){
        for(let j=0; j<arrayOfPhases[i].tasks.length; j++){
            tasks.push(arrayOfPhases[i].tasks[j]);
        }
        arrayOfTasks.push(tasks);
        tasks = [];
    }
    return arrayOfTasks;
}

export const PropertyList = (properties: any) => {
    const propertyList: any = [];
    _.each(properties, property => {
        let row = {
            "id": property.id,
            "name": property.attributes.name ? property.attributes.name : '',
        };
        propertyList.push(row);
    });

    return propertyList;

}

  
  
  
  
  
  
  
  
  
  
  