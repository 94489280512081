import React, {useState} from "react";
import { ListGroupControl, View, LoadingState } from "../../Shared";
import { ButtonDropDown } from '../ButtonDropDown';
import _ from 'lodash';
import {PropertyStatsAnalytics, ProjectStatsAnalytics} from './PropertyStatsHelper';
import axios from "v2/utils/axios";

const ProjectCollection = ({properties}: { properties: any}) => {
	
	const [propertyNameSelected, setSelectedProperty] = useState({
		id: "",
		name: "Select a Property",
	});
	const [TeamAnalysis, setTeamAnalysis] = useState([]);
	const [isTeamAnalaysisLoading, setIsTeamAnalaysisLoading] = useState(false);
	const [monthSelected, setMonthSelected] = useState(
		{
			id: "6",
			name: "6 months",
		}
	);

	const periodOfProjects = [
		{
			id: "3",
			name: "3 months",
		},
		{
			id: "6",
			name: "6 months",
		},
		{
			id: "12",
			name: "12 months",
		},
		{
			id: "24",
			name: "24 months",
		}
	]

	const getTeamAnalysisReport = (id: string, monthPeriod: string) => {
		setIsTeamAnalaysisLoading(true);
		const path = `advanced_reports/team_analysis_report?property_id=${id}&months=${monthPeriod}`;
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}${path}`)
			.then((res) => {
				setTeamAnalysis(res.data)
			})
			.catch((e) => {})
			.finally(() => setIsTeamAnalaysisLoading(false));
	}

	let tempProjectAnalyticsReport = Object.values(TeamAnalysis);
	let projectAnalyticsReport: any = tempProjectAnalyticsReport[0];
	
	const callBack = (id: string) => {
		let property = _.find(properties, {'id': id});
		setSelectedProperty({
			id: property.id,
			name: property.name,
		});
		getTeamAnalysisReport(id, monthSelected.id);
		return;
	}

	const fetchReportUsingMonths = (reportId: string) => {
		if(reportId === '3'){
			setMonthSelected(
				{
					id: '3',
					name: '3 months',
				},
			)
		}
		else if (reportId === '6'){
			setMonthSelected(
				{
					id: '6',
					name: '6 months',
				},
			)
		}
		else if (reportId === '12'){
			setMonthSelected(
				{
					id: '12',
					name: '12 months',
				},
			)
		}
		else {
			setMonthSelected(
				{
					id: '24',
					name: '24 months',
				},
			)
		}
		getTeamAnalysisReport(propertyNameSelected.id, reportId);
		return;

	}

	return (
		<View className="flex flex-row">
			<ListGroupControl />
			<div className="flex items-center justify-between">
				<div className='flex items-center'>
					<ButtonDropDown 
						buttonOption={propertyNameSelected}
						dropdownOptions={properties}
						callBack={(arg) => {
							callBack(arg);
						}}
						textClassNames="w-215 text-14 transition rounded-l-md text-left p-4"
						dropDownClassNames="bg-white border-borderColor border-l"
					/>
					<p className="text-reportProjectSubtitle text-14 ml-6">Choose the property you want to report on</p>
				</div>
				<div className='flex items-center'>
					<p className="text-reportProjectSubtitle text-14 mr-6">Projects created in the last</p>
					<ButtonDropDown 
							buttonOption={monthSelected}
							dropdownOptions={periodOfProjects}
							callBack={(arg) => {
								fetchReportUsingMonths(arg);
							}}
							textClassNames="w-215 text-14 transition rounded-l-md text-left p-4"
							dropDownClassNames="bg-white border-borderColor border-l"
						/>
				</div>
			</div>
			{isTeamAnalaysisLoading === false && propertyNameSelected.id && projectAnalyticsReport ? (<div className="-mx-4 bg-blue-10 text-blue-darker text-20 h-215 mt-4 font-bold py-4 px-8">
				Property: {propertyNameSelected.name}
			</div>) : ''}
			<div>
				{isTeamAnalaysisLoading ===false ? projectAnalyticsReport && (
					<div>
						<div className="my-8">
							<PropertyStatsAnalytics PropertyStats={projectAnalyticsReport.property_stats}/>
						</div>
						<div>
							<ProjectStatsAnalytics projectStats={projectAnalyticsReport.project_stats}/>
						</div>
					</div>
				): <LoadingState />}
			</div>
		</View>
	);
};
export default ProjectCollection;
