import { LoadingState } from "components/Shared";
import { UserContext } from "context";
import { UnprotectedLayout } from "layouts";
import { getAuthTokenFromCookie, useSubscribeChannel } from "lib/hooks";
import { default as React } from "react";
import { Redirect, Route } from "react-router-dom";
import { useCurrentUserForSmidge } from "v2/hooks";
import { ProtectedLayout } from "v2/layouts";
import { useCreateCable, useCurrentUserInfo } from "./hooks";
// import { ProtectedLayout } from "layouts";

const ProtectedRoute = (props: any) => {
	const [currentUser, authError] = useCurrentUserInfo();
	const [cable, cableCreationError] = useCreateCable();
	useSubscribeChannel("AppearanceChannel", () => {});
	useCurrentUserForSmidge();
	const authCookie = getAuthTokenFromCookie();
	if (!!currentUser && !!cable && !cableCreationError && !authError && !!authCookie) {
		return (
			<UserContext.Provider value={{ cable, currentUser }}>
				<ProtectedLayout key="protected-layout">
					<Route {...props} />
				</ProtectedLayout>
			</UserContext.Provider>
		);
	} else if (!!authError || !authCookie) {
		return (
			<UnprotectedLayout key="unprotected-layout">
				<Redirect
					to={{
						pathname: "/signin",
						state: { from: window.location.href, reason: 'unauthorized', reload: true }
					}}
				/>
			</UnprotectedLayout>
		);
	}
	return <LoadingState />;
};

export default ProtectedRoute;
