import React from "react";
import { translate } from "lib";
import { ErrorBoundary } from "components/Shared";
import Actor from "components/Activities/Actor"
import Subject from "components/Activities/Subject"
import ItemObject from "components/Activities/ItemObject"

const DeleteParty = ({ activity }: { activity: tcpinpoint.ActivityItem }) => <ErrorBoundary>
    <Actor actor={activity.attributes.item.actor} />{' '}
    {translate('removed')}{' '}
    <ItemObject object={activity.attributes.item.object} />{' '}
    {translate('from')}{' '}
    <Subject subject={activity.attributes.item.subject} />{' '}

</ErrorBoundary>

export default DeleteParty;