import React, { memo } from "react";
import { Flyout, Text, Date, OpenGraph } from "components/Shared";
import classNames from "classnames";
import { Show as Avatar } from "components/Avatar";
import { translate } from "lib";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import User from "components/Users/Inline";
interface Props {
	comment: tcpinpoint.Comment;
	collection?: tcpinpoint.Comment[];
	onReply: any;
	onDelete?: any;
	onEdit?: any;
}

const Comment = ({ collection, comment, onReply, onDelete }: Props) => {
	if (!!comment) {
		const replies = _.filter(collection, { reply_to_id: comment.id });
		return (
			<React.Fragment>
				<div
					id={`comment-${comment.id}`}
					className={classNames("list-group-item", "comment", {
						deleted: !!comment.deleted_at,
						"is-reply": !!comment.reply_to_id,
						"comment-anonymous": !comment.created_by
					})}
				>
					{!!comment.deleted_at ? (
						<div className="list-group-item-deleted">
							Comment by <User user={comment.created_by} /> deleted <Date date={comment.deleted_at} />
						</div>
					) : (
							<React.Fragment>
								<div className="list-group-item-avatar">
									<Avatar url={comment.created_by.avatar} name={comment.created_by.name} />
								</div>
								<div className="list-group-item-body">
                  {
                    // This is used to allow multiple links (instead of 1) displayed in comment when uploads from TCP Outlook addin
                    // As there is no restriction on how many documents can be uploaded via TCP Outlook addin
                    Array.isArray(comment.metadata) ? 
                    comment.metadata.map((each_metadata: any) => {
                      return <OpenGraph metadata={each_metadata} />
                    }) : <OpenGraph metadata={comment.metadata} />
                  }
									<Text>{`${comment.body}`.replace(/&amp;#x(\d+);/g, (match, dec) => String.fromCharCode(dec))}</Text>
									<p className="byline">
										<span>
											{translate("posted_by")} {" "}
											<User user={comment.created_by} /> {" "}
											<Date date={comment.created_at} showTime showRelative />
										</span>
										{!!onReply && (
											<span>
												<a href="#reply" onClick={() => onReply(comment)}>
													<FontAwesomeIcon icon={faReply} /> {translate("reply")}
												</a>
											</span>
										)}
									</p>
								</div>
								<Flyout
									menu={[
										<button onClick={() => onDelete(comment)}>
											<i className="fas fa-fw fa-delete" />
											{translate("delete")}
										</button>
									]}
								/>
							</React.Fragment>
						)}
				</div>
				{_.map(_.compact(replies), reply => (
					<Comment key={reply.id} comment={reply} collection={collection} onReply={() => onReply(reply)} />
				))}
			</React.Fragment>
		);
	}
	return null;
};

export default memo(Comment);
