import React, { useState, memo } from "react";

import _ from "lodash";

import Modal from "react-modal";
import { Icon } from "components/Documents";
import { ListGroup, Button, ModalHeader, ListGroupItem } from "components/Shared";
//@ts-ignore
import { saveAs } from 'file-saver';
// import { getFilenameFromPath } from "lib";
import axios from "v2/utils/axios";

interface Props {
	documents: tcpinpoint.Document[];
}

const Download = ({ documents }: Props) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [uuids, setUuids] = useState([] as string[]);

	const downloadSelected = () => {
		axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}documents/download-zip`, { keys: uuids })
			.then((res: any) => {
				// dispatch(postEvent("download", "documents", body));
				const path = res.data.path
				const filename = res.data.filename
				// saveAs(path, getFilenameFromPath(path));
				fetch(path)
					.then(res => res.blob())
					.then(blob => saveAs(blob, filename))
			});
	};


	return (
		<div className="documents-download">
			<Button small kind="download" onClick={() => setModalOpen(true)} />
			<Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} contentLabel="zip" className="zip-download-document-modal">
				<ModalHeader title="download" onRequestClose={() => setModalOpen(false)} />
				<section className="documents documents-list-view">
					{/* TODO - 'select all/ select none should be moved into a control component - this ZipModal component is a view-only component.  */}
					<div className="btn-group">
						<button className="btn" onClick={e => { e.preventDefault(); setUuids(_.map(documents, "id")); }} > Select All </button>
						<button className="btn" onClick={e => { e.preventDefault(); setUuids([]); }} > Select None </button>
					</div>
					<ListGroup
						resource="document"
						className="document-items download-documents"
						collection={documents}
						renderRow={document =>
							<ListGroupItem
								icon={<Icon {...{ document }} />}
								heading={document.name}
								selected={_.includes(uuids, document.id)}
								onSelect={() => setUuids(_.concat(uuids, document.id))}
								onUnselect={() => setUuids(_.filter(uuids, uuid => uuid !== document.id))}
							/>

						}
					/>
				</section>
				<footer>
					<Button disabled={!uuids.length} onClick={downloadSelected} kind="download" />
				</footer>
			</Modal>
		</div>
	);
};

export default memo(Download);
