import Icon from "components/Activities/Icon";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { CloseProject, RescheduleProject } from "../Items";
import ActivityItemDom from "./ActivityItemDom";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityProjectItem = ({ activity }: Props) => {
	const props = { key: activity.attributes.item.id, activity };
	let link = "/#";
	let output;;
	let icon;

	switch (activity.attributes.item.type) {
		case "Close":
			icon = Icon("close-project");
			link = `/projects/${_.get(activity.attributes.item.subject, "id")}`;
			output = <CloseProject {...props} />;
			break;
		case "Reschedule":
			icon = Icon("reschedule-project");
			link = `/projects/${_.get(activity.attributes.item.context, "id")}`;
			output = <RescheduleProject {...props} />;
			break;			
	}

	return (
		<Link to={link} className="activity-item-link">
			{" "}
			<ActivityItemDom activity={activity} icon={icon} link={link} output={output} />
		</Link>
	);
};

export default memo(ActivityProjectItem);
