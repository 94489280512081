import classNames from "classnames";
import { Inline as Property } from "components/Properties";
import { Status } from "components/Shared";
import { UserContext } from "context";
import { icons, translate } from "lib";
import _ from "lodash";
import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";

interface Props {
	showProperty: boolean;
	project: tcpinpoint.Project;
}

/**
 * Render a single Project for a List Group
 * @param {props} Project
 * @param {props} showProperty Should show Property context (Property and Tenancy Name)
 */
const Project = ({ showProperty, project }: Props) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const startedByUser = currentUser.id === project.created_by_id;
	const badge = !!startedByUser ? translate("mine") : <i className={`far fa-${icons.watching}`} />;
	const leaseStatus = _.replace(translate(_.get(project, "lease_status")), "unknown", "");
	const parent = !!showProperty && <Property property={project.property_summary} />;
	const heading = project.name || _.get(project, "tenant_name", false) || "Unnamed";
	const subheading = _.compact([
		!!showProperty ? _.get(project, "tenancy_name") !== project.name && _.get(project, "tenancy_name") : null,
		_.get(project, "building_name") ? ` at ${_.get(project, "building_name")}` : null,
	]).join("");
	const dateKey = project.closed ? "archived_after" : project.complete ? "completed_in" : "open_for";
	const daysCount = project.closed
		? project.days_to_archived
		: project.complete
		? project.days_to_completed
		: project.days_active;
	const dateValue = `${daysCount} ${daysCount > 1 ? "days" : "day"}`;
	const projectClassNames = classNames([
		"project",
		{
			"project-open": !!project.open,
			"project-closed": !!project.closed,
			"project-complete": !!project.complete,
			"project-incomplete": !!project.incomplete,
			"project-ahead": !!project.ahead,
			"project-behind": !!project.behind,
			"project-deleted": !!project.deleted,
		},
		"list-group-item",
	]);

	return (
		<>
			<Link
				to={`/projects/${_.get(project, "id")}`}
				className={projectClassNames}
				data-heading={heading}
				data-subheading={subheading}
			>
				<div className="stamp">
					<Status closed={!!project.closed} incomplete={!project.complete} is_defects={project.is_defects} />
				</div>
				<span className="list-group-item-content">
					{(!!heading || !!subheading) && (
						<div className="list-group-item-heading">
							{!!heading && <h3>{heading}</h3>}
							{!!subheading && <h6>{subheading}</h6>}
						</div>
					)}
					{!!parent && <div className="list-group-item-parent">{parent}</div>}
				</span>
				{/* Lease Status */}
				<div className="list-group-item-statistics">
					<h6>{leaseStatus}</h6>
				</div>
				{/* Open / Completed / Closed Days */}
				<div className="list-group-item-datapoints">
					<div data-key={dateKey} className="list-group-item-datapoint">
						<h6>{translate(dateKey)}</h6>
						{dateValue}
					</div>
				</div>
				<div className="badge">{badge}</div>
				<div className="disclosure">
					<i className="fas fa-angle-right" />
				</div>
			</Link>
		</>
	);
};

export default memo(Project);
