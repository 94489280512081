import React from "react";
// @ts-ignore
import validate from "validate.js";
import { View, EmptyState, Button } from "components/Shared";
import { Input, PlainTextInput } from "components/Inputs";
// @ts-ignore
import uuidv5 from "uuid/v5";

const constraints = {};

class Facilities extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      facilities: []
    };
  }
  setStateFromProps(props) {
    const { facilities } = props;
    this.setState({ facilities });
  }

  componentDidMount() {
    this.setStateFromProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setStateFromProps(props);
  }

  isValid() {
    return validate(this.state, constraints) === undefined;
  }

  addFacility(event) {
    event.preventDefault();
    if (this.isValid()) {
      const { onUpdate } = this.props;
      const { facilities } = this.state;
      facilities.push({
        id: uuidv5("tcpinpoint.com", uuidv5.DNS),
        description: this.state.description,
        name: this.state.name
      });
      this.setState({ facilities }, () => onUpdate(this.state.facilities));
    } else {
      this.setState({ errors: validate(this.state, constraints) });
    }
  }

  removeFacility(facility) {
    const { onUpdate } = this.props;
    const { facilities } = this.state;
    const newFacilities = facilities.filter(f => f.id !== facility.id);
    this.setState({ facilities: newFacilities }, onUpdate(newFacilities));
  }

  render() {
    const { facilities } = this.state;

    const output = (
      <View resource="facilities">
        <ul className="facilities list-group">
          {facilities && facilities.length ? (
            facilities.map(facility => (
              <li className="list-group-item facility" key={facility.name}>
                <h4 className="list-group-item-heading">{facility.name}</h4>
                <div dangerouslySetInnerHTML={{ __html: facility.description }} />
                <table className="facility-attributes table">
                  {facility.attributes &&
                    facility.attributes.map(attribute => (
                      <tr>
                        <td className="facility-attribute-name">{attribute.name}</td>
                        <td className="facility-attribute-units">{attribute.units}</td>
                      </tr>
                    ))}
                </table>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={event => this.removeFacility(facility)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </li>
            ))
          ) : (
              <EmptyState resource="facilities" />
            )}
        </ul>
        <hr />
        <fieldset>
          <legend>Add Facility</legend>
          <Input
            defaultState={this.state}
            label="Name"
            name="name"
            onChange={name => this.setState({ name })}
            type="email"
          />
          <PlainTextInput
            defaultState={this.state}
            label="Description"
            name="description"
            onChange={description => this.setState({ description })}
            type="email"
          />
          <Button kind="add" onClick={event => this.addFacility(event)} />
        </fieldset>
      </View>
    );
    return output;
  }
}

export default Facilities;

Facilities.contextTypes = {};

Facilities.propTypes = {};
