import { Input, PlainTextInput } from "components/Inputs";
import { Lookup as RoleLookup } from "components/Roles";
import { Button, LoadingState, ModalHeader } from "components/Shared";
import { LastMomentTriggerAPIContext } from "context";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import "moment-business-days";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
//@ts-ignore
import validate from "validate.js";
import RescheduleInput from "./RescheduleInput";

const constraints = {
	name: {
		presence: true
	},
	length: {
		presence: true
	}
};

const EditTask = ({ isOpen, doCloseModal, task }: { isOpen: any; doCloseModal: any; task: any }) => {
	const { setLastMoment: setTaskLastMoment } = useContext(LastMomentTriggerAPIContext);
	const taskId = useMemo(() => _.get(task, "id"), [task]);
	const [state, setState] = useState({ ...task });
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "task", renderTrigger: renderTrigger }));

	const onSubmit = useCallback(() => {
		const errors = validate(state, constraints);
		if (errors) {
			setState((previousState: any) => ({ ...previousState, errors }));
		} else {
			setState((previousState: any) => ({ ...previousState, errors: {} }));
			if (!_.isNil(taskId)) datasource.put("v2", `tasks/${taskId}`, { body: state }, { updateIsLoding: true });
		}
	}, [datasource, taskId, state]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) {
				setState((previousState: any) => ({ ...previousState, ...response.normalizedMainModelResponse }));
				setTaskLastMoment(moment());
				doCloseModal();
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, setState, setTaskLastMoment, doCloseModal]);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			onRequestClose={doCloseModal}
			className="task-edit-modal"
			contentLabel="Task Edit Modal"
		>
			<ModalHeader title="edit_task" onRequestClose={doCloseModal} />
			{datasource.isLoading && <LoadingState />}
			{!datasource.isLoading && (
				<>
					<section>
						<Input
							defaultState={state}
							required={constraints}
							name="name"
							onChange={(name: string) => setState({ ...state, name })}
						/>
						<RoleLookup
							defaultState={state}
							required={constraints}
							label="responsible_role"
							collection="roles"
							onChange={(role: any) => {
								setState({ ...state, role_id: _.get(role, "id"), role });
							}}
						/>
						<label style={{ fontSize: "13px" }}>{translate("due_date")}</label>
						<RescheduleInput
							task={state as tcpinpoint.Task}
							onChange={(task: tcpinpoint.Task) => setState({ ...state, ...task })}
						/>
						<hr />

						<PlainTextInput
							defaultState={state}
							required={constraints}
							name="description"
							rows={12}
							onChange={(description: string) => setState({ ...state, description })}
						/>
					</section>
					<footer>
						<Button onClick={onSubmit} />
					</footer>
				</>
			)}
		</Modal>
	);
};

export default memo(EditTask);
