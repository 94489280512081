import Icon from "components/Activities/Icon";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { CreateDocument, Delete, DownloadDocument, ViewDocument } from "../Items";
import ActivityItemDom from "./ActivityItemDom";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityDocumentItem = ({ activity }: Props) => {
	const props = { key: activity.attributes.item.id, activity };
	let link = `/documents/${_.get(activity.attributes.item.object, "id")}`;
	let output;
	let icon;

	switch (activity.attributes.item.type) {
		case "Read":
			icon = Icon("view");
			output = <DownloadDocument {...props} />;
			break;
		case "View":
			icon = Icon("view");
			output = <ViewDocument {...props} />;
			break;
		case "Delete":
			icon = Icon("delete-document");
			output = <Delete {...props} />;
			break;
		case "Create":
			icon = Icon("create-document");
			output = <CreateDocument {...props} />;
			break;
	}

	return (
		<Link to={link} className="activity-item-link">
			{" "}
			<ActivityItemDom activity={activity} icon={icon} link={link} output={output} />
		</Link>
	);
};

export default memo(ActivityDocumentItem);
