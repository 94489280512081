import { createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "reducers/index.ts";
import { applyMiddleware, compose } from "redux";
import createMemoizeMiddleware from 'redux-memoize';
import { ttl } from 'actions/memoize';
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: process.env.NODE_ENV === "development", traceLimit: 25 })) ||
  compose;
const middlewares = [
  createMemoizeMiddleware({ ttl }),
  thunk
];
const store = createStore(rootReducer,
  /* preloadedState, */
  composeEnhancers(applyMiddleware(...middlewares)));
export { store };

