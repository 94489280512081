import React from "react";

export const PropertyStatsAnalytics = ({ PropertyStats }: {PropertyStats: any}) => {
    let averageDuration = '';
    if(PropertyStats.average_project_duration){
        averageDuration = PropertyStats.average_project_duration.split(' ');
    }
    return (
        <>
            {PropertyStats ? (
                <div className="flex">
                    <div className="flex flex-col items-center w-190">
                        <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                            Open
                        </span>
                        <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                            {PropertyStats.open_projects}
                        </span>
                        <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                            {PropertyStats.open_projects === 1 ? 'Project' : 'Projects'}
                        </span>
                    </div>
                    <div className="flex flex-col items-center w-190">
                        <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                            Started
                        </span>
                        <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                            {PropertyStats.projects_started_in_scoped_period}
                        </span>
                        <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                            Last {PropertyStats.scoped_period} {PropertyStats.scoped_period === 1 ? 'Month' : 'Months'}
                        </span>
                    </div>
                    <div className="flex flex-col items-center w-190">
                        <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                            Completed
                        </span>
                        <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                            {PropertyStats.projects_completed_in_period}
                        </span>
                        <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                            Last {PropertyStats.scoped_period} {PropertyStats.scoped_period === 1 ? 'Month' : 'Months'}
                        </span>
                    </div>
                    <div className="flex flex-col items-center w-190">
                        <span className="text-16 font-normal text-reportProjectSubtitle text-center flex-wrap">
                            Completed On-Time
                        </span>
                        <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                            {PropertyStats.projects_completed_on_time.percentage}
                        </span>
                        <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                            {PropertyStats.projects_completed_on_time.ratio}
                        </span>
                    </div>
                    <div className="flex flex-col items-center w-190">
                        <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                            Completed Late
                        </span>
                        <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                            {PropertyStats.projects_late.percentage}
                        </span>
                        <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                            {PropertyStats.projects_late.ratio}
                        </span>
                    </div>
                    <div className="flex flex-col items-center w-190 ">
                        <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                            Average Duration
                        </span>
                        <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                            {PropertyStats.average_project_duration ? averageDuration[0] : averageDuration}
                        </span>
                        <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                            {PropertyStats.average_project_duration === '-' ? '' : PropertyStats.average_project_duration }
                        </span>
                    </div>
                    <div className="flex flex-col items-center w-190 ">
                        <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                            Handover Rescheduled
                        </span>
                        <span className="flex items-center justify-center h-80 w-80  my-3 rounded-full border-2 text-28 font-normal text-black-60">
                            {PropertyStats.projects_with_handover_rescheduled.percentage}
                        </span>
                        <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                            {PropertyStats.projects_with_handover_rescheduled.ratio}
                        </span>
                    </div>
                </div>
            ) : ''}
        </>
    )
}

export const ProjectStatsAnalytics = ({projectStats}: {projectStats: any}) => {
    return (
        <div>
            {projectStats.map((project: any) => {

                let duration = '';
                if(project.duration){
                    duration = project.duration.split(' ');
                }
                return (
                    <div>
                        <div className="-mx-4 flex items-center px-8 bg-reportProjectBar text-reportProjectTitle text-20 h-80 font-bold my-8">Project: {project.project_name}</div> 
                        <div className="flex">
                        <div className="px-2 w-150 flex flex-col items-center">
                                <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                                    Closed Tasks
                                </span>
                                <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                                    {project.tasks_closed_from_total.percentage}
                                </span>
                                <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                                    {project.tasks_closed_from_total.ratio}
                                </span>
                            </div>
                            <div className="px-2 w-150 flex flex-col items-center">
                                <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                                    Closed On-Time
                                </span>
                                <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                                    {project.tasks_closed_on_time.percentage}
                                </span>
                                <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                                    {project.tasks_closed_on_time.ratio}
                                </span>
                            </div>
                            <div className="px-2 w-150 flex flex-col items-center">
                                <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                                    Closed Early
                                </span>
                                <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                                    {project.tasks_closed_early.percentage}
                                </span>
                                <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                                {project.tasks_closed_early.ratio}
                                </span>
                            </div>
                            <div className="px-2 w-150 flex flex-col items-center">
                                <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                                    Closed Late
                                </span>
                                <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                                    {project.tasks_closed_late.percentage}
                                </span>
                                <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                                    {project.tasks_closed_late.ratio}
                                </span>
                            </div>
                            <div className="px-2 w-150 flex flex-col items-center">
                                <span className="text-16 font-normal text-reportProjectSubtitle text-center">
                                    Duration
                                </span>
                                <span className="flex items-center justify-center h-80 w-80 my-3 rounded-full border-2 text-28 font-normal text-black-60">
                                    {project.duration ? duration[0] : duration}
                                </span>
                                <span className="text-14 font-normal text-reportProjectSubtitle text-center">
                                    {project.duration === '-' ? '' : project.duration}
                                </span>
                            </div>
                        </div>
                        <div className="mt-14 mb-8">
                            <ProjectTeamMemberStats teamMemberStats={project.team_member_stats}/>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const ProjectTeamMemberStats = ({teamMemberStats}: {teamMemberStats: any}) => {
    const tableHeading = ['Team Members', 'Total Comments', 'Total Documents', 'Closed On Time', 'Closed Early', 'Closed Late'];
    const memberClassName = "text-16 font-semibold text-black-70 text-center w-180";
    return (
        <>
            <ul className="flex">
                {tableHeading.map((subheading: any) => {
                    return (
                        <li className={`${subheading === 'Team Members' ? 'text-20 text-black text-left w-200 ' : 'text-16 text-reportProjectSubtitle text-center w-180'} font-normal px-4 py-2`}>
                            {subheading}
                        </li>
                    )
                })}
            </ul>
            {teamMemberStats.map((member:any) => {
                return (
                    <ul className="flex py-4 px-4">
                        <li className="text-16 font-semibold text-black-70 text-left w-200">
                            {member.name}
                        </li>
                        <li className={memberClassName}>
                            {member.total_comments}
                        </li>
                        <li className={memberClassName}>
                            {member.total_documents}
                        </li>
                        <li className={memberClassName}>
                            {member.closed_on_time}
                        </li>
                        <li className={memberClassName}>
                            {member.closed_early}
                        </li>
                        <li className={memberClassName}>
                            {member.closed_late}
                        </li>
                    </ul>
                )
            })}
        </>
    )
}