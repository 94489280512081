import React, { memo, useState } from "react";
import Modal from "react-modal";
import { PlainTextInput, Input } from "components/Inputs";
import { Lookup as RoleLookup } from "components/Roles";
import { Button, Row, Col, Title, ListGroup, ListGroupItem } from "components/Shared";
import { Modal as ConditionModal } from "components/Conditions";
import { Modal as DependencyModal } from "components/Dependencies";
import { translate } from "lib";
// @ts-ignore
import validate from "validate.js";
import _ from "lodash";

const constraints = {
	name: {
		presence: true
	},
	role: {
		presence: true
	},
	length: {
		numericality: {
			greaterThan: 0,
			onlyInteger: true
		},
		presence: true
	}
};

const TaskModal = ({
	task: defaultTask,
	isOpen,
	phase,
	template,
	onUpdate: defaultOnUpdate,
	deleteTask,
	doCloseModal
}: {
	task?: tcpinpoint.Task;
	isOpen: boolean;
	phase?: tcpinpoint.Phase;
	template?: tcpinpoint.Template;
	deleteTask?: (task: any) => any;
	onUpdate?: (task: any) => any;
	doCloseModal?: () => any;
}) => {
	const [task, setTask] = useState<any>({
		errors: null,
		start_with: false,
		name: "",
		description: "",
		length: "0",
		role: false,
		spectators: [],
		conditions: [],
		dependencies: [],
		confirmDelete: false,
		dependencyModal: false,
		conditionModal: false,
		...defaultTask
	});

	const onUpdate = () => {
		const errors = validate(task, constraints);

		if (errors) {
			setTask((task: any) => ({ ...task, errors }));
		} else {
			defaultOnUpdate && defaultOnUpdate(task);
			doCloseModal && doCloseModal();
		}
	};

	const { id, spectators, conditions, dependencies, dependencyModal, conditionModal, start_with, end_with } = task;
	const tasks = _.flatten(_.map(_.get(template, "body.phases", []), phase => phase.tasks));
	const isStartSet = _.find(tasks, { start_with: true });

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			onRequestClose={doCloseModal}
			className="task-edit-modal"
			contentLabel="Task Edit Modal"
		>
			<header>
				<div className="modal-header-body">
					<Title>{translate("edit_template_task_modal_header")}</Title>
				</div>
				<button type="button" className="close" aria-label="Close" onClick={doCloseModal}>
					<span aria-hidden="true">×</span>
				</button>
			</header>

			<section>
				<Row>
					<Col>
						<Input
							defaultState={task}
							required={constraints}
							name="name"
							onChange={(name: string) => setTask((task: any) => ({ ...task, name }))}
						/>
						<PlainTextInput
							defaultState={task}
							required={constraints}
							limit={300}
							name="description"
							hint="A reminder of what this task is about"
							onChange={(description: string) => setTask((task: any) => ({ ...task, description }))}
						/>
						<Input
							required={constraints}
							defaultState={task}
							name="length"
							min={1}
							step={1}
							suffix="calendar days"
							onChange={(length: string) => setTask((task: any) => ({ ...task, length }))}
							type="number"
							hint="Number of Calendar Days estimated to complete the task"
						/>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col>
						<fieldset>
							<RoleLookup
								defaultState={task}
								required={constraints}
								hint="The Role responsible for completing task."
								name="role"
								label="primary_responsibility"
								onChange={(role: any) => setTask((task: any) => ({ ...task, role }))}
							/>
							<RoleLookup
								label="Spectator Roles"
								required={constraints}
								hint="The spectator roles on the task"
								name="spectators"
								defaultState={task}
								onChange={(role: any) =>
									setTask((task: any) => {
										if (!_.find(task?.spectators, { name: role?.name })) {
											const spectators = [...task?.spectators];
											spectators.push(role);
											return { ...task, spectators };
										}
										return task;
									})
								}
								defaultOption={"null"}
							>
								<ListGroup
									resource="spectators"
									collection={spectators}
									renderRow={row => (
										<ListGroupItem
											key={row.id}
											subheading={row.name}
											onRemove={() => {
												setTask((task: any) => ({
													...task,
													spectators: _.filter(task?.spectators, spectator => spectator.name !== row.name)
												}));
											}}
										/>
									)}
								/>
							</RoleLookup>
						</fieldset>
					</Col>
					<Col>
						<fieldset id="dependencies">
							<label>{translate("dependencies")}</label>
							{(!isStartSet || _.get(isStartSet, "id") === id) && (
								<Input
									type="checkbox"
									defaultState={task}
									name="start_with"
									required={constraints}
									onChange={(start_with: any) => setTask((task: any) => ({ ...task, start_with }))}
								/>
							)}
							{!start_with && (
								<React.Fragment>
									<ListGroup
										resource="dependencies"
										collection={dependencies}
										onAdd={() => setTask((task: any) => ({ ...task, dependencyModal: true }))}
										renderRow={row => (
											<ListGroupItem
												key={row.id}
												heading={row.name}
												onRemove={() =>
													setTask((task: any) => ({
														...task,
														dependencies: _.filter(task?.dependencies, dependency => dependency.id !== row.id)
													}))
												}
											/>
										)}
									/>
									<DependencyModal
										isOpen={dependencyModal}
										doCloseModal={() => setTask((task: any) => ({ ...task, dependencyModal: false }))}
										onUpdate={(dependency: any) =>
											setTask((task: any) => {
												const deps = [...task?.dependencies];
												deps.push(dependency);
												return { ...task, dependencies: _.uniqBy(deps, "id") };
											})
										}
										task={task}
										template={template}
									/>
								</React.Fragment>
							)}
						</fieldset>
						<fieldset id="conditions">
							<label>{translate("conditions")}</label>
							<ListGroup
								resource="conditions"
								collection={conditions}
								onAdd={() => setTask((task: any) => ({ ...task, conditionModal: true }))}
								renderRow={row => (
									<ListGroupItem
										key={row.id}
										title={translate(row.kind)}
										heading={row.name}
										onRemove={() =>
											setTask((task: any) => ({
												...task,
												conditions: _.filter(task?.conditions, condition => condition.id !== row.id)
											}))
										}
									/>
								)}
							/>
							<ConditionModal
								isOpen={conditionModal}
								doCloseModal={() => setTask((task: any) => ({ ...task, conditionModal: false, condition: false }))}
								onUpdate={(condition: any) =>
									setTask((task: any) => {
										const cons = [...task?.conditions];
										cons.push(condition);
										return { ...task, conditions: cons };
									})
								}
							/>
						</fieldset>
					</Col>
				</Row>
			</section>
			<footer>
				<Button onClick={() => onUpdate()} />
				{!end_with && !start_with && id && (
					<Button kind="destroy" confirm onClick={() => deleteTask && deleteTask(task)} />
				)}
			</footer>
		</Modal>
	);
};

export default memo(TaskModal);
