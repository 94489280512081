import _ from "lodash";
import classNames from "classnames";
import Datasource, { DatasourceResponse } from "lib/datasource";
import LocalNotification from "lib/Notification";
import moment from "moment";
import Point from "./Point";
import React, { memo, useEffect, useReducer, useState } from "react";
import Reschedule from "./Reschedule";
import Step from "./Step";
import Steps from "./Steps";
import validate from "validate.js";
import { Board, Button } from "v2/components/shared";
import { Confirmation } from "v2/components/Projects";
import { LoadingState, View } from "components/Shared";
import { NewProjectContext } from "v2/context";
import { parse as parseUrl } from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Select as Lease } from "v2/components/Leases";
import { Select as Template } from "v2/components/Templates";
import { Select as Tenancy } from "v2/components/Tenancies";
import { Select as Tenant } from "v2/components/Tenants";
import { toSentenceSerial } from "underscore.string";
import { translate } from "lib";
import { v4 as uuidv4 } from "uuid";
import axios from "v2/utils/axios";

const constraints = {
	property: {
		presence: { message: "^Must select a Property", allowEmpty: false }
	},
	template: {
		presence: { message: "^Must select a Template", allowEmpty: false }
	}
} as any;

const SESSION_STORAGE_KEY = "cached_new_project";

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case "SET_TENANT":
			return { tenant: action.value, project: { ...state.project, name: _.get(action.value, ["trading_name"]) } };
		case "SET_PROJECT":
			return { ...state, project: action.value };
		default:
			return state;
	}
};

const useGetTemplate = (template_id: string) => {
	const [template, setTemplate] = useState<any>(null);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "template", renderTrigger: renderTrigger }));
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setTemplate(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		if (!_.isEmpty(template_id)) datasource.get("v2", `templates/${template_id}`, {});
	}, [datasource, template_id]);

	return [template, setTemplate];
};

const Create = ({ history, location }: RouteComponentProps) => {
	const { template_id } = parseUrl(location.search) as { template_id: string };
	const [current, setCurrent] = useState(template_id ? 1 : 0);
	const [tenancy, setTenancy] = useState<any>(null);
	const [lease, setLease] = useState<any>(null);
	const [deadlines, setDeadlines] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [rescheduledTasks, setRescheduledTasks] = useState([] as any[]);
	const [template, setTemplate] = useGetTemplate(template_id);
	const [viewState, setViewState] = useState(-1);
	const [state, dispatch] = useReducer(reducer, {
		tenant: null,
		project: { kind: "", name: "", reference: "", portfolio_ids: [] }
	});

	const { tenant, project } = state;
	const setTenant = (tenant: any) => dispatch({ type: "SET_TENANT", value: tenant });
	const setProject = (project: any) => dispatch({ type: "SET_PROJECT", value: project });

	const onSubmit = () => {
		setLoading(true);
		for(var i = 0; i < rescheduledTasks.length; i++){
			rescheduledTasks[i].due_at_fixed = true;
		}
		const payload = {
			template: {
				id: template.id
			},
			tenancy: {
				id: _.get(tenancy, "id"),
				floor_id: _.get(tenancy, "floor.id", null) || _.get(tenancy, "floor_id", ""),
				building_id: _.get(tenancy, "building.id", null) || _.get(tenancy, "building_id", ""),
				name: _.get(tenancy, "name", ""),
				area: _.get(tenancy, "area", ""),
				precinct: _.get(tenancy, "precinct", ""),
				division: _.get(tenancy, "division", ""),
				status: _.get(tenancy, "status", ""),
				kind: _.get(tenancy, "status", "")
			},
			tenant: {
				name: _.get(tenant, "name"),
				trading_name: _.get(tenant, "trading_name"),
				contact_details: _.get(tenant, "contact_details"),
				contact_name: _.get(tenant, "contact_name"),
				contact_email: _.get(tenant, "contact_email"),
				sector: _.get(tenant, "sector"),
				source: _.get(tenant, "source"),
				phone_number: _.get(tenant, "phone_number", "").replace(/[^0-9]/g, ""),
				full_time_equivalent: parseInt(_.get(tenant, "full_time_equivalent", 0), 10),
				business_code_id: _.get(tenant, "kind.id"),
				addresses: _.get(tenant, "addresses", [])
			},
			company: {
				name: _.get(tenant, "name")
			},
			property: {
				id: _.get(tenancy, ["property", "id"]),
				name: _.get(tenancy, ["property", "name"])
			},
			lease: {
				annual_review: _.get(lease, "annual_review", ""),
				base_rent_cents: !!_.get(lease, "base_rent_cents", 0) ? parseInt(_.get(lease, "base_rent_cents", 0), 10) : 0,
				base_rent_frequency: _.get(lease, "base_rent_frequency", ""),
				duration: _.get(lease, "duration", ""),
				ends_at: !!_.get(lease, "ends_at", false) && moment(_.get(lease, "ends_at", false)).format("YYYY-MM-DD"),
				fitout_contribution_cents: _.get(lease, "fitout_contribution_cents", 0),
				fitout_ends_at:
					!!_.get(lease, "fitout_ends_at", false) && moment(_.get(lease, "fitout_ends_at", false)).format("YYYY-MM-DD"),
				handover_at:
					!!_.get(lease, "handover_at", false) && moment(_.get(lease, "handover_at", false)).format("YYYY-MM-DD"),
				incentives: _.get(lease, "incentives", ""),
				kind: _.get(lease, "kind", ""),
				lease_renewals: parseInt(_.get(lease, "lease_renewals", 0), 10),
				net_lettable_area: _.get(lease, "net_lettable_area", ""),
				opening_at:
					!!_.get(lease, "opening_at", false) && moment(_.get(lease, "opening_at", false)).format("YYYY-MM-DD"),
				operational_outgoings_cents: parseInt(_.get(lease, "operational_outgoings_cents", 0), 10),
				permitted_use: _.get(lease, "permitted_use", ""),
				rent_per_sqm_cents: parseInt(_.get(lease, "rent_per_sqm_cents", 0), 10),
				rent_review_percent: parseInt(_.get(lease, "rent_review_percent", 0), 10),
				rent_starts_at:
					!!_.get(lease, "rent_starts_at", false) && moment(_.get(lease, "rent_starts_at", false)).format("YYYY-MM-DD"),
				seating_area: _.get(lease, "seating_area", ""),
				starts_at: !!_.get(lease, "starts_at", false) && moment(_.get(lease, "starts_at", false)).format("YYYY-MM-DD"),
				status: _.get(lease, "status", ""),
				statutory_outgoings_cents: parseInt(_.get(lease, "statutory_outgoings_cents", 0), 10),
				storage_area: parseInt(_.get(lease, "storage_area", 0), 10),
				vacant_possession_at:
					!!_.get(lease, "vacant_possession_at", false) &&
					moment(_.get(lease, "vacant_possession_at", false)).format("YYYY-MM-DD")
			},
			project: {
				...project,
				deadlines: _.map(deadlines, deadline => ({
					name: _.get(deadline, "name"),
					date: _.get(deadline, "date"),
					task_template_id: _.get(deadline, "task_id")
				})),
				template_id: template.id
			},
			rescheduled_tasks: JSON.stringify(rescheduledTasks)
		};
		let { property } = tenancy;
		if (template!.kind === "retail" && !!property.name) {
			constraints.tenant = {
				presence: { allowEmpty: false }
			};
			constraints.tenancy = {
				presence: { allowEmpty: false }
			};
		}
		const errors = validate(
			{
				template,
				tenant,
				tenancy,
				lease,
				property
			},
			constraints
		);

		if (errors !== undefined) {
			let errorMessage = toSentenceSerial(_.map(_.keys(errors)));
			new LocalNotification(`but ${errorMessage}`, translate("error_boundary_body"), "error");
			setLoading(false);
		} else {
			axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}projects`, { project: payload })
				.then(response => {
					new LocalNotification("Project Created");
					sessionStorage.setItem(SESSION_STORAGE_KEY, "");
					history.push(`/projects/${_.get(response.data, "data.id")}`);
				})
				.catch(() => {
					new LocalNotification("Something Went Wrong", "Something Went Wrong", "error");
					setLoading(false);
				});
		}
	};

	const [tenancyArea, setTenancyArea] = useState();

	return (
		<NewProjectContext.Provider value={{ template }}>
			<Board className="lg:w-full lg:mt-7">
				<div className="flex-1">
					<Steps setCurrent={setCurrent} current={current} className="px-5 lg:mb-20">
						<Step title="Template" className="mt-6 mx-auto" clickable>
							<View
								resource="template"
								className={classNames({
									"form-group": !template,
									card: !!template
								})}
								hideTitle
							>
								<Template onUpdate={setTemplate} template={template} />
								<Button
									label="< Reset"
									className={classNames("float-left text-black-60 mt-5", { hidden: !template })}
									onClick={() => setTemplate(null)}
								/>
								<Button
									label="Confirm"
									className={classNames("float-right text-white mt-5", {
										"bg-blue-100 hover:shadow-hover shadow-button": true,
										hidden: !template
									})}
									onClick={() => {
										setCurrent(current + 1);
										setViewState(-1);
									}}
								/>
							</View>
						</Step>
						<Step title="Tenancy" className="mt-6 mx-auto" clickable={!!template || !!tenancy}>
							<View
								resource="tenancy"
								className={classNames({
									"form-group": !tenancy,
									card: !!tenancy
								})}
								hideTitle
							>
								<Tenancy tenancy={tenancy} setTenancyArea={setTenancyArea} onUpdate={setTenancy} viewState={viewState} setViewState={setViewState} />
								<Button
									label="< Reset"
									className={classNames("float-left text-black-60 mt-5", { hidden: viewState !== 1 })}
									onClick={() => setViewState(0)}
								/>
								<Button
									label="Confirm"
									className={classNames("float-right text-white mt-5", {
										"bg-blue-100 hover:shadow-hover shadow-button": true,
										hidden: viewState !== 1
									})}
									onClick={() => {
										setCurrent(current + 1);
										setViewState(-1);
									}}
								/>
							</View>
						</Step>
						<Step title="Tenant" className="mt-6 mx-auto" clickable={(!!tenancy && !!template) || !!tenant}>
							<View resource="tenant" className={"form-group"} hideTitle>
								<Tenant tenant={tenant} onUpdate={setTenant} viewState={viewState} setViewState={setViewState} />
								<Button
									label="< Reset"
									className={classNames("float-left text-black-60 mt-5", { hidden: viewState !== 1 })}
									onClick={() => setViewState(0)}
								/>
								<Button
									label="Confirm"
									className={classNames("float-right text-white mt-5", {
										"bg-blue-100 hover:shadow-hover shadow-button": true,
										hidden: viewState !== 1
									})}
									onClick={() => {
										setCurrent(current + 1);
										setViewState(-1);
									}}
								/>
							</View>
						</Step>
						<Step
							title="Lease"
							className="mt-6 mx-auto"
							clickable={true || (!!tenancy && !!template && !!tenant) || !!lease}
						>
							<View resource="lease" className={"form-group"} hideTitle>
								<Lease
									lease={lease}
									onUpdate={(lease: any) => {
										_.map(
											["ends_at", "fitout_ends_at", "handover_at", "opening_at", "rent_starts_at", "starts_at"],
											(d: any) => {
												if (!!lease && !!lease[d]) {
													deadlines.push({
														id: uuidv4(),
														date: moment(lease[d]).format("YYYY-MM-DD"),
														name: translate(d)
													});
												}
											}
										);
										!!lease ? setDeadlines(_.sortBy(deadlines, "date")) : setDeadlines([]);
										setLease(lease);
									}}
									viewState={viewState}
									setViewState={setViewState}
									tenancyArea={tenancyArea}
								/>
								<Button
									label="< Reset"
									className={classNames("float-left text-black-60 mt-5", { hidden: viewState !== 1 })}
									onClick={() => setViewState(0)}
								/>
								<Button
									label="Confirm"
									className={classNames("float-right text-white mt-5", {
										"bg-blue-100 hover:shadow-hover shadow-button": true,
										hidden: viewState !== 1
									})}
									onClick={() => {
										setCurrent(current + 1);
										setViewState(-1);
									}}
								/>
							</View>
						</Step>
						<Step
							title="Scheduling"
							className="mt-6 mx-auto"
							clickable={!!tenancy && !!template && !!tenant && !!lease}
						>
							<View resource="task" hideTitle>
								<div className="border rounded-1 bg-blue-darker  text-white text-14 p-4 font-bold">
									Calendar Days and Scheduled Due Date are calculated based on the design of your workflow
									template. You can make adjustments by clicking the Calendar Days to complete and/or Adjusted Due Date data
									to ensure your project reflects real life due dates. <br />
									<br />
									You can also reschedule your workflow backwards from one task. To do this, click the{" "}
									<span className="italic">"I would like to reverse schedule from a Task"</span> button, adjust the due
									date for the Task that you wish to reschedule backwards from (eg Handover). When this task’s adjusted
									due at or adjusted calendar days is completed its parent tasks will also be recalculated. Once you've
									completed the first reschedule backwards, unclick the box, then you can continue to finalise your
									program.
								</div>
								<div className="border rounded-1 border-blue-darker text-18 leading-6 p-4 font-medium mt-3 flex justify-between items-center">
									<span>
										<span>Handover Date: {" " + moment(_.get(lease, "handover_at")).format("DD MMM YYYY")}</span>
										<span className="ml-7">
											Fitout Period:
											{" " +
												moment(_.get(lease, "fitout_ends_at")).diff(moment(_.get(lease, "handover_at")), "days") +
												" "}
											(Calendar Days){" "}
										</span>
									</span>

									<span>
										<div className="text-14">
											<Point className="text-error-darker mr-3" />
											Rescheduling of a task has created an adjusted due date to be in the past or the adjusted calendar
											days is 0
										</div>
										<span className="text-14">
											<Point className="text-warning-light mr-3" />
											Rescheduling of a parent task has adjusted this task's due date
										</span>
									</span>
								</div>
								<Reschedule templateId={template?.id} onUpdate={setRescheduledTasks} />
								<Button
									label="< Previous"
									className={classNames("float-left text-black-60 mt-5", { hidden: !rescheduledTasks })}
									onClick={() => {
										setRescheduledTasks([]);
										setCurrent(c => c - 1);
									}}
								/>
								<Button
									label="Next"
									className={classNames("float-right text-white mt-5", {
										"bg-blue-100 hover:shadow-hover shadow-button": true,
										hidden: !rescheduledTasks
									})}
									onClick={() => setCurrent(current + 1)}
								/>
							</View>
						</Step>
						<Step title="Project" className="mt-6 mx-auto" clickable={!!tenancy && !!template && !!tenant && !!lease}>
							<View resource="project" hideTitle>
								{!!loading ? (
									<LoadingState />
								) : (
									<Confirmation project={project} onUpdate={setProject} onSubmit={onSubmit} />
								)}
							</View>
						</Step>
					</Steps>
				</div>
			</Board>
		</NewProjectContext.Provider>
	);
};

export default memo(withRouter(Create));
