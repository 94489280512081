import React, { useState, memo } from "react";
import _ from "lodash";
import { Button } from "components/Shared";
import { TextInput } from "components/Inputs";
import { postComment } from "actions";
import Action from "./Action";
import { useDispatch } from "react-redux";

interface Props {
	onSubmit?: () => void;
	resource: any;
	reply?: tcpinpoint.Comment;
	taggableUsers: (false | null | tcpinpoint.User)[];
}

const Create = ({ onSubmit, resource, reply, taggableUsers }: Props) => {
	const dispatch = useDispatch();
	const [metadata, setMetadata] = useState({});
	const [body, setBody] = useState<any>();
	const [actionModalOpen, setActionModalOpen] = useState(false);

	return (
		<div className="new-comment-form">
			<TextInput
				users={taggableUsers}
				body={body}
				prefix={!!reply && `Replying to ${reply.created_by.name}`}
				hint="Use an @ tag followed by a person's name to mention them in a comment"
				onChange={(body: string) => {
					setBody(body);
				}}
				spellCheck={true}
			/>
			<button
				className="button btn btn-add btn-primary"
				type="button"
				onClick={() => setActionModalOpen(!actionModalOpen)}
			>
				<i className="fas fa-plus" />
			</button>
			<Button
				kind="send"
				onClick={() => {
					dispatch(postComment(resource, { body, metadata, reply_to_id: _.get(reply, "id") }));
					onSubmit && onSubmit();
					setBody("");
					setMetadata(false);
				}}
			/>
			<Action resource={resource} comment={body} isOpen={actionModalOpen} onClose={() => setActionModalOpen(false)} />
		</div>
	);
};

export default memo(Create);
