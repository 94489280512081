export default (state = [], action: any) => {
	switch (action.type) {
		case "CLEAR_NOTIFICATIONS_REQUEST":
			return [];
		case "GET_NOTIFICATION_SUCCESS":
			return action.resource.data as tcpinpoint.ActivityItem;
		case "GET_NOTIFICATIONS_SUCCESS":
			return action.resource.data as tcpinpoint.ActivityItem[];
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
