import React, { memo } from "react";
import classNames from "classnames";

const Cloud = ({ className }: { className?: string }) => {
	return (
		<div className={className}>
			<svg
				className={"absolute left-0 bottom-0"}
				width="227"
				height="140"
				viewBox="0 0 227 140"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					opacity="0.5"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M-167 140H226.58C215.662 115.868 192.587 98.4133 165.167 95.4473C162.847 66.0957 138.294 43 108.346 43C98.3387 43 88.934 45.5787 80.7599 50.108C61.7106 19.9953 28.1132 0 -10.1543 0C-66.3698 0 -112.507 43.15 -117.252 98.1357C-137.837 106.659 -155.198 121.393 -167 140Z"
					fill="#F5F5F5"
				/>
			</svg>
			<svg
				className={"absolute right-0 bottom-0"}
				width="345"
				height="170"
				viewBox="0 0 345 170"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					opacity="0.5"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M406.562 170H0C3.01417 131.802 32.3648 101.011 69.8982 95.7835C72.056 66.2722 96.6825 43 126.745 43C136.286 43 145.279 45.3442 153.181 49.4881C172.523 19.7015 206.083 0 244.245 0C300.829 0 347.297 43.3158 352.299 98.5979C382.362 109.174 404.355 136.88 406.562 170Z"
					fill="#F5F5F5"
				/>
			</svg>
		</div>
	);
};

const Cloud1 = memo(({ className }: { className?: string }) => {
	return (
		<svg
			width="394"
			height="140"
			viewBox="0 0 394 140"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("absolute", className)}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M393.58 140H0C10.9177 115.868 33.9932 98.4133 61.4135 95.4473C63.7331 66.0957 88.2866 43 118.234 43C128.241 43 137.646 45.5787 145.82 50.108C164.87 19.9953 198.467 0 236.734 0C292.95 0 339.087 43.15 343.832 98.1357C364.417 106.659 381.778 121.393 393.58 140Z"
				fill="#FFFFFE"
				fillOpacity="0.15"
			/>
		</svg>
	);
});

const Cloud2 = memo(({ className }: { className?: string }) => {
	return (
		<svg
			width="407"
			height="170"
			viewBox="0 0 407 170"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames("absolute", className)}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M406.562 170H0C3.01417 131.802 32.3648 101.011 69.8982 95.7835C72.056 66.2722 96.6825 43 126.745 43C136.286 43 145.279 45.3442 153.181 49.4881C172.523 19.7015 206.083 0 244.245 0C300.829 0 347.297 43.3158 352.299 98.5979C382.362 109.174 404.355 136.88 406.562 170Z"
				fill="#FFFFFE"
				fillOpacity="0.15"
			/>
		</svg>
	);
});

export { Cloud1, Cloud2 };
export default memo(Cloud);
