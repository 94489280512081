import classNames from "classnames";
import { ListGroup, ListGroupControl, LoadingState, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import { Row2 } from "components/Tasks";
import { useDefaults } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Tasks = () => {
	const urlFilteredBy = new URLSearchParams(useLocation().search).get("filteredBy");
	const defaults = useDefaults() as { sortBy: string; filteredBy: string };
	const [tasks, setTasks] = useState([] as tcpinpoint.Task[]);
	const [filteredBy, setFilteredBy] = useState(urlFilteredBy || defaults.filteredBy || "incomplete");
	const [, renderTrigger] = useState(moment());
	const getSortAttribute = (sortBy: string) => (sortBy === "recent" ? "-updated_at" : sortBy);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "task",
			perPage: 20,
			currentPage: 1,
			sortBy: "due_at",
			renderTrigger: renderTrigger
		})
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setTasks(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		datasource.get("v2", "tasks", {
			queryStringParameters: {
				active: true,
				responsible: true,
				status: filteredBy,
				sort: getSortAttribute(datasource.sortBy),
				per_page: datasource.perPage,
				page: datasource.currentPage
			}
		});
	}, [datasource, datasource.currentPage, datasource.perPage, datasource.sortBy, filteredBy]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<View resource="tasks" hideTitle={true}>
			{/* Added List Group Control to provide filtering options */}
			<ListGroupControl
				sortOptions={["due_date"]}
				onSortChange={newSortBy => {
					datasource.setCurrentPage(1);
					newSortBy = (newSortBy === "due_date") ? "due_at" : newSortBy; 
					datasource.setSortBy(newSortBy);
				}}
				selectedSort={datasource.sortBy}
				filterOptions={["incomplete", "ahead", "behind", "complete"]}
				onFilterChange={newFilterBy => {
					datasource.setCurrentPage(1);
					setFilteredBy(newFilterBy);
				}}
				selectedFilter={filteredBy}
			/>
			<ListGroup
				resource="tasks"
				collection={tasks}
				pagination={false}
				renderRow={(task: tcpinpoint.Task) => <Row2 key={task.id} task={task} showProject />}
			/>
			<div className={classNames("margin-atuo", "flex justify-center")}>
				<Pagination datasource={datasource} />
			</div>
		</View>
	);
};

export default memo(Tasks);
