import React, { memo } from "react";
import _ from "lodash";
//@ts-ignore
import accounting from "accounting";

const currencySymbols = {
	AUD: "$",
	EUR: "€",
	USD: "$",
	GBP: "£",
	YEN: "¥",
	THB: "฿"
};
interface Props {
	amount: string | number;
	currency?: string;
	showCurrency?: boolean;
	decimals?: number;
}

const Money = ({ amount, showCurrency = false, currency = "AUD", decimals = 2 }: Props) => (
	<span {...{ className: "money", "data-currency": currency, "data-amount": `${amount}` }}>
		{accounting.formatMoney(
			accounting.toFixed(parseFloat(`${amount}`) / 100, 2),
			_.get(currencySymbols, currency),
			decimals
		)}
		{showCurrency && currency}
	</span>
);

export default memo(Money);
