import React from "react";
import config from "config.js";
import _ from "lodash";

export default class Map extends React.Component {
	constructor(props) {
		super(props);
		this.mapContainer = React.createRef();
	}

	componentDidMount() {
		import("mapbox-gl")
			.then(module => this.renderMap(module.default))
			.catch(e => console.log(e, "mapbox-gl import failed"));
	}

	renderMap(mapboxgl) {
		mapboxgl.accessToken = config.Mapbox.mapboxApiAccessToken;
		// const { mapboxgl } = this.state;
		const { paddingBounds = 50, defaultZoom = 15, geo_points = [], bearing = [], addresses = [] } = this.props;
		const bounds = new mapboxgl.LngLatBounds();
		const buildings = _.filter(this.props.buildings, building => !!building.coordinates);
		if (!!this.mapContainer) {
			if (!!_.get(geo_points, "length", 0) || !!_.get(addresses, "length", 0) || !!_.get(buildings, "length", 0)) {
				const map = new mapboxgl.Map(
					_.assign({}, config.Mapbox, {
						defaultZoom,
						container: this.mapContainer.current,
						bearing: bearing || 0
					})
				);

				map.on("load", e => {
					if (!!_.get(geo_points, "length")) {
						map.addLayer({
							id: "geopoint-labels",
							type: "symbol",
							source: "geopoints",
							layout: {
								"icon-image": "{icon}-11",
								"text-field": "{title}",
								"text-offset": [0, 0.6],
								"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
								"text-size": 12,
								"text-anchor": "top"
							}
						});
						map.addSource("geopoints", {
							type: "geojson",
							data: {
								type: "FeatureCollection",
								features: _.map(geo_points, geo_point => {
									const { lng, lat, name } = geo_point;
									const lnglat = new mapboxgl.LngLat(lng, lat);
									bounds.extend(lnglat);
									return {
										type: "Feature",
										properties: {
											title: name,
											icon: "marker"
										},
										geometry: {
											type: "Point",
											coordinates: [lng, lat]
										}
									};
								})
							}
						});
					} else if (!!_.get(buildings, "length")) {
						map.addSource("buildings", {
							type: "geojson",
							data: {
								type: "FeatureCollection",
								features: _.map(buildings, building => ({
									type: "Feature",
									properties: {
										title: building.name,
										area: building.area
									},
									geometry: {
										type: "Polygon",
										coordinates: [building.coordinates]
									}
								}))
							}
						});
						map.addLayer({
							id: "buildings-extrusion",
							type: "fill-extrusion",
							source: "buildings",
							paint: {
								"fill-extrusion-color": "#088",
								"fill-extrusion-height": 10,
								"fill-extrusion-base": 0,
								"fill-extrusion-opacity": 0.4
							}
						});
						map.addLayer({
							id: "buildings",
							type: "fill",
							source: "buildings",
							layout: {},
							paint: {
								"fill-color": "#088",
								"fill-opacity": 0.5
							}
						});
						map.addLayer({
							id: "building-labels",
							type: "symbol",
							source: "buildings",
							layout: {
								"text-field": "{title}",
								"text-offset": [0, 0.6],
								"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
								"text-size": 12,
								"text-anchor": "top"
							}
						});
						_.forEach(buildings, building => {
							_.forEach(_.get(building, "coordinates"), lnglat => {
								bounds.extend(lnglat);
							});
						});
					} else if (!!_.get(addresses, "length")) {
						map.addSource("addresses", {
							type: "geojson",
							cluster: true,
							clusterMaxZoom: 12,
							clusterRadius: 30,
							data: {
								type: "FeatureCollection",
								features: _.map(_.compact(addresses), address => ({
									type: "Feature",
									properties: {
										title: _.get(address, "name"),
										icon: "marker"
									},
									geometry: {
										type: "Point",
										coordinates: _.get(address, "geo_point")
									}
								}))
							}
						});
						map.addLayer({
							id: "address-labels",
							type: "symbol",
							source: "addresses",
							layout: {
								"icon-image": "{icon}-11",
								"text-field": "{title}",
								"text-offset": [0, 0.6],
								"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
								"text-size": 12,
								"text-anchor": "top"
							}
						});
						map.addLayer({
							id: "clusters",
							type: "circle",
							source: "addresses",
							filter: ["has", "point_count"],
							paint: {
								"circle-color": ["step", ["get", "point_count"], "#51bbd6", 2, "#f1f075", 10, "#f28cb1"],
								"circle-radius": ["step", ["get", "point_count"], 20, 2, 30, 10, 40]
							}
						});
						map.addLayer({
							id: "cluster-count",
							type: "symbol",
							source: "addresses",
							filter: ["has", "point_count"],
							layout: {
								"text-field": "{point_count_abbreviated}",
								"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
								"text-size": 12
							}
						});
						_.forEach(addresses, address => {
							if (!!_.get(address, "geo_point[1]") && !!_.get(address, "geo_point[0]")) {
								const lat = _.get(address, "geo_point[1]");
								const lng = _.get(address, "geo_point[0]");
								const lnglat = new mapboxgl.LngLat(lng, lat);
								bounds.extend(lnglat);
							}
						});
					}

					map.fitBounds(bounds, {
						padding: {
							top: paddingBounds,
							bottom: paddingBounds,
							left: paddingBounds,
							right: paddingBounds
						},
						maxZoom: defaultZoom,
						animate: false,
						easing(t) {
							return t * (2 - t);
						}
					});
					map.resize();
				});
			}
		}
	}

	render() {
		import("mapbox-gl")
			.then(module => this.renderMap(module.default))
			.catch(e => console.log(e, "mapbox-gl import failed"));

		return (
			<React.Fragment>
				<div ref={this.mapContainer} style={this.props} className="map" />
			</React.Fragment>
		);
	}
}
