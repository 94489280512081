import React from "react";
import { translate } from "lib";
import { ErrorBoundary, Date } from "components/Shared";
import Actor from "components/Activities/Actor"
import ItemObject from "../ItemObject";
import Context from "../Context";

const MoveTask = ({ activity }: { activity: tcpinpoint.ActivityItem }) => <ErrorBoundary>
    <Actor actor={activity.attributes.item.actor} />{' '}
    {translate('rescheduled')}{' '}
    <ItemObject object={activity.attributes.item.object} />{" "}
    <Context context={activity.attributes.item.context} />{" "}
    {translate("to")}{" "}
    <span className="activity-item-content">
        <Date showTime={false} date={activity.attributes.item.content.due_at} />
    </span>
</ErrorBoundary>

export default MoveTask;