import CreateModal from "components/Comments/CreateDynamic";
import List from "components/Comments/ListDynamic";
import { View } from "components/Shared";
import React, { Dispatch, memo, SetStateAction, useState } from "react";

/**
 *  Workflow of reply to a comment (call it Comment-P)
 *  (1) click reply button,
 * 		-> set context.replyToComment: Comment-P
 *  (2) CreateModal will read replyToComment information from CommentContext and display it
 *  (3) input reply content and submit
 *  (4) if success (call created comment as Comment-C),
 * 		-> set context.highlightCommentId: Comment-C's id
 * 		-> set context.listReload: true
 *  (5) List component read context.listReload, if true, reload from API
 *  (6) Row component read context.highlightCommentId, if match self' id, scroll to component and highlight
 */

interface ICommentContext {
	context: {
		replyToComment?: any; // the comment that is currently reply to
		listReload?: boolean; // indicate whther or not the comments list need reload from API call
		highlightCommentId?: string; // Id of comment that need to be highlight
	};
	setContext: Dispatch<SetStateAction<any>>;
}
export const CommentContext = React.createContext({
	context: {} as any,
	setContext: (context: any) => {}
} as ICommentContext);

const CardDynamic = ({ globalId, taggableUsers }: any) => {
	const [context, setContext] = useState({} as any);
	const commentContextProviderValue = { context, setContext };

	return (
		<CommentContext.Provider value={commentContextProviderValue}>
			<View hideTitle={true} resource="comments">
				<List globalId={globalId} />
				<CreateModal globalId={globalId} taggableUsers={taggableUsers} />
			</View>
		</CommentContext.Provider>
	);
};

export default memo(CardDynamic);
