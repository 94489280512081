import React, { memo } from "react";
import { Form, Card } from "v2/components/Tenants";

const Select = ({
	onUpdate,
	tenant,
	viewState,
	setViewState
}: {
	onUpdate?: any;
	tenant?: any;
	viewState?: number;
	setViewState?: any;
}) => {
	return viewState === 1 ? (
		<Card tenant={tenant} onClear={() => setViewState(0)} />
	) : (
		<Form
			tenant={tenant}
			onSubmit={tenant => {
				onUpdate(tenant);
				setViewState(1);
			}}
		/>
	);
};

export default memo(Select);
