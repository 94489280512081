import React, { memo } from "react";
import { Breadcrumbs, Header, Workspace } from "../Shared";
import CollectionDynamic from "./CollectionDynamic";

const Properties = () => {
	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: "Properties" }]} />
			<Header title="properties" />
			<Workspace id="properties" className="list">
				<CollectionDynamic />
			</Workspace>
		</React.Fragment>
	);
};

const MemoziedProperties = memo(Properties);

export default () => <MemoziedProperties />;
