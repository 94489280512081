import { getDocument, upload } from "actions/documents";
import classNames from "classnames";
import { Icon } from "components/Documents";
import { TextInput } from "components/Inputs";
import { Button, ListGroup, ListGroupItem, LoadingState, ModalHeader } from "components/Shared";
import { whatIs } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import { setMergedStateWrapper } from "lib/hooks";
import _ from "lodash";
import moment from "moment";
import React, { Dispatch, memo, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { toSentenceSerial } from "underscore.string";
import axios from "v2/utils/axios";

// ###### Comment Modal ############################################################################################################################################################

const CommentModal = () => {
	const { context, setContext } = useContext(CommentDocumentContext);
	const setMergedContext = setMergedStateWrapper(setContext);
	const { globalId, uuid, users, files, comment, commentModalOpen: modalOpen } = context;
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "comment", renderTrigger: renderTrigger }));
	const dispatch = useDispatch();
	const setComment = (comment: string) => setMergedContext({ comment: comment });
	const closeModal = useCallback(() => setMergedContext({ commentModalOpen: false, uploadModalOpen: false }), [
		setMergedContext
	]);
	const submitComment = () => {
		datasource.setIsLoading(true);
		_.forEach(files, file => {
			dispatch({ type: "POST_DOCUMENT_REQUEST" });
			
			axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}${whatIs(globalId)}/${uuid}/documents`, 
					{ document: { mimetype: file.type, name: file.name, filesize_bytes: file.size } })
				.then((res) => {
					let document = Datasource.getNormalizedData(res.data, "document") as tcpinpoint.Document;
					dispatch({ type: "POST_DOCUMENT_SUCCESS" });
					dispatch(upload(document, file));
					dispatch(getDocument(document.id, false));
					if (comment !== "") {
						datasource.post(
							"v2",
							"comments",
							{
								body: {
									global_id: globalId,
									body: comment,
									metadata: {
										ogSiteName: "tcpinpoint",
										ogTitle: document.name,
										ogUrl: `${process.env.REACT_APP_HOST}documents/${document.id}`,
										ogDescription: ""
									}
								}
							},
							{ updateIsLoding: true }
						);
					}
				})
				.catch((e) => dispatch({ type: "POST_DOCUMENT_FAILED", resource: document, response: e }));
		});
	};

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && context.onSubmitSuccess) {
				closeModal();
				context.onSubmitSuccess(response.normalizedMainModelResponse);
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, closeModal, context]);

	return (
		<Modal
			ariaHideApp={false}
			className="document-upload-modal"
			isOpen={modalOpen}
			onRequestClose={closeModal}
			contentLabel="zip"
		>
			<ModalHeader title="upload" onRequestClose={closeModal} />
			<section>
				{!!users.length && (
					<div className="alert alert-info">
						<i className="fas fa-exclamation-circle" /> Shared with {toSentenceSerial(_.map(users, "name"))}
					</div>
				)}
				{datasource.isLoading ? (
					<LoadingState />
				) : (
					<TextInput
						users={users}
						defaultState={{ comment }}
						name="body"
						onChange={setComment}
						placeholder="Add a comment about the file"
						spellCheck={true}
					/>
				)}
				<br />
				<ListGroup
					collection={files}
					pagination={false}
					resource="documents"
					renderRow={(document: any) => (
						<ListGroupItem
							key={document.name}
							heading={document.name}
							icon={<Icon document={{} as tcpinpoint.Document} />}
						/>
					)}
				/>
			</section>
			<footer>{!datasource.isLoading && <Button onClick={submitComment} />}</footer>
		</Modal>
	);
};

// ###### Upload Modal ############################################################################################################################################################

const UploadModal = () => {
	const uploadRef = useRef<HTMLInputElement>(null);
	const divRef = useRef(null);
	const { context, setContext } = useContext(CommentDocumentContext);
	const { uuid } = context;
	const setMergedContext = setMergedStateWrapper(setContext);

	// upload documents from local to browser, not actual upload to AWS cloud
	const onUploadDocuments = () => {
		const { files } = uploadRef.current as HTMLInputElement;
		setMergedContext({ files: files || [], commentModalOpen: true });
	};

	const onDrop = (event: any) => {
		event.preventDefault();
		const { files } = event.dataTransfer;
		if(files.length > 1){
			return;
		}
		setMergedContext({ files: files || [], commentModalOpen: true });
	};

	const onDragOver = (event: any) => {
		if (divRef && divRef.current) {
			//@ts-ignore
			divRef.current.style.borderColor = "red";
			event.preventDefault();
		}
	};

	return (
		<div ref={divRef} onDrop={onDrop} onDragOver={onDragOver} className="upload">
			<input
				className="file-input"
				ref={uploadRef}
				onChange={onUploadDocuments}
				id={`${uuid}-file`}
				name={`${uuid}-file`}
				type="file"
			/>
			<label htmlFor={`${uuid}-file`} className="btn btn-primary btn-sm button">
				<i className="fas fa-fw fa-file-upload" /> Add a file from your computer (One document per comment)
			</label>
		</div>
	);
};

interface ICommentDocumentContext {
	context: any;
	setContext: Dispatch<SetStateAction<any>>;
}
export const CommentDocumentContext = React.createContext({
	context: {} as any,
	setContext: (context: any) => {}
} as ICommentDocumentContext);

// ###### CreateWithDocument ############################################################################################################################################################

const CreateWithDocument = ({ globalId, isOpen: isUploadModalOpen, onSubmitSuccess, comment, users }: any) => {
	const ref = useRef(null);
	const [context, setContext] = useState({
		uploadModalOpen: isUploadModalOpen,
		commentModalOpen: false,
		comment: comment,
		files: [] as any[],
		users: users,
		globalId: globalId, // the global id of commentable object, such as project / task etc.
		uuid: globalId.match(/[a-zA-Z0-9-]*?$/), // the database uuid of commentable object, such as project / task etc.
		onSubmitSuccess: onSubmitSuccess
	} as any);
	const commentDocumentContextProviderValue = { context, setContext };
	const triggerUploadModal = () =>
		setContext((prevState: any) => ({ ...prevState, uploadModalOpen: !prevState.uploadModalOpen }));

	useEffect(() => setContext((prevState: any) => ({ ...prevState, users, uploadModalOpen: isUploadModalOpen })), [
		isUploadModalOpen,
		users,
		setContext
	]);

	return (
		<CommentDocumentContext.Provider value={commentDocumentContextProviderValue}>
			<React.Fragment>
				<button
					className={classNames("button", "btn", "btn-add", "btn-primary", {
						"action-menu-open": context.uploadModalOpen
					})}
					id="action-menu-button"
					type="button"
					onClick={triggerUploadModal}
				>
					<i className="fas fa-plus" />
				</button>
				<ul ref={ref} className={classNames({ "action-menu-open": context.uploadModalOpen })} id="action-menu">
					<li>
						<UploadModal />
						<CommentModal />
					</li>
				</ul>
			</React.Fragment>
		</CommentDocumentContext.Provider>
	);
};

export default memo(CreateWithDocument);
