import React from "react";
import {  PhaseHelper, TaskHelper } from './CollectionHelper';
import moment from "moment";

const WorkflowPhase = ({ phaseName, tasks }: { phaseName: any, tasks: any }) => {
    return (
        <>
            <div className="flex justify-center">
                <abbr title={`${phaseName ? phaseName : "none"}`} className="text-center text-workflowGray truncate uppercase text-14 font-semibold truncate cursor-default border-none no-underline"
                style={{
                    minWidth: `calc(${tasks.length} * 120px)`,
                    maxWidth: `calc(${tasks.length} * 120px)`,
                }}>
                    {phaseName}
                </abbr>
            </div>
            <div className="flex">
                {tasks.map((task: any) => {
                    return (
                    <ul className="flex w-full mt-21">
                        <li className="px-4 font-bold text-13 w-140 text-center truncate">
                            <abbr title={`${(task !== null && task.task_name) ? task.task_name : "none"}`} className="cursor-default border-none no-underline">
                                {(task !== null && task.task_name) ? task.task_name : "none"}
                            </abbr>
                        </li>
                    </ul>
                    )
                })}
            </div>
        </>
    );
};

export const WorkflowLayout = ({ reports}: { reports: any}) => {

    const phaseNames = PhaseHelper(reports);
    const tasks = TaskHelper(reports);

    return(
        <div className = "flex">
            {phaseNames.map((phaseName: any, index: number) => {
                return(
                    <div className="flex flex-col">
                        <WorkflowPhase phaseName={phaseName} tasks={tasks[index]} />
                        <div className="border border-borderColor border-l-0 mt-4">
                            {reports.projects.map((project: any) => {
                                return (
                                    <div>
                                        {project.phases.map((phase: any) => {
                                            return (
                                                <>
                                                    {phase.phase_name === phaseName ? (
                                                        <div>
                                                            <ul>
                                                                <li className="flex w-full h-50 items-center">
                                                                    {phase.tasks.map((task: any) => (
                                                                        <abbr title={task.task_name} className="border-none no-underline cursor-default w-140 text-center">
                                                                            {(task === "") ? "" : DueDate({taskDueDate: task.task_display_date, taskStatus: task.task_status })}
                                                                        </abbr>
                                                                    ))}
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    ) : ('')}
                                                </>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export const WorkflowTable = ({reports, clickHandler} : {reports: any,  clickHandler: (arg0: string) => void}) => {

    const subtitle1 = ['Project Name', 'Tenancy Ref.', 'Handover'];
    return (
        <>
            <ul>
                <li className="flex">
                    {subtitle1.map((itm) => (
                        <div className={`${itm === 'Project Name' ? 'text-left' : 'text-center'} font-bold text-13 w-160 px-2`}>
                            {itm}
                        </div>
                    ))}
                </li>
            </ul>
            <div className="border border-borderColor mt-4">  
                <ul>
                    {reports.projects.map((project: any) => {
                        return(
                            <li className="flex h-50 items-center">
                                <abbr title={project.project_name} className="border-none no-underline font-normal text-14 w-180 text-left px-2 cursor-pointer text-blue-100 truncate" onClick={() => clickHandler(project.project_id)}>
                                    {project.project_name}
                                </abbr>
                                <abbr title={project.tenancy_name} className="border-none no-underline cursor-default font-normal text-14 w-140 text-center px-2 truncate">   
                                    {project.tenancy_name}
                                </abbr>
                                <div className="font-normal text-14 w-160 text-center px-2 truncate">   
                                    {project.handover_date ? moment(formatDate(project.handover_date)).format('DD MMM YYYY') : ''}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export const dateDiffInDays = (a: Date, b: Date): number => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

const formatDate = (date: string) => {
    let temp = date.split('-');
    let formattedDate = temp[2] + '-' + temp[1] + '-' + temp[0];
    return formattedDate;
}

export const DueDate = ({
    taskDueDate,
    taskStatus,
}: {
    taskDueDate: string;
    taskStatus: string;
}) => {
    let label = '',
        className = 'text-12 font-normal';

    if (taskDueDate) {
        const cDate = new Date(),
            dDate = new Date(formatDate(taskDueDate));
        let diff = dateDiffInDays(cDate, dDate);


        if(taskStatus === 'open'){
            if(diff === 0){
                label = `Due Today`;
                className += ' text-warning-darker'
            }
            else if (diff <= 2 && diff > 0){
                label = `${Math.abs(diff)}d to go`;
                className += ' text-warning-darker'
            }
            else if (diff > 2) { 
                if(diff > 2 && diff < 7){
                    label = `${Math.abs(diff)}d to go`;
                    className += ' text-black';
                }
                if(diff >= 7 && diff < 31){
                    diff = Math.floor(diff/7);
                    label = `${Math.abs(diff)}w to go`;
                    className += ' text-black';
                }
                if(diff >= 31 && diff < 365){
                    diff = Math.floor(diff/31);
                    label = `${Math.abs(diff)}m to go`;
                    className += ' text-black';
                }
                else if (diff >= 365){
                    diff = Math.floor(diff/365);
                    label = `${Math.abs(diff)}y to go`;
                    className += ' text-black';
                }
            }
            else if (diff < 0){
                if (diff > -7 && diff < 0){
                    label = `Due ${Math.abs(diff)}d ago`;
                    className += ' text-error-darker'
                }
                else if(diff <= -7 && diff > -31){
                    diff = Math.floor(diff/7);
                    label = `Due ${Math.abs(diff)}w ago`;
                    className += ' text-error-darker';
                }
                else if(diff <= -31 && diff > -365){
                    diff = Math.floor(diff/31);
                    label = `Due ${Math.abs(diff)}m ago`;
                    className += ' text-error-darker';
                }
                else if (diff <= -365){
                    diff = Math.floor(diff/365);
                    label = `Due ${Math.abs(diff)}y ago`;
                    className += ' text-error-darker';
                }
            }
        }
        else if (taskStatus === 'closed'){
            label = `Closed`;
            className += ' text-success-darker'
        }
    } else{
        label = "No Date";
    }

    return (
        <div className="flex items-center select-none flex-col">
            <p className={className}>{label}</p>
            <p className="text-10 font-normal">{taskDueDate === '' ? '' : moment(formatDate(taskDueDate)).format('DD MMM YYYY')}</p>
        </div>
    );
};