import _ from "lodash";
import classNames from "classnames";
import moment from "moment";
import React, { memo } from "react";
import { Date } from "components/Shared";
import { Link } from "react-router-dom";

const Task = ({ task }: any) => {
	return (
		<Link
			to={`/tasks/${_.get(task, "id")}`}
			className={classNames("task", "task-card", {
				"is-due": moment(_.get(task, "due_at")).isSame(moment(), "day"),
				"is-active": moment(_.get(task, "due_at")).isAfter(moment(), "day") && _.get(task, "closed_at") === null,
				"is-overdue": moment(_.get(task, "due_at")).isBefore(moment(), "day"),
				"is-closed": _.get(task, "closed_at") !== null
			})}
			id={_.get(task, "id")}
		>
			<div className="card-title">
				<h6>
					<Date date={_.get(task, "due_at")} showTime={true} showShort={true} />
				</h6>
			</div>
			<div className="card-body">
				{_.get(task, "project_name") && <h4>{_.get(task, "project_name")}</h4>}
				<h3>{_.get(task, "name")}</h3>
			</div>
		</Link>
	);
};

export default memo(Task);
