import Icon from "components/Activities/Icon";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { CreateParty, DeleteParty } from "../Items";
import ActivityItemDom from "./ActivityItemDom";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityPartyItem = ({ activity }: Props) => {
	const props = { key: activity.attributes.item.id, activity };
	let link = `/projects/${_.get(activity.attributes.item.context, "id")}`;
	let output;;
	let icon;

	switch (activity.attributes.item.type) {
		case "Create":
			icon = Icon("create-party");
			output = <CreateParty {...props} />;
			break;
		case "Delete":
			icon = Icon("delete-party");
			output = <DeleteParty {...props} />;
			break;
	}

	return (
		<Link to={link} className="activity-item-link">
			{" "}
			<ActivityItemDom activity={activity} icon={icon} link={link} output={output} />
		</Link>
	);
};

export default memo(ActivityPartyItem);
