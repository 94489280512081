import React, { useState, Fragment, memo } from "react";
import classNames from "classnames";
import { useAddClassNames } from "v2/hooks";
import { Item as FormItem, Form } from "v2/components/shared/Form";
import { Button, Cloud, Spinner, Help, Board } from "v2/components/shared";
import { Step, Steps } from "v2/components/shared/Steps";
import { isEmail, isValidPassword, getValidationStatus } from "v2/utils";
// import { parse, format } from "libphonenumber-js";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { parse as parseUrl } from "query-string";
import { ExistUser as Exist, Welcome } from ".";
import axios from "axios";
import { LocalNotification } from "lib";
import { generateAUOrIntlPhoneNumberValidator } from "v2/utils/form/validator";

const defaultData = {
	email: "",
	password: "",
	firstname: "",
	surname: "",
	phone: "",
	terms: "",
	code: "",
};

const SignUp = (props: RouteComponentProps) => {
	useAddClassNames("input", ["font-mont"]);
	const { history, location } = props;
	const { email, project_id } = parseUrl(location.search);
	const [current, setCurrent] = useState(0);
	const [data, setData] = useState({ ...defaultData, email: email as string, project_id: project_id as string });
	const [error, setError] = useState<{ [key: string]: string }>({});
	const [loading, setLoading] = useState(false);
	const [emailChecked, setEmailChecked] = useState(false);
	const [onConfirm, setOnConfirm] = useState(false);
	const [verified, setVerified] = useState(false);
	const [verifying, setVerifying] = useState(false);
	const [existUser, setExistUser] = useState(false);

	const stepOneNextable = getValidationStatus(data, error, ["email", "password"]);
	const stepTwoNextable = getValidationStatus(data, error, ["firstname", "surname", "phone"]);
	const stepThreeNextable = getValidationStatus(data, error, ["terms"]);
	const stepFourNextable = getValidationStatus(data, error, ["code"]);

	const onSignIn = () => {
		const { email, password } = data;
		setLoading(true);
		
		axios
			.post(`${process.env.REACT_APP_API_ENDPOINT_V2}sign_in`, {
				user: {
					email,
					password,
				},
			})
			.then((res: any) => {
				console.log('res ' + JSON.stringify(res));

				console.log('res.status.code ' + res.status.code + ' - ' + res.status.message);
				if (res.data.status.code === 200 && res.data.status.message === "Logged in sucessfully.") {
					console.log('no entra ');
					setExistUser(true);
				}
			})
			.catch((error) => {
				console.log('res ' + JSON.stringify(error.response));
				if (error.response) {
					if (error.response.data.errors === "User not found") {
						setLoading(false);
						setEmailChecked(true);
						if (stepOneNextable) setCurrent(current + 1);
					} else if (error.response.data.errors === "Please verified email") {
						// Auth.resendSignUp(email);
						setLoading(false);
						setCurrent(3);
					} else if (error.response.data.errors === "NotAuthorizedException") {
						setExistUser(true);
					} else {
						setLoading(false);
						setError(error);
					}
				} else {
					setLoading(false);
					setError(error);
				}
			});
	};

	const onSubmit = () => {
		setLoading(true);

		// const phone_number = format(parse(data.phone.replace(" ", ""), "AU"), "International").replace(/ /g, "");
		
		axios
			.post(`${process.env.REACT_APP_API_ENDPOINT_V2}sign_up`, {
				user: {
					email: data.email,
					firstname: data.firstname,
					phone_number: data.phone,
					password: data.password,
					surname: data.surname,
				},
			})
			.then(() => {
				setLoading(false);
				setOnConfirm(true);
				setCurrent(current + 1);
			})
			.catch((error) => {
				console.log(error)
				setLoading(false);
				setOnConfirm(false);
				new LocalNotification(error.response.data.errors, "Sign Up Error", "error");
			});
	};

	const onVerify = () => {
		const { email, code } = data;		
		
		axios
			.post(`${process.env.REACT_APP_API_ENDPOINT_V2}registration_verification`, {				
				email,
				pin: code,				
			})
			.then((res) => {
				console.log('res' + JSON.stringify(res))
				res.data.success ? setVerified(true) : setVerified(false);
			})
			.catch((e) => {
				setError({ ...error, code: "Code doesn't match!" });
				setData({ ...data, code: "" });
				setVerified(false);
			});
	};

	if (!!existUser) {
		console.log('user exists');
		return <Exist email={data.email} />;
	}

	function createCookie(name: any, value: any, minutes: any) {
		var expires;		
		if (minutes) {
			var date = new Date();
			date.setTime(date.getTime() + (minutes * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		} else {
			expires = "";
		}		
		document.cookie = name + "=" + value + expires + "; path=/";
	}

	return (
		<Board className="z-20 lg:max-h-2/3 lg:w-1/2 min-h-60 lg:max-w-screen-xl lg:min-w-19">
			<div className="body py-4 lg:py-14 flex-1 lg:relative">
				{!!verified && <Cloud className="hidden lg:block" />}
				{!!verified && (
					<Welcome
						verifying={verifying}
						label={!!project_id ? "Go to your Project" : "Go to your Dashboard"}
						onDirect={() => {
							setVerifying(true);
							axios
								.post(`${process.env.REACT_APP_API_ENDPOINT_V2}sign_in`, {
									user: {
										email: data.email,
										password: data.password,
									},
								})
								.then((res) => {
									/* const { storage } = cog_user;
									setLocalStorage(storage); */
									createCookie('OAUTH', res.data.data.token, 60);
									localStorage.setItem('userId', res.data.data.user.id);
									setVerifying(false);
									!project_id && history.push("/");
									!!project_id && history.push(`/projects/${project_id}`);
								})
								.catch((err) => {
									process.env.NODE_ENV === "development" && new LocalNotification(err, "Error Sign In", "error");
									new LocalNotification("Something wrong!", "Error Sign In", "error");
								});
						}}
					/>
				)}
				{!verified && (
					<Steps current={current} setCurrent={setCurrent}>
						<Step title="Welcome" className="w-4/5 mt-6 lg:mt-12 mx-auto lg:w-3/4" clickable={!onConfirm}>
							{loading ? (
								<div className="flex flex-col justify-center min-h-35">
									<Spinner width="100px" height="100px" className="mx-auto" />
								</div>
							) : (
								<Fragment>
									<Form
										title="Setup Your Account"
										className="mb-10 lg:w-1/2 lg:mx-auto"
										data={data}
										setData={setData}
										error={error}
										setError={setError}
										onSubmit={onSignIn}
									>
										<FormItem
											type="email"
											name="email"
											label="Work Email"
											predefined={!!email}
											rules={[
												{
													message: "Please input a valid Email address.",
													validator: isEmail,
												},
											]}
										/>
										<FormItem
											type="password"
											label="Password"
											name="password"
											hint="Must include numbers, uppercase letters, and special characters (at least one of each). Minimum of 8 characters."
											wordBreakHint={false}
											rules={[
												{
													message:
														"Must include numbers, uppercase letters, and special characters (at least one of each). Minimum of 8 characters.",
													validator: isValidPassword,
												},
											]}
										/>
									</Form>
									<Help className="lg:mx-auto lg:w-1/2" small />
									<Button
										label="Next"
										className={classNames("float-right text-white mt-10", {
											"bg-blue-100 hover:shadow-hover  shadow-button": stepOneNextable,
											"cursor-not-allowed bg-black-5 text-black-20  font-semibold": !stepOneNextable,
										})}
										disabled={!stepOneNextable}
										onClick={onSignIn}
									/>
								</Fragment>
							)}
						</Step>
						<Step
							title="About You"
							className="w-4/5 mt-6 lg:mt-12 mx-auto lg:w-3/4"
							clickable={!!emailChecked && !onConfirm && stepOneNextable}
						>
							<Form
								title="Tell Us About Yourself"
								className="mb-6 lg:w-1/2 lg:mx-auto"
								data={data}
								setData={setData}
								error={error}
								setError={setError}
								onSubmit={() => setCurrent(current + 1)}
							>
								<FormItem
									type="text"
									name="firstname"
									label="First Name"
									info="Required"
									placeholder="Charlotte"
									rules={[
										{
											message: "First name cannot be empty!",
											validator: (firstname: string) => firstname.length,
										},
									]}
								/>
								<FormItem
									type="text"
									name="surname"
									label="Family Name"
									info="Required"
									placeholder="Smith"
									rules={[
										{
											message: "Family name cannot be empty!",
											validator: (surname: string) => surname.length,
										},
									]}
								/>
								<FormItem
									type="text"
									name="phone"
									label="Phone Number"
									info="Required"
									wordBreakHint={false}
									rules={[
										{
											message: "Please input a valid phone number.",
											validator: generateAUOrIntlPhoneNumberValidator(),
										},
									]}
								/>
							</Form>
							<Button
								label="Next"
								className={classNames("float-right text-white mt-10", {
									"bg-blue-100 hover:shadow-hover  shadow-button": stepTwoNextable,
									"cursor-not-allowed bg-black-5 text-black-20  font-semibold": !stepTwoNextable,
								})}
								disabled={!stepTwoNextable}
								onClick={() => setCurrent(current + 1)}
							/>
						</Step>
						<Step
							title="Confirm"
							className="w-4/5 mt-6 lg:mt-12 mx-auto lg:w-3/4"
							clickable={!onConfirm && stepTwoNextable}
						>
							{!!loading ? (
								<div className="flex flex-col justify-center min-h-35">
									<Spinner width="100px" height="100px" className="mx-auto" />
								</div>
							) : (
								<Fragment>
									<Form
										title="Confirm Details"
										className="mb-15 lg:w-1/2 lg:mx-auto"
										data={data}
										setData={setData}
										error={error}
										setError={setError}
										onSubmit={onSubmit}
									>
										<FormItem
											type="confirm"
											name="name"
											label="Name"
											confirm
											value={data.firstname + " " + data.surname}
										/>
										<FormItem type="confirm" name="email" label="Work Email" confirm value={data.email} />
										<FormItem type="confirm" name="phone" label="Phone Number" confirm value={data.phone} />
										<FormItem type="checkbox" name="terms" />
									</Form>
									<Button
										label="Previous"
										className="float-left text-black-60 mt-10"
										onClick={() => setCurrent(current - 1)}
									/>
									<Button
										label="Confirm"
										className={classNames("float-right text-white mt-10", {
											"bg-blue-100 hover:shadow-hover  shadow-button": stepThreeNextable,
											"cursor-not-allowed bg-black-5 text-black-20  font-semibold": !stepThreeNextable,
										})}
										disabled={!stepThreeNextable}
										onClick={onSubmit}
									/>
								</Fragment>
							)}
						</Step>
						<Step title="Verify Emails" className="w-4/5 mt-6 lg:mt-12 mx-auto lg:w-3/4" clickable={onConfirm}>
							<div className="mb-10 lg:w-1/2 lg:mx-auto">
								<div className="form-title text-black-100 font-semibold text-26 leading-9 text-center">
									Verify Your Email
								</div>
								<div className="text-center text-black-100 text-14 lg:text-16 leading-6 mt-2">
									Check your email for a verification code.
								</div>
								<Form data={data} setData={setData} error={error} setError={setError} onSubmit={onVerify}>
									<FormItem
										label="Verification Code"
										name="code"
										type="text"
										rules={[
											{
												message: "Please input your verification code.",
												validator: (code: string) => code.length === 6,
											},
										]}
									/>
								</Form>
								<div className="w-full flex justify-center">
									<Button
										label="Verify"
										className={classNames("text-white mx-auto mt-10", {
											"bg-blue-100 hover:shadow-hover  shadow-button": stepFourNextable,
											"cursor-not-allowed bg-black-5 text-black-20  font-semibold": !stepFourNextable,
										})}
										disabled={!stepFourNextable}
										onClick={onVerify}
									/>
								</div>
							</div>
						</Step>
					</Steps>
				)}
			</div>
		</Board>
	);
};

export default memo(withRouter(SignUp));
