import React from "react";
import Modal from "react-modal";
import validate from "validate.js";
import { PlainTextInput, Input } from "components/Inputs";
import { Lookup as RoleLookup } from "components/Roles";
import { Button, Row, Col, Title, ListGroup, ListGroupItem } from "components/Shared";
import { Modal as ConditionModal } from "components/Conditions";
import { Modal as DependencyModal } from "components/Dependencies";

import { translate } from "lib";
import _ from "lodash";

const constraints = {
	name: {
		presence: true
	},
	role: {
		presence: true
	},
	length: {
		numericality: {
			greaterThan: 0,
			onlyInteger: true
		},
		presence: true
	}
};

export default class TaskModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			start_with: false,
			name: "",
			description: "",
			length: false,
			role: "",
			spectators: [],
			conditions: [],
			dependencies: [],
			confirmDelete: false,
			dependencyModal: false,
			conditionModal: false
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(nextProps.task);
	}

	onUpdate() {
		const { onUpdate, doCloseModal } = this.props;
		const errors = validate(this.state, constraints);

		if (errors) {
			this.setState({ errors });
		} else {
			onUpdate && onUpdate(this.state);
			this.reset();
			doCloseModal && doCloseModal();
		}
	}

	reset() {
		this.setState({
			start_with: false,
			name: "",
			description: "",
			length: false,
			role: false,
			spectators: [],
			conditions: [],
			dependencies: []
		});
	}

	popSpectator(role) {
		const { spectators } = this.state;
		this.setState({ spectators: _.filter(spectators, spectator => spectator.id !== role.id) });
	}

	popDependency(dependency) {
		const { dependencies } = this.state;
		this.setState({ dependencies: _.filter(dependencies, _dependency => dependency.id !== _dependency.id) });
	}

	render() {
		const { isOpen, doCloseModal, deleteTask, template, task } = this.props;
		const {
			id,
			spectators,
			conditions,
			dependencies,
			dependencyModal,
			conditionModal,
			start_with,
			end_with
		} = this.state;
		const tasks = _.flatten(_.map(_.get(template, "body.phases", []), phase => phase.tasks));
		const isStartSet = _.find(tasks, { start_with: true });
		return (
			<Modal
				ariaHideApp={false}
				isOpen={isOpen}
				onRequestClose={doCloseModal}
				className="task-edit-modal"
				contentLabel="Task Edit Modal"
			>
				<header>
					<div className="modal-header-body">
						<Title>{translate("edit_template_task_modal_header")}</Title>
					</div>
					<button type="button" className="close" aria-label="Close" onClick={doCloseModal}>
						<span aria-hidden="true">×</span>
					</button>
				</header>

				<section>
					<Row>
						<Col>
							<Input
								defaultState={this.state}
								required={constraints}
								name="name"
								onChange={name => this.setState({ name })}
							/>
							<PlainTextInput
								defaultState={this.state}
								required={constraints}
								limit={300}
								name="description"
								hint="A reminder of what this task is about"
								onChange={description => this.setState({ description })}
							/>
							<Input
								required={constraints}
								defaultState={this.state}
								name="length"
								min={1}
								step={1}
								suffix="calendar days"
								onChange={length => this.setState({ length })}
								type="number"
								hint="Number of Calendar Days estimated to complete the task"
							/>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<fieldset>
								<RoleLookup
									defaultState={this.state}
									required={constraints}
									hint="The Role responsible for completing task."
									name="role"
									label="primary_responsibility"
									onChange={role => this.setState({ role })}
								/>
								<RoleLookup
									label="Copied To"
									required={constraints}
									hint="The Roles copied on the task"
									name="spectators"
									defaultState={this.state}
									onChange={role =>
										this.setState(state => {
											if (!_.find(state.spectators, { name: role.name })) {
												return state.spectators.push(role);
											}
										})
									}
									defaultOption={"null"}
								>
									<ListGroup
										resource="spectators"
										collection={spectators}
										renderRow={row => (
											<ListGroupItem
												key={row.id}
												subheading={row.name}
												onRemove={() =>
													this.setState({
														spectators: _.filter(spectators, spectator => spectator.name !== row.name)
													})
												}
											/>
										)}
									/>
								</RoleLookup>
							</fieldset>
						</Col>
						<Col>
							<fieldset id="dependencies">
								<label>{translate("dependencies")}</label>
								{(!isStartSet || _.get(isStartSet, "id") === id) && (
									<Input
										type="checkbox"
										defaultState={this.state}
										name="start_with"
										required={constraints}
										onChange={start_with => this.setState({ start_with })}
									/>
								)}
								{!start_with && (
									<React.Fragment>
										<ListGroup
											resource="dependencies"
											collection={dependencies}
											onAdd={() => this.setState({ dependencyModal: true })}
											renderRow={row => (
												<ListGroupItem
													key={row.id}
													heading={row.name}
													onRemove={() =>
														this.setState({
															dependencies: _.filter(dependencies, dependency => dependency.id !== row.id)
														})
													}
												/>
											)}
										/>
										<DependencyModal
											isOpen={dependencyModal}
											doCloseModal={() => this.setState({ dependencyModal: false })}
											onUpdate={dependency =>
												this.setState(state => {
													state.dependencies.push(dependency);
													state.dependencies = _.uniqBy(state.dependencies, "id");
													return state;
												})
											}
											task={this.state}
											template={template}
										/>
									</React.Fragment>
								)}
							</fieldset>
							<fieldset id="conditions">
								<label>{translate("conditions")}</label>
								<ListGroup
									resource="conditions"
									collection={conditions}
									onAdd={() => this.setState({ conditionModal: true })}
									renderRow={row => (
										<ListGroupItem
											key={row.id}
											title={translate(row.kind)}
											heading={row.name}
											onRemove={() =>
												this.setState({ conditions: _.filter(conditions, condition => condition.id !== row.id) })
											}
										/>
									)}
								/>
								<ConditionModal
									isOpen={conditionModal}
									doCloseModal={() => this.setState({ conditionModal: false, condition: false })}
									onUpdate={condition => this.setState(state => state.conditions.push(condition))}
								/>
							</fieldset>
						</Col>
					</Row>
				</section>
				<footer>
					<Button onClick={() => this.onUpdate()} />
					{!end_with && !start_with && id && <Button kind="destroy" confirm onClick={() => deleteTask(task)} />}
				</footer>
			</Modal>
		);
	}
}
