import React, { cloneElement, memo, useRef } from "react";
import { toArray } from "v2/utils";
import { FormContext } from "./context";

const Form = ({
	title,
	children,
	className,
	data,
	setData,
	error = {},
	setError,
	onSubmit
}: {
	title?: string;
	children?: React.ReactNode;
	className?: string;
	data: any;
	error?: any;
	setError: (error: any) => any;
	setData: (data: any) => any;
	onSubmit?: (p?: any) => any;
}) => {
	const childArray = toArray(children);
	const submitRef = useRef(null);
	return (
		<FormContext.Provider value={{ data, setData, error, setError, submitRef }}>
			<form
				className={className}
				onSubmit={event => {
					event!.preventDefault();
					!!onSubmit && onSubmit();
				}}
			>
				<div className="form-title text-black-100 font-semibold text-26 leading-9 text-center">{title}</div>
				{/* TODO: what if the passed children is not a FormItem component? */}
				{childArray.map(child => {
					return cloneElement(child, {
						...child.props,
						key: child.props.name,
						value: !!child.props.confirm ? child.props.value : data[child.props.name]
					});
				})}
				<input
					type="text"
					name="submit"
					id="submit"
					ref={submitRef}
					className="inline h-0 w-0 overflow-hidden opacity-0 float-right"
				/>
				<input type="submit" className="hidden" />
			</form>
		</FormContext.Provider>
	);
};

export default memo(Form);
