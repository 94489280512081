import _ from "lodash";

export interface JsonApiResponseMeta {
	current_page: number;
	per_page: number;
	total_count: number;
}

export default class Normalizer {
	private response: any;
	private options: any;

	private isSingleRecord = true; // if response is from index endpoint or show endpoint
	private result = {} as any;
	private entities = {} as any;
	private relationshipTypes = {} as any; // {... {type: string, singular: boolean}}
	private meta = { current_page: 1, per_page: 20, total_count: 0 };
	private included = [];

	public normalizedData(): any {
		return {
			isSingleRecord: this.isSingleRecord,
			result: this.result,
			entities: this.entities,
			relationshipTypes: this.relationshipTypes,
			meta: this.meta
		};
	}

	constructor(response: any, options: any = {}) {
		this.response = response;
		this.options = _.merge({ includeRelationship: false }, options);
		this.included = _.get(response, "included", []);
		this.meta = _.merge(this.meta, _.get(response, "meta", {}));
	}

	public normalize(): any {
		let data;

		if (_.isEmpty(this.response)) return this.normalizedData();

		if (Array.isArray(this.response.data)) {
			data = this.response.data;
			this.isSingleRecord = false;
		} else data = [this.response.data];

		data.forEach((entity: any) => {
			this.addResult(entity);
			this.addEntity(entity);
		});

		_.each(this.included, (entity: any) => this.addEntity(entity));
		return this.options.includeRelationship ? this.normalizeIncluded() : this.normalizedData();
	}

	private normalizeIncluded(): any {
		let topLevelModelName = _.keys(this.result)[0];

		if (_.isNil(topLevelModelName)) return this.normalizedData();

		let relationshipNames = _.keys(this.relationshipTypes);
		let topLevelRecrods = _.map(this.entities[topLevelModelName], topLevelRecrod => {
			_.forEach(relationshipNames, relationshipName => {
				let relationshipType = this.relationshipTypes[relationshipName].type;
				let relationshipDetails = this.getRelationshipDetails(
					topLevelRecrod,
					this.entities[relationshipType],
					relationshipName
				);
				if (!_.isEmpty(relationshipDetails)) topLevelRecrod[relationshipName] = relationshipDetails; // if only association but not include, relationship details are emtpty, so we dont overwrite association data
			});
			return topLevelRecrod;
		});

		this.entities[topLevelModelName] = topLevelRecrods;
		return this.normalizedData();
	}

	private getRelationshipIds(normalizedRecord: any, relationshipName: string): any {
		let ids = _.map(_.get(normalizedRecord, relationshipName, []), relationship => _.get(relationship, "id"));
		return _.compact(ids);
	}

	private getRelationshipDetails(
		normalizedTopLevelRecord: any,
		normalizedRelationshipCollection: any[],
		relationshipName: string
	): any {
		let singular = this.relationshipTypes[relationshipName].singular;
		if (singular) {
			return _.get(normalizedRelationshipCollection, _.get(normalizedTopLevelRecord, `${relationshipName}.id`));
		} else {
			let relationshipIds = this.getRelationshipIds(normalizedTopLevelRecord, relationshipName);
			return _.filter(normalizedRelationshipCollection, relationshipRecord =>
				_.includes(relationshipIds, _.get(relationshipRecord, "id"))
			);
		}
	}

	private addResult(entity: any): any {
		const { type, id } = entity;
		if (!this.result[type]) this.result[type] = [];
		this.result[type].push(id);
	}

	private addEntity(entity: any): any {
		const { type, id, attributes } = entity;
		if (!this.entities[type]) this.entities[type] = {};
		this.entities[type][id] = { id, ...attributes, ...this.extractRelationships(entity) };
		return this.entities;
	}

	private extractRelationships(entity: any): any {
		const { relationships: responseRelationships } = entity;

		if (!responseRelationships) return undefined;

		let relationships = {} as any;

		_.forEach(Object.keys(responseRelationships), relationName => {
			relationships[relationName] = this.duplicateRelationships(responseRelationships[relationName].data);
			if (_.isNil(this.relationshipTypes[relationName]) && !_.isEmpty(responseRelationships[relationName].data)) {
				let data = responseRelationships[relationName].data;
				this.relationshipTypes[relationName] =
					data.length > 0 ? { type: data[0]["type"], singular: false } : { type: data["type"], singular: true };
			}
		});

		return relationships;
	}

	private duplicateRelationships(relationships: any): any {
		return Array.isArray(relationships) ? [...relationships] : { ...relationships };
	}
}
