import _ from "lodash";
import { getValidator } from "./validator";

// validate rules for single property
const validateRules = (value: string, type: string, rules: any) => {
	let result: Record<string, string>[] = [];
	for (let type in rules) {
		getValidator(type);
		const pass = rules[type].validator
			? rules[type].validator(value, rules[type].value)
			: getValidator(type)(value, rules[type].value);
		!pass && result.push({ [type]: rules[type].message });
	}
	return result.length > 0 ? { [type]: result } : null;
};

// validate rules for mutiple properties
export const validate = (objects: { [key: string]: string }, rules: { [key: string]: any }) => {
	let errors = {};
	for (let type in objects) {
		const value = objects[type];
		const error = validateRules(value, type, rules[type]);
		!!error && (errors = { ...errors, ...error });
	}
	return errors;
};

// validate and pass error message in FormItem component
export const validateFormItem = (value: string, rules: any): string => {
	const errors: string[] = [];
	if (!rules || !rules.length) return "";
	for (let rule of rules) {
		const validator = rule.validator;
		if (!validator(value)) {
			errors.push(rule.message);
		}
	}
	return _.get(errors, 0, "");
};
