import React, { memo, Fragment } from "react";
import classNames from "classnames";

const Help = ({ small = false, className }: { className?: string; small?: boolean }) => {
	return (
		<div
			className={classNames(
				"border border-black-20 rounded-3",
				"px-5 py-6",
				"text-black-80 text-12 leading-4 tracking-wide",
				className
			)}
		>
			{!!small ? (
				<Fragment>
					<div className=" mb-3">Need help? Reach out to us at:</div>
				</Fragment>
			) : (
				<Fragment>
					<div>Need to change your email?</div>
					<div className=" mb-3">Reach out to us at:</div>
				</Fragment>
			)}

			<a href="mailto:help@tcpinpoint.com" className="text-blue-100 focus:outline-none hover:bg-transparent">
				help@tcpinpoint.com
			</a>
		</div>
	);
};

export default memo(Help);
