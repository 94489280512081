import React, { memo, useEffect, useRef, useState } from "react";
import { LoadingState, View, ListGroupControl } from "components/Shared";
import { generateCSVDownload } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import classNames from "classnames";
interface LeaseData {
	lease_id: string;
	handover_date: string;
	net_lettable_area: number | null;
	project_name: string;
	seating_area: number | null;
	storage_required: number | null;
	tenancy_name: string | null;
	fitout_period_day: number | null;
	opening_at: string | null;
}

const leaseInfoMeta = [
	{
		label: "Project Name",
		key: "project_name",
	},
	{
		label: "Tenancy Name",
		key: "tenancy_name",
	},
	{
		label: "Storage Required",
		key: "storage_required",
	},
	{
		label: "Net Lettable Area",
		key: "net_lettable_area",
	},
	{
		label: "Seating Area",
		key: "seating_area",
	},
	{
		label: "Handover Date",
		key: "handover_date",
	},
	{
		label: "Fitout Period (Calendar Days)",
		key: "fitout_period_day",
	},
	{
		label: "Expected Opening Date",
		key: "opening_at",
	},
];

const LeaseTableReport = ({ portfolioId }: any) => {
	const [data, setData] = useState([] as any[]);
	const leaseTableRef = useRef<HTMLDivElement>(null);
	const [filteredBy, setFilteredBy] = useState("open");
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "lease_table_report", renderTrigger: renderTrigger }));

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			let data = response.normalizedMainModelResponse;

			data = _.concat(
				_.sortBy(
					_.filter(data, (record) => !_.isNil(record.handover_date)),
					(record) => moment(record.handover_date).valueOf()
				),
				_.filter(data, (record) => _.isNil(record.handover_date))
			);

			setData(data);
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		if (!_.isEmpty(portfolioId))
			datasource.get("v2", `portfolios/${portfolioId}/lease-table-report`, {
				queryStringParameters: { status: filteredBy },
			});
	}, [datasource, portfolioId, filteredBy]);

	if (datasource.isLoading) return <LoadingState />;

	if (!!data && !!data.length) {
		return (
			<View resource="lease-table">
				<div>
					<div className="report-header">
						<h3>Lease Table Report</h3>
						<div>{generateCSVDownload(data, leaseInfoMeta, "lease-report.csv")}</div>
						{/* <Button onClick={() => exportPDFbyId(leaseTableRef.current!.innerHTML)}>Download PDF</Button> */}
					</div>
					<ListGroupControl
						filterOptions={["All", "open"]}
						onFilterChange={(newFilterBy) => {
							datasource.setCurrentPage(1);
							setFilteredBy(newFilterBy);
						}}
						selectedFilter={filteredBy}
					/>
					<div ref={leaseTableRef} className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th scope="col" className="text-center">
										Project Name
									</th>
									<th scope="col" className="text-center">
										Tenancy Name
									</th>
									<th scope="col" className="text-center">
										Storage Required
									</th>
									<th scope="col" className="text-center">
										Net Lettable Area
									</th>
									<th scope="col" className="text-center">
										Seating Area
									</th>
									<th scope="col" className="text-center">
										Handover Date
									</th>
									<th scope="col" className="text-center">
										Fitout Period (Calendar Days)
									</th>
									<th scope="col" className="text-center">
										Expected Opening Date
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((lease) => {
									return (
										<tr key={lease.project_id}>
											<td className="text-center">{lease.project_name}</td>
											<td className="text-center">{lease.tenancy_name}</td>
											<td
												className={classNames("text-center", {
													"text-gray": !lease.storage_required,
												})}
											>
												{lease.storage_required ? lease.storage_required : "unknown"}
											</td>
											<td
												className={classNames("text-center", {
													"text-gray": !lease.net_lettable_area,
												})}
											>
												{lease.net_lettable_area ? lease.net_lettable_area : "unknown"}
											</td>
											<td
												className={classNames("text-center", {
													"text-gray": !lease.seating_area,
												})}
											>
												{lease.seating_area ? lease.seating_area : "unknown"}
											</td>
											<td className="text-center">{lease.handover_date}</td>
											{/* fitout period */}
											<td className="text-center">{lease.fitout_period_day}</td>
											{/* expected opening date */}
											<td className="text-center">{lease.opening_at}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</View>
		);
	}
	return null;
};

export default memo(LeaseTableReport);
