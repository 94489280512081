import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import "./scss/App.scss";
import { ThemeProvider } from "styled-components";
import dictionary from "dictionary";
import { I18n } from "aws-amplify";

I18n.putVocabularies(dictionary)

const theme = {
	colors: {
		transparent: "transparent",
		current: "currentColor",
		white: "#fff",
		primary: "#00b1bf",
		black: {
			5: "#f5f5f5",
			10: "#ECEFF1",
			20: "#CFD8DC",
			40: "#B6C3CC",
			50: "#80f6ff",
			60: "#849CAD",
			80: "#486279",
			100: "#263746"
		},
		blue: {
			10: "#e5fdff",
			20: "#ccfbff",
			30: "#b3f9ff",
			40: "#99f8ff",
			50: "#80f6ff",
			60: "#61e8f2",
			70: "#45dae5",
			80: "#2bccd9",
			90: "#14bfcc",
			100: "#00b1bf",
			darker: "#009aa6"
		},
		red: {
			10: "#fff5f5",
			20: "#fed7d7",
			30: "#feb2b2",
			40: "#fc8181",
			50: "#f56565",
			60: "#e53e3e",
			70: "#c53030",
			80: "#9b2c2c",
			90: "#742a2a"
		},
		green: {
			10: "#f0fff4",
			20: "#c6f6d5",
			30: "#9ae6b4",
			40: "#68d391",
			50: "#48bb78",
			60: "#38a169",
			70: "#2f855a",
			80: "#276749",
			90: "#22543d"
		},
		error: {
			default: "#fa5047",
			light: "#fa6c64",
			darker: "#de473f"
		},
		warning: {
			default: "#ff8400",
			light: "#ffa94d",
			darker: "#e57c0b"
		},
		success: {
			default: "#2ac769",
			light: "#40dd7f",
			darker: "#1ab759"
		},
		brand: {
			red: "#de473f"
		}
	}
};

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</Provider>,
	document.getElementById("app")
);
