import React from "react";
import RCInputNumber from "rc-input-number";
import { InputNumberProps } from "rc-input-number/lib/interface";
import classNames from "classnames";

interface Props extends Partial<InputNumberProps> {
	className?: string;
	innerCls?: string;
}

const formatInput = (value: string | number | undefined): string => {
	if (!value) return "";
	if (typeof value === "number") {
		return String(value);
	}
	if (typeof value === "string") {
		if (!!String(value).match(/^0+/)) {
			return "0";
		}
		return value.replace(/\D/, "");
	}
	return value;
};

const InputNumber = (props: Props) => {
	const { className: wrapperCls, innerCls, children, disabled } = props;
	return (
		<label
			className={classNames("whitespace-no-wrap mb-0", "transition-colors duration-100", wrapperCls, {
				"hover:cursor-pointer": !disabled,
				"hover:cursor-not-allowed": disabled
			})}
		>
			<RCInputNumber
				{...props}
				className={classNames("text-center h-9", innerCls, {
					"hover:cursor-pointer": !disabled,
					"hover:cursor-not-allowed": disabled
				})}
				formatter={formatInput}
			/>
			{!!children && children}
		</label>
	);
};

export default InputNumber;
