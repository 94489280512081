import React, { memo } from "react";
import _ from "lodash";

export default memo(({ tags }: any) => {
  if (_.includes(tags, "retail")) {
    return <i className="fal fa-shopping-cart" />;
  }
  if (_.includes(tags, "commercial")) {
    return <i className="fal fa-industry-alt" />;
  }
  if (_.includes(tags, "residential")) {
    return <i className="fal fa-home" />;
  }
  return <i className="fal fa-code" />;
});
