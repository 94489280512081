import classNames from "classnames";
import { TextInput } from "components/Inputs";
import { Button, LoadingState, ModalHeader } from "components/Shared";
import { translate } from "lib";
import Datasource, { DatasourceActionType, DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
//@ts-ignore
import validate from "validate.js";

const constraints = {
	comment: { presence: true }
};

const NotifyModal = ({ task, isOpen, onRequestClose, onUpdate }: any) => {
	const [comment, setComment] = useState<string>();
	const [errors, setErrors] = useState();
	const taggableUsers = useMemo(() => _.get(task, "all_users", []), [task]);
	const { id: taskId } = task;
	const [, renderTrigger] = useState(moment());
	const [taskDatasource] = useState(new Datasource({ mainModelName: "task", renderTrigger: renderTrigger }));

	const closeTask = useCallback(() => {
		if (!_.isNil(taskId))
			taskDatasource.put("v2", `tasks/${taskId}/close`, { body: { message: comment } }, { updateIsLoding: true });
	}, [taskDatasource, taskId, comment]);

	const onSubmit = useCallback(() => {
		const errors = validate({ comment }, constraints);
		if (!!errors) {
			setErrors(errors);
		} else {
			setErrors({} as any);
			closeTask();
		}
	}, [setErrors, closeTask, comment]);

	useEffect(() => {
		let sub = taskDatasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && response.actionType === DatasourceActionType.PUT) {
				onUpdate(response.normalizedMainModelResponse);
				onRequestClose();
			}
		});
		return () => sub.unsubscribe();
	}, [taskDatasource, onRequestClose, onUpdate]);

	return (
		<Modal
			ariaHideApp={false}
			className={classNames("task-notify-modal success")}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="reject"
		>
			<ModalHeader title="task_notify_notification_header" onRequestClose={onRequestClose} />
			{taskDatasource.isLoading && <LoadingState />}
			{!taskDatasource.isLoading && (
				<>
				<h3 className="pt-10 px-8">Do you wish to add any comments to this Task before closing it?</h3>
					<section>
						<fieldset>
							<TextInput
								name="comment"
								users={taggableUsers}
								defaultState={{ comment, errors }}
								required={constraints}
								onChange={(comment: string) => setComment(comment)}
							/>
						</fieldset>
					</section>
					<footer>
						<Button onClick={onSubmit}>{translate("close")}</Button>
					</footer>
				</>
			)}
		</Modal>
	);
};

export default memo(NotifyModal);
