import React from "react";
import { translate } from 'lib'

interface Props {
	title: string;
	tooltip?: string;
}

const FeatureFlag = ({ title, tooltip }: Props) =>
	<div className="feature-flag badge badge-warning" title={translate(tooltip)}>
		{translate(title)}
	</div>

export default FeatureFlag;
