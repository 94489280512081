import React, { memo } from "react";
import classNames from "classnames";

interface Props {
	currentPage: number;
	jumpToPage: any;
	maximumPagesInWidget: number;
	updateItemsPerPage: (arg0: number) => void;
	itemsPerPage?: number;
	firstPage?: number;
	totalItems?: number | undefined;
}

const Pagination = (props: Props) => {
	let {
		currentPage = 0,
		jumpToPage,
		maximumPagesInWidget = 10,
		updateItemsPerPage,
		itemsPerPage = 10,
		firstPage = 0,
		totalItems = 0
	} = props;
	const pages: number[] = [];
	const lastTotalPage = Math.ceil(totalItems / itemsPerPage);
	let lastPage = lastTotalPage;

	if (lastPage > maximumPagesInWidget) {
		if (currentPage > Math.ceil(maximumPagesInWidget / 2)) {
			firstPage = currentPage - Math.ceil(maximumPagesInWidget / 2);
		}
		if (firstPage + maximumPagesInWidget <= lastPage) {
			lastPage = maximumPagesInWidget + firstPage;
		}
	}

	for (var i = firstPage; i < lastPage; i += 1) {
		pages.push(i);
	}

	if (totalItems === 1) {
		return null;
	}

	return (
		<nav className="pagination" aria-label="Page Navigation">
			{totalItems > itemsPerPage && (
				<ul>
					{!~pages.indexOf(0) && (
						<li className="page-item">
							<a
								className="page-link"
								href={`#page-${0}`}
								aria-label={`Go to First Page`}
								onClick={event => {
									event.preventDefault();
									jumpToPage && jumpToPage(0);
								}}
							>
								<i className="fas fa-angle-double-left" />
							</a>
						</li>
					)}
					{currentPage !== firstPage && (
						<li className="page-item">
							<a
								className="page-link"
								aria-label={`Go to Previous Page`}
								href={`#page-${currentPage - 1}`}
								onClick={event => {
									event.preventDefault();
									jumpToPage && jumpToPage(currentPage - 1);
								}}
							>
								<i className="fas fa-angle-left" />
							</a>
						</li>
					)}

					{pages.map(page => (
						<li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
							<a
								className="page-link"
								aria-label={`Go to Next ${page}`}
								href={`#page-${page}`}
								onClick={event => {
									event.preventDefault();
									jumpToPage && jumpToPage(page);
								}}
							>
								{page + 1}
							</a>
						</li>
					))}

					{currentPage !== lastPage - 1 && (
						<li className="page-item">
							<a
								aria-label={`Go to Next Page`}
								className="page-link"
								href={`#page-${currentPage + 1}`}
								onClick={event => {
									event.preventDefault();
									jumpToPage && jumpToPage(currentPage + 1);
								}}
							>
								<i className="fas fa-angle-right" />
							</a>
						</li>
					)}
					{!~pages.indexOf(lastTotalPage - 1) && (
						<li className="page-item">
							<a
								className="page-link"
								aria-label={`Go to Last Page`}
								href={`#page-${lastTotalPage - 1}`}
								onClick={event => {
									event.preventDefault();
									jumpToPage && jumpToPage(lastTotalPage - 1);
								}}
							>
								<i className="fas fa-angle-double-right" />
							</a>
						</li>
					)}
				</ul>
			)}
			{!!totalItems && totalItems < itemsPerPage && <p>Showing all {totalItems}</p>}
			{!!totalItems && totalItems > itemsPerPage && (
				<p>
					Showing {currentPage * itemsPerPage + 1} -{" "}
					{(currentPage + 1) * itemsPerPage > totalItems ? totalItems : (currentPage + 1) * itemsPerPage} of{" "}
					{totalItems}
				</p>
			)}
			{updateItemsPerPage && totalItems > itemsPerPage && (
				<p>
					Show{" "}
					{[10, 20, 50, 100].map(l => {
						return [
							<a
								href="#updateItemsPerPage"
								className={classNames({ active: itemsPerPage === l })}
								key={`itemsPerPage-${l}`}
								onClick={e => {
									e.preventDefault();
									updateItemsPerPage(l);
								}}
							>
								{l}
							</a>,
							" "
						];
					})}
					at a time
				</p>
			)}
		</nav>
	);
};

export default memo(Pagination);
