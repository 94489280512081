import React, { memo, useEffect, useState } from "react";
import { View, LoadingState } from "components/Shared";
import Button from "./Button";
import TodoModal from "./TodoModal";
import { TodoList } from "./TodoList";
import _ from "lodash";
import { TodoFilterBlock } from "../Todos/TodoFilterBlock";
import {
	fetchProperties,
	fetchProjects,
	fetchAssigneeTodos,
	applyFilters,
	fetchFullPropertyList,
	fetchFullProjectList,
} from "../Todos/GlobalTodoHelper";
import axios from "v2/utils/axios";

const FilterArrow = (filterState: string) => {
	if (filterState === "Show") {
		return (
			<svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 2L8.5 9L2 2" stroke="#4FAEBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		);
	} else {
		return (
			<svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2.00024 9L8.50024 2L15.0002 9"
					stroke="#4FAEBD"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
	}
};

const TodoTab = ({ todoKind, globalTodo, globalId, propertyId, users }: any) => {
	const [modalOpen, toggleModal] = useState(false);
	const [todoList, setTodoList] = useState([] as any[]);
	const [todoListLoading, setTodoLoading] = useState(false);
	const [id] = useState(todoKind === "property" ? propertyId : globalId);
	const [filteredList, setFilteredList] = useState([] as any[]);
	const [showFilter, setShowFilter] = useState(false);
	interface dropDownProps {
		id: string;
		name: string;
	}

	const initialState = {
		id: "",
		name: "Any",
	};
	const [propertyName, setPropertyName] = useState(initialState);
	const [projectName, setProjectName] = useState(initialState);
	const [assigneeName, setAssigneeName] = useState(initialState);
	const [status, setStatus] = useState({
		id: "1",
		name: "Any",
	});
	const [globalTodoType, setGlobalTodoType] = useState({
		id: "1",
		name: "Project",
	});

	const allPropertyList = fetchProperties(todoList).sort((a: dropDownProps, b: dropDownProps) =>
		a.name?.localeCompare(b.name)
	);
	if (allPropertyList) {
		allPropertyList.unshift(initialState);
	}

	const allProjectList = fetchProjects(todoList).sort((a: dropDownProps, b: dropDownProps) =>
		a.name?.localeCompare(b.name)
	);
	allProjectList.unshift(initialState);

	const allAssigneeList = fetchAssigneeTodos(
		todoList.sort((a: dropDownProps, b: dropDownProps) => a.name?.localeCompare(b.name))
	);
	allAssigneeList.unshift(initialState);

	const todoTypeCallBack = (typeId: string) => {
		if (typeId === "1") {
			setGlobalTodoType({
				id: "1",
				name: "Project",
			});
			setPropertyName(initialState);
			setFilteredList(applyFilters(fetchFullProjectList(todoList), status.name.toLowerCase(), assigneeName.id, "", ""));
		} else {
			setGlobalTodoType({
				id: "2",
				name: "Property",
			});
			setProjectName(initialState);
			setFilteredList(
				applyFilters(fetchFullPropertyList(todoList), status.name.toLowerCase(), assigneeName.id, "", "")
			);
		}
	};

	const statusOptions = [
		{
			id: "1",
			name: "Any",
		},
		{
			id: "2",
			name: "Open",
		},
		{
			id: "3",
			name: "Closed",
		},
		{
			id: "4",
			name: "Overdue",
		},
	];

	const todoTypeOptions = [
		{
			id: "1",
			name: "Project",
		},
		{
			id: "2",
			name: "Property",
		},
	];

	const projectCallBack = (arg: string) => {
		const project = _.find(allProjectList, (todo) => {
			return todo.id === arg;
		});

		setProjectName({
			id: arg,
			name: project.name,
		});
	};

	const propertyCallBack = (arg: string) => {
		const property = _.find(allPropertyList, (todo) => {
			return todo.id === arg;
		});

		setPropertyName({
			id: arg,
			name: property?.name,
		});
	};

	const assigneeCallBack = (arg: any) => {
		const assignee = _.find(allAssigneeList, (todo) => {
			return todo.id === arg.id;
		});

		setAssigneeName({
			id: arg.id,
			name: assignee.name,
		});
	};

	const StatusCallBack = (statusId: string) => {
		const status = _.find(statusOptions, { id: statusId });
		if (status) {
			setStatus({
				id: status.id,
				name: status.name,
			});
		}
	};

	const fetchData = () => {
		if (globalTodo) {
			setTodoLoading(true);
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}todos-feed`)
				.then((res) => {
					setTodoList(res.data.data);
					if (globalTodoType.id === "2") {
						setFilteredList(
							applyFilters(
								fetchFullPropertyList(res.data.todoList.data),
								status.name.toLowerCase(),
								assigneeName.id,
								projectName.id,
								propertyName.id
							)
						);
					} else {
						setFilteredList(
							applyFilters(
								fetchFullProjectList(res.data.todoList.data),
								status.name.toLowerCase(),
								assigneeName.id,
								projectName.id,
								propertyName.id
							)
						);
					}
				})
				.catch((e) => console.log("Error found while fetching global todo tickets"))
				.finally(() => setTodoLoading(false));
		} else {
			setTodoLoading(true);
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}properties/${id}/todos`)
				.then((res) => {
					setTodoList(res.data.todoList.data);
					setFilteredList(applyFilters(res.data.todoList.data, "any", "", "", ""));
				})
				.catch((e) => console.log("Error found while updating property todo tickets"))
				.finally(() => setTodoLoading(false));
		}
	};
	useEffect(fetchData, [globalTodoType.id]);
	useEffect(() => {
		if (todoKind === "global") {
			if (globalTodoType.id === "1") {
				setFilteredList(
					applyFilters(
						fetchFullProjectList(todoList),
						status.name.toLowerCase(),
						assigneeName.id,
						projectName.id,
						propertyName.id
					)
				);
			} else {
				setFilteredList(
					applyFilters(
						fetchFullPropertyList(todoList),
						status.name.toLowerCase(),
						assigneeName.id,
						projectName.id,
						propertyName.id
					)
				);
			}
		}
	}, [todoList, status.name, assigneeName.id, projectName.id, propertyName.id, todoKind, globalTodoType.id]);

	const openTodos = _.filter(filteredList, (todo) => {
		return todo?.attributes?.status?.toLowerCase() === "open";
	});
	const closedTodos = _.filter(filteredList, (todo) => {
		return todo?.attributes?.status?.toLowerCase() === "closed";
	});

	return (
		<View>
			<div
				className="mx-auto"
				style={{
					width: "100%",
					maxWidth: "1024px",
				}}
			>
				<div className="flex justify-between mt-8">
					{globalTodo ? (
						<div className="flex flex-col w-full">
							<div className="flex justify-between items-center">
								<div className="mb-6 text-24 font-bold text-workflowGray">Your ToDo's</div>
								<div className="flex items-center">
									<span>{showFilter ? FilterArrow("Hide") : FilterArrow("Show")}</span>
									<div
										className="text-16 font-normal text-blue-darker cursor-pointer pl-2"
										onClick={() => setShowFilter(!showFilter)}
									>
										{showFilter ? "Hide Filters" : "Show Filters"}
									</div>
								</div>
							</div>
							{showFilter && (
								<TodoFilterBlock
									status={status}
									assigneeName={assigneeName}
									allAssigneeList={allAssigneeList.sort()}
									projectName={projectName}
									allProjectList={allProjectList.sort()}
									allPropertyList={allPropertyList.sort()}
									propertyName={propertyName}
									globalTodoType={globalTodoType}
									statusOptions={statusOptions}
									todoTypeOptions={todoTypeOptions}
									StatusCallBack={(arg: string) => StatusCallBack(arg)}
									projectCallBack={(arg: string) => projectCallBack(arg)}
									propertyCallBack={(arg: string) => propertyCallBack(arg)}
									setAssigneeName={(arg: string) => assigneeCallBack(arg)}
									todoTypeCallBack={(typeId: string) => todoTypeCallBack(typeId)}
								/>
							)}
						</div>
					) : (
						<>
							<div className="text-24 font-bold text-workflowGray">Your ToDo's</div>
							<Button kind="add" onClick={() => toggleModal(!modalOpen)} />
							<TodoModal
								userList={users}
								reloadPage={fetchData}
								updateTodo={false}
								todoKind={todoKind}
								id={id as string}
								isOpen={modalOpen}
								toggleModal={(arg: boolean) => toggleModal(arg ? arg : false)}
							/>
						</>
					)}
				</div>
				{todoListLoading ? (
					<LoadingState />
				) : (
					<>
						{!filteredList.length && (
							<div className="text-18 font-semibold text-workflowGray mt-6 text-center">
								{globalTodo ? "No ToDo's are assigned." : "Create a new ToDo using the Add button"}
							</div>
						)}
						<div className="text-center text-24 opacity-75 font-semibold text-workflowGray pt-8">
							{openTodos.length !== 0 && status.id !== "3" && "Open"}
						</div>
						<ul>
							{filteredList.length ? (
								<>
									{filteredList.map((todos: any) => {
										return (
											<>
												{todos.attributes.status && todos.attributes.status.toLowerCase() === "open" && (
													<TodoList
														userList={users}
														todoKind={todoKind}
														reloadPage={fetchData}
														id={id}
														todoInfo={todos}
													/>
												)}
											</>
										);
									})}
								</>
							) : (
								""
							)}
						</ul>

						<div className="flex m-auto justify-center">
							<div className="text-center text-24 opacity-75 font-semibold text-workflowGray pt-8">
								{closedTodos.length !== 0 && status.id !== "2" && status.id !== "4" && "Closed"}
							</div>
						</div>
						<ul>
							{filteredList.length ? (
								<>
									{filteredList.map((todos: any) => {
										return (
											<>
												{todos.attributes.status && todos.attributes.status.toLowerCase() === "closed" && (
													<TodoList
														userList={users}
														todoKind={todoKind}
														reloadPage={fetchData}
														id={id}
														todoInfo={todos}
													/>
												)}
											</>
										);
									})}
								</>
							) : (
								""
							)}
						</ul>
					</>
				)}
			</div>
		</View>
	);
};

export default memo(TodoTab);
