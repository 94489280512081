import React, { memo } from "react";

const Row = ({ children, id }: { children?: any; id?: string }) =>
	children ? (
		<div className="row" id={id}>
			{children}
		</div>
	) : null;

export default memo(Row);
