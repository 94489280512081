import { lazy } from "react";
import Header from "./Header";
import SignIn from "./SignIn";
import Success from "./Success";
import Clouds from "./Clouds";
import Disclaimer from "./Disclaimer";
import SignUp from "./SignUp";

const SignOut = lazy(() => import("./SignOut"));

export { Header, SignIn, SignUp, SignOut, Success, Disclaimer, Clouds };
