import React from "react";
import { Tags as TagComponent, View } from "components/Shared";

import { connect } from "react-redux";
import { putDocument } from "actions";

export class Tags extends React.Component {
  putDocument(tags) {
    const { document, putDocument } = this.props;
    const { id } = document;
    putDocument({ tags, id });
  }

  render() {
    const { document } = this.props;
    return (
      <View
        resource="tags"
        hint={
          document.metadata &&
          document.metadata.detected_things &&
          `Detected ${document.metadata.detected_things.join(" ")}`
        }
      >
        <TagComponent
          {...document}
          onChange={tags => this.putDocument(tags)}
          suggestions={document.metadata && document.metadata.detected_things}
        />
      </View>
    );
  }
}

const mapStateToProps = (state, { document }) => ({ document });
const mapDispatchToProps = dispatch => ({
  putDocument: document => dispatch(putDocument(document))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
