import React, { memo } from "react";
import classNames from "classnames";
import { emojify } from "../../lib/formatting";
import { translate } from "../../lib";

interface Props {
	children?: any;
	title: string;
	value?: string | number | JSX.Element | JSX.Element[] | false;
	prefix?: string;
	suffix?: string;
	handleClick?: any;
	className?: string | string[] | Object;
	footnote?: string | JSX.Element | false;
	icon?: string;
	showIcon?: boolean;
}

const DefinitionItem = (props: Props) => {
	const { children, title, prefix, suffix, className, footnote, icon, value = false, handleClick, showIcon } = props;
	if ((!!value && value !== "unknown") || !!children) {
		return (
			<div
				className={classNames(className, "definition-item", {
					"has-icon": !!icon,
				})}
			>
				{icon && (
					<div className="icon">
						<i className={`fas fa-${icon}`} />
					</div>
				)}
				<div className="definition-item-body">
					<dt className="definition-term" dangerouslySetInnerHTML={{ __html: emojify(translate(title)) }} />
					<dd
						onClick={handleClick}
						className={`definition-description ${title === "handover_at" && showIcon ? "definiiton-description-cursor" : ""}`}
						data-title={title}
					>
						{prefix}
						{children}
						{value}
						{title === "handover_at" && showIcon ? (
							<span className="icon" style={{ paddingLeft: "8px" }}>
								<i className={`fas fa-info-circle`} />
							</span>
						) : null}
						{suffix}
						<div className="muted footnote">{footnote}</div>
					</dd>
				</div>
			</div>
		);
	}
	return null;
};

export default memo(DefinitionItem);
