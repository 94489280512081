import { whatIs } from "lib";
import _ from "lodash";
import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const postFacility = memoAC((inspection: tcpinpoint.Inspection, facility: tcpinpoint.Facility) => (dispatch: any) => {
  dispatch({ type: "POST_FACILITY_REQUEST" });
  axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}inspections/${inspection.id}/facilities`, _.assign({}, facility, { exists: true }), 
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "POST_FACILITY_SUCCESS", resource: res.data.inspection, response: { status: 200 } });
    dispatch(getInspection(inspection));
  })
  .catch((e) => { dispatch({ type: "POST_FACILITY_FAILED", resource: inspection, response: e }); });
});

export const postInspection = memoAC(({
  resource,
  kind,
  facilities = [],
  defects = []
}: {
  resource: any;
  kind: string;
  facilities?: any[];
  defects?: any[];
}) => (dispatch: any) => {
  dispatch({ type: "POST_INSPECTION_REQUEST" });
  const inspection = {
    source: "web",
    inspectable_id: _.get(resource, "id", ""),
    inspectable_type: _.get(resource, "link", "").split("/")[3],
    kind,
    facilities,
    defects
  };

  axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}inspections`, { inspection }, 
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "POST_INSPECTION_SUCCESS", resource: res.data.inspection, response: { status: 200 } });
    dispatch(getInspection(res.data.inspection.id));
  })
  .catch((e) => { dispatch({ type: "POST_INSPECTION_FAILED", resource: inspection, response: e }); });
});

export const deleteInspection = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "DELETE_INSPECTION_REQUEST" });
  axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}inspections/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "DELETE_INSPECTION_SUCCESS", resource: { id }, response: { status: 200 } });
    dispatch(getInspections());
  })
  .catch((e) => { dispatch({ type: "DELETE_INSPECTION_FAILED", resource: { id }, response: e }); });
});

export const getInspection = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "GET_INSPECTION_REQUEST" });
  axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}inspections/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => { dispatch({ type: "GET_INSPECTION_SUCCESS", resource: res.data.inspection }) })
  .catch((e) => { dispatch({ type: "GET_INSPECTION_FAILED", resource: { id }, response: e }); });
});

export const getInspections = memoAC(() => (dispatch: any) => {
  dispatch({ type: "GET_INSPECTIONS_REQUEST" });
  axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}inspections`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => dispatch({ type: "GET_INSPECTIONS_SUCCESS", resource: res.data.inspections }))
  .catch((e) => dispatch({ type: "GET_INSPECTION_FAILED", response: e }));
});

export const getInspectionsFrom = memoAC((inspection: tcpinpoint.Inspection) => (dispatch: any) => {
  dispatch({ type: "GET_INSPECTIONS_REQUEST" });
  axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}${whatIs(inspection.link)}/${inspection.id}/inspections`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => dispatch({ type: "GET_INSPECTIONS_SUCCESS", resource: res.data.inspections }))
  .catch((e) => dispatch({ type: "GET_INSPECTIONS_FAILED", response: e }));
});

export const closeInspection = memoAC((inspection: tcpinpoint.Inspection) => (dispatch: any) => {
  dispatch({ type: "CLOSE_INSPECTION_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}inspections/${inspection.id}/close`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "CLOSE_INSPECTION_SUCCESS" });
    dispatch(getInspection(res.data.inspection.id));
  })
  .catch((e) => dispatch({ type: "CLOSE_INSPECTION_FAILED", response: e }));
});
