import React, { useContext } from "react";
import { translate } from "lib";
import { UserContext } from "context";

import User from "components/Users/Inline";

const Actor = ({ actor }: { actor: any }) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	if (!actor) {
		return null;
	}
	return (
		<span className="activity-item-actor">
			{actor.id === currentUser.id || actor.name === currentUser.email || actor.email === currentUser.email ? (
				translate("you")
			) : !actor.email ? (
				<React.Fragment>{actor.name}</React.Fragment>
			) : (
				<User user={actor} />
			)}
		</span>
	);
};

export default Actor;
