import _ from "lodash";

export const setLocalStorage = (storage: { [key: string]: any }) => {
	for (let key in storage) {
		localStorage.setItem(key, storage[key]);
	}
};

interface ISmidgeUser {
	id: string;
	email?: string;
	name?: string;
	attributes?: any;
}
export const setSmidgeUser = (user: ISmidgeUser) => {
	user.attributes = user.attributes || {} as any;
	user.attributes = _.assign({}, user.attributes, _.pick(user, ["email", "name"]));
	(window as any).smidgeSettings = _.assign((window as any).smidgeSettings, { user: user });
};
