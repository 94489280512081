import React, { useState, memo } from "react";
import { View } from "components/Shared";
import { List, Create } from "components/Comments";
import _ from 'lodash';

/** Defines a Comment Card
 * a list of existing comments for the resource and (if enabled) a post form
 *
 *
 * @param {string} name
 * @param {boolean} canPost
 * @param {resource} resource
 * @param {array} events
 * @param {User} users
 * @return View
 *
 *
 * */

const Card = ({
	canPost = true,
	resource,
	events,
	users
}: {
	name?: string;
	canPost?: boolean;
	resource: any;
	events?: (false | null | tcpinpoint.Event)[];
	users?: (false | null | tcpinpoint.User)[];
	icon?: string;
}) => {
	const [reply, setReply] = useState();

	const taggableUsers = !!users ? users : _.map(_.filter(resource.permissions, (permission: any) => _.includes(permission.can, 'comment')), "user");

	return (
		<View hideTitle={true} resource="comments">
			<List events={events} resource={resource} onReply={(reply: any) => setReply(reply)} />
			{!!canPost && <Create taggableUsers={taggableUsers} reply={reply} resource={resource} onSubmit={() => setReply(undefined)} />}
		</View>
	);
};

export default memo(Card);
