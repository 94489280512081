import React, { memo } from "react";
import classNames from "classnames";
import { markup } from "lib/formatting";

const Hint = ({ children }: { children: string }) => (
	<div className={classNames("alert", "alert-info")} role="alert">
		<i className={classNames("fas", "fa-info-circle")} />{" "}
		<span dangerouslySetInnerHTML={{ __html: markup(children) }} />
	</div>
);

export default memo(Hint);
