import React, { memo } from "react";
import { Date } from "components/Shared";
import { Inline as User } from "components/Users";

const Status = ({ document }) => {
  const { expired, expires_at, deleted_at, deleted_by } = document;
  if (deleted_at) {
    return (
      <div className="document-status">
        <i className="fas fa-exclamation-circle" />
        <span>
          Document deleted <Date date={deleted_at} showTime={false} /> by <User user={deleted_by} />
        </span>
      </div>
    );
  }

  if (expired) {
    return (
      <div className="document-status">
        <i className="fas fa-exclamation-circle" />
        <span>
          Document Expired <Date date={expires_at} showTime={false} />
        </span>
      </div>
    );
  }
  return null;
};

export default memo(Status);
