import React, { memo } from "react";
import { Button, ModalHeader } from "components/Shared";
import Modal from "react-modal";

const TrashDocument = ({ modalOpen, doCloseModal, onDelete }) => (
	<Modal isOpen={modalOpen} className="archive" onRequestClose={doCloseModal} contentLabel="close-document">
		<ModalHeader title="trash_document" onClose={doCloseModal} />
		<section>
			<p>
				This action will send your document to the trash. You will no longer be able to access this document in any way.
			</p>
			<p>If you do not want to trash your document, click the arrow in the top-right corner to close this modal.</p>
			<hr />
			<h3>Are you sure you want to trash this document?</h3>
		</section>
		<footer>
			<Button
				onClick={() => {
					onDelete();
					doCloseModal();
				}}
				kind="delete"
			/>
		</footer>
	</Modal>
);

export default memo(TrashDocument);
