import React from "react";
import { View, Title } from "components/Shared";

export default class Warning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("app").classList.add("is-missing");
  }

  UNSAFE_componentWillReceiveProps() {
    document.getElementById("app").classList.add("is-missing");
  }

  componentWillUnmount() {
    document.getElementById("app").classList.remove("is-missing");
  }

  render() {
    const { children } = this.props;
    return (
      <section>
        <View>
          <Title>Something Went Wrong.</Title>
          {children}
        </View>
      </section>
    );
  }
}
