import React, { memo } from "react";
import Title from "./Title";
import { translate } from "lib";
import _ from "lodash";

const ValidationErrors = ({ errors }: { errors: any }) => {
	if (!!errors) {
		return (
			<div className="alert alert-warning">
				<Title>{translate("validation-errors")}</Title>
				<ul>{_.map(errors, messages => _.map(messages, message => <li>{message}</li>))}</ul>
			</div>
		);
	}
	return null;
};

export default memo(ValidationErrors);
