import React from "react";
import validate from "validate.js";
import { View, ListGroup, ListGroupItem } from "components/Shared";
import { Input } from "components/Inputs";
import { Lookup as RoleLookup } from "components/Roles";
import { Button } from "components/Shared";
import _ from "lodash";

const constraints = {
  email: {
    email: true,
    presence: true
  },
  role: {
    presence: true
  }
};

export default class Users extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      users: []
    };
  }
  setStateFromProps(props) {
    const { users } = props;
    this.setState({ users });
  } 

  componentDidMount() {
    this.setStateFromProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setStateFromProps(props);
  }

  addUser() {
    const errors = validate(this.state, constraints);

    if (!errors) {
      const { onUpdate } = this.props;
      const { users, email, role, authority } = this.state;
      const newUser = { email, role, authority };
      let newUsers = [];
      if (Array.isArray(users)) {
        newUsers = [...users, newUser];
      } else {
        newUsers = [newUser];
      }
      this.setState({ users: newUsers, email: "", role: null, authority: null}, () => onUpdate(newUsers));
    } else {
      this.setState({ errors });
    }
  }

  removeUser(user) {
    const { onUpdate } = this.props;
    const { users } = this.state;
    const newUsers = users.filter(u => u.email !== user.email);
    this.setState({ users: newUsers }, onUpdate(newUsers));
  }

  render() {
    const { users } = this.state;
    return (
      <React.Fragment>
        <View resource="team_members">
          <ListGroup
            resource="users"
            collection={users}
            renderRow={user => (
              <ListGroupItem
                icon="user"
                key={user.email}
                data={user}
                heading={user.email}
                subheading={_.get(user, "role.name")}
                onRemove={() => this.removeUser(user)}
                badge={user.authority === "administrator" && <i className="fas fa-badge-check" />}
              />
            )}
          />
        </View>
        <View resource="add_party">
          <Input
            defaultState={this.state}
            name="email"
            required={constraints}
            ref={input => (input && input.textInput ? (this.email = input.textInput) : false)}
            onChange={email => this.setState({ email })}
            type="email"
          />
          <RoleLookup
            name="role"
            ref={input => (this.role = input)}
            defaultState={this.state}
            required={constraints}
            onChange={role => this.setState({ role })}
            collection="roles"
          />
          <Input
            defaultState={this.state}
            name="authority"
            options={["standard", "administrator"]}
            onChange={authority => this.setState({ authority })}
          />
          <Button kind="add" onClick={() => this.addUser()} />
        </View>
      </React.Fragment>
    );
  }
}
