import React from "react";
import { translate } from "lib";
import { ResourceContext } from "context";

const Context = ({ context }: { context: any }) => (
	<ResourceContext.Consumer>
		{(resource: any) => {
			return !!context.link && context.link !== resource.link ? (
				<React.Fragment>
					{!!context
						? [
								translate("on"),
								" ",
								<span key={context.link} className="activity-item-context">
									{context.name}
								</span>
						  ]
						: null}
				</React.Fragment>
			) : null;
		}}
	</ResourceContext.Consumer>
);

export default Context;
