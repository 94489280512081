import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const getLikes = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_LIKES_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}likes`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_LIKES_SUCCESS", resource: res.data.likes }))
	.catch((e) => dispatch({ type: "GET_LIKES_FAILED", response: e }));
});

export const postLike = memoAC((like: tcpinpoint.Like) => (dispatch: any) => {
	dispatch({ type: "POST_LIKE_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}likes`, { like }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "POST_LIKE_SUCCESS", resource: res.data.like }))
	.catch((e) => dispatch({ type: "POST_LIKE_FAILED", response: e }));
});

export const deleteLike = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "DELETE_LIKE_REQUEST" });
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}likes/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "DELETE_LIKE_SUCCESS", resource: { id } }))
	.catch((e) => dispatch({ type: "DELETE_LIKE_FAILED", response: e }));
});

export const putLike = memoAC((like: tcpinpoint.Like) => (dispatch: any) => {
	dispatch({ type: "PUT_LIKE_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}likes/${like.id}`, { like }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "PUT_LIKE_SUCCESS", resource: res.data.like }))
	.catch((e) => dispatch({ type: "PUT_LIKE_FAILED", response: e }));
});

export const getLike = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_LIKE_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}likes/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_LIKE_SUCCESS", resource: res.data.like }))
	.catch((e) => dispatch({ type: "GET_LIKE_FAILED", response: e }));
});
