import React, { lazy } from "react";
import { View, Button } from "components/Shared";
import _ from "lodash";
import { UserContext } from "context";
import axios from "v2/utils/axios";

// @ts-ignore
const Editor = lazy(() => import("@stfy/react-editor.js"));

// TODO: revise this component
class MergeTool {
	static get toolbox() {
		return {
			icon: '<i className="fas fa-brackets-curly"></i>',
			title: "Merge Block"
		};
	}
	render() {
		const element = document.createElement("div");
		element.classList.add("merge-field");
		element.innerHTML = "Merge Block";
		return element;
	}

	save(blockContent: any) {
		return {};
	}
}

export default class Document extends React.Component {
	static contextType = UserContext;
	constructor(props: any) {
		super(props);
		this.state = {
			body: _.assign({}, _.get(props, "document.body"), {})
		};
	}

	doSave() {
		//@ts-ignore
		const { body } = this.state;
		//@ts-ignore
		const { document } = this.props;
		axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}documents/${_.get(document, "id")}`, 
				{
					document: _.assign({}, document, {
						body
					})
				})
			.then((res) => this.setState(res.data.document));
	}

	render() {
		// @ts-ignore
		const { body } = this.state;
		return (
			<View>
				<Editor
					holderId="editorjs-container"
					autofocus
					customTools={{ merge: MergeTool }}
					excludeDefaultTools={["image"]}
					onChange={(body: any) => this.setState({ body })}
					data={body}
				/>
				<hr />
				<Button onClick={() => this.doSave()} />
			</View>
		);
	}
}
