import React, { useCallback, useRef, useState, memo } from "react";
import PropTypes from "prop-types";
import { Feedback, Label } from "components/Inputs";
import classNames from "classnames";
import { stripTags } from "lib/formatting";
import _ from "lodash";

const PlainText = (props: any) => {
	const textInput = useRef(null);
	const [state, setState] = useState({
		value: stripTags(_.get(props, `defaultState.${props.name}`, "")),
		remaining: props.limit,
		ignoreError: false
	});

	const onUpdate = useCallback(() => {
		const value = (textInput.current as any).value;
		const { limit, onChange } = props;
		const valueLength = value ? value.length : 0;
		const remaining = parseInt(limit, 10) - valueLength;
		setState(state => ({ ...state, value, remaining }));
		if (onChange) {
			onChange(value);
		}
	}, [props]);

	const {
		name,
		prefix,
		suffix,
		autoComplete,
		children,
		placeholder,
		required,
		disabled,
		large,
		className,
		limit,
		defaultState,
		showIf = true,
		rows
	} = props;

	const { remaining, value } = state;
	if (!showIf) {
		return null;
	}

	console.log(className);
	return (
		<div
			id={`form-control-${name}`}
			className={classNames("form-group", {
				large,
				className,
				"has-danger":
					(limit && remaining <= 0) ||
					(defaultState && defaultState.errors && defaultState.errors[name] && !state.ignoreError),
				"is-required": required && required[name]
			})}
		>
			<Label {...props} />
			<div className="input-group">
				{prefix && <div className="input-group-addon">{prefix}</div>}
				<textarea
					name={name}
					className="form-control min-h-7"
					disabled={disabled}
					placeholder={placeholder}
					value={value}
					ref={textInput}
					autoComplete={!!autoComplete ? "on" : "off"}
					onChange={() => onUpdate()}
					onKeyUp={() => onUpdate()}
					onClick={() => onUpdate()}
					onFocus={() => onUpdate()}
					rows={rows}
				/>
				{!!suffix && <div className="input-group-addon">{suffix}</div>}
			</div>
			{limit && (
				<div className="input-limit">
					{remaining > 0 ? remaining : 0}/{limit}
				</div>
			)}
			<Feedback {...state} {...props} />
			{children && <p className="form-control-child">{children}</p>}
		</div>
	);
};

export default memo(PlainText);

PlainText.defaultProps = {
	name: "text",
	type: "text",
	className: "",
	required: {},
	placeholder: "",
	autoComplete: true,
	autoFocus: false,
	defaultState: {
		errors: []
	}
};

PlainText.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	hint: PropTypes.node,
	type: PropTypes.string,
	limit: PropTypes.number,
	placeholder: PropTypes.string,
	autoComplete: PropTypes.bool,
	autofocus: PropTypes.bool,
	defaultState: PropTypes.shape({
		errors: PropTypes.shape({})
	}),
	rows: PropTypes.number
};
