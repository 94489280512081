import React, { memo } from "react";
import _ from "lodash";
import { translate } from "lib";

const Feedback = (props: any) => {
	const { name, defaultState, hint, limit, remaining } = props;
	let feedback;
	if (!!_.get(defaultState, `errors.${name}`)) {
		feedback = _.get(defaultState, `errors.${name}`, []).join(" ");
	} else if (limit && remaining <= 0) {
		feedback = `Over limit of ${limit} characters`;
	} else if (hint) {
		feedback = translate(hint);
	}
	if (!!feedback) {
		return <div className="form-control-feedback">{feedback}</div>;
	}
	return null;
};

export default memo(Feedback);
