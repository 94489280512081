import React from "react";
import _ from "lodash";

const pickTextClass = (delivery: number) => {
	console.log({ delivery });
	if (delivery === 0) {
		return "early-open";
	} else if (delivery < -14) {
		return "overdue-open";
	} else if (delivery > 0) {
		return "earlier-open";
	} else {
		return "borderline-open";
	}
};

export const OpenAnalyticTable = ({ reports }: { reports: any }) => {
	if (JSON.stringify(reports) === JSON.stringify({})) {
		return <span>No report data available.</span>;
	}

	let propertySpecified = _.get(reports, "project_details") !== null && _.get(reports, "project_details") !== undefined;
	let headings = propertySpecified ? ["Project", "Delivery", "Revenue"] : ["Property", "Projects", "Delivery", "Revenue"];
	let tableContents = propertySpecified ? _.get(reports, "project_details") : _.get(reports, "property_details");
	let averages = _.get(reports, "averages");
	let averageClassname = pickTextClass(_.get(averages, "actual_delivery_in_days_average"));
	let totalProjectCount = 0;
	return (
		<table id="LeaseToOpenTable">
			<thead>
				<tr>
					{headings.map((heading: any, i: number) => {
						return <th key={"lto-table-heading-" + i}>{heading}</th>;
					})}
				</tr>
			</thead>
			<tbody>
				{tableContents.map((row: any, i: number) => {
					let deliveryVal = propertySpecified ? _.get(row, "actual_delivery") : _.get(row, "average_actual_delivery");
					let deliveryString = formatDays(deliveryVal);
					let revenueVal = propertySpecified ? _.get(row, "actual_revenue") : _.get(row, "average_actual_revenue");
					let revenueString = formatRevenue(revenueVal);
					let rowClassName = pickTextClass(deliveryVal);
					let rowClassName2 = parseFloat(revenueString.replace(/[^0-9.-]+/g,"")) < 0 ? "overdue" : "early";
					let linkPrefix = propertySpecified ? "/projects/" : "/reports?type=4&property_id=";
					totalProjectCount += _.get(row, "project_count");
					return (
						<tr key={"lto-table-row-" + i}>
							<td key={"lto-table-name-cell-" + i}>
								<a href={linkPrefix + _.get(row, "id")} target="_blank" rel="noopener noreferrer">
									{_.get(row, "name")}
								</a>
							</td>
							{_.get(row, "project_count") && (
								<td key={"lto-table-project-count-cell-" + i} className={rowClassName}>
									<span className="property-count">{_.get(row, "project_count")}</span>
								</td>
							)}
							<td key={"lto-table-delivery-cell-" + i} className={rowClassName}>
								{deliveryString}
							</td>
							<td key={"lto-table-revenue-cell-" + i} className={rowClassName2}>
								{revenueString}
							</td>
						</tr>
					);
				})}
			</tbody>
			<tfoot>
				<tr>
					<td>Average</td>
					{!propertySpecified && (
						<td className={averageClassname + " property-count property-count-td-padding"}>{totalProjectCount}</td>
					)}
					<td className={averageClassname}>{formatDays(_.get(averages, "actual_delivery_in_days_average"))}</td>
					<td className={_.get(averages, "actual_revenue_average") > 0 ? "early" : "overdue"}>
						{formatRevenue(_.get(averages, "actual_revenue_average"))}
					</td>
				</tr>
			</tfoot>
		</table>
	);
};

const formatDays = (days: number) => {
	let dayPlural = Math.round(Math.abs(days)) === 1 ? "Day" : "Days";
	if (days > 0) {
		return `${Math.round(Math.abs(days))} ${dayPlural} Early`;
	} else if (days < 0) {
		return `${Math.round(Math.abs(days))} ${dayPlural} Late`;
	} else {
		return "On Time";
	}
};


const formatRevenue = (revenue: number) => {
	if (revenue > 0) {
		return `+$${Math.abs(revenue / 100).toFixed(2)}`;
	} else if (revenue < 0) {
		return `-$${Math.abs(revenue / 100).toFixed(2)}`;
	} else {
		return "$0";
	}
};