import _ from "lodash";
export default (state: tcpinpoint.Invite[] = [], action: any) => {
	switch (action.type) {
		case "GET_INVITE_SUCCESS":
			return _.unionBy([action.resource], state, "id");
		case "GET_INVITES_SUCCESS":
			return _.unionBy(action.resource as tcpinpoint.Invite[], state, "id");
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
