import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

class ReadStatus extends React.PureComponent {
  render() {
    const { profile, collection, resource } = this.props;

    let isUnread = !_.includes(_.map(_.get(resource, "read_by", []), reader => reader.id), _.get(profile, "id"));

    if (!!collection) {
      if (!!collection.length) {
        const collectionReaders = _.flatten(
          _.map(collection, resource => _.map(_.get(resource, "read_by", []), reader => reader.id))
        );
        isUnread = _.filter(collectionReaders, { reader: _.get(profile, "id") }).length !== collection.length;
      } else {
        isUnread = false;
      }
    }

    if (!!isUnread) {
      return (
        <div className="unread">
          <i className="fas fa-circle" />
        </div>
      );
    }
    return false;
  }
}

const mapStateToProps = ({ profile }, props) => _.assign({}, props, { profile });

export default connect(
  mapStateToProps,
  {}
)(ReadStatus);
