import { Show as ShowBuilding } from "components/Buildings";
import { EditModal as EditDocument, Show as ShowDocument } from "components/Documents";
import { Edit as EditLease } from "components/Leases";
import { Home, Missing } from "components/Pages";
import { Show as ShowCondition } from "components/Conditions";

import { List as ListPortfolios, Show as ShowPortfolio } from "components/Portfolios";

import { List as ListReports } from "components/Reports";
import { List as ListTodos } from "./components/Todos";

import { Edit as EditProject, Show as ShowProject } from "components/Projects";
import { List as ListProjects } from "v2/components/Projects";
import { List as ListProperties, Show as ShowProperty } from "components/Properties";
import { SignOut } from "components/Session";
import { LoadingState } from "components/Shared";
import { Masthead } from "v2/components/shared/Masthead";
import { List as ListTasks, Show as ShowTask } from "components/Tasks";
import { List as ListTemplates, Show as ShowTemplate } from "components/Templates";
import { Show as ShowTenancies, Edit as EditTenancy } from "components/Tenancies";
import { Edit as EditTenant } from "components/Tenants";
import { default as React, Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
// @ts-ignore
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ProtectedRoute, UnprotectedRoute } from "routes";
import "scss/react-notifications.css";
import { Reset as PasswordReset, SignIn, SignUp } from "v2/components";
import { Create as CreateProject } from "v2/components/Projects";

const fallback = (
	<React.Fragment>
		<Helmet>
			<body className="application-layout" />
		</Helmet>
		<Masthead />
		<LoadingState />
	</React.Fragment>
);

const App = () => (
	<Fragment>
		<NotificationContainer />
		<Router>
			<Suspense fallback={fallback}>
				<Switch>
					<UnprotectedRoute exact path="/password/reset" component={PasswordReset} />
					<UnprotectedRoute exact path="/signup/:token" component={SignUp} />
					<UnprotectedRoute exact path="/signup" component={SignUp} />
					<UnprotectedRoute exact path="/signin" component={SignIn} />
					<UnprotectedRoute exact path="/signout" component={SignOut} />
					<ProtectedRoute path="/conditions/:id" component={ShowCondition} />
					<ProtectedRoute path="/tasks/:id" component={ShowTask} />
					<ProtectedRoute path="/tasks" component={ListTasks} />
					<ProtectedRoute path="/leases/:id/edit" component={EditLease} />
					<ProtectedRoute path="/tenants/:id/edit" component={EditTenant} />
					<ProtectedRoute path="/properties/:property_id/buildings/:id" component={ShowBuilding} />
					<ProtectedRoute path="/properties/:id" component={ShowProperty} />
					<ProtectedRoute path="/properties" component={ListProperties} />

					<ProtectedRoute path="/portfolios/:id" component={ShowPortfolio} />
					<ProtectedRoute path="/portfolios" component={ListPortfolios} />

					{/* <ProtectedRoute path="/reports/:id" component={ShowReport} /> */}
					<ProtectedRoute path="/reports" component={ListReports} />
					<ProtectedRoute path="/todos" component={ListTodos} />

					<ProtectedRoute path="/projects/new" component={CreateProject} />
					<ProtectedRoute path="/projects/:id/edit" component={EditProject} />
					<ProtectedRoute path="/projects/:id" component={ShowProject} />
					<ProtectedRoute path="/projects" component={ListProjects} />
					<ProtectedRoute path="/documents/:id/edit" component={EditDocument} />
					<ProtectedRoute path="/documents/:id/:revision_id" component={ShowDocument} />
					<ProtectedRoute path="/documents/:id" component={ShowDocument} />
					<ProtectedRoute path="/templates/:id/edit" component={ShowTemplate} />
					<ProtectedRoute path="/templates/:id" component={ShowTemplate} />
					<ProtectedRoute path="/tenancies/:id/edit" component={EditTenancy} />
					<ProtectedRoute path="/tenancies/:id" component={ShowTenancies} />
					<ProtectedRoute path="/templates" component={ListTemplates} />
					<ProtectedRoute exact path="/" component={Home} />
					<ProtectedRoute component={Missing} />
				</Switch>
			</Suspense>
		</Router>
	</Fragment>
);
export default App;
