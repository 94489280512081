import HomeFeed from "components/Activities/HomeFeed";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Datapoint, Datapoints, Greeting, Row, Title, View, Workspace } from "components/Shared";
import { faCircle as farFaCircle } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { Help } from "components/Widgets";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

const Today = memo(() => {
	return (
		<div className="datapoint info" key="datapoint-info">
			<div className="datapoint-name" style={{ fontWeight: 200 }}>
				{moment().format("dddd D MMMM")}
			</div>
			<div className="datapoint-value" style={{ width: "auto" }}>
				<i className="fal fa-calendar" />
			</div>
		</div>
	);
});

const Home = () => {
	const [home, setHome] = useState({} as tcpinpoint.Home);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}home`, { headers: headers })
			.then((res) => setHome(res.data.data.attributes) )
			.catch((e) => {})
			.finally(() => setIsLoading(false));
	}, []);

	return (
		<React.Fragment>
			<Helmet>
				<body className="application-layout home"></body>
			</Helmet>
			<View resource="headline" hideTitle>
				<Title>
					<Greeting />
				</Title>
				<Datapoints
					viewBy="list"
					collection={[
						<Today key={"today"} />,
						<Datapoint
							loading={isLoading}
							link="/properties"
							key="/properties"
							name="properties"
							value={home.properties_count}
						/>,
						<Datapoint
							loading={isLoading}
							key="/projects"
							link="/projects?filteredBy=open"
							name="open_projects"
							value={home.projects_opened_count}
						/>,
						<Datapoint
							loading={isLoading}
							key="my_tasks/tasks#filteredBy=incomplete"
							link="/tasks?filteredBy=incomplete"
							name="my_tasks"
							value={home.tasks_opened_count}
						/>,
						<Datapoint
							loading={isLoading}
							key="behind/tasks#filteredBy=behind"
							link="/tasks?filteredBy=behind"
							name="tasks_behind"
							value={home.tasks_opened_beind_count}
						/>,
					]}
				/>
			</View>
			<Workspace key="home-main" id="home-main" className="home">
				<React.Fragment>
					<Row>
						<Col>
							<HomeFeed />
							<Help />
						</Col>
						<Col>
							<Datapoints
								title="project-status"
								viewBy="list"
								resource="project-status"
								collection={[
									<Datapoint
										icon={farFaCircle}
										loading={isLoading}
										className="project-ahead"
										link="/projects?filteredBy=ahead"
										key="projects_ahead/projects#filteredBy=ahead"
										name="projects_ahead"
										value={home.projects_ahead_count}
									/>,
									<Datapoint
										icon={farFaCircle}
										loading={isLoading}
										className="project-behind"
										link="/projects?filteredBy=behind"
										key="projects_behind/projects#filteredBy=behind"
										name="projects_behind"
										value={home.projects_behind_count}
									/>,
									<Datapoint
										icon={farFaCircle}
										loading={isLoading}
										className="project-defect"
										link="/projects?filteredBy=defects"
										key="archived/projects#filteredBy=defects"
										name="projects_in_defects"
										value={home.projects_defects_count}
									/>,
									<Datapoint
										icon={farFaCircle}
										loading={isLoading}
										className="project-archived"
										link="/projects?filteredBy=archived"
										key="archived/projects#filteredBy=archived"
										name="projects_archived"
										value={home.projects_archived_count}
									/>,
								]}
							/>
							<Datapoints
								resource="projects-by-property"
								collection={(home.projects_opened_count_by_property || []).map((property) => (
									<Datapoint
										className="property"
										loading={isLoading}
										link={`/properties/${property.id}`}
										key={`/properties/${property.id}`}
										name={property.name}
										count={property.projects_count}
										legend={property.is_administrator}
										value={property.projects_count}
									/>
								))}
								title={"live-project-by-property"}
							/>
						</Col>
					</Row>
				</React.Fragment>
			</Workspace>
		</React.Fragment>
	);
};
export default Home;
