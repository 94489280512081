import React, { memo } from "react";
import classNames from "classnames";

const Report = (props: any) => {
  const { report } = props;
  return <div className={classNames("list-group-item")}>
    <div className="list-group-item-heading">
      <h3>{report.name}</h3>
    </div>
    <div className="list-group-item-body">
      <p>
        {report.schedule} &middot; {report.kind}
      </p>
    </div>
  </div>
}

export default memo(Report)
