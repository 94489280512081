import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Workspace, Breadcrumbs, Header } from "components/Shared";
import Collection from "./Collection";

const List = () => {
	// TODO: fetch related users
	// const dispatch = useDispatch()
	// useEffect(() => {
	// 	dispatch(getUsers())
	// }, [dispatch]);
	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: "users" }]} />
			<Header stamp="users" title={"users"} />
			<Workspace id="users" className="list">
				<Collection users={useSelector((store: tcpinpoint.Store) => store.users)} />
			</Workspace>
		</React.Fragment>
	);
};

const MemoizedList = memo(List);
export default () => <MemoizedList />;
