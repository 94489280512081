import React, { memo, useState } from "react";
import Modal from "react-modal";
import { Button, ModalHeader } from "components/Shared";
import { TextInput } from "components/Inputs";

const constraints = {
	message: { presence: true }
};

export const Reopen = (props: any) => {
	const [state, setState] = useState({ reason: "" });
	const { modalOpen, closeModal, onClose } = props;

	return (
		<Modal isOpen={modalOpen} className="success" onRequestClose={closeModal} contentLabel="reopen">
			<button type="button" className="close" aria-label="Close" onClick={event => onClose()}>
				<span aria-hidden="true">×</span>
			</button>
			<ModalHeader title="reopen" onClose={closeModal} />
			<section>
				<p>Provide a reason for reopening this task</p>
				<TextInput
					name="message"
					defaultState={state}
					onChange={(reason: any) =>
						setState({
							reason
						})
					}
					required={constraints}
					placeholder="Your Reason"
					hint="Your comment will be shown
          in the comments section and the Roles associated with this Task will
          be notified"
				/>
			</section>
			<footer>
				<Button
					onClick={() => {
						onClose(state);
						closeModal();
					}}
				/>
			</footer>
		</Modal>
	);
};

export default memo(Reopen);
