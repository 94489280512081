import React, { Fragment } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { translate } from "lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Datapoint {
	name?: string;
	value?: number;
	link?: string;
	icon?: IconProp;
	count?: string | number;
	className?: string;
	loading?: Boolean;
	legend?: Boolean;
}

const Datapoint = ({ name, value, link, icon, count, className, loading }: Datapoint) => {
	let dots: Array<any> = [];
	let _count = 0;
	if (count) {
		_count = typeof count === "string" ? parseInt(count, 10) : count;
		dots = [...Array(_count)];
		if (_count > 10) {
			dots = [...Array(10)];
		}
	}
	const domBody = (
		<Fragment>
			{!!icon && <FontAwesomeIcon icon={icon} />}
			<div className={classNames("datapoint-name")}>{translate(name)}</div>
			<div className="datapoint-dots">
				{!!count && dots.map((_, i) => <i key={`count-${i}`} className="fas fa-circle fa-fw" />)}
				{_count > 10 && <i key="count-11" className="fas fa-plus fa-fw" />}
			</div>
			<div className={classNames("datapoint-value")}>
				{loading ? <FontAwesomeIcon icon={faCircleNotch} className={classNames({ "is-active": loading })} /> : value}
			</div>
		</Fragment>
	);
	if (!!link) {
		return (
			<Link to={link} className={classNames("datapoint", className)}>
				{domBody}
			</Link>
		);
	}
	return <div className={classNames("datapoint", className)}>{domBody}</div>;
};

export default Datapoint;
