import Icon from "components/Activities/Icon";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { CreateInvite } from "../Items";
import ActivityItemDom from "./ActivityItemDom";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityInviteItem = ({ activity }: Props) => {
	const props = { key: activity.attributes.item.id, activity };
	let link = `/projects/${_.get(activity.attributes.item.context, "id")}`;
	let output;;
	let icon;

	switch (activity.attributes.item.type) {
		case "Create":
			icon = Icon("create-invite");
			output = <CreateInvite {...props} />;
			break;
	}
	return (
		<Link to={link} className="activity-item-link">
			{" "}
			<ActivityItemDom activity={activity} icon={icon} link={link} output={output} />
		</Link>
	);
};

export default memo(ActivityInviteItem);
