import { LastMomentTriggerAPIContext } from "context";
import moment from "moment";
import React, { memo, useState } from "react";
import { Modal } from ".";
import { Breadcrumbs, Header, Workspace } from "components/Shared";
import CollectionDynamic from "./CollectionDynamic";

const Portfolios = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [lastMoment, setLastMoment] = useState(moment());
	const lastMomentProviderValue = { lastMoment, setLastMoment };

	return (
		<React.Fragment>
			<LastMomentTriggerAPIContext.Provider value={lastMomentProviderValue}>
				<Breadcrumbs items={[{ title: "portfolios" }]} />
				<Header
					title="portfolios"
					toolbar={[
						{
							label: "new",
							onClick: () => setIsOpen(!isOpen)
						}
					]}
				/>
				<Workspace hideTitle id="portfolios" className="list">
					<CollectionDynamic />
				</Workspace>
				<Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
			</LastMomentTriggerAPIContext.Provider>
		</React.Fragment>
	);
};

const MemoizedPorfolios = memo(Portfolios);

export default () => <MemoizedPorfolios />;
