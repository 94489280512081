import React, { memo, Fragment } from "react";
import { Lookup } from "components/Templates";
import { Card } from "v2/components/Templates";

const Select = ({ onUpdate, template }: { onUpdate?: any; template?: any; template_id?: string }) => {
	return (
		<Fragment>
			{!!template ? <Card template={template} onUpdate={() => onUpdate(false)} /> : <Lookup onChange={onUpdate} />}
		</Fragment>
	);
};

export default memo(Select);
