import _ from "lodash";
export default (state: [] = [], action: any) => {
	switch (action.type) {
		case "GET_LIKE_SUCCESS":
			return _.unionBy([action.resource], state, "id");
		case "GET_LIKES_SUCCESS":
			return _.unionBy(action.resource as [], state, "id");
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
