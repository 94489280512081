import { Date, LoadingState } from "components/Shared";
import FeatureFlag from "components/Shared/FeatureFlag/FeatureFlag";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";

const HistoryCard = ({ taskId }: any) => {
	const [history, setHistory] = useState([] as any[]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "task::history", renderTrigger: renderTrigger }));

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setHistory(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		if (!taskId) return;
		datasource.get("v2", `tasks/${taskId}/history`, {
			queryStringParameters: { per_page: datasource.perPage, page: datasource.currentPage }
		});
	}, [datasource, taskId]);

	return (
		<div className="history-card">
			<header className="component-header">
				<h2>Edit History</h2>
				<FeatureFlag title={"beta"} tooltip={"this feature is in development"} />
			</header>
			<table className="table">
				<tbody>
					<tr>
						<th>Event</th>
						<th>Content of Change</th>
						<th>Date of Change</th>
						<th>Changed By</th>
					</tr>
					{!datasource.isLoading &&
						_.map(history, (row: any) => (
							<tr key={row.id}>
								<td>{translate(row.name)}</td>
								<td>{row.content}</td>
								<td>
									<Date date={row.changed_datetime} />
								</td>
								<td>{row.changed_by}</td>
							</tr>
						))}
				</tbody>
			</table>
			{datasource.isLoading && (
				<div>
					<LoadingState />
				</div>
			)}
		</div>
	);
};

export default memo(HistoryCard);
