import _ from "lodash";

export default (state = [], action: any) => {
	switch (action.type) {
		case "GET_EVENTS_SUCCESS":
			return _.unionBy(action.resource as [], state, "action_id");
		case "POST_EVENT_SUCCESS":
			return _.unionBy([action.resource], state, "action_id");
		default:
			return state;
	}
};
