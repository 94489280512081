import _ from "lodash";

export const fetchProperties = (todoList: any) => {
    const propertyArray = _.filter(todoList, (todo) => {
        return todo.attributes.todoable_type === "Property";
    })
    const curatedPropertyArray = _.map(propertyArray, (todo) => {
        return{
            id: todo.attributes.todoable_id,
            name: todo.attributes.todoable_name
        }
    })

    return _.uniqBy(curatedPropertyArray, 'id');;
}

export const fetchProjects = (todoList: any): any => {
    const projectArray = _.filter(todoList, (todo) => {
        return todo.attributes.todoable_type === "Project";
    })
    const curatedProjectArray = _.map(projectArray, (todo) => {
        return{
            id: todo.attributes.todoable_id,
            name: todo.attributes.todoable_name
        }
    })

    return _.uniqBy(curatedProjectArray, 'id');
}

export const fetchOpenStatus = (todoList: any): any => {
    const openTickets = _.filter(todoList, (todo) => {
        return (todo.attributes.status)?.toLowerCase() === "open";
    })
    return openTickets;
}

export const fetchPastDueDate = (dueAt: any): any => {
    const today = new Date();
    today.setHours(0,0,0,0);
    const dueDate = new Date(dueAt);
    return dueDate.getTime() < today.getTime();
}

export const fetchClosedStatus = (todoList: any): any => {
    const closedTickets = _.filter(todoList, (todo) => {
        return (todo.attributes.status)?.toLowerCase()=== "closed";
    })
    return closedTickets;
}

export const fetchAssigneeTodos = (todoList: any): any => {
    const assigneeInfo = _.map(todoList, (todo) => {
        return{
            id: todo.attributes.assigned_to_id,
            name: todo.attributes?.assigned_to_name
        }
    })
    return _.uniqBy(assigneeInfo, 'id');
}

export const fetchFullPropertyList = (todoList: any): any => {
    const propertyArray = _.filter(todoList, (todo) => {
        return todo.attributes.todoable_type === "Property";
    })

    return propertyArray;
}

export const fetchFullProjectList = (todoList: any): any => {
    const projectArray = _.filter(todoList, (todo) => {
        return todo.attributes.todoable_type === "Project";
    })

    return projectArray;
}

export const applyFilters = (todoList:any, selectedStatus: string, selectedAssignee: string, selectedProject: string, selectedProperty: string) => {

    const filteredList: any = [];

    todoList.forEach((todo: any)=> { 
        let matchingAllConditions = true;
        if(selectedStatus !== "any"){
            if(selectedStatus === 'overdue' && todo.attributes.status?.toLowerCase() === 'open'){
                if(!fetchPastDueDate(todo.attributes.due_at)){
                    matchingAllConditions = false;
                }
            } else if (todo.attributes.status?.toLowerCase() !== selectedStatus.toLowerCase()){
                matchingAllConditions = false;
            }
        }
        if(selectedAssignee !== ""){
            if(todo.attributes.assigned_to_id !== selectedAssignee){
                matchingAllConditions = false;
            }
        }
        if(selectedProject !== ""){
            if(todo.attributes.todoable_id !== selectedProject){
                matchingAllConditions = false;
            }
        }
        if(selectedProperty !== ""){
            if(todo.attributes.todoable_id !== selectedProperty){
                matchingAllConditions = false;
            }
        }
        if(matchingAllConditions){
            filteredList.push(todo);
        }
    })
    return filteredList;
}