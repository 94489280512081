import React, { Fragment, useEffect, useState } from "react";
import { DefinitionList, DefinitionItem, Row, Col, Address } from "components/Shared";
import { translate } from "lib";
// import { Map } from "components/Widgets";
import { Map } from "v2/components/Widgets";
import { Link } from "react-router-dom";
import { useGetAddresses, useGetBuildings } from "v2/hooks";
import _ from "lodash";
import moment from "moment";
import Datasource, { DatasourceResponse } from "lib/datasource";

interface Props {
	editable?: boolean;
	name?: string;
	tenancy?: tcpinpoint.Tenancy;
	onUpdate?: any;
	onClear?: any;
	property?: tcpinpoint.Property;
	className?: string;
	link?: string;
	icon?: string;
	setTenancyArea: any;
}

const formatBuildings = (buildings: any[]) =>
	_.map(buildings, building => ({
		name: building.name,
		coordinates: building.shape,
		area: building.area
	}));

const Tenancy = ({ name, tenancy: newTenancy, onClear, property, link, setTenancyArea }: Props) => {
	const propertyId = _.get(property, "id");
	const tenancyId = _.get(newTenancy, "id");
	const [, renderTrigger] = useState(moment());
	const [existingTenancy, setExistingTenancy ] = useState({} as any);
	const [existingTenancyDataSource] = useState(new Datasource({mainModelName: "tenancy", renderTrigger: renderTrigger}));
	const buildings = useGetBuildings(propertyId);
	const addresses = useGetAddresses(propertyId);

	useEffect(() => {
		let sub = existingTenancyDataSource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) setExistingTenancy(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [existingTenancyDataSource, setExistingTenancy]);
	
	useEffect(() => {
		if(!_.isNil(tenancyId)){
			existingTenancyDataSource.get("v2", `tenancies/${tenancyId}`, {});
		}
	}, [tenancyId, existingTenancyDataSource]);

	const tenancy = { ...existingTenancy, ...newTenancy };
	setTenancyArea(tenancy.area);

	if (!!tenancy) {
		return (
			<Fragment>
				{!!onClear && (
					<button type="button" className="close" aria-label="Close" onClick={onClear}>
						<span aria-hidden="true">×</span>
					</button>
				)}
				<Row>
					<Col>
						<h3>{translate(_.defaultTo(name, tenancy.name))}</h3>
						<h4>{_.get(tenancy, "building_name") || _.get(tenancy, "building.name")}</h4>
						<DefinitionList>
							<DefinitionItem title="area" value={tenancy.area} suffix="m²" />
							<DefinitionItem title="kind" value={translate(tenancy.kind)} />
							<DefinitionItem title="division" value={tenancy.division} />
							<DefinitionItem title="precinct" value={tenancy.precinct} />
						</DefinitionList>
						{/* TODO - give user the ability to change which tenancy their project belongs to IN THE LEASE CARD*/}
						{/* {onUpdate && editable && <Button onClick={onUpdate}>{translate("edit")}</Button>} */}
					</Col>
					<Col>
						<h4>
							{!!link ? (
								<Link to={`/properties/${_.get(property, "id")}`}>{_.get(property, "name")}</Link>
							) : (
								_.get(property, "name")
							)}
						</h4>
						<DefinitionList>
							{!!_.get(property, "owned_by") && (
								<DefinitionItem title="owned_by">
									{/* <Link to={`/companies/${_.get(property, "owned_by.id")}`}>{_.get(property, "owned_by")!.name}</Link> */}
									{_.get(property, "owned_by")!.name}
								</DefinitionItem>
							)}
							{!!_.get(property, "managed_by") && (
								<DefinitionItem title="managed_by">
									{/* <Link to={`/companies/${_.get(property, "managed_by")!.id}`}>{_.get(property, "managed_by")!.name}</Link> */}
									{_.get(property, "managed_by")!.name}
								</DefinitionItem>
							)}
							{!!_.get(property, "operated_by") && (
								<DefinitionItem title="developed_by">
									{/* <Link to={`/companies/${_.get(property, "operated_by")!.id}`}>{_.get(property, "operated_by")!.name}</Link> */}
									{_.get(property, "operated_by")!.name}
								</DefinitionItem>
							)}
						</DefinitionList>
						<Map addresses={addresses} buildings={formatBuildings(buildings)} height="394px" />
						<hr />
						<DefinitionList>
							{_.map(addresses, a => (
								<DefinitionItem key={_.get(a, "locality_name")} title={_.get(a, "locality_name")}>
									<Address key={a.id} address={a} />
								</DefinitionItem>
							))}
						</DefinitionList>
					</Col>
				</Row>
			</Fragment>
		);
	}
	return null;
};

export default Tenancy;
