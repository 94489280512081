import React, { useEffect, useState } from "react";
import { LoadingState } from "components/Shared";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import Datasource from "lib/datasource";
import axios from "v2/utils/axios";

const Show = ({ match }: RouteComponentProps) => {
	const [condition, setCondition] = useState();
	const condition_id = _.get(match, ["params", "id"], "");
	const task = _.get(condition, "task");
	const task_id = _.get(condition, ["task", "id"]);
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}conditions/${condition_id}`, 
				{ 
					params: {
						fields: JSON.stringify({
							task: ["id"]
						})
					}
				})
			.then((res) => {
				let condition = Datasource.getNormalizedData(res.data.jsonApiResponse, "condition");
				setCondition(condition);
			});
	}, [condition_id]);
	return !!task ? <Redirect to={{ pathname: `/tasks/${task_id}` }} push={true} /> : <LoadingState />;
};

export default withRouter(Show);
