import _ from "lodash";
import React, { memo, useState } from "react";
import validate from "validate.js";
import { Button } from "components/Shared";
import { Input } from "components/Inputs";

const constraints = { name: { presence: true } };

interface Props {
	extended: boolean;
	project: tcpinpoint.Project;
	onSubmit: (arg1: any) => void;
}

const Form = (props: Props) => {
	const { project, onSubmit } = props;
	const [data, setData] = useState(project);

	if (!!project) {
		return (
			<section>
				<Input
					name="name"
					defaultState={data}
					required={constraints}
					onChange={(name: string) => setData(_.assign({}, data, { name }))}
				/>
				<Input
					name="reference"
					defaultState={data}
					required={constraints}
					onChange={(reference: string) => setData(_.assign({}, data, { reference }))}
				/>
				<Button
					onClick={() => {
						const errors = validate(data, constraints);
						if (!!errors) {
							setData(_.assign({}, data, { errors }));
						} else {
							onSubmit(data);
						}
					}}
				/>
			</section>
		);
	}
	return null;
};

export default memo(Form);
