import Actor from "components/Activities/Actor";
import Context from "components/Activities/Context";
import { ErrorBoundary } from "components/Shared";
import { UserContext } from "context";
import { translate } from "lib";
import _ from "lodash";
import React, { useContext } from "react";

const CreateParty = ({ activity }: { activity: tcpinpoint.ActivityItem }) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const subject = _.get(activity, "attributes.item.subject", {});
	const forCurrentUser = subject.id === currentUser.id || subject.email === currentUser.email;
	const subjectUserName = forCurrentUser
		? translate("you")
		: `${activity.attributes.item.subject.firstname} ${activity.attributes.item.subject.surname}`;

	return (
		<ErrorBoundary>
			<Actor actor={activity.attributes.item.actor} /> {translate("added")}{" "}
			{activity.attributes.item.subject && (
				<span className="activity-item-subject activity-resource">{subjectUserName}</span>
			)}{" "}
			{translate("as")}{" "}
			{activity.attributes.item.relationship && (
				<span className="activity-item-relationship">{activity.attributes.item.relationship.name}</span>
			)}{" "}
			<Context context={activity.attributes.item.context} />{" "}
		</ErrorBoundary>
	);
};

export default CreateParty;
