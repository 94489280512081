import { combineReducers } from "redux";
import { Logger } from "aws-amplify";

//@ts-ignore
import pluralize from "pluralize";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import { translate } from "lib";
import LocalNotification from "lib/Notification";
import comments from "./comments";
import companies from "./companies";
import documents from "./documents";
import feed from "./feed";
import notifications from "./notifications";
import inspections from "./inspections";
import leases from "./leases";
import ledgers from "./ledgers";
import projects from "./projects";
import properties from "./properties";
import tasks from "./tasks";
import history from "./history";
import likes from "./likes";
import portfolios from "./portfolios";
import templates from "./templates";
import tenancies from "./tenancies";
import tenants from "./tenants";
import parties from "./parties";
import invites from "./invites";
import workflows from "./workflows";
import users from "./users";

const logger = new Logger("reducers/index.ts");

const defaultActivityState = {
	PROJECTS: false,
	PROPERTIES: false,
	TASKS: false,
	INSPECTIONS: false
};

const errors = (state = {}, generic_action: any) => {
	const action = generic_action as tcpinpoint.APIFailure;
	const matches = /(GET|POST|PUT|DELETE)_(.*)_(REQUEST|SUCCESS|FAILED)/.exec(action.type);
	if (!matches || !action.response) return state;
	const [, method, resource, requestState] = matches;
	if (requestState === "FAILED") {
		if (action.response.status !== 401) {
			logger.error(action.response);
			// new LocalNotification(
			// 	`${method.toLocaleLowerCase()} ${
			// 		action.response.statusText
			// 	} for ${resource.toLocaleLowerCase()}`.toLocaleLowerCase(),
			// 	"There Was An Error",
			// 	{ type: "error" }
			// );
			Sentry.captureException(action.response);
		}
	}
	return {
		...state,
		[resource]: requestState === "FAILED" ? { [method]: action.response } : false
	};
};

const uploads = (state: any = {}, generic_action: any) => {
	const action = generic_action as tcpinpoint.DocumentUpload;
	const matches = /(UPLOAD_DOCUMENT|POST_THUMBNAIL|GET_EXIF)_(REQUEST|PROGRESS|CANCELLED|SUCCESS|FAILED|FINISHED|CLEAR)/.exec(
		action.type
	);

	if (action.type === "FINALISE_DOCUMENT") {
		return {
			...state,
			[action.document.id]: false
		};
	}
	if (!matches || !action.document) return state;

	if (action.type === "UPLOAD_DOCUMENT_CLEAR") return _.omit(state, [action.document.id]);

	const [, method, requestState] = matches;
	return {
		...state,
		[action.document.id]: _.assign({}, action, { method, state: requestState }) as tcpinpoint.DocumentUpload
	};
};

const activity = (state = defaultActivityState, generic_action: any) => {
	const action = generic_action as tcpinpoint.ReduxAction;
	const matches = /(GET|POST|PUT|DELETE)_(.*)_(REQUEST|SUCCESS|FAILED)/.exec(action.type);
	if (!matches) return state;
	const [, method, requestResource, requestState] = matches;
	if ((method === "DELETE" || method === "POST") && requestState === "SUCCESS" && requestResource !== "EVENT") {
		new LocalNotification(translate(`${requestResource.toLowerCase()}_${method.toLocaleLowerCase()}`));
	}
	return {
		...state,
		[pluralize.plural(requestResource)]: requestState === "REQUEST"
	};
};

export default combineReducers({
	uploads,
	activity,
	history,
	errors,
	comments,
	likes,
	companies,
	documents,
	inspections,
	leases,
	ledgers,
	feed,
	portfolios,
	projects,
	properties,
	tasks,
	templates,
	invites,
	parties,
	tenancies,
	notifications,
	tenants,
	workflows,
	users
});
