import { Input, PlainTextInput } from "components/Inputs";
import { Button, LoadingState, ModalHeader } from "components/Shared";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import Modal from "react-modal";
//@ts-ignore
import validate from "validate.js";

const constraints = {
	display_name: {
		presence: {
			message: "^Must have a name"
		}
	}
};

interface Props extends ReactModal.Props {
	document: tcpinpoint.Document;
}

const EditModal = ({ document, isOpen, onRequestClose }: Props) => {
	const id = _.get(document, "id");
	const [data, setDocument] = useState(document);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "document", renderTrigger: renderTrigger }));

	const onUpdate = () => {
		const errors = validate(data, constraints);
		if (errors) {
			setDocument(_.assign({}, data, { errors }));
		} else {
			datasource.put(
				"v2",
				`documents/${id}`,
				{ body: { display_name: data.display_name, description: data.description } },
				{ updateIsLoding: true }
			);
		}
	};

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) {
				setDocument(response.normalizedMainModelResponse);
				if (onRequestClose) onRequestClose({} as React.MouseEvent);
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, onRequestClose]);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			className="archive"
			onRequestClose={onRequestClose}
			contentLabel="close-document"
		>
			<ModalHeader title="edit_document" onRequestClose={onRequestClose} />
			{datasource.isLoading ? (
				<LoadingState />
			) : (
				<>
					<section>
						<Input
							defaultState={data}
							required={constraints}
							name="display_name"
							onChange={(display_name: string) => setDocument(_.assign({}, data, { display_name }))}
						/>
						<PlainTextInput
							defaultState={data}
							//@ts-ignore
							required={constraints}
							name="description"
							onChange={(description: string) => setDocument(_.assign({}, data, { description }))}
						/>
					</section>
					<footer>
						<Button onClick={onUpdate} />
					</footer>
				</>
			)}
		</Modal>
	);
};

export default memo(EditModal);
