import React, { memo } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
	faCircle as falCircle,
	faCheckCircle as falCheckCircle,
	faTrash as falTrash,
	faBan as falBan,
} from "@fortawesome/pro-light-svg-icons";

interface Props {
	closed?: boolean;
	incomplete?: boolean;
	dismissed?: boolean;
	deleted?: boolean;
	is_defects?: boolean;
}

const Status = ({
	closed = false,
	incomplete = true,
	dismissed = false,
	deleted = false,
	is_defects = false,
}: Props) => {
	// const circleStyle = {
	// 	width: "50px",
	// 	height: "50px",
	// 	borderRadius: "50%",
	// 	display: "flex",
	// 	alignItems: "center",
	// 	justifyContent: "center",
	// 	fontSize: "34px",
	// 	border: "2px solid red",
	// };

	// const textStyle = {
	// 	color: "#4d4d4d",
	// };

	let iconClass = falCircle;
	if (!!deleted) {
		iconClass = falTrash;
	} else if (!!dismissed) {
		iconClass = falBan;
	} else {
		if (!!closed) {
			if (!incomplete) {
				iconClass = faCheckCircle;
			} else if (!!incomplete) {
				iconClass = faExclamationCircle;
			}
		} else if (!closed) {
			if (!incomplete) {
				iconClass = falCheckCircle;
			}
		}
	}

	return (
		<div className={classNames("status")}>
			{/* Commenting this now to replace with another D icon */}
			{/* {is_defects ? (
				<div style={circleStyle} title="Defects">
					<span style={textStyle}>D</span>
				</div>
			) : (
				<FontAwesomeIcon icon={iconClass} />
			)} */}

			<FontAwesomeIcon icon={iconClass} />
		</div>
	);
};

export default memo(Status);
