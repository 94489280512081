import { Input } from "components/Inputs";
import { Button, ModalHeader } from "components/Shared";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState, useContext } from "react";
import Modal from "react-modal";
//@ts-ignore
import validate from "validate.js";
import { LastMomentTriggerAPIContext } from "context";

const constraints = {
	name: {
		presence: true
	}
};

interface Props extends ReactModal.Props {
	portfolio?: tcpinpoint.Portfolio;
}

const PortfolioModal = ({ isOpen, onRequestClose, portfolio = {} as tcpinpoint.Portfolio }: Props) => {
	const { setLastMoment } = useContext(LastMomentTriggerAPIContext);
	const portfolioId = _.get(portfolio, "id");
	const [name, setName] = useState(!!portfolio ? portfolio.name : "");
	const [errors, setErrors] = useState();
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "portfolio", renderTrigger: renderTrigger }));
	const createPortfolio = () => datasource.post("v2", `portfolios`, { body: { name } });
	const updatePortfolio = () =>
		datasource.put("v2", `portfolios/${portfolioId}`, { body: _.assign({}, portfolio, { name }) });
	const onClickSubmit = (event: React.MouseEvent) => {
		const errors = validate({ name }, constraints);
		if (!errors) {
			_.isNil(portfolioId) ? createPortfolio() : updatePortfolio();
			//@ts-ignore
			onRequestClose(event);
		} else {
			setErrors(errors);
		}
	};
	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) setLastMoment(moment());
		});
		return () => sub.unsubscribe();
	}, [datasource, setLastMoment]);

	// for update case, get original name
	useEffect(() => {
		if (!_.isNil(_.get(portfolio, "id"))) setName(_.get(portfolio, "name", ""));
	}, [portfolio, setName]);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className="new-portfolio-modal"
			contentLabel="Create Portfolio"
		>
			<ModalHeader title="portfolio" onRequestClose={onRequestClose} />
			<section>
				<Input defaultState={{ name, errors }} name="name" onChange={setName} required={constraints} />
			</section>
			<footer>
				<Button onClick={onClickSubmit} />
			</footer>
		</Modal>
	);
};

export default memo(PortfolioModal);
