import React from "react";
import PropTypes from "prop-types";
import { Feedback, Label } from "components/Inputs";
import classNames from "classnames";

export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultState[props.name],
      ignoreError: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let value = false;
    if (nextProps.defaultState && nextProps.name) {
      value = !!nextProps.defaultState[nextProps.name];
    }
    this.setState({ value });
  }

  onUpdate(event) {
    const { onChange } = this.props;
    const { value } = this.state;
    this.setState({ value: !value }, () => {
      if (onChange) {
        onChange(!value);
      }
    });
  }

  render() {
    const { children, name, required, className } = this.props;
    const { value } = this.state;
    return (
      <div
        className={classNames("form-group", `form-control-checkbox`, `form-control-${name}`, className, {
          "is-required": required && required[name]
        })}
      >
        <Label {...this.props} for={name} className="form-check-label">
          <input
            name={name}
            type="checkbox"
            className="form-check-input"
            checked={value ? "checked" : ""}
            onChange={event => this.onUpdate(event)}
            onClick={event => this.onUpdate(event)}
          />
        </Label>
        <Feedback {...this.state} {...this.props} />
        {children && <p className="form-control-child">{children}</p>}
      </div>
    );
  }
}

Checkbox.defaultProps = {
  onUpdate() {},
  name: "text",
  type: "text",
  className: "",
  placeholder: "",
  autoComplete: true,
  autoFocus: false,
  defaultState: {
    errors: []
  }
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  hint: PropTypes.node,
  type: PropTypes.string,
  limit: PropTypes.number,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.bool,
  autofocus: PropTypes.bool,
  defaultState: PropTypes.shape({
    errors: PropTypes.shape({})
  })
};
