import React from "react";
import ReactModal from "react-modal";
import { Button } from "components/Shared";
import { Input } from "components/Inputs";

export default class Modal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  onSubmit(event) {
    const { onUpdate, doCloseModal } = this.props;
    event.preventDefault();
    if (onUpdate) {
      onUpdate(this.state);
    }
    doCloseModal && doCloseModal();
  }

  render() {
    const { isOpen, doCloseModal, doAfterOpenModal } = this.props;
    return (
      <ReactModal isOpen={isOpen} onAfterOpen={doAfterOpenModal} onRequestClose={doCloseModal} contentLabel="Roles">
        <header>
          <h1>Add Role</h1>
        </header>
        <section>
          <Input
            label="Role"
            name="roles"
            defaultState={this.state}
            multi
            onChange={roles => this.setState(roles)}
            collection="roles"
          />
        </section>
        <footer>
          <Button onClick={event => this.onSubmit(event)} />
        </footer>
      </ReactModal>
    );
  }
}
