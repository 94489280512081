import { createSelector, createSelectorCreator, defaultMemoize } from "reselect";
import { isEqual } from 'lodash'
import _ from 'lodash';

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
)

export const findCallback = (entities: any, id: any) => _.find(entities, { id });
export const filterCallback = (attribute: string) => (entities: any, id: any) => _.filter(entities, { [attribute]: id })

export const makeEntitiesSelector = (entity_name: any) => createSelector(
  (store: tcpinpoint.Store) => _.get(store, [entity_name]),
  entities => entities
)

export const makeEntitySelectorWithIdInProps = (entity_name: string, callback: any) => () => createSelector(
  makeEntitiesSelector(entity_name),
  (_: any, id: any) => id,
  (entities, id) => callback(entities, id)
)

export const deriveEntitySelectorWithIdInProps = (selector: any, callback: any) => () => createSelector(
  selector,
  (_: any, id: any) => id,
  (entities, id) => callback(entities, id)
)



