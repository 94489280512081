import React, {ReactNode, memo} from "react";


const Container = (props: {
  width?: string;
  radius?: string;
  backGround?: string;
  children?: ReactNode;
  display?: string
}) => {
  const {radius = "2rem", width="100%", backGround, display} = props;
  return (
    <div
      className="progress"
      style={{
        borderRadius: radius,
        width: width,
        backgroundColor: backGround,
        display,
      }}
    >
      {props.children}
    </div>
  );
};

export default memo(Container)
