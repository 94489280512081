import { UserContext } from "context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

export const useAppClass = (className: string) => {
	useEffect(() => {
		//@ts-ignore
		document.getElementById("app").classList.add(className);

		return () => {
			//@ts-ignore
			document.getElementById("app").classList.remove(className);
		};
	});
};

export const useSubscribeChannel = (channel: string, callback: Function) => {
	const { cable } = useContext(UserContext) as { cable: any };
	if (cable) {
		cable.subscriptions.create(
			{ channel: channel },
			{
				connected: () => {
					console.log(`${channel}: connected`);
				},
				received: callback
			}
		);
	}
};

/** useState does not merge updated state, this is a wrapper around set method of useState
 *  to auto merge updated state
 */
export const useMergedState = <S>(initialState: S | (() => S)) => {
	const [state, setState] = useState(initialState);
	const setMergedState = (newPartialState: any, merge: boolean) => {
		if (typeof newPartialState === "function") {
			setState(newPartialState);
		} else {
			setState((prevState: any) => (merge ? { ...prevState, ...newPartialState } : newPartialState));
		}
	};
	return [state, setMergedState];
};

export const setMergedStateWrapper = (setState: Dispatch<SetStateAction<any>>) => {
	let setMergedState = (newPartialState: any, merge = true) => {
		if (typeof newPartialState === "function") {
			setState(newPartialState);
		} else {
			setState((prevState: any) => (merge ? { ...prevState, ...newPartialState } : newPartialState));
		}
	};

	return setMergedState;
};

export const getAuthTokenFromCookie = () => {
	var token = '';
	const oauth_token = document.cookie.match(`(^|;)\\s*OAUTH\\s*=\\s*([^;]+)`);
	if (oauth_token !== null) {
		token = oauth_token[0].split('=')[1];
	}
	return token;
}

export const getUserIdFromStorage = () => {
	var userId = localStorage.getItem('userId');	
	return userId;
}

