import React from "react";
import classNames from "classnames";
import { LoadingAnimation } from "components/Shared";

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false
    };
  }

  componentDidMount() {
    document.getElementById("app").classList.add("is-loading");
  }

  UNSAFE_componentWillReceiveProps() {
    document.getElementById("app").classList.add("is-loading");
  }

  componentWillUnmount() {
    document.getElementById("app").classList.remove("is-loading");
  }

  render() {
    const { missing, unavailable, error } = this.props;

    return (
      <div
        className={classNames("loading", {
          "loading-loading": !missing && !unavailable && !error,
          "loading-missing": missing,
          "loading-unavailable": unavailable || error
        })}
      >
        <LoadingAnimation {...this.props} />
      </div>
    );
  }
}
