import { getProject } from "actions";
import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

/**
 * Create new Ledger
 * @param ledger Ledger
 */
export const postLedger = memoAC((ledger: { name: string; cap_cents: number; project_id: string }) => (dispatch: any) => {
	dispatch({ type: "POST_LEDGER_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}ledgers`, { ledger }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "POST_LEDGER_SUCCESS", resource: res.data.ledger });
		dispatch(getLedger(res.data.ledger.id));
	})
	.catch((e) => { dispatch({ type: "POST_LEDGER_FAILED", response: e }); });
});

/**
 * Update a Ledger
 * @param ledger Ledger
 */
export const putLedger = memoAC((ledger: { id: string; name: string; cap_cents: number }) => (dispatch: any) => {
	dispatch({ type: "PUT_LEDGER_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}ledgers/${ledger.id}`, { ledger }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "PUT_LEDGER_SUCCESS", resource: res.data.ledger });
		dispatch(getLedger(res.data.ledger.id));
	})
	.catch((e) => { dispatch({ type: "PUT_LEDGER_FAILED", response: e }); });
});
/**
 * Delete a Ledger
 * @param id Ledger ID
 */
export const deleteLedger = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "DELETE_LEDGER_REQUEST" });
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}ledgers/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getLedgers());
		dispatch({ type: "DELETE_LEDGER_SUCCESS", resource: { id } });
	})
	.catch((e) => { dispatch({ type: "DELETE_LEDGER_FAILED", response: e }); });
});

/**
 * Get a Ledger
 * @param id Ledger ID
 */
export const getLedger = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_LEDGER_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}ledgers/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getProject(res.data.ledger.project_id));
		dispatch({ type: "GET_LEDGER_SUCCESS", resource: res.data.ledger });
	})
	.catch((e) => { dispatch({ type: "GET_LEDGER_FAILED", response: e }); });
});

/**
 * Get all Ledgers belonging to a Project
 * @param id Project ID
 */
export const getProjectLedgers = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_LEDGER_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/${id}/ledgers`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_LEDGERS_SUCCESS", resource: res.data.ledgers }))
	.catch((e) => dispatch({ type: "GET_LEDGERS_FAILED", response: e }));
});

/**
 * Get Ledgers
 */
export const getLedgers = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_LEDGERS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}ledgers`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_LEDGERS_SUCCESS", resource: res.data.ledgers }))
	.catch((e) => dispatch({ type: "GET_LEDGERS_FAILED", response: e }));
});
