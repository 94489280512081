import { Breadcrumbs, Header, LoadingState, View, Workspace } from "components/Shared";
import Datasource, { DatasourceActionType, DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Form from "./Form";

interface Props extends RouteComponentProps {
	match: any;
}

const EditProject = ({ match, history }: Props) => {
	const { id } = match.params;
	const [, renderTrigger] = useState(moment());
	const [project, setProject] = useState({} as any);
	const [datasource] = useState(new Datasource({ mainModelName: "project", renderTrigger: renderTrigger }));
	const onSubmit = useCallback(
		(project: any) => datasource.put("v2", `projects/${id}`, { body: project }, { updateIsLoding: true }),
		[datasource, id]
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setProject(response.normalizedMainModelResponse);
			if (response.actionType === DatasourceActionType.PUT) history.goBack();
		});
		return () => sub.unsubscribe();
	}, [datasource, history]);

	useEffect(() => {
		if (!_.isEmpty(id))
			datasource.get("v2", `projects/${id}`, {
				queryStringParameters: { fields: JSON.stringify({ project: ["name", "reference"] }) },
			});
	}, [datasource, id]);

	return (
		<React.Fragment>
			<Breadcrumbs
				items={[{ title: "projects" }, { title: project.name, link: `/projects/${id}` }, { title: "edit" }]}
			/>

			{datasource.isLoading ? (
				<LoadingState />
			) : (
				<>
					<Header title={project.name} stamp="project" />
					<Workspace id="project-edit">
						<View>
							<Form extended project={project} onSubmit={onSubmit} />
						</View>
					</Workspace>
				</>
			)}
		</React.Fragment>
	);
};

export default EditProject;
