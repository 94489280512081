import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { useCurrentUserInfo } from "routes/hooks";
import { setSmidgeUser } from "./utils/session";

export const useParams = (callback: (params: any) => any, dependency: any[]) => {
	const updateParams = useCallback(callback, dependency);
	const [params, setParams] = useState<any>({});
	useEffect(() => setParams(updateParams), [updateParams]);
	return params;
};

export const useGetEntity = (
	name: string,
	id?: string,
	queryStringParameters?: any,
	process: (nomalized: any) => any = entity => entity
) => {
	const [, renderTrigger] = useState(new Date());
	const [datasource] = useState(new Datasource({ mainModelName: name, renderTrigger: renderTrigger }));
	const [entity, setEntity] = useState(null);
	useEffect(() => {
		if (!id) return;
		const sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			const res = !!process ? process(response.normalizedMainModelResponse) : response.normalizedMainModelResponse;
			setEntity(res);
		});
		return () => sub.unsubscribe();
	}, [datasource, id, process]);

	useEffect(() => {
		if (_.every(queryStringParameters, v => !!v) && !_.isEmpty(queryStringParameters) && !!id)
			datasource.get("v2", `${pluralize(name!)}/${id}`, {
				queryStringParameters
			});
	}, [datasource, id, name, queryStringParameters]);
	return [entity, setEntity, datasource];
};

export const useGetEntities = (
	name: string,
	queryStringParameters?: any,
	process: (nomalized: any) => any = entities => entities
): any => {
	const [, renderTrigger] = useState(new Date());
	const [datasource] = useState(new Datasource({ mainModelName: name, renderTrigger: renderTrigger }));
	const [entities, setEntities] = useState(null);
	useEffect(() => {
		const sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setEntities(process ? process(response.normalizedMainModelResponse) : response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource, process]);

	useEffect(() => {
		if (_.every(queryStringParameters, v => !!v) && !_.isEmpty(queryStringParameters))
			datasource.get("v2", `${pluralize(name)}`, {
				queryStringParameters
			});
	}, [datasource, name, queryStringParameters]);

	return entities;
};

export const useGetTenancy = (tenancy_id?: string, queryParameters?: any) => {
	const tenancyParams = useParams(
		params => ({
			...params,
			...queryParameters
		}),
		[]
	);
	const [tenancy, setTenancy, datasource] = useGetEntity("tenancy", tenancy_id, tenancyParams);
	return { tenancy, datasource, setTenancy } as { tenancy: any; datasource: any; setTenancy: any };
};

export const useGetLease = (lease_id: string, queryParameters?: any) => {
	const leaseParams = useParams(
		params => ({
			...params,
			...queryParameters
		}),
		[]
	);
	const [lease, setLease, datasource] = useGetEntity("lease", lease_id, leaseParams);
	return { lease, datasource, setLease } as { lease: any; datasource: any; setLease: any };
};

// TODO: get single building should depend on entity show API endpoint rather than the index endpoint.
export const useGetBuilding = (property_id: string, building_id: string, name: string = "building") => {
	const buildingsParams = useParams(params => ({ ...params, property_id, per_page: 100 }), [property_id]);
	const building = useGetEntities(name, buildingsParams, buildings => _.find(buildings, { id: building_id }));
	return building;
};

export const useGetBuildings = (property_id?: string, name: string = "building") => {
	const buildingsParams = useParams(params => ({ ...params, property_id, per_page: 100 }), [property_id]);
	const buildings = useGetEntities(name, buildingsParams);
	return buildings;
};

export const useGetAddresses = (property_id?: string, name: string = "address") => {
	const addressesParams = useParams(params => ({ ...params, property_id, per_page: 100 }), [property_id]);
	const addresses = useGetEntities(name, addressesParams);
	return addresses;
};

export const useAddClassNames = (selector: string, styles: string[]) => {
	useEffect(() => {
		const element = document.querySelector(selector);
		for (let style of styles) {
			element?.classList.add(style);
		}

		return () => {
			for (let style of styles) {
				element?.classList.remove(style);
			}
		};
	}, [selector, styles]);
};

export const useOverrideStyles = (selector: string, styles: { [key: string]: string }) => {
	useEffect(() => {
		const elements = document.querySelectorAll(selector);
		for (let e of Array.from(elements)) {
			for (let key in styles) {
				(e as any).style[key] = styles[key];
			}
		}
		return () => {
			for (let e of Array.from(elements)) {
				for (let key in styles) {
					(e as any).style[key] = styles[key];
				}
			}
		};
	}, [selector, styles]);
};

export const useCurrentUserForSmidge = () => {
	const [currentUser] = useCurrentUserInfo();
	useEffect(() => {
		if (!_.isEmpty(currentUser))
			setSmidgeUser({
				id: currentUser.id,
				email: currentUser.email,
				name: `${currentUser.firstname} ${currentUser.surname}`
			});
	}, [currentUser]);
};
