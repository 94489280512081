import React from "react";
import { lazy } from "react";
import Card from "./Card";
import Facilities from "./Facilities";
import Header from "./Header";
import Icon from "./Icon";
import Ledger from "components/Ledgers/Create/Ledgers";
import Ledgers from "components/Ledgers/Create/Ledger";
import List from "./List";
import Lookup from "./Lookup";
import Modal from "./Modal";
import Parties from "./Parties";
import Phase from "./Phase";
import Quotes from "components/Ledgers/Create/Quotes";
import Report from "./Report";
import Reports from "./Reports";
import Select from "./Select";
import Task from "./Task";
import Tasks from "./Tasks";

const LazyShow = lazy(() => import("./Show"));
const Show = (props: any) => <LazyShow {...props} />;

const LazyPhase = lazy(() => import("./Phases"));
const Phases = (props: any) => <LazyPhase {...props} />;

export {
	Card,
	Facilities,
	Header,
	Icon,
	Ledger,
	Ledgers,
	List,
	Lookup,
	Modal,
	Phase,
	Phases,
	Quotes,
	Report,
	Reports,
	Select,
	Show,
	Task,
	Tasks,
	Parties
};
