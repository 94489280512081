import React, { useState, memo } from "react";
import { View, ListGroup, ListGroupControl } from "components/Shared";
import { Row } from "components/Users";
import _ from "lodash";

interface Props {
	users: tcpinpoint.User[];
	name?: string;
	children?: any;
	isAdministrator?: boolean;
}

const Collection = (props: Props) => {
	const { users, children } = props;
	const [sortBy, setSortBy] = useState("surname");
	const collection = _.uniqBy(_.sortBy(users, sortBy), "id");

	return (
		<View resource="users" hideTitle={true}>
			<ListGroupControl sortOptions={["firstname", "surname"]} onSortChange={setSortBy} selectedSort={sortBy} />
			<ListGroup
				resource="users"
				collection={collection}
				renderRow={user => <Row key={user.id} {...{ user }} />}
			/>
			{children}
		</View>
	);
};

export default memo(Collection);
