import CollectionDynamic from "./CollectionDynamic";
import Datasource, { DatasourceResponse } from "lib/datasource";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Breadcrumbs, Header, Workspace } from "components/Shared";
import { RouteComponentProps } from "react-router-dom";
import { translate } from "lib";

const Templates = ({ history }: any) => {
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "template", renderTrigger: renderTrigger }));

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) history.push(`/templates/${response.normalizedMainModelResponse.id}`);
		});
		return () => sub.unsubscribe();
	}, [datasource, history]);

	const createTemplate = useCallback(
		(kind: string) => {
			datasource.post("v2", `templates`, { body: { template: { kind } } }, { updateIsLoding: true });
		},
		[datasource]
	);

	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: "templates" }]} />
			<Header
				title={translate("list_templates")}
				stamp={"templates"}
				toolbar={[
					{
						label: "start_new_retail_workflow_template",
						icon: "new",
						onClick: () => createTemplate("standard-retail-workflow")
					},
					{
						label: "start_new_commercial_workflow_template",
						icon: "new",
						onClick: () => createTemplate("standard-commercial-workflow")
					}
				]}
			/>
			<Workspace id="templates" className="list">
				<CollectionDynamic />
			</Workspace>
		</React.Fragment>
	);
};

const MemoizedTemplates = memo(Templates);
export default ({ history }: RouteComponentProps) => <MemoizedTemplates history={history} />;
