import { postDocument } from "actions";
import classNames from "classnames";
import { CollectionDynamic, Download, Upload } from "components/Documents";
import { View } from "components/Shared";
import download from "downloadjs";
import _ from "lodash";
import React, { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

interface Props {
	name?: any;
	resource: any;
	download?: any;
	hideTitle?: any;
	hideEmpty?: any;
	title?: any;
	subtitle?: any;
	help?: any;
	onUpdate?: (file: any) => void;
	hint?: any;
	upload?: any;
	viewBy?: any;
	hideFilter?: any;
	sort?: any;
	visibleTo?: any;
	queryStringParameters?: any;
	momentUpdate?: any;
	lastMoment?: any;	
}
const Documents = (props: Props) => {
	const updateLastUpload = function (datetime:any){
		setLastUpload(datetime);
		momentUpdate(moment());
	};	
	const [lastUpload, setLastUpload] = useState("")
	const dispatch = useDispatch();
	const [documents, setDocuments] = useState([]);
	const {
		hideTitle,
		resource,
		hideEmpty,
		title,
		subtitle,
		help,
		hint,
		upload = true,
		visibleTo,
		onUpdate,
		queryStringParameters,
		momentUpdate,
		lastMoment
	} = props;

	const divRef = useRef(null);
	const className = classNames("drop-target", {});
	const onDrop = (event: any) => {
		event.preventDefault();
		const { files } = event.dataTransfer;
		_.forEach(files, (file: any) => {
			!!onUpdate && onUpdate(file);
			// TDOO: update to v2
			dispatch(postDocument(resource, file, "", updateLastUpload));
		});
		//@ts-ignore
		divRef.current.classList.remove("on-dragover");
	};

	const onDragOver = (event: any) => {
		if (!!divRef && !!divRef.current) {
			//@ts-ignore

			event.preventDefault();
			//@ts-ignore
			divRef.current.classList.add("on-dragover");
		}
	};

	const onDragLeave = (event: any) => {
		if (divRef && divRef.current) {
			//@ts-ignore

			event.preventDefault();
		}
		//@ts-ignore
		divRef.current.classList.remove("on-dragover");
	};

	const onLoad = useCallback((documents: any) => setDocuments(documents), [setDocuments]);

	if (hideEmpty && !_.size(documents)) return null;

	return (
		<View
			resource="documents"
			help={help}
			title={hideTitle ? null : title}
			hint={hint}
			subtitle={subtitle ? null : subtitle}
			hideTitle={true}
		>
			<div
				className={className}
				ref={divRef}
				onDrop={onDrop}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				data-role="drag-drop-container"
			>
				<div className="documents-tool">
					{!!upload && <Upload onUpdate={onUpdate} postCallback={updateLastUpload} resource={resource} />}

					{!!download && !!_.size(documents) && <Download documents={documents} />}
				</div>
				<CollectionDynamic queryStringParameters={queryStringParameters} onLoad={onLoad} docDispatch={lastUpload} lastMoment={lastMoment} />
				{visibleTo && <p className="muted">{visibleTo}</p>}
			</div>
		</View>
	);
};

export default memo(Documents);
