import React from "react";
import { View } from "components/Shared";
import { Form, Card } from "components/Tenants";

const Select = ({ onUpdate, tenant }) => (
	<View resource="tenant" className={"form-group"}>
		{!!tenant ? <Card tenant={tenant} onClear={() => onUpdate(false)} /> : <Form onSubmit={onUpdate} />}
	</View>
);

export default Select;
