import React, { useState, memo } from "react";
import classNames from "classnames";
import { translate } from "lib";
import { setHash } from "lib";
import _ from "lodash";

interface Props {
	onViewChange?: (arg: string) => void;
	onFilterChange?: (arg: string) => void;
	onSortChange?: (arg: string) => void;
	selectedSort?: string;
	selectedFilter?: string;
	selectedView?: string;
	sortOptions?: (string | false)[];
	filterOptions?: (string | false)[];
	viewOptions?: (string | false)[];
}

const ListGroupControl = ({
	onViewChange,
	onFilterChange,
	onSortChange,
	selectedSort,
	selectedFilter,
	selectedView,
	sortOptions,
	filterOptions,
	viewOptions,
}: Props) => {
	const [filterOpen, setFilterOpen] = useState(false);

	if (!onSortChange && !onFilterChange && !onViewChange) return null;

	return (
		<div className="sort-filter-bar-container">
			<dl>
				{!!selectedView && (
					<React.Fragment>
						<dt>{translate("view_as")}</dt>
						<dd>{translate(selectedView)}</dd>
					</React.Fragment>
				)}
				{!!selectedFilter && (
					<React.Fragment>
						<dt>{translate("filter_by")}</dt>
						<dd>{translate(selectedFilter)}</dd>
					</React.Fragment>
				)}
				{!!selectedSort && (
					<React.Fragment>
						<dt>{translate("sort_by")}</dt>
						<dd>{translate(selectedSort)}</dd>
					</React.Fragment>
				)}
			</dl>
			<button onClick={() => setFilterOpen(!filterOpen)}>
				<i className="fas fa-fw fa-grip-horizontal" />
				<i className="fas fa-fw fa-sort" />
				<i className="fas fa-fw fa-filter" />
			</button>
			{!!filterOpen && (
				<div className="sort-filter-bar">
					<button onClick={() => setFilterOpen(false)} className="close">
						<span aria-hidden="true">&times;</span>
					</button>
					{onViewChange && !!_.compact(viewOptions).length && (
						<div className="view">
							<label>
								<i className="fa-fw fas fa-grip-horizontal" />
								{translate("view_as")}
							</label>
							<div className="btn-toolbar" role="toolbar" aria-label="Change View">
								<div className="btn-group" role="group" aria-label="Group">
									{_.map(_.compact(viewOptions), (option) => (
										<button
											type="button"
											key={option}
											className={classNames("btn", "btn-secondary", "btn-sm", {
												active: option === selectedView,
											})}
											onClick={() => {
												setHash("viewAs", option);
												onViewChange(option);
											}}
										>
											{translate(option)}
										</button>
									))}
								</div>
							</div>
						</div>
					)}
					{onSortChange && !!_.compact(sortOptions).length && (
						<div className="sort">
							<label>
								<i className="fa-fw fas fa-sort" />
								{translate("sort_by")}
							</label>
							<div className="btn-toolbar" role="toolbar" aria-label="Sort List by Options">
								<div className="btn-group" role="group" aria-label="Group">
									{_.map(_.compact(sortOptions), (option) => (
										<button
											type="button"
											key={option}
											className={`btn btn-secondary btn-sm ${option === selectedSort ? "active" : ""}`}
											onClick={() => {
												setHash("sortBy", option);
												onSortChange(option);
											}}
										>
											{translate(option)}
										</button>
									))}
								</div>
							</div>
						</div>
					)}
					{onFilterChange && !!_.compact(filterOptions) && (
						<div className="filter">
							<label>
								<i className="fa-fw fas fa-filter" />
								{translate("filter_by")}
							</label>
							<div className="btn-toolbar" role="toolbar" aria-label="Filter by Options">
								<div className="btn-group" role="group" aria-label="Group">
									{_.map(_.compact(filterOptions), (option) => {
										if (Array.isArray(option)) {
											return _.map(option, (o) => (
												<button
													type="button"
													key={o}
													className={classNames("btn", "btn-secondary", "btn-sm", {
														active: o === selectedFilter,
													})}
													onClick={() => {
														setHash("filteredBy", o);
														onFilterChange(o);
													}}
												>
													{translate(o)}
												</button>
											));
										} else {
											return (
												<button
													type="button"
													key={option}
													className={classNames("btn", "btn-secondary", "btn-sm", {
														active: option === selectedFilter,
													})}
													onClick={() => {
														setHash("filteredBy", option);
														onFilterChange(option);
													}}
												>
													{translate(option)}
												</button>
											);
										}
									})}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default memo(ListGroupControl);
