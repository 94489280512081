import React, { memo } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faCircle as falCircle,
  faCheckCircle as falCheckCircle,
  faTrash as falTrash,
  faBan as falBan
} from "@fortawesome/pro-light-svg-icons";
import Circle from "./Circle";

const Status = ({ type, id, closed = false, incomplete = true, dismissed = false, deleted = false, progress = 0 }: any) => {

  let iconClass = falCircle;
  if (!!deleted) {
    iconClass = falTrash;
  } else if (!!dismissed) {
    iconClass = falBan;
  } else {
    if (!!closed) {
      if (!incomplete) {
        iconClass = faCheckCircle;
      } else if (!!incomplete) {
        iconClass = faExclamationCircle;
      }
    } else if (!closed) {
      if (!incomplete) {
        iconClass = falCheckCircle;
      }
    }
  }

  return (
    <Circle
      number={ progress || 0}
      width={54}
      height={54}
      isTextShown={false}
      strokeColor={"#00af3d"}
    >
      <div className={`${classNames("status")} m-0`}>
        <FontAwesomeIcon icon={iconClass} />
      </div>
    </Circle>
  );
};

export default memo(Status);
