import React, { useState, Fragment, memo } from "react";
import { Board, Button, Spinner, Help } from "v2/components/shared";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Steps, Step } from "v2/components/shared/Steps";
import { Form, Item as FormItem } from "v2/components/shared/Form";
import { isEmail, isValidPassword, getValidationStatus } from "v2/utils";
import classNames from "classnames";
import { useAddClassNames } from "v2/hooks";
import LocalNotification from "lib/Notification";
import axios from "axios";

const headers = { 'Content-Type': 'application/json' };

const defaultData = {
	email: "",
	password: "",
	code: ""
};

const PasswordReset = (props: RouteComponentProps) => {
	useAddClassNames("input", ["font-mont"]);
	const { history } = props;
	const [current, setCurrent] = useState(0);
	const [data, setData] = useState(defaultData);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const [codeSent, setCodeSent] = useState(false);

	const emailValidated = getValidationStatus(data, error, ["email"]);
	const passwordAndCodeValidated = getValidationStatus(data, error, ["password", "code"]);

	const onNext = () => {
		if (!codeSent) {
			setLoading(true);

			axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}reset_password_verification`, { email: data.email }, { headers })
				.then((res) => {
					setCodeSent(true);
					setCurrent(current + 1);
				})
				.catch((e) => {
					if (e.response.data.errors === "LimitExceededException") {
						!loading && new LocalNotification(e.response.data.errors, "Hold On", "error");
						setTimeout(() => {
							setData({ ...data, email: "" });
						}, 4000);
					} else {
						setError({ ...error, email: "We don't recognise this email." });
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
		!!codeSent && setCurrent(current + 1);
	};

	const onReset = () => {
		const { email, code, password } = data;
		setLoading(true);

		axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}reset_password`, 
				{ 
					email,
					pin: code,
					password
				},
				{ headers }
			)
			.then((res) => {
				!loading && new LocalNotification("Redirecting to SignIn Page", "Reset Password Done!", "success");
				setTimeout(() => {
					history.push(`/signin?email=${email}`);
				}, 1000);
			})
			.catch((e) => {
				if (e.response.data.errors === "CodeMismatchException") {
					setError({ ...error, code: "Verification code doesn't match!" });
				}
				if (e.response.data.errors === "PasswordValidationException") {
					setError({ ...error, password: "Can't reset with the provided password." });
				}
				if (e.response.data.errors === "LimitExceededException") {
					!loading && new LocalNotification(e.message, "Hold On", "error");
					setTimeout(() => {
						setData({ ...data, code: "", password: "" });
					}, 4000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Board className="lg:max-h-2/3 lg:w-1/2 min-h-60 lg:max-w-screen-xl lg:min-w-19">
			<div className="py-4 lg:py-14 flex-1 lg:relative">
				<Steps current={current} setCurrent={setCurrent} NavClassName="lg:px-20">
					<Step title="Confirm Identity" className="w-4/5 mt-6 lg:mt-12 mx-auto lg:w-3/4" clickable={emailValidated}>
						{loading ? (
							<div className="flex flex-col justify-center min-h-35">
								<Spinner width="100px" height="100px" className="mx-auto" />
							</div>
						) : (
							<Fragment>
								<div className="mb-10 lg:w-1/2 lg:mx-auto">
									<div className="form-title text-black-100 font-semibold text-26 leading-9 text-center">
										Reset Your Password
									</div>
									<div className="text-center text-black-100 text-14 lg:text-16 leading-6 mt-4 font-normal">
										Enter your work email and we'll send you a verification code to reset your password.
									</div>

									<Form
										data={data}
										error={error}
										setData={setData}
										setError={setError}
										className="my-5"
										onSubmit={onNext}
									>
										<FormItem
											label="Work Email"
											name="email"
											type="text"
											rules={[
												{
													message: "We don't recognize this email.",
													validator: (email: string) => isEmail(email)
												}
											]}
										/>
									</Form>
									<Help small />
								</div>
								<Button
									label="Next"
									className={classNames("float-right text-white mt-10", {
										"bg-blue-100 hover:shadow-hover  shadow-button": emailValidated,
										"cursor-not-allowed bg-black-5 text-black-20  font-semibold": !emailValidated
									})}
									onClick={onNext}
									disabled={!emailValidated}
								/>
							</Fragment>
						)}
					</Step>
					<Step
						title="Enter New Password"
						className="w-4/5 mt-6 lg:mt-12 mx-auto lg:w-3/4"
						clickable={passwordAndCodeValidated}
					>
						<div className="mb-10 lg:w-1/2 lg:mx-auto">
							<div className="form-title text-black-100 font-semibold text-26 leading-9 text-center">
								Enter New Password
							</div>
							<div className="text-center text-black-100 text-14 lg:text-16 leading-6 mt-4 font-normal">
								We've sent you a code. If you don't see it, check your spam folder {/* for: */}
								{/* TODO: verification email should be from @tcpinpoint.com */}
								{/* <div className="mt-2 text-black-80 font-medium">hello@tcpinpoint.com</div> */}
							</div>

							<Form data={data} error={error} setData={setData} setError={setError} className="mt-5" onSubmit={onReset}>
								<FormItem
									label="Verification Code"
									name="code"
									type="text"
									rules={[
										{
											message: "Verification code should be 6 digits.",
											validator: (code: string) => code.length === 6
										}
									]}
									loading={loading}
								/>
								<FormItem
									label="New Password"
									name="password"
									type="password"
									hint="All symbols are allowed. Minimum of 8 characters. Must contain a number, an uppercase letter, and a special character"
									rules={[
										{
											message: "Password should be at least 8 characters. It should contain a number, an uppercase letter, and a special character",
											validator: isValidPassword
										}
									]}
									loading={loading}
								/>
							</Form>
						</div>
						<Button
							label="Reset Password"
							className={classNames("float-right text-white mt-10 w-35", {
								"bg-blue-100 hover:shadow-hover  shadow-button": passwordAndCodeValidated,
								"cursor-not-allowed bg-black-5 text-black-20  font-semibold": !passwordAndCodeValidated
							})}
							onClick={onReset}
							disabled={!passwordAndCodeValidated}
						/>
					</Step>
				</Steps>
			</div>
		</Board>
	);
};

export default memo(withRouter(PasswordReset));
