import { Button, LoadingState, ModalHeader } from "components/Shared";
import Datasource, { DatasourceResponse } from "lib/datasource";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

const DefectProjectModal = ({ isOpen, onRequestClose, projectId, isDefect }: any) => {
	const history = useHistory();
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "project", renderTrigger: renderTrigger }));
	const closeProject = useCallback(() => {
		datasource.put("v2", `projects/${projectId}/defect`, {}, { updateIsLoding: true });
	}, [datasource, projectId]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) {
				!!onRequestClose && onRequestClose();
				setTimeout(() => history.go(0), 1000);
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, history, onRequestClose]);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			className="archive"
			onRequestClose={onRequestClose}
			contentLabel="close-project"
		>
			<ModalHeader
				title={!isDefect ? "move_to_defect_project" : "remove_from_defect_project"}
				onRequestClose={onRequestClose}
			/>
			<section>
				<p>
					{!isDefect
						? "This will move the project to Defect status."
						: "This will remove the project from Defect status."}
				</p>
			</section>
			<footer>{datasource.isLoading ? <LoadingState /> : <Button onClick={closeProject} />}</footer>
		</Modal>
	);
};

export default memo(DefectProjectModal);
