import React from "react";
import { View, ListGroupControl, ListGroup, ListGroupItem } from "components/Shared";
import _ from "lodash";

export default class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "name",
      viewBy: !!_.filter(props.tenancies, tenancy => !!tenancy.shape).length ? "plan" : "list",
      filteredBy: false
    };
  }

  render() {
    const { property } = this.props;
    const { buildings } = property;
    const tenancies = _.compact(_.flatten(_.map(buildings, building => building.tenancies)));
    const { filteredBy, viewBy, sortBy } = this.state;
    var filterOptions = ["all"];
    filterOptions.push(
      _.compact(
        _.map(tenancies, tenancy => {
          let precinct = _.get(tenancy, "precinct", false);
          return precinct ? `precinct='${precinct}'` : false;
        })
      )
    );
    filterOptions = _.uniqBy(_.flatten(filterOptions), "value");

    const collection = _.sortBy(
      _.filter(tenancies, tenancy => {
        if (filteredBy) {
          if (filteredBy.indexOf("=") > -1) {
            let [key, value] = filteredBy.split("=");
            value = value.substr(1, value.length - 2);
            return _.get(tenancy, key, false) === value;
          } else if (filteredBy === "occupied") {
            return !!tenancy.current_tenant;
          } else if (filteredBy === "inspected") {
            return _.get(tenancy, "inspections", []).length > 0;
          } else if (filteredBy === "vacant") {
            return !tenancy.current_tenant;
          }
          return true;
        }
        return true;
      }),
      sortBy
    );

    return (
      <View resource="tenancies">
        <ListGroupControl
          key={"list-group-control-tenancies"}
          viewOptions={[!!_.filter(collection, tenancy => !!tenancy.shape).length && "plan", "list"]}
          onViewChange={viewBy => this.setState({ viewBy })}
          selectedView={viewBy}
          sortOptions={viewBy === "list" && ["name", "division", "precinct"]}
          onSortChange={sortBy => this.setState({ sortBy })}
          selectedSort={sortBy}
          onFilterChange={filteredBy => this.setState({ filteredBy })}
          selectedFilter={filteredBy}
          filterOptions={filterOptions}
        />

        <ListGroup
          key={"list-group-tenancies"}
          resource={"tenancies"}
          collection={collection}
          renderRow={tenancy => (
            <ListGroupItem
              id={tenancy.id}
              key={tenancy.id}
              link={`/tenancies/${tenancy.id}`}
              heading={_.get(tenancy, "name")}
              tenancy={tenancy}
            />
          )}
        />
      </View>
    );
  }
}
