import React from "react";
import _ from "lodash";
import { translate } from "lib";
import { Date, View, DefinitionList, DefinitionItem } from "components/Shared";
import { Inline as User } from "components/Users";

export default ({ lease }: { lease: tcpinpoint.Lease; name?: string }) => (
	<View resource="Details">
		<DefinitionList>
			<DefinitionItem title="kind">{translate(_.get(lease, "kind"))}</DefinitionItem>
			{!!_.get(lease, "created_by") && (
				<DefinitionItem title="created_by">
					<User isAuthority={true} user={_.get(lease, "created_by")} />
				</DefinitionItem>
			)}
			<DefinitionItem title="created_at">
				<Date date={_.get(lease, "created_at")} />
			</DefinitionItem>
			<DefinitionItem title="updated_at">
				<Date date={_.get(lease, "updated_at")} />
			</DefinitionItem>
		</DefinitionList>
	</View>
);
