import React, { memo } from "react";
import classNames from "classnames";

const Center = ({ children, vertical = false }: { children?: React.ReactNode; vertical?: boolean }) => {
	return (
		<div
			className={classNames("flex justify-center", {
				"flex-col": vertical
			})}
		>
			{children}
		</div>
	);
};

export default memo(Center);
