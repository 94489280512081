import React from "react";
import { View } from "components/Shared";
import { Map } from "components/Widgets";

const Building = ({ building, onUpdate, property }) => {
	if (building) {
		return (
			<View title={`${building.name} at ${property.name}`} resource="building">
				{onUpdate && (
					<button onClick={onUpdate} className="close">
						<span aria-hidden="true">&times;</span>
					</button>
				)}
				<Map buildings={[{ name: building.name, coordinates: building.shape }]} height={"394px"} />
			</View>
		);
	}
	return null;
};

export default Building;
