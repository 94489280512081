import _ from "lodash";
import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const getCompanyProperties = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_PROPERTIES_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}companies/${id}/properties`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => { dispatch({ type: "GET_PROPERTIES_SUCCESS", resource: res.data.properties }) })
	.catch((e) => { dispatch({ type: "GET_PROPERTIES_FAILED", response: e }); });
});

export const postCompany = memoAC((company: tcpinpoint.Company) => (dispatch: any) => {
	dispatch({ type: "POST_COMPANY_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}companies`, { company }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "POST_COMPANY_SUCCESS", response: { status: 200 }, resource: res.data.company });
		dispatch(getCompany(res.data.company.id));
	})
	.catch((e) => { dispatch({ type: "PUT_COMPANY_FAILED", response: e }); });
});

export const putCompany = memoAC((company: tcpinpoint.Company) => (dispatch: any) => {
	dispatch({ type: "PUT_COMPANY_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}companies/${_.get(company, "id")}`, { company }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "PUT_COMPANY_SUCCESS", response: { status: 200 }, resource: res.data.company });
		dispatch(getCompany(res.data.company.id));
	})
	.catch((e) => { dispatch({ type: "PUT_COMPANY_FAILED", resource: company, response: e }); });
});

export const getCompany = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_COMPANY_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}companies/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch(getCompanyProperties(id));
		dispatch({ type: "GET_COMPANY_SUCCESS", response: { status: 200 }, resource: res.data.company });
	})
	.catch((e) => { dispatch({ type: "GET_COMPANY_FAILED", response: e }); });
});

export const getCompanies = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_COMPANIES_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}companies`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "GET_COMPANIE_SUCCESS", response: { status: 200 }, resource: res.data.companies })
	})
	.catch((e) => { dispatch({ type: "GET_COMPANIES_FAILED", response: e }); });
});
