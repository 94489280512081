import React, { useState } from "react";
import Modal from "react-modal";
import { Button, Title } from "components/Shared";
import { Input } from "components/Inputs";
// @ts-ignore
import validate from "validate.js";
import { translate } from "lib";

const constraints = { name: { presence: true } };

interface ModalProps {
	id?: string;
	name?: string;
	onUpdate?: any;
	isOpen?: boolean;
	onRequestClose?: () => any;
}

const TemplateModal = (props: ModalProps) => {
	const { id, onUpdate, onRequestClose, isOpen } = props;
	const [name, setName] = useState(props.name);
	const [errors, setErrors] = useState<any>({});

	const onSubmit = () => {
		const errors = validate({ name }, constraints);
		if (!errors) {
			!!onUpdate && onUpdate({ id, name });
			!!onRequestClose && onRequestClose();
		} else {
			setErrors(errors);
		}
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen!}
			onRequestClose={onRequestClose}
			className="template-modal"
			contentLabel="template"
		>
			<header>
				<div className="modal-header-body">
					<Title>{translate("edit_template_modal_header")}</Title>
				</div>

				<button type="button" className="close" aria-label="Close" onClick={onRequestClose}>
					<span aria-hidden="true">×</span>
				</button>
			</header>
			{/* <ModalHeader title="edit_template" onRequestClose={onRequestClose} /> */}
			<section>
				<Input
					defaultState={{ name, errors }}
					required={constraints}
					name="name"
					onChange={(name: string) => setName(name)}
				/>
			</section>
			<footer>
				<Button onClick={() => onSubmit()} />
			</footer>
		</Modal>
	);
};

export default TemplateModal;
