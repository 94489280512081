import classNames from "classnames";
import { Row } from "components/Projects";
import { ListGroup, ListGroupControl, LoadingState, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import { LastMomentTriggerAPIContext } from "context";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface Props {
	queryStringParameters?: {};
	children?: JSX.Element;
	name?: string;
}

const Projects = (props: Props) => {
	const urlFilteredBy = new URLSearchParams(useLocation().search).get("filteredBy");
	const { children, queryStringParameters } = props;
	const { lastMoment } = useContext(LastMomentTriggerAPIContext);
	const [projects, setProjects] = useState([] as tcpinpoint.Project[]);
	const [filteredBy, setFilteredBy] = useState(urlFilteredBy || "open");
	const [, renderTrigger] = useState(moment());
	const getSortAttribute = (sortBy: string) => (sortBy === "lease_status" ? "-lease_status" : sortBy);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "project",
			perPage: 20,
			currentPage: 1,
			sortBy: "name",
			renderTrigger: renderTrigger,
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setProjects(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		datasource.get("v2", "projects", {
			queryStringParameters: _.extend({}, queryStringParameters, {
				status: filteredBy,
				sort: getSortAttribute(datasource.sortBy),
				per_page: datasource.perPage,
				page: datasource.currentPage,
			}),
		});
	}, [
		datasource,
		datasource.currentPage,
		datasource.perPage,
		datasource.sortBy,
		filteredBy,
		queryStringParameters,
		lastMoment,
	]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<View resource="projects" hideTitle={true}>
			<ListGroupControl
				sortOptions={["name", "tenancy_name", "property_name", "lease_status"]}
				onSortChange={(newSortBy) => {
					datasource.setCurrentPage(1);
					datasource.setSortBy(newSortBy);
				}}
				selectedSort={datasource.sortBy}
				selectedView={"list"}
				filterOptions={["open", "ahead", "behind", "archived", "defects"]}
				onFilterChange={(newFilterBy) => {
					datasource.setCurrentPage(1);
					setFilteredBy(newFilterBy);
				}}
				selectedFilter={filteredBy}
			/>
			<ListGroup
				resource="projects"
				collection={projects}
				pagination={false}
				renderRow={(project) => <Row showProperty={true} key={project.id} project={project} />}
			/>
			{children}
			<div className={classNames("margin-atuo", "flex justify-center")}>
				<Pagination datasource={datasource} />
			</div>
		</View>
	);
};

export default memo(Projects);
