import React, { memo, useContext } from "react";
import { View, Date, DefinitionItem, DefinitionList, Row, Col, Title } from "components/Shared";
import { translate } from "lib";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Inline as User } from "components/Users";
import { UserContext } from "context";

interface Props {
	project: tcpinpoint.Project;
	name: string;
}
const Dashboard = ({ project }: Props) => {
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const isCreator = project.created_by_id === currentUser.id;
	const authorityIds = _.map(project.authorities, "user_id");
	const isAuthority = _.includes(authorityIds, currentUser.id) || isCreator;

	return (
		<View resource="project-dashboard" hideTitle>
			<Row>
				<Col>
					<DefinitionList>
						{!!_.get(project, "reference") && (
							<DefinitionItem title="reference">{_.get(project, "reference")}</DefinitionItem>
						)}

						{!!isAuthority && !!_.get(project, "template.name") && (
							<DefinitionItem title="template">
								<Link to={`/templates/${_.get(project, "template.id")}`}>{_.get(project, "template.name")}</Link>
							</DefinitionItem>
						)}

						{!!_.get(project, "created_at") && (
							<DefinitionItem title="created_at">
								<Date date={_.get(project, "created_at")} />
							</DefinitionItem>
						)}
						{!!_.get(project, "days_active") && (
							<DefinitionItem title="days_active">{_.get(project, "days_active")}</DefinitionItem>
						)}

						{!!_.get(project, "completed_at") && (
							<DefinitionItem title="completed_at">
								<Date date={_.get(project, "completed_at")} />
							</DefinitionItem>
						)}
						{!!_.get(project, "closed_by") && (
							<DefinitionItem title="closed_by">
								<User user={project.closed_by!} />
							</DefinitionItem>
						)}
					</DefinitionList>
					{(!!_.get(project, "company") || !!_.get(project, "started_by")) && (
						<React.Fragment>
							<hr />
							<Title>{translate("delivery")}</Title>
							<DefinitionList>
								{!!_.get(project, "company") && (
									<DefinitionItem title="delivered_by">
										<Link to={`/companies/${_.get(project, "company.id")}`}>{_.get(project, "company.name")}</Link>
									</DefinitionItem>
								)}
								{!!_.get(project, "started_by") && (
									<DefinitionItem title="created_by">
										<User user={_.get(project, "started_by")} />
									</DefinitionItem>
								)}
							</DefinitionList>
						</React.Fragment>
					)}
				</Col>
				<Col>
					{/* {!!_.get(project, "deadlines.length", 0) && (
						<React.Fragment>
							<Title>{translate("deadlines")}</Title>
							{_.map(_.sortBy(_.get(project, "deadlines", []), "date"), (deadline, index) => (
								<DefinitionList key={`deadline-${index}`}>
									<Row>
										<Col className="icon-col">{icon("schedule")}</Col>
										<Col>
											<DefinitionItem title={deadline.name}>
												{deadline.status === "completed" ? (
													translate(deadline.status)
												) : (
													<React.Fragment>
														{translate("due")} <Date date={deadline.date} showTime={false} />
													</React.Fragment>
												)}
											</DefinitionItem>
										</Col>
										<Col>
											{_.get(deadline, "task_name") ? (
												<DefinitionItem title="tracked">
													{`${_.get(deadline, "status")} ${translate("against")}`}{" "}
													<Link to={`/tasks/${_.get(deadline, "task_id")}`}>{_.get(deadline, "task_name")}</Link>
												</DefinitionItem>
											) : (
												<p className="muted">{translate("untracked")}</p>
											)}
										</Col>
									</Row>
									<hr />
								</DefinitionList>
							))}
						</React.Fragment>
					)} */}
				</Col>
			</Row>
		</View>
	);
};

export default memo(Dashboard);
