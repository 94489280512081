import classNames from "classnames";
import React, { memo } from "react";

const Logo = ({
	width = "40",
	height = "40",
	className,
	spin,
	hoverSpin,
	hideTCP
}: {
	width?: string;
	height?: string;
	className?: string;
	spin?: boolean;
	hoverSpin?: boolean;
	hideTCP?: boolean;
}) => {
	return (
		<div>
			<svg
				width={width}
				height={height}
				viewBox="0 0 80 80"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={classNames("group", className)}
			>
				<g className={classNames({ hidden: hideTCP })}>
					<path
						name="p"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M55.5518 34.1718C53.252 34.1807 50.7851 35.5547 50.7851 38.5619V41.0496C50.7851 43.4816 52.646 45.2601 55.3113 45.3771C57.4975 45.3771 59.4295 44.1799 60.2106 42.3278C60.4568 41.6857 60.6172 40.8973 60.6456 40.1792C60.6764 38.1206 59.9535 36.4572 58.494 35.2468C57.7243 34.6395 56.705 34.2651 55.5518 34.1718ZM49.1092 53.9278C48.2756 53.9278 47.4324 53.3752 47.4324 52.3204V32.7695C47.4324 31.7992 48.1122 31.1466 49.1239 31.1466C50.093 31.1466 50.7013 31.7464 50.7221 32.7181C52.0462 31.4427 53.8878 30.958 55.3833 30.958C56.4683 30.958 57.6007 31.1773 58.5773 31.5776L58.5854 31.5634C62.0791 33.0001 64.1024 36.2445 63.9983 40.2447C63.8605 45.1592 60.3176 48.5904 55.3833 48.5904C53.5583 48.5904 51.9127 48.0057 50.7851 47.0047V52.3204C50.7851 53.3752 49.9424 53.9278 49.1092 53.9278Z"
						fill="#263746"
					/>
					<path
						name="c"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M38.5249 48.5479C33.6313 48.5479 29.7986 44.592 29.7986 39.5403C29.7986 35.2642 32.8615 31.6841 37.0833 31.0273C37.6349 30.9533 38.1589 30.9165 38.6697 30.9165C41.2081 30.9165 43.3564 31.819 45.0555 33.5984C45.5014 34.1001 45.607 34.7031 45.3533 35.2624C45.0872 35.8494 44.4472 36.2596 43.7953 36.2596C43.3583 36.2596 42.9507 36.0823 42.6492 35.7588C41.6091 34.6635 40.3659 34.1515 38.736 34.1515C38.353 34.1515 37.9615 34.1793 37.5615 34.2241C34.785 34.6513 33.1176 36.649 33.1176 39.5648C33.1176 42.7352 35.5656 45.3148 38.5741 45.3148C39.3704 45.3148 40.2533 45.0927 41.0604 44.6901C41.7336 44.3449 42.3689 43.8286 42.7988 43.2774C43.0624 42.8856 43.5174 42.6499 44.0343 42.6499C44.6753 42.6499 45.3035 43.0266 45.5971 43.5877C45.8617 44.0946 45.8148 44.6872 45.4726 45.1738C44.7629 46.1678 43.7475 47.0033 42.5346 47.5936C41.1821 48.2113 39.7614 48.5479 38.5249 48.5479Z"
						fill="#263746"
					/>
					<path
						name="t"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M22.601 48.7459C21.7952 48.7459 20.9403 48.158 20.9403 47.0683V34.6202H16.2914C15.2253 34.6202 14.6497 33.7875 14.6497 33.0038C14.6497 32.5908 14.7983 32.199 15.0677 31.9034C15.3716 31.5705 15.8067 31.388 16.2914 31.388H28.9578C30.0481 31.388 30.6365 32.2207 30.6365 33.0038C30.6365 33.7875 30.0481 34.6202 28.9578 34.6202H24.2858L24.309 47.0674C24.309 48.1184 23.4408 48.7459 22.601 48.7459Z"
						fill="#263746"
					/>
				</g>

				<g
					className={classNames({
						"origin-center animate-spin": spin,
						"group-hover:origin-center group-hover:animate-spin": hoverSpin
					})}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14.1662 22.2337C15.434 22.9627 15.8686 24.5771 15.1367 25.8393C14.4048 27.102 12.7839 27.5344 11.5161 26.8054C10.2488 26.0765 9.8142 24.4625 10.5461 23.1998C11.278 21.9372 12.8989 21.5048 14.1662 22.2337Z"
						fill="#DE473F"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M68.7706 23.5389C68.7578 23.5172 68.7327 23.4766 68.7327 23.4766C62.9785 13.691 52.3184 7.11404 40.1102 7.11404C30.3368 7.11404 21.5532 11.3279 15.4851 18.0284C15.4851 18.0284 15.3412 18.2 15.2797 18.2976C14.7783 19.0968 15.0226 20.1502 15.825 20.6495C16.5186 21.0814 17.401 20.956 17.952 20.3991C17.952 20.3991 17.9752 20.3779 17.9781 20.3746C23.4208 14.3318 31.3177 10.5263 40.1102 10.5263C51.0231 10.5263 60.557 16.3866 65.728 25.1165L65.8596 25.3221C66.3529 26.0704 67.3565 26.3174 68.1448 25.8643C68.9623 25.3942 69.2426 24.3532 68.7706 23.5389Z"
						fill="#00B1BF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M66.0414 58.2321C64.7736 57.5032 64.3395 55.8887 65.0714 54.626C65.8033 53.3638 67.4237 52.931 68.6915 53.6604C69.9593 54.3889 70.3934 56.0033 69.6615 57.266C68.9296 58.5282 67.3087 58.961 66.0414 58.2321Z"
						fill="#DE473F"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M68.3985 49.3966C68.106 50.2948 68.5993 51.2595 69.5011 51.5514C70.3982 51.8418 71.3611 51.3557 71.6589 50.4655L71.6636 50.4669C72.6975 47.272 73.2618 43.8673 73.2618 40.3301C73.2618 36.7514 72.6786 33.3108 71.621 30.0833C71.6168 30.0687 71.6163 30.0536 71.6111 30.0386C71.318 29.1408 70.3499 28.649 69.4481 28.9409C68.5463 29.2328 68.0525 30.1975 68.3455 31.0957C68.3498 31.1084 68.3574 31.1192 68.3616 31.132C69.3122 34.0284 69.8358 37.1172 69.8358 40.3301C69.8358 43.4764 69.3331 46.5035 68.4203 49.348C68.4142 49.365 68.4042 49.3796 68.3985 49.3966Z"
						fill="#263746"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M11.8091 49.3966C12.1022 50.2948 11.6089 51.2595 10.7071 51.5514C9.80949 51.8418 8.84706 51.3557 8.54881 50.4655L8.54408 50.4669C7.51016 47.272 6.94586 43.8673 6.94586 40.3301C6.94586 36.7514 7.5291 33.3108 8.58668 30.0833C8.59142 30.0687 8.59189 30.0536 8.59662 30.0386C8.88966 29.1408 9.85825 28.649 10.7601 28.9409C11.6614 29.2328 12.1552 30.1975 11.8622 31.0957C11.8579 31.1084 11.8503 31.1192 11.8461 31.132C10.8955 34.0284 10.3719 37.1172 10.3719 40.3301C10.3719 43.4764 10.8746 46.5035 11.7878 49.348C11.794 49.365 11.8035 49.3796 11.8091 49.3966Z"
						fill="#263746"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M11.4375 56.9274C11.4499 56.9487 11.4754 56.9892 11.4754 56.9892C17.2292 66.7748 27.8893 73.3518 40.0979 73.3518C49.8714 73.3518 58.6545 69.1384 64.7226 62.4374C64.7226 62.4374 64.8669 62.2663 64.928 62.1687C65.4294 61.369 65.1856 60.3161 64.3827 59.8164C63.6891 59.3845 62.8067 59.5099 62.2556 60.0672C62.2556 60.0672 62.2329 60.0884 62.2296 60.0912C56.7869 66.1345 48.89 69.9395 40.0979 69.9395C29.1845 69.9395 19.6506 64.0792 14.4801 55.3498L14.3485 55.1438C13.8552 54.3955 12.8516 54.1489 12.0629 54.602C11.2453 55.0721 10.9656 56.1132 11.4375 56.9274Z"
						fill="#00B1BF"
					/>
				</g>
			</svg>
		</div>
	);
};

export default memo(Logo);
