import React from "react";
import { icons } from "lib";

const Icon = (verb: string) => {
    const notificationIcons: any = {
        "announce": "fa-fw fal fa-bullhorn",
        "remind-task": "fa-fw fal fa-exclamation-triangle",
        "accept-condition": "fa-fw fal fa-check-circle",
        "accept-task": "fa-fw fal  fa-thumbs-up",
        "close-condition": "fa-fw fal fa-check-circle",
        "close-inspection": `fa-fw fal fa-clipboard-check`,
        "close-project": "fa-fw fas fa-archive",
        "close-task": "fa-fw fal fa-check-circle",
        "create-comment-mention": "fa-fw fal fa-at",
        "create-comment-reply": "fa-fw fal fa-comments",
        "create-comment": "fa-fw fal fa-comment",
        "create-document": "fa-fw fal fa-file-upload",
        "create-facility": `fa-fw fal fa-${icons.facilities}`,
        "create-inspection": `fa-fw fal fa-${icons.inspections}`,
        "create-issue": `fa-fw fal fa-file-${icons.issues}`,
        "create-party": `fa-fw fal fa-user-plus`,
        "create-permission": "fa-fw fal fa-unlock",
        "create-projects": `fa-fw fal fa-${icons.projects}`,
        "create-quote": `fa-fw fal fa-${icons.quotes}`,
        "create-report": `fa-fw fas fa-layer-plus`,
        "delete-document": "fa-fw fal fa-file-times",
        "delete-comment": "fa-fw fal fa-file-times",
        "delete-party": "fa-fw fal fa-file-times",
        "dismissed-condition": "fa-fw fal fa-box-alt",
        "follow-documents": "fa-fw fal fa-plus-hexagon",
        "follow-projects": `fa-fw fal fa-${icons.projects}`,
        "follow-reports": `fa-fw fas fa-layer-plus`,
        "follow-tasks": "fa-fw fal fa-plus-hexagon",
        "follow-templates": "fa-fw fal fa-plus-hexagon",
        "move-task": "fa-fw fal fa-calendar-edit",
        "reschedule-project": "fa-fw fal fa-bullhorn",
        "upload-report": "fa-fw fal fa-file-upload",
        test: "fal fa-atom"
    };

    return (
        <div className="activity-item-icon">
            <i title={verb} className={notificationIcons[verb]} />
        </div>
    );
};

export default Icon;
