import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Date } from "components/Shared";
import Avatar from "components/Avatar/Show";
import Actor from "../Actor";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityDefectActItem = ({ activity }: Props) => {
	let status = <>{activity.attributes.item.object.status}</>;

	if (activity.attributes.item.object.status === "removed" || activity.attributes.item.object.status === "moved") {
		status = (
			<span style={{ textTransform: "none", fontWeight: "normal" }}>
				{activity.attributes.item.object.status} <strong>{activity.attributes.item.object.project_name}</strong>{" "}
				{activity.attributes.item.object.status === "moved" ? "to" : "from"} Defect Status
			</span>
		);
	}

	const link = `/projects/${activity.attributes.item.context.id}`;
	const output = (
		<>
			<strong className="activity-item-actor">
				<Actor actor={activity.attributes.item.actor} />
			</strong>{" "}
			{status}{" "}
		</>
	);

	return (
		<Link to={link} className="activity-item-link">
			<div className="activity-item-avatar">
				<Avatar
					size={32}
					url={activity.attributes.item.actor.image}
					name={
						activity.attributes.item.actor && !!activity.attributes.item.actor.name
							? activity.attributes.item.actor.name
							: "tcbot"
					}
				/>
			</div>
			<div className="activity-item-container">
				<div className="activity-item-body">{output}</div>
				<span className="activity-item-date">
					<Date showRelative date={activity.attributes.item.published} />
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.object.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{link}</code>}
				</span>
			</div>
		</Link>
	);
};

export default memo(ActivityDefectActItem);
