import moment from "moment";
import React, {  useState,  ReactElement } from "react";
import { DueDate } from "components/Shared";
import TodoModal from './TodoModal';
import _ from 'lodash';

const openUpdateTodo = () => {
    return(
        <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L12 12L2 22" stroke="black" stroke-opacity="0.2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};


export const TodoList = ({todoKind, id, todoInfo, reloadPage, userList}: {userList:any, todoKind:string, id:any, todoInfo:any, reloadPage: () => void} ): ReactElement => {
    const [updateTodoModalOpen, toggleUpdateTodo] = useState(false);

    const getInitials = (name: string) => {
        let userInitials;

        if(name){
            const names = name.split(' ');
            const initials = _.map(names, name => (name[0] && name[0].toUpperCase()))
            userInitials = initials.join('');
        }
        return userInitials;
    }
    
    return (
        <>
            <li key={todoInfo.id} className={`opacity-75 hover:opacity-100 shadow flex items-center bg-white mt-6 py-4 px-4 cursor-pointer rounded-lg`} onClick={() => toggleUpdateTodo(!updateTodoModalOpen)}
            >
                <div className="w-5/100">
                    <div className={`${todoInfo.attributes.status && (todoInfo.attributes.status).toLowerCase() === "open" ? 'bg-profileBackground ' : 'bg-grayedNameSign'} text-center h-50 w-50 text-15 tracking-16 py-4 px-4 rounded-full text-white`}>
                        {getInitials(todoInfo.attributes.assigned_to_name)}
                    </div>
                </div>
                <div className="ml-7 flex flex-col w-70/100 flex-nowrap">
                    <p className="text-16 font-medium truncate">{todoInfo.attributes.title}</p>
                    <p className="font-normal text-14 tracking-22 text-reportProjectSubtitle truncate ">{todoInfo.attributes.description}</p>
                </div>
                <div className=" flex flex-col text-right w-20/100">
                    <p className={`${todoInfo.attributes.status && (todoInfo.attributes.status).toLowerCase() === "open" ? 'text-success' : 'text-black-60'} py-2 text-16 font-semibold`}>
                        {todoInfo.attributes.status ? (todoInfo.attributes.status).toUpperCase() : ''}
                    </p>
                    <p>
                        {todoInfo.attributes.status && (todoInfo.attributes.status).toLowerCase() === "open" ? 
                        <DueDate taskDueDate={moment(todoInfo.attributes.due_at).format('YYYY-MM-DD')} taskStatus={todoInfo.attributes.status} extraClassNames="text-14 font-medium" errorClassNames="text-error-light" />
                        :
                        <DueDate taskDueDate={todoInfo.attributes.updated_at} taskStatus={todoInfo.attributes.status} showClosedDate={true} extraClassNames="text-14 font-medium" />}
                    </p>
                </div>
                <div className="ml-4 w-5/100">
                    {openUpdateTodo()}
                </div>
            </li>
            <TodoModal userList={userList} todoKind={todoKind} reloadPage={reloadPage} updateTodo={true} updateTodoId={todoInfo.id} updateTodoInfo={todoInfo} id={id} isOpen={updateTodoModalOpen} toggleModal={(arg: boolean) => toggleUpdateTodo(arg ? arg : false)}/>
        </>
    )
}