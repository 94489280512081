import { Input } from "components/Inputs";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import React, { memo, useEffect, useState, useCallback } from "react";

interface Props {
	buildingId: string;
	preselectedFloorId?: string;
	onChange?: (floorId: string, tenancyExist: boolean) => void;
}

const constraints = {
	name: {
		presence: {
			message: "^ is required"
		}
	}
};

const SelectDynamic = ({ buildingId, onChange, preselectedFloorId }: Props) => {
	const [options, setOptions] = useState([] as any[]);
	const [defaultState, setDefaultState] = useState({ floor_id: preselectedFloorId } as any);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "floor",
			perPage: 1000,
			currentPage: 1,
			sortBy: "name"
		})
    );
    
    const onSelectOption = useCallback((floorId: string, newOptions?: any[] ) => {
		const tenancyExist = _.get(_.find(_.defaultTo(newOptions, options), option => option.item.id === floorId), "item.tenancy_count") > 0;
        if (!_.isNil(onChange)) onChange(floorId, tenancyExist);
		setDefaultState({ floor_id: floorId });
    }, [onChange, options]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			const options = _.map(response.normalizedMainModelResponse, (floor: any) => {
				const net_lettable_area = parseInt(floor.net_lettable_area, 10);
				return {
					item: floor,
					value: floor.id,
					label: _.compact([
						floor.name,
						net_lettable_area > 0 ? `(${net_lettable_area} m²)` : `(${floor.tenancy_count} tenancies)`
					]).join(" ")
				};
			});
            setOptions(options);
            onSelectOption(_.get(options[0], "value"), options); // default select the first one
		});
		return () => sub.unsubscribe();
	}, [datasource, onSelectOption]);

	// API call
	useEffect(() => {
		if (_.isEmpty(buildingId)) return;
		datasource.get("v2", "floors", {
			queryStringParameters: {
				building_id: buildingId,
				sort: datasource.sortBy,
				per_page: datasource.perPage,
				page: datasource.currentPage
			}
		});
	}, [datasource, buildingId, datasource.currentPage, datasource.perPage, datasource.sortBy]);

	if (_.isEmpty(buildingId)) return null;

	return (
		<Input
			onChange={onSelectOption}
			name="floor_id"
			defaultState={defaultState}
			required={constraints}
			options={options}
			showIf={true}
		/>
	);
};

export default memo(SelectDynamic);
