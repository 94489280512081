import React, { useEffect, memo, useState, useContext } from "react";
import { Breadcrumbs, Workspace, Header, LoadingState } from "../Shared";
import TenancyCollection from "./TenancyTracker/TenancyCollection";
import ProjectCollection from "./ProjectAnalytics/ProjectCollection";
import LeaseToOpenReport from "./LeaseToOpen/LeaseToOpenReport";
import ProjectPhaseAnalyticsReport from "./ProjectPhaseAnalytics/ProjectPhaseAnalyticsReport";
import { PropertyList } from "./TenancyTracker/CollectionHelper";
import { ButtonDropDown } from "./ButtonDropDown";
import _ from "lodash";
import { isFeatureToggled } from "lib";
import { UserContext } from "context";
import OpenAnalyticReport from "./LeaseToOpen/OpenAnalyticReport";
import axios from "v2/utils/axios";

const List = () => {
	const params = new URLSearchParams(window.location.search);
	const initReportType = params.get("type");
	const initPropertyId = params.get("property_id");

	const [isPropertyLoading, setIsPropertyLoading] = useState(true);
	const [properties, setProperty] = useState();
	const reportOptions = [
		{
			id: "1",
			name: "Tenancy Tracker",
		},
		{
			id: "2",
			name: "Project Analytics",
		},
		{
			id: "3",
			name: "Rent Commencement",
		},
		{
			id: "4",
			name: "Open for Trade",
		},
		{
			id: "5",
			name: "Project Phase Analytics"
		},
	];
	const initReport = initReportType
		? reportOptions.filter((o) => _.get(o, "id") === initReportType)[0]
		: { id: "", name: "Select a report" };

	const [ReportTypeSelected, setReportTypeSelected] = useState(initReport);
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}properties/index-admin-properties?per_page=-1`)
			.then((res) => { setProperty(res.data.data); })
			.catch((e) => {})
			.finally(() => setIsPropertyLoading(false));
	}, []);

	interface ReportTypeParams {
		id: string;
		name: string;
	}

	const reportSetter = (): ReportTypeParams[] => {
		const reportFeatureFlags = [
			"tenancy-tracking-report",
			"team-analysis-report",
			"lease-to-open-report",
			"trade-analytic-report",
			"project-phase-analytics",
		];
		const reportParams = [
			{
				id: "1",
				name: "Tenancy Tracker",
			},
			{
				id: "2",
				name: "Project Analytics",
			},
			{
				id: "3",
				name: "Rent Commencement",
			},
			{
				id: "4",
				name: "Open for Trade",
			},
			{
				id: "5",
				name: "Project Phase Analytics",
			},
		];
		let reports = [];
		for (var i = 0; i < reportFeatureFlags.length; i++) {
			if (isFeatureToggled(reportFeatureFlags[i], currentUser)) {
				reports.push(reportParams[i]);
			}
		}
		return reports;
	};

	const reportTempType: any = reportSetter();

	let propertyList: any = [];
	if (isPropertyLoading === false) {		
		propertyList = PropertyList(properties);
		propertyList = _.sortBy(propertyList, "name");
	}

	const reportTypeCallBack = (arg: any) => {
		const args = ["1", "2", "3", "4", "5"];
		const reportNames = ["Tenancy Tracker", "Project Analytics", "Rent Commencement", "Open for Trade", "Project Phase Analytics"];
		if (args.includes(arg)) {
			setReportTypeSelected({
				id: arg,
				name: reportNames[args.indexOf(arg)],
			});
		}
	};

	let reportEl;
	if (ReportTypeSelected.id === "1") {
		reportEl = (
			<Workspace id="reports" className="report">
				<TenancyCollection properties={propertyList} />
			</Workspace>
		);
	} else if (ReportTypeSelected.id === "2") {
		reportEl = (
			<Workspace id="reports" className="report">
				<ProjectCollection properties={propertyList} />
			</Workspace>
		);
	} else if (ReportTypeSelected.id === "3") {
		reportEl = (
			<Workspace id="reports" className="report">
				<LeaseToOpenReport properties={propertyList} preselectedPropertyId={initPropertyId} />
			</Workspace>
		);
	} else if (ReportTypeSelected.id === "4") {
		reportEl = (
			<Workspace id="reports" className="report">
				<OpenAnalyticReport properties={propertyList} preselectedPropertyId={initPropertyId} />
			</Workspace>
		);
	} else if (ReportTypeSelected.id === "5") {
		reportEl = (
			<Workspace id="reports" className="report">
				<ProjectPhaseAnalyticsReport properties={propertyList} />
			</Workspace>
		);
	} else {
		reportEl = null;
	}
	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: "reports" }]} />
			<Header
				title="reports"
				children={
					<div className="flex items-center">
						<ButtonDropDown
							buttonOption={ReportTypeSelected}
							dropdownOptions={reportTempType}
							callBack={(arg) => {
								reportTypeCallBack(arg);
							}}
							textClassNames="w-215 text-14 transition rounded-l-md text-left p-4"
							dropDownClassNames="bg-white border-borderColor border-l"
						/>
						<p className="text-reportProjectSubtitle text-14 ml-6">
							Choose the report you want to view. To get access to more reports,{" "}
							<a href="mailto:hello@tcpinpoint.com" className="text-blue-100 focus:outline-none hover:bg-transparent">
								contact the TCP team.
							</a>
						</p>
					</div>
				}
				className="z-10"
			/>
			{isPropertyLoading === false ? reportEl : <LoadingState />}
		</React.Fragment>
	);
};

const MemoizedList = memo(List);
export default () => <MemoizedList />;
