import React, { useState, useEffect, memo } from "react";
import { translate } from "lib";
import { Button, View, Title, Row, Col, ListGroup, ListGroupItem } from "components/Shared";
import { Input } from "components/Inputs";
import _ from "lodash";
import axios from "v2/utils/axios";

interface Props {
  onUpdate: (state: any) => void;
  onSubmit: (state: any) => void;
  errors: any;
  clearErrors: () => void;
}

const Confirmation = (props: Props) => {
  const { onUpdate, onSubmit, errors, clearErrors } = props;
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    

    const fetch = () => axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}portfolios`).then((res) => setPortfolios(res.data.portfolios));
    fetch();
  }, []);

  if (!!_.get(errors, "response")) {
    return (
      <View resource="error" hideTitle>
        <Title>Something Went Wrong</Title>
        <Button onClick={clearErrors}>{translate("retry")}</Button>
      </View>
    );
  }

  return (
    <View resource="project">
      <Row>
        <Col>
          <Input defaultState={props} name="name" onChange={(name: string) => onUpdate({ name })} large />
          <Input defaultState={props} name="reference" onChange={(reference: string) => onUpdate({ reference })} />
          <Input
            defaultState={props}
            name="kind"
            onChange={(kind: string) => onUpdate({ kind })}
            options={["speciality", "food", "kiosk", "food-kiosk"]}
          />
          {!!portfolios.length && (
            <React.Fragment>
              <label>Portfolios</label>
              <ListGroup
                resource="portfolios"
                collection={_.sortBy(portfolios, "name")}
                renderRow={(portfolio: tcpinpoint.Portfolio) => (
                  <ListGroupItem
                    icon="portfolios"
                    key={_.get(portfolio, "id")}
                    heading={_.get(portfolio, "name", false)}
                    subheading={`with ${_.get(portfolio, "projects", []).length} projects`}
                    selected={_.includes(_.get(props, "portfolio_ids", []), portfolio.id)}
                    onSelect={() => onUpdate({ portfolio_ids: _.get(props, "portfolio_ids", []).concat(portfolio.id) })}
                    onUnselect={() => onUpdate({ portfolio_ids: _.filter(_.get(props, "portfolio_ids", []), !portfolio.id) })}
                  />
                )}
              />
            </React.Fragment>
          )}
          <Button className="create-project" onClick={onSubmit}>
            Create my Project!
					</Button>
        </Col>
      </Row>
    </View>
  );
};

export default memo(Confirmation);
