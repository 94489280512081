import React from "react";
import { View, ListGroup, ListGroupItem } from "components/Shared";
import { icon } from "lib";
import _ from "lodash";

const Help = () => (
	<View title="help" resource="help">
		<ListGroup
			hidePageCount={true}
			resource="help"
			renderRow={row => React.createElement(ListGroupItem, _.assign({}, row, { key: row.link }))}
			collection={[
				{
					icon: icon("watch"),
					link: "https://tcpinpoint-learning.thinkific.com/",
					heading: "Join our TCPinpoint Learning Community"
				}
			]}
		/>
	</View>
);
export default Help;
