import classNames from "classnames";
import { ListGroup, ListGroupControl, LoadingState, View } from "components/Shared";
import { Status } from "components/Shared/Status";
import Pagination from "components/Shared/Tcp/Pagination";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Legend } from "./Legend";
import PhaseReportWorkflowRow from "./PhaseReportWorkflowRow";

const PortfolioPhaseReport = ({ portfolioId }: any) => {
	const [workflows, setWorkflows] = useState([] as any[]);
	const [displayLeaseStatus, setDisplayLeaseStatus] = useState(false);
	const [displayHandoverAt, setDisplayHandoverAt] = useState(false);
	const [filteredBy, setFilteredBy] = useState("open");
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "workflow",
			perPage: 20,
			currentPage: 1,
			sortBy: "lease_handover_at",
			renderTrigger: renderTrigger,
			includeRelationship: true
		})
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setWorkflows(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		setDisplayLeaseStatus(datasource.sortBy === "lease_status" ? true : false);
		setDisplayHandoverAt(datasource.sortBy === "lease_handover_at" ? true: false);
		if (!_.isEmpty(portfolioId))
			datasource.get("v2", "workflows", {
				queryStringParameters: {
					portfolio_id: portfolioId,
					fields: JSON.stringify({
						phases: ["name", "position", "progress", "open", "closed", "complete", "incomplete", "ahead", "behind"]
					}), // only require basic attribute, do not include tasks of phase
					status: filteredBy,
					sort: datasource.sortBy,
					per_page: datasource.perPage,
					page: datasource.currentPage
				}
			});
	}, [datasource, portfolioId, datasource.currentPage, datasource.perPage, datasource.sortBy, filteredBy]);

	if (!!portfolioId) {
		return (
			<View resource="portfolio-phase-status-report">
				<div className="report-header">
					<h3>Project Phase Status Report</h3>
					<div className="status-legend">
						<div>
							<Status incomplete={true} />
							<figcaption>Incomplete</figcaption>
						</div>

						<div>
							<Status incomplete={false} />
							<figcaption>Complete</figcaption>
						</div>

						<div>
							<Status incomplete={false} closed={true} />
							<figcaption>Closed Complete</figcaption>
						</div>

						<div>
							<Status closed={true} />
							<figcaption>Closed Incomplete</figcaption>
						</div>
						<div className="ahead">
							<Legend type="spinner" color="#ddd" />
							<figcaption>Phase Progress</figcaption>
						</div>
					</div>
				</div>

				<div className="report-body">
					{datasource.isLoading ? (
						<LoadingState />
					) : (
						<>
							<ListGroupControl
								sortOptions={["lease_handover_at", "project_name", "property_name", "lease_status"]}
								onSortChange={newSortBy => {
									datasource.setCurrentPage(1);
									datasource.setSortBy(newSortBy);
								}}
								selectedSort={datasource.sortBy}
								filterOptions={["open"]}
								onFilterChange={newFilterBy => {
									datasource.setCurrentPage(1);
									setFilteredBy(newFilterBy);
								}}
								selectedFilter={filteredBy}
							/>
							<ListGroup
								resource="workflows"
								pagination={false}
								collection={workflows}
								renderRow={(workflow: any) => (
									<PhaseReportWorkflowRow
										key={workflow.id}
										workflow={workflow}
										displayLeaseStatus={displayLeaseStatus}
										displayHandoverAt={displayHandoverAt}
									></PhaseReportWorkflowRow>
								)}
							/>
							<div className={classNames("margin-atuo", "flex justify-center")}>
								<Pagination datasource={datasource} />
							</div>
						</>
					)}
				</div>
			</View>
		);
	}

	return null;
};

export default memo(PortfolioPhaseReport);
