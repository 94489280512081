import React, { memo } from "react";
import { Address } from "components/Shared";
import _ from "lodash";

const InlineProperty = ({ property }: any) => {
  if (property) {
    const { id, name } = property;
    return (
      <div className="inline property-inline" id={`property-inline-${id}`}>
        <h4 className="property-name">{name}</h4>
        {_.get(property, "addresses[0]") && (
          <h6>
            <Address address={property.addresses[0]} short />
          </h6>
        )}
      </div>
    );
  }
  return null;
};

export default memo(InlineProperty);
