import "whatwg-fetch";
import React, { memo, useMemo } from "react";
import filesize from "filesize";
import { Inline as User } from "components/Users";
import { Link } from "react-router-dom";
import { RelativeDate, Date, DefinitionList, DefinitionItem, View, Col, Row, Address } from "components/Shared";
import { Map } from "components/Widgets";
import _ from "lodash";
import dms2dec from "dms2dec";
import { translate } from "lib";

const Details = ({ document, documentable, name = "" }) => {
	const documentableType = useMemo(() => _.get(documentable, "documentable_type"), [documentable]);
	let capturedDate = null;
	if (!!_.get(document.metadata, "exif.exif.DateTimeOriginal")) {
		const original = _.get(document.metadata, "exif.exif.DateTimeOriginal").split(" ");
		capturedDate = `${original[0].replace(/:/g, "-")} ${original[1]}`;
	}
	let geo_point = [];
	let decimal = [0, 0];
	if (!!_.get(document.metadata, "exif.gps.GPSLatitude")) {
		decimal = dms2dec(
			_.get(document.metadata, "exif.gps.GPSLatitude"),
			_.get(document.metadata, "exif.gps.GPSLatitudeRef"),
			_.get(document.metadata, "exif.gps.GPSLongitude"),
			_.get(document.metadata, "exif.gps.GPSLongitudeRef")
		);
	}
	const latitude = decimal[0];
	const longitude = decimal[1];
	const bearing = _.get(document.metadata, "exif.gps.GPSDestBearing", 0);
	if (!!latitude && !!longitude) {
		geo_point = [
			{
				name: document.name,
				lat: latitude,
				lng: longitude
			}
		];
	}
	return (
		<View>
			<Row>
				<Col>
					<DefinitionList>
						<DefinitionItem title="belongs_to" value={_.get(documentable, "name")} />
						<DefinitionItem title="description" value={document.description} />
						<DefinitionItem title="mimetype" value={document.mimetype} />
						{documentableType === "Project" && (
							<DefinitionItem
								title="project_name"
								value={<Link to={`/projects/${documentable.id}`}>{documentable.name}</Link>}
							/>
						)}
						{documentableType === "Property" && (
							<DefinitionItem
								title="property_name"
								value={<Link to={`/properties/${documentable.id}`}>{documentable.name}</Link>}
							/>
						)}
						<DefinitionItem title="created_by" value={<User isAuthority={true} user={document.created_by} />} />
						<DefinitionItem
							title="created_at"
							value={<Date date={document.created_at} showTime={false} />}
							footnote={<RelativeDate date={document.created_at} />}
						/>
						<DefinitionItem
							title="updated_at"
							value={<Date date={document.updated_at} showTime={false} />}
							footnote={<RelativeDate date={document.updated_at} />}
						/>
						{!!document.deleted_by && (
							<React.Fragment>
								<DefinitionItem title="deleted_by" value={<User user={document.deleted_by} showTime={false} />} />
								<DefinitionItem
									title="deleted_at"
									value={<Date date={document.deleted_at} showTime={false} />}
									footnote={<RelativeDate date={document.deleted_at} />}
								/>
							</React.Fragment>
						)}
						{/* TODO - commented out document expiry while we're currently not using it. Review at a later date */}
						{/* {!!document.expires_at && (
							<DefinitionItem
								title={document.expired ? "expired" : "expires"}
								footnote={<RelativeDate date={document.expires_at} />}
							>
								<Date date={document.expires_at} showTime={false} />
							</DefinitionItem>
						)} */}
						<hr />
						<DefinitionItem title="filesize_bytes" value={filesize(document.filesize_bytes)} />
						{!!document.metadata.aws && (
							<React.Fragment>
								<DefinitionItem
									title="storage"
									value={translate(document.metadata.aws.storage_class === "GLACIER" ? "long_term" : "short_term")}
								/>
								<DefinitionItem title="encryption" value={document.metadata.aws.server_side_encryption} />
							</React.Fragment>
						)}
					</DefinitionList>
				</Col>
				<Col>
					<React.Fragment>
						<h3>Photo Details</h3>
						<DefinitionList>
							{!!latitude && longitude && (
								<React.Fragment>
									<Map bearing={bearing} geo_points={geo_point} />
									<DefinitionItem
										title="latlng"
										value={`${parseFloat(latitude).toFixed(3)}&deg;, ${parseFloat(longitude).toFixed(3)}&deg;`}
									/>
									<DefinitionItem
										title="taken"
										value={_.map(document.addresses, address => (
											<Address key={address.id} address={address} />
										))}
									/>
								</React.Fragment>
							)}
							<DefinitionItem title="captured" value={<Date date={capturedDate} />} />
							{!!document.metadata.exif && (
								<React.Fragment>
									<DefinitionItem
										title="dimensions"
										value={`${document.metadata.exif.image.XResolution}&times;${document.metadata.exif.image.YResolution}`}
									/>
									<DefinitionItem title="make" value={document.metadata.exif.image.Make} />
									<DefinitionItem title="model" value={document.metadata.exif.image.Model} />
									<DefinitionItem title="lens_make" value={document.metadata.exif.image.LensMake} />
									<DefinitionItem title="lens_model" value={document.metadata.exif.image.LensModel} />
								</React.Fragment>
							)}
						</DefinitionList>
					</React.Fragment>
				</Col>
			</Row>
		</View>
	);
};
export default memo(Details);
