import { ListGroup, LoadingState, View } from "components/Shared";
import InviteModal from "components/Tasks/Invite2";
import TaskUserRow from "components/Tasks/TaskUser";
import { LastMomentTriggerAPIContext } from "context";
import { translate } from "lib";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";

const TeamMemberTab = ({ taskId, isAdministrator }: any) => {
	const [inviteModal, setInviteModal] = useState(false);
	const [lastMoment, setLastMoment] = useState(moment());
	const lastMomentProviderValue = { lastMoment, setLastMoment };
	const [taskUsers, setTaskUsers] = useState([] as any[]);
	const [, renderTrigger] = useState(moment());
	const [taskUserDatasource] = useState(
		new Datasource({
			mainModelName: "task_user",
			perPage: -1,
			sortBy: "name",
			renderTrigger: renderTrigger
		})
	);

	const responsibleUsers = useMemo(() => _.filter(taskUsers, taskUser => taskUser.responsible), [taskUsers]);
	const spectators = useMemo(() => _.filter(taskUsers, taskUser => !taskUser.responsible), [taskUsers]);
	const openAddUserModal = isAdministrator ? () => setInviteModal(true) : false;
	// const spectatorRoleNames = useMemo(() => _.map(task.spectator_roles, role => role.name).join(", "), [task]);

	useEffect(() => {
		let sub = taskUserDatasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setTaskUsers(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [taskUserDatasource]);

	// API call
	useEffect(() => {
		if (!_.isNil(taskId))
			taskUserDatasource.get("v2", `tasks/${taskId}/task-users`, {
				queryStringParameters: {
					sort: taskUserDatasource.sortBy,
					per_page: taskUserDatasource.perPage,
					page: taskUserDatasource.currentPage,
					fields: JSON.stringify({ user: ["name", "email", "phone_number"] })
				}
			});
	}, [taskId, taskUserDatasource, taskUserDatasource.sortBy, lastMoment]);

	if (taskUserDatasource.isFirstTimeLoading) return <LoadingState />;

	return (
		<LastMomentTriggerAPIContext.Provider value={lastMomentProviderValue}>
			<View hideTitle={true} resource="taskUsers">
				<h4>{translate("responsible_users")}</h4>
				<ListGroup
					resource="responsible_users"
					collection={responsibleUsers}
					renderRow={user => <TaskUserRow key={user.id} taskUser={user} />}
				/>
				<hr />
				<h4>{translate("spectators")}</h4>
				<ListGroup
					resource="spectators"
					collection={spectators}
					renderRow={user => <TaskUserRow key={user.id} taskUser={user} canUpdate={isAdministrator} />}
					onAdd={openAddUserModal}
				/>
				<InviteModal
					ariaHideApp={false}
					taskId={taskId}
					isOpen={inviteModal}
					onRequestClose={() => setInviteModal(false)}
				/>
			</View>
		</LastMomentTriggerAPIContext.Provider>
	);
};

export default memo(TeamMemberTab);
