import Normalizer, { JsonApiResponseMeta } from "lib/jsonapi-normalizer";
import LocalNotification from "lib/Notification";
import _ from "lodash";
import moment, { MomentInput } from "moment";
import { Subject } from "rxjs";
import ErrorFormatter from "./jsonapi-error-formatter";
import awsconfig from "../aws-exports.js";

interface Props {
	renderTrigger?: any; // if set, datasource will auto trigger render when necessary
	mainModelName?: string;
	perPage?: number;
	currentPage?: number;
	sortBy?: string;
	includeRelationship?: boolean; // include relationship data in normalizedMainModelResponse as attribute
}

export interface DatasourceMeta {
	perPage: number;
	currentPage: number;
	totalCount: number;
}

export interface DatasourceResponse {
	success?: boolean;
	jsonApiResponse?: any;
	normalizedResponse?: any;
	normalizedMainModelResponse?: any;
	normalizedMainModelResponseBackup?: any;
	datasourceMeta?: DatasourceMeta;
	actionType?: DatasourceActionType;
}

export enum DatasourceActionType {
	GET,
	POST,
	DEL,
	PUT,
}

export interface DatasourceApiOption {
	displayNotification?: boolean;
	updateIsLoding?: boolean;
	actionType?: DatasourceActionType;
	errorTitle?: string;
	errorMessage?: string;
}

export default class Datasource {
	public responseSubject$: Subject<DatasourceResponse> = new Subject<DatasourceResponse>();
	public paginationSubject$: Subject<DatasourceMeta> = new Subject<DatasourceMeta>();
	public loadingSubject$: Subject<boolean> = new Subject<boolean>();

	private mainModelName: string;
	private jsonApiResponse: any; // original response from API call
	private normalizedResponse: any; // normalized response
	private _normalizedMainModelResponse: any; // data of main model of normalized response
	private normalizedMainModelResponseBackup: any; // backup of original main model response
	private includeRelationship: boolean;

	private renderTrigger: any;
	private _perPage: number;
	private _currentPage: number;
	private _totalCount: number = 0;
	private _sortBy: string; // sort
	private _isLoading: boolean = false;
	private _loadingCount: number = 0;

	public reloadTrigger: MomentInput;

	public get isFirstTimeLoading(): boolean {
		return this.isLoading && this._loadingCount === 1;
	}
	public get perPage(): number {
		return this._perPage;
	}
	public get currentPage(): number {
		return this._currentPage;
	}
	public get totalCount(): number {
		return this._totalCount;
	}
	public get sortBy(): string {
		return this._sortBy;
	}
	public get isLoading(): boolean {
		return this._isLoading;
	}
	public get loadingCount(): number {
		return this._loadingCount;
	}

	private get normalizedMainModelResponse(): any {
		return this._normalizedMainModelResponse;
	}

	private set normalizedMainModelResponse(data: any) {
		this._normalizedMainModelResponse = data;
		this.normalizedMainModelResponseBackup = _.cloneDeep(data);
	}

	public get datasourceResponse(): DatasourceResponse {
		return {
			jsonApiResponse: this.jsonApiResponse,
			normalizedResponse: this.normalizedResponse,
			normalizedMainModelResponse: this.normalizedMainModelResponse,
			normalizedMainModelResponseBackup: _.cloneDeep(this.normalizedMainModelResponseBackup),
			datasourceMeta: this.datasourceMeta,
		};
	}

	public get datasourceMeta(): DatasourceMeta {
		return {
			currentPage: this.currentPage,
			perPage: this.perPage,
			totalCount: this.totalCount,
		};
	}

	/******************************************* Constructor *******************************************/

	constructor({
		mainModelName = "",
		perPage = 10,
		currentPage = 1,
		sortBy = "",
		renderTrigger,
		includeRelationship = true,
	}: Props) {
		this.mainModelName = mainModelName;
		this._perPage = perPage;
		this._currentPage = currentPage;
		this._sortBy = sortBy;
		this.renderTrigger = renderTrigger;
		this.includeRelationship = includeRelationship;
	}

	/******************************************* API Methods *******************************************/

	checkSessionSource = () => {			
		const lastAuthUserKey = `CognitoIdentityServiceProvider.${awsconfig.Auth.userPoolWebClientId}.LastAuthUser`;
  		const lastAuthUser = localStorage.getItem(lastAuthUserKey);
		if (lastAuthUser) return 'A'
		else return 'G';
	}

	public getAuthTokenFromCookie(): String {
		var token = '';
		const oauth_token = document.cookie.match(`(^|;)\\s*OAUTH\\s*=\\s*([^;]+)`);
		if (oauth_token !== null) {
			token = oauth_token[0].split('=')[1];
		}
		return token;
	}	

	checkReqBody = (init: any) => {
		if (init.hasOwnProperty('body')) {
			return init.body;
		}
		return {};
	}

	fetchFunction = async (path: any, method: any, init: any) => {
		const token = this.getAuthTokenFromCookie();
		var params = Object.keys(init).length === 0 ? '' : `?${new URLSearchParams(init.queryStringParameters)}`;
		if (params === '?') params = '';
		const url = `${process.env.REACT_APP_API_ENDPOINT_V2}${path}${params}`;

		const headers = {
		  Authorization: `Bearer ${token}`,
		  'Content-Type': 'application/json',
		};

		let baseoptions: RequestInit = {
		  method: method,
		  headers: new Headers(headers),
		};

		// Check if the method allows a request body
		if (['POST', 'PUT', 'PATCH'].includes(method)) {
			const body = this.checkReqBody(init);
			(baseoptions as any).body = JSON.stringify(body);
		}
	  
		try {
			const response = await fetch(url, baseoptions);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			// Read the response body as text
			const text = await response.text();
			// Check if there's content in the response body
			if (!text.trim()) {
				return null;
			}
			
			const data = JSON.parse(text);
			
			// const data = await response.json();
			return data;
		} catch (error) {
			console.error('Error:', error);
			throw error; 
		}
	};
	
	get(apiName: any, path: any, init: any, options?: DatasourceApiOption): Promise<any> {
		options = _.merge(
			{
				displayNotification: false,
				updateIsLoding: true,
				actionType: DatasourceActionType.GET,
			},
			options
		);		
		return this.call(this.fetchFunction(path, 'GET', init), options);
	}

	post(apiName: any, path: any, init: any, options?: DatasourceApiOption): Promise<any> {
		options = _.merge(
			{
				displayNotification: true,
				updateIsLoding: false,
				actionType: DatasourceActionType.POST,
			},
			options
		);
		return this.call(this.fetchFunction(path, 'POST', init), options);
	}

	put(apiName: any, path: any, init: any, options?: DatasourceApiOption): Promise<any> {
		options = _.merge(
			{
				displayNotification: true,
				updateIsLoding: false,
				actionType: DatasourceActionType.PUT,
			},
			options
		);
		return this.call(this.fetchFunction(path, 'PUT', init), options);
	}

	del(apiName: any, path: any, init: any, options?: DatasourceApiOption): Promise<any> {
		options = _.merge(
			{
				displayNotification: true,
				updateIsLoding: false,
				actionType: DatasourceActionType.DEL,
			},
			options
		);
		return this.call(this.fetchFunction(path, 'DELETE', init), options);
	}

	private call(ApiPromise: Promise<any>, options: DatasourceApiOption): Promise<any> {
		if (options.updateIsLoding) this.setIsLoading(true);
		return ApiPromise.then((jsonApiResponse: any) => {
			if (options.displayNotification) this.notifySuccess();
			this.getNormalizedData(jsonApiResponse);
			this.paginationSubject$.next(this.datasourceMeta);
			this.responseSubject$.next(
				_.extend({}, { success: true, actionType: options.actionType }, this.datasourceResponse)
			);
			return this.datasourceResponse;
		})
			.catch((exception: any) => {
				if (options.displayNotification)
					this.notifyError(new ErrorFormatter(exception.response).messages, options.errorTitle);
				this.responseSubject$.next(
					_.extend({}, { success: false, actionType: options.actionType }, this.datasourceResponse)
				);
			})
			.finally(() => {
				if (options.updateIsLoding) this.setIsLoading(false);
			});
	}

	/******************************************* Pagination & Sorting *******************************************/
	public setPerPage(newPerPage: number) {
		this._perPage = newPerPage;
		this.touch();
		this.paginationSubject$.next(this.datasourceMeta);
	}

	public setCurrentPage(newCurrentPage: number) {
		this._currentPage = newCurrentPage;
		this.paginationSubject$.next(this.datasourceMeta);
		this.touch();
	}

	public setSortBy(newSortBy: string) {
		this._sortBy = newSortBy;
		this.touch();
	}

	private setMeta(newMeta: JsonApiResponseMeta) {
		this._perPage = newMeta.per_page;
		this._currentPage = newMeta.current_page;
		this._totalCount = newMeta.total_count;
	}

	/******************************************* Rendering *******************************************/

	private touch(): void {
		if (!_.isNil(this.renderTrigger)) this.renderTrigger(moment());
	}

	public setIsLoading(isLoading: boolean): void {
		if (this._isLoading !== isLoading) {
			this._loadingCount += 1;
			this._isLoading = isLoading;
			this.loadingSubject$.next(this._isLoading);
			this.touch();
		}
	}

	/******************************************* Notification *******************************************/

	private notifySuccess(message: string = "", title: string = "Successful!"): void {
		new LocalNotification(message, title, "success");
	}

	private notifyError(message: string = "", title: string = "Something went wrong!"): void {
		new LocalNotification(message, title, "error");
	}

	/******************************************* Normalization *******************************************/

	static getNormalizedData(jsonApiResponse: any, mainModelName: string) {
		let datasource = new Datasource({ mainModelName: mainModelName });
		datasource.getNormalizedData(jsonApiResponse);
		return datasource.normalizedMainModelResponse;
	}

	// normalize JSON API Data
	getNormalizedData(jsonApiResponse: any) {
		this.jsonApiResponse = jsonApiResponse;
		this.normalizedResponse = new Normalizer(jsonApiResponse, {
			includeRelationship: this.includeRelationship,
		}).normalize();
		this.setMeta(this.normalizedResponse.meta);

		if (!_.isEmpty(this.mainModelName)) {
			// the ticky point is when relationshp is of the same type of mainModel, causing these records also
			// been appended into this type's entities array, so we have to filter out the required data
			// using result[type] which is the mainModel record ids without same type relationship ones
			let modelEntities = _.values(this.normalizedResponse["entities"][this.mainModelName]);
			this.normalizedMainModelResponse = _.filter(modelEntities, (entity) =>
				_.includes(this.normalizedResponse["result"][this.mainModelName], entity.id)
			);
			if (this.normalizedResponse.isSingleRecord)
				this.normalizedMainModelResponse = this.normalizedMainModelResponse[0];
		}
	}
}
