import React from "react";
import { View } from "components/Shared";
import { SignIn } from "components/Session";

export default class Unauthenticated extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dragging: false
		};
	}

	componentDidMount() {
		document.getElementById("app").classList.add("is-error", "is-unauthenticated");
	}

	UNSAFE_componentWillReceiveProps() {
		document.getElementById("app").classList.add("is-error", "is-unauthenticated");
	}

	componentWillUnmount() {
		document.getElementById("app").classList.remove("is-error", "is-unauthenticated");
	}

	render() {
		return (
			<section>
				<View resource="unauthenticated" hideTitle>
					<SignIn />
				</View>
			</section>
		);
	}
}
