import React from "react";
import Modal from "react-modal";
import { ModalHeader, ListGroup, ListGroupItem } from "components/Shared";
import _ from "lodash";
import { Show as Avatar } from "components/Avatar";
import { ResourceContext, ProjectContext } from "context";
import { addSpectatorToTask } from "actions";
import { connect } from "react-redux";

class Invite extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { addSpectatorToTask, isOpen, onRequestClose } = this.props;

		return (
			<ProjectContext.Consumer>
				{project => (
					<ResourceContext.Consumer>
						{resource => {
							const projectUsers = _.uniqBy(
								_.sortBy(
									_.map(_.get(project, "permissions"), p => p.user),
									user => user.surname
								),
								"id"
							);
							const taskUsers = _.uniqBy(
								_.sortBy(
									_.map(_.get(resource, "permissions"), p => p.user),
									user => user.surname
								),
								"id"
							);
							const allUsers = _.uniqBy([...projectUsers, ...taskUsers], "id");
							return (
								<Modal isOpen={isOpen} onRequestClose={onRequestClose} className="invite-modal" contentLabel="Invite">
									<ModalHeader title="add_user" onRequestClose={onRequestClose} />
									<section>
										<ListGroup
											collection={allUsers}
											renderRow={user => {
												const canAdd =
													!_.includes(_.get(resource, "user_ids", []), _.get(user, "id")) &&
													!_.includes(_.get(resource, "spectator_ids", []), _.get(user, "id"));
												return (
													<ListGroupItem
														key={_.get(user, "id")}
														disabled={!canAdd}
														badge={!canAdd && "On Task"}
														icon={<Avatar name={`${_.get(user, "firstname")} ${_.get(user, "surname")}`} />}
														heading={_.get(user, "name")}
														link={() => addSpectatorToTask(resource, user).then(onRequestClose)}
													/>
												);
											}}
										/>
									</section>
								</Modal>
							);
						}}
					</ResourceContext.Consumer>
				)}
			</ProjectContext.Consumer>
		);
	}
}

const mapStateToProps = (_state, props) => props;

const mapDispatchToProps = dispatch => ({
	addSpectatorToTask: (task, user) => dispatch(addSpectatorToTask(task, user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
