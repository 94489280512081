import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const postPermission = memoAC((resource: tcpinpoint.Resource, user: tcpinpoint.User) => (dispatch: any) => {
	dispatch({ type: "POST_PERMISSIONS_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}permissions`, 
	{ resource: resource.link, user_id: user.id },
	{
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "POST_PERMISSIONS_SUCCESS" }))
	.catch((e) => { dispatch({ type: "POST_PERMISSIONS_FAILED", response: e }); });
});

export const getPermissions = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_PERMISSIONS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}permissions`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_PERMISSIONS_SUCCESS", resource: res.data.permissions }))
	.catch((e) => { dispatch({ type: "GET_PERMISSIONS_FAILED", response: e }); });
});
