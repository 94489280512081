import React, { Fragment, memo } from "react";
import { DefinitionList, DefinitionItem, Address, PhoneNumber, Button, Col, Row } from "components/Shared";
import { translate } from "lib";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Center } from "v2/components/shared";

interface Props extends RouteComponentProps {
	tenant: tcpinpoint.Tenant;
	name?: string;
	className?: string;
	editable?: boolean;
	onUpdate?: () => void;
	icon?: string;
	onClear?: (props: any) => any;
}

const Tenant = ({ tenant, history, editable = false, onClear }: Props) => {
	if (!!tenant) {
		return (
			<Fragment>
				{!!onClear && (
					<button type="button" className="close" aria-label="Close" onClick={onClear}>
						<span aria-hidden="true">×</span>
					</button>
				)}

				<Center>
					<Row>
						<Col>
							<DefinitionList className="mt-5">
								<DefinitionItem title="trading_name" value={tenant.trading_name} className="mb-2" />
								<DefinitionItem title="contact_name" value={tenant.contact_name} className="mb-2" />
								<DefinitionItem title="contact_email" value={tenant.contact_email} className="mb-2" />
								<DefinitionItem
									title="phone_number"
									value={<PhoneNumber phone_number={tenant.phone_number} />}
									className="mb-2"
								/>
								{!!_.get(tenant, "addresses", []).length && (
									<DefinitionItem title="address" className="mb-2">
										{_.map(tenant.addresses, address => (
											<Address key={address.id} address={address} />
										))}
									</DefinitionItem>
								)}
								<DefinitionItem title="sector" value={translate(tenant.sector)} className="mb-2" />
								<DefinitionItem title="purpose" value={tenant.purpose} className="mb-2" />
								<DefinitionItem
									title="business_code"
									value={_.get(tenant, "business_code.name", false)}
									className="mb-2"
								/>
								<DefinitionItem title="source" value={translate(tenant.source)} className="mb-2" />
							</DefinitionList>
							{!!editable && <Button onClick={() => history.push(`/tenants/${tenant.id}/edit`)}>Edit</Button>}
						</Col>
					</Row>
				</Center>
			</Fragment>
		);
	}
	return null;
};

export default memo(withRouter(Tenant));
