import { Button, LoadingState, ModalHeader } from "components/Shared";
import Datasource, { DatasourceResponse } from "lib/datasource";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

const ArchiveProjectModal = ({ isOpen, onRequestClose, projectId }: any) => {
	const history = useHistory();
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "project", renderTrigger: renderTrigger }));
	const closeProject = useCallback(() => {
		datasource.put("v2", `projects/${projectId}/close`, {}, { updateIsLoding: true });
	}, [datasource, projectId]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) {
				!!onRequestClose && onRequestClose();
				setTimeout(() => history.go(0), 1000);
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, history, onRequestClose]);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			className="archive"
			onRequestClose={onRequestClose}
			contentLabel="close-project"
		>
			<ModalHeader title="close_project" onRequestClose={onRequestClose} />
			<section>
				<p>
					Archiving this project will close this project, meaning you will still be able to view the project and its
					resources but you won't be able to act on it.
				</p>
				<p>Are you sure?</p>
			</section>
			<footer>{datasource.isLoading ? <LoadingState /> : <Button onClick={closeProject} />}</footer>
		</Modal>
	);
};

export default memo(ArchiveProjectModal);
