import React, { memo } from "react";


const Completion = ({ color, status, font, radius = "2.5rem", data, }: any) => {
  return (
    <div
      className={`progress-bar progress-bar-striped progress-bar-animated bg-${status}`}
      style={{
        width: data,
        backgroundColor: color,
        borderRadius: radius,
        color: font
      }}
    >
      {data}
    </div>
  );
};

export default memo(Completion)
