import _ from "lodash";
import classNames from "classnames";
import Datasource, { DatasourceResponse } from "lib/datasource";
import DatePicker from "react-datepicker";
import moment, { MomentInput } from "moment";
import Point from "./Point";
import React, { Dispatch, memo, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, CheckBox } from "v2/components/shared";
import { LoadingState } from "components/Shared";
import axios from "v2/utils/axios";

const OneTaskRow = ({ templateId, task, task_idx, phase, reversed }: any) => {
	const [, renderTrigger] = useState(moment());	
	const { context, setContext } = useContext(TemplateRescheduleContext);
	const scheduleData = useMemo(() => _.find(context, scheduleData => scheduleData.id === task.id), [task, context]);

	const rescheduledDueAtBgColor = useMemo(() => {
		return moment(scheduleData.rescheduled_due_at).toDate() < moment().toDate()
			? "text-error-darker"
			: moment(scheduleData.rescheduled_due_at).isSame(moment(scheduleData.tcp_due_at))
			? "hidden"
			: "text-warning-light";
	}, [scheduleData]);

	const rescheduledLengthBgColor = useMemo(() => {
		return parseInt(scheduleData.rescheduled_length) <= 0
			? "text-error-darker"
			: parseInt(scheduleData.rescheduled_length) !== parseInt(scheduleData.tcp_length)
			? "text-warning-light"
			: "hidden";
	}, [scheduleData]);

	const minDate = useMemo(
		() =>
			reversed
				? null
				: moment(scheduleData.latest_effective_parent_due_at)
						.add(1, "days")
						.toDate(),
		[reversed, scheduleData]
	);

	const getDaysBetween = (startDate: MomentInput, endDate: MomentInput) =>
		moment(endDate).diff(moment(startDate), "days");

	const getReschedulePreview = useCallback(
		(rescheduled_tasks: any[]) => {
			for(var i = 0; i < rescheduled_tasks.length; i++){
				delete rescheduled_tasks[i].name;
			}
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}templates/${templateId}/reschedule-preview`, 
					{ 
						params: {
							rescheduled_tasks: JSON.stringify(rescheduled_tasks),
							target_task_id: task.id,
							reversed: reversed
						}
					})
				.then((res) => setContext(res.data));
		},
		[templateId, setContext, task, reversed]
	);

	// change task's due_at (rescheduled_due_at)
	const onChangeDueAt = useCallback(
		(newDueAt: any) => {
			newDueAt = moment(newDueAt);
			if (moment(scheduleData.rescheduled_due_at).isSame(newDueAt)) return;
			if (_.isNil(newDueAt)) newDueAt = moment(scheduleData.tcp_due_at);

			let newState = [...context];
			let targetData = _.find(newState, scheduleData => scheduleData.id === task.id);
			scheduleData.rescheduled_due_at = targetData.rescheduled_due_at = moment(newDueAt).format("YYYY-MM-DD");
			if (!reversed)
				scheduleData.rescheduled_length = targetData.rescheduled_length = getDaysBetween(
					scheduleData.latest_effective_parent_due_at,
					newDueAt
				);
			getReschedulePreview(newState);
		},
		[scheduleData, context, task.id, getReschedulePreview, reversed]
	);

	// change task's duration
	const onChangeDuration = useCallback(
		(newDuration: number) => {
			if (scheduleData.rescheduled_length === newDuration) return;
			newDuration = _.max([newDuration, 1]) as number;
			let newState = [...context];
			let targetData = _.find(newState, scheduleData => scheduleData.id === task.id);

			if (!reversed) {
				scheduleData.rescheduled_due_at = targetData.rescheduled_due_at = moment(
					targetData.latest_effective_parent_due_at
				)
					.add(newDuration, "days")
					.format("YYYY-MM-DD");
			}
			scheduleData.rescheduled_length = targetData.rescheduled_length = newDuration;
			getReschedulePreview(newState);
		},
		[scheduleData, context, task.id, getReschedulePreview, reversed]
	);

	const onChangeDueAtFixed = (e: any) => {
		scheduleData.due_at_fixed = e.target.checked as boolean;
	};

	const onClickDueAtFixed = (e: any) => {
		scheduleData.due_at_fixed = !scheduleData.due_at_fixed;
		renderTrigger(moment());
	};

	return (
		<tr key={task.id}>
			{parseInt(task_idx) === 0 && (
				<th rowSpan={_.size(phase.tasks)} scope="rowgroup" className="font-semibold">
					{phase.name}
				</th>
			)}
			<th scope="row" className="font-medium">
				{task.name}
			</th>
			<td className={classNames("relative text-center")}>
				<Point className={classNames("", rescheduledLengthBgColor)} />
				<input
					type="number"
					className="mx-auto text-center bg-transparent hover:cursor-text"
					value={scheduleData.rescheduled_length}
					onChange={(event: any) => onChangeDuration(event.target.value)}
				/>
			</td>
			<td className="text-center">
				<input
					type="text"
					value={moment(scheduleData.tcp_due_at).format("D MMM yyyy")}
					className="mx-auto bg-transparent text-center hover:cursor-not-allowed text-black-40"
					readOnly
				/>
			</td>
			<td>
				<i className="fas fa-long-arrow-alt-right"></i>
			</td>
			<td className={classNames("text-center")}>
				<Point className={classNames("", rescheduledDueAtBgColor)} />
				<DatePicker
					dateFormat="d MMM yyyy"
					className="mx-auto text-center bg-transparent hover:cursor-pointer"
					selected={moment(scheduleData.rescheduled_due_at).toDate()}
					onChange={onChangeDueAt}
					minDate={minDate}
				/>
			</td>
			<td className="text-center">
				<CheckBox
					defaultChecked={scheduleData.due_at_fixed}
					checked={scheduleData.due_at_fixed}
					onClick={onClickDueAtFixed}
					onChange={onChangeDueAtFixed}
					style={{ marginRight: "5px" }}
				/>
			</td>			
		</tr>
	);
};

interface ITemplateRescheduleContext {
	context: any[];
	setContext: Dispatch<SetStateAction<any[]>>;
}

export const TemplateRescheduleContext = React.createContext({
	context: [] as any[],
	setContext: (context: any[]) => {}
} as ITemplateRescheduleContext);

const Reschedule = ({ templateId, onUpdate }: any) => {
	const [reversed, setReversed] = useState(false);
	const [template, setTemplate] = useState({} as any);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "template", renderTrigger: renderTrigger }));
	const [phases, setPhases] = useState([] as any[]);
	const [context, setContext] = useState([] as any[]);
	const templateContextProviderValue = { context, setContext };
	const [fixAllTask, setFixAllTask] = useState(false);
	const getDefaultTemplateSchedule = useCallback(() => {
		setFixAllTask(false)
		if (!_.isEmpty(templateId))
			datasource.get("v2", `templates/${templateId}`, {
				queryStringParameters: { fields: JSON.stringify({ template: ["body", "tcp_scheduled_tasks"] }) }
			});
	}, [datasource, templateId]);

	const onChangeFixAllTask = (e: any) => {
		setFixAllTask(e.target.checked as boolean);
		context.forEach((element) => { element.due_at_fixed = e.target.checked as boolean });
		setContext(context);
	};

	const onChangeReversed = (e: any) => {
		setReversed(e.target.checked as boolean);
	};

	useEffect(() => {
		!!onUpdate && onUpdate(context);
	}, [onUpdate, context]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			const template = response.normalizedMainModelResponse;
			setTemplate(template);
			setContext(template.tcp_scheduled_tasks);
			setPhases(_.sortBy(_.get(template, "body.phases", []), phase => phase.index));
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		getDefaultTemplateSchedule();
	}, [getDefaultTemplateSchedule]);

	if (datasource.isLoading || _.isEmpty(template)) return <LoadingState />;

	return (
		<>
			<div className="text-center my-3 flex justify-between align-middle mx-1">
				<div className="left flex items-center">
					<div className="text-18 leading-6 text-center font-medium">I would like to reverse schedule from a Task</div>
					<CheckBox defaultChecked={reversed} onChange={onChangeReversed} className="ml-3 h-4" checked={reversed}/>
				</div>
				<Button onClick={getDefaultTemplateSchedule} className="bg-primary text-white mr-0">
					Reset
				</Button>
			</div>
			<table
				className={classNames("table border-5 border-blue-darker", {
					"border-warning-darker": reversed
				})}
			>
				<thead>
					<tr
						className={classNames(" bg-blue-darker text-white", {
							"bg-warning-darker": reversed
						})}
					>
						<th>Phase Name</th>
						<th>Task Name</th>
						<th className="text-center">Calendar days to complete</th>
						<th className="text-center">Scheduled Due Date</th>
						<th className="text-center"></th>
						<th className="text-center">Adjusted Due Date</th>
						<th className="text-center">Fix All <CheckBox defaultChecked={fixAllTask} onChange={onChangeFixAllTask} className="ml-3 h-4" checked={fixAllTask}/> </th>						
					</tr>
				</thead>
				<TemplateRescheduleContext.Provider value={templateContextProviderValue}>
					{_.map(phases, phase => {
						const tasks = _.get(phase, "tasks", []);
						return (
							<tbody key={phase.id}>
								{_.map(tasks, (task, task_idx) => (
									<OneTaskRow
										key={task_idx}
										reversed={reversed}
										templateId={templateId}
										task={task}
										task_idx={task_idx}
										phase={phase}
									/>
								))}
							</tbody>
						);
					})}
				</TemplateRescheduleContext.Provider>
			</table>
		</>
	);
};

export default memo(Reschedule);
