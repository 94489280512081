import React, { memo, MutableRefObject, useEffect } from "react";
import classNames from "classnames";

const Checkbox = ({
	onClick,
	agree,
	className,
	submitRef
}: {
	width?: string;
	height?: string;
	className?: string;
	agree: boolean;
	onClick?: (i?: any) => any;
	submitRef?: MutableRefObject<HTMLInputElement | null>;
}) => {
	useEffect(() => {
		if (!!agree) {
			submitRef?.current?.focus();
		}
	}, [agree, submitRef]);

	return (
		<span
			onClick={() => !!onClick && onClick()}
			className={classNames(
				"inline-block align-middle mr-1 h-4 w-4 rounded-1",
				{
					"text-blue-100": !!agree,
					"text-black-60": !agree,
					border: !agree,
					"border-blue-100": agree
				},
				className
			)}
		>
			{agree && (
				<svg
					viewBox="64 64 896 896"
					focusable="false"
					data-icon="check"
					fill="currentColor"
					aria-hidden="true"
					className="rounded-2"
				>
					<path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM695.5 365.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L308.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H689c6.5 0 10.3 7.4 6.5 12.7z" />
				</svg>
			)}
		</span>
	);
};

export default memo(Checkbox);
