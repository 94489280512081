import React, { memo } from "react";
import { CollectionDynamic } from "components/Documents"
import { Workspace, Breadcrumbs, Header, View } from "components/Shared";

const List = () => {

	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: "documents" }]} />
			<Header title={"documents"} />
			<Workspace id="documents" className="list">
				<View>
					<CollectionDynamic />
				</View>
			</Workspace>
		</React.Fragment>
	);
};

const MemoizedList = memo(List);

export default () => <MemoizedList />;
