import React, { useCallback, useContext, useState, useEffect } from "react";
import { Input } from "components/Inputs";
import { Button } from "v2/components/shared";
import { Col, Row, LoadingState } from "components/Shared";
import { AsyncSelect as BuildingLookup } from "v2/components/Buildings";
import { AsyncSelect as PropertyLookUp } from "v2/components/Properties";
import { FloorAsyncSelect as FloorLookup } from "v2/components/Tenancies/";
import { AsyncSelect as TenancyLookup } from "v2/components/Tenancies";
import { NewProjectContext } from "v2/context";
import { useGetBuilding } from "v2/hooks";
import { parse as parseUrl } from "query-string";
import Datasource, { DatasourceResponse } from "lib/datasource";

// @ts-ignore
import validate from "validate.js";
import classNames from "classnames";
import _ from "lodash";

const constraints = {
	name: {
		presence: {
			message: "^ is required",
		},
	},
};

const Form = ({ defaultTenancy, onUpdate, tenancy }: { defaultTenancy?: any; onUpdate?: any; tenancy?: any }) => {
	const { template } = useContext(NewProjectContext);
	const [property, setProperty] = useState<any>(tenancy?.property);
	const [buildingId, setBuildingId] = useState<string>("");
	const [floorId, setFloorId] = useState<string>("");
	const [kind, setKind] = useState(tenancy?.tenancy_kind);
	const [tenancyExist, setTenancyExist] = useState(false);
	const [tenancyId, setTenancyId] = useState(defaultTenancy);
	const [name, setName] = useState("");
	const [area, setArea] = useState(0);
	const [category, setCategory] = useState("");
	const [errors, setErrors] = useState();
	const { property_id } = parseUrl(window.location.search) as { property_id: string };
	const [defaultPropertyAddress, setDefaultPropertyAddress] = useState<string>("");
	const building = useGetBuilding(property?.id, buildingId);

	const getTenancyKindOptions = useCallback(
		() => [
			!!tenancyExist && "existing_tenancy",
			"new_tenancy",
			!_.includes(_.get(template, "tags"), "flinders") ? "unallocated_space" : false,
		],
		[tenancyExist, template]
	);

	const [datasource] = useState(new Datasource({ mainModelName: "property" }));

	var startWithPropertyId = property_id && property && defaultPropertyAddress;
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setProperty(response.normalizedMainModelResponse);
			setDefaultPropertyAddress(response.normalizedMainModelResponse.name);
		});
		return () => sub.unsubscribe();
	}, [datasource]);
	useEffect(() => {
		if (!_.isEmpty(property_id)) {
			datasource.get("v2", `properties/${property_id}`, {});
		}
	}, [datasource, property_id]);
	const onSubmit = () => {
		if (kind === "existing_tenancy" && tenancyId) {
			const tenancy = {
				id: tenancyId,
				building_id: buildingId,
				floor_id: floorId,
				tenancy_exist: tenancyExist,
				tenancy_kind: kind,
				property,
				building,
			};
			onUpdate(tenancy);
		}

		if (kind !== "existing_tenancy") {
			const finalName = kind === "unallocated_space" ? "Unallocated Space" : name;
			const tenancy = {
				building_id: buildingId,
				floor_id: floorId,
				tenancy_exist: tenancyExist,
				tenancy_kind: kind,
				name: finalName,
				area,
				property,
				building,
				kind: category,
			};
			const errors = validate(tenancy, constraints);
			!!errors ? setErrors(errors) : onUpdate(tenancy);
		}
	};

	if (startWithPropertyId || !property_id) {
		//const newArea = {area};
		return (
			<Row>
				<Col>
					<PropertyLookUp
						onChange={setProperty}
						name="property"
						defaultState={{
							name: defaultPropertyAddress,
							errors,
						}}
					/>
					<BuildingLookup propertyId={property?.id} onChange={setBuildingId} defaultState={{ errors }} />
					<FloorLookup
						buildingId={buildingId}
						onChange={(floor_id: string, tenancy_exist: boolean) => {
							setFloorId(floor_id);
							setTenancyExist(tenancy_exist);
						}}
						defaultState={{ floor_id: "", errors }}
					/>
					<Input
						onChange={setKind}
						name="tenancy_kind"
						defaultState={{ tenancy_kind: kind, errors }}
						required={constraints}
						options={getTenancyKindOptions()}
						showIf={!!property}
					/>

					{kind === "existing_tenancy" && <TenancyLookup floorId={floorId} onChange={setTenancyId} />}

					<Input
						onChange={setName}
						name="name"
						defaultState={{ name, errors }}
						required={constraints}
						label={_.includes(_.get(template, "tags"), "flinders") ? "room_number" : "tenancy_name"}
						showIf={kind === "new_tenancy"}
					/>

					<Input
						onChange={setArea}
						name="area"
						defaultState={{ area, errors }}
						required={constraints}
						suffix="m²"
						type="number"
						min="0"
						showIf={kind === "new_tenancy" || kind === "unallocated_space"}
					/>
					<Input
						onChange={setCategory}
						name="category"
						defaultState={{ category, errors }}
						required={constraints}
						options={["speciality", "food", "kiosk", "food-kiosk"]}
						showIf={
							(kind === "new_tenancy" || kind === "unallocated_space") && _.includes(_.get(template, "tags"), "retail")
						}
					/>
					<Button
						label="Next"
						className={classNames("float-right text-white mt-5", {
							"bg-blue-100 hover:shadow-hover shadow-button": true,
						})}
						onClick={onSubmit}
					/>
				</Col>
			</Row>
		);
	}

	return <LoadingState />;
};

export default Form;
