import React, { memo } from "react";
import classNames from "classnames";

const Col = ({
	id,
	className,
	children,
	style = {}
}: {
	id?: string;
	className?: string;
	children?: any;
	style?: any;
}) => {
	return (
		<div
			{...{
				id,
				style,
				className: classNames("col", className)
			}}
		>
			{children}
		</div>
	);
};

export default memo(Col);
