import classNames from "classnames";
import { Show as Avatar } from "components/Avatar";
import { ListGroup, LoadingState, ModalHeader } from "components/Shared";
import { LastMomentTriggerAPIContext } from "context";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";

const OneUserRow = ({ taskId, user, onRequestClose }: any) => {
	const { setLastMoment } = useContext(LastMomentTriggerAPIContext);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "task::project_user", renderTrigger: renderTrigger }));
	const addUserAsSpectator = useCallback(() => {
		datasource.post("v2", `tasks/${taskId}/add-spectator`, { body: { user_id: user.id } }, { updateIsLoding: true });
	}, [taskId, user, datasource]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) setLastMoment(moment());
		});
		return () => sub.unsubscribe();
	}, [datasource, setLastMoment]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<>
			<a
				href="#link"
				onClick={user.connected ? undefined : addUserAsSpectator}
				className={classNames("list-group-item", { disabled: user.connected })}
			>
				<div className="stamp">
					<Avatar name={`${_.get(user, "firstname")} ${_.get(user, "surname")}`} />
				</div>
				<span className="list-group-item-content">
					<div className="list-group-item-heading">
						<h3>{_.get(user, "name")}</h3>
					</div>
				</span>
				<div className="badge ml-2">{user.connected && "On Task"}</div>
				<div className="disclosure">
					<i className="fas fa-angle-right" />
				</div>
			</a>
		</>
	);
};

const InviteModal = ({ taskId, isOpen, onRequestClose }: any) => {
	const { lastMoment } = useContext(LastMomentTriggerAPIContext);
	const [users, setUsers] = useState([] as any[]);
	const [, renderTrigger] = useState(moment());
	const [projectUserDatasource] = useState(
		new Datasource({
			mainModelName: "task::project_user",
			renderTrigger: renderTrigger,
			perPage: -1
		})
	);

	useEffect(() => {
		let sub = projectUserDatasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setUsers(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [projectUserDatasource]);

	// API call
	useEffect(() => {
		if (isOpen && !_.isNil(taskId))
			projectUserDatasource.get("v2", `tasks/${taskId}/project-users`, {
				queryStringParameters: {
					sort: projectUserDatasource.sortBy,
					per_page: projectUserDatasource.perPage,
					page: projectUserDatasource.currentPage
				}
			});
	}, [isOpen, taskId, projectUserDatasource, projectUserDatasource.sortBy, lastMoment]);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className="invite-modal"
			contentLabel="Invite"
		>
			<ModalHeader title="add_user_as_task_spectator" onRequestClose={onRequestClose} />
			<section>
				{projectUserDatasource.isFirstTimeLoading && <LoadingState />}
				{!projectUserDatasource.isLoading && (
					<ListGroup collection={users} renderRow={user => <OneUserRow key={user.id} user={user} taskId={taskId} />} />
				)}
			</section>
		</Modal>
	);
};

export default memo(InviteModal);
