import React from "react";
import { View, Button, EmptyState } from "components/Shared";
import { Ledger } from "components/Ledgers/Create";
import { Input } from "components/Inputs";
import validate from "validate.js";
import uuidv4 from "uuid/v4";
import _ from "lodash";

const constraints = {
  name: {
    presence: true
  }
};

export default class Ledgers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ledgers: _.get(props, "ledgers", [])
    };
    this.input = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ ledgers: _.get(props, "ledgers", []) });
  }

  onUpdate(ledger) {
    const { onUpdate } = this.props;
    const { ledgers } = this.state;
    const newLedgers = _.filter(ledgers, l => l.id !== ledger.id);
    newLedgers.push(ledger);
    this.setState({ ledgers: newLedgers }, () => onUpdate(newLedgers));
  }

  onDelete(ledger) {
    const { onUpdate } = this.props;
    const { ledgers } = this.state;
    const newLedgers = _.filter(ledgers, l => l.id !== ledger.id);
    this.setState({ ledgers: newLedgers }, () => onUpdate(newLedgers));
  }

  onCreate() {
    const { onUpdate } = this.props;
    const errors = validate(this.state, constraints);
    if (!!errors) {
      this.setState({ errors });
    } else {
      const { ledgers, name } = this.state;
      const newLedgers = ledgers || [];
      newLedgers.push({
        id: uuidv4(),
        index: ledgers.length + 1,
        name,
        quotes: []
      });
      this.input.current.value = "";
      this.setState({ ledgers: newLedgers }, () => onUpdate(newLedgers));
    }
  }

  render() {
    const { ledgers } = this.state;
    return (
      <React.Fragment>
        <View resource="ledgers">
          {!ledgers.length && <EmptyState resource="ledgers" />}
          {_.map(_.sortBy(ledgers, "index"), ledger => (
            <Ledger
              key={ledger.id}
              ledger={ledger}
              onDelete={ledger => this.onDelete(ledger)}
              onUpdate={ledger => this.onUpdate(ledger)}
            />
          ))}
        </View>
        <View resource="add_ledger">
          <Input
            defaultState={this.state}
            required={constraints}
            name="name"
            ref={this.input}
            onEnter={() => this.onCreate()}
            onChange={name => this.setState({ name })}
          />
          <Button kind="add" onClick={() => this.onCreate()} />
        </View>
      </React.Fragment>
    );
  }
}
