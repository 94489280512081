import classNames from "classnames";
import Datasource, { DatasourceMeta } from "lib/datasource";
import _ from "lodash";
import React, { memo, useEffect, useState, useCallback } from "react";

interface Props {
	maximumPagesInWidget?: number;
	datasource: Datasource;
}

const Pagination = (props: Props) => {
	let { maximumPagesInWidget = 10 } = props;
	const [datasource] = useState(props.datasource);
	const [state, setState] = useState({
		currentPage: 1,
		perPage: 10,
		displayedFirstPage: 1,
		displayedLastPage: 1,
		displayedPages: [] as number[],
		totalPage: 0,
		totalCount: 0
	});

	const onChangeCurrentPage = (newCurrentPage: number) => datasource.setCurrentPage(newCurrentPage);
	const onChangePerPage = (newPerPage: number) => datasource.setPerPage(newPerPage);
	const calculatePages = useCallback(
		(datasourceMeta: DatasourceMeta) => {
			let { currentPage, perPage, totalCount } = datasourceMeta;
			let totalPage = Math.ceil(totalCount / perPage);
			let displayedLastPage = totalPage;
			let displayedFirstPage = 1;
			if (displayedLastPage > maximumPagesInWidget) {
				if (currentPage > Math.ceil(maximumPagesInWidget / 2))
					displayedFirstPage = currentPage - Math.ceil(maximumPagesInWidget / 2);
				if (displayedFirstPage + maximumPagesInWidget <= displayedLastPage)
					displayedLastPage = maximumPagesInWidget + displayedFirstPage;
			}
			let displayedPages = _.range(displayedFirstPage, displayedLastPage + 1);
			setState(prevData =>
				_.assign({}, prevData, {
					currentPage,
					perPage,
					displayedFirstPage,
					displayedLastPage,
					displayedPages,
					totalPage,
					totalCount
				})
			);
		},
		[maximumPagesInWidget, setState]
	);

	const PageItem = ({ pageNumber, label, iconClassNames, icon, active, keyValue }: any) => (
		<>
			<li key={keyValue} className={`page-item ${active ? "active" : ""}`}>
				<a
					className="page-link"
					href={`#page-${pageNumber}`}
					aria-label={label}
					onClick={event => {
						event.preventDefault();
						onChangeCurrentPage(pageNumber);
					}}
				>
					<i className={iconClassNames} />
					{icon}
				</a>
			</li>
		</>
	);

	useEffect(() => {
		calculatePages(datasource.datasourceMeta);
		let paginationSubscription = datasource.paginationSubject$.subscribe((datasourceMeta: DatasourceMeta) =>
			calculatePages(datasourceMeta)
		);
		return () => paginationSubscription.unsubscribe();
	}, [datasource, calculatePages]);

	if (state.totalCount <= 1) return null;
	return (
		<nav className="pagination" aria-label="Page Navigation">
			{state.totalPage > 1 && (
				<ul>
					<PageItem
						key={"first"}
						keyValue={"first"}
						pageNumber={1}
						label={`Go to First Page`}
						icon={<i className="fas fa-angle-double-left" />}
					></PageItem>
					{state.currentPage !== state.displayedFirstPage && (
						<PageItem
							key={"previous"}
							keyValue={"previous"}
							pageNumber={state.currentPage - 1}
							label={`Go to Previous Page`}
							icon={<i className="fas fa-angle-left" />}
						></PageItem>
					)}
					{state.displayedPages.map(page => (
						<PageItem
							key={page}
							keyValue={page}
							pageNumber={page}
							label={`Go to Next ${page}`}
							icon={page}
							active={page === state.currentPage}
						></PageItem>
					))}
					{state.currentPage !== state.displayedLastPage && (
						<PageItem
							key={"next"}
							keyValue={"next"}
							pageNumber={state.currentPage + 1}
							label={`Go to Next Page`}
							icon={<i className="fas fa-angle-right" />}
						></PageItem>
					)}
					<PageItem
						key={"last"}
						keyValue={"last"}
						pageNumber={state.totalPage}
						label={`Go to Last Page`}
						icon={<i className="fas fa-angle-double-right" />}
					></PageItem>
				</ul>
			)}
			{state.totalPage === 1 && <p>Showing all {state.totalCount}</p>}
			{state.totalPage > 1 && (
				<p>
					Showing {(state.currentPage - 1) * state.perPage + 1} -{" "}
					{state.currentPage * state.perPage > state.totalCount ? state.totalCount : state.currentPage * state.perPage}{" "}
					of {state.totalCount}
				</p>
			)}

			<p>
				Show{" "}
				{[10, 20, 50, 100].map(l => {
					return [
						<a
							href="#updateItemsPerPage"
							className={classNames({ active: state.perPage === l })}
							key={`perPage-${l}`}
							onClick={e => {
								e.preventDefault();
								onChangePerPage(l);
								onChangeCurrentPage(1);
							}}
						>
							{l}
						</a>,
						" "
					];
				})}
				at a time
			</p>
		</nav>
	);
};

export default memo(Pagination);
