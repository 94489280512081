import React, { useEffect, useState } from "react";
import { ListGroupControl, View, LoadingState } from "../../Shared";
import { ButtonDropDown } from "../ButtonDropDown";
import { OpenAnalyticTable } from "./OpenAnalyticTable";
import { OpenAnalyticChart } from "./OpenAnalyticChart";
import _ from "lodash";
import axios from "v2/utils/axios";

const OpenAnalyticReport = ({
	properties,
	preselectedPropertyId,
}: {
	properties: any;
	preselectedPropertyId: string | null;
}) => {
	const initProperty = preselectedPropertyId
		? _.find(properties, { id: preselectedPropertyId })
		: { id: "", name: "All Properties" };
	const [propertyNameSelected, setSelectedProperty] = useState(initProperty);
	const [LeaseReports, setLeaseReports] = useState([]);
	const [isReportLoading, setIsReportLoading] = useState(false);
	const [monthSelected, setMonthSelected] = useState({
		id: "6",
		name: "6 months",
	});

	const periodOfProjects = [
		{
			id: "3",
			name: "3 months",
		},
		{
			id: "6",
			name: "6 months",
		},
		{
			id: "12",
			name: "12 months",
		},
		{
			id: "24",
			name: "24 months",
		},
	];

	const fetchReportUsingMonths = (reportId: string) => {
		if (reportId === "3") {
			setMonthSelected({
				id: "3",
				name: "3 months",
			});
		} else if (reportId === "6") {
			setMonthSelected({
				id: "6",
				name: "6 months",
			});
		} else if (reportId === "12") {
			setMonthSelected({
				id: "12",
				name: "12 months",
			});
		} else if (reportId === "24") {
			setMonthSelected({
				id: "24",
				name: "24 months",
			});
		}
		getLeaseToOpenReport(propertyNameSelected.id, reportId);
		return;
	};

	const getLeaseToOpenReport = (id: string, monthPeriod: string) => {
		const path =
			id.length > 0
				? `advanced_reports/lease_to_open_report?property_id=${id}&months=${monthPeriod}&report_type=open_analytic`
				: `advanced_reports/lease_to_open_report?months=${monthPeriod}&report_type=open_analytic`;

		axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}${path}`)
			.then((res) => setLeaseReports(res.data))
			.catch((e) => {})
			.finally(() => setIsReportLoading(false));
	};
	//run once on load
	useEffect(() => {
		setIsReportLoading(true);
		getLeaseToOpenReport(initProperty.id, monthSelected.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const callBack = (id: string) => {
		setIsReportLoading(true);
		if (id === "") {
			setSelectedProperty({
				id: "",
				name: "All Properties",
			});
		} else {
			let property = _.find(properties, { id: id });
			setSelectedProperty({
				id: property.id,
				name: property.name,
			});
		}
		getLeaseToOpenReport(id, monthSelected.id);
		return;
	};

	let tempLeaseReport = Object.values(LeaseReports);
	let leaseToOpenReport: any = tempLeaseReport[0];

	return (
		<View className="flex flex-row">
			<ListGroupControl />
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<ButtonDropDown
						buttonOption={propertyNameSelected}
						dropdownOptions={[{ id: "", name: "All Properties" }].concat(properties)}
						callBack={(arg) => {
							callBack(arg);
						}}
						textClassNames="w-215 text-14 transition rounded-l-md text-left p-4"
						dropDownClassNames="bg-white border-borderColor border-l"
					/>
					<p className="text-reportProjectSubtitle text-14 ml-6">Choose the property you want to report on</p>
				</div>
				<div className="flex items-center">
					<p className="text-reportProjectSubtitle text-14 mr-6">Projects that opened in the last</p>
					<ButtonDropDown
						buttonOption={monthSelected}
						dropdownOptions={periodOfProjects}
						callBack={(arg) => {
							fetchReportUsingMonths(arg);
						}}
						textClassNames="w-215 text-14 transition rounded-l-md text-left p-4"
						dropDownClassNames="bg-white border-borderColor border-l"
					/>
				</div>
			</div>
			<div className=""></div>
			{isReportLoading === false ? (
				leaseToOpenReport && (
					<div>
						<div className="mt-10">
							<OpenAnalyticChart reports={leaseToOpenReport} />
							<OpenAnalyticTable reports={leaseToOpenReport} />
						</div>
					</div>
				)
			) : (
				<LoadingState />
			)}
		</View>
	);
};
export default OpenAnalyticReport;
