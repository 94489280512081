import React from "react";
import { translate } from "lib";
import { ErrorBoundary } from "components/Shared";
import Actor from "components/Activities/Actor"
import ItemObject from "components/Activities/ItemObject"

const RescheduleProject = ({ activity }: { activity: tcpinpoint.ActivityItem }) => <ErrorBoundary>
    {<Actor actor={activity.attributes.item.actor} />}{' '}
    {translate('rescheduled')}{' '}
    <ItemObject object={activity.attributes.item.object} />
</ErrorBoundary>

export default RescheduleProject;