import React from "react";
import SVG from "svgjs";
import _ from "lodash";

export default class TenancyShape extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			poi: [],
			middle: [0, 0],
			nearby: [],
			x: 0,
			y: 0,
			width: 0,
			height: 0
		};
		this.svg = React.createRef();
	}

	componentDidMount() {
		const { poi, shape } = this.props;
		const s = n => parseFloat(n, 10);
		const points = _.map(shape, point => [s(point[0]), s(point[1])]);
		const xValues = _.map(points, point => point[0]);
		const yValues = _.map(points, point => point[1]);
		const viewBox = [_.min(xValues), _.min(yValues), _.max(xValues) - _.min(xValues), _.max(yValues) - _.min(yValues)];
		const draw = SVG(this.svg.current);
		this.setState({ draw }, () => {
			draw.viewbox(viewBox);
			draw
				.polygon(points)
				.addClass("tenancy-body")
				.attr({ "vector-effect": "non-scaling-stroke" });
			_.each(poi, point =>
				draw
					.circle(1)
					.addClass("poi")
					.move(point.x, point.y)
			);
		});
	}

	// addPOI(event) {
	//   const { onClick, active } = this.props;
	//   event.preventDefault();

	//   if (onClick && active) {
	//     if (event.currentTarget.createSVGPoint) {
	//       const poi = this.state.poi ? this.state.poi : [];
	//       let uupos = event.currentTarget.createSVGPoint();

	//       uupos.x = event.pageX;
	//       uupos.y = event.pageY;
	//       let ctm = event.target.getScreenCTM();
	//       const inverseCTM = (ctm = ctm.inverse());
	//       if (inverseCTM) {
	//         uupos = uupos.matrixTransform(inverseCTM);
	//       }
	//       const newPOI = {
	//         x: Math.round(uupos.x),
	//         y: Math.round(uupos.y)
	//       };
	//       poi.push(newPOI);
	//       this.setState({ poi });
	//       onClick(poi);
	//     }
	//   }
	// }

	// renderNearby() {
	//   const { nearby } = this.state;
	//   return (
	//     !!nearby.length &&
	//     Array.isArray(nearby) && (
	//       <g id="tenancies-nearby">
	//         {nearby.map(tenancy => (
	//           <path id={tenancy.id} className="nearby-tenancy" key={tenancy.id} d={SVG.getPoints(tenancy.shape)} />
	//         ))}
	//       </g>
	//     )
	//   );
	// }

	// renderPOI() {
	//   const { poi } = this.state;
	//   return (
	//     !!poi.length && (
	//       <g id="poi-group">
	//         {poi.map(poi => (
	//           <circle
	//             key={`${poi.x}-${poi.y}`}
	//             data-id={`${poi.x}-${poi.y}`}
	//             cx={`${poi.x}`}
	//             cy={`${poi.y}`}
	//             className="poi"
	//             r="5"
	//           />
	//         ))}
	//       </g>
	//     )
	//   );
	// }

	render() {
		const { id, shape, children } = this.props;
		if (shape) {
			return (
				<div className="tenancy-shape" id={`tenancy-shape-${id}`}>
					<div ref={this.svg} />
					{children}
					{/* {(name || division) && (
              <div className="tenancy-details">
                {name && <div className="tenancy-name">{name}</div>}
                {division && <div className="tenancy-division">{division}</div>}
              </div>
            )} */}
				</div>
			);
		}
		return false;
	}
}
