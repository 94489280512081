import React, { useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "components/Shared";
import { isFeatureToggled, translate, icon, LocalNotification } from "lib";
import { UserContext } from "context";
import _ from "lodash";
import FeatureFlag from "./FeatureFlag/FeatureFlag";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "actions";

const beta = ["portfolios", "leases", "reports", "todos"];

const usePrevious = <T extends any>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const Masthead = () => {
	const dispatch = useDispatch();
	const { currentUser, cable } = useContext(UserContext) as { cable: any; currentUser: tcpinpoint.User };
	useEffect(() => {
		dispatch(getNotifications());
	}, [dispatch]);

	useEffect(() => {
		if (cable) {
			cable.subscriptions.create("NotificationsChannel", {
				received: (payload: any) => {
					dispatch(getNotifications());
					if (payload.attributes) {
						if (!!("Notification" in window) && Notification.permission === "granted") {
							var notification = new Notification(payload.attributes.name);
							setTimeout(notification.close.bind(notification), 4000);
						} else {
							new LocalNotification(payload.attributes.name, "Notification");
						}
					}
				}
			});
		}
	}, [dispatch, cable]);

	const notifications = useSelector((store: tcpinpoint.Store) => store.notifications);
	const unread = _.filter(notifications, notification => !notification.attributes.read_at).length;

	const previous = usePrevious({ unread });

	const audioElement = useRef(null);

	if (!!previous && previous.unread < unread && !!unread && !!audioElement && !!audioElement.current) {
		//@ts-ignore
		audioElement.current.play();
	}

	return (
		<React.Fragment>
			<header id="masthead" role="banner">
				<audio ref={audioElement} preload="true" src="/new-notification.mp3" />
				<div className="global-nav">
					<div id="ident">
						<NavLink to="/">
							<Logo />
						</NavLink>
					</div>
					<nav aria-label="Primary navigation" id="primary">
						<NavLink key="home" to={"/#home"} activeClassName="active" exact>
							<span>
								{icon("home")}
								<span>{translate("home")}</span>
							</span>
						</NavLink>
						{_.map(
							_.compact([
								"projects",
								"templates",
								"properties",
								"portfolios",
								"tasks",
								"reports",
								"todos",
								process.env.NODE_ENV === "development" && "documents",
								process.env.NODE_ENV === "development" && "companies",
								process.env.NODE_ENV === "development" && "users"
							]),
							link =>
								!!isFeatureToggled(link, currentUser) && (
									<NavLink key={link} to={`/${link}`} activeClassName="active">
										{_.includes(beta, link) && <FeatureFlag title="beta" />}
										<span>
											{icon(link)}
											<span>{translate(link)}</span>
										</span>
									</NavLink>
								)
						)}
					</nav>
					{process.env.NODE_ENV === "development" && (
						<span className="branch-warning">
							<p>develop branch</p>
						</span>
					)}
					<nav aria-label="Secondary Navigation" id="secondary">
						<NavLink to={"/notifications"}>
							{!!unread && <i className="fas fa-circle notification-dot" />}
							<span>{icon("notifications")}</span>
						</NavLink>
						<NavLink id="signout" key="signout" to={"/signout"}>
							<span>{icon("signout")}</span>
						</NavLink>
					</nav>
					<nav aria-label="Mobile Navigation" id="mobile">
						<NavLink to={"/projects"}>
							<span>
								{icon("projects")}
								<span>{translate("projects")}</span>
							</span>
						</NavLink>
						<NavLink to={"/properties"}>
							<span>
								{icon("properties")}
								<span>{translate("properties")}</span>
							</span>
						</NavLink>
						<NavLink to={"/tasks"}>
							<span>
								{icon("tasks")}
								<span>{translate("tasks")}</span>
							</span>
						</NavLink>
						<NavLink id="signout" key="signout" to={"/signout"}>
							<span>{icon("signout")}</span>
						</NavLink>
					</nav>
				</div>
			</header>
		</React.Fragment>
	);
};

export default React.memo(Masthead);
