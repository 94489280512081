import React from "react";
import { Workspace } from "components/Shared";
import { translate } from "lib";
import classNames from "classnames";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faCircle } from "@fortawesome/free-solid-svg-icons";

class Step extends React.Component {
	render() {
		const { children } = this.props;
		return <div className="step">{children}</div>;
	}
}
class Steps extends React.Component {
	constructor(props) {
		super(props);
		const hashSubstring = window.location.hash.substr(5);
		const currentStepIndex = hashSubstring ? Math.abs(parseInt(hashSubstring, 10)) : 0;
		this.state = {
			currentStepIndex,
			steps: [],
			status: [],
			completedSteps: []
		};
	}

	componentDidMount() {
		document.getElementById("app").classList.add("steps");
	}

	UNSAFE_componentWillReceiveProps({ children }) {
		let currentStepIndex = 0;
		const hash = window.location.hash;
		if (hash.substr(0, 5) === "#step") {
			const hashSubstring = hash.substr(6);
			currentStepIndex = parseInt(hashSubstring, 10);
		}
		document.getElementById("app").classList.add("steps");
		const steps = [];
		const labels = [];
		_.each(_.compact(children), child => {
			steps.push(child.ref);
			labels.push(!!_.get(child, "props.label") ? _.get(child, "props.label") : child.ref);
		});
		const enabledSteps = _.map(_.filter(children, child => !!_.get(child, "props.enabled", false)), child => child.ref);
		const completedSteps = _.map(
			_.filter(children, child => !!_.get(child, "props.complete", false)),
			child => child.ref
		);
		this.setState({
			steps: _.compact(steps),
			labels: _.compact(labels),
			enabledSteps,
			completedSteps,
			currentStepIndex: Math.abs(currentStepIndex)
		});
	}

	componentWillUnmount() {
		document.getElementById("app").classList.remove("steps");
	}

	moveForward(steps) {
		const { onUpdate } = this.props;
		const { currentStepIndex } = this.state;
		const newStepIndex = currentStepIndex + steps;
		this.setState({ currentStepIndex: newStepIndex }, () => {
			window.history.pushState({}, "", `#step-${newStepIndex}`);
			onUpdate && onUpdate(newStepIndex);
		});
	}

	moveBackward(steps) {
		const { onUpdate } = this.props;
		const { currentStepIndex } = this.state;
		const newStepIndex = currentStepIndex - steps;
		this.setState({ currentStepIndex: newStepIndex }, () => {
			window.history.pushState({}, "", `#step-${newStepIndex}`);
			onUpdate && onUpdate(newStepIndex);
		});
	}

	moveTo(step) {
		const { onUpdate } = this.props;
		this.setState({ currentStepIndex: step }, () => {
			window.history.pushState({}, "", `#step-${step}`);
			onUpdate && onUpdate(step);
		});
	}

	render() {
		const { children } = this.props;
		const { steps, currentStepIndex, completedSteps, enabledSteps, labels } = this.state;
		const currentStep = steps[currentStepIndex];
		const nextStep = steps[currentStepIndex + 1];
		const prevStep = steps[currentStepIndex - 1];
		return (
			<Workspace resource="wizard" id="wizard">
				<nav>
					<ul>
						{_.map(steps, (step, index) => (
							<li
								key={`${index}-step`}
								onClick={() =>
									(_.includes(enabledSteps, steps[index]) || _.includes(completedSteps, steps[index])) &&
									this.moveTo(index)
								}
								className={classNames([
									"step",
									`step-${index}`,
									`step-${step}`,
									{ "step-active": index === currentStepIndex },
									{ "step-enabled": _.includes(enabledSteps, step) },
									{ "step-completed": _.includes(completedSteps, step) }
								])}
							>
								<div className="step-number">
									<FontAwesomeIcon icon={faCircle} size="2x" />
								</div>
								<div className="step-label">{translate(labels[index])}</div>
								<div className="step-index">{index + 1}</div>
							</li>
						))}
					</ul>
				</nav>
				<section>{_.find(children, child => child.ref === currentStep)}</section>
				{(prevStep || nextStep) && (
					<footer>
						<div className="back">
							{prevStep && (
								<button onClick={() => this.moveBackward(1)}>
									<FontAwesomeIcon icon={faAngleLeft} /> {translate("previous")}
								</button>
							)}
						</div>
						<div className="next">
							{nextStep && _.includes(enabledSteps, nextStep) && (
								<button onClick={() => this.moveForward(1)}>
									{translate("next")} <FontAwesomeIcon icon={faAngleRight} />
								</button>
							)}
						</div>
					</footer>
				)}
			</Workspace>
		);
	}
}

export { Steps, Step };
