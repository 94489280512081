import { getLeases, getTenant } from "actions";
import ActivityTab from "components/Activities/ActivityDynamic";
// import { Card as Documents } from "components/Documents";
import { Card as Comments } from "components/Comments";
import { CardDynamic as Documents } from "components/Documents";
import { Card as Lease } from "components/Leases";
import {
	Address,
	Breadcrumbs,
	DefinitionItem,
	DefinitionList,
	Header,
	PhoneNumber,
	View,
	Workspace
} from "components/Shared";
import { translate } from "lib";
import _ from "lodash";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {
	match: any;
}

const Show = ({ match, history }: Props) => {
	const dispatch = useDispatch();
	const { id } = match.params;
	const queryStringParameters = useCallback(() => ({ tenant_id: id }), [id]);

	useEffect(() => {
		dispatch(getTenant(id));
		dispatch(getLeases());
	}, [dispatch, id]);

	const tenant = useSelector((store: tcpinpoint.Store) => _.find(store.tenants, { id }));
	const lease = useSelector((store: tcpinpoint.Store) => _.find(store.leases, { tenant_id: id }));
	if (tenant) {
		return (
			<React.Fragment>
				<Breadcrumbs items={[{ title: "tenants" }, { title: tenant.name }]} />,
				<Header
					stamp="tenants"
					title={tenant.name}
					toolbar={[{ label: "edit", onClick: () => history.push(`/tenants/${tenant.id}/edit`) }]}
				/>
				<Workspace
					id="project"
					className="show"
					tabs={_.compact([
						lease && <Lease name="lease" key="lease-tab" tenant={tenant} lease={lease} />,
						// <Documents name="documents" key="documents-tab" resource={tenant} />,
						<Documents
							name="documents"
							key="documents-tab"
							resource={tenant}
							queryStringParameters={{ tenant_id: id }}
						/>,
						<Comments name="comments" key="comments-tab" resource={tenant} events={[]} />,
						<View name="dashboard" key="details">
							<DefinitionList>
								<DefinitionItem title="trading_name" value={tenant.trading_name} />
								<DefinitionItem title="phone_number" value={<PhoneNumber phone_number={tenant.phone_number} />} />
								<DefinitionItem title="contact_details" value={tenant.contact_details} />
								<DefinitionItem
									title="address"
									value={_.map(tenant.addresses, address => (
										<Address {...{ address }} />
									))}
								/>
								<DefinitionItem title="business_code" value={_.get(tenant, "business_code.name", false)} />
								<DefinitionItem title="purpose" value={tenant.purpose} />
								<DefinitionItem title="full_time_equivalent" value={tenant.full_time_equivalent} />
								<DefinitionItem title="sector" value={translate(tenant.sector)} />
								<DefinitionItem title="source" value={translate(tenant.source)} />
							</DefinitionList>
						</View>,
						<ActivityTab name="tenant" queryStringParameters={queryStringParameters()} />
					])}
				/>
			</React.Fragment>
		);
	}
	return null;
};

const MemoizedShow = memo(Show);
export default ({ match, history, location }: any) => (
	<MemoizedShow match={match} history={history} location={location}></MemoizedShow>
);
