import "whatwg-fetch";
import React from "react";
import { Feedback, Label, Required } from "components/Inputs";
import classNames from "classnames";
import _ from "lodash";
import { translate } from "lib";
import moment from "moment";
import Select from "react-select";
import Async from "react-select/async";
import { AsYouType } from "libphonenumber-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import axios from "v2/utils/axios";

export default class Input extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			remaining: props.limit,
			ignoreError: false,
		};
		this.input = React.createRef();
	}

	UNSAFE_componentWillUpdate(nextProps) {
		const { name } = this.props;
		const doesUpdate = _.get(nextProps.defaultState, name, "") === _.get(this.props.defaultState, name, "");
		return doesUpdate;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ value: _.get(nextProps, `defaultState.${nextProps.name}`) });
	}

	loadOptions(inputValue, callback) {
		const { endpoint, formatter = (v) => _.get(v, "name") } = this.props;
		setTimeout(() => {
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}${endpoint}/search`, { params: { q: inputValue,} })
				.then((res) => 
					callback(
						_.map(_.uniq(res.data.json), (item) => ({
							value: item.id,
							label: formatter(item),
							item,
						}))
				));
		}, 1000);
	}

	onKeyUp(event) {
		this.onChange();
	}

	onChange() {
		const { limit, onChange, type, name } = this.props;
		const { current } = this.input;
		let { value } = current;
		if (limit) {
			const valueLength = value ? value.length : 0;
			const remaining = parseInt(limit, 10) - valueLength;
			this.setState({ remaining });
		}
		if (type === "money" || !!_.includes(name, "_cents")) {
			value = parseInt(value.replace(/[^\d]g/, ""), 10) * 100;
			if (isNaN(value)) {
				value = "";
			}
		}

		if (type === "checkbox") {
			value = !!this.input.current.checked;
		}

		if (type === "date" || name === "date" || !!_.includes(name, "_at") || !!_.includes(name, "_date")) {
			value = moment(value).format("YYYY-MM-DD");
		}
		if (type === "number") {
			value = parseFloat(value);
		}
		if (name === "telephone" || name === "phone_number" || type === "tel") {
			value = value.replace(/[^\d]/g, "");
		}
		onChange && onChange(value);
	}

	autoComplete() {
		const { name, type } = this.props;

		if (name === "firstname") {
			return "given-name";
		} else if (name === "surname") {
			return "family-name";
		} else if (type === "email" || name === "email") {
			return "email";
		} else if (type === "password" || name === "password") {
			return "new-password";
		} else if (name === "telephone" || name === "phone_number" || type === "tel") {
			return "tel";
		} else {
			return "off";
		}
	}

	render() {
		const {
			required,
			name,
			children,
			placeholder,
			step,
			accept,
			tags = [],
			limit,
			endpoint,
			defaultState,
			large,
			ref,
			className,
			options,
			showIf = true,
			disabled = false,
			formatter = (v) => v,
		} = this.props;
		const { remaining, ignoreError } = this.state;
		let { min, max, pattern, prefix, suffix, type } = this.props;
		let element = false;
		let value = _.get(defaultState, name, "");
		if (!showIf) {
			return false;
		}

		if (type === "date" || name === "date" || !!_.includes(name, "_at")) {
			if (!!value) {
				value = moment(value).format("YYYY-MM-DD");
			}
			type = "date";
			pattern = "[0-9]{4}-[0-9]{2}-[0-9]{2}";
		} else if (name === "email") {
			type = "email";
		} else if (name === "color") {
			type = "color";
		} else if (name === "phone_number" || name === "telephone" || type === "tel") {
			value = new AsYouType().input(value);
		} else if (type === "money" || !!_.includes(name, "_cents")) {
			prefix = "$";
			pattern = "[0-9]";
			suffix = ".00";
			min = 0;
			type = "number";
			value = parseInt(value, 10) / 100;
		} else if (!!_.includes(name, "_area")) {
			suffix = "m²";
			min = 0;
			pattern = "[0-9]";
			type = "number";
		} else if (type === "checkbox" && this.input.current) {
			this.input.current.checked = !!value;
		} else if (name === "password") {
			type = "password";
		} else {
			value = formatter(value);
		}

		if (!!disabled) {
			element = (
				<p className="form-control form-control-static">
					{!!prefix ? prefix : ""}
					{value}
					{!!suffix ? suffix : ""}
				</p>
			);
		} else if (!!endpoint) {
			let defaultValue = {};
			let defaultOptions = [];

			if (!!value) {
				defaultValue = { value: _.get(value, "id"), label: _.get(value, "name") };
				defaultOptions = [{ value: _.get(value, "id"), label: _.get(value, "name") }];
			}

			element = (
				<Async
					value={defaultValue}
					defaultOptions={defaultOptions}
					name={name}
					placeholder={"Type to search ..."}
					cacheOptions
					ref={ref}
					className="react-select"
					onChange={(option) =>
						this.setState({ value: option }, () => this.props.onChange && this.props.onChange(option.item))
					}
					loadOptions={(input, callback) => this.loadOptions(input, callback)}
				/>
			);
		} else if (!!_.get(options, "length", false)) {
			const selectOptions = _.map(_.compact(options), (option) => {
				return typeof option === "string" ? { label: translate(option), value: option } : option;
			});
			element = (
				<Select
					name={name}
					value={!!value && _.find(selectOptions, { value })}
					placeholder={placeholder}
					autoFocus={false}
					className="react-select"
					onChange={(option) => {
						this.setState({ value: option.value });
						this.props.onChange && this.props.onChange(option.value);
					}}
					options={selectOptions}
				/>
			);
		} else {
			element = (
				<input
					{...{
						name,
						type,
						accept,
						placeholder,
						max,
						min,
						step,
						pattern,
						value,
						ref: this.input,
						id: `form-control-input-${name}`,
						className: "form-control",
						autoComplete: this.autoComplete(),
						maxLength: limit,
					}}
					onChange={() => this.onChange()}
					onKeyUp={() => this.onKeyUp()}
					onClick={() => this.onChange()}
					onFocus={() => this.onChange()}
					onBlur={() => this.onChange()}
					onPaste={() => this.onChange()}
					onInput={() => this.onChange()}
				/>
			);
		}
		const input_group_style = {};
		if (type === "color") {
			input_group_style.backgroundColor = value;
		}
		return (
			<div
				id={`form-group-${name.replace("_", "-")}`}
				className={classNames("form-group", `form-group-${type}`, `form-group-${name.replace("_", "-")}`, className, {
					large,
					"has-prefix": !!prefix,
					"has-suffix": !!suffix,
					"form-group-checkbox-checked": type === "checkbox" && !!value,
					"form-group-checkbox-unchecked": type === "checkbox" && !value,
					"has-warning": limit && remaining <= 0,
					"is-required": _.get(required, name, false),
					"is-fulfilled": !!_.get(defaultState, name, false),
					"has-danger": !ignoreError && !!_.get(defaultState, `errors.${name}`),
				})}
			>
				<Label {...this.props}>
					<div
						className={classNames("input-group")}
						style={input_group_style}
						id={`form-control-input-group-${name.replace("_", "-")}`}
					>
						{prefix && <div className="input-group-addon">{prefix}</div>}
						{type === "checkbox" && !!value && (
							<div className="form-check-input">
								<FontAwesomeIcon icon={faCheckCircle} />
							</div>
						)}
						{type === "checkbox" && !value && (
							<div className="form-check-input">
								<FontAwesomeIcon icon={faCircle} />
							</div>
						)}
						{element}
						{suffix && <div className="input-group-addon">{suffix}</div>}
						<Required {...this.props} />
					</div>
				</Label>
				<Feedback {...this.state} {...this.props} />
				{!!tags.length && (
					<div className="input-tags">
						{_.map(tags, (tag) => (
							<span
								className="tag"
								onClick={() => {
									this.input.value += `%{${tag.toLowerCase()}} `;
									this.onChange();
								}}
							>
								<i className="fas fa-plus" /> {tag.toLowerCase()}
							</span>
						))}
					</div>
				)}
				{limit && (
					<div className="input-limit">
						{remaining > 0 ? remaining : 0}/{limit}
					</div>
				)}
				{children && <div className="form-control-child">{children}</div>}
			</div>
		);
	}
}
