import React, { memo } from "react";
import _ from "lodash";

const Address = ({ address }: any) => {
	if (!!address) {
		const { id, name, street_1, street_2, locality, region, locality_name, region_iso_alpha } = address;
		const theLocalityName = _.defaultTo(_.get(locality, "name"), locality_name);
		const theRegionIsoAlpha = _.defaultTo(_.get(region, "iso_alpha"), region_iso_alpha);
		if (theLocalityName) {
			return (
				<address
					id={id}
					data-street-1={street_1}
					data-street-2={street_2}
					data-locality-name={theLocalityName}
					data-region-iso_alpha={theRegionIsoAlpha}
				>
					{name}
				</address>
			);
		}
	}
	return null;
};

export default memo(Address);
