import React, { memo, useRef, useState } from "react";
import classNames from "classnames";
import { usePrevious } from "ahooks";
import { isNil } from "lodash";
import styled from "styled-components";

interface Target extends CheckboxProps {
	checked: boolean;
}

interface CheckboxChangeEvent {
	target: Target;
	stopPropagation: () => void;
	preventDefault: () => void;
	nativeEvent: MouseEvent;
}

interface CheckboxProps {
	prefixCls?: string;
	className?: string;
	checked?: boolean;
	style?: React.CSSProperties;
	type?: string;
	defaultChecked?: boolean;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (e: CheckboxChangeEvent) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	name?: string;
	id?: string;
	disabled?: boolean;
	readOnly?: boolean;
	tabIndex?: number;
	autoFocus?: boolean;
	value?: string | number | readonly string[];
	required?: boolean;
	indeterminate?: boolean;
	[key: string]: any;
}

const StyledInput = styled.input.attrs({
	className: classNames(
		"opacity-0", // hide the native input!
		"absolute left-0 top-0 bottom-0 right-0 z-50", // ceiling component
		"cursor-pointer",
		"prev"
	)
})`
	&:focus + .inner {
		border-color: red;
	}
`;

const CheckBox = (props: CheckboxProps) => {
	const inputRef = useRef();
	const {
		className,
		style = {},
		name,
		id,
		type = "checkbox",
		disabled,
		readOnly,
		tabIndex,
		onClick,
		onFocus,
		onBlur,
		autoFocus,
		value,
		required,
		onChange,
		defaultChecked,
		checked: controlledCheck,
		...others
	} = props;

	const [checked, setChecked] = useState(controlledCheck ?? !!defaultChecked); // Nullish operator

	//-------------- Hooks for getDerivedStateFromProps()-----------
	const prevChecked = usePrevious(checked);
	if (!isNil(controlledCheck) && controlledCheck !== prevChecked) {
		setChecked(controlledCheck);
	}
	//------- Please use ControlledCheck with OnChange together ----

	const globalProps = Object.keys(others).reduce((newProps: any, key: string) => {
		if (key.substr(0, 5) === "aria-" || key.substr(0, 5) === "data-" || key === "role") {
			newProps[key] = others[key];
		}
		return newProps;
	}, {});

	const handleChange = (e: CheckboxChangeEvent) => {
		if (disabled) return;
		if (!isNil(controlledCheck) && isNil(onChange)) {
			console.warn("Please use checked together with onChange method as props!");
			return;
		}
		setChecked(e.target.checked);
		if (onChange) {
			onChange({
				target: {
					...props,
					checked: e.target.checked
				},
				stopPropagation() {
					e.stopPropagation();
				},
				preventDefault() {
					e.preventDefault();
				},
				nativeEvent: e.nativeEvent
			});
		}
	};

	return (
		<span
			className={classNames(
				"relative",
				"inline-block  align-middle",
				"outline-none",
				"leading-font whitespace-no-wrap",
				className
			)}
			style={style}
		>
			<StyledInput
				id={id}
				type={type}
				name={name}
				required={required}
				readOnly={readOnly}
				disabled={disabled}
				tabIndex={tabIndex}
				checked={controlledCheck ?? !!checked}
				onClick={onClick}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={handleChange}
				autoFocus={autoFocus}
				value={value}
				ref={inputRef}
				{...globalProps}
			/>

			<span
				className={classNames("inner", "relative top-0 left-0 inline-block", "w-5 h-5", {
					"border-2 border-black-60 bg-white": !checked && !props.indeterminate,
					"border-primary bg-primary border-0": checked || props.indeterminate,
					"bg-black-40": disabled
				})}
				style={{ borderRadius: "3px" }}
			>
				{/* checked svg */}
				<svg
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={classNames("text-white absolute w-4 h-4 hover:cursor-default", {
						visible: checked,
						invisible: !checked
					})}
					style={{ top: "4px", left: "4px" }}
				>
					<path
						d="M10.6666 1.5L4.24992 7.91667L1.33325 5"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>

				{/* intermedaite svg, still need testing in CheckBox Group Component */}
				<svg
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={classNames("text-white absolute h-4 w-4 hover:cursor-default", {
						visible: props.indeterminate,
						invisible: !props.indeterminate
					})}
					style={{ top: "6.8px", left: "2px" }}
				>
					<path
						d="M1.91675 1H10.0834"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</span>
		</span>
	);
};

export default memo(CheckBox);
