import React from "react";
import { translate } from "lib";
import { ErrorBoundary } from "components/Shared";
import Actor from "components/Activities/Actor";
import Context from "components/Activities/Context";

const CreateInvite = ({ activity }: { activity: tcpinpoint.ActivityItem }) => (
	<ErrorBoundary>
		<Actor actor={activity.attributes.item.actor} /> {translate("invited")}{" "}
		{activity.attributes.item.content ? (
			<React.Fragment>
				<span className="activity-item-invited-user">
					{activity.attributes.item.content.name}
					{activity.attributes.item.content.email}
				</span>{" "}
			</React.Fragment>
		) : (
			<span className="activity-item-invited-user">{translate("someone")} </span>
		)}
		<Context context={activity.attributes.item.context} />
	</ErrorBoundary>
);

export default CreateInvite;
