import { AsYouType } from 'libphonenumber-js';

export const formatPhone = (phone: string, maxLength?: number) => {
  return new AsYouType('AU').input(
    phone.replace(/[^\d^\s+]/, '').substring(0, maxLength)
  );
};

export const formatCode = (code: string, maxLength?: number) => {
  code = code.replace(/\D/, '');
  if (maxLength && code.length >= maxLength)
    return code.substring(0, maxLength);
  return code;
};

export const format = (value: any, type?: string) => {
  switch (type) {
    case 'phone':
      return formatPhone(value, 16);
    case 'code':
      return formatCode(value, 6);
    default:
      return value;
  }
};
