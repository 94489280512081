import { ListGroup, ListGroupControl, ListGroupItem, LoadingState, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Icon } from ".";

interface Props {
	queryStringParameters?: {};
	children?: JSX.Element;
	name?: string;
	onRowClick?: (template: tcpinpoint.Template) => any;
}

const Templates = ({ onRowClick }: Props) => {
	const [templates, setTemplates] = useState([] as tcpinpoint.Template[]);
	const [filteredBy, setFilteredBy] = useState("all");
	const [, renderTrigger] = useState(moment());
	const getSortAttribute = (sortBy: string) => {
		switch (sortBy) {
			case "updated_at":
				return "-updated_at";
			case "created_at":
				return "-created_at";
			default:
				return sortBy;
		}
	};
	const getFilterAttribute = (filteredBy: string) => {
		switch (filteredBy) {
			case "workflow-template":
				return "workflow";
			case "facilities-template":
				return "facilities";
			case "commercial-template":
				return "commercial";
			case "retail-template":
				return "retail";
			default:
				return ""; // all
		}
	};
	const [datasource] = useState(
		new Datasource({
			mainModelName: "template",
			perPage: 20,
			currentPage: 1,
			sortBy: "name",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setTemplates(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		datasource.get("v2", "templates", {
			queryStringParameters: {
				kind: getFilterAttribute(filteredBy),
				sort: getSortAttribute(datasource.sortBy),
				per_page: datasource.perPage,
				page: datasource.currentPage,
				fields: JSON.stringify({ template: ["body", "kind", "name", "link", "tags"] })
			}
		});
	}, [datasource, datasource.currentPage, datasource.perPage, datasource.sortBy, filteredBy]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<View resource="templates" hideTitle className="list">
			<ListGroupControl
				onSortChange={newSortBy => {
					datasource.setCurrentPage(1);
					datasource.setSortBy(newSortBy);
				}}
				selectedSort={datasource.sortBy}
				sortOptions={["name", "updated_at", "created_at"]}
				filterOptions={["all", "workflow-template", "retail-template", "commercial-template", "facilities-template"]}
				onFilterChange={newFilterBy => {
					datasource.setCurrentPage(1);
					setFilteredBy(newFilterBy);
				}}
				selectedFilter={filteredBy}
			/>
			<ListGroup
				resource="templates"
				collection={templates}
				pagination={false}
				renderRow={(template: tcpinpoint.Template) => (
					<ListGroupItem
						icon={<Icon tags={template.tags} />}
						key={template.id}
						className={template.kind}
						link={_.isNil(onRowClick) ? `/templates/${template.id}` : () => onRowClick!(template)}
						heading={template.name}
						subheading={template.kind && `${template.kind} template`}
					/>
				)}
			/>
			<div className="flex justify-center m-auto">
				<Pagination datasource={datasource} />
			</div>
		</View>
	);
};

export default memo(Templates);
