import React, { useState, useRef, useEffect, ReactElement } from 'react';

const DropDownArrow = ({ color }: { color: string }): ReactElement => {
    return (
        <svg width="15" height="15" viewBox="0 0 13 12" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 12L0.0048084 0.750001L12.9952 0.75L6.5 12Z" fill={color}/>
        </svg>
        
    );
};

function useOutsideAlerter(ref: any, toggleDropDownState: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, toggleDropDownState]);
}

export const ButtonDropDown = ({
    buttonOption,
    dropdownOptions,
    textClassNames,
    dropDownClassNames,
    dropDownArrowColor,
    callBack,
}: {
    buttonOption: {
        id: string;
        name: string;
    };
    dropdownOptions: {
        id: string;
        name: string;
    }[];
    callBack: (arg0: any) => void;
    textClassNames?: string;
    dropDownClassNames?: string;
    dropDownArrowColor?: string;
}): ReactElement => {
    const handleClick = (e: any) => {
        toggleDropDownState(false);
        callBack(e.target.dataset.value);
    };

    const [isOpen, toggleDropDownState] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleDropDownState);

    return (
        <span ref={wrapperRef} className="relative z-1 inline-flex shadow-sm rounded-md text-center border border-borderColor">
            <button
                onClick={handleClick}
                data-value={buttonOption.id}
                type="button"
                className={` ${
                    textClassNames
                        ? textClassNames
                        : 'relative inline-flex items-center rounded-l-md border-border-1 box-border text-12 focus:outline-none select-none transition ease-in-out duration-500 text-blue-100'
                } select-none transition ease-in-out duration-500 border-r border-borderColor truncate`}
            >
                {buttonOption.name}
            </button>
            <span className="-ml-px relative block">
                <button
                    type="button"
                    onClick={() => toggleDropDownState(!isOpen)}
                    className={` ${
                        dropDownClassNames
                            ? dropDownClassNames
                            : 'rounded-r-md border text-blue-100'
                    } relative flex justify-center rounded-r-md select-none`}
                    aria-label="Expand"
                >
                    <p className={`transition ease-linear duration-200 py-4 px-4 ${isOpen ? 'transform rotate-180' : ''}`}>
                        <DropDownArrow color={`${dropDownArrowColor ? dropDownArrowColor : '#D8D8D8'}`} />
                    </p>
                </button>
                <div
                    className={`origin-top-right absolute right-0 mt-2 -mr-1 w-168 rounded-md shadow-lg ${
                        isOpen ? 'animate-grow-down-dropdown-animation opacity-1 ' : 'opacity-0 pointer-events-none'
                    }`}
                >
                    <div className="rounded-md bg-white shadow-xs overflow-y-auto" style={{
						minHeight: '90px',
						maxHeight: '155px',
					}}>
                        <div>
                            {dropdownOptions.map((itm) => (
                                <button
                                    data-value={itm.id}
                                    onClick={handleClick}
                                    key={itm.id}
                                    className="py-3 px-2 w-215 block font-normal text-left text-14 text-black 
                                hover:bg-blue-100 hover:text-white focus:outline-none focus:text-black select-none w-265"
                                >
                                    {itm.name}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </span>
        </span>
    );
};