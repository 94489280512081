import { makeEntitiesSelector } from './utils';
export { findCallback, filterCallback, makeEntitiesSelector, makeEntitySelectorWithIdInProps } from './utils';

export const projectsSelector = makeEntitiesSelector('projects');
export const propertiesSelector = makeEntitiesSelector('properties');
export const leasesSelector = makeEntitiesSelector('leases');
export const tenanciesSelector = makeEntitiesSelector('tenancies');
export const tenantsSelector = makeEntitiesSelector('tenants');
export const ledgersSelector = makeEntitiesSelector('ledgers');
export const tasksSelector = makeEntitiesSelector('tasks');
export const partiesSelector = makeEntitiesSelector('parties');
export const usersSelector =makeEntitiesSelector('users');

