import { lazy } from 'react';
import Card from "./Card";
import Collection from "./Collection";
import LeaseTableReport from "./LeaseTableReport";
import Create from "./Form";
import Details from "./Details";
import Edit from "./Edit";
import Form from "./Form";
import List from "./List";
import Select from "./Select";
import Show from "./Show";

const HandoverDateTimeline = lazy(() => import("./HandoverDateTimeline"))
const FitoutPeriodTimeline = lazy(() => import("./FitoutPeriodTimeline"))

export {
	List,
	Card,
	Form,
	Show,
	Edit,
	Details,
	Create,
	Collection,
	LeaseTableReport,
	Select,
	HandoverDateTimeline,
	FitoutPeriodTimeline
};
