import React, { useState } from "react";
import { Button, Row, Col } from "components/Shared";
import { Input } from "components/Inputs";
//@ts-ignore
import validate from "validate.js";
import _ from "lodash";
import Lookup from "components/Inputs/Lookup";
const constraints = { name: { presence: true }, contact_email: {email: true}};

const Form = ({ tenant, onSubmit, extended = false }: any) => {
	const [data, setData] = useState(tenant);
	return (
		<Row>
			<Col>
				<Input
					defaultState={data}
					required={constraints}
					label="Lessee"
					name="name"
					onChange={(name: string) => setData(_.assign({}, data, { name }))}
				/>
				<Input
					defaultState={data}
					required={constraints}
					name="trading_name"
					onChange={(trading_name: string) => setData(_.assign({}, data, { trading_name }))}
				/>

				<Input
					defaultState={data}
					required={constraints}
					name="contact_name"
					onChange={(contact_name: string) => setData(_.assign({}, data, { contact_name }))}
				/>
				
				<Input
					defaultState={data}
					required={constraints}
					name="contact_email"
					onChange={(contact_email: string) => setData(_.assign({}, data, { contact_email }))}
				/>

				<Input
					defaultState={data}
					required={constraints}
					name="phone_number"
					onChange={(phone_number: string) => setData(_.assign({}, data, { phone_number }))}
				/>

				{/* <Address
					defaultState={data}
					required={constraints}
					name="addresses"
					onChange={(addresses: string) => setData(_.assign({}, data, { addresses }))}
				/> */}

				<Input
					defaultState={data}
					required={constraints}
					showIf={extended}
					name="source"
					onChange={(source: string) => setData(_.assign({}, data, { source }))}
				/>
				<Input
					defaultState={data}
					required={constraints}
					showIf={extended}
					name="sector"
					onChange={(sector: string) => setData(_.assign({}, data, { sector }))}
				/>
				<Input
					defaultState={data}
					required={constraints}
					showIf={extended}
					name="purpose"
					onChange={(purpose: string) => setData(_.assign({}, data, { purpose }))}
				/>
				<Lookup
					collection="business_codes"
					defaultState={data}
					required={constraints}
					showIf={extended}
					name="business_code"
					onChange={(option: any) =>
						setData(_.assign({}, data, { business_code: option, business_code_id: option.value }))
					}
				/>
				<Input
					defaultState={data}
					required={constraints}
					showIf={extended}
					name="source"
					onChange={(source: string) => setData(_.assign({}, data, { source }))}
				/>
				<Button
					onClick={() => {
						const errors = validate(data, constraints);
						if (!!errors) {
							setData(_.assign({}, data, { errors }));
						} else {
							onSubmit(data);
						}
					}}
				/>
			</Col>
		</Row>
	);
};
export default Form;
