import { Breadcrumbs, LoadingState, View, Workspace } from "components/Shared";
import React from "react";
import { RouteComponentProps } from "react-router";
import Form from "./Form";
import { useGetTenancy } from "v2/hooks";

import { useParams } from "react-router-dom";

interface Props extends RouteComponentProps {
	match: any;
}

const EditTenancy = ({ history }: Props) => {
	const { id } = useParams() as any;

	const { tenancy, datasource: tenancyDatasource } = useGetTenancy(id, {
		fields: JSON.stringify({
			tenancy: [
				"area",
				"building_name",
				"floor_name",
				"name",
				"building_id",
				"floor_id",
				"occupancy_status",
				"property_id",
			],
		}),
	});

	const putTenancy = (tenancy: any) => {
		console.log("putTenancy: ", { tenancy });

		tenancyDatasource
			.put("v2", `tenancies/${tenancy.id}`, { body: { tenancy } })
			.then(({ normalizedMainModelResponse }: any) => {
				// setLease(normalizedMainModelResponse);
				history.goBack();
			});
	};

	if (tenancyDatasource?.isLoading) return <LoadingState />;

	if (!!tenancy) {
		return (
			<React.Fragment>
				{" "}
				<Breadcrumbs items={[{ title: "Tenancy" }, { title: "", link: `/tenancies` }, { title: "edit" }]} />
				<Workspace id="lease-edit">
					<View>
						<Form tenancy={tenancy} onUpdate={putTenancy} oldBuildingId={tenancy.building_id} />
					</View>
				</Workspace>
			</React.Fragment>
		);
	}
	return null;
};

export default EditTenancy;
