// @ts-ignore
import { NotificationManager } from "react-notifications";

export default class LocalNotification {
	private message: string;
	private title?: string;
	private type?: string;
	constructor(message: string, title = "tcbot", type: string = "info") {
		this.message = message;
		this.title = title;
		this.type = type;
		this.deliver();
	}

	deliver() {
		switch (this.type) {
			case "success":
				NotificationManager.success(this.message, this.title, 3000);
				break;
			case "warning":
				NotificationManager.warning(this.message, this.title, 3000);
				break;
			case "error":
				NotificationManager.error(this.message, this.title, 3000);
				break;
			default:
				NotificationManager.info(this.message, this.title, 3000);
				break;
		}
	}
}
