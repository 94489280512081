import React, { memo } from "react";
import Button from "../shared/Button";
import classNames from "classnames";
import ceo from "v2/assets/ceo.jpg";

const Welcome = ({
	onDirect,
	className,
	label,
	verifying
}: {
	onDirect?: () => any;
	className?: string;
	label?: string;
	verifying?: boolean;
}) => {
	return (
		<div className={classNames("px-10 lg:mx-auto lg:w-1/2", className)}>
			<div className="mb-5 text-23 font-semibold text-black-100 lg:text-30 lg:leading-11 ">Welcome to TCPinpoint!</div>

			<div className="word text-16 leading-6 text-black-100 font-normal">
				We imagine a world where property people collaborate, support and empathise with each other to keep themselves
				focused and proactive in their work and lives.
			</div>

			<Button
				label={label}
				className={classNames("my-17 lg:mb-16 w-45 h-11 text-14 font-normal leading-5", {
					"hover:shadow-hover bg-blue-100 text-white shadow-button": !verifying,
					"w-full": verifying
				})}
				onClick={onDirect}
				loading={verifying}
			/>

			<div>
				<img src={ceo} alt="no_img" className="float-left mr-2 hidden lg:block" />
				<div>
					<div className="text-18 leading-7 text-blue-100 tracking-2">“Welcome to the community!</div>
					<div className="text-18 text-blue-100 tracking-2 overflow-x-visible lg:whitespace-no-wrap break-words">
						<span>&nbsp;</span>We are so pleased to have you join us.”
					</div>
					<div className="text-16 leading-5 text-black-60 tracking-1 lg:mt-1 mt-2">
						<span>&nbsp;</span>Rachel Kidwell - Founder & CEO
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Welcome);
