import React, { memo } from "react";
import classNames from "classnames";
import Spinner from "./Spinner";

const Button = ({
	onClick,
	label,
	className,
	disabled,
	loading,
	children
}: {
	label?: string;
	onClick?: (props: any) => any;
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	children?: React.ReactNode;
}) => {
	return (
		<button
			onClick={onClick}
			className={classNames(
				"text-14 font-semibold leading-5",
				"w-27 h-10 rounded-2 focus:outline-none",
				"flex items-center justify-center",
				className
			)}
			disabled={disabled}
		>
			{!loading && label}
			{!!children && children}
			{loading && <Spinner className="mx-auto" />}
		</button>
	);
};

export default memo(Button);
