import { getInspections, getTenancy } from "actions";
import ActivityTab from "components/Activities/ActivityDynamic";
import { Collection as Facilities } from "components/Facilities";
// import { Collection as Inspections } from "components/Inspections";
import { Collection as Leases } from "components/Leases";
import { Card as Permissions } from "components/Permissions";
import { Aside, Breadcrumbs, DefinitionItem, DefinitionList, Header, View, Workspace } from "components/Shared";
import { translate } from "lib";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {
	match: any;
}

const Tenancy = ({ match, history }: Props) => {
	const dispatch = useDispatch();
	const { id } = match.params;
	useEffect(() => {
		dispatch(getInspections());
		dispatch(getTenancy(id));
	}, [dispatch, id]);
	// const inspections = useSelector((store: tcpinpoint.Store) =>
	// 	_.filter(store.inspections, { inspectable_id: id })
	// ) as tcpinpoint.Inspection[];
	const queryStringParameters = useCallback(() => ({ tenancy_id: id }), [id]);
	const tenancy = useSelector((store: tcpinpoint.Store) => _.find(store.tenancies, { id })) as tcpinpoint.Tenancy;
	const property = useSelector((store: tcpinpoint.Store) =>
		_.find(store.properties, { id: _.get(tenancy, "property_id") })
	) as tcpinpoint.Property;

	// Need to send request to api server to get tenancy and proeperty from the url then render the list of project to link to on this page
	if (!!tenancy && !!property) {
		return (
			<React.Fragment>
				<Breadcrumbs
					items={[
						{ link: "/properties", title: translate("properties") },
						{ link: `/properties/${property.id}`, title: property.name },
						{ link: `/properties/${property.id}#buildings`, title: translate("tenancies") },
						{ title: tenancy.name }
					]}
				/>
				<Header
					resource={tenancy}
					stamp="tenancy"
					title={tenancy.name}
					subtitle={property.name}
					toolbar={[{ label: "edit", onClick: () => history.push(`/tenancies/${tenancy.id}/edit`) }]}
				/>
				<Workspace
					id={tenancy.id}
					className="tenancies"
					tabs={[
						<Facilities name="facilities" key="facilities" canAdd={false} resource={tenancy} />,
						// <Inspections name="inspections" key="inspections" inspections={inspections} resource={tenancy} />,
						<Leases name="leases" key="leases" tenancy={tenancy} />,
						<View name="dashboard">
							<DefinitionList>
								<DefinitionItem title="kind" value={translate(tenancy.kind)} />
								<DefinitionItem title="size" value={tenancy.area} suffix="m²" />
								<DefinitionItem title="precinct" value={tenancy.precinct} />
								<DefinitionItem title="division" value={tenancy.division} />
							</DefinitionList>
						</View>
					]}
				/>
				<Aside>
					<ActivityTab queryStringParameters={queryStringParameters()} />
					<Permissions resource={tenancy} />
				</Aside>
			</React.Fragment>
		);
	}
	return null;
};

export default Tenancy;
