import _ from "lodash";
import moment, { MomentInput } from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";
import ReschedulePreview from "./ReschedulePreview";
import DatePicker from "v2/components/shared/DatePicker";
import InputNumber from "v2/components/shared/InputNumber";
import icons from "v2/utils/icon";
import axios from "v2/utils/axios";

interface Props {
	task: tcpinpoint.Task;
	onChange(task: tcpinpoint.Task): void;
}

const RescheduleInput = ({ task, onChange = (task: tcpinpoint.Task) => task }: Props) => {
	const parentDueAt = moment(_.get(task, "latest_effective_parent_due_at"));
	const startDueAt = moment(_.get(task, "latest_effective_parent_due_at")).add(1, "days");
	const childrenLength = _.get(task, "child_ids", []).length;
	const isRootTask = useMemo(() => _.get(task, "parent_ids", []).length === 0, [task]);
	const taskId = _.get(task, "id");
	const dueAtString = moment(_.get(task, "due_at")).format("YYYY-MM-DD");

	// states
	const [rescheduledChildren, setRescheduledChildren] = useState([] as any[]);
	const [rescheduledDueAtString, setRescheduledDueAtString] = useState(
		moment(_.get(task, "rescheduled_due_at", dueAtString)).format("YYYY-MM-DD")
	);
	const [length, setLength] = useState(task.length || 1);

	// for root task that due at is in the past, disable the duration adjust because
	// we dont know the start due at for it, so the customer only allow to select new due at in the future
	const disableLengthEdit = useMemo(
		() => isRootTask && moment(_.get(task, "due_at")).toDate() < moment().toDate(),
		[isRootTask, task]
	);

	const getDaysBetween = (startDate: MomentInput, endDate: MomentInput) =>
		moment(endDate).diff(moment(startDate).add(-1, "day"), "days");

	const callParentOnChange = (newRescheduledDueAtString: string) => {
		if (newRescheduledDueAtString !== dueAtString)
			onChange({ ...task, rescheduled_due_at: newRescheduledDueAtString } as tcpinpoint.Task);
	};

	// change task's due_at (rescheduled_due_at)
	const onChangeDueAt = (newDueAt: MomentInput) => {
		if (_.isNil(newDueAt)) newDueAt = moment(dueAtString);
		const newRescheduledDueAtString = moment(newDueAt).format("YYYY-MM-DD");
		setLength(getDaysBetween(startDueAt, newDueAt));
		setRescheduledDueAtString(newRescheduledDueAtString);
		callParentOnChange(newRescheduledDueAtString);
	};

	// change task's duration
	const onChangeDuration = (newDuration: number) => {
		newDuration = _.max([newDuration, 1]) as number;
		const newRescheduledDueAtString = moment(parentDueAt).add(newDuration, "days").format("YYYY-MM-DD");
		setLength(newDuration);
		setRescheduledDueAtString(newRescheduledDueAtString);
		callParentOnChange(newRescheduledDueAtString);
	};


	// API call to get reschedule preview
	useEffect(() => {
		if (childrenLength > 0 && rescheduledDueAtString !== dueAtString)
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}tasks/${taskId}/reschedule-preview`, 
					{ params: { rescheduled_due_at: rescheduledDueAtString } })
				.then((res) => setRescheduledChildren(res.data))
				.catch((e) => setRescheduledChildren([] as any[]));
		else {
			setRescheduledChildren([] as any[]);
		}
	}, [rescheduledDueAtString, dueAtString, childrenLength, taskId]);

	return (
		<>
			<div className="flex items-center space-x-5 mb-5">
				<DatePicker
					dateFormat="dd/MM/yyyy"
					className="w-auto border-1 border-pink rounded-1 flex justify-between items-center mb-0 hover:border-primary"
					selected={moment(rescheduledDueAtString).toDate()}
					onChange={(date: any) => onChangeDueAt(date)}
					minDate={moment(rescheduledDueAtString).toDate()}
				>
					{icons("date", "text-black-80 mx-3")}
				</DatePicker>
				<i className="fas fa-equals"></i>
				<InputNumber
					className="w-15"
					innerCls="w-full"
					value={length}
					min={1}
					disabled={disableLengthEdit}
					onChange={(l: any) => {
						if (l === undefined) return;
						onChangeDuration(parseInt(String(l).replace(/\D/g, "")));
					}}
				/>
				<span> calendar days</span>
			</div>
			<ReschedulePreview rescheduledTaskId={taskId} rescheduledChildren={rescheduledChildren}></ReschedulePreview>
		</>
	);
};

export default memo(RescheduleInput);
