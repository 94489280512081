import React, { useState, useEffect } from "react";
import { DefinitionList, DefinitionItem, View, Row, Col, Address } from "components/Shared";
import { translate } from "lib";
import { Map } from "components/Widgets";
import _ from "lodash";
import { Link } from "react-router-dom";
import Datasource, { DatasourceResponse } from "lib/datasource";

interface Props {
	editable?: boolean;
	name?: string;
	tenancy?: tcpinpoint.Tenancy;
	onUpdate?: any;
	onClear?: any;
	property?: tcpinpoint.Property;
	className?: string;
	link?: string;
	icon?: string;
}
const Tenancy = ({ name, tenancy, onClear, property, className, link }: Props) => {
	const propertyId = _.get(property, "id");
	const tenancyId = _.get(tenancy, "id");
	const [addresses, setAddresses] = useState([] as any[]);
	const [buildings, setBuildings] = useState([] as any[]);
	const [tenancyDatasource] = useState(new Datasource({ mainModelName: "tenancy" }));
	const [buildingDatasource] = useState(new Datasource({ mainModelName: "building" }));
	const [addressDatasource] = useState(new Datasource({ mainModelName: "address" }));

	const formatBuildings = (buildings: any[]) =>
		_.map(buildings, building => ({
			name: building.name,
			coordinates: building.shape,
			area: building.area
		}));

	/******************************************  Response Subscribe  ******************************************/
	useEffect(() => {
		if (_.isNil(tenancyId)) return;
		let sub = tenancyDatasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			_.merge(tenancy, response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [tenancyDatasource, tenancyId, tenancy]);

	useEffect(() => {
		let sub = buildingDatasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setBuildings(formatBuildings(response.normalizedMainModelResponse))
		);
		return () => sub.unsubscribe();
	}, [buildingDatasource]);

	useEffect(() => {
		let sub = addressDatasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setAddresses(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [addressDatasource]);

	/******************************************  API Call  ******************************************/
	useEffect(() => {
		if (!_.isEmpty(tenancyId)) tenancyDatasource.get("v2", `tenancies/${tenancyId}`, {});
	}, [tenancyDatasource, tenancyId]);

	useEffect(() => {
		if (!_.isEmpty(propertyId)) buildingDatasource.get("v2", "buildings", { queryStringParameters: { property_id: propertyId, per_page: 1000 } });
	}, [buildingDatasource, propertyId]);

	useEffect(() => {
		if (!_.isEmpty(propertyId)) addressDatasource.get("v2", "addresses", { queryStringParameters: { property_id: propertyId, per_page: 1000 } });
	}, [ addressDatasource, propertyId ]);

	if (!!tenancy) {
		return (
			<View className={className}>
				{!!onClear && (
					<button type="button" className="close" aria-label="Close" onClick={onClear}>
						<span aria-hidden="true">×</span>
					</button>
				)}
				<Row>
					<Col>
						<h3>{translate(_.defaultTo(name, tenancy.name))}</h3>
						<h4>{_.get(tenancy, "building_name")}</h4>
						<DefinitionList>
							<DefinitionItem title="area" value={tenancy.area} suffix="m²" />
							<DefinitionItem title="kind" value={translate(tenancy.kind)} />
							<DefinitionItem title="division" value={tenancy.division} />
							<DefinitionItem title="precinct" value={tenancy.precinct} />
						</DefinitionList>
						{/* TODO - give user the ability to change which tenancy their project belongs to IN THE LEASE CARD*/}
						{/* {onUpdate && editable && <Button onClick={onUpdate}>{translate("edit")}</Button>} */}
					</Col>
					<Col>
						<h4>
							{!!link ? (
								<Link to={`/properties/${_.get(property, "id")}`}>{_.get(property, "name")}</Link>
							) : (
								_.get(property, "name")
							)}
						</h4>
						<DefinitionList>
							{!!_.get(property, "owned_by") && (
								<DefinitionItem title="owned_by">
									{/* <Link to={`/companies/${_.get(property, "owned_by.id")}`}>{_.get(property, "owned_by")!.name}</Link> */}
									{_.get(property, "owned_by")!.name}
								</DefinitionItem>
							)}
							{!!_.get(property, "managed_by") && (
								<DefinitionItem title="managed_by">
									{/* <Link to={`/companies/${_.get(property, "managed_by")!.id}`}>{_.get(property, "managed_by")!.name}</Link> */}
									{_.get(property, "managed_by")!.name}
								</DefinitionItem>
							)}
							{!!_.get(property, "operated_by") && (
								<DefinitionItem title="developed_by">
									{/* <Link to={`/companies/${_.get(property, "operated_by")!.id}`}>{_.get(property, "operated_by")!.name}</Link> */}
									{_.get(property, "operated_by")!.name}
								</DefinitionItem>
							)}
						</DefinitionList>
						<Map addresses={addresses} buildings={buildings} height="394px" />
						<hr />
						<DefinitionList>
							{_.map(addresses, a => (
								<DefinitionItem key={_.get(a, "locality_name")} title={_.get(a, "locality_name")}>
									<Address key={a.id} address={a} />
								</DefinitionItem>
							))}
						</DefinitionList>
					</Col>
				</Row>
			</View>
		);
	}
	return null;
};

export default Tenancy;
