import React from "react";
import { translate } from "lib";

const Footer = () => (
	<footer>
		<ul>
			<li key="copyright">© TCPinpoint {new Date().getFullYear()}</li>
			<li>2023.05.31</li>
			<li key="support">
				<a target="_new" href="mailto:help@tcpinpoint.com">
					{translate("help")}
				</a>
			</li>
			<li key="about">
				<a target="_new" href="http://tcpinpoint.com/about">
					{translate("about")}
				</a>
			</li>
			<li key="terms">
				<a target="_new" href="http://tcpinpoint.com/terms">
					{translate("terms")}
				</a>
			</li>
			<li key="privacy">
				<a target="_new" href="http://tcpinpoint.com/privacy">
					{translate("privacy")}
				</a>
			</li>
		</ul>
	</footer>
);

export default Footer;