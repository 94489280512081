import { I18n } from "aws-amplify";
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import uuidv4 from "uuid/v4";
import LocalNotification from "./Notification";
import { saveAs } from "file-saver";
import { CSVLink } from "react-csv";
import { Button } from "components/Shared";
import { smartStrictEqual } from "./smartStrictEqual";
export const black = "#231f20";
export const white = "#ffffff";
export const borderGrey = "hsla(0, 0%, 92%, 1)";
export const grey = "hsla(0, 0%, 50%, 1)";
export const orange = "hsla(31, 100%, 50%, 1)";
export const yellow = "#ffde66";
export const green = "#00af3d";
export const purple = "#b62097";
export const brown = "#684f27";
export const red = "#fc4036";
export const blue = "#00c6d7";
export const dark_blue = "#02191c";
const icons = {
	add: "plus",
	archive: "archive",
	blog_article: "thumbtack",
	buildings: "building",
	chat: "comment-alt-smile",
	close_task: "check-circle",
	comments: "comment-alt",
	conditions: "check-square",
	dashboard: "hashtag",
	deadlines: "calendar",
	defect: "clipboard-check",
	details: "info-circle",
	dismiss: "ban",
	documents: "copy",
	download: "file-download",
	duplicate: "copy",
	edit: "pencil",
	editor: "paragraph",
	mark_all_done: "check-circle",
	export: "file-export",
	close: "save",
	facilities: "lightbulb",
	sustainability: "hand-holding-seedling",
	facility: "clipboard-list-check",
	help_article: "book-heart",
	home: "home",
	inspections: "clipboard",
	issues: "exclamation-triangle",
	lease: "file-contract",
	leases: "file-contract",
	ledgers: "file-invoice-dollar",
	messages: "comment-alt-smile",
	new: "plus",
	no_comments: "comment-alt-times",
	no_permission: "eye-slash",
	notifications: "bell",
	parties: "users",
	pdf: "file-pdf",
	permission: "eye",
	portfolios: "archive",
	preview: "eye",
	users: "users",
	print: "print",
	projects: "folder",
	properties: "map-marker-alt",
	protected: "unlock-alt",
	companies: "socks",
	quote: "comment-dollar",
	quotes: "comment-dollar",
	read: "book-open",
	get: "book-open",
	reports: "layer-group",
	schedule: "calendar",
	settings: "cog",
	share: "retweet",
	signout: "power-off",
	tag: "tag",
	tasks: "stream",
	team_members: "users",
	templates: "file-alt",
	tenancies: "draw-square",
	tenancy: "draw-square",
	tenant: "users",
	tenants: "users",
	trash: "trash-alt",
	undo: "undo-alt",
	user: "user",
	watch: "film",
	watching: "eye",
	workflow: "sitemap",
	workflows: "sitemap",
	xlsx: "file-excel",
	confirm: "",
	default: "atom",
	channel_web: "browser",
	channel_email: "envelope",
	channel_sms: "sms",
	channel_voice: "phone-square",
	channel_telegram: "telegram",
	channel_facebook: "facebook-messenger",
	channel_apns: "apple",
	channel_gcm: "google",
	on: "circle",
	off: "circle",
	"create-comment": "comment-plus",
	"create-comment-mention": "quote-right",
	"create-comment-reply": "reply",
	"accept-task-responsible": "thumbs-up",
	"accept-task-watching": "thumbs-up",
	"move-task-responsible": "calendar",
	"move-task-watching": "calendar-alt",
	"update-task-responsible": "sync",
	"update-task-watching": "sync",
	"create-project": "plus-circle",
	"close-project": "archive",
	"create-party": "user-plus",
	"create-party-subject": "id-badge",
	"create-document": "file-upload",
	"create-document-responsible": "file-upload",
	"create-document-watching": "file-upload",
	"accept-condition-responsible": "check-circle",
	"reject-condition-responsible": "ban",
	"tenativereject-condition-responsible": "circle",
	"accept-condition-watching": "check-circle",
	"reject-condition-watching": "ban",
	"create-comment-responsible": "comment",
	"create-comment-author": "comment",
	"create-comment-spectator": "comment",
	"tenativereject-condition-watching": "circle",
};

const icon = (i, kind = "far", classname = []) => {
	if (_.includes(["telegram", "facebook", "apple", "google", "facebook-messenger"], icons[i])) {
		kind = "fab";
	}
	return !!icons[i] && React.createElement("i", { className: classNames(kind, `fa-${icons[i]}`, classname) }, null);
};

const decomposeGoogleAddress = ({ vicinity, geometry, address_components }) => {
	const street_number = _.get(
		_.find(address_components, (c) => _.includes(c.types, "street_number")),
		"long_name"
	);
	const route = _.get(
		_.find(address_components, (c) => _.includes(c.types, "route")),
		"long_name"
	);
	const region = _.get(
		_.find(address_components, (c) => _.includes(c.types, "administrative_area_level_1")),
		"long_name"
	);
	const locality = _.get(
		_.find(address_components, (c) => _.includes(c.types, "locality")),
		"long_name"
	);
	const address = {
		id: uuidv4(),
		name: _.compact([`${street_number} ${route}`, !!vicinity && vicinity, !!locality && locality]).join(", "),
		street_1: `${street_number} ${route}`,
		locality,
		region,
		geo_point: [geometry.location.lng(), geometry.location.lat()],
	};
	return address;
};

const decomposeURI = (component) => {
	let query = window.location[component];
	let params = {};
	if (!!query) {
		params = (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
			let [key, value] = param.split("=");
			params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
			return params;
		}, {});
	}
	return params;
};

export const slugify = (str) => {
	str = str.trim();
	str = str.toLowerCase();

	// remove accents, swap ñ for n, etc
	const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
	const to = "aaaaaaeeeeiiiioooouuuunc------";

	for (let i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
	}

	return str
		.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
		.replace(/\s+/g, "-") // collapse whitespace and replace by -
		.replace(/-+/g, "-") // collapse dashes
		.replace(/^-+/, "") // trim - from start of text
		.replace(/-+$/, "") // trim - from end of text
		.replace(/-/g, "-");
};

export const useDefaults = () => {
	const hashValues = window.location.hash.slice(1).split("|");
	let defaults = {};
	_.forEach(hashValues, (hashValue) => {
		const values = hashValue.split("=");
		defaults = _.assign({}, defaults, { [values[0]]: values[1] });
	});
	return defaults;
};

export const isFeatureToggled = (feature, currentUser) => {
	if (process.env.NODE_ENV === "development") {
		return true;
	}
	const baseFeatures = [
		"templates",
		"projects",
		"tasks",
		"properties",
		"tenants",
		"tenancies",
		"schedules",
		"leases",
		"deadlines",
		"todos",
	];
	return !!_.includes(_.unionBy(_.get(currentUser, "features", []), baseFeatures), `${feature}`.toLowerCase());
};

export const setHash = (key, value) => {
	const params = decomposeURI("hash");
	params[key] = encodeURIComponent(value);
	window.location.hash = `#${Object.keys(params)
		.map((key) => `${key}=${params[key]}`)
		.join("|")}`;
};

export const setParams = (key, value) => {
	const params = decomposeURI("search");
	params[key] = encodeURIComponent(value);
	window.location.search = `?${Object.keys(params)
		.map((key) => `${key}=${params[key]}`)
		.join("&")}`;
};

export const getHash = () => decomposeURI("hash");
export const getParams = () => decomposeURI("search");

export const whatIs = (link) => {
	if (!link) {
		return false;
	}
	let parent;
	if (!link) {
		return false;
	}
	try {
		if (link.indexOf("Project") !== -1) {
			parent = "projects";
		} else if (link.indexOf("Approval") !== -1) {
			parent = "approvals";
		} else if (link.indexOf("Property") !== -1) {
			parent = "properties";
		} else if (link.indexOf("Report") !== -1) {
			parent = "reports";
		} else if (link.indexOf("Report") !== -1) {
			parent = "todos";
		} else if (link.indexOf("Party") !== -1) {
			parent = "parties";
		} else if (link.indexOf("Document") !== -1) {
			parent = "documents";
		} else if (link.indexOf("Comment") !== -1) {
			parent = "comments";
		} else if (link.indexOf("Ledger") !== -1) {
			parent = "ledgers";
		} else if (link.indexOf("Quote") !== -1) {
			parent = "quotes";
		} else if (link.indexOf("Portfolio") !== -1) {
			parent = "portfolios";
		} else if (link.indexOf("Condition") !== -1) {
			parent = "conditions";
		} else if (link.indexOf("Lease") !== -1) {
			parent = "leases";
		} else if (link.indexOf("Tenancy") !== -1) {
			parent = "tenancies";
		} else if (link.indexOf("Issue") !== -1) {
			parent = "issues";
		} else if (link.indexOf("Inspection") !== -1) {
			parent = "inspections";
		} else if (link.indexOf("Company") !== -1) {
			parent = "companies";
		} else if (link.indexOf("Template") !== -1) {
			parent = "templates";
		} else if (link.indexOf("Task") !== -1) {
			parent = "tasks";
		} else if (link.indexOf("Tenancy") !== -1) {
			parent = "tenancies";
		} else if (link.indexOf("Tenant") !== -1) {
			parent = "tenants";
		} else if (link.indexOf("Session") !== -1) {
			parent = "session";
		} else if (link.indexOf("Permission") !== -1) {
			parent = "permissions";
		} else if (link.indexOf("User") !== -1) {
			parent = "users";
		} else if (link.indexOf("Building") !== -1) {
			parent = "buildings";
		}
		return parent;
	} catch (error) {
		console.error(error);
	}
};

/**
 * Translate a key using AWS I18n
 * @param {any} word A key to translate
 * @return {string} translated
 */
const translate = (word) => {
	let res = "";
	if (!!word) {
		word = String(word).replace(/-/g, "_");
		const translation = I18n.get(word);
		if (typeof translation !== String) {
			res = translation.replace("_", "-");
		} else if (translation.localeCompare(word) === 0) {
			res = (
				<span title="word" className="debug">
					{word}
				</span>
			);
		} else {
			res = translation.replace("_", "-");
		}
	}
	return res;
};

const exportPDFbyId = (body, type = "", filename = "report.pdf", portfolio_id = "", numberOfProjects = 0) => {
	if (type === "phase-report") {
		// fetch("http://localhost:3000/pdf", {
		fetch("https://3y47yhkjvi.execute-api.ap-southeast-2.amazonaws.com/production/pdf", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				localStorage,
				portfolio_id,
				numberOfProjects,
			}),
		})
			.then((response) => response.text())
			// .then(pdf => saveAs(`data:application/octet-stream;base64,${pdf}`, filename, "application/pdf"));
			.then((png) => saveAs(`data:image/octet-stream;base64,${png}`, filename, "image/png"));
	}

	if (type === "") {
		let head = `<head>
			<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:300,400,900|Montserrat:300,500,700"></link>
			<link rel="stylesheet" type='text/css' href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha.6/css/bootstrap.min.css">
			</head>`;
		fetch("https://8st3defn30.execute-api.ap-southeast-2.amazonaws.com/production/pdf", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				html: `<html>
						${head}
						<body>${body}</body>
					   </html>`,
			}),
		})
			.then((response) => response.text())
			.then((pdf) => saveAs(`data:application/octet-stream;base64,${pdf}`, filename, "application/pdf"));
	}
};

export const getFilenameFromPath = (path) => {
	const atoms = path.split("/");
	return atoms[atoms.length - 1];
};

const getCompletionPercentage = (arrayOfTask) => {
	let numberOfComplete = 0;
	let totalNumberOfTasks = 0;
	for (let i = 0; i < arrayOfTask.length; i++) {
		const task_closed_at = _.get(arrayOfTask, [i, "task_closed_at"]);
		if (task_closed_at) numberOfComplete++;
		totalNumberOfTasks++;
	}
	return Math.round((numberOfComplete / totalNumberOfTasks) * 100);
};

const getFormattedPercentage = (type, data) => {
	const percentage = data && getCompletionPercentage(data);
	let info = "";
	if (type === "project") {
		info = percentage ? percentage + "%" : "";
	} else {
		info = _.get(data, [0, "phase_name"]);
	}
	return info;
};

const generateCSVDownload = (data, headers, filename) => {
	return (
		<CSVLink data={data} headers={headers} filename={filename}>
			<Button>Download CSV</Button>
		</CSVLink>
	);
};

export {
	icon,
	decomposeGoogleAddress,
	icons,
	translate,
	LocalNotification,
	exportPDFbyId,
	getCompletionPercentage,
	getFormattedPercentage,
	generateCSVDownload,
	smartStrictEqual,
};
