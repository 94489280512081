import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { ListGroupControl, View, ListGroup } from "components/Shared";
import { Row } from "components/Projects";
import _ from "lodash";
import { getProperties } from "actions";
import { useDefaults } from "lib";
import { makeEntitiesSelector } from "../../selectors/utils";

interface Props {
	projects?: tcpinpoint.Project[];
	children?: JSX.Element;
	name?: string;
}

const propertiesSelector = makeEntitiesSelector("properties");

const Projects = ({ projects, children }: Props) => {
	const dispatch = useDispatch();
	const defaults = useDefaults() as { sortBy: string; filteredBy: string };

	const [sortBy, setSortBy] = useState<any>(defaults.sortBy || "name");
	const [filteredBy, setFilteredBy] = useState(defaults.filteredBy || "open");

	const filterFunction = (project: tcpinpoint.Project) =>
		!filteredBy ||
		filteredBy === "" ||
		(filteredBy === "open" && !project.closed_at) ||
		(filteredBy === "ahead" && !project.behind && !project.closed_at) ||
		(filteredBy === "behind" && !!project.behind) ||
		(filteredBy === "archived" && !!project.closed_at);

	useEffect(() => {
		dispatch(getProperties());
	}, [dispatch]);

	const properties = useSelector(propertiesSelector, shallowEqual);
	const sortOrder = _.includes(sortBy, "created_at") || _.includes(sortBy, "updated_at") ? "desc" : "asc";
	const collection = _.map(_.filter(_.orderBy(projects, sortBy, [sortOrder]), filterFunction), (project) =>
		_.assign({}, project, { property: _.find(properties, { id: project.property_id }) })
	);

	return (
		<View resource="projects" hideTitle={true}>
			<ListGroupControl
				sortOptions={["name", "tenancy_name", "property_name", "updated_at", "created_at"]}
				onSortChange={setSortBy}
				selectedSort={sortBy}
				selectedView={"list"}
				filterOptions={["open", "ahead", "behind", "archived"]}
				onFilterChange={setFilteredBy}
				selectedFilter={filteredBy}
			/>
			<ListGroup
				resource="projects"
				collection={collection}
				renderRow={(project) => <Row showProperty={true} key={project.id} project={project} />}
			/>
			{children}
		</View>
	);
};

export default memo(Projects);
