import React, { memo } from "react";
import { useMapRenderer } from "./hooks";

const Map = (props: any) => {
	const { buildings = [], addresses = [], ...rest } = props;
	const mapContainer = useMapRenderer(buildings, addresses);

	return (
		<React.Fragment>
			<div ref={mapContainer} style={rest} className="map" />
		</React.Fragment>
	);
};

export default memo(Map);
