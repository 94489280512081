//@ts-ignore
import filesize from "filesize";
import { translate } from "lib";
import _ from "lodash";
import React, { memo, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const Uploads = () => {
	const dispatch = useDispatch();
	const uploads: tcpinpoint.DocumentUpload[] = useSelector((store: tcpinpoint.Store) => store.uploads, shallowEqual);
	const onClear = useCallback((document: Document) => dispatch({ type: "UPLOAD_DOCUMENT_CLEAR", document: document }), [
		dispatch
	]);

	const items = _.compact(
		_.map(_.keys(uploads), (key: string) => {
			//@ts-ignore
			const upload: DocumentUpload = uploads[key as string];
			if (!upload) {
				return false;
			}
			if (upload.state !== "FINISHED") {
				let remaining;
				const loadedFilesize = filesize(upload.loaded || 9999, { round: 1 });
				const totalFilesize = filesize(upload.total || 9999, { round: 1 });

				if (upload.remainingSeconds > 60) {
					const minutes = Math.round(upload.remainingSeconds / 60);
					remaining = `${minutes} ${translate("minute")} ${translate("left")}`;
				} else if (upload.remainingSeconds) {
					remaining = `${Math.ceil(upload.remainingSeconds / 10) * 10} ${translate("seconds")} ${translate("left")}`;
				} else {
					remaining = translate("finishing");
				}

				return (
					<div className="m-auto flex">
						<div className="flex-10 mt-1">
							<div className="upload" key={key}>
								{upload.method === "GET_EXIF" && upload.state === "SUCCESS" && (
									<i className="far fa-brackets-curly fa-2x" />
								)}
								{upload.method === "POST_THUMBNAIL" && upload.state === "SUCCESS" && (
									<i className="far fa-image fa-2x" />
								)}
								{upload.method === "UPLOAD_DOCUMENT" && upload.state === "SUCCESS" && (
									<i className="fas fa-check-circle fa-2x" />
								)}
								{upload.state === "REQUEST" && <i className="fal fa-circle-notch fa-spin fa-2x" />}
								{upload.state === "PROGRESS" && <i className="fal fa-circle-notch fa-spin fa-2x" />}
								{upload.state === "FAILED" && <i className="fal fa-exclamation-circle fa-2x" />}
								{upload.state === "CANCELLED" && <i className="fa fa-pause-circle fa-2x" />}
								<h4>{upload.document.name}</h4>
								{upload.method === "GET_EXIF" && upload.state === "REQUEST" && <p>Getting Image Information</p>}
								{upload.method === "GET_EXIF" && upload.state === "SUCCESS" && <p>Done!</p>}
								{upload.method === "POST_THUMBNAIL" && upload.state === "REQUEST" && <p>Generating Thumbnail</p>}
								{upload.method === "POST_THUMBNAIL" && upload.state === "SUCCESS" && <p>Thumbnail Done!</p>}
								{upload.method === "UPLOAD_DOCUMENT" && upload.state === "SUCCESS" && <p>Done!</p>}
								{upload.method === "UPLOAD_DOCUMENT" && upload.state === "REQUEST" && <p>Starting</p>}
								{upload.method === "UPLOAD_DOCUMENT" && upload.state === "PROGRESS" && !!upload.percentComplete && (
									<div className="upload-status">
										<div className="progress">
											<div
												className="progress-bar"
												role="progressbar"
												style={{ width: `${upload.percentComplete}%` }}
												aria-valuenow={upload.percentComplete}
												aria-valuemin={0}
												aria-valuemax={100}
											/>
										</div>
										<div className="document-uploading-size">
											{upload.total !== upload.loaded && `${loadedFilesize} of `}
											{totalFilesize}
										</div>
										<div className="document-uploading-estimated-seconds">{remaining}</div>
										{!!upload.cancelToken && <button onClick={upload.cancelToken.cancel}>Cancel</button>}
									</div>
								)}
								{upload.state === "FAILED" && <p>Something Went Wrong</p>}
								{upload.state === "CANCELLED" && <p>Cancelled</p>}
							</div>
						</div>
						<div className="flex-2 ml-2 relative -top-10 -right-5">
							<button
								type="button"
								className="close relative top-0"
								aria-label="Close"
								onClick={() => onClear(upload.document)}
							>
								<span aria-hidden="true">×</span>
							</button>
						</div>
					</div>
				);
			}
			return null;
		})
	);
	return items.length ? <div id="uploads">{items}</div> : null;
};

export default memo(Uploads);
