import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWaveSine } from "@fortawesome/pro-regular-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import Progress from "components/Visualisations/Progress/ProgressLegend";

interface Props {
  type?: string;
  color?: string;
  className?: string;
}

const Legend = memo(({ type = "wave", color = "#000" }: Props) => {
  // @ts-ignore
  if (type === "spinner")
    return (
      <div className="status">
        <FontAwesomeIcon
          // @ts-ignore
          icon={faSpinnerThird}
          size={"1x"}
          color={"#efefef"}
          // @ts-ignore
          style={{ "--fa-primary-color": `${color}` }}
        />
      </div>
    );
  if (type === "progress") return <Progress></Progress>;
  return <FontAwesomeIcon icon={faWaveSine} size={"1x"} />;
});

export { Legend }
