import React, { memo } from "react";
import classNames from "classnames";
import { Header, Footer, Cloud1, Cloud2 } from ".";

const Sky = ({ children: Card, className }: { children?: React.ReactNode; className?: string }) => {
	return (
		<div className={classNames("relative", "flex flex-col", "bg-blue-80", className)}>
			<Header className="min-h-17 lg:min-h-15" />
			{Card}
			<Footer className="min-h-13 lg:bottom-1 lg:w-full lg:z-20  mt-auto lg:mb-4" />
			<Cloud1 className="hidden lg:block lg:bottom-0 lg:left-1 animate-movefast" />
			<Cloud2 className="hidden lg:block lg:bottom-0 lg:left-10 animate-moveslow" />
		</div>
	);
};

export default memo(Sky);
