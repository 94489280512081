import React, { Fragment } from "react";
import { Card, Form } from "v2/components/Tenancies";
import { Center } from "v2/components/shared";

export default ({
	onUpdate,
	tenancy,
	name,
	viewState,
	setViewState,
	setTenancyArea
}: {
	onUpdate?: any;
	tenancy?: any;
	name?: string;
	viewState?: number;
	setViewState?: any;
	setTenancyArea: any;
}) => {
	return (
		<Fragment>
			{viewState === 1 && !!tenancy ? (
				<Card property={tenancy.property} tenancy={tenancy} name={name} setTenancyArea={setTenancyArea} onClear={() => setViewState(0)} />
			) : (
				<Center>
					<Form
						onUpdate={(tenancy: any) => {
							onUpdate(tenancy);
							setViewState(1);
						}}
						tenancy={tenancy}
					/>
				</Center>
			)}
		</Fragment>
	);
};
