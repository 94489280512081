import Avatar from "components/Avatar/Show";
import { Date } from "components/Shared";
import React, { memo } from "react";

interface Props {
	activity: tcpinpoint.ActivityItem;
	link: string;
	icon: any;
	output: any;
}

const ActivityDomItem = ({ activity, icon, output, link }: Props) => {
	return (
		<React.Fragment>
			<div className="activity-item-avatar">
				<Avatar
					size={32}
					url={activity.attributes.item.actor.image}
					name={
						activity.attributes.item.actor && !!activity.attributes.item.actor.name
							? activity.attributes.item.actor.name
							: "tcbot"
					}
				/>
			</div>
			<div className="activity-item-container">
				<div className="activity-item-body">{output}</div>
				<span className="activity-item-date">
					{icon} <Date showRelative date={activity.attributes.item.published} />
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{activity.attributes.item.object.type}</code>}
					{process.env.NODE_ENV === "development" && <code>{link}</code>}
				</span>
			</div>
		</React.Fragment>
	);
};

export default memo(ActivityDomItem);
