import { useState, useEffect } from "react";
//@ts-ignore
import ActionCable from "actioncable";
import Datasource, { DatasourceResponse } from "lib/datasource";
import moment from "moment";
import { getAuthTokenFromCookie ,getUserIdFromStorage } from 'lib/hooks';

export const getUserInfo = (session: any) => {
	return {
		id: session.id,
		admin: session.admin,
		email: session.email,
		firstname: session.firstname,
		surname: session.surname,
		features: JSON.parse(session.features? session.features : []),
		phone_number: session.phone_number
	};
};

export const useCreateCable = () => {
	const [cable, setCable] = useState<any>();
	const [error, setError] = useState<any>();
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "userInfo",
			renderTrigger: renderTrigger
		})
	);

	useEffect(() => {
		try {
			const token = datasource.getAuthTokenFromCookie();
			const cable = ActionCable.createConsumer(
				`${process.env.REACT_APP_WEBSOCKET}cable?accessToken=${token}`
			)
			setCable(cable)
		} catch (error) {
			setError(error)
		}
	}, [datasource]);
	return [cable, error];
};


export const useCurrentUserInfo = () => {
	const [currentUser, setCurrentUser] = useState<any>();
	const [error, setError] = useState<any>();
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "users",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			setCurrentUser(
				!response.success ? response.jsonApiResponse.user : {}
			);
			if (response.success) {
				setError('Could not get user info');
			}
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		if (getUserIdFromStorage() && getAuthTokenFromCookie()) {
			datasource.get("v2", `users/${getUserIdFromStorage()}`, {})
		} else {
			setError('No auth token or User id');
		};
	}, [datasource]);
	return [currentUser, error];
};
