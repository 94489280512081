import React from "react";
import classNames from "classnames";
import { View } from "components/Shared";
import { Create, Lookup, Card } from "components/Properties";
import _ from "lodash";
export default class PropertySelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = { property: { created: false } };
	}

	render() {
		const { property } = this.state;
		const { errors, className, onUpdate } = this.props;
		if (!!this.props.property) {
			return (
				<Card
					property={this.props.property}
					onUpdate={() => this.setState({ property: false }, () => onUpdate(false))}
				/>
			);
		}
		return (
			<View
				resource="location"
				className={classNames("form-group", className, {
					"has-error": !!errors.property_id || !!errors.property
				})}
			>
				{(!property || (!!property && !property.created)) && (
					<Lookup
						label="location-lookup-label"
						onChange={property => {
							let building;
							let floor;
							const buildings = _.get(property, "buildings", []);
							if (buildings.length === 1) {
								building = buildings[0];
								if (_.get(building, "floors", []).length === 1) {
									floor = building.floors[0];
								}
							}
							this.setState({ property, tenancy: { building, floor } }, () => onUpdate(property));
						}}
					/>
				)}
				{!!property && !!property.created && (
					<Create
						key="property-create"
						property={property}
						onUpdate={property => this.setState({ property }, () => onUpdate(property))}
					/>
				)}
				{errors.property_id && <div className="form-control-feedback help-block">{errors.property_id.join(" ")}</div>}
				{!!errors.property_id ||
					(!!errors.property && (
						<div className="alert alert-danger">
							<i className="fa fa-exclamation" /> {errors["property"]} {errors["property_id"]}
						</div>
					))}
			</View>
		);
	}
}
