import React, { memo } from "react";
import { markup } from "lib/formatting";
import classNames from "classnames";
import Linkify from "react-linkify";
interface Props {
	className?: string;
	selected?: boolean;
	attribution?: string;
	children: JSX.Element[] | JSX.Element | string | boolean;
}

const Text = ({ className, selected, children }: Props) => {
	if (!!children) {
		return (
			<div
				className={classNames(className, "text-block", {
					active: selected,
				})}
			>
				<Linkify>
					<span className="text-block-content" dangerouslySetInnerHTML={{ __html: markup(children) }} />
				</Linkify>
			</div>
		);
	}
	return null;
};

export default memo(Text);
