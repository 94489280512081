import React, { useState, memo } from "react";
import _ from "lodash";
import classNames from "classnames";

const Flyout = ({ menu }: { menu: any[] }) => {
	const [flyoutOpen, setFlyoutOpen] = useState(false);
	if (!menu || !_.compact(menu).length) {
		return null;
	}
	return (
		<div
			className={classNames("flyout", {
				"flyout-open": flyoutOpen,
				"flyout-closed": !flyoutOpen
			})}
		>
			<button
				className="btn btn-secondary"
				onClick={() => setFlyoutOpen(!flyoutOpen)}
				type="button"
				data-toggle="flyout"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<i className="fas fa-angle-left" />
			</button>
			<ul>
				{_.map(_.compact(menu), item => (
					<li key={item.key}>{item}</li>
				))}
			</ul>
		</div>
	);
};
export default memo(Flyout);
