import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const postReport = memoAC((report?: tcpinpoint.Report) => (dispatch: any) => {
	dispatch({ type: "POST_REPORT_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}reports`, { report }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "POST_REPORT_SUCCESS" });
		dispatch(getReports());
	})
	.catch((e) => { dispatch({ type: "POST_REPORT_FAILED", response: e } as tcpinpoint.APIFailure); });
});

export const putReport = memoAC((report: tcpinpoint.Report) => (dispatch: any) => {
	dispatch({ type: "PUT_REPORT_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}reports/${report.id}`, { report }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "PUT_REPORT_SUCCESS" });
		dispatch(getReport(report));
	})
	.catch((e) => { dispatch({ type: "PUT_REPORT_FAILED", response: e } as tcpinpoint.APIFailure); });
});

export const getReport = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_REPORT_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}reports/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_REPORT_SUCCESS", report: res.data.report }))
	.catch((e) => { dispatch({ type: "GET_REPORT_FAILED", response: e } as tcpinpoint.APIFailure); });
});

export const getReports = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_REPORTS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}reports`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_REPORTS_SUCCESS", reports: res.data.reports }))
	.catch((e) => { dispatch({ type: "GET_REPORTS_FAILED", response: e } as tcpinpoint.APIFailure); });
});

export const getReportsFrom = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_REPORTS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}reports/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_REPORTS_SUCCESS", report: res.data.report }))
	.catch((e) => { dispatch({ type: "GET_REPORTS_FAILED", response: e } as tcpinpoint.APIFailure); });
});
