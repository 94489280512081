import _ from "lodash";
import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const getNotifications = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_NOTIFICATIONS_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V2}notifications`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		const unread = _.filter(res.data, notification => !notification.attributes.read_at).length;
		if (!!unread) {
			if (document.title.substr(0, 1) === "(") {
				document.title = document.title.replace(/\(.+\)/, `(${unread})`);
			} else {
				document.title = `(${unread}) ${document.title}`;
			}
		} else {
			document.title = document.title.replace(/\(.+\)/, ``);
		}
		dispatch({ type: "GET_NOTIFICATIONS_SUCCESS", resource: res.data.response });
	})
	.catch((e) => { dispatch({ type: "GET_NOTIFICATIONS_FAILED", response: e }); });
});

export const markAsUnread = memoAC((notification: tcpinpoint.Notification) => (dispatch: any) => {
	dispatch({ type: "MARK_NOTIFICATION_UNREAD", notification });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}notifications/${notification.id}/unread`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {})
	.catch((e) => dispatch({ type: "MARK_READ_NOTIFICATIONS_FAILED", response: e }))
	.finally(() => dispatch(getNotifications()));
});

export const markAsRead = memoAC((notification: tcpinpoint.Notification) => (dispatch: any) => {
	dispatch({ type: "MARK_NOTIFICATION_READ", notification });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}notifications/${notification.id}/read`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {})
	.catch((e) => dispatch({ type: "MARK_READ_NOTIFICATIONS_FAILED", response: e }))
	.finally(() => dispatch(getNotifications()));
});

export const markAllNotificationsRead = memoAC(() => (dispatch: any) => {
	dispatch({ type: "MARK_ALL_NOTIFICATION_READ" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}notifications/read`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {})
	.catch((e) => dispatch({ type: "MARK_ALL_READ_NOTIFICATIONS_FAILED", response: e }))
	.finally(() => dispatch(getNotifications()));
});

export const markAllNotificationsUnread = memoAC(() => (dispatch: any) => {
	dispatch({ type: "MARK_ALL_NOTIFICATION_UNREAD" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}notifications/unread`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {})
	.catch((e) => dispatch({ type: "MARK_ALL_READ_NOTIFICATIONS_FAILED", response: e }))
	.finally(() => dispatch(getNotifications()));
});
