import axios from 'axios';
import { getAuthTokenFromCookie } from 'lib/hooks';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT_V2 || 'http://localhost:3000/' });

axiosServices.interceptors.request.use(
  (config) => {
    const session = getAuthTokenFromCookie();
    if (session) {
      config.headers['Authorization'] = `Bearer ${session}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/signin')) {
      window.location.pathname = '/signin';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;
