import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const postProperty = memoAC((property: tcpinpoint.Property) => (dispatch: any) => {
	dispatch({ type: "POST_PROPERTY_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}properties`, { property }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "POST_PROPERTY_SUCCESS" });
		dispatch(getProperty(res.data.property.id));
	})
	.catch((e) => { dispatch({ type: "POST_PROPERTY_FAILED", response: e }); });
});

export const putProperty = memoAC((property: tcpinpoint.Property) => (dispatch: any) => {
	dispatch({ type: "PUT_PROPERTY_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}properties/${property.id}`, { property }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "PUT_PROPERTY_SUCCESS" });
		dispatch(getProperty(res.data.property.id));
	})
	.catch((e) => { dispatch({ type: "PUT_PROPERTY_FAILED", response: e }); });
});

export const getProperty = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_PROPERTY_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}properties/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_PROPERTY_SUCCESS", resource: res.data.property }))
	.catch((e) => { dispatch({ type: "GET_PROPERTY_FAILED", response: e }); });
});

export const getProperties = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_PROPERTIES_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}properties`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_PROPERTIES_SUCCESS", resource: res.data.properties }))
	.catch((e) => { dispatch({ type: "GET_PROPERTIES_FAILED", response: e }); });
});
