import { getLedger } from "actions";
import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const deleteQuote = memoAC((id: string) => (dispatch: any) => {
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}quotes/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
});

/**
 * Create a New Quote
 * @param quote Quote
 */
export const postQuote = memoAC((quote: tcpinpoint.Quote) => (dispatch: any) => {
	dispatch({ type: "POST_QUOTE_REQUEST" });
	axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}quotes`, { quote }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "POST_QUOTE_SUCCESS" });
		dispatch(getLedger(quote.ledger_id));
	})
	.catch((e) => { dispatch({ type: "POST_QUOTE_FAILED", resource: quote, response: e }); });
});

/**
 * Update a Quote
 * @param quote Quote
 */
export const putQuote = memoAC((quote: tcpinpoint.Quote) => (dispatch: any) => {
	dispatch({ type: "PUT_QUOTE_REQUEST" });
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}quotes/${quote.id}`, { quote }, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => {
		dispatch({ type: "PUT_QUOTE_SUCCESS" });
		dispatch(getLedger(res.data.quote.ledger_id));
	})
	.catch((e) => { dispatch({ type: "PUT_QUOTE_FAILED", resource: quote, response: e }); });
});

export const upsertQuote = memoAC((quote: tcpinpoint.Quote) => (dispatch: any) => {
	if (quote.id === undefined) {
		dispatch(postQuote(quote));
	} else {
		dispatch(putQuote(quote));
	}
});

/**
 * Get a Quote
 * @param id Quote ID
 */
export const getQuote = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_QUOTE_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}quotes/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_QUOTE_SUCCESS", quote: res.data.quote }))
	.catch((e) => { dispatch({ type: "GET_QUOTE_FAILED", resource: { id }, response: e }); });
});
/**
 * Get Quotes
 */
export const getQuotes = memoAC(() => (dispatch: any) => {
	dispatch({ type: "GET_QUOTES_REQUEST" });
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}quotes`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromCookie()}`,
		}
	})
	.then((res) => dispatch({ type: "GET_QUOTES_SUCCESS", quotes: res.data.quotes }))
	.catch((e) => { dispatch({ type: "GET_QUOTES_FAILED", resource: {}, response: e }); });
});
