import React, { memo } from "react";
import { Breadcrumbs, Header, Workspace } from "../Shared";
import CollectionDynamic from "./CollectionDynamic";

const Projects = ({ history }: any) => {
	return (
		<React.Fragment>
			<Breadcrumbs items={[{ title: "projects" }]} />
			<Header
				title="projects"
				toolbar={[{ onClick: () => history.push("/projects/new"), label: "new_project", icon: "new" }]}
			/>
			<Workspace id="projects" className="list">
				<CollectionDynamic />
			</Workspace>
		</React.Fragment>
	);
};

const MemoizedProjects = memo(Projects);
export default ({ history }: any) => <MemoizedProjects history={history} />;
