import React, { memo } from "react";
import moment from "moment";
import "moment-business-days";
import { translate } from "lib";
import { DefinitionList, DefinitionItem, RelativeDate, Date, View } from "components/Shared";
import EditHistory from "components/Shared/EditHistory/EditHistory";

const Dashboard = ({ task }: { task: tcpinpoint.Task; name: string }) => {
	return (
		<View resource="details">
			<DefinitionList>
				<DefinitionItem title="description" value={task.description} />
				{!task.closed_at && !!task.length && (
					<React.Fragment>
						<DefinitionItem title="projected_length" value={task.length} suffix={translate("calendar_days")} />
						<DefinitionItem
							title="scheduled_close_date"
							value={<Date date={task.due_at} showTime={false} showDay={true} />}
						/>
					</React.Fragment>
				)}
				{!!task.closed_at && (
					<DefinitionItem
						title="due_at"
						value={<Date date={task.due_at} showTime={false} showDay={true} />}
						footnote={!moment(task.due_at).isSame(moment(), "day") && <RelativeDate date={task.due_at} />}
					/>
				)}
			</DefinitionList>
			<EditHistory resource={task} attributeFilter={["projected_due_at", "fixed_due_at", "due_at"]} />
		</View>
	);
};
export default memo(Dashboard);
