import { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fromEvent, merge, timer } from "rxjs";
import { switchMapTo } from "rxjs/operators";

const IdleTimer = () => {
	const history = useHistory();
	const clicks$ = fromEvent(window, "click");
	const keys$ = fromEvent(window, "keydown");
	const mouse$ = fromEvent(window, "mousemove");
	const scroll$ = fromEvent(window, "scroll");
	const idle$ = merge(timer(), clicks$, keys$, mouse$, scroll$).pipe(switchMapTo(timer(2 * 60 * 60 * 1000))); // 2 * 60 minute inactivity timeout;

	useEffect(() => {
		let sub = idle$.subscribe(() => history.push("/signout?reason=timeout"));

		return () => {
			if (sub) sub.unsubscribe();
		};
	}, [idle$, history]);

	return null;
};

export default memo(IdleTimer);
