import React from "react";
import Modal from "react-modal";
import { ListGroup, ModalHeader, ListGroupItem } from "components/Shared";
import _ from "lodash";

export default ({ template, isOpen, doCloseModal, onUpdate, task }: any) => {
	const existingDependencies = task.dependencies;
	const phases = _.sortBy(_.get(template, "body.phases", []), "index");
	const tasks = _.filter(
		_.compact(_.flatMap(phases, phase => _.map(phase.tasks, task => _.assign({}, task, { phase })))),
		task => !_.includes(_.map(existingDependencies, "id"), task.id)
	);

	return (
		<Modal isOpen={isOpen} onRequestClose={doCloseModal} className="dependency-modal" contentLabel="Dependency">
			<ModalHeader title="dependencies" onRequestClose={doCloseModal} />
			<section>
				<ListGroup
					collection={tasks}
					resource="tasks"
					renderRow={task => (
						<ListGroupItem
							key={task.id}
							icon={<i className="fas fa-circle" style={{ color: _.get(task, "phase.color", "#dedede") }} />}
							parent={<h4>{_.get(task, "phase.name")}</h4>}
							heading={task.name}
							subheading={_.get(task, "role.name")}
							onAdd={() => {
								onUpdate({ id: task.id, name: task.name });
								doCloseModal();
							}}
						/>
					)}
				/>
			</section>
		</Modal>
	);
};
