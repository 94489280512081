import React, { Fragment } from "react";
// import { Footer } from "v2/components/shared/Sky";
import { Footer } from "components/Shared";
import { Masthead } from "v2/components/shared/Masthead";
import { useBoot } from "./hooks";
import IdleTimer from "components/Session/IdleTimer";
import Uploads from "components/Documents/Uploads";

const ProtectedLayout = ({ children }: { children: React.ReactNode }) => {
	const output = useBoot(children);
	return (
		<Fragment>
			<IdleTimer />
			<Masthead />
			{output}
			<Footer />
			<Uploads />
		</Fragment>
	);
};

export default ProtectedLayout;
