import React from "react";
import validate from "validate.js";
import ReactModal from "react-modal";
import { Input } from "components/Inputs";
import { Shape } from "components/Tenancies";
import { isFeatureToggled } from "lib";
import { UserContext} from "context"
import { Lookup } from "components/Facilities";
import { Button, ModalHeader, Row, Col } from "components/Shared";
import _ from "lodash";

const constraints = {
	name: { presence: true }
};

export default class Modal extends React.Component {
	static contextType = UserContext;
	state = { exists: true };

	onSubmit() {
		const { doCloseModal, onUpdate } = this.props;
		const metadata = _.assign({}, this.state);
		const name = _.get(this.state, "name.slug");
		const poi = _.get(this.state, "poi", []);
		const notes = _.get(this.state, "notes", "");
		delete metadata.name;
		delete metadata.notes;
		delete metadata.poi;
		const errors = validate(this.state, constraints);
		if (!!errors) {
			this.setState({ errors });
		} else {
			onUpdate({ name, notes, poi, metadata: _.map(_.keys(metadata), key => ({ key, value: metadata[key] })) });
			doCloseModal();
		}
	}

	render() {
		const { currentUser } = this.context;
		const { modalOpen, doCloseModal, resource, inspection } = this.props;
		const { name } = this.state;
		return (
			<ReactModal isOpen={modalOpen} className="facility-modal" contentLabel="Facility" onRequestClose={doCloseModal}>
				<ModalHeader title={`${_.get(inspection, 'kind')}_inspection`} onClose={doCloseModal} />
				<section>
					<Lookup
						required={constraints}
						inspection={inspection}
						defaultState={this.state}
						name="name"
						onChange={name => this.setState({ name })}
					/>
					<Input
						name="exists"
						type="checkbox"
						onChange={exists => this.setState({ exists })}
						defaultState={this.state}
					/>
					{!!name && (
						<Input
							name="notes"
							defaultState={this.state}
							required={constraints}
							onChange={notes => this.setState({ notes })}
						/>
					)}
					{!!_.get(name, "metadata") && (
						<Row>
							<Col>
								{_.map(_.get(name, "metadata", []), input => (
									<Input
										name={input.slug}
										suffix={input.units}
										required={constraints}
										options={input.options}
										defaultState={this.state}
										onChange={value => this.setState({ [input.slug]: value })}
									/>
								))}
							</Col>
							{!!_.get(resource, "shape") && isFeatureToggled("plans", currentUser) && (
								<Col>
									<Shape {...resource} />
								</Col>
							)}
						</Row>
					)}
				</section>
				<footer>
					<Button onClick={() => this.onSubmit()} />
				</footer>
			</ReactModal>
		);
	}
}
