import React from "react";
import Modal from "react-modal";
import { Button, ListGroup, ListGroupItem, ModalHeader } from "components/Shared";
import { Lookup } from "components/Projects";
import _ from "lodash";
import { Show as Avatar } from "components/Avatar";

class ProjectSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: _.get(props, "projects", [])
    };
  }

  pushItem(project) {
    const { projects } = this.state;
    projects.push(project);
    this.setState({ projects: _.uniqBy(projects, "id") });
  }

  popItem(project) {
    const { projects } = this.state;
    this.setState({ projects: _.filter(projects, _project => project.id !== _project.id) });
  }

  render() {
    const { projects } = this.state;
    const { onUpdate, onClose, isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHeader title="select_project" onClose={onClose} />
        <section>
          <Lookup onChange={project => this.pushItem(project)}>
            <ListGroup
              resource="projects"
              collection={projects}
              renderRow={project => (
                <ListGroupItem
                  key={project.id}
                  icon={<Avatar url={_.get(project, "avatar")} name={_.get(project, "name")} />}
                  heading={project.name}
                  onClose={() => this.popItem(project)}
                />
              )}
            />
          </Lookup>
        </section>
        <footer>
          <Button onClick={() => onUpdate(projects)} />
        </footer>
      </Modal>
    );
  }
}

export default ProjectSelect;
