import React, { Fragment, memo } from "react";
import { Button, Money, Date, DefinitionList, DefinitionItem, Row, Col } from "components/Shared";
import { translate } from "lib";
import moment from "moment";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Center } from "v2/components/shared";

interface Props extends RouteComponentProps {
	name?: string;
	editable?: boolean;
	lease: tcpinpoint.Lease | false;
	tenant?: tcpinpoint.Tenant | false;
	onClear?: () => void;
	icon?: string;
	tenancyArea: any;
}

const Card = ({ lease, history, onClear, tenancyArea }: Props) => {
	const calculateRentPerMeterSquared = (tenancyArea:number, baseRent:number): number => {
		return (_.divide(baseRent,tenancyArea));
	}
	const calculateDailyRent = (baseRent:number): number => {
		return (_.divide(_.multiply(baseRent, 12), 365));
	}
	if (!!lease) {
		return (
			<Fragment>
				{!!onClear && (
					<button type="button" className="close" aria-label="Close" onClick={onClear}>
						<span aria-hidden="true">×</span>
					</button>
				)}{" "}
				<Center>
					<Row>
						<Col>
							<DefinitionList className="mt-5">
								<DefinitionItem title="kind" value={translate(_.get(lease, "kind"))} className="mb-2" />
								<DefinitionItem title="duration" value={_.get(lease, "duration")} suffix="months" className="mb-2" />
								<DefinitionItem title="permitted_use" value={_.get(lease, "permitted_use")} className="mb-2" />
								<DefinitionItem title="incentives" value={_.get(lease, "incentives")} className="mb-2" />
								<DefinitionItem
									title="handover_at"
									value={<Date date={_.get(lease, "handover_at")} showTime={false} />}
									className="mb-2"
									showIcon={false}
								/>
								{_.get(lease, "fitout_ends_at") && _.get(lease, "handover_at") && (
									<DefinitionItem
										title="fitout_period"
										value={moment(_.get(lease, "fitout_ends_at")).diff(moment(_.get(lease, "handover_at")), "days")}
										suffix="days"
										className="mb-2"
									/>
								)}
								<DefinitionItem
									title={"opening_at"}
									value={<Date date={_.get(lease, "opening_at")} showTime={false} />}
									className="mb-2"
								/>
								<DefinitionItem
									title="fitout_ends_at"
									value={<Date date={_.get(lease, "fitout_ends_at")} showTime={false} />}
									className="mb-2"
								/>
								<DefinitionItem
									title="rent_starts_at"
									value={<Date date={_.get(lease, "rent_starts_at")} showTime={false} />}
									className="mb-2"
								/>
								<DefinitionItem
									title="ends_at"
									value={<Date date={_.get(lease, "ends_at")} showTime={false} />}
									className="mb-2"
								/>
								<DefinitionItem
									title="annual_rent_cents"
									value={<Money amount={_.get(lease, "base_rent_cents", 0) * 12} />}
									footnote={translate("(ex tax)")}
									className="mb-2"
								/>
								<DefinitionItem
									title="annual_rent_cents_per_sq_m"
									value={<Money amount={calculateRentPerMeterSquared(tenancyArea, (_.get(lease, "base_rent_cents", 0) * 12) )} />}
									footnote={translate("(ex tax)")}
									className="mb-2"
								/>
								<DefinitionItem
									title="daily_rent_cents"
									value={<Money amount={calculateDailyRent(_.get(lease, "base_rent_cents", 0))} />}
									footnote={translate("(ex tax)")}
									className="mb-2"
								/>
								{/* {_.map(
						[
							"base_rent_cents",
							"rent_per_sqm_cents",
							"fitout_contribution_cents",
							"operational_outgoings_cents",
							"statutory_outgoings_cents"
						],
						value =>
							!!_.get(lease, "value") &&
							!isUndefined(_.get(lease, "value")) && (
								<DefinitionItem title={value} footnote={!!_.get(lease, "value") && translate("(excluding tax)")}>
									{!!_.get(lease, "value") ? (
										<Money amount={_.get(lease, "value")} />
									) : (
										<span className="muted">{translate("unknown")}</span>
									)}
								</DefinitionItem>
							)
					)} */}

								<DefinitionItem
									title="base_rent_frequency"
									value={translate(_.get(lease, "base_rent_frequency"))}
									className="mb-2"
								/>
								<DefinitionItem
									title="annual_review"
									value={_.get(lease, "annual_review")}
									suffix="%"
									className="mb-2"
								/>
							</DefinitionList>
							{!!lease.id && <Button onClick={() => history.push(`/leases/${lease.id}/edit`)}>Edit</Button>}
						</Col>
					</Row>
				</Center>
			</Fragment>
		);
	}
	return null;
};

export default memo(withRouter(Card));
