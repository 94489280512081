const config = {
  version: '%VERSION%',
  build: 20190101,
  api: process.env.REACT_APP_API_ENDPOINT_V1_HOST,
  GoogleAnalytics: {
    accessToken: 'UA-85423890-2'
  },
  Stripe: {
    key: 'pk_test_TLnaus9f3e8123tjGKlnuSBe'
  },
  Intercom: {
    app_id: 'xgqmzf9f'
  },
  Pusher: {
    accessToken: '5d4a443ec77a60e03a62',
    cluster: 'ap1'
  },
  Sentry: {
    dsn: 'https://113727229f2247378c8275db40aef2bf@sentry.io/111667'
  },
  Raven: {
    accessToken: 'https://113727229f2247378c8275db40aef2bf@sentry.io/111667'
  },
  Stream: {
    key: 'd28etvfqn93p',
    appId: '40290'
  },
  Mapbox: {
    style: 'mapbox://styles/tcpinpoint/cjde3vidd9er62rvh2wwf9dkx',
    mapboxApiAccessToken: 'pk.eyJ1IjoidGNwaW5wb2ludCIsImEiOiJjaXVxZTF3aWQwMDAxMm5wMm9uMGY0cDVvIn0.P_3dtR3ifLSsvLHQMnnFqw',
    minZoom: 1,
    defaultZoom: 7,
    maxZoom: 18,
    pitch: 30
  },
  GoogleChart: {
    title: null,
    bars: 'horizontal',
    hAxis: {
      title: null,
      minValue: 0,
      viewWindow: {
        min: 0
      },
      titleTextStyle: {
        fontName: 'Lato'
      }
    },
    vAxis: {
      titleTextStyle: {
        fontName: 'Lato'
      },
      title: null,
      minValue: 0
    },
    backgroundColor: {
      fill: 'white'
    },
    pieHole: 0.5,
    animation: {
      duration: 200,
      easing: 'in'
    },
    colors: [
      '#00c6d7',
      '#FF8400',
      '#00af3d',
      '#b62097',
      '#684f27',
      '#fc4036',
      '#fef8b5'
    ],
    sliceVisibilityThreshold: 0,
    fontName: 'Lato',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#757575',
    gantt: {
      criticalPathEnabled: true,
      criticalPathStyle: {
        stroke: '#e64a19',
        strokeWidth: 5
      },
      arrow: {
        angle: 100,
        width: 5,
        color: 'green',
        radius: 0
      },
      labelStyle: {},
      barCornerRadius: 0,
      shadowEnabled: false
    }
  }
}

export default config;