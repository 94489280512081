import React, { useState, memo } from "react";
import classNames from "classnames";
import { ListGroupItem } from "components/Shared";
import { Modal } from "v2/components/Tasks";
import _ from "lodash";

const Task = ({
	task,
	template,
	phase,
	onUpdate,
	onDelete
}: {
	task: any;
	template: any;
	phase: any;
	onUpdate: any;
	onDelete: any;
}) => {
	const [state, setState] = useState({ isOpen: false });
	if (task) {
		const { id, name, role, start_with } = task;
		const { isOpen } = state;
		return (
			<React.Fragment>
				<ListGroupItem
					id={id}
					icon={<i className="fal fa-circle" />}
					className={classNames("task", "list-group-item", "task-open", "template-task", {
						"first-template-task": start_with
					})}
					link={() => setState({ ...state, isOpen: true })}
					heading={name}
					subheading={_.get(role, "name")}
					// @ts-ignore
					datapoints={[
						{ key: "length", value: _.get(task, "length", "0") },
						(!start_with as any) && ({ key: "dependencies", value: _.get(task, "dependencies.length", "0") } as any),
						{ key: "conditions", value: _.get(task, "conditions.length", "0") }
					]}
				/>
				<Modal
					task={task}
					isOpen={isOpen}
					phase={phase}
					template={template}
					deleteTask={(task: any) => {
						setState({ ...state, isOpen: false });
						onDelete(task);
					}}
					onUpdate={(task: any) => {
						setState({ ...state, isOpen: false });
						onUpdate(task);
					}}
					doCloseModal={() => setState({ ...state, isOpen: false })}
				/>
			</React.Fragment>
		);
	}
	return null;
};

export default memo(Task);
