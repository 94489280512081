import Icon from "components/Activities/Icon";
import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { AcceptCondition, DismissCondition, RejectCondition } from "../Items";
import UndoCondition from "../Items/UndoCondition";
import ActivityItemDom from "./ActivityItemDom";

interface Props {
	activity: tcpinpoint.ActivityItem;
}

const ActivityConditionItem = ({ activity }: Props) => {
	const props = { key: activity.attributes.item.id, activity };
	let link = `/tasks/${_.get(activity.attributes.item.context, "id")}`;
	let output;
	let icon;

	switch (activity.attributes.item.type) {
		case "Reject":
			icon = Icon("reject-condition");
			output = <RejectCondition {...props} />;
			break;
		case "Accept":
			icon = Icon("accept-condition");
			output = <AcceptCondition {...props} />;
			break;
		case "Undo":
			icon = Icon("undo-condition");
			output = <UndoCondition {...props} />;
			break;
		case "TentativeReject":
			icon = Icon("dismissed-condition");
			link = `/tasks/${_.get(activity.attributes.item.subject, "id")}`;
			output = <DismissCondition {...props} />;
			break;
	}

	return (
		<Link to={link} className="activity-item-link">
			{" "}
			<ActivityItemDom activity={activity} icon={icon} link={link} output={output} />
		</Link>
	);
};

export default memo(ActivityConditionItem);
