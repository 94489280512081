import "whatwg-fetch";
import React from "react";
import QRCode from "qrcode.react";
import validate from "validate.js";
import { Analytics } from "aws-amplify";
import { Link, Redirect } from "react-router-dom";
import { Button, LoadingState, Text, ErrorBlock, Subtitle, Title } from "components/Shared";
import { Auth } from "aws-amplify";
import { Input } from "components/Inputs";
import config from "config.js";
import awsconfig from "aws-exports.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { translate } from "lib";
import classNames from "classnames";

const OAuthURI = provider =>
	`https://${awsconfig.Auth.oauth.domain}/oauth2/authorize?redirect_uri=${
		awsconfig.Auth.oauth.redirectSignIn
	}&response_type=${awsconfig.Auth.oauth.responseType}&client_id=${
		awsconfig.Auth.userPoolWebClientId
	}&identity_provider=${provider}&scope=${awsconfig.Auth.oauth.scope.join(" ")}`;

const OAuthButton = ({ provider }) => (
	<a className={classNames("btn", "btn-secondary", `btn-${provider.toLowerCase()}`)} href={OAuthURI(provider)}>
		{provider !== "Okta" && <FontAwesomeIcon icon={["fab", provider.toLowerCase()]} />}
		{provider}
	</a>
);

export default class SignIn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			email: "",
			identityProviders: [],
			password: "",
			two_factor_uri: "",
			errors: false,
			user: false,
			state: "okay"
		};
	}

	componentDidMount() {
		const { api } = config;
		localStorage.setItem("cached_new_user", {});
		fetch(api)
			.then(() => {})
			.catch(() => {
				fetch("https://google.com")
					.then(() => this.setState({ state: "api_offline" }))
					.catch(() => this.setState({ state: "no_connection" }));
			});
	}

	authenticate2FA() {
		const { user, verification } = this.state;
		Auth.confirmSignIn(user, verification, "SOFTWARE_TOKEN_MFA")
			.then(() => this.setState({ loading: false, verification: "", state: "authenticated" }))
			.catch(errors =>
				this.setState({
					loading: false,
					verification: "",
					errors: { verification: [errors.message] }
				})
			);
	}

	enable2FA() {
		const { user, verification } = this.state;
		Auth.verifyTotpToken(user, verification)
			.then(() => {
				Auth.setPreferredMFA(user, "TOTP");
				this.setState({ state: "two_factor_enabled" });
			})
			.catch(errors => this.setState({ loading: false, verification: "", errors: { verification: [errors.message] } }));
	}

	onSubmit(event) {
		event.preventDefault();
		const { email, password } = this.state;
		const constraints = {
			password: {
				presence: true
			},
			email: {
				presence: true
			}
		};
		const errors = validate({ email, password }, constraints);
		if (!errors) {
			this.setState({ loading: true }, () => {
				Auth.signIn(email, password)
					.then(user => {
						if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
							this.setState({ state: "two_factor_challenge" });
						} else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
							Auth.completeNewPassword(user, password).then(user => this.setState({ state: "authenticated" }));
						} else {
							Analytics.record({ name: "_userauth.sign_in" });
							this.setState({ state: "authenticated" });
							window.location.href = this.props.fromUrl || "/";
							// Auth.setupTOTP(user).then(code => this.setState({ two_factor_code: code, state: "two_factor_setup" }));
						}
						this.setState({ user, password: "" });
					})
					.catch(errors =>
						this.setState({ loading: false, password: "", state: errors.code, errors: { email: [errors.message] } })
					);
			});
		} else {
			this.setState({ errors });
		}
	}

	render() {
		const { state, loading, errors, identityProviders, email, two_factor_code } = this.state;
		if (!!loading) {
			return (
				<LoadingState>
					<p className="lead">{translate("authentication_success")}</p>
				</LoadingState>
			);
		}
		if (state === "two_factor_setup") {
			return (
				<React.Fragment>
					<Title>{translate("two_factor_auth_heading")}</Title>
					<Text>{translate("two_factor_auth_body")}</Text>
					<QRCode
						renderAs="svg"
						value={`otpauth://totp/AWSCognito:${email}?secret=${two_factor_code}&issuer=tcpinpoint`}
					/>
					<Input
						defaultState={this.state}
						name="verification"
						onChange={verification => this.setState({ verification })}
					/>
					<Button onClick={() => this.enable2FA()} />
					<hr />
					<Button onClick={() => this.setState({ state: "two_factor_skipped" })}>{translate("skip_for_now")}</Button>
				</React.Fragment>
			);
		} else if (state === "two_factor_challenge") {
			return (
				<React.Fragment>
					<Title>{translate("two_factor_challenge_heading")}</Title>
					<Text>{translate("two_factor_challenge_body")}</Text>
					<Input
						defaultState={this.state}
						name="verification"
						onChange={verification => this.setState({ verification })}
					/>
					<Button onClick={() => this.authenticate2FA()} />
				</React.Fragment>
			);
		} else if (state === "PasswordResetRequiredException") {
			return (
				<Redirect
					to={{
						pathname: "/password/reset",
						state: { from: "signin", resetRequired: true }
					}}
					push={true}
				/>
			);
		} else if (state === "UserNotFoundException") {
			return (
				<React.Fragment>
					<Title>{translate("user_not_found_heading")}</Title>
					<Text>{translate("user_not_found_body")}</Text>
					<Link className="btn btn-large btn-primary" to="/signup">
						{translate("sign_up")}
					</Link>
					<hr />
					<Text>{translate("need_help")}</Text>
				</React.Fragment>
			);
		} else if (state === "no_connection") {
			return (
				<React.Fragment>
					<Title>{translate("no_connection_heading")}</Title>
					<Text>{translate("no_connection_body")}</Text>
				</React.Fragment>
			);
		} else if (state === "api_offline") {
			return (
				<React.Fragment>
					<Title>{translate("api_offline_heading")}</Title>
					<Text>{translate("api_offline_body")}</Text>
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				<ErrorBlock {...{ errors }} />
				<Title>{translate("sign_in")}</Title>
				<form onSubmit={event => this.onSubmit(event)}>
					<Input
						defaultState={this.state}
						name="email"
						autoComplete="on"
						onChange={email => this.setState({ email })}
					/>
					<Input
						name="password"
						autoComplete="new-password"
						type="password"
						onChange={password => this.setState({ password })}
						defaultState={this.state}
					/>
					<Button type="submit" onClick={event => this.onSubmit(event)} />
				</form>
				{!!_.get(identityProviders, "length") && (
					<React.Fragment>
						<hr />
						<Subtitle> {translate("sign_in_with")}</Subtitle>
						<div className="identity_providers">
							{_.map(identityProviders, provider => (
								<OAuthButton key={provider} {...{ provider }} />
							))}
						</div>
					</React.Fragment>
				)}
				<hr />
				<Link to="/password/reset">{translate("forgotten_password")}</Link>
			</React.Fragment>
		);
	}
}
