import React, { useState } from 'react';

import SearchModal from "v2/components/Search/search";

const SearchComponent: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  return (
    <div className='search flex h-100 items-center'>
      <button onClick={handleOpenModal} className='border-b-3 border-transparent cursor-pointer flex flex-column h-100 hover:bg-transparent hover:border-blue-100 hover:text-blue-100 items-center justify-center px-4 text-black-60'>
        <i className="fal fa-search"></i>
        <div className="text-12 font-mont mt-1">Search</div>
      </button>
      {isModalOpen && (<SearchModal isOpen={isModalOpen} closeModal={handleCloseModal} />)}
    </div>
  );
};

export default SearchComponent;
