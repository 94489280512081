import React, { memo } from "react";
import { Greeting } from "components/Shared";
import { Link } from "react-router-dom";
import { translate } from "lib";
import _ from "lodash";
import { useAppClass } from "lib/hooks";

const Breadcrumb = ({ title, link }: { title?: string; link?: string }) => {
	if (!!link && !!title) {
		return (
			<li key={`item-${title}`} className="breadcrumb-item text-black-60">
				<Link to={link}>{translate(title)}</Link>
			</li>
		);
	}
	if (!!title) {
		return (
			<li key={`item-${title}`} className="breadcrumb-item text-black-60">
				{translate(title)}
			</li>
		);
	}
	return null;
};

const Breadcrumbs = ({ items = [] }: { items?: ({ link?: string; title: string } | false)[] }) => {
	useAppClass("with-breadcrumbs");
	return (
		<section id="breadcrumbs" className="breadcrumbs">
			<div className="breadcrumbs-items">
				<ol>
					<li className="breadcrumb-item text-black-60">
						<Link to="/">{translate("home")}</Link>
					</li>
					{_.map(
						_.filter(_.compact(_.flatten(items)), item => !!_.get(item, "title")),
						(item, index) => (
							<Breadcrumb title={item.title} link={item.link} key={`breadcrumb-${index}`} />
						)
					)}
				</ol>
			</div>
			<Greeting />
		</section>
	);
};

export default memo(Breadcrumbs);
