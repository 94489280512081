import _ from "lodash";
import { connect } from "react-redux";
import { postFacility } from "actions";
import { Row, Col, ListGroupItem, View, ListGroup } from "components/Shared";
import { Modal } from "components/Facilities";
import { Shape } from "components/Tenancies";
import { isFeatureToggled, whatIs, translate } from "lib";
import { UserContext } from "context";
import React from "react";

class Facilities extends React.Component {
	static contextType = UserContext;
	state = { modalOpen: false };

	render() {
		const { currentUser } = this.context;
		const { modalOpen } = this.state;
		const { resource, inspection, postFacility, facilities, canAdd = true } = this.props;
		const collection = _.sortBy(facilities, "name");
		let addFunction = false;
		if (canAdd && !inspection.closed_at) {
			addFunction = () => this.setState({ modalOpen: true });
		}
		return (
			<View>
				<Row>
					{!!_.get(resource, "shape") && isFeatureToggled("plans", currentUser) && (
						<Col>
							<Shape {...resource} />
						</Col>
					)}
					<Col>
						<ListGroup
							resource="facilities"
							collection={collection}
							renderRow={facility => (
								<ListGroupItem
									icon={
										!!_.find(facility.metadata, { key: "exists" }).value ? (
											<i className="fal fa-check-circle" />
										) : (
											<i className="fal fa-circle" />
										)
									}
									key={_.get(facility, "name")}
									link={`/facilities/${_.get(facility, "id")}`}
									heading={translate(_.get(facility, "name"))}
									datapoints={_.map(
										_.get(facility, "metadata"),
										y =>
											y.key !== "exists" && {
												key: translate(y.key),
												value: translate(y.value)
											}
									)}
								/>
							)}
							onAdd={addFunction}
						/>
						<Modal
							modalOpen={modalOpen}
							resource={resource}
							inspection={inspection}
							onUpdate={facility => postFacility(inspection, facility)}
							doCloseModal={() => this.setState({ modalOpen: false })}
						/>
					</Col>
				</Row>
			</View>
		);
	}
}

const mapStateToProps = ({ inspections }, { resource }) => {
	const parent = whatIs(_.get(resource, "link"));
	if (parent === "inspections") {
		return { resource, inspection: resource, facilities: _.get(resource, "facilities", []) };
	}
	if (parent === "tenancies") {
		const inspection = _.last(_.sortBy(_.filter(inspections, { inspectable_id: resource.id }), "closed_at"));
		return { resource, inspection, facilities: _.get(inspection, "facilities", []) };
	}
	return { resource };
};

const mapDispatchToProps = dispatch => ({
	postFacility: (inspection, facility) => dispatch(postFacility(inspection, facility))
});

export default connect(mapStateToProps, mapDispatchToProps)(Facilities);
