import { ListGroup, View } from "components/Shared";
import { Status } from "components/Shared/Status";
import React, { memo } from "react";
import { Legend } from "../../Portfolios/Legend";
import PhaseReportWorkflowRow from "./PhaseReportWorkflowRow";

const PhaseReport = ({ workflows, userProjects} : any) => {
    return (
        <>
            <View resource="project-phase-analytics-report" hideTitle>
                <div className="report-header">
                    <div className="status-legend">
                        <div>
                            <Status incomplete={true} />
                            <figcaption>Incomplete</figcaption>
                        </div>

                        <div>
                            <Status incomplete={false} />
                            <figcaption>Complete</figcaption>
                        </div>

                        <div>
                            <Status incomplete={false} closed={true} />
                            <figcaption>Closed Complete</figcaption>
                        </div>

                        <div>
                            <Status closed={true} />
                            <figcaption>Closed Incomplete</figcaption>
                        </div>
                        <div className="ahead">
                            <Legend type="spinner" color="#ddd" />
                            <figcaption>Phase Progress</figcaption>
                        </div>
                    </div>
                </div>

                <div className="report-body">
                    <ListGroup
                        resource="workflows"
                        pagination={false}
                        collection={workflows}
                        renderRow={(workflow: any) => (
                            <PhaseReportWorkflowRow
                                key={workflow.id}
                                workflow={workflow}
                                clickableLink={userProjects.includes(workflow.project_id)}
                            ></PhaseReportWorkflowRow>
                        )}
                    />
                </div>  
            </View>
        </>
    );
};

export default memo(PhaseReport);
