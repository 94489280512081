import _ from "lodash";
export default (state: tcpinpoint.Property[] = [], action: any) => {
	switch (action.type) {
		case "GET_PROPERTY_SUCCESS":
			return _.unionBy([action.resource as tcpinpoint.Property], state, "id");
		case "GET_PROPERTIES_SUCCESS":
			return _.unionBy(action.resource as tcpinpoint.Property[], state, "id");
		case "DELETE_PROPERTIES_SUCCESS":
			return _.filter(state, resource => resource.id !== action.resource.id);
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
