import classNames from "classnames";
import _ from "lodash";
import React, { memo } from "react";

const OpenGraph = ({ metadata, onClear }: { metadata: any; onClear?: () => void }) => {
	const { ogTitle, ogUrl, ogSiteName, twitterPlayer, ogVideo, ogImage, ogDescription } = metadata;

	if (!ogTitle) return null;

	let content;
	switch (ogSiteName) {
		case "Spotify":
			content = (
				<div className="media-responsive">
					<iframe
						title={ogTitle}
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						src={_.get(twitterPlayer, "url")}
					/>
				</div>
			);
			break;
		case "YouTube":
			content = (
				<div className="media-responsive">
					<iframe
						title={ogTitle}
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						src={_.get(ogVideo, "url")}
					/>
				</div>
			);
			break;
		default:
			if (_.get(ogImage, "url")) {
				content = (
					<a target="_blank" rel="noopener noreferrer" href={ogUrl}>
						<img alt={ogTitle} src={ogImage.url} />
					</a>
				);
			}
	}

	return (
		<div className={classNames("metadata", ogSiteName).toLowerCase()}>
			{content}
			<div>
				{!!ogSiteName && (
					<h6>
						{ogSiteName !== "tcpinpoint" && (
							<React.Fragment>
								<i className={`fab fa-${ogSiteName.toLowerCase()}`} />{" "}
							</React.Fragment>
						)}
						{ogSiteName}
					</h6>
				)}
				<h3>
					<a target="_blank" rel="noopener noreferrer" href={ogUrl}>
						{ogTitle}
					</a>
				</h3>
				{!!ogDescription && <p>{ogDescription}</p>}
			</div>
			{!!onClear && (
				<button type="button" className="close" aria-label="Close" onClick={onClear}>
					<span aria-hidden="true">×</span>
				</button>
			)}
		</div>
	);
};
export default memo(OpenGraph);
