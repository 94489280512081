import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import validate from "validate.js";
import uuidv4 from "uuid/v4";
import { Input, PlainTextInput } from "components/Inputs";
import { Button, ModalHeader } from "components/Shared";

const constraints = {
	name: {
		presence: true
	},
	kind: {
		presence: true
	}
};

export default class ConditionModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.setStateFromProps(props);
	}

	componentWillReceiveProps(nextProps) {
		this.setStateFromProps(nextProps);
	}

	setStateFromProps(nextProps) {
		if (nextProps.item) {
			const id = nextProps.item.id ? nextProps.item.id : uuidv4;
			const { errors, item } = nextProps;
			const { name, description, kind } = item;
			this.setState(
				Object.assign(
					{},
					{
						id,
						name,
						description,
						kind,
						errors
					}
				)
			);
		} else {
			this.setState({
				id: uuidv4(),
				name: "",
				description: "",
				kind: ""
			});
		}
	}

	doCloseModal() {
		const { doCloseModal } = this.props;
		if (doCloseModal) {
			this.setStateFromProps({});
			doCloseModal();
		}
	}

	onDelete(event) {
		const { onDelete } = this.props;
		event.preventDefault();
		if (onDelete) {
			onDelete(this.state.id);
			this.doCloseModal();
		}
	}

	isValid() {
		const data = Object.assign({}, this.state);
		const errors = validate(data, constraints);
		if (errors !== undefined) {
			this.setState({ errors });
			return false;
		}
		return true;
	}

	onSave(event) {
		event.preventDefault();
		const { onUpdate } = this.props;
		if (onUpdate && this.isValid()) {
			onUpdate(this.state);
			this.doCloseModal();
		}
	}

	render() {
		const { isOpen, doCloseModal } = this.props;
		return (
			<Modal
				isOpen={isOpen}
				onRequestClose={doCloseModal}
				className="condition-modal"
				contentLabel="condition"
				label="Condition"
			>
				<ModalHeader title="condition" onRequestClose={doCloseModal} />
				<section>
					<Input
						large
						required={constraints}
						hint="This will list the name of the document you want uploaded"
						limit={155}
						name="name"
						defaultState={this.state}
						onChange={name => this.setState({ name })}
					/>
					<Input
						name="kind"
						required={constraints}
						defaultState={this.state}
						onChange={kind => this.setState({ kind })}
						options={["document", "boolean", "date", "accept"]}
					/>
					<PlainTextInput
						hint="Do you need to tell the Role responsible anything specific about this document? E.g. 'Make sure this is an executed copy'"
						limit={300}
						name="description"
						defaultState={this.state}
						onChange={description => this.setState({ description })}
					/>
				</section>
				<footer>
					<Button onClick={event => this.onSave(event)} />
				</footer>
			</Modal>
		);
	}
}

ConditionModal.propTypes = {
	onUpdate: PropTypes.func,
	doCloseModal: PropTypes.func
};
