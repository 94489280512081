/* eslint arrow-body-style: ["error", "as-needed", { requireReturnForObjectLiteral: true }]*/

import React from "react";
import Select, { components } from "react-select";
import classNames from "classnames";

import { Feedback, Label } from "components/Inputs";

import _ from "lodash";
const Option = props => {
	const option = props.options.find(option => option.value === props.value);
	if (!option.item) {
		return false;
	}
	return (
		<components.Option {...props}>
			<div className="option-left">{_.get(option, "item.name")}</div>
		</components.Option>
	);
};

export default class Lookup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { name, tenants, className, children, required, defaultState, onChange } = this.props;
		if (!!_.get(tenants, "length")) {
			return (
				<div
					className={classNames("form-group", `form-control-${name}`, className, {
						className,
						"is-required": required && (required.tenant || required.tenant_id),
						"has-danger":
							defaultState && defaultState.errors && (defaultState.errors.tenant || defaultState.errors.tenant_id)
					})}
				>
					<Label {...this.props} name="tenant" />
					<Select
						name={name}
						autoFocus={false}
						closeMenuOnSelect={false}
						components={{ Option }}
						value={this.state.value}
						onChange={option => this.setState({ value: option }, () => onChange && onChange(option.item))}
						options={_.map(tenants, tenant => {
							return { item: tenant, value: tenant.id, label: tenant.name };
						})}
					/>
					<Feedback {...this.state} {...this.props} name="tenant" />
					{children && children}
				</div>
			);
		}
		return false;
	}
}
