import { Show as Avatar } from "components/Avatar";
import { Collection as Buildings } from "components/Buildings";
import { CardDynamic as Documents } from "components/Documents";
import Projects from "components/Projects/CollectionDynamic";
import { Dashboard } from "components/Properties";
import { Breadcrumbs, Header, LoadingState, Workspace } from "components/Shared";
import { Collection as Tenancies } from "components/Tenancies";
import { Collection as Users } from "components/Users";
import { ResourceContext, UserContext } from "context";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import { isFeatureToggled } from "lib";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import TodoTab from "../Shared/TodoTab";

const Property = (props: RouteComponentProps) => {
	const { match, history } = props;
	const { currentUser } = useContext(UserContext) as { currentUser: tcpinpoint.User };
	const id = _.get(match, "params.id");
	const [property, setProperty] = useState({} as any);
	const [projects, setProjects] = useState([] as any[]);
	const [tenancies, setTenancies] = useState([] as any[]);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "property", renderTrigger: renderTrigger }));
	const [projectDataSource] = useState(new Datasource({ mainModelName: "project", renderTrigger: renderTrigger }));
	const [tenancyDataSource] = useState(new Datasource({mainModelName: "tenancy", renderTrigger: renderTrigger}));
	const tabQueryStringParameters = useMemo(() => ({ property_id: id }), [id]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) setProperty(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource, setProperty]);
	
	// API call
	useEffect(() => {
		if (!_.isNil(id))
			datasource.get("v2", `properties/${id}`, {
				queryStringParameters: {
					fields: JSON.stringify({
						addresses: ["street_1", "street_2", "locality_name", "region_iso_alpha"],
						buildings: ["name", "area", "precinct", "shape", "tenancies"],
						users: ["firstname", "surname", "name", "email", "phone_number"],
					})
				}
			});
	}, [id, datasource]);

	useEffect(() => {
		let sub = projectDataSource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) setProjects(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [projectDataSource, setProjects]);
	
	// API call
	useEffect(() => {
		if (!_.isNil(id))
			projectDataSource.get("v2", "projects", {
				queryStringParameters: {
					property: id,
					per_page: -1
				}
			});
	}, [id, projectDataSource]);

	useEffect(() => {
		let sub = tenancyDataSource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) setTenancies(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [tenancyDataSource, setTenancies]);
	
	// API call
	useEffect(() => {
		if (!_.isNil(id))
			tenancyDataSource.get("v2", "tenancies/index_with_joins", {
				queryStringParameters: {
					property_id: id,
					per_page: -1
				}
			});
	}, [id, tenancyDataSource]);	

	if (!_.isEmpty(property)) {
		const { id, name, avatar, addresses } = property;
		property.tenancies = tenancies;
		const isAdministrator = _.includes(_.map(property.users, "id"), currentUser.id);
		const projectIds = projects.map(p => p.id);
		return (
			<ResourceContext.Provider value={property}>
				<Breadcrumbs
					key="property-breadcrumbs"
					items={[{ link: "/properties", title: "properties" }, { title: name }]}
				/>
				<Header
					resource={property}
					stamp={<Avatar url={avatar} name={name} />}
					title={name}
					subtitle={!!addresses && _.compact(_.map(addresses, a => _.get(a, "locality.name"))).join("")}
					toolbar={[
						{
							onClick: () => history.push(`/projects/new?property_id=${id}`),
							label: "start_new_project",
							icon: "new"
						}
					]}
				/>
				<Workspace
					id={id}
					className={["show", "property"]}
					key="property-main"
					tabs={[
						<Projects
							name="projects"
							key="property-projects"
							queryStringParameters={tabQueryStringParameters}
						/>,
						<Buildings
							name="buildings"
							key="property-buildings"
							property={property}
							buildings={property.buildings as tcpinpoint.Building[]}
						/>,
						!!isFeatureToggled("tenancies_tab", currentUser) && (<Tenancies name="tenancies" key="property-tenancies" property={property} projectIds={projectIds} />),
						<Documents
							name="documents"
							key="property-documents"
							resource={property}
							viewBy="grid"
							upload={isAdministrator}
							queryStringParameters={tabQueryStringParameters}
							visibleTo={`All users on projects at ${property.name} can view and download these documents`}
						/>,
						<Users name="parties" key="property-team" users={property.users} isAdministrator={isAdministrator} />,
						<Dashboard name="dashboard" key="property-dashboard" property={property} />,
						<TodoTab name="ToDo" key="todo-tab" propertyId={id} users={property.users} todoKind="property" />,
					]}
				/>
			</ResourceContext.Provider>
		);
	}
	return <LoadingState />;
};

export default withRouter(Property);
