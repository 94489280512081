import React from "react";
import Modal from "react-modal";
import validate from "validate.js";
import { Input, PlainTextInput } from "components/Inputs";
import { Button, ModalHeader } from "components/Shared";
import _ from "lodash";
import classNames from "classnames";

const constraints = {
	name: {
		presence: true
	}
};

export default class PhaseModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(nextProps.phase);
	}

	onUpdate(event) {
		const { onUpdate, doCloseModal } = this.props;
		const errors = validate(this.state, constraints);

		if (errors) {
			this.setState({ errors });
		} else {
			onUpdate && onUpdate(this.state);
			doCloseModal && doCloseModal();
		}
	}

	render() {
		const { isOpen, doCloseModal, onDelete, tasks } = this.props;
		const colors = ["#e6261f", "#eb7532", "#f7d038", "#a3e048", "#49da9a", "#34bbe6", "#4355db", "#d23be7"];
		return (
			<Modal isOpen={isOpen} onRequestClose={doCloseModal} className="phase-edit-modal" contentLabel="phase Edit Modal">
				<ModalHeader title="phase" onRequestClose={doCloseModal} />
				<section>
					<Input
						defaultState={this.state}
						required={constraints}
						name="name"
						large
						onChange={name => this.setState({ name })}
					/>

					<div id="form-group-color" class="form-group form-group-undefined form-group-color">
						<label for="form-control-input-color">
							<span>Color</span>
						</label>
						<div class="input-group" id="form-control-input-group-color">
							{_.map(colors, color => (
								<button
									key={color}
									onClick={() => this.setState({ color })}
									style={{ backgroundColor: color }}
									className={classNames(color, { active: color === this.state.color })}
								/>
							))}
						</div>
					</div>
					<PlainTextInput
						defaultState={this.state}
						required={constraints}
						limit={300}
						name="description"
						onChange={description => this.setState({ description })}
					/>
				</section>
				<footer>
					<Button onClick={() => this.onUpdate()} />
					{!tasks.length && <Button kind="trash" confirm onClick={() => onDelete()} />}
				</footer>
			</Modal>
		);
	}
}
