import React from "react";
import { Feedback, Label } from "components/Inputs";
import { Tags } from "components/Shared";
import classNames from "classnames";

export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      remaining: props.limit,
      ignoreError: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let value = "";
    if (nextProps.defaultState && nextProps.name) {
      value = nextProps.defaultState[nextProps.name];
    }
    this.setState({ value });
  }

  onUpdate() {
    const value = this.textInput.value;
    const { limit, onChange } = this.props;
    const valueLength = value ? value.length : 0;
    const remaining = parseInt(limit, 10) - valueLength;
    this.setState({ remaining });
    if (onChange) {
      onChange(value);
    }
  }

  autoComplete() {
    if (!this.props.autoComplete) {
      return "off";
    }
    if (this.props.name === "firstname") {
      return "given-name";
    } else if (this.props.name === "surname") {
      return "family-name";
    } else if (this.props.type === "email") {
      return "email";
    } else if (this.props.type === "password") {
      return "new-password";
    } else if (this.props.type === "tel") {
      return "tel";
    }
    return "off";
  }

  render() {
    const {
      required,
      name,
      prefix,
      suffix,
      children,

      limit,
      defaultState,
      large,
      className
    } = this.props;
    const { remaining, ignoreError } = this.state;

    return (
      <div
        className={classNames("form-group", `form-control-${name}`, className, {
          large,
          "has-warning": limit && remaining <= 0,
          "is-required": required && required[name],
          "has-danger": !ignoreError && defaultState && defaultState.errors && defaultState.errors[name]
        })}
      >
        <Label {...this.props} />
        <div className="input-group">
          {prefix && <div className="input-group-addon">{prefix}</div>}
          <Tags tags={defaultState[name]} onChange={tags => this.onUpdate(tags)} />
          {suffix && <div className="input-group-addon">{suffix}</div>}
        </div>
        <Feedback {...this.state} {...this.props} />

        {limit && (
          <div className="input-limit">
            {remaining > 0 ? remaining : 0}/{limit}
          </div>
        )}
        {children && <p className="form-control-child">{children}</p>}
      </div>
    );
  }
}
