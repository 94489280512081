/* eslint arrow-body-style: ["error", "as-needed", { requireReturnForObjectLiteral: true }]*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Feedback } from "components/Inputs";
import { connect } from "react-redux";
import { ListGroup, ListGroupControl, ListGroupItem, LoadingState } from "components/Shared";
import { Icon } from "components/Templates";
import { getTemplates } from "actions";
import _ from "lodash";
import moment from "moment";

class Lookup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: "name",
			filteredBy: "all"
		};
	}

	componentDidMount() {
		const { getTemplates } = this.props;
		getTemplates();
	}

	render() {
		const { name, templates, className, children, required, defaultState, onChange } = this.props;

		const { sortBy, filteredBy } = this.state;

		const filterFunction = template =>
			filteredBy === "all" ||
			(filteredBy === "workflow-template" && _.get(template, "kind") === "workflow") ||
			(filteredBy === "commercial-template" && _.get(template, "kind") === "commercial") ||
			(filteredBy === "retail-template" && ~_.includes(_.get(template, "tags", []), "retail")) ||
			(filteredBy === "recent" && moment(_.get(template, "updated_at")).isAfter(moment().subtract(1, "week")));

		const collection = _.sortBy(
			_.map(templates || [], template => {
				const { phases } = _.get(template, "body", []);
				const tasks = _.flatten(_.map(phases, phase => phase.tasks));
				const startTask = _.find(tasks, task => !!task.start_with);
				const endTask = _.find(tasks, task => !!task.end_with);
				return _.assign({}, template, { startTask, endTask });
			}),
			sortBy
		)
			.filter(template => !!_.get(template, "startTask"))
			.filter(template => _.get(template, "kind") !== "facilities")
			.filter(filterFunction);

		if (!!collection.length) {
			return (
				<div
					className={classNames("form-group", `form-control-${name}`, className, {
						className,
						"is-required": required && (required.template || required.template_id),
						"has-danger":
							defaultState && defaultState.errors && (defaultState.errors.template || defaultState.errors.template_id)
					})}
				>
					<ListGroupControl
						collection={templates}
						onSortChange={sortBy => this.setState({ sortBy })}
						selectedSort={sortBy}
						sortOptions={["name", "updated_at", "created_at"]}
						filterOptions={["all", "recent", "workflow-template", "retail-template", "commercial-template"]}
						onFilterChange={filteredBy => this.setState({ filteredBy })}
						selectedFilter={filteredBy}
					/>
					<ListGroup
						resource={"templates"}
						collection={collection}
						pagination={true}
						renderRow={template => (
							<ListGroupItem
								icon={<Icon tags={_.get(template, "tags")} />}
								key={_.get(template, "id")}
								className={_.get(template, "kind")}
								link={() => _.get(template, "startTask") && onChange(template)}
								heading={_.get(template, "name")}
								subheading={_.get(template, "kind") && `${_.get(template, "kind")} Template`}
							/>
						)}
					/>
					<Feedback {...this.state} {...this.props} name="template" />
					{children && children}
				</div>
			);
		}
		return <LoadingState />;
	}
}

const mapStateToProps = ({ templates, activity, session }) => {
	return {
		templates,
		activity,
		session
	};
};

const mapDispatchToProps = dispatch => {
	return { getTemplates: () => dispatch(getTemplates()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lookup);

Lookup.defaultProps = {
	onUpdate() {},
	name: "text",
	type: "text",
	className: "",
	create: false,
	multi: false,
	placeholder: "",
	defaultState: {
		errors: {}
	}
};

Lookup.propTypes = {
	onChange: PropTypes.func,
	collection: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	allowCreate: PropTypes.bool,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	create: PropTypes.bool,
	multi: PropTypes.bool,
	optionRenderer: PropTypes.func,
	defaultState: PropTypes.shape({
		errors: PropTypes.shape({})
	})
};
