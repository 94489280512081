import React from "react";
import { translate } from "lib";
import { ErrorBoundary } from "components/Shared";
import ItemObject from "components/Activities/ItemObject"

const RemindTask = ({ activity }: { activity: tcpinpoint.ActivityItem }) => <ErrorBoundary>
    <ItemObject object={activity.attributes.item.object} />{" "}
    {translate('is_due_soon')}
</ErrorBoundary>

export default RemindTask;