import React, { memo } from "react";
import { View, ErrorBoundary } from "components/Shared";
import { CardDynamic } from "components/Documents";

const Panel = ({ context, folder }) => (
  <ErrorBoundary>
    <View resource="documents-panel" hideTitle key={`${context && context.id}-${folder && folder.id}`}>
      {context && <h4>{context.name}</h4>}
      {folder && <h3>{folder.name}</h3>}
      {folder.documents && (
        <CardDynamic documents={folder.documents} download={true} upload={false} hideTitle={true} hideEmpty={false} />
      )}
    </View>
  </ErrorBoundary>
);

export default memo(Panel);
