import React, { memo } from "react";
import _ from "lodash";

const Thumbnail = (props: any) => {
  const { document } = props;
  if (document) {
    const { revisions } = document;
    const thumbnail =
      revisions &&
      revisions.length > 0 &&
      _.sortBy(revisions, "created_at")[0].thumbnail;
    if (thumbnail) {
      return (
        <div
          className="document-preview"
          style={{
            backgroundImage: `url(${thumbnail})`
          }}
        />
      );
    }
  }
  return null;
}

export default memo(Thumbnail);

// export default class Thumbnail extends React.Component {
//   constructor(props, context) {
//     super(props, context);
//     this.state = {};
//   }
//   render() {
//     const { document } = this.props;
//     if (document) {
//       const { revisions } = document;
//       const thumbnail =
//         revisions &&
//         revisions.length > 0 &&
//         _.sortBy(revisions, "created_at")[0].thumbnail;
//       if (thumbnail) {
//         return (
//           <div
//             className="document-preview"
//             style={{
//               backgroundImage: `url(${thumbnail})`
//             }}
//           />
//         );
//       }
//     }
//     return null;
//   }
// }
