import { getTenancy, getTenant } from "actions";
import { memoAC } from "./memoize";
import { batch } from "react-redux";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

/**
 * Delete a Lease
 * @param id Lease ID
 */
export const deleteLease = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "DELETE_LEASE_REQUEST" });
  axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}leases/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => dispatch({ type: "DELETE_LEASE_SUCCESS", response: { status: 200 }, resource: { id } }))
  .catch((e) => dispatch({ type: "DELETE_LEASE_FAILED", response: e }));
});

/**
 * Create a Lease
 * @param lease Lease
 */
export const postLease = memoAC((lease: any) => (dispatch: any) => {
  dispatch({ type: "POST_LEASE_REQUEST" });
  axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}leases`, { lease }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "POST_LEASE_SUCCESS", resource: res.data.lease });
    dispatch(getLease(res.data.lease.id));
  })
  .catch((e) => dispatch({ type: "POST_LEASE_FAILED", resource: lease, response: e }));
});

/**
 * Update a Lease
 * @param lease
 */
export const putLease = memoAC((lease: tcpinpoint.Lease) => (dispatch: any) => {
  dispatch({ type: "PUT_LEASE_REQUEST" });
  axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}leases/${lease.id}`, { lease }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    dispatch({ type: "PUT_LEASE_SUCCESS", resource: res.data.lease });
    dispatch(getLease(res.data.lease.id));
  })
  .catch((e) => dispatch({ type: "PUT_LEASE_FAILED", resource: lease, response: e }));
});

/**
 * Get a Lease
 * @param {uuid} id Lease ID
 */
export const getLease = memoAC((id: string) => (dispatch: any) => {
  dispatch({ type: "GET_LEASE_REQUEST" });
  axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}leases/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => {
    batch(() => {
      dispatch(getTenancy(res.data.lease.tenancy_id));
      dispatch(getTenant(res.data.lease.tenant_id));
    })
    dispatch({ type: "GET_LEASE_SUCCESS", resource: res.data.lease });
  })
  .catch((e) => dispatch({ type: "GET_LEASE_FAILED", resource: { id }, response: e }));
});

/**
 * Get Leases
 */
export const getLeases = memoAC(() => (dispatch: any) => {
  dispatch({ type: "GET_LEASES_REQUEST" });
  axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}leases`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromCookie()}`,
    }
  })
  .then((res) => dispatch({ type: "GET_LEASES_SUCCESS", resource: res.data.leases }))
  .catch((e) => dispatch({ type: "GET_LEASES_FAILED", resource: { id: "leases" }, response: e }));
});
