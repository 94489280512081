/* eslint arrow-body-style: ["error", "as-needed", { requireReturnForObjectLiteral: true }]*/
import "whatwg-fetch";
import React, {memo} from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import classNames from "classnames";
import { Feedback } from "components/Inputs";
import _ from "lodash";
import axios from "v2/utils/axios";

const Option = memo(props => {
  const option = props.options.find(option => option.value === props.value);
  if (!option.item) {
    return false;
  }
  return (
    <components.Option {...props}>
      <p>
        {_.get(option, "item.name")} ({_.get(option, "item.email")})
      </p>
    </components.Option>
  );
});

export default class UserLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  loadOptions(inputValue, callback) {
    setTimeout(() => {
      axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}users/search`, { params: { q: inputValue } })
        .then((res) => 
          callback(
            _.map(_.uniqBy(res.data.json, "id"), item => {
              return {
                value: item.id,
                label: `${item.name} (${item.email})`,
                item
              };
            })
          )
        );
    }, 1000);
  }

  render() {
    const { name, className, children, required, defaultState, onChange } = this.props;
    const { value } = this.state;
    return (
      <div
        className={classNames("form-group", name && `form-control-${name}`, className, {
          className,
          "is-required": required && (required.property || required.property_id),
          "has-danger":
            defaultState && defaultState.errors && (defaultState.errors.property || defaultState.errors.property_id)
        })}
      >
        <AsyncSelect
          name={name}
          autoFocus={false}
          className="react-select"
          components={{ Option }}
          value={value}
          onChange={(option, result) => onChange && onChange(option.item)}
          loadOptions={(input, callback) => this.loadOptions(input, callback)}
        />
        <Feedback {...this.state} {...this.props} />
        {children && children}
      </div>
    );
  }
}
