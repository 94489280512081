import classNames from "classnames";
import { ListGroup, ListGroupControl, LoadingState, ModalHeader, View } from "components/Shared";
import Pagination from "components/Shared/Tcp/Pagination";
import { LastMomentTriggerAPIContext } from "context";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useContext, useEffect, useState } from "react";
import Modal from "react-modal";

const OnePortfolioLine = ({ projectId, portfolio }: any) => {
	const { setLastMoment } = useContext(LastMomentTriggerAPIContext);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(
		new Datasource({
			mainModelName: "portfolio",
			renderTrigger: renderTrigger
		})
	);

	const isSelected = _.includes(_.map(portfolio.projects, "id"), projectId);

	const addProjectToPortfolio = (portfolioId: string) => {
		datasource
			.post(
				"v2",
				`portfolios/${portfolioId}/add-project`,
				{ queryStringParameters: { project_id: projectId } },
				{ updateIsLoding: true }
			)
			.then(() => setLastMoment(moment()))
			.catch();
	};

	const removeProjectFromPortfolio = (portfolioId: string) => {
		datasource
			.post(
				"v2",
				`portfolios/${portfolioId}/remove-project`,
				{ queryStringParameters: { project_id: projectId } },
				{ updateIsLoding: true }
			)
			.then(() => setLastMoment(moment()))
			.catch();
	};

	const onClick = (event: any) => {
		event.preventDefault();
		!!isSelected ? removeProjectFromPortfolio(portfolio.id) : addProjectToPortfolio(portfolio.id);
	};

	if (datasource.isLoading) return <LoadingState />;

	return (
		<>
			<a
				href="#link"
				onClick={onClick}
				data-heading={portfolio.name}
				className={classNames("list-group-item", {
					selected: isSelected
				})}
			>
				<span className="list-group-item-content">
					<div className="list-group-item-heading">
						<h3>{portfolio.name}</h3>
					</div>
				</span>
				<div className="list-group-item-statistics ml-auto">
					<h6>{moment(portfolio.created_at).format("DD MMM YYYY")}</h6>
				</div>
				<div className="disclosure">
					{!!isSelected ? <i className="fal fa-minus" /> : <i className="fal fa-plus" />}
				</div>
			</a>
		</>
	);
};

const AddToPortfolio = ({ isOpen, onRequestClose, projectId }: any) => {
	const [, renderTrigger] = useState(moment());
	const [lastMoment, setLastMoment] = useState(moment());
	const [portfolios, setPortfolios] = useState([] as any[]);
	const lastMomentProviderValue = { lastMoment, setLastMoment };
	const getSortAttribute = (sortBy: string) => (sortBy === "created_at" ? "-created_at" : sortBy);
	const [datasource] = useState(
		new Datasource({
			mainModelName: "portfolio",
			perPage: 20,
			currentPage: 1,
			sortBy: "name",
			renderTrigger: renderTrigger
		})
	);

	// subscribe datasource subject
	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) =>
			setPortfolios(response.normalizedMainModelResponse)
		);
		return () => sub.unsubscribe();
	}, [datasource]);

	// API call
	useEffect(() => {
		if (!isOpen) return;
		datasource.get("v2", "portfolios", {
			queryStringParameters: {
				sort: getSortAttribute(datasource.sortBy),
				per_page: datasource.perPage,
				page: datasource.currentPage,
				associations: JSON.stringify(["projects"])
			}
		});
	}, [datasource, datasource.currentPage, datasource.perPage, datasource.sortBy, lastMoment, isOpen]);

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isOpen}
			className="archive"
			onRequestClose={onRequestClose}
			contentLabel="close-project"
		>
			<ModalHeader title="add_portfolio" onRequestClose={onRequestClose} />
			<section>
				<View resource="portfolios" hideTitle={true}>
					{datasource.isFirstTimeLoading ? (
						<LoadingState />
					) : (
						<>
							<ListGroupControl
								sortOptions={["name", "created_at"]}
								onSortChange={newSortBy => {
									datasource.setCurrentPage(1);
									datasource.setSortBy(newSortBy);
								}}
								selectedSort={datasource.sortBy}
								selectedView={"list"}
							/>
							<LastMomentTriggerAPIContext.Provider value={lastMomentProviderValue}>
								<ListGroup
									resource="portfolios"
									collection={portfolios}
									pagination={false}
									renderRow={portfolio => (
										<OnePortfolioLine key={portfolio.id} projectId={projectId} portfolio={portfolio} />
									)}
								/>
							</LastMomentTriggerAPIContext.Provider>

							<div className={classNames("margin-atuo", "flex justify-center")}>
								<Pagination datasource={datasource} />
							</div>
						</>
					)}
				</View>
			</section>
		</Modal>
	);
};
export default memo(AddToPortfolio);
