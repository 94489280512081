import React, { useEffect, useState, memo } from "react";
import _ from "lodash";
import { useDispatch, useSelector, batch, shallowEqual } from "react-redux";
import { getProjectWorkflows } from "actions";
import { View } from "components/Shared";
import { CollectionDynamic } from "components/Documents";
import classNames from "classnames";
import { translate } from "lib";

const Documents = ({ project }: { name: String; project: tcpinpoint.Project }) => {
	const dispatch = useDispatch();
	// const [documents, setDocuments] = useState([])
	const [resource, setResource] = useState<any>();

	useEffect(() => {
		if (project) {
			batch(() => {
				dispatch(getProjectWorkflows(project.id));
				// dispatch(getDocumentsFor({ id: project.company_id, link: "gid://tcpinpoint/Company/" + project.company_id }));
			});
		}
	}, [dispatch, project]);

	const activity = useSelector((store: tcpinpoint.Store) => store.activity, shallowEqual);
	const documents = useSelector((store: tcpinpoint.Store) => store.documents, shallowEqual);
	const company = useSelector(
		(store: tcpinpoint.Store) => _.find(store.companies, { id: project.company_id }),
		shallowEqual
	);
	const property = useSelector(
		(store: tcpinpoint.Store) => _.find(store.properties, { id: project.property_id }),
		shallowEqual
	);
	const workflow = useSelector(
		(store: tcpinpoint.Store) => _.first(_.filter(store.workflows, { project_id: project.id })),
		shallowEqual
	);

	let collectionDynamic;
	if(_.includes(_.get(resource, "link"), "Property")) {
		collectionDynamic = <CollectionDynamic queryStringParameters={{property_id: _.get(resource, "id")}}  />
	} else if(_.includes(_.get(resource, "link"), "Task")) {
		collectionDynamic = <CollectionDynamic queryStringParameters={{task_id: _.get(resource, "id")}}  />
	}
	return (
		<View resource="project-documents" hideTitle={true}>
			<div className="project-documents-resources">
				<ul>
					{project && (
						<li className={classNames({ selected: project === resource })}>
							{/* <i className="fal fa-fw fa-angle-down" /> */}
							<button onClick={() => setResource(project)}>{project.name}</button>
							<ul>
								{_.map(_.get(workflow, "phases", []), phase => (
									<li className={classNames({ selected: resource === phase })} key={phase.id}>
										{/* <i className="fal fa-fw fa-angle-down" /> */}
										{phase.name}
										<ul>
											{_.map(phase.tasks, task => {
												const allDocumentsCount = _.get(task, "all_documents_count", 0);
												return (
													<li className={classNames({ selected: task === resource })} key={task.id}>
														{/* <i className="fal fa-fw fa-angle-right" /> */}
														<button onClick={() => setResource(task)}>{task.name}</button>
														<span>
															{!activity.DOCUMENTS ? (
																!!allDocumentsCount && allDocumentsCount
															) : (
																<i className="fal fa-spin fa-circle-notch" />
															)}
														</span>
													</li>
												);
											})}
										</ul>
									</li>
								))}
							</ul>
						</li>
					)}
					<hr />
					{company && (
						<li>
							<button onClick={() => setResource(company)}>{company.name}</button>
							<span>
								{!activity.DOCUMENTS ? (
									_.filter(documents, { documentable_id: _.get(company, "id") }).length
								) : (
									<i className="fal fa-spin fa-circle-notch" />
								)}
							</span>
						</li>
					)}
					{property && (
						<li>
							<button onClick={() => setResource(property)}>{property.name}</button>
							<span>
								{!activity.DOCUMENTS ? (
									_.get(property, "documents_count", 0)
								) : (
									<i className="fal fa-spin fa-circle-notch" />
								)}
							</span>
						</li>
					)}
				</ul>
			</div>

			{resource && (
				<div className="project-documents-documents">
					<section className="breadcrumbs">
						<div className="breadcrumbs-items">
							<ol>
								{_.includes(resource.link, "Task") ? (
									<React.Fragment>
										<li className="breadcrumb-item">{_.get(project, "name")}</li>
										<li className="breadcrumb-item">
											{_.get(_.find(_.get(workflow, "phases", []), { id: resource.phase_id }), "name")}
										</li>
										<li className="breadcrumb-item">{_.get(resource, "name")}</li>
									</React.Fragment>
								) : (
									<li className="breadcrumb-item">{_.get(resource, "name")}</li>
								)}
								{!!resource && <li className="breadcrumb-item">{translate("documents")}</li>}
							</ol>
						</div>
					</section>
					{collectionDynamic}
				</div>
			)}
		</View>
	);
};

export default memo(Documents);
