import _ from "lodash";
export default (state: tcpinpoint.User[] = [], action: any) => {
    const {type, resource} = action;
	switch (type) {
		case "GET_TASK_USERS_SUCCESS":
        case "GET_TASK_SPECTATORS_SUCCESS":
			return _.unionBy(resource as tcpinpoint.User[], state, "id");
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};