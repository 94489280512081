import React from "react";
import validate from "validate.js";
import { Input } from "components/Inputs";
import { Button, View, ListGroup, ListGroupItem } from "components/Shared";
import uuidv4 from "uuid/v4";
import _ from "lodash";

const constraints = {
  quote: {
    presence: true
  }
};

export default class Quotes extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      quote: ""
    };
    this.input = React.createRef();
  }

  addQuote() {
    if (validate(this.state, constraints) === undefined) {
      const { quote } = this.state;
      const { onUpdate, ledger } = this.props;
      const quotes = _.get(ledger, "quotes");
      quotes.push({ name: quote, id: uuidv4(), index: quotes.length });
      this.input.current.value = "";
      onUpdate(quotes);
    } else {
      this.setState({ errors: validate(this.state, constraints) });
    }
  }

  removeQuote(quote) {
    const { onUpdate, ledger } = this.props;
    const newQuotes = _.filter(_.get(ledger, "quotes"), q => q.name !== quote.name);
    onUpdate(newQuotes);
  }

  render() {
    const { ledger } = this.props;
    return (
      <React.Fragment>
        <View resource="quotes">
          <ListGroup
            collection={_.get(ledger, "quotes", [])}
            resource={"quotes"}
            renderRow={quote => (
              <ListGroupItem
                icon="quote"
                key={quote.id}
                onRemove={() => this.removeQuote(quote)}
                heading={quote.name}
              />
            )}
          />
        </View>
        <View resource="add_quote">
          <Input
            name="quote"
            required={constraints}
            ref={this.input}
            limit={155}
            defaultState={this.state}
            onChange={quote => this.setState({ quote })}
          />
          <Button kind="add" onClick={() => this.addQuote()} />
        </View>
      </React.Fragment>
    );
  }
}
