import _ from "lodash";
export default (state: tcpinpoint.Inspection[] = [], action: any) => {
	switch (action.type) {
		case "GET_INSPECTION_SUCCESS":
			return _.unionBy([action.resource], state, "id");
		case "GET_INSPECTIONS_SUCCESS":
			return _.unionBy(action.resource as tcpinpoint.Inspection[], state, "id");
		case "CLEAR_SESSION":
			return [];
		default:
			return state;
	}
};
