import React, { memo } from "react";
import { Board, Button, Help } from "v2/components/shared";
import classNames from "classnames";
import icons from "v2/utils/icon";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {
	email?: string;
}

const ExistUser = ({ email, history }: Props) => {
	return (
		<Board className="min-h-70 lg:min-h-0 lg:min-w-9 lg:w-1/4 lg:max-w-screen-xl">
			<div className="py-16 w-3/4 mx-auto lg:py-15">
				<div className="text-center">
					<div className="text-30 leading-11 text-black-100 font-semibold">Have we met before?</div>
					<div className="text-16 leading-6 my-4" style={{ color: "black" }}>
						An account already exists for
					</div>
					<div className="text-black-100 text-16 leading-6">{email}</div>
				</div>

				<Button
					label="Go to Sign In"
					className={classNames(
						"bg-blue-100 text-white",
						"hover:shadow-hover shadow-button",
						"mt-16 mb-15 lg:mb-12  mx-auto ",
						"w-32"
					)}
					onClick={() => history.push(`/signin?email=${email}`)}
				>
					{icons("arrow", "w-4 ml-1")}
				</Button>
				<Help />
			</div>
		</Board>
	);
};

export default memo(withRouter(ExistUser));
