import React, { useEffect, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import _ from "lodash";
import { translate } from "lib";
import options from "./default";
import axios from "v2/utils/axios";

export default ({ name, className, children, inspection, onChange }) => {
	const [template, setTemplate] = useState(options);

	useEffect(() => {
		const fetch = async () => {
			const templates = _.filter(await axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}/templates?kind=inspection&tag=${inspection.kind}`), _i =>
				_.includes(_i.tags, inspection.kind)
			);
			if (!!templates.length) {
				setTemplate(_.first(templates));
			}
		};
		fetch();
	}, [inspection.kind]);
	return (
		<div className={classNames("form-group", `form-control-${name}`, className)}>
			<Select
				name={name}
				autoFocus={false}
				classNamePrefix="react-select"
				onChange={(option, _result) => onChange(option.item)}
				options={_.map(_.sortBy(template, "slug"), option => ({
					value: _.get(option, "slug"),
					label: translate(_.get(option, "slug")),
					item: option
				}))}
			/>
			{children}
		</div>
	);
};
