import React from "react";
import { ListGroup, ListGroupItem } from "components/Shared";
import Autocomplete from "react-google-autocomplete";
import { decomposeGoogleAddress, translate } from "lib";
import _ from "lodash";
import axios from "v2/utils/axios";

export default class AddressInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = { addresses: _.get(props.resource, "addresses", []) };
	}

	componentDidMount() {
		this.getAddresses();
	}

	getAddresses() {
		const { resource } = this.props;
		if (!!resource) {
			const { resource } = this.props;
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}addresses?addressable_id=${resource.id}`)
				.then((res) => this.setState({ addresses: res.data.addresses }));
		}
	}

	addAddress(place) {
		const { onChange, resource } = this.props;
		const { addresses } = this.state;
		if (!!place) {
			const address = decomposeGoogleAddress(place);
			if (!!onChange) {
				addresses.push(address);
				this.setState({ addresses }, onChange(addresses));
			} else {
				axios.post(`${process.env.REACT_APP_API_ENDPOINT_V1}addresses`, { address: _.assign({}, address, { addressable: resource.link }) })
					.then((res) => this.getAddresses());
			}
		}
	}

	removeAddress({ id }) {
		const { onChange } = this.props;
		let { addresses } = this.state;
		if (!!onChange) {
			addresses = _.filter(addresses, address => address.id !== id);
			this.setState({ addresses }, onChange(addresses));
		} else {
			axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}addresses/${id}`)
				.then((res) => this.getAddresses());
		}
	}

	render() {
		const { addresses } = this.state;
		return (
			<div id="form-group-addresses" className="form-group form-group-address">
				<label>{translate("addresses")}</label>
				<ListGroup
					resource="address"
					collection={addresses}
					renderRow={address => (
						<ListGroupItem
							heading={_.get(address, "street_1")}
							subheading={_.get(address, "locality")}
							key={_.get(address, "name")}
							onClose={() => this.removeAddress(address)}
						/>
					)}
				>
					<Autocomplete
						onPlaceSelected={place => this.addAddress(place)}
						types={["address"]}
						componentRestrictions={{ country: "au" }}
					/>
				</ListGroup>
			</div>
		);
	}
}
