import classNames from "classnames";
import ActivityTab from "components/Activities/ActivityDynamic";
import { List as ConditionTab } from "components/Conditions";
import { Breadcrumbs, Header, LoadingState, Status, Workspace } from "components/Shared";
import { Edit, Notify, Override } from "components/Tasks";
import { LastMomentTriggerAPIContext, ProjectContext, ResourceContext } from "context";
import Datasource, { DatasourceActionType, DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import "moment-business-days";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import CommentTab from "./Show/CommentTab";
import TeamMemberTab from "./Show/TeamMemberTab";
import InsightsTab from "./TaskShow/InsightsTab";

const Task = (props: RouteComponentProps) => {
	const { match } = props;
	const id: string = _.get(match, "params.id");

	const [lastMoment, setLastMoment] = useState(moment());
	const lastMomentProviderValue = { lastMoment, setLastMoment };

	const [task, setTask] = useState({} as any);
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "task", renderTrigger: renderTrigger }));

	const isAdministrator = useMemo(() => _.get(task, "is_administrator", false), [task]);
	const isResponsible = useMemo(() => _.get(task, "is_responsible", false), [task]);
	const globalId = useMemo(() => _.get(task, "global_id", null), [task]);
	const project = useMemo(() => _.get(task, "project", {} as any), [task]);

	const activityQueryStringParameters = useMemo(() => ({ task_id: id }), [id]);
	const [editModalOpen, setEditModalOpen] = useState<boolean>();
	const [overrideModalOpen, setOverrideModalOpen] = useState<boolean>();
	const [notifyModalOpen, setNotifyModalOpen] = useState<boolean>();
	const closeEditModal = useCallback(() => setEditModalOpen(false), [setEditModalOpen]);

	const responsibleRoleName = useMemo(() => _.get(task, "responsible_role.name"), [task]);
	// const spectatorRoleName = useMemo(() => _.map(_.get(task, "spectator_roles", []), role => role.name).join(" | "), [
	// 	task
	// ]);
	const metadata = useMemo(
		() => (
			<div className="flex flex-col">
				<div className="font-medium text-14 leading-4">
					{/* Responsible Role: <span className={"font-bold"}>{responsibleRoleName}</span> */}
					Responsible Role: <span>{responsibleRoleName}</span>
				</div>
				{/* {spectatorRoleName && (
					<div>
						Spectator Roles: <span className={"font-bold"}>{spectatorRoleName}</span>
					</div>
				)} */}
			</div>
		),
		[responsibleRoleName]
	);

	const onUpdate = useCallback(
		(task: any) => {
			setTask(task);
			setLastMoment(moment());
		},
		[setTask, setLastMoment]
	);

	const onUpdateMoment = useCallback(() => {
		setLastMoment(moment());
	}, [setLastMoment]);

	const reopen = useCallback(() => {
		if (!_.isNil(id)) datasource.put("v2", `tasks/${id}/reopen`, {}, { updateIsLoding: true });
	}, [datasource, id]);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success && response.actionType === DatasourceActionType.GET)
				setTask(response.normalizedMainModelResponse);
			if (response.success && response.actionType === DatasourceActionType.PUT) {
				onUpdate(response.normalizedMainModelResponse);
			}
		});
		return () => sub.unsubscribe();
	}, [datasource, setTask, onUpdate]);

	// API call
	useEffect(() => {
		if (!_.isNil(id))
			datasource.get("v2", `tasks/${id}`, {
				queryStringParameters: {
					fields: JSON.stringify({
						project: ["name", "id", "closed"],
						responsible_role: ["name"],
						spectator_roles: ["name"],
						all_users: ["id", "name", "firstname", "surname"],
					}),
				},
			});
	}, [id, datasource, lastMoment]);

	if (!_.isEmpty(task) && !_.isEmpty(project)) {
		let action;
		if (!task.closed && (!task.complete || !task.ready)) {
			action = { onClick: () => setOverrideModalOpen(!overrideModalOpen), label: "close_task" };
		} else if (!task.closed && !!task.complete) {
			action = { onClick: () => setNotifyModalOpen(!notifyModalOpen), label: "close_task" };
		} else if (!!task.closed) {
			action = { onClick: () => reopen(), label: "reopen", confirm: true, icon: "undo" };
		}

		const className = classNames("tasks", "show", {
			"project-open": !project.closed,
			"project-closed": !!project.closed,
			"task-open": !task.closed,
			"task-closed": !!task.closed,
			"task-ahead": !task.behind,
			"task-behind": !!task.behind,
			"task-complete": !!task.complete,
			"task-incomplete": !task.complete,
		});

		return (
			<LastMomentTriggerAPIContext.Provider value={lastMomentProviderValue}>
				<ProjectContext.Provider value={project}>
					<ResourceContext.Provider value={task}>
						<React.Fragment>
							<Breadcrumbs
								key="breadcrumbs"
								items={[{ title: project.name, link: `/projects/${project.id}` }, { title: task.name }]}
							/>
							<Header
								stamp={<Status closed={!!task.closed} incomplete={!task.complete} />}
								subtitle={project.name}
								title={task.name}
								className={className}
								resource={task}
								toolbar={_.compact([
									isAdministrator && { label: "edit", onClick: () => setEditModalOpen(true) },
									(isResponsible || isAdministrator) && action,
								])}
								metadata={metadata}
							/>
							<Workspace
								id={task.id}
								className={className}
								key="tasks"
								tabs={[
									<CommentTab name="comments" key="comments-tab" globalId={globalId} />,
									<ConditionTab
										name="conditions"
										key="conditions-tab"
										taskId={id}
										onUpdate={onUpdateMoment}
										lastMoment={lastMoment}
									/>,
									<TeamMemberTab name="parties" key="teammember-tab" taskId={id} isAdministrator={isAdministrator} />,
									<InsightsTab name="dashboard" key="dashboard-tab" taskId={id} />,
									<ActivityTab
										name="activity"
										key="activity-tab"
										queryStringParameters={activityQueryStringParameters}
									/>,
								]}
							/>
							<Edit isOpen={editModalOpen} doCloseModal={closeEditModal} task={task} />
							<Notify
								task={task}
								isOpen={notifyModalOpen}
								onRequestClose={() => setNotifyModalOpen(false)}
								onUpdate={onUpdate}
							/>
							<Override
								task={task}
								lastMoment={lastMoment}
								isOpen={overrideModalOpen}
								onRequestClose={() => setOverrideModalOpen(false)}
								onUpdate={onUpdate}
							/>
						</React.Fragment>
					</ResourceContext.Provider>
				</ProjectContext.Provider>
			</LastMomentTriggerAPIContext.Provider>
		);
	}
	return <LoadingState />;
};
export default Task;
