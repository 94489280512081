import CommentCard from "components/Comments/CardDynamic";
import Datasource, { DatasourceResponse } from "lib/datasource";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";

const CommentTab = ({ globalId = "" }: any) => {
	const [, renderTrigger] = useState(moment());
	const [task, setTask] = useState({} as any);
	const [datasource] = useState(new Datasource({ mainModelName: "task", renderTrigger: renderTrigger }));
	const taggableUsers = useMemo(() => _.get(task, "all_users", []), [task]);
	const taskId = useMemo(() => globalId.match(/[a-zA-Z0-9-]*?$/), [globalId]);



	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			var result = response.normalizedMainModelResponse;
			result.all_users.unshift({name: 'All', id: 'All'});
			setTask(result)
		});
		return () => sub.unsubscribe();
	}, [datasource]);

	useEffect(() => {
		if (!_.isEmpty(taskId))
			datasource.get("v2", `tasks/${taskId}`, {
				queryStringParameters: { fields: JSON.stringify({ all_users: ["id", "name"] }) }
			});
	}, [datasource, taskId]);

	return <CommentCard taggableUsers={taggableUsers} globalId={globalId} />;
};

export default memo(CommentTab); 
