import { Date } from "components/Shared";
import _ from "lodash";
import { CheckBox } from "v2/components/shared";
import React, { memo, useCallback, useEffect, useState } from "react";

interface Props {
	rescheduledTaskId?: string;
	rescheduledChildren: any[];
	onlyDisplayChanged?: boolean;
}

const ReschedulePreview = ({ rescheduledChildren = [], onlyDisplayChanged = true, rescheduledTaskId }: Props) => {
	const [tasks, setTasks] = useState([] as any[]);
	const [affectedOtherTasksCount, setAffectedOtherTasksCount] = useState(0);
	const [displayAll, setdisplayAll] = useState(!onlyDisplayChanged);
	const onChangeDisplayAll = (e: any) => setdisplayAll(e.target.checked);
	const getAffectedOtherTasks = useCallback(
		() =>
			_.filter(
				rescheduledChildren,
				child => !child.closed && child.original_due_at !== child.rescheduled_due_at && child.id !== rescheduledTaskId
			),
		[rescheduledChildren, rescheduledTaskId]
	);
	const getTasks = useCallback(() => (displayAll ? rescheduledChildren : getAffectedOtherTasks()), [
		displayAll,
		rescheduledChildren,
		getAffectedOtherTasks
	]);
	const getBackgroundColorClass = (task: any) => {
		if (task.id === rescheduledTaskId) return "#fbd38d";
		return task.original_due_at === task.rescheduled_due_at || task.closed ? "#9ae6b4" : "#feb2b2";
	};

	const getDatesCells = (task: any) => {
		if (task.closed) {
			return (
				<>
					<td colSpan={3} style={{ textAlign: "center" }}>
						<span>closed at </span>
						<Date date={task.closed_at} showTime={false} />
					</td>
				</>
			);
		} else {
			return (
				<>
					<td>
						<Date date={task.original_due_at} showTime={false} />
					</td>
					<td>
						<i className="fas fa-long-arrow-alt-right"></i>
					</td>
					<td>
						<Date date={task.rescheduled_due_at} showTime={false} />
					</td>
				</>
			);
		}
	};

	useEffect(() => setTasks(getTasks()), [displayAll, rescheduledChildren, getTasks]);
	useEffect(() => setAffectedOtherTasksCount(getAffectedOtherTasks().length), [
		rescheduledChildren,
		getAffectedOtherTasks
	]);

	if (_.size(tasks) < 1) return <></>;

	return (
		<div className="reschedule-preview warn">
			<h5>This change has flow on effects</h5>
			<div className="warn-body">
				<div style={{ display: "flex" }}>
					<div style={{ marginRight: "25px" }}>
						Changes <b>{affectedOtherTasksCount}</b> other tasks
					</div>
					<CheckBox defaultChecked={displayAll} onChange={onChangeDisplayAll} style={{ marginRight: "5px" }} />
					<span>Display All Tasks</span>
				</div>
				<table className="table">
					<tbody>
						<tr>
							<th>Task Name</th>
							<th style={{ textAlign: "center" }}>Calendar Days</th>
							<th>Current Due At</th>
							<th></th>
							<th>Adjusted Due At</th>
							<th style={{ textAlign: "center" }}>Fixed</th>
							<th style={{ textAlign: "center" }}>Closed</th>
						</tr>
						{_.map(tasks, (task: any) => (
							<tr
								key={task.id}
								className={task.id === rescheduledTaskId ? "border-2 border-yellow-500" : ""}
								style={{ backgroundColor: getBackgroundColorClass(task) }}
							>
								<td>
									<b>{task.name}</b>
								</td>
								<td style={{ textAlign: "center" }}>{task.new_length}</td>
								{getDatesCells(task)}
								<td style={{ textAlign: "center" }}>
									{task.due_at_fixed ? (
										<i className="far fa-check-circle" style={{ color: "green" }}></i>
									) : (
										<i className="far fa-times-circle" style={{ color: "red" }}></i>
									)}
								</td>
								<td style={{ textAlign: "center" }}>
									{task.closed ? (
										<i className="far fa-check-circle" style={{ color: "green" }}></i>
									) : (
										<i className="far fa-times-circle" style={{ color: "red" }}></i>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default memo(ReschedulePreview);
