import React, { useState, useCallback, memo } from "react";
import { ListGroupControl, ListGroupItem, View, ListGroup, Address, LoadingState } from "components/Shared";
import { useDefaults, translate } from "lib";
import { Show as Avatar } from "components/Avatar";
import { useSelector } from "react-redux";
import _ from "lodash";

const Collection = ({
	properties,
	company
}: {
	name?: string;
	company?: tcpinpoint.Company;
	properties?: tcpinpoint.Property[];
}) => {
	const defaults = useDefaults() as { sortBy: string; filteredBy: string };
	const [sortBy, setSortBy] = useState(defaults.sortBy || "name");
	const [filteredBy, setFilteredBy] = useState(defaults.filteredBy || "all");

	let collection = _.sortBy(
		_.filter(
			properties,
			(property: tcpinpoint.Property) =>
				(filteredBy === "neighbourhood" && property.kind === "neighbourhood") ||
				(filteredBy === "cbdretail" && property.kind === "cbdretail") ||
				(filteredBy === "subregional" && property.kind === "subregional") ||
				(company && filteredBy === "owned" && property.owned_by.id === company.id) ||
				(company && filteredBy === "managed" && property.managed_by.id === company.id) ||
				(company && filteredBy === "operated" && property.operated_by.id === company.id) ||
				filteredBy === "all"
		),
		property => (sortBy === "gross_lettable_area" && Math.round(property.gross_lettable_area)) || sortBy
	);

	if (sortBy === "gross_lettable_area") {
		collection = collection.reverse();
	}

	const renderProperty = useCallback(
		property => (
			<ListGroupItem
				icon={<Avatar url={property.avatar} name={property.name} />}
				link={`/properties/${property.id}`}
				key={property.id}
				heading={property.name}
				subheading={<Address address={_.first(property.addresses)} />}
				datapoints={[
					!!Math.round(property.gross_lettable_area) && {
						key: "gross_lettable_area",
						value: <React.Fragment>{Math.round(property.gross_lettable_area).toLocaleString()}m²</React.Fragment>
					},
					!!property.kind && { key: "kind", value: translate(property.kind) }
				]}
			/>
		),
		[]
	);

	const loading = useSelector((store: tcpinpoint.Store) => store.activity.PROPERTIES);
	if (!!loading) return <LoadingState />;

	return (
		<View hideTitle name="properties" key="company-properties" resource="properties">
			<ListGroupControl
				onSortChange={setSortBy}
				selectedSort={sortBy}
				sortOptions={["name", "locality", "gross_lettable_area"]}
				onFilterChange={setFilteredBy}
				selectedFilter={filteredBy}
				filterOptions={["all", "subregional", "neighbourhood", "cbdretail", "owned", "managed", "operated"]}
			/>
			<ListGroup pagination={true} resource="properties" collection={collection} renderRow={renderProperty} />
		</View>
	);
};

export default memo(Collection);
