import React from "react";
import Color from "color";
import Container from "./Container";

export default ({
  color = 'rgba(60, 140, 7, 0.773)',
  font,
  width = "100%",
  display,
  status
}: {
  color?: string;
  font?: string;
  width?: string;
  display?: string;
  status?: string
}) => {
  return (
    <React.Fragment>
      <Container
        backGround={Color(color)
          .fade(0.7)
          .rgb()
          .string()}
        width={width}
        display={display}
      >
        <div
          className={`progress-bar progress-bar-striped progress-bar-animated bg-${status}`}
          style={{
            width: '30%',
            backgroundColor: color,
            borderRadius: '2.5rem',
            color: font
          }}
        >
          30%
        </div>
      </Container>
    </React.Fragment>
  );
};
