import _ from "lodash";
import { getProjectWorkflows, getProject } from "actions";
import { memoAC } from "./memoize";
import axios from "axios";
import { getAuthTokenFromCookie } from 'lib/hooks';

export const addRoles = memoAC((id: string, roles: any[]) => (dispatch: any) => {
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks/${id}/roles`, 
		//@ts-ignore
		{ roles: _.map(_.keys(roles), (key: string) => roles[key].slug) },
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getAuthTokenFromCookie()}`,
			}
		})
		.then((res) => dispatch(getTask(res.data.task.id)));
});

export const addSpectatorToTask = memoAC((task: tcpinpoint.Task, user: tcpinpoint.User) => (dispatch: any) =>
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks/${task.id}/spectators`, { user_id: user.id },
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getAuthTokenFromCookie()}`,
			}
		})
		.then((res) => dispatch(getTask(res.data.task.id)))
);

/**
 * Reject a Task
 * @param {uuid} id Task ID
 * @param {uuid} reason
 */
export const rejectTask = memoAC((id: string, reason: string) => (dispatch: any) => {
	dispatch({ type: "REJECT_TASK_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.delete(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks/${id}`, { params: { reason }, headers: headers })
		.then((res) => {
			dispatch({ type: "REJECT_TASK_REQUEST" });
			dispatch(getProjectWorkflows(res.data.task.project_id));
			dispatch(getTask(res.data.task.id));
		})
		.catch((e) => { dispatch({ type: "REJECT_TASK_FAILED", response: e }); });
});

/**
 * Reopen Task
 * @param {uuid} id Task ID
 */
export const reopenTask = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "REOPEN_TASK_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks/${id}/reopen`, { headers: headers })
		.then((res) => {
			dispatch({ type: "REOPEN_TASK_SUCCESS" });
			dispatch(getProjectWorkflows(res.data.task.project_id));
			dispatch(getTask(res.data.task.id));
		})
		.catch((e) => { dispatch({ type: "REOPEN_TASK_FAILED", response: e }); });
});

/**
 * Close a Task
 * @param {uuid} id Task ID
 */
export const closeTask = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "CLOSE_TASK_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks/${id}/close`, { headers: headers })
		.then((res) => {
			dispatch({ type: "CLOSE_TASK_SUCCESS" });
			dispatch(getProjectWorkflows(res.data.task.project_id));
			dispatch(getTask(res.data.task.id));
		})
		.catch((e) => { dispatch({ type: "CLOSE_TASK_FAILED", response: e }); });
});

/**
 * Update a Task
 * @param task Task
 */
export const putTask = memoAC((task: tcpinpoint.Task) => (dispatch: any) => {
	dispatch({ type: "PUT_TASK_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.put(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks/${task.id}`, { task }, { headers: headers })
		.then((res) => {
			dispatch({ type: "PUT_TASK_SUCCESS", resource: res.data.task });
			// FIXME: THIS IS THE CAUSE OF THE BROWSER LOOP dispatch(getProjectTasks(task.project_id));
		})
		.catch((e) => { dispatch({ type: "PUT_TASK_FAILED", response: e }); });
});

/**
 * Get a Task
 * @param id Task ID
 */
export const getTask = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_TASK_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks/${id}`, { headers: headers })
		.then((res) => {
			dispatch(getProject(res.data.task.project_id));
			dispatch({ type: "GET_TASK_SUCCESS", resource: res.data.task });
		})
		.catch((e) => { dispatch({ type: "GET_TASK_FAILED", response: e }); });
});

/**
 * Get all Tasks
 */
export const getTasks = memoAC((args: Object) => (dispatch: any) => {
	dispatch({ type: "GET_TASKS_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}tasks`, { params: args, headers: headers })
		.then((res) => {
			dispatch({ type: "GET_TASKS_SUCCESS", resource: res.data.tasks })
		})
		.catch((e) => { dispatch({ type: "GET_TASKS_FAILED", response: e }); });
});

/**
 * Get all Tasks in a Project
 * @param id Project ID
 */
export const getProjectTasks = memoAC((id: string) => (dispatch: any) => {
	dispatch({ type: "GET_TASKS_REQUEST" });
	const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthTokenFromCookie()}`, };
	axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}projects/${id}/tasks`, { headers: headers })
		.then((res) => dispatch({ type: "GET_TASKS_SUCCESS", resource: res.data.tasks }))
		.catch((e) => { dispatch({ type: "GET_TASKS_FAILED", response: e }); });
});
