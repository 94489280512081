import Color from "color";
import React, { memo } from "react";
import Completion from "./Completion";
import Container from "./Container";


const Progress = ({ type, value = 0, color = "#49da9a", width = "100%", display }: any) => {
  return (
    <React.Fragment>
      <Container
        backGround={Color(color).fade(0.7).rgb().string()}
        width={width}
        display={display}
      >
        <Completion type={type} data={`${value}%`} color={color} />

        <div
          style={{ margin: "0 auto", color: "#fff" }}>
          {value === 0 ? '0%' : null}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default memo(Progress);
