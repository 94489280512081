import React from "react";
import PropTypes from "prop-types";
import Avatar from "react-avatar";

export default class RoleCard extends React.Component {
	render() {
		if (this.props.item.name) {
			return (
				<article className="card">
					<div className="card-block">
						<h4 className="card-title">
							<Avatar size={48} color="#849CAD" className="avatar" round name={this.props.item.name} />
							{this.props.item.name}
						</h4>
						<p className="card-text">{this.props.item.description}</p>
					</div>
				</article>
			);
		}
		return null;
	}
}

RoleCard.propTypes = {
	item: PropTypes.shape({
		description: PropTypes.string,
		name: PropTypes.string
	})
};
